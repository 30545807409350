import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import { IInventoryFilters } from '../../../../interfaces/inventory-data/IInventoryFilters';
import { PaginatedAggregatedInventory, PaginatedInventory } from '../../../../interfaces/inventory-data/paginatedStore.type';
import inventoryAxios from '../../../../utils/axios/inventory.axios';

const tryToFetchInventoryData = (
    pageNumber: number,
    pageSize: number,
    data?: IInventoryFilters,
): Promise<AxiosResponse<PaginatedInventory>> => {
    const url = `${INVENTORY_API_URL}/inventory?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedInventory>(url, data);
};

const tryToFetchInventoryAgreggatedData = (
    pageNumber: number,
    pageSize: number,
    data?: IInventoryFilters,
): Promise<AxiosResponse<PaginatedAggregatedInventory>> => {
    const url = `${INVENTORY_API_URL}/inventory/items?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedAggregatedInventory>(url, data);
};

const tryToFetchInventoryAgreggatedDetails = (
    pageNumber: number,
    pageSize: number,
    data?: IInventoryFilters,
): Promise<AxiosResponse<PaginatedInventory>> => {
    const url = `${INVENTORY_API_URL}/inventory/items/details?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedInventory>(url, data);
};

const tryToFetchSingleInventoryItem = (itemId: string): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/item/${itemId}`
    return inventoryAxios.get(url);
};

const tryToFetchItemDetails = (itemId: string): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/item/${itemId}`
    return inventoryAxios.get(url);
};

const tryToFetchInventoryFieldValues = (categoryId?: string, data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/asset/${categoryId}/values`
    return inventoryAxios.put(url, data);
};

const tryToDownloadInventorySample = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/sample/download`
    return inventoryAxios.put(url, data);
};

const tryToImportInventoryData = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/items/import`
    return inventoryAxios.put(url, data);
};

const tryToImportInventorySalesGrade = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/items/sales-grades`
    return inventoryAxios.put(url, data);
};

const tryToFetchTotalInventoryItemsNumber = (warehouseId: string): Promise<AxiosResponse<any>> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/inventory/item-count/${warehouseId}`);
};

const tryToImportInventoryCosts = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/items/costs`
    return inventoryAxios.put(url, data);
};

const tryToAddInventoryLocation = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/items/location`
    return inventoryAxios.put(url, data);
};

const tryToChangeInventoryItemDetails = (id: string, data: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/item/${id}`
    return inventoryAxios.put(url, data);
};

const tryToDeleteInventoryItem = (id: string): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/inventory/item/${id}`
    return inventoryAxios.delete(url);
};

const tryToChangeInventoryItemsLocation = (data: any): Promise<AxiosResponse<any>> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/inventory/items/stock-change`, data);
};

export const InventoryService = {
    tryToFetchInventoryData,
    tryToImportInventoryCosts,
    tryToImportInventorySalesGrade,
    tryToFetchInventoryAgreggatedData,
    tryToFetchInventoryAgreggatedDetails,
    tryToFetchInventoryFieldValues,
    tryToFetchSingleInventoryItem,
    tryToChangeInventoryItemDetails,
    tryToDownloadInventorySample,
    tryToImportInventoryData,
    tryToAddInventoryLocation,
    tryToDeleteInventoryItem,
    tryToFetchItemDetails,
    tryToChangeInventoryItemsLocation,
    tryToFetchTotalInventoryItemsNumber
};