import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { setupAxios } from './utils/axios';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { ENVIRONMENT, SENTRY_URL } from './config';

(ENVIRONMENT === 'production' || ENVIRONMENT === 'prod' || ENVIRONMENT === 'dev' || ENVIRONMENT === 'development') && Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

setupAxios();
const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
);
