import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { onFormatSalesStatus } from '../../../../utils/constants/sale-details-statuses';


interface ISaleOrderDetailsInfo {
    saleDetails: any;
    // onGoToParentOrder: () => void;
}

const SaleOrderDetailsInfo = ({
    saleDetails,
    // onGoToParentOrder,
}: ISaleOrderDetailsInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatSalesStatus(saleDetails?.status)?.name
        const color = onFormatSalesStatus(saleDetails?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatSalesStatus(saleDetails?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text,
        })
    }, [saleDetails?.status])

    return (
        <div className='bg-white !rounded p-3'>
            <div className='flex flex-row items-center'>
                <div className={`${orderStatusDetails?.color} !border rounded-md ${orderStatusDetails?.text} mr-10 py-1 px-3 font-semibold`}>
                    {orderStatusDetails?.name}
                </div>
                {saleDetails?.quoteId &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Quote Id</p>
                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(saleDetails?.quoteId)}>
                            <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                            <p className='text-[14px]'>{saleDetails?.quoteId}</p>
                        </div>
                    </div>
                }
                {saleDetails?.salesInfo?.customer?.displayName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Customer</p>
                        <p className='text-[14px]'>{saleDetails?.salesInfo?.customer?.displayName}</p>
                    </div>
                }
                {saleDetails?.salesInfo?.partner?.companyName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Broker</p>
                        <p className='text-[14px]'>{saleDetails?.salesInfo?.partner?.companyName}</p>
                    </div>
                }
                {saleDetails?.salesInfo?.dueDate &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Due Date</p>
                        <p className='text-[14px]'>{moment(saleDetails?.salesInfo?.dueDate).format('DD-MM-YYYY')}</p>
                    </div>
                }
                {saleDetails?.salesInfo?.warranty &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Warranty</p>
                        <p className='text-[14px]'>{saleDetails?.salesInfo?.warranty}</p>
                    </div>
                }
                {saleDetails?.salesInfo?.stockVat &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Stock VAT</p>
                        <p className='text-[14px]'>{saleDetails?.salesInfo?.stockVat}</p>
                    </div>
                }
                {saleDetails?.market?.label &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Market</p>
                        <p className='text-[14px]'>{saleDetails?.market?.label}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default SaleOrderDetailsInfo;