import { createApi } from '@reduxjs/toolkit/query/react';
import { assetsAxiosQuery } from '../../../../utils/axios/assets.axios';

export const templatesAPI = createApi({
    reducerPath: 'templates',
    baseQuery: assetsAxiosQuery(),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        assetTemplateInventoryFilters: builder.mutation<any, any>({
            query: ({ assetId }) => ({
                url: `/asset-templates/${assetId}/get-inventory-filters`,
                method: 'GET',
                data: {
                    token: localStorage.getItem('accessToken') || ''
                },
            }),
        }),
        assetTemplateFilters: builder.mutation<any, string>({
            query: (assetTemplateId: string) => ({
                url: `/asset-templates/${assetTemplateId}/get-filters?replacements=true`,
                method: 'GET'
            })
        })
    }),
});

export const {
    useAssetTemplateInventoryFiltersMutation,
    useAssetTemplateFiltersMutation
} = templatesAPI;
