import React from 'react';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IUploadCostsCsvFile {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    fileSelected?: any;
    importError?: string;
    selectedSector?: string;
    activeWarehouse?: IWarehouse;
    sectors: Array<any>;
    rowsError?: any;
    onChangeSelectedSector: (e: any, type?: string) => void;
    onUploadFile: () => void;
}

const UploadCostsCsvFile = ({
    activeWarehouse,
    getRootProps,
    getInputProps,
    isDragActive,
    fileSelected,
    importError,
    sectors,
    selectedSector,
    rowsError,
    onChangeSelectedSector,
    onUploadFile
}: IUploadCostsCsvFile) => {
    return (
        <div className='p-5'>
            <div className='mb-3'>
                <p className='text-sm font-semibold'>Import COGs Costs CSV</p>
                <p className='text-md mb-2 mt-1'>Import data for {activeWarehouse?.name} warehouse.</p>
                {importError && <Error text={importError} />}
                {rowsError && rowsError?.itemId &&
                    <div className='flex flex-row item-center my-1'>
                        <p className='mr-1 text-xs text-red-500'>Missing Item ID in rows:</p>
                        <p className='text-xs text-red-500'>{rowsError?.itemId && rowsError?.itemId?.map((item: any, index: any) => `${index ? ', ' : ''}${item}`)}</p>
                    </div>
                }
                {rowsError && rowsError?.type &&
                    <div className='flex flex-row item-center my-1'>
                        <p className='mr-1 text-xs text-red-500'>Missing Type in rows:</p>
                        <p className='text-xs text-red-500'>{rowsError?.type && rowsError?.type?.map((item: any, index: any) => `${index ? ', ' : ''}${item}`)}</p>
                    </div>
                }
                {rowsError && rowsError?.value &&
                    <div className='flex flex-row item-center my-1'>
                        <p className='mr-1 text-xs text-red-500'>Missing Value in rows:</p>
                        <p className='text-xs text-red-500'>{rowsError?.value && rowsError?.value?.map((item: any, index: any) => `${index ? ', ' : ''}${item}`)}</p>
                    </div>
                }
            </div>
            <div className='my-3'>
                <SelectCheckbox
                    placeholder='Category'
                    containerStyle='min-w-[90%]'
                    selectedOption={selectedSector}
                    onChangeSelectedOption={onChangeSelectedSector}
                    options={sectors || []}
                />
            </div>
            <div className='flex flex-col items-center justify-center min-h-[200px] rounded'>
                <div className='flex flex-col items-center justify-center' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p className='font-semibold'>Drag n drop csv file here</p>
                    }
                    <p className='mb-2'>
                        or
                    </p>
                    <Button
                        icon={<img src='/assets/shared/upload-file.svg' className='w-[30px] object-contains pr-2' />}
                        className={'btn-primary-rounded-less !py-2 !shadow-none flex flex-row items-center'}
                        label={'Select File'}
                    />
                    {fileSelected && <p>{fileSelected?.name}</p>}
                </div>
            </div>
            <div className='flex flex-col items-center my-2'>
                <Button
                    label='Upload File'
                    onClickButton={() => fileSelected && onUploadFile()}
                    className={`${fileSelected ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} !py-2 !shadow-none min-w-[150px]`}
                />
            </div>
        </div>
    )
}

export default UploadCostsCsvFile;