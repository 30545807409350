import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { tryToFetchSingleWarehouse } from '../../../../store/inventory/broker/warehouses/warehousesSlice';


const WarehouseManage = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [warehouseData, setWarehouseData] = useState<IWarehouse>()
    const state = useAppSelector((state) => state.warehouses);
    const warehouseIsLoading = useAppSelector((state) => state.warehouses?.warehouseIsLoading);

    useEffect(() => {
        if (state.warehouse) {
            setWarehouseData(state.warehouse)
        }
    }, [state.warehouse]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleWarehouse(id));
        }
    }, []);

    const onGoBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div>
                <div className={'flex flex-row items-center justify-between'}>
                    <div className='flex flex-row items-center'>
                        <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                            <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                        </div>
                        <p className='page-title'>Warehouse Details</p>
                    </div>
                </div>
                <div className='ml-[18px] mb-5'>
                    {!warehouseIsLoading
                        && <div>
                            <p className='font-medium'>Name: <span className='font-normal'>{warehouseData?.name}</span></p>
                            <p className='font-medium'>Details: <span className='font-normal'>{warehouseData?.address?.addressNo} {warehouseData?.address?.street}, {warehouseData?.address?.city} {warehouseData?.address?.postalCode}, {warehouseData?.address?.country}</span></p>
                        </div>
                    }
                </div>
            </div>
            <div>
            </div>
        </div >
    )
}

export default WarehouseManage;