import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import { tryToFindOperatingMarkets } from '../../../store/inventory/shared/partners/partnersSlice';
import BankAccounts from './categories/BankAccounts';
import Warehouses from './categories/Warehouses';
import Companies from './categories/Companies';
import Customers from './categories/Customers';
import Markets from './categories/Markets';
import Button from '../../../shared/button';


interface IProfileCategories {
    name: string,
    element?: React.ReactNode
}

const profileCategories: IProfileCategories[] = [
    { name: 'Markets' },
    { name: 'Warehouses' },
    { name: 'Companies' },
    { name: 'Customers' },
    { name: 'Bank Accounts' },
]

const Profile = () => {
    const dispatch = useAppDispatch()
    const [activeCategory, setActiveCategory] = useState<IProfileCategories>(profileCategories[0]);
    const [brokerMarkets, setBrokerMarkets] = useState<Array<any>>();
    const [allMarkets, setAllMarkets] = useState<Array<any>>();
    const [pageAccess, setPageAccess] = useState<any>();
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const [getAllMarkets] = useGetAllMarketsMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Profile Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        const findIfMarkets = (profileCategories && profileCategories?.length > 0) && profileCategories?.find((item) => findChild?.actions[item?.name] || !accessControl)
        if (findIfMarkets) {
            setActiveCategory(profileCategories[0])
        } else {
            const filterProfileCategories = profileCategories?.filter((item: any) => !accessControl || findChild?.actions?.[item?.name])
            setActiveCategory(filterProfileCategories?.[0])
        }
    }, [accessControl])

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        setAllMarkets(response || [])
    }

    const getAllOperatingMarkets = async () => {
        const response = await dispatch(tryToFindOperatingMarkets(null)).unwrap()
        setBrokerMarkets(response || [])
    }

    useEffect(() => {
        getMarketsData()
        getAllOperatingMarkets()
    }, [])

    const onChangeActiveCategory = (item: IProfileCategories) => {
        setActiveCategory(item)
    }

    const onSuccessfullFinishMarketAction = (type: string, marketId: string) => {
        if (type === 'disable') {
            const formatBrokerMarkets = brokerMarkets?.filter((item) => item?.market?._id !== marketId)
            setBrokerMarkets(formatBrokerMarkets || [])
        } else {
            const findMarket = allMarkets?.find((market) => market?._id === marketId)
            setBrokerMarkets([
                ...brokerMarkets || [],
                { market: findMarket || {} }
            ])
        }
        getAllOperatingMarkets()
    }

    const onCallActiveElement = () => {
        switch (activeCategory?.name) {
            case 'Markets':
                return <Markets
                    onSuccessfullFinishAction={onSuccessfullFinishMarketAction}
                    allMarkets={allMarkets || []}
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    brokerMarkets={brokerMarkets || []}
                />
            case 'Warehouses':
                return <Warehouses
                    pageAccess={pageAccess}
                    accessControl={accessControl}
                    brokerMarkets={brokerMarkets || []}
                />
            case 'Companies':
                return <Companies />
            case 'Bank Accounts':
                return <BankAccounts />
            case 'Customers':
                return <Customers />
        }
    }

    return (
        <div>
            <div className='mb-5'>
                <p className='page-title'>Profile</p>
            </div>
            <div className='flex flex-row items-center my-5'>
                {profileCategories?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={category?.name}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2' : 'btn-noactive-categories ml-2'}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    )
                })}
            </div>
            <div className='my-2 min-w-[100%]'>
                {onCallActiveElement()}
            </div>
        </div>
    )
}

export default Profile;