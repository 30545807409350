import React from 'react';
import Button from '../../../../shared/button';


interface IInventoryCatalogFilters {
    items: Array<any>;
    activeButton?: any;
    itemsCount?: any;
    onClickCategory: (item: string) => void;
}

const InventoryCatalogFilters = ({
    items,
    activeButton,
    itemsCount,
    onClickCategory
}: IInventoryCatalogFilters) => {
    return (
        <div>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Categories</p>
            <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4'>
                {items && items?.length > 0 && items?.map((item, index) => {
                    let buttonClassName = 'btn-noactive-categories border'
                    if (activeButton?.value === item?.value) buttonClassName = 'btn-categories-list';
                    else buttonClassName = 'btn-noactive-categories-list border';
                    const { totalReceived } = itemsCount?.find((count: any) => count?.category?.code === item?.value) || {};
                    return (
                        <Button
                            key={index}
                            label={
                                <>
                                    <span className={`${activeButton?.value === item?.value ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>{item?.label}</span>
                                    {totalReceived ?
                                        <span className={`ml-auto shrink-0 !text-[12px] text-xs ${activeButton?.value === item?.value ? 'text-[#202020]' : 'text-[#8a8a8e]'}`}>
                                            {totalReceived}
                                        </span>
                                        : ''
                                    }
                                </>
                            }
                            className={`${buttonClassName} !min-w-[70px] text-sm !rounded`}
                            onClickButton={() => onClickCategory(item)}
                            dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                            icon={item.enabledIcon ? <div className={'w-[23px] h-[23px]'}><img src={activeButton?.value === item?.value ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} /></div> : ''}
                        />)
                })}
            </div>
        </div>
    )
}

export default InventoryCatalogFilters;