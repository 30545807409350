import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { ISales } from '../../../../interfaces/sales/ISales';
import { tryToShowSaleSplit } from '../../../../store/inventory/shared/sales/salesSlice';
import MarkApprovedModal from '../modals/MarkApprovedModal';
import MarkProcessedModal from '../modals/MarkProcessedModal';
import MarkAsPaidModal from '../modals/MarkAsPaidModal';
import ShipOrderModal from '../modals/ShipOrderModal';
import GenerateModal from '../modals/GenerateModal';
import Button from '../../../../shared/button';


interface ISaleDetailsActions {
    saleDetails?: ISales;
    accessControl?: any;
    pageAccess?: any;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const SaleDetailsActions = ({
    saleDetails,
    accessControl,
    pageAccess,
    onSuccessfullyChanged
}: ISaleDetailsActions) => {
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [showGenerateModal, setShowGenerateModal] = useState<{ show: boolean, type: string }>();
    const [showActionsModal, setShowActionsModal] = useState<{ show: boolean, type: string }>();
    const [loadingDownloadCSV, setLoadingDownloadCSV] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<any>();

    const onClickAction = (type: string) => {
        document.body.style.overflow = 'hidden';
        if (type === 'continue') {
            dispatch(tryToShowSaleSplit(true)).unwrap();
            return;
        }
        setShowActionsModal({
            show: true,
            type: type
        })
    }

    const onHideAction = () => {
        setShowActionsModal(undefined)
        setShowGenerateModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const onDownloadCSV = async () => {
        setLoadingDownloadCSV(true)
        const formatItems: any = [['Item ID/ IMEI', 'Item Name', 'Price', 'Sales Date']];
        const salesDate = saleDetails?.payInfo?.payDate ? moment(saleDetails?.payInfo?.payDate).format('DD-MM-YYYY') : '';
        (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.map((item: any) => {
            const itemName = [
                ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
            ].join(' ');

            const itemData = [item?.itemCode || item?.itemId, itemName, `${CURRENCY_SYMBOLS?.[`${(saleDetails?.saleItems?.[0]?.currency || '')?.toLowerCase()}`]}${item.price}`, salesDate || '']
            formatItems.push(itemData);
            return item;
        })
        setCsvData(formatItems)
    }

    const onGenerateInvoice = async (type: string) => {
        setShowGenerateModal({
            show: true,
            type: type
        })
        document.body.style.overflow = 'auto';
    }

    const onDownloadProforma = async () => {
        window.open(saleDetails?.proFormData?.pdfLink, '_blank');
    }

    const onDownloadInvoice = async () => {
        window.open(saleDetails?.invoiceData?.pdfLink, '_blank');
    }

    const onDownloadShippingLabel = async () => {
        window.open(saleDetails?.shippingInfo?.invoiceUrl, '_blank');
    }

    const onAddOrderToDraft = async () => {
        navigator(`/sales-orders/edit/${saleDetails?._id}?expired=true`);
    }

    useEffect(() => {
        if (csvData && loadingDownloadCSV) {
            csvLink.current.link.click()
            setLoadingDownloadCSV(false)
        }
    }, [csvData])

    return (
        <div className='flex flex-row items-center'>
            {(saleDetails && (['draft']?.includes(saleDetails?.status || '')) && !saleDetails?.batch) &&
                <Button
                    label={'Mark as approved'}
                    className={`${(!accessControl || pageAccess?.actions?.['Mark as approved']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as approved']) && onClickAction('mark_approved')}
                />
            }
            {(saleDetails && (['draft']?.includes(saleDetails?.status || '')) && saleDetails?.batch) &&
                <Button
                    label={'Mark as processed'}
                    className={`${(!accessControl || pageAccess?.actions?.['Mark as processed']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as processed']) && onClickAction('mark_processed')}
                />
            }
            {saleDetails && (['processed']?.includes(saleDetails?.status || '')) &&
                <Button
                    label={'Mark as paid'}
                    className={`${(!accessControl || pageAccess?.actions?.['Mark as paid']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as paid']) && onClickAction('mark_paid')}
                />
            }
            {saleDetails && (['paid']?.includes(saleDetails?.status || '')) &&
                <Button
                    label={'Mark as shipped'}
                    className={`${(!accessControl || pageAccess?.actions?.['Mark as shipped']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as shipped']) && onClickAction('mark_shipped')}
                />
            }
            {saleDetails && (['open_order', 'review', 'processed', 'paid', 'shipped']?.includes(saleDetails?.status || '')) &&
                <Button
                    label={loadingDownloadCSV ? 'Loading...' : 'Download CSV'}
                    className={`${((!accessControl || pageAccess?.actions?.['Download CSV']) && !loadingDownloadCSV) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => ((!accessControl || pageAccess?.actions?.['Download CSV']) && !loadingDownloadCSV) && onDownloadCSV()}
                />
            }
            {(saleDetails && (['open_order', 'processed']?.includes(saleDetails?.status || '')) && !saleDetails?.proFormData?.pdfLink) &&
                <Button
                    label={'Generate Proforma'}
                    className={`${(!accessControl || pageAccess?.actions?.['Generate Proforma']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Generate Proforma']) && onGenerateInvoice('proforma')}
                />
            }
            {saleDetails && (['shipped']?.includes(saleDetails?.status || '') && saleDetails?.shippingInfo?.invoiceUrl) &&
                <Button
                    label={'Download Shipping Label'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Shipping Label']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Shipping Label']) && onDownloadShippingLabel()}
                />
            }
            {saleDetails && (['open_order', 'processed']?.includes(saleDetails?.status || '') && saleDetails?.proFormData?.pdfLink) &&
                <Button
                    label={'Download Proforma'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Proforma']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Proforma']) && onDownloadProforma()}
                />
            }
            {(saleDetails && (['shipped']?.includes(saleDetails?.status || '')) && saleDetails?.invoiceData?.pdfLink) &&
                <Button
                    label={'Download Invoice'}
                    className={`${(!accessControl || pageAccess?.actions?.['Download Invoice']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => ((!accessControl || pageAccess?.actions?.['Download Invoice'])) && onDownloadInvoice()}
                />
            }
            {(saleDetails && (['shipped']?.includes(saleDetails?.status || '')) && !saleDetails?.invoiceData?.pdfLink) &&
                <Button
                    label={'Generate an Invoice'}
                    className={`${(!accessControl || pageAccess?.actions?.['Generate an Invoice']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml-3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Generate an Invoice']) && onGenerateInvoice('invoice')}
                />
            }
            {saleDetails && (['expired']?.includes(saleDetails?.status || '')) &&
                <Button
                    label={'Add Order To Draft'}
                    className={`${(!accessControl || pageAccess?.actions?.['Add Order To Draft']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px] ml=3`}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add Order To Draft']) && onAddOrderToDraft()}
                />
            }
            {(showActionsModal?.show && showActionsModal?.type === 'mark_approved') &&
                <MarkApprovedModal
                    openModal={showActionsModal?.show}
                    saleId={saleDetails?._id || ''}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onHideAction}
                />
            }
            {(showActionsModal?.show && showActionsModal?.type === 'mark_processed') &&
                <MarkProcessedModal
                    openModal={showActionsModal?.show}
                    saleId={saleDetails?._id || ''}
                    saleDetails={saleDetails}
                    quoteId={saleDetails?.quoteId || ''}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onHideAction}
                />
            }
            {(showActionsModal?.show && showActionsModal?.type === 'mark_paid') &&
                <MarkAsPaidModal
                    openModal={showActionsModal?.show}
                    saleId={saleDetails?._id || ''}
                    partnerId={saleDetails?.salesInfo?.partner?.id || saleDetails?.salesInfo?.customer?.id}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onHideAction}
                />
            }
            {(showActionsModal?.show && showActionsModal?.type === 'mark_shipped') &&
                <ShipOrderModal
                    openModal={showActionsModal?.show}
                    saleId={saleDetails?._id || ''}
                    saleDetails={saleDetails}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onHideAction}
                />
            }
            <CSVLink
                ref={csvLink}
                data={(csvData && csvData?.length > 0) ? csvData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                headers={csvData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Sale-items-${moment().format('DD-MM-YYYY')}.csv`}
            >
            </CSVLink>
            {showGenerateModal?.show &&
                <GenerateModal
                    openModal={showGenerateModal?.show}
                    saleId={saleDetails?._id || ''}
                    type={showGenerateModal?.type || ''}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onHideAction}
                />
            }
        </div>
    )
}
export default SaleDetailsActions;