import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface IOptions {
    value: string | number;
    label?: string | number;
}

interface ISelect {
    placeholder?: string;
    register?: UseFormRegisterReturn,
    className?: string;
    disabled?: boolean;
    value?: any;
    showValue?: boolean;
    options: Array<IOptions>;
    containerStyle?: string;
    error?: string;
    multiple?: boolean;
    showNone?: boolean;
    noneName?: string;
    name?: string;
    onChangeSelectValue?: (e: any, type?: string) => void;
    inputTypeName?: string
}

const Select = ({
    value,
    className,
    placeholder,
    disabled,
    options,
    register,
    showValue,
    containerStyle,
    error,
    multiple,
    showNone,
    noneName,
    name,
    onChangeSelectValue,
    inputTypeName
}: ISelect) => {
    return (
        <div className={containerStyle || 'mb-3 w-full'}>
            {name && <p className='mb-1 ml-1 text-[#a4a4a4]'>{name}</p>}
            <select
                {...register}
                {...showValue && ({
                    value: value || '',
                    onChange: (e) => onChangeSelectValue?.(e, inputTypeName)
                })}
                multiple={multiple || false}
                className={className || `${error ? 'select-primary-error' : 'select-primary'}`}
                disabled={disabled || false}>
                {!!placeholder && <option value='' hidden disabled selected className='select-placeholder'>{placeholder}</option>}
                {showNone && <option value={0}>{noneName || 'None'}</option>}
                {options?.length > 0 && options?.map((option: any, idx: number) => {
                    return (
                        <option key={idx} value={option?.value}>{option?.label || option?.value}</option>
                    )
                })}
            </select>
            {error ? <span className='block text-xs text-red-500'>{error}</span> : <span className='block h-2 mt-2' />}
        </div>
    );
};

export default Select;