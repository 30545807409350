import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IToast } from '../../../interfaces/components/IToast';
import { tryToFetchSingleAssetTemplate } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import {
    tryToEditSaleFields,
    tryToFetchSingleSale
} from '../../../store/inventory/shared/sales/salesSlice';
import SaleFieldsTable from './tables/SaleFieldsTable';
import FieldsModal from './modals/FieldsModal';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const SaleFieldsDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [fieldsData, setFieldsData] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false);
    const [assetTemplate, setAssetTemplate] = useState<IAssetTemplate>();
    const [fieldSelected, setFieldSelected] = useState<string>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false);
    const state = useAppSelector((state) => state.sales);
    const stateAsset = useAppSelector((state) => state.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const saleIsLoading = useAppSelector((state) => state.sales?.saleFieldsAreLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Sales Asset Catalog Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (stateAsset.assetTemplateDetails) {
            setAssetTemplate(stateAsset.assetTemplateDetails)
        }
    }, [stateAsset.assetTemplateDetails]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleAssetTemplate(id));
            dispatch(tryToFetchSingleSale(id));
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setFieldsData(state.saleFields ? state.saleFields.fields || [] : undefined)
        }
    }, [state.saleFields]);

    const onToggleFieldModal = (id?: string) => {
        if (id) {
            setFieldSelected(id)
        }
        setShowFieldModal(!showFieldModal)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onRequestToDeleteField = (id: string) => {
        setShowDeleteFieldModal(true)
        setFieldSelected(id)
    }

    const onCloseDeleteFieldModal = () => {
        setFieldSelected(undefined)
        setShowDeleteFieldModal(false)
    }

    const onDeleteCarrierField = async () => {
        try {
            if (fieldSelected) {
                const filterFields = fieldsData && fieldsData?.length > 0 && fieldsData?.filter((field: any) => field?.id !== fieldSelected)
                const formatData = {
                    assetTemplateId: id || '',
                    fields: filterFields || []
                }
                await dispatch(tryToEditSaleFields(formatData)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        onCloseDeleteFieldModal()
    }

    return (
        <div>
            <div>
                <p className='page-title'>Manage Sale Fields</p>
                {!saleIsLoading &&
                    <>
                        <p className='mt-3 font-medium'>Category Name: {assetTemplate?.displayName}</p>
                    </>
                }
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Field'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Field']) ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Field']) && onToggleFieldModal()}
                />
            </div>
            <div className='my-5 relative'>
                {((fieldsData && fieldsData?.length > 0) || saleIsLoading) ?
                    <SaleFieldsTable
                        rows={fieldsData || []}
                        rowsLoading={saleIsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        onEditField={onToggleFieldModal}
                        onDeleteField={onRequestToDeleteField}
                    />
                    : <EmptyContainer text={'No field added yet'} showImage={true} />
                }
            </div>
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDeleteCarrierField}
            />
            <FieldsModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onToggleFieldModal}
                fieldItemId={fieldSelected}
                saleData={fieldsData}
                assetTemplateId={id || ''}
                onSaveChanges={onSaveFieldChanges}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    )
}

export default SaleFieldsDetails;