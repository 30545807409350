import React, { useEffect } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../utils/constants/currencySymbols';
import { tryToFetchMainOrder } from '../../store/inventory/broker/purchases/purchasesSlice';
import Modal from '../modal';
import Button from '../button';
import Table from '../table';
import LoadingPage from './loading-index';


interface IMainOrderModal {
    openModal: boolean;
    id: string;
    categories?: Array<any>;
    handleCloseModal: () => void;
}

const MainOrderModal = ({
    openModal,
    id,
    categories,
    handleCloseModal,
}: IMainOrderModal) => {
    const dispatch = useAppDispatch();
    const orderMainView = useAppSelector((state) => state?.purchases?.mainOrder)
    const mainOrderLoading = useAppSelector((state) => state?.purchases?.mainOrderLoading)

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchMainOrder(id))
        }
    }, [id])

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const onCloseModal = () => {
        handleCloseModal();
    }

    let findTotalQuantity = 0;
    let subPriceTotal = 0;

    orderMainView?.expectedItems && orderMainView?.expectedItems?.length > 0 &&
        [...orderMainView?.expectedItems].map((currentValue: any) => {
            subPriceTotal = subPriceTotal + Number(currentValue.price || 0) * Number(currentValue?.qty || 0);
            findTotalQuantity = Number(findTotalQuantity) + Number(currentValue?.qty || 0);
            return currentValue;
        });

    const totalDeliveredAmount = orderMainView?.expectedItems && orderMainView?.expectedItems?.length > 0 && orderMainView?.expectedItems?.reduce((accumulator: number, item: any) => accumulator + (Number(item.price || 0) * Number(item?.qty || 0)), 0);
    const totalCompletedAmount = orderMainView?.completedItems && orderMainView?.completedItems?.length > 0 && orderMainView?.completedItems?.reduce((accumulator: number, item: any) => accumulator + (Number(item.price || 0) * Number(item?.qty || 1)), 0);
    const totalCreditOwed = orderMainView?.discrepancyItems && orderMainView?.discrepancyItems?.length > 0 && orderMainView?.discrepancyItems?.reduce((accumulator: number, item: any) => accumulator + (Number(item.price || 0) * Number(item?.qty || 1)), 0);
    const expectedItemsQty = orderMainView?.expectedItems && orderMainView?.expectedItems?.length > 0 && orderMainView?.expectedItems?.reduce((accumulator: number, item: any) => accumulator + Number(item?.qty || 0), 0);
    return (
        <Modal
            open={openModal}
            showInRight
            title='Main Order View'
            contentContainerStyle='!min-w-[800px]'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[750px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>A peek into what happened so far with this purchase order</p>
                </div>
                {mainOrderLoading ?
                    <LoadingPage />
                    : <>
                        <div className='my-5 grid grid-cols-3 gap-3 items-center'>
                            {orderMainView?.purchaseInfo?.customer &&
                                <div className='mb-2 mr-3'>
                                    <span>Purchasing from: </span>
                                    <span className='font-bold'>{orderMainView?.purchaseInfo?.customer?.name}</span>
                                </div>
                            }
                            {orderMainView?.purchaseInfo?.dueDate &&
                                <div className='mb-2 mr-3'>
                                    <span>Due Date: </span>
                                    <span className='font-bold'>{moment(orderMainView?.purchaseInfo?.dueDate).format('DD MMMM YYYY')}</span>
                                </div>
                            }
                            {orderMainView?.purchaseInfo?.orderCreator &&
                                <div className='mb-2 mr-4'>
                                    <span>Order Created: </span>
                                    <span className='font-bold'>{orderMainView?.purchaseInfo?.orderCreator}</span>
                                </div>
                            }
                            {orderMainView?.purchaseInfo?.stockVat &&
                                <div className='mb-2 mr-4'>
                                    <span>Stock VAT: </span>
                                    <span className='font-bold'>{orderMainView?.purchaseInfo?.stockVat?.label || orderMainView?.purchaseInfo?.stockVat}</span>
                                </div>
                            }
                            {orderMainView?.market &&
                                <div className='mb-2 mr-4'>
                                    <span>Market: </span>
                                    <span className='font-bold'>{orderMainView?.market?.label}</span>
                                </div>
                            }
                            {orderMainView?.purchaseInfo?.warranty &&
                                <div className='mb-2 mr-4'>
                                    <span>Warranty(Days): </span>
                                    <span className='font-bold'>{orderMainView?.purchaseInfo?.warranty} days</span>
                                </div>
                            }
                            {orderMainView?.categories &&
                                <div className='mb-2 mr-4'>
                                    <span>Categories: </span>
                                    <span className='font-bold'>{orderMainView?.categories && orderMainView?.categories?.map((item: any, index: number) => `${index ? ', ' : ''}${item?.code}`)}</span>
                                </div>
                            }
                        </div>
                        <div className='grid grid-cols-3 gap-3'>
                            <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                                <div>
                                    <p className='mb-3'>Expected Items</p>
                                    <p className='mb-2 text-[#8a8a8e]'>{orderMainView?.categories && orderMainView?.categories?.map((item: any, index: number) => `${index ? ', ' : ''}${capitalizeFirstLetter(item?.code || '')}`)}</p>
                                </div>
                                <p className='text-5xl font-bold my-3'>{expectedItemsQty || 0}</p>
                                <div>
                                    <p className='text-[#8a8a8e]'>Expected Amount:</p>
                                    <p>{CURRENCY_SYMBOLS?.[`${(orderMainView?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}{Number(totalDeliveredAmount || 0).toFixed(2)}</p>
                                </div>
                            </div>
                            <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                                <div>
                                    <p className='mb-3'>Completed Items</p>
                                    <p className='mb-2 text-[#8a8a8e]'>Reviewed Items going to your Inventory</p>
                                </div>
                                <p className='text-5xl font-bold my-3 text-[#00b67a]'>{orderMainView?.completedItems?.length || 0}</p>
                                <div>
                                    <p className='text-[#8a8a8e]'>Completed Amount:</p>
                                    <p>{CURRENCY_SYMBOLS?.[`${(orderMainView?.completedItems?.[0]?.currency || '').toLowerCase()}`]}{Number(totalCompletedAmount || 0).toFixed(2)}</p>
                                </div>
                            </div>
                            <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                                <div>
                                    <p className='mb-3'>Discrepancy</p>
                                    <p className='mb-2 text-[#8a8a8e]'>Items that need actions</p>
                                </div>
                                <p className='text-5xl font-bold my-3 text-[#e50000]'>{orderMainView?.discrepancyItems?.length || 0}</p>
                                <div>
                                    <p className='text-[#8a8a8e]'>Credit Owed:</p>
                                    <p>{CURRENCY_SYMBOLS?.[`${(orderMainView?.discrepancyItems?.[0]?.currency || '').toLowerCase()}`]}{Number(totalCreditOwed || 0).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                        <div className=' border-b border-[#e4e4e4] pb-2 my-2'>
                            <Table
                                columns={['Item', 'Price per Unit', 'Units', 'Subtotal']}
                                hidePagination={true}
                                headerStyle='!bg-[#000000] !text-white'
                            >
                                {orderMainView?.expectedItems && orderMainView?.expectedItems?.length > 0 && orderMainView?.expectedItems?.map((row: any, idx: number) => {
                                    const findCategory = categories && categories?.length > 0 && categories?.find((item: any) => item?.value === row?.categoryId)
                                    const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                                    const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                                    const keys = [...keysDescriptor || [], ...keysInventory || []]
                                    return (
                                        <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                            <td className='px-6 py-4'>
                                                <div className='flex flex-wrap'>
                                                    <img src={findCategory?.enabledIcon} className={'h-[16px] min-h-[16px] object-contain'} />
                                                    {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                                        return (
                                                            <div key={index} className='flex items-center whitespace-nowrap'>
                                                                {(index !== 0) &&
                                                                    <div className='flex flex-row items-center ml-1 mr-1'>
                                                                        <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                    </div>
                                                                }
                                                                <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{row?.price || 0}</td>
                                            <td className='px-6 py-4'>{row?.qty || ''} unit(s)</td>
                                            <td className='px-6 py-4'>
                                                {CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}
                                                {(Number(row?.price || 0) * Number(row?.qty || 0)).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                {orderMainView?.expectedItems && orderMainView?.expectedItems?.length > 0 &&
                                    <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                                        <td></td>
                                        <td className='px-6 py-4'>Total:</td>
                                        <td className='px-6 py-4'>{findTotalQuantity} unit(s)</td>
                                        <td className='px-6 py-4'>
                                            {CURRENCY_SYMBOLS?.[`${(orderMainView?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}
                                            {Number(subPriceTotal || 0).toFixed(2)}
                                        </td>
                                    </tr>
                                }
                            </Table>
                            <div className='flex flex-row justify-center items-center self-center'>
                                <Button
                                    label={'Go Back'}
                                    className='btn-main min-w-[150px] mr-3 !rounded'
                                    onClickButton={() => onCloseModal()}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </Modal>
    )
}

export default MainOrderModal;