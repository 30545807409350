import React from 'react';
import { SALES_ORDER_STATUSES } from '../../../../utils/constants/sales-order-statuses';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-types';
import AddCustomerBtn from './AddCustomerBtn';
import Input from '../../../../shared/input';
import Datepicker from '../../../../shared/datepicker';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IFieldsStep {
    fieldsData?: any;
    salesFields?: any;
    partners?: any;
    formData?: any;
    formErrors?: any;
    brokerCustomers?: Array<any>;
    markets?: Array<any>;
    selectedMarket?: any;
    bankAccounts?: Array<any>;
    companies?: Array<any>;
    isPurchase?: boolean;
    isBatch?: boolean;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeDateInput: (value: string | null | undefined, type: string) => void;
    getBrokerCustomersAndBanks: () => void
}

const FieldsStep = ({
    fieldsData,
    salesFields,
    partners,
    brokerCustomers,
    formData,
    formErrors,
    markets,
    selectedMarket,
    bankAccounts,
    companies,
    isPurchase,
    isBatch,
    onChangeDateInput,
    onChangeInput,
    onChangeSelectedValue,
    getBrokerCustomersAndBanks
}: IFieldsStep) => {
    return (
        <div className='bg-white p-3 border-b-2 border-[#e4e4e4]'>
            <p className='font-bold mb-2'>Sale Details</p>
            <p className='mb-4'>Please fill details on the form below.</p>
            <div className={'grid grid-cols-4 gap-4'}>
                <SelectCheckbox
                    name='Broker'
                    placeholder={formData?.partner ? partners?.find((item: any) => item?.username === formData?.partner?.username)?.companyName || '' : ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={partners || []}
                    selectedOption={fieldsData?.partner}
                    uniqueName={'partner'}
                    disabled={isPurchase || false}
                    error={formErrors?.partner}
                />
                <div className='flex'>
                    <SelectCheckbox
                        name='Customer'
                        placeholder={formData?.customer?.displayName || ''}
                        onChangeSelectedOption={onChangeSelectedValue}
                        options={brokerCustomers || []}
                        selectedOption={fieldsData?.customer}
                        uniqueName={'customer'}
                        disabled={isPurchase || false}
                        error={formErrors?.customer}
                    />
                    <AddCustomerBtn getBrokerCustomersAndBanks={getBrokerCustomersAndBanks} />
                </div>
                <Datepicker
                    label={isBatch ? 'Due Date (Optional)' : 'Due Date'}
                    placeholder=' '
                    containerStyle='mb-0'
                    inputValue={formData?.dueDate}
                    onChangeInput={onChangeDateInput}
                    inputUniqueName={'dueDate'}
                    error={formErrors?.dueDate}
                />
                <SelectCheckbox
                    name='Status'
                    placeholder={formData?.status || ''}
                    disabled={true}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={SALES_ORDER_STATUSES || []}
                    selectedOption={fieldsData?.status}
                    uniqueName={''}
                    error={formErrors?.status}
                />
                <Input
                    label={'Order Creator'}
                    containerStyle='mb-0'
                    showValue={true}
                    disabled={true}
                    inputValue={formData?.orderCreator}
                    inputUniqueName={'orderCreator'}
                    error={formErrors?.orderCreator}
                />
                <SelectCheckbox
                    name='Stock VAT'
                    placeholder={formData?.stockVat || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={VAT_TYPE_ITEMS || []}
                    selectedOption={fieldsData?.stockVat}
                    uniqueName={'stockVat'}
                    error={formErrors?.stockVat}
                />
                <SelectCheckbox
                    name='Invoice VAT'
                    placeholder={formData?.invoiceVat || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={VAT_TYPE_ITEMS || []}
                    selectedOption={fieldsData?.invoiceVat}
                    uniqueName={'invoiceVat'}
                    error={formErrors?.invoiceVat}
                />
                <SelectCheckbox
                    name='Market'
                    placeholder={''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={markets || []}
                    selectedOption={selectedMarket}
                    uniqueName={'market'}
                    error={formErrors?.market}
                />
                <SelectCheckbox
                    name={isBatch ? 'Bank Account (Optional)' : 'Bank Account'}
                    placeholder={formData?.bankAccount?.displayName || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={bankAccounts || []}
                    selectedOption={fieldsData?.bankAccount}
                    uniqueName={'bankAccount'}
                    error={formErrors?.bankAccount}
                />
                <SelectCheckbox
                    name={isBatch ? 'Company (Optional)' : 'Company'}
                    placeholder={formData?.company?.displayName || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={companies || []}
                    selectedOption={fieldsData?.company}
                    uniqueName={'company'}
                    error={formErrors?.company}
                />
                <Input
                    label={isBatch ? 'Warranty (Days) (Optional)' : 'Warranty (Days)'}
                    containerStyle='mb-0'
                    showValue={true}
                    type={'number'}
                    inputValue={formData?.warranty}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'warranty'}
                    error={formErrors?.warranty}
                />
                {(salesFields && salesFields?.length > 0) && salesFields?.map((field: any, idx: number) => {
                    let values: any = field?.value;
                    if (field?.type === 'boolean') {
                        values = [
                            { value: true, label: 'True', name: 'True' },
                            { value: false, label: 'False', name: 'False' },
                        ];
                    } else if (field?.type === 'list') {
                        values =
                            field?.value &&
                            field?.value?.length > 0 &&
                            values?.map((value: string) => {
                                return { value: value, label: value, name: value };
                            });
                    }
                    return (
                        <>
                            {field?.type === 'number' ||
                                field?.type === 'string' ? (
                                <Input
                                    key={idx}
                                    containerStyle='mb-0'
                                    label={field?.name}
                                    placeholder={' '}
                                    showValue={true}
                                    type={field?.dataType}
                                    inputValue={formData?.[field?.name]}
                                    error={formErrors?.[field?.name] || ''}
                                    onChangeInput={onChangeInput}
                                    inputUniqueName={field?.name}
                                />
                            ) : (
                                <SelectCheckbox
                                    key={idx}
                                    name={field?.name && `${field?.name[0].toUpperCase()}${field?.name?.slice(1).toLowerCase()}`}
                                    placeholder={' '}
                                    containerStyle='mb-1'
                                    selectedOption={formData?.[field?.name] ? { label: formData?.[field?.name], value: formData?.[field?.name] } : undefined}
                                    onChangeSelectedOption={onChangeSelectedValue}
                                    uniqueName={field?.name}
                                    options={values}
                                    error={formErrors?.[field?.name] || ''}
                                />
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    )
}
export default FieldsStep;