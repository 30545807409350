import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

interface IPrintLabel {
    barcode: string;
    orderId: string;
}

const PrintLabel = ({
    barcode,
    orderId
}: IPrintLabel) => {
    const componentRef = React.useRef([]) as React.MutableRefObject<any>;

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.click();
        }
    }, [barcode]);

    return (
        <div className=''>
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <div className='max-w-[100%] flex flex-col justify-center print-label my-2 p-5'>
                        <Barcode value={barcode || 'null'} width={1.2} height={60} fontSize={14} displayValue={true} />
                        <div className='flex flex-row items-center my-5 min-w-[100%] mx-2'>
                            <p className='mr-3'>Quote ID: </p>
                            <p>{orderId}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <ReactToPrint
                    trigger={() => <button className='btn-primary-text-underline !text-[#000000]'>Label</button>}
                    content={() => componentRef.current}
                />
            </div>
        </div >
    )
}
export default PrintLabel