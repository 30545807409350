import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { IPurchaseContent } from '../../../../interfaces/purchases/IPurchaseContent';
import Table from '../../../../shared/table';


interface IPurchaseDetailsTable {
    showRevalued?: boolean;
    rows: IPurchaseContent[];
}

const PurchaseDetailsTable = ({
    rows,
    showRevalued
}: IPurchaseDetailsTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Item', 'Price', 'Nr of Expected Items', 'Expected Value', 'Delivered Items', 'Delivered Value'])

    useEffect(() => {
        if (showRevalued) {
            setTableColumns(['Item', 'Expected Price', 'Final Price', 'Nr of Expected Items', 'Expected Value', 'Delivered Items', 'Delivered Value'])
        }
    }, [rows, showRevalued])

    const totalExpectedItems = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + item?.expected, 0);
    const totalExpectedValue = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + (showRevalued ? (Number(item?.expected) * Number(item?.initialPrice >= 0 ? item?.initialPrice : item?.price)) : item?.totalExpected), 0);
    const totalDeliveredItems = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + item?.delivered, 0);
    const totalDeliveredValue = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + Number(item?.totalDelivered || 0), 0);
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}>
            {rows?.length > 0 && rows?.map((row: IPurchaseContent, idx: number) => {
                const color: any = 'bg-[#f8f0e6]'
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                return (
                    <tr key={idx} className={`${color} border-b`}>
                        <td className='px-6 py-4 max-w-[400px]'>
                            <div className='flex flex-wrap'>
                                <img src={row?.category?.icon} className={'h-[16px] min-h-[16px] object-contain'} />
                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                            {(index !== 0) &&
                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                </div>
                                            }
                                            <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                        {showRevalued && <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.initialPrice || row?.initialPrice === 0 ? row?.initialPrice : row?.price).toFixed(2)}</td>}
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.price || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{row?.expected}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{showRevalued ? ((Number(row?.expected) * Number(row?.initialPrice && row?.initialPrice >= 0 ? row?.initialPrice : row?.price))).toFixed(2) || 0 : Number(row?.totalExpected || 0)?.toFixed(2) || 0}</td>
                        <td className='px-6 py-4'>{row?.delivered || 0}{row?.rts ? `(${row?.rts})` : ''}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.totalDelivered || 0).toFixed(2)}</td>
                        {/* <td className='px-6 py-4'>{outstanding || 0}</td> */}
                    </tr>
                )
            })}
            {rows && rows?.length > 0 &&
                <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                    <td></td>
                    {showRevalued && <td></td>}
                    <td className='px-6 py-4'>Total:</td>
                    <td className='px-6 py-4'>{totalExpectedItems || 0}</td>
                    <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(rows?.[0]?.currency || '').toLowerCase()}`]}{Number(totalExpectedValue || 0).toFixed(2)}</td>
                    <td className='px-6 py-4'>{totalDeliveredItems || 0}</td>
                    <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(rows?.[0]?.currency || '').toLowerCase()}`]}{Number(totalDeliveredValue || 0).toFixed(2)}</td>
                    {/* <td></td> */}
                </tr>
            }
        </Table>
    )
}

export default PurchaseDetailsTable;