import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Table from '../../../../shared/table';

const tableColumns = ['Nr.', 'Expected Item to be reserved', 'Price per item', 'Quantity', 'Sales Value', 'Shipped Items', 'Shipped Value']

interface IShippedTable {
    rows: any[];
    categories?: Array<any>;
    selectedItems?: any;
    templateFields?: any;
}

const ShippedTable = ({
    rows,
    categories,
    selectedItems,
    templateFields,
}: IShippedTable) => {
    const [formatRows, setFormatRows] = useState<any>();
    const [hideDetails, setHideDetails] = useState<any>();

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const categoryFields = category && templateFields?.[category]
        const formatObj1: any = obj1
        let formatObj2: any = obj2
        if (categoryFields && category) {
            formatObj2 = {}
            const moreKeys: any = obj1 && (Object?.keys(obj1))?.filter((item: any, index: number) => !(categoryFields?.find((field: any) => field?.name === item)))
            obj2 && Object.keys(obj2)?.map((key: any, index: number) => {
                const findInCategories = categoryFields?.find((field: any) => field?.name === key)
                const findInMoreKeys = moreKeys && moreKeys?.length > 0 && moreKeys?.find((obj: any) => obj === key)
                if (findInCategories || findInMoreKeys) {
                    formatObj2[key] = obj2[key];
                }
                return key
            }, {});
        }
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    useEffect(() => {
        if (selectedItems && rows) {
            const newRows: any = [...rows || []]
            const findAllExtra = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((selected: any) => (!selected?.match || Object?.keys(selected?.match)?.length <= 0))
            if (findAllExtra && findAllExtra?.length > 0) {
                findAllExtra?.forEach((item: any) => {
                    newRows.push(
                        {
                            item: item,
                            descriptor: item?.descriptor,
                            inventory: item?.inventory,
                            currency: rows?.[0]?.currency || undefined,
                            price: 0,
                            extra: true,
                            qty: 1
                        }
                    )
                })
            }
            setFormatRows(newRows)
        }
    }, [rows, selectedItems])

    const onClickHideShowItem = (index: number, type: string) => {
        setHideDetails({
            ...hideDetails || {},
            [index]: {
                ...hideDetails?.[index] || {},
                [type]: !hideDetails?.[index]?.[type]
            }
        })
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'>
            {(formatRows && formatRows?.length > 0) && formatRows?.map((row: any, idx: number) => {
                const totalPrice = (row?.price) * (row?.qty || 0)
                const findCategory = categories && categories?.length > 0 && categories?.find((item: any) => item?.id === row?.categoryId)
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                const filterSelectedItems = (selectedItems && selectedItems?.length > 0 && !row?.extra) && selectedItems?.filter((item: any) => row?.descriptor && item?.match ? areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)) : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor)
                const totalSelectedItemsPrice = filterSelectedItems && filterSelectedItems?.length > 0 && filterSelectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                return (
                    <>
                        <tr key={idx} className={`border-b hover:bg-gray-50 my-1 ${row?.extra ? '!bg-[#e7f3fc]' : '!bg-[#fef6e8]'}`}>
                            <td className='pl-6 py-4 text-black'>{idx + 1}</td>
                            <td className='pl-3 py-4 min-w-[400px]'>
                                <div className='flex flex-wrap'>
                                    {row?.descriptor ?
                                        <>
                                            <img src={findCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                            {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                                return (
                                                    <div key={index} className='flex items-center whitespace-nowrap'>
                                                        {(index !== 0) &&
                                                            <div className='flex flex-row items-center ml-1'>
                                                                <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                            </div>
                                                        }
                                                        <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : <p>Extra Items</p>
                                    }
                                </div>
                            </td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.price || 0).toFixed(2)}</td>
                            <td className='px-6 py-4'>{row?.qty || 0}</td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(totalPrice || 0).toFixed(2)}</td>
                            <td className='px-6 py-4'>{filterSelectedItems?.length || 0}</td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(totalSelectedItemsPrice || 0).toFixed(2)}</td>
                        </tr>
                        {(!hideDetails?.[idx]?.row && (filterSelectedItems && filterSelectedItems?.length > 0)) &&
                            <tr className='bg-[#f8f8f8] shadow my-3'>
                                <td colSpan={(tableColumns?.length) || 5} className='px-4 py-3 border-r border-slate-100'>
                                    <table className='w-full p-3'>
                                        <tbody>
                                            {(filterSelectedItems && filterSelectedItems?.length > 0) &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'processed')}>
                                                            <p className='text-[#00b67a]'>Processed ({filterSelectedItems?.length || 0} of {row?.qty})</p>
                                                            <img src={'/assets/table-actions/matching-array.svg'} className={`object-contain h-[16px] ml-3 ${hideDetails?.[idx]?.processed ? 'rotate-[-90deg]' : 'rotate-0'}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {(!hideDetails?.[idx]?.processed && (filterSelectedItems && filterSelectedItems?.length > 0)) && filterSelectedItems?.map((item: any, idx: number) => {
                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                return (
                                                    <tr key={idx} className='border-b bg-[#e5f8f2]'>
                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                    return (
                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                            {(index !== 0) &&
                                                                                <div className='flex flex-row items-center ml-1'>
                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                </div>
                                                                            }
                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                            <div>
                                                                <p className='font-bold'>{(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI:' : 'Item ID:'}</p>
                                                                <p>{item?.itemId}</p>
                                                            </div>
                                                        </td>
                                                        {(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ?
                                                            <td className='py-2 px-2'>
                                                                <p className='font-bold'>IMEI Status:</p>
                                                                <p>{item?.imeiStatus}</p>
                                                            </td>
                                                            : <td className='py-2 px-2'>
                                                            </td>
                                                        }
                                                        <td className='py-2 px-2'>
                                                            <p className='font-bold'>Location:</p>
                                                            <p>
                                                                {item?.newLocation ?
                                                                    item?.newLocation
                                                                    : <>
                                                                        {item?.locations && (Object?.keys(item?.locations))?.map((obj: any, index: number) => {
                                                                            return index === 0 ? item?.locations?.[obj] : ` - ${item?.locations?.[obj]}`
                                                                        })}
                                                                    </>
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr >
                        }
                    </>
                )
            })}
        </Table>
    )
}

export default ShippedTable;