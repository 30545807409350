import React from 'react';

interface ISwitch {
    checked: boolean;
    onToggleSwitch: () => void;
    beforeText?: string;
    afterText?: string;
}

const Switch = ({ checked, beforeText, afterText, onToggleSwitch }: ISwitch) => {
    return (
        <div className='flex flex-row items-center'>
            {beforeText && <span className='mr-3 text-sm font-medium text-black'>{beforeText}</span>}
            <div onClick={onToggleSwitch} className='inline-flex relative items-center cursor-pointer'>
                <input type='checkbox' value='' className='sr-only peer' checked={checked} />
                <div className='w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[#F7A21E]-300 dark:peer-focus:ring-ring-[#F7A21E]-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F7A21E]'></div>
            </div>
            {afterText && <span className='ml-3 text-sm font-medium text-black'>{afterText}</span>}
        </div>
    );
};

export default Switch;
