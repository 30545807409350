import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SaleRequestsService } from './saleRequestsApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { AxiosError } from 'axios';
import { ISaleRequestList } from '../../../../interfaces/sale-requests/ISaleRequestList';


const initialState: ISaleRequestList = {
    saleRequestsAreLoading: false,
    saleRequestIsLoading: false,
    saleNotesAreLoading: true,
    mainOrderLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchSaleRequests = createAsyncThunk<any, IPaginationPayload>(
    'saleRequests/tryToFetchSaleRequests',
    async ({ pageNumber, pageSize, data }) => {
        const result = await SaleRequestsService.tryToFetchSaleRequests(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    }
);

export const tryToFetchSingleSaleRequest = createAsyncThunk<any, string>(
    'saleRequests/tryToFetchSingleSaleRequest',
    async (id: string) => {
        const result = await SaleRequestsService.tryToFetchSingleSaleRequest(id);
        return result?.data;
    },
);

export const tryToFetchAllSaleRequest = createAsyncThunk<any, any>(
    'saleRequests/tryToFetchAllSaleRequest',
    async (data: any) => {
        const result = await SaleRequestsService.tryToFetchAllSaleRequest(data);
        return result?.data?.data;
    },
);

export const tryToAddSaleRequestNote = createAsyncThunk<any, any>(
    'saleRequests/tryToAddSaleRequestNote',
    async ({ id, data }) => {
        const result = await SaleRequestsService.tryToAddSaleRequestNote(id, data);
        return result?.data;
    },
);

export const tryToFetchSaleRequestNote = createAsyncThunk<any, string>(
    'saleRequests/tryToFetchSaleRequestNote',
    async (id: string) => {
        const result = await SaleRequestsService.tryToFetchSaleRequestNote(id);
        return result?.data;
    },
);

export const tryToSubmitSaleRequestOffer = createAsyncThunk<any, { id: string, status: string, data?: any }>(
    'purchaseOrders/tryToSubmitSaleRequestOffer',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await SaleRequestsService.tryToSubmitSaleRequestOffer(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const saleRequestsSlice = createSlice({
    name: 'saleRequests',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSaleRequests
            .addCase(tryToFetchSaleRequests.pending, (state) => {
                state.saleRequestsAreLoading = true;
            })
            .addCase(tryToFetchSaleRequests.rejected, (state) => {
                state.saleRequestsAreLoading = false;
            })
            .addCase(tryToFetchSaleRequests.fulfilled, (state, action) => {
                state.saleRequestsAreLoading = false;
                state.saleRequests = action.payload;
            })

            // tryToFetchSingleSaleRequest
            .addCase(tryToFetchSingleSaleRequest.pending, (state) => {
                state.saleRequestIsLoading = true;
            })
            .addCase(tryToFetchSingleSaleRequest.rejected, (state) => {
                state.saleRequestIsLoading = false;
            })
            .addCase(tryToFetchSingleSaleRequest.fulfilled, (state, action) => {
                state.saleRequestIsLoading = false;
                state.saleRequestDetails = action.payload.data;
            })

            // tryToFetchSaleRequestNote
            .addCase(tryToFetchSaleRequestNote.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToFetchSaleRequestNote.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToFetchSaleRequestNote.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                state.saleRequestNotes = action.payload.data;
            })

            // tryToAddSaleRequestNote
            .addCase(tryToAddSaleRequestNote.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToAddSaleRequestNote.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToAddSaleRequestNote.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                state.saleRequestNotes = action.payload?.data;
            })

            // tryToSubmitSaleRequestOffer
            .addCase(tryToSubmitSaleRequestOffer.pending, (state) => {
                state.saleRequestsAreLoading = true;
            })
            .addCase(tryToSubmitSaleRequestOffer.rejected, (state) => {
                state.saleRequestsAreLoading = false;
            })
            .addCase(tryToSubmitSaleRequestOffer.fulfilled, (state, action) => {
                state.saleRequestsAreLoading = false;
                state.requestStatus = 'success';
                if (state.saleRequests?.data.elements)
                    state.saleRequests.data.elements =
                        { ...state }.saleRequests?.data.elements.map((sale) => {
                            if (sale?._id === action.meta.arg?.id) {
                                return {
                                    ...sale || {},
                                    status: action.meta.arg?.status,
                                    totalOfferPrice: action?.meta?.arg?.data?.totalOfferPrice || action?.payload?.data?.totalOfferPrice,
                                    offers: action?.payload?.data?.offers,
                                }
                            } else {
                                return sale
                            }
                        }
                        ) || [];
            })
    },
});

export default saleRequestsSlice.reducer;
