import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { onFormatSalesStatus } from '../../../utils/constants/sale-details-statuses';
import Button from '../../../shared/button';


interface ISaleInfo {
    saleDetails: any;
    onClickGoToSale: () => void;
}

const SaleInfo = ({
    saleDetails,
    onClickGoToSale,
}: ISaleInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatSalesStatus(saleDetails?.status)?.name
        const color = onFormatSalesStatus(saleDetails?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatSalesStatus(saleDetails?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text,
        })
    }, [saleDetails?.status])

    return (
        <div className='bg-[#f8f9fc] rounded-[6px] p-3 border-y border-[#e4e4e4] my-6'>
            <p className='text-[20px]'>Sales Order Details</p>
            <div className='grid grid-cols-4 span-2 mt-2 mb-6 pb-2 gap-3'>
                <div className={`${orderStatusDetails?.color} !border rounded-md ${orderStatusDetails?.text} text-center py-1 px-3 mb-4 font-semibold`}>
                    {orderStatusDetails?.name}
                </div>
                {saleDetails?.quoteId &&
                    <div className='mb-4 flex flex-row items-center'>
                        <span>Quote Id: </span>
                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(saleDetails?.quoteId)}>
                            <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                            <p className='text-[14px] font-semibold'>{saleDetails?.quoteId}</p>
                        </div>
                    </div>
                }
                <div className='mb-4'>
                    <span>Source: </span>
                    <span className='font-bold'>{saleDetails?.purchaseRequestId ? 'wingpro' : 'IMS'}</span>
                </div>
                {saleDetails?.salesInfo?.customer &&
                    <div className='mb-4'>
                        <span>Customer: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.customer?.name}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.partner?.companyName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Broker</p>
                        <p className='text-[14px]'>{saleDetails?.salesInfo?.partner?.companyName}</p>
                    </div>
                }
                {saleDetails?.salesInfo?.dueDate &&
                    <div className='mb-4'>
                        <span>Due Date: </span>
                        <span className='font-bold'>{moment(saleDetails?.salesInfo?.dueDate).format('DD MMMM YYYY')}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.orderCreator &&
                    <div className='mb-4'>
                        <span>Order Created: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.orderCreator}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.company &&
                    <div className='mb-4'>
                        <span>Company: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.company?.name}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.bankAccount &&
                    <div className='mb-4'>
                        <span>Bank Account: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.bankAccount?.name}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.invoiceNr &&
                    <div className='mb-4'>
                        <span>Invoice Number: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.invoiceNr}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.stockVat &&
                    <div className='mb-4'>
                        <span>Stock VAT: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.stockVat?.label || saleDetails?.salesInfo?.stockVat}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.invoiceVat &&
                    <div className='mb-4'>
                        <span>Invoice VAT: </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.invoiceVat?.label || saleDetails?.salesInfo?.invoiceVat}</span>
                    </div>
                }
                {saleDetails?.salesInfo?.warranty &&
                    <div className='mb-4'>
                        <span>Warranty(Days): </span>
                        <span className='font-bold'>{saleDetails?.salesInfo?.warranty} days</span>
                    </div>
                }
                {saleDetails?.itemInfo?.type &&
                    <div className='mb-4'>
                        <span>Item Status: </span>
                        <span className='font-bold'>{saleDetails?.itemInfo?.type}</span>
                    </div>
                }
            </div>
            <div className='mt-5 mb-10 flex flex-row justify-center items-center'>
                <Button
                    label={'Go To Sales Order Details'}
                    className='btn-main'
                    onClickButton={onClickGoToSale}
                />
            </div>
        </div>
    )
}
export default SaleInfo;