import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IAcceptPurchaseModal {
    openModal: boolean;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const AcceptPurchaseModal = ({
    openModal,
    loading,
    handleCloseModal,
    onApproveAction,
}: IAcceptPurchaseModal) => {
    return (
        <Modal
            open={openModal}
            onClose={() => handleCloseModal()}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Approved Purchase</p>
                </div>
                <p className='my-2'>Are you sure you Approve the Purchase Selected?</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        dataQa={'continue-button'}
                        className={loading ? 'btn-primary-disable' : 'btn-primary'}
                        onClickButton={() => !loading && onApproveAction()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default AcceptPurchaseModal;