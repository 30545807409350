import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToGetCredit } from '../../../../store/inventory/broker/credit-managment/creditManagmentSlice';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Customer Name', 'Initial Balance', 'Outstanding Credit', 'Expiry Date', 'Actions']

interface ICreditManagmentTable {
    search?: string;
    loading: boolean;
    loadingTransaction: boolean;
    onSeeCreditManagmentDetails: (id: string) => void
    onClickAction: (type: string, id: string) => void
}

const CreditManagmentTable = ({
    search,
    loading,
    loadingTransaction,
    onClickAction,
    onSeeCreditManagmentDetails,
}: ICreditManagmentTable) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([])
    const [paginationData, setPaginationData] = useState()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const getCredits = async () => {
        try {
            const data = await dispatch(tryToGetCredit({ ...paginationState, search: `${search || ''}` })).unwrap()
            setPaginationData(data?.page)
            setRows(data?.elements)
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            if (search) {
                searchParams.set('search', `${search}`)
            } else {
                searchParams.delete('search')
            }

            setSearchParams(searchParams)
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!loading || !loadingTransaction) {
            getCredits()
        }
    }, [paginationState, search, loading, loadingTransaction])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                const currency = CURRENCY_SYMBOLS?.[`${(row?.currency || '')}`]
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.customerInfo?.name}</td>
                        <td className='px-6 py-4'>{currency}{(row?.initialBalance || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{currency}{(row?.outStandingCredit || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{row?.expiryDate ? moment(row?.expiryDate).format('DD-MM-YYYY') : ''}</td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div>
                                    <img title={'See Credit Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mb-3 object-contain cursor-pointer`} onClick={() => { onSeeCreditManagmentDetails(row?._id) }} />
                                </div>
                                <TableActions>
                                    <div
                                        onClick={() => { onSeeCreditManagmentDetails(row?._id) }}
                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                        role='none'>
                                        <Button
                                            label={'View Details'}
                                            dataQa={'view-details'}
                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] cursor-pointer`}
                                        />
                                    </div>
                                    <div
                                        onClick={() => { onClickAction('deposit', row) }}
                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                        role='none'>
                                        <Button
                                            label={'Deposit'}
                                            dataQa={'deposit'}
                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] cursor-pointer`}
                                        />
                                    </div>
                                    <div
                                        onClick={() => { onClickAction('withdraw', row) }}
                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                        role='none'>
                                        <Button
                                            label={'Withdraw'}
                                            dataQa={'withdraw'}
                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] cursor-pointer`}
                                        />
                                    </div>
                                </TableActions>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default CreditManagmentTable;