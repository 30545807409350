import { AxiosResponse } from 'axios';
import { ASSETS_API_URL, INVENTORY_API_URL } from '../../../../config';
import { IPurchase } from '../../../../interfaces/purchases/IPurchase';
import { PaginatedPurchases } from '../../../../interfaces/purchases/paginatedStore.type';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import assetsAxios from '../../../../utils/axios/assets.axios';

const tryToFetchPurchases = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPurchases>> => {
    const url = `${INVENTORY_API_URL}/purchases?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedPurchases>(url, data);
};

const tryToFetchSinglePurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/${id}`);
};

const tryToEditPurchase = (id: string, data: IPurchase): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}`, data);
};

const tryToCreatePurchase = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_API_URL}/purchases`, data);
};

const tryToAddPurchaseNote = (id: string, data: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/notes`, data);
};

const tryToFetchPurchaseNote = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/${id}/notes`);
};

const tryToSplitPurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/split`);
};

const tryToDeletePurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.delete(`${INVENTORY_API_URL}/purchases/${id}`);
};

const tryToReviewPurchase = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/review`, data)
}

const tryToFetchPurchaseContent = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/${id}/content`)
}

const tryToAddPurchasePackages = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/add-packages`, data)
}

const tryToFetchPurchasePackages = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/${id}/packages`)
}

const tryToAcceptPurchase = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/accepted`)
}

const tryToPayPurchase = (id: string, dueDate?: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${id}/on-delivery`, { dueDate: dueDate })
}

const tryToGetPurchaseUuid = (): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/generate/uuid`)
}

const tryToFetchMainOrder = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/purchases/${id}/main-view`)
}

const tryToAcceptPurchaseItem = (purchaseId: string, itemId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/item/${itemId}/accept`)
}

const tryToAwaitCreditPurchaseItem = (purchaseId: string, item: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/item/awaiting-credit`, item)
}

const tryToRevaluePurchaseItem = (purchaseId: string, itemId: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/item/${itemId}/revalue`, data)
}

const tryToReturnPurchaseItem = (purchaseId: string, itemId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/item/${itemId}/return`)
}

const tryToMatchPurchaseItem = (purchaseId: string, itemId: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/item/${itemId}/match`, data)
}

const tryToReturnAllPurchase = (purchaseId: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/return`, data)
}

const tryToFinalizePurchaseReview = (purchaseId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/finalize-review`)
}

const tryToValidatedImportedItems = (assetTemplateId: string, data: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/values/validate`, data)
}

const tryToChangePurchaseStatus = (purchaseId: string, status: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/${purchaseId}/${status}`, data)
}

const tryToFetchAllPurchases = (data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/purchases/all`, data);
};

export const PurchasesService = {
    tryToFetchPurchases,
    tryToFetchSinglePurchase,
    tryToFetchPurchaseContent,
    tryToEditPurchase,
    tryToCreatePurchase,
    tryToAddPurchaseNote,
    tryToFetchPurchaseNote,
    tryToDeletePurchase,
    tryToReviewPurchase,
    tryToFetchPurchasePackages,
    tryToAddPurchasePackages,
    tryToAcceptPurchase,
    tryToPayPurchase,
    tryToSplitPurchase,
    tryToAcceptPurchaseItem,
    tryToRevaluePurchaseItem,
    tryToReturnPurchaseItem,
    tryToMatchPurchaseItem,
    tryToReturnAllPurchase,
    tryToGetPurchaseUuid,
    tryToValidatedImportedItems,
    tryToAwaitCreditPurchaseItem,
    tryToFinalizePurchaseReview,
    tryToFetchMainOrder,
    tryToChangePurchaseStatus,
    tryToFetchAllPurchases
};