import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToDownloadInventorySample } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
 

interface IDownloadSampleInventoryModal {
    openDownloadModal: boolean;
    sectors: Array<any>;
    handleCloseDownloadModal: () => void;
}

const DownloadSampleInventoryModal = ({
    openDownloadModal,
    sectors,
    handleCloseDownloadModal,
}: IDownloadSampleInventoryModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [selectedSector, setSelectedSector] = useState<any>()
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [inventoryTemplateData, setInventoryTemplateData] = useState<Array<any>>()

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e)
    }

    const onCloseModal = () => {
        handleCloseDownloadModal();
        setSelectedSector(undefined)
        setDownloadError(undefined)
        setLoadingDownload(false)
        setInventoryTemplateData(undefined)
    }

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        if (!selectedSector) {
            setDownloadError('Please select a sector first to continue with download.')
            return;
        }
        try {
            const formatData = {
                assetTemplateId: selectedSector?.id || '',
            }
            const response = await dispatch(tryToDownloadInventorySample(formatData)).unwrap()
            setInventoryTemplateData(response || [])
            setDownloadError(undefined)
        } catch (err: any) {
            setDownloadError(`${err?.response || err?.data?.message || err}`)
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (inventoryTemplateData && loadingDownload) {
            csvLink.current.link.click()
            onCloseModal()
        }
        setLoadingDownload(false)
    }, [inventoryTemplateData])

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Inventory Template</p>
                <p className='mb-4 text-sm'>Please select a category to download inventory template.</p>
                {downloadError && <Error text={downloadError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        placeholder='Category'
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedSector}
                        onChangeSelectedOption={onChangeSelectedSector}
                        options={sectors || []}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Download Inventory Template'}
                        onClickButton={onDownloadTemplate}
                        className={'btn-primary-rounded-less my-3 !py-2 !shadow-none'}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={[]}
                        headers={inventoryTemplateData && inventoryTemplateData?.length > 0 ? inventoryTemplateData?.[0] : []}
                        className={'none'}
                        target={'_blank'}
                        filename={`Inventory-${sectors?.find((sector) => sector?.value === selectedSector)?.label || 'catalog'}-data.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
        </Modal >
    )
}
export default DownloadSampleInventoryModal;