import React from 'react';
import { PURCHASE_ORDER_STATUS } from '../../../../utils/constants/purchase-order-status';
import Button from '../../../../shared/button';


interface IPurchasesFilters {
    selectedOrder?: string;
    selectedCompany?: string;
    companyValues: any;
    categories?: any;
    selectedCategory?: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onClickCategory: (value: any) => void;
}

const PurchasesFilters = ({
    selectedOrder,
    selectedCompany,
    companyValues,
    categories,
    selectedCategory,
    onClickCategory,
    onChangeSelectedValue,
}: IPurchasesFilters) => {
    return (
        <div>
            <div className='mb-4'>
                <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Categories</p>
                <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4'>
                    <Button
                        label={<span className={`${selectedCategory?.value === 'allCategories' ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>All Categories</span>}
                        dataQa={'all-categories'}
                        className={`${selectedCategory?.value === 'allCategories' ? 'btn-categories-list' : 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0]'} !min-w-[70px]  !rounded-[6px] text-sm`}
                        onClickButton={() => onClickCategory({ value: 'allCategories', label: 'All Categories' })}
                    />
                    {categories && categories?.length > 0 && categories?.map((item: any, index: number) => {
                        let buttonClassName = 'btn-noactive-categories border'
                        if (selectedCategory?.value === item?.value) buttonClassName = 'btn-categories-list !rounded-[6px]';
                        else buttonClassName = 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0] !rounded-[6px]';
                        return (
                            <Button
                                key={index}
                                label={item?.label}
                                className={`${buttonClassName} !min-w-[70px] text-sm`}
                                onClickButton={() => onClickCategory(item)}
                                dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                                icon={item.enabledIcon ? <div className={'w-[23px] h-[23px]'}><img src={selectedCategory?.value === item?.value ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} /></div> : ''}
                            />)
                    })}
                </div>
            </div>
            <div className='min-w-full'>
                <div className='flex flex-row items-center'>
                    <p className='text-[#202020] text-sm font-bold ml-2 mr-4'>Filter</p>
                    <div className='flex flex-row items-center flex-wrap'>
                        {(PURCHASE_ORDER_STATUS && PURCHASE_ORDER_STATUS?.length > 0) && PURCHASE_ORDER_STATUS?.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    onClickButton={() => onChangeSelectedValue(item?.value, 'order')}
                                    label={item?.name}
                                    className={`${selectedOrder === item?.value ? 'btn-filters' : 'btn-noactive-filters !text-[#a0a0a0] !border-[#a0a0a0]'} mr-2 !font-medium mb-2`}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchasesFilters;