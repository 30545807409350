import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISales } from '../../../../interfaces/sales/ISales';
import { tryToEditSaleOrderStatuses } from '../../../../store/inventory/shared/sales/salesSlice';
import ReservedLocationsModal from '../modals/ReservedLocationsModal';
import ScanItemsModal from '../modals/ScanItemsModal';
import RequestModal from '../modals/RequestModal';
import SplitModal from '../modals/SplitModal';
import ProcessingTable from '../tables/ProcessingTable';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IProcessingStatus {
    saleDetails?: ISales;
    saleId: string;
    accessControl?: any;
    pageAccess?: any;
    salesContent?: any;
    saleCategories?: Array<any>;
    refreshStockLoading?: boolean;
    templateFields?: any;
    onClickRefreshStock: () => void;
    onGettingPurchaseData: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const ProcessingStatus = ({
    saleDetails,
    saleId,
    accessControl,
    pageAccess,
    templateFields,
    salesContent,
    saleCategories,
    refreshStockLoading,
    onClickRefreshStock,
    onGettingPurchaseData,
    onSuccessfullyChanged
}: IProcessingStatus) => {
    const dispatch = useAppDispatch();
    const [locationsModal, setLocationsModal] = useState<boolean>();
    const [requestedItems, setRequestedItems] = useState<any>();
    const [selectedItems, setSelectedItems] = useState<any>();
    const [showRequestModal, setShowRequestModal] = useState<{ show: boolean, data?: any, requestedItem?: any, missingItems?: any }>();
    const [showScanModal, setShowScanModal] = useState<{ show: boolean, data?: any }>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [generalError, setGeneralError] = useState<string>();
    const [showSplitModal, setShowSplitModal] = useState<boolean>();
    const totalItems = saleDetails?.saleItems && saleDetails?.saleItems?.length > 0 && saleDetails?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + currentValue?.qty, 0)
    const selectedItemsWithoutExtra = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.match)?.length || 0
    const selectedItemsWithExtra = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => !item?.match)?.length || 0
    const findNotConfirmed = selectedItems && selectedItems?.length > 0 && selectedItems?.find((item: any) => item?.status === 'rejected' && !item?.confirm_reject)
    const notMatchingItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'not_matching')
    const matchingItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'matching' || item?.status === 'accepted')
    const findConfirmedRejected = selectedItems?.length > 0 && selectedItems?.filter((item: any) => item?.match && item?.status === 'rejected' && item?.confirm_reject)

    const onClickShowSplitModal = () => {
        setShowSplitModal(true)
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (saleDetails) {
            setRequestedItems(saleDetails?.requestedItems)
            setSelectedItems(saleDetails?.selectedItems)
        }
    }, [saleDetails])

    const onClickShowScanModal = (data?: any) => {
        document.body.style.overflow = 'hidden';
        setShowScanModal({
            show: true,
            data: data
        })
    }

    const onHideSplitModal = () => {
        setShowSplitModal(false)
        document.body.style.overflow = 'auto';
    }

    const onClickShowRequestModal = (data?: any, requestedItems?: any, missingItems?: number) => {
        document.body.style.overflow = 'hidden';
        setShowRequestModal({
            show: true,
            data: data,
            requestedItem: requestedItems,
            missingItems: missingItems
        })
    }

    const onHideModals = () => {
        setShowRequestModal(undefined)
        setShowScanModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const onToggleLocationsModal = () => {
        if (locationsModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setLocationsModal(!locationsModal)
    }

    const onClickConfirmReject = (mainItem: any, itemRejected: any) => {
        const formatSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.map((item: any) => {
            const findIfItem = item?.itemId === itemRejected?.itemId
            if (findIfItem) {
                return {
                    ...item,
                    confirm_reject: true
                }
            }
            else return item
        })
        setSelectedItems(formatSelectedItems || [])
    }

    const onApproveRequestItems = (data: any, oldRequestedItems: any) => {
        const filterRequestedItems: any = [];
        (requestedItems && requestedItems?.length > 0) && requestedItems?.forEach((item: any) => {
            const findOldRequested = (oldRequestedItems && oldRequestedItems?.length > 0) && oldRequestedItems?.find((old: any) => JSON.stringify(old) === JSON.stringify(item))
            if (!findOldRequested) {
                filterRequestedItems?.push(item)
            }
        })
        setRequestedItems([
            ...filterRequestedItems || [],
            ...data || []
        ])
    }

    const onFindSaleStatus = (missingItems?: any) => {
        if (requestedItems && requestedItems?.length > 0) {
            return 'review';
        }
        if (notMatchingItems && notMatchingItems?.length > 0) {
            return 'review'
        }
        return 'processed'
    }

    const onValidateSubmit = async (isConfirm: boolean) => {
        setLoadingSubmit(true)
        const totalRequestedItems = requestedItems?.length || 0
        const findAnyMissingItems = (totalItems + (findConfirmedRejected?.length || 0)) - (totalRequestedItems + selectedItemsWithoutExtra)
        if (isConfirm && findAnyMissingItems && (selectedItemsWithoutExtra || selectedItemsWithExtra) && !totalRequestedItems) {
            onClickShowSplitModal()
            setLoadingSubmit(false)
            setGeneralError(undefined)
            return;
        }
        setGeneralError(undefined)
        const status = isConfirm ? onFindSaleStatus(findAnyMissingItems) : 'processing'
        try {
            const reformatSelectedItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status !== 'rejected')
            let formatData: any = {
                ...saleDetails || {},
                requestedItems: requestedItems,
                selectedItems: reformatSelectedItems,
                completedReviewed: true,
                completedProcessing: true,
                status: status
            }
            if (isConfirm) {
                formatData = {
                    ...formatData || {},
                    completed: true
                }
            }
            await dispatch(tryToEditSaleOrderStatuses({ id: saleId, data: formatData })).unwrap();
            onGettingPurchaseData();
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onAddScannedItem = (data: any) => {
        setSelectedItems((prevSelectedItems: any) => [
            ...prevSelectedItems || [],
            data || {}
        ])
    }

    const onCheckBeforeRefresh = () => {
        onClickRefreshStock()
    }

    const onDeleteItemScanned = (itemId: string) => {
        const filterSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.filter((item: any) => item?.itemId !== itemId)
        setSelectedItems(filterSelectedItems || [])
    }

    return (
        <div className='my-5'>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Scan Items'}
                    className={`btn-primary !rounded !shadow-none mr-3`}
                    onClickButton={() => !refreshStockLoading && onClickShowScanModal()}
                />
                <Button
                    label={'See Locations'}
                    className={`${(!accessControl || pageAccess?.actions?.['See Locations']) ? 'btn-main' : 'btn-primary-disable'} !rounded mr-3`}
                    onClickButton={() => !refreshStockLoading && (!accessControl || pageAccess?.actions?.['See Locations']) && onToggleLocationsModal()}
                />
                <Button
                    label={'Refresh Stock'}
                    className={`${!refreshStockLoading && (!accessControl || pageAccess?.actions?.['Refresh Stock']) ? 'btn-main' : 'btn-primary-disable'} !rounded`}
                    onClickButton={() => !refreshStockLoading && (!accessControl || pageAccess?.actions?.['Refresh Stock']) && onCheckBeforeRefresh()}
                />
            </div>
            <div className='bg-white !rounded my-3 pb-4'>
                <p className='font-semibold text-[18px] p-3'>Items</p>
                <ProcessingTable
                    rows={saleDetails?.saleItems}
                    selectedItems={selectedItems}
                    categories={saleCategories}
                    salesContent={salesContent}
                    accessControl={accessControl}
                    pageAccess={pageAccess}
                    requestedItems={requestedItems}
                    reservedItems={saleDetails?.reservedItems}
                    templateFields={templateFields}
                    onScanItem={onClickShowScanModal}
                    onDeleteItem={onDeleteItemScanned}
                    onRequestItem={onClickShowRequestModal}
                    onClickConfirmReject={onClickConfirmReject}
                />
                {generalError && <div className='my-3'>
                    <Error text={generalError} />
                </div>
                }
                <div className='my-4 flex flex-row items-center border-t pt-4 mx-5'>
                    <Button
                        label={'Save for now'}
                        className={`${!loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Processing']) ? 'btn-main' : 'btn-primary-disable'} !rounded mr-3`}
                        onClickButton={() => !loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Processing']) && onValidateSubmit(false)}
                    />
                    <Button
                        label={'Confirm'}
                        className={`${(!loadingSubmit && !findNotConfirmed) && (!accessControl || pageAccess?.actions?.['Confirm Processing']) ? 'btn-primary' : 'btn-primary-disable'} !rounded !shadow-none`}
                        onClickButton={() => (!loadingSubmit && !findNotConfirmed) && (!accessControl || pageAccess?.actions?.['Confirm Processing']) && onValidateSubmit(true)}
                    />
                </div>
            </div>
            {locationsModal &&
                <ReservedLocationsModal
                    reservedItems={saleDetails?.reservedItems}
                    categories={saleCategories}
                    openModal={locationsModal}
                    handleCloseModal={onToggleLocationsModal}
                />
            }
            {showScanModal?.show &&
                <ScanItemsModal
                    openModal={showScanModal?.show}
                    rowSelected={showScanModal?.data}
                    categories={saleCategories}
                    reservedItems={saleDetails?.reservedItems}
                    handleCloseModal={onHideModals}
                    onAddScannedItem={onAddScannedItem}
                />
            }
            {showRequestModal?.show &&
                <RequestModal
                    openModal={showRequestModal?.show}
                    saleId={saleId || ''}
                    prevRequestedItems={showRequestModal?.requestedItem}
                    review={false}
                    missingItems={showRequestModal?.missingItems}
                    categories={saleCategories}
                    itemDetailsInfo={showRequestModal?.data}
                    handleCloseModal={onHideModals}
                    onApproveAction={onApproveRequestItems}
                />
            }
            {showSplitModal &&
                <SplitModal
                    openModal={showSplitModal}
                    saleId={saleId}
                    rejected={0}
                    matching={matchingItems?.length || 0}
                    missing={(totalItems + (findConfirmedRejected?.length || 0)) - ((requestedItems?.length || 0) + selectedItemsWithoutExtra)}
                    notMatching={notMatchingItems?.length || 0}
                    saleDetails={saleDetails}
                    selectedItems={selectedItems}
                    requestedItems={requestedItems}
                    saleItems={saleDetails?.saleItems}
                    handleCloseModal={onHideSplitModal}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                />
            }
        </div>
    )
}
export default ProcessingStatus;