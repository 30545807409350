import React from 'react';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ISuccessRegisterModal {
    openModal: boolean;
    handleCloseModal: () => void;
}

const SuccessRegisterModal = ({
    openModal,
    handleCloseModal
}: ISuccessRegisterModal) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[400px] flex flex-col items-center justify-between'}>
                <div>
                    <p className='text-xl font-bold text-center'>Thank you for registering.</p>
                    <p className='text-xl font-bold text-center'>We`ll come back to you.</p>
                </div>
                <img src={'/assets/auth/register-phone.svg'} className={'h-[150px] object-contain my-6'} />
                <Button
                    label='Close'
                    className='btn-primary-rounded-less my-3 min-w-[150px] !shadow-none'
                    onClickButton={handleCloseModal}
                />
            </div>
        </Modal>
    )
}
export default SuccessRegisterModal;