import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ICrmListState } from '../../../../interfaces/crm-data/ICrmList';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ICrmSchema } from '../../../../interfaces/crm-data/ICrmSchema';
import { CrmService } from './crmDataApi';

const initialState: ICrmListState = {
    crmAreLoading: false,
    crmIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCrmData = createAsyncThunk<any, IPaginationPayload>(
    'crm/tryToFetchCrmData',
    async ({ pageNumber, pageSize, data }) => {
        const result = await CrmService.tryToFetchCrmData(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    }
);

export const tryToFetchSingleCrmData = createAsyncThunk<any, string>(
    'crm/tryToFetchSingleCrmData', async (id) => {
        const result = await CrmService.tryToFetchSingleCrmData(id);
        return result?.data;
    }
);

export const tryToFetchAllCrmData = createAsyncThunk<any, any>(
    'crm/tryToFetchAllCrmData', async (data) => {
        const result = await CrmService.tryToFetchAllCrmData(data);
        return result?.data;
    }
);

export const tryToAddCrmData = createAsyncThunk<ApiResponse<any>, ICrmSchema>(
    'crm/tryToAddCrmData',
    async (data: ICrmSchema, { rejectWithValue }) => {
        try {
            const result = await CrmService.tryToAddCrmData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditCrmData = createAsyncThunk<any, { id: string, data: ICrmSchema }>(
    'crm/tryToEditCrmData',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await CrmService.tryToEditCrmData(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteCrmData = createAsyncThunk<ApiResponse<any>, string>(
    'crm/tryToDeleteCrmData',
    async (id, { rejectWithValue }) => {
        try {
            const result = await CrmService.tryToDeleteCrmData(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const crmSlice = createSlice({
    name: 'crm',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchCrmData
            .addCase(tryToFetchCrmData.pending, (state) => {
                state.crmAreLoading = true;
            })
            .addCase(tryToFetchCrmData.rejected, (state) => {
                state.crmAreLoading = false;
            })
            .addCase(tryToFetchCrmData.fulfilled, (state, action) => {
                state.crmAreLoading = false;
                state.crm = action.payload;
            })

            // tryToFetchSingleCrmData
            .addCase(tryToFetchSingleCrmData.pending, (state) => {
                state.crmIsLoading = true;
            })
            .addCase(tryToFetchSingleCrmData.rejected, (state) => {
                state.crmIsLoading = false;
            })
            .addCase(tryToFetchSingleCrmData.fulfilled, (state, action) => {
                state.crmIsLoading = false;
                state.crmDetails = action.payload;
            })

            // tryToAddCrmData
            .addCase(tryToAddCrmData.pending, (state) => {
                state.crmIsLoading = true;
            })
            .addCase(tryToAddCrmData.rejected, (state) => {
                state.crmIsLoading = false;
            })
            .addCase(tryToAddCrmData.fulfilled, (state, action) => {
                state.crmIsLoading = false;
                const data = action.payload.data || action.meta.arg;
                if (state.crm?.data) state.crm.data.elements = [...state.crm?.data.elements || [], data];
            })

            // tryToEditCrmData
            .addCase(tryToEditCrmData.pending, (state) => {
                state.crmIsLoading = true;
            })
            .addCase(tryToEditCrmData.rejected, (state) => {
                state.crmIsLoading = false;
            })
            .addCase(tryToEditCrmData.fulfilled, (state, action) => {
                state.crmIsLoading = false;
                if (state.crm?.data.elements)
                    state.crm.data.elements =
                        { ...state }.crm?.data.elements.map((crmData) => {
                            if (crmData?._id === action?.meta?.arg?.id) {
                                return {
                                    ...action.payload || {},
                                }
                            } else {
                                return crmData
                            }
                        }) || [];
                state.crmDetails = action.payload.data;
            })

            // tryToDeleteCrmData
            .addCase(tryToDeleteCrmData.pending, (state) => {
                state.crmIsLoading = true;
            })
            .addCase(tryToDeleteCrmData.rejected, (state) => {
                state.crmIsLoading = false;
            })
            .addCase(tryToDeleteCrmData.fulfilled, (state, action) => {
                state.crmIsLoading = false;
                if (state.crm?.data.elements)
                    state.crm.data.elements = { ...state }.crm?.data.elements.filter((crmData) => crmData?._id !== action.meta.arg) || [];
            })
    },
});

export default crmSlice.reducer;
