import { createApi } from '@reduxjs/toolkit/query/react';
import { brokersAxiosQuery } from '../../utils/axios/brokers-roles.axios';
import { IUser } from '../../interfaces/user/IUser';
import { IResetPassword } from '../../interfaces/auth/IResetPassword';
import { IAddress } from '../../interfaces/auth/ICreateUserSchema';
import { IChangePassword } from '../../interfaces/user/IChangePassword';
import { readFromStorage } from '../../utils/webStorage';

export const userDomAPI = createApi({
    reducerPath: 'user',
    baseQuery: brokersAxiosQuery(),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        changePassword: builder.mutation<IUser, IChangePassword>({
            query: (data) => ({
                url: '/dom/change-password',
                method: 'PUT',
                data: data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${readFromStorage('accessToken')}`,
                },
            }),
        }),
        forgotPassword: builder.mutation<IUser, { username: string }>({
            query: (username) => ({
                url: '/dom/forgot-password',
                method: 'POST',
                data: username
            }),
        }),
        resetPassword: builder.mutation<IUser, IResetPassword>({
            query: (data) => ({
                url: '/dom/confirm-forgot-password',
                method: 'POST',
                data
            }),
        }),
        userProfile: builder.mutation<any, null>({
            query: () => ({
                url: '/dom/user',
                method: 'GET'
            }),
        }),
        addNewAddress: builder.mutation<IUser, IAddress>({
            query: (data) => ({
                url: '/dom/user/add-address',
                method: 'POST',
                data
            }),
        }),
        getAllMarkets: builder.mutation<any, undefined | null>({
            query: () => ({
                url: '/dom/markets',
                method: 'GET',
            }),
        }),
        userGetProfile: builder.query<any, void>({
            query: () => ({
                url: '/dom/user',
                method: 'GET'
            }),
        })
    }),
});

export const {
    useChangePasswordMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useUserProfileMutation,
    useUserGetProfileQuery,
    useAddNewAddressMutation,
    useGetAllMarketsMutation
} = userDomAPI;
