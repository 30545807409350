import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IWarehouse } from '../../interfaces/warehouses/IWarehouse';
import { tryToFetchWarehouses, tryToGetWarehouseFields } from '../../store/inventory/broker/warehouses/warehousesSlice';
import Button from '../button';
import Error from '../error';
import Loading from '../Loading';
import SelectCheckbox from '../select-checkbox';

interface IWarehouseModal {
    open: boolean;
    title?: string;
    contentContainerStyle?: string;
    onClose: () => void;
    onSetItemLocation: (data: any) => void;
}

const WarehouseModal = ({
    open,
    title,
    contentContainerStyle,
    onSetItemLocation,
    onClose
}: IWarehouseModal) => {
    const dispatch = useAppDispatch();
    const [fields, setFields] = useState<any>()
    const [fieldsData, setFieldsData] = useState<any>()
    const [warehouses, setWarehouses] = useState<Array<any>>()
    const [warehouseSelected, setWarehouseSelected] = useState<string>()
    const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)
    const [fieldsErrors, setFieldsErrors] = useState<any>()
    const [locationsLoading, setLocationsLoading] = useState<boolean>(false)
    const [generalError, setGeneralError] = useState<string>()
    const activeMarket = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)
    const stateWarehouses = useAppSelector((state) => state.warehouses);
    const stateFields = useAppSelector((state) => state?.warehouses)

    useEffect(() => {
        dispatch(tryToFetchWarehouses(null)).unwrap()
    }, [])

    useEffect(() => {
        const formatWarehouses = stateWarehouses.warehouses && stateWarehouses.warehouses?.length > 0 && stateWarehouses.warehouses?.map((warehouse: IWarehouse) => ({ ...warehouse, label: `${warehouse?.address?.street || ''}-${warehouse?.name}`, value: `${warehouse?.address?.street || ''}-${warehouse?.name}` }))
        // const filterWarehouses: any = formatWarehouses && formatWarehouses?.length > 0 && activeMarket && formatWarehouses?.filter((warehouse: any) => warehouse?.markets && warehouse?.markets?.find((item: any) => item?._id === activeMarket?._id))
        setWarehouses(formatWarehouses || [])
    }, [stateWarehouses.warehouses, activeMarket])

    const onGettingFieldsData = async () => {
        if (warehouseSelected) {
            const findWarehouse = warehouses && warehouses?.length > 0 && warehouses.find((warehouse: any) => warehouse?.label === warehouseSelected)
            let formatData: any = {
                warehouseId: findWarehouse?._id || ''
            }
            if (fieldsData) {
                const fieldsDataKeys = fieldsData && Object.keys(fieldsData)
                const values = fieldsDataKeys && fieldsDataKeys?.map((field: any) => ({ [field]: fieldsData?.[field] }))
                formatData = {
                    ...formatData,
                    data: {
                        values: values
                    }
                }
            }
            dispatch(tryToGetWarehouseFields(formatData))
            setFieldsLoading(false)
            setLocationsLoading(false)
        }
    }

    useEffect(() => {
        if (stateFields.warehouseFields) {
            setFields(stateFields?.warehouseFields)
        }
    }, [stateFields?.warehouseFields])

    useEffect(() => {
        if (warehouseSelected) {
            onGettingFieldsData()
        } else {
            setFields(undefined)
        }
    }, [warehouseSelected])

    const onCloseModal = () => {
        onClose();
        setFields(undefined)
        setWarehouseSelected(undefined)
        setFieldsData(undefined)
        setFieldsErrors(undefined)
        setFieldsLoading(false)
    }

    const onClickWarehouse = (value: any, type?: string) => {
        setWarehouseSelected(value?.label || undefined)
        setFieldsLoading(true)
    }

    useEffect(() => {
        onGettingFieldsData()
    }, [fieldsData])

    const onChangeSelectedValue = (value: any, type?: string) => {
        if (type) {
            setLocationsLoading(true)
            setFieldsData({
                ...(fieldsData || {}),
                [type]: value?.value || undefined,
            });
        }
    };

    const onClickClearFields = () => {
        setFieldsData(undefined)
    }

    const onSubmit = () => {
        const fieldsDataKeys = fields && Object.keys(fields)
        let itemErrors: any
        if (fieldsDataKeys && fieldsDataKeys?.length > 0) {
            fieldsDataKeys?.map((item: any) => {
                if (!fieldsData?.[item]) {
                    itemErrors = {
                        ...(itemErrors || {}),
                        [item]: `${item} is required`
                    }
                }
                return item
            })

        } else {
            setGeneralError('Please fill all the fields to continue')
            return;
        }
        if (itemErrors) {
            setFieldsErrors(itemErrors)
            return;
        }

        const findWarehouse = warehouseSelected && warehouses && warehouses?.length > 0 && warehouses?.find((warehouse: any) => warehouse?.label === warehouseSelected)
        const formatData = {
            warehouse: {
                id: findWarehouse?._id,
                name: warehouseSelected
            },
            locations: fieldsData
        }
        onSetItemLocation(formatData)
        setFields(undefined)
        setWarehouseSelected(undefined)
        setFieldsData(undefined)
        setFieldsErrors(undefined)
        setFieldsLoading(false)
    }

    const findFieldKeys = fields && Object.keys(fields)
    return (
        <div className={`fixed z-20 inset-0 ${open ? '' : 'pointer-events-none'} `}>
            <div className={`fixed inset-0 bg-black ${open ? 'opacity-50' : 'pointer-events-none opacity-0'}`}
                onClick={onCloseModal} />
            <div className={`fixed overflow-auto overscroll-auto !max-h-[95vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg w-auto min-w-[700px] h-auto min-h-[100px] max-w-screen-sm p-4 ${open ? 'opacity-100' : 'pointer-events-none opacity-0'} ${contentContainerStyle || ''}`}>
                <div className='flex flex-row justify-between items-center mt-1 mb-2'>
                    <p className='text-[#5C5C5C] text-[15px] font-medium'>{title || ''}</p>
                    <div onClick={onCloseModal} className={'cursor-pointer'}>
                        <img alt='close' src={'/assets/shared/modal-close.svg'} className='object-contain w-[16px]' />
                    </div>
                </div>
                <div className={'p-2 min-w-[650px]'}>
                    <div className='min-h-[200px] flex flex-col justify-center items-center'>
                        <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Warehouses</p>
                        {generalError && <Error text={generalError} />}
                        <div className='my-2 min-w-[60%]'>
                            <SelectCheckbox
                                placeholder={warehouseSelected || 'Warehouse'}
                                options={warehouses || []}
                                selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px', minWidth: 200 }}
                                onChangeSelectedOption={onClickWarehouse}
                                uniqueName={'warehouse'}
                            />
                        </div>
                        {fieldsLoading ?
                            <div className='my-6 flex flex-row justify-center items-center min-w-[100%]'>
                                <Loading />
                            </div>
                            : <div className='grid grid-cols-3 gap-4 my-5 mb-[40px] min-w-[100%]'>
                                {fields && findFieldKeys && findFieldKeys?.length > 0 && findFieldKeys?.map((field: any, index: number) => {
                                    const values =
                                        fields?.[field] &&
                                        fields?.[field]?.length > 0 &&
                                        fields?.[field]?.map((value: string) => {
                                            return { value: value, label: value };
                                        });
                                    const findError = fieldsErrors && fieldsErrors?.[field]
                                    return (
                                        <SelectCheckbox
                                            key={index}
                                            isLoading={(locationsLoading && !fieldsData?.[field])}
                                            name={field && `${field[0].toUpperCase()}${field.slice(1).toLowerCase()}`}
                                            placeholder={(fieldsData?.[field] || '')}
                                            options={values || []}
                                            selectedOption={fieldsData?.[field]}
                                            selectStyle={{ backgroundColor: 'transparent', borderRadius: '4px' }}
                                            onChangeSelectedOption={onChangeSelectedValue}
                                            uniqueName={field || ''}
                                            error={findError}
                                        />
                                    )
                                })}
                            </div>
                        }
                        <div className='min-w-full flex flex-row justify-center my-3'>
                            {!fieldsLoading && fields && findFieldKeys && findFieldKeys?.length > 0 &&
                                <Button
                                    className={'!py-0 btn-primary !shadow-none mr-4 min-w-[130px]'}
                                    label={'Clear fields'}
                                    onClickButton={onClickClearFields}
                                />
                            }
                            <Button
                                label={'Save'}
                                className={`btn-primary-rounded-less min-w-[130px] !shadow-none ${warehouseSelected ? '' : 'opacity-50'}`}
                                disabled={!warehouseSelected}
                                onClickButton={() => warehouseSelected && !fieldsErrors && onSubmit()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarehouseModal;