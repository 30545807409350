export const SALES_STATUSES = [
    { value: 'DRAFT', label: 'DRAFT', name: 'DRAFT' },
    { value: 'OPEN_ORDERS', label: 'OPEN ORDERS', name: 'OPEN ORDERS' },
    { value: 'REVIEW', label: 'REVIEW', name: 'REVIEW' },
    { value: 'PROCESSING', label: 'PROCESSING', name: 'PROCESSING' },
    { value: 'PROCESSED', label: 'PROCESSED', name: 'PROCESSED' },
    { value: 'PAID', label: 'PAID', name: 'PAID' },
    { value: 'SHIPPED', label: 'SHIPPED', name: 'SHIPPED' },
    { value: 'EXPIRED', label: 'EXPIRED', name: 'EXPIRED' },
]

export const SALES_ADMIN_STATUSES = [
    { value: 'ACCEPTED', label: 'ACCEPTED', name: 'ACCEPTED' },
    { value: 'REJECTED', label: 'REJECTED', name: 'REJECTED' }
]