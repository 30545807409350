import React from 'react';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import Error from '../../../../shared/error';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';

interface IUploadCsvFile {
    getRootProps: any;
    getInputProps: any;
    isDragActive: boolean;
    fileSelected?: any;
    importError?: string;
    markets: Array<any>;
    selectedMarket?: string;
    selectedSector?: string;
    activeWarehouse?: IWarehouse;
    sectors: Array<any>;
    onChangeSelectedMarket: (e: any, type?: string) => void;
    onChangeSelectedSector: (e: any) => void;
    onUploadFile: () => void;
    combined?: any
    onChangeDateFilters: (value: string | null | undefined, type: string) => void;
    dateFiltersValues?: any;
    onChangeCustomer?: (e: any, type?: string, index?: any) => void;
    selectedCustomer?: any;
    selectedVatType?: any;
    onChangeVatTypeFilter: (e: any, type?: string, index?: any) => void;
    onClickRemoveThisFile: () => void;
}

const VAT_TYPE = [{label: 'Standard', value: 'Standard'}, {label: 'UK Margin', value: 'UK Margin'},{label: 'EU Margin', value: 'EU Margin'},{label: 'B2B Margin', value: 'B2B Margin'}, {label: 'Zero Rated', value: 'Zero Rated'}]

const UploadCsvFile = ({
    activeWarehouse,
    getRootProps,
    getInputProps,
    isDragActive,
    fileSelected,
    importError,
    sectors,
    markets,
    selectedSector,
    selectedMarket,
    onChangeSelectedMarket,
    onChangeSelectedSector,
    onUploadFile,
    combined,
    onChangeDateFilters,
    dateFiltersValues,
    onChangeCustomer,
    selectedCustomer,
    selectedVatType,
    onChangeVatTypeFilter,
    onClickRemoveThisFile
}: IUploadCsvFile) => {

    return (
        <>
        <div className='p-3 bg-[#F8F9FC] rounded-[10px] '>
            <div className='mb-3'>
                <p className='text-md mb-2 mt-1 font-medium text-[18px]'>Import data for {activeWarehouse?.name} warehouse.</p>
                {importError && <Error text={importError} />}
            </div>
            <div className='grid grid-cols-1 gap-3 w-[70%]'>
                <SelectCheckbox
                    placeholder='Market'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedMarket}
                    onChangeSelectedOption={onChangeSelectedMarket}
                    options={markets || []}
                />
                <SelectCheckbox
                    placeholder='Category'
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedSector}
                    onChangeSelectedOption={onChangeSelectedSector}
                    options={sectors || []}
                />
                <Datepicker
                                placeholder='Purchase Date'
                                dataQa={'date-from'}
                                containerStyle='mb-1 min-w-[90%]'
                                inputValue={dateFiltersValues?.from}
                                onChangeInput={onChangeDateFilters}
                                inputUniqueName={'from'}
                            />
                            <SelectCheckbox
                dataQa={'vatType-filter'}
                placeholder={'VAT Type'}
                options={VAT_TYPE || []}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                onChangeSelectedOption={onChangeVatTypeFilter}
                selectedOption={selectedVatType}
                uniqueName={'vatType'}
            />
             { onChangeCustomer &&  <SelectCheckbox
                containerStyle='mb-1 min-w-[90%]'
                dataQa={`${(selectedCustomer?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                selectedOption={selectedCustomer}
                placeholder='Customer'
                uniqueName='combined'
                onChangeSelectedOption={onChangeCustomer}
                options={combined || []}
            />}
            </div>
          {!fileSelected ?  <div>
           <p className='mb-5 font-medium text-[18px]'>Upload CSV File</p>
            <div className='w-full h-[130px] bg-white rounded-[8px] flex justify-center items-center' {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='flex flex-col gap-3 justify-center items-center'>
                    <img src='/assets/table-actions/CSVtextIcon.svg' className='w-[41px] h-[30px]'/>
                    {
                        isDragActive ?
                            <p className='font-medium text-[18px]'>Drop the files here ...</p> :
                            <p className='font-medium text-[18px]'>Drag and Drop CSV File here</p>
                    }
                </div>
            </div>
            <div className='w-full h-[35px] bg-white mt-3 rounded-[8px] flex flex-row gap-2 justify-center items-center cursor-pointer' {...getRootProps()}>
                <img src='/assets/table-actions/upload-file.svg' className='w-[22px] h-[16px]'/>
                <p className='font-medium text-[17px] underline'>or Select File</p>
            </div>
           </div> : <div>
                    <p>File Uploaded: <strong className='text-[#F7A21E]'>{fileSelected?.name}</strong></p>
                    <p className='underline font-semibold text-[15px] my-2 cursor-pointer' onClick={onClickRemoveThisFile}>Remove this file</p>
            </div>}
           
        </div>
        <div className='flex flex-col items-center my-2 mt-9'>
            <button className={`text-black py-[10px] px-[20px] bg-[#EBEDEF] h-[38px] w-[299px] rounded-[10px] font-semibold ${fileSelected && 'btn-primary-rounded-less'}`} onClick={() => fileSelected && onUploadFile()}>
                Confirm
            </button>
            </div>
        </>
    )
}

export default UploadCsvFile;
