import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ISectionChildsFilter {
    sectors?: Array<IAssetTemplate>;
    parents?: Array<any>;
    selectedSector?: any;
    selectedParent?: any;
    onSelectFilter: (e: any) => void;
}

const SectionChildsFilter = ({
    sectors,
    parents,
    selectedParent,
    selectedSector,
    onSelectFilter
}: ISectionChildsFilter) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4 my-4'>
                <SelectCheckbox
                    name={'Category'}
                    placeholder=' '
                    dataQa={'category-selected'}
                    selectedOption={selectedSector}
                    options={sectors || []}
                    uniqueName='category'
                    multiple={true}
                    onChangeSelectedOption={onSelectFilter}
                />
                <SelectCheckbox
                    name={'Parent'}
                    placeholder=' '
                    dataQa={'parent-selected'}
                    selectedOption={selectedParent}
                    options={parents || []}
                    uniqueName='parent'
                    onChangeSelectedOption={onSelectFilter}
                />
            </div>
        </div>
    )
}

export default SectionChildsFilter;