export const onFormatSalesStatus = (status: string) => {
    switch (status) {
        case 'draft':
            return { name: 'Draft', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'open_order':
            return { name: 'Open Order', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'review':
            return { name: 'Review', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'processing':
            return { name: 'Processing', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'processed':
            return { name: 'Processed', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'paid':
            return { name: 'Paid', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'shipped':
            return { name: 'Shipped', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'expired':
            return { name: 'Expired', color: 'border-[#E50000]', text: 'text-[#E50000]' }
        default:
            return { name: status, color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
    }
}

export const onFormatSaleItemStatus = (status: string) => {
    switch (status) {
        case 'not_scanned':
            return { name: 'Not Scanned', color: 'border-[#c4c4c4]', text: 'text-[#c4c4c4]' }
        case 'not_matching':
            return { name: 'Not Matching', color: 'border-[#E50000]', text: 'text-[#E50000]' }
        case 'scanned':
            return { name: 'Matching', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'matching':
            return { name: 'Matching', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'extra_item':
            return { name: 'Extra Item', color: 'border-[#c3ced6]', text: 'text-[#c3ced6]' }
        case 'accepted':
            return { name: 'Accepted', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'rejected':
            return { name: 'Rejected', color: 'border-[#e50000]', text: 'text-[#e50000]' }
        case 'replaced':
            return { name: 'Replaced', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        default:
            return { name: status, color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
    }
}