import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const ItemOrderContainerLoading = () => {
    return (
        <WhiteContainer containerStyle='!rounded !h-auto p-4'>
            <p className='font-bold text-[18px] mb-4'>Purchase/Order Information</p>
            <div>
                {[...Array(5)].map((x, i) => {
                    return (
                        <>
                            <div className=' my-4 flex flex-row item-center animate-pulse'>
                                <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[30%] mb-1 mr-3'></div>
                                <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[40%] mb-1'></div>
                            </div>
                        </>
                    )
                })
                }
                <div className='my-4 animate-pulse'>
                    <div className='h-4 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[200px] mt-4 mb-2'></div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ItemOrderContainerLoading;