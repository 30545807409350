import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { PaginatedPurchaseRequests } from '../../../../interfaces/purchase-requests/paginatedStore.type';


const tryToFetchPurchaseRequests = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPurchaseRequests>> => {
    const url = `${INVENTORY_API_URL}/broker-purchases?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedPurchaseRequests>(url, data);
};

const tryToFetchSinglePurchaseRequest = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/broker-purchases/${id}`);
};

const tryToAddPurchaseRequestNote = (id: string, data: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-purchases/${id}/notes`, data);
};

const tryToFetchPurchaseRequestNote = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/broker-purchases/${id}/notes`);
};

const tryToSubmitPurchaseRequestOffer = (id: string, status: string, data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-purchases/${id}/status/${status}`, data);
};

const tryToFetchAllPurchaseRequest = (data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-purchases/all`, data);
};

export const PurchaseRequestsService = {
    tryToFetchPurchaseRequests,
    tryToFetchSinglePurchaseRequest,
    tryToAddPurchaseRequestNote,
    tryToFetchPurchaseRequestNote,
    tryToSubmitPurchaseRequestOffer,
    tryToFetchAllPurchaseRequest
};