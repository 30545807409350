import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrdersService } from './ordersApi';
import { AxiosError } from 'axios';

const initialState: any = {
    imeiStatusLoading: true,
};

export const tryToFetchImeiStatusCheck = createAsyncThunk<any, string>(
    'orderDetail/tryToFetchImeiStatusCheck',
    async (imei, { rejectWithValue }) => {
        try {
            const result = await OrdersService.tryToFetchImeiStatusCheck(imei);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

    },
});

export default ordersSlice.reducer;
