import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToConfirmReviewSale } from '../../../../store/inventory/shared/sales/salesSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface ISplitModal {
    openModal: boolean;
    saleId: string;
    matching?: number;
    notMatching?: number;
    missing?: number;
    rejected?: number;
    saleDetails?: any;
    requestedItems?: any;
    selectedItems?: any;
    saleItems?: any;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const SplitModal = ({
    openModal,
    saleId,
    matching,
    notMatching,
    missing,
    rejected,
    saleDetails,
    requestedItems,
    selectedItems,
    saleItems,
    handleCloseModal,
    onSuccessfullyChanged,
}: ISplitModal) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        handleCloseModal()
    }

    const onApproveAction = async () => {
        setLoading(true)
        try {
            const reformatSelectedItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => !(!item?.match && item?.status === 'rejected') || item?.confirm_reject)
            const formatData = {
                ...saleDetails || {},
                saleItems: saleItems,
                selectedItems: reformatSelectedItems,
                requestedItems: requestedItems,
            }
            await dispatch(tryToConfirmReviewSale({ id: saleId, data: formatData })).unwrap()
            onSuccessfullyChanged('success', 'Action was successful!')
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }
    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Confirm'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Action Verification</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='my-2 text-[20px]'>Do you confirm your action?</p>
                    {!!((missing && Number(missing || 0) > 0) || (notMatching && Number(notMatching || 0) > 0)) &&
                        <div className='my-4'>
                            <p className='mb-4'>This sale order will be split</p>
                            <ul className='list-disc px-4'>
                                {(matching && matching > 0) ?
                                    <li><span className='font-semibold'>{matching} matching</span> items will be marked as <span className='text-[#00b67a]'>Processed</span></li>
                                    : <></>
                                }
                                {(missing || notMatching) &&
                                    <li><span className='font-semibold'>{missing ? `${missing} missing` : ''}{notMatching ? `${missing ? ' and ' : ''}${notMatching} not matching` : ''}</span> items go to <span className='text-[#f7a21e]'>Open Order</span></li>
                                }
                            </ul>
                        </div>
                    }
                    {(rejected && rejected > 0) ?
                        <div className='my-4'>
                            <p className='text-[#e50000]'>**{rejected > 1 ? `${rejected} items` : `${rejected} item`} was rejected, this sale order status will be processing until return to stock is confirmed.</p>
                        </div>
                        : <></>
                    }
                    {error &&
                        <div className='flex flex-row justify-center my-5'>
                            <Error text={error} />
                        </div>
                    }
                    <div className='flex flex-row justify-center mt-10 mb-5'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} !rounded mr-4 min-w-[150px]`}
                            onClickButton={() => !loading && onCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default SplitModal;