import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ILocationsFilters {
    sections?: Array<any>;
    selectedSection?: any;
    onSelectFilter: (e: any, type?: string) => void;
}

const LocationsFilters = ({
    sections,
    selectedSection,
    onSelectFilter
}: ILocationsFilters) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4 my-4'>
                <SelectCheckbox
                    name={'Section'}
                    placeholder=' '
                    dataQa={'section-selected'}
                    selectedOption={selectedSection}
                    options={sections || []}
                    uniqueName='section'
                    onChangeSelectedOption={onSelectFilter}
                />
            </div>
        </div>
    )
}

export default LocationsFilters;