import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToRevaluePurchaseItem } from '../../../../store/inventory/broker/purchases/purchasesSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IRevalueDiscrepancyItemModal {
    openModal: boolean;
    purchaseId: string;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessSave: () => void;
}

const RevalueDiscrepancyItemModal = ({
    openModal,
    purchaseId,
    itemId,
    handleCloseModal,
    onSuccessSave
}: IRevalueDiscrepancyItemModal) => {
    const dispatch = useAppDispatch();
    const [revaluePrice, setRevaluePrice] = useState<any>()
    const [generalError, setGeneralError] = useState<any>()
    const [loadingSubmit, setLoadingSubmit] = useState<any>()

    const onCloseModal = () => {
        setRevaluePrice(undefined);
        setGeneralError(undefined);
        handleCloseModal();
    }

    const onSubmit = async () => {
        if (!revaluePrice && revaluePrice !== 0) {
            setGeneralError('Please write the price to continue')
            return;
        }
        setLoadingSubmit(true)
        try {
            const formData = {
                revaluatePrice: revaluePrice
            }
            await dispatch(tryToRevaluePurchaseItem({ id: purchaseId, itemId: itemId, data: formData })).unwrap();
            onSuccessSave()
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onChangeRevaluePriceInputValue = (value: any, name?: string) => {
        setRevaluePrice((/^\d*\.?\d*$/).test(value) ? value : revaluePrice)
    }

    return (
        <Modal
            open={openModal}
            showInRight
            title={'Discrepancy Action'}
            onClose={() => onCloseModal()}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Revalue</p>
                </div>
                {generalError && <Error text={generalError} />}
                <div className='p-2 !rounded bg-[#f8f9fc]'>
                    <p className='my-2 text-[17px] font-medium'>Are you sure you want to Accept Item with all the details and the Initial Cost?</p>
                    <p className='mb-3 text-[13px]'>This item will be added to inventory after you complete this action and will be added into the completed purchase order.</p>
                    <div className='my-3'>
                        <Input
                            label='Revalue Price'
                            showValue={true}
                            placeholder=' '
                            inputValue={revaluePrice}
                            onChangeInput={onChangeRevaluePriceInputValue}
                        />
                    </div>
                    <p className='text-primary-light my-3'>If the revaluation price is higher than the initial price, a credit note will be created to be paid to the supplier. If the revaluation price is lower than initial price, a credit note will be created to be paid by supplier to you.</p>
                    <div className='flex flex-row justify-center my-2'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px] mr-3`}
                            onClickButton={() => !loadingSubmit && onCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'yes-button'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loadingSubmit && onSubmit()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default RevalueDiscrepancyItemModal;