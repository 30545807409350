import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IWarehouseSectionsFilter {
    sectors?: Array<IAssetTemplate>;
    selectedSector?: any;
    onSelectFilter: (e: any) => void;
}

const WarehouseSectionsFilter = ({
    sectors,
    selectedSector,
    onSelectFilter
}: IWarehouseSectionsFilter) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4'>
                <div className='my-4'>
                    <SelectCheckbox
                        name={'Category'}
                        placeholder=' '
                        dataQa={'category-selected'}
                        selectedOption={selectedSector}
                        options={sectors || []}
                        uniqueName='category'
                        multiple={true}
                        onChangeSelectedOption={onSelectFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default WarehouseSectionsFilter;