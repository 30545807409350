export const onFormatDiscrepancyStatus = (type: string) => {
    switch (type) {
        case 'not_matching':
        case 'not_match':
            return { label: 'Not Matching', color: 'text-[#e50000]' }
        case 'return':
        case 'returned':
            return { label: 'Return', color: 'text-[#e50000]' }
        case 'missing':
            return { label: 'Missing', color: 'text-[#e50000]' }
        case 'await_credit':
        case 'awaiting_credit':
            return { label: 'Missing', color: 'text-[#e50000]' }
        case 'accepted':
            return { label: 'Accepted', color: 'text-[#00b67a]' }
        case 'reject':
            return { label: 'Reject', color: 'text-[#e50000]' }
        case 'revalued':
            return { label: 'Revalued', color: 'text-[#1389e6]' }
        case 'extra_item':
            return { label: 'Extra Item', color: 'text-[#1389e6]' }
        default:
            return { label: type, color: 'text-[#1389e6]' }
    }
}