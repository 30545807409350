import React, { useState } from 'react';
import PurchaseTypeModal from '../modals/PurchaseTypeModal';
import Button from '../../../../shared/button';


interface IPurchaseTypeStep {
    selectedType?: any;
    onClickType: (item: any) => void;
}

const PurchaseTypeStep = ({
    selectedType,
    onClickType
}: IPurchaseTypeStep) => {
    const [showTypeModal, setShowTypeModal] = useState<boolean>(false)

    const onClickAddType = () => {
        if (!showTypeModal) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        setShowTypeModal(!showTypeModal)
    }

    return (
        <div className='min-w-[100%] bg-white border-b-2 border-[#e4e4e4] p-3'>
            <div className='flex flex-row items-center my-5'>
                <div className='mr-4'>
                    <p className='font-bold'>Choose Order Type</p>
                </div>
                <div className='mr-3'>
                    <Button
                        label='Choose Type'
                        icon={<img src='/assets/shared/add-box.svg' className='w-[30px] object-contains pr-2' />}
                        className='btn-primary flex flex-row items-center'
                        onClickButton={onClickAddType}
                    />
                </div>
                <div className='flex flex-row items-center mr-2'>
                    <p>{selectedType?.label}</p>
                </div>
            </div>
            {showTypeModal &&
                <PurchaseTypeModal
                    openModal={showTypeModal}
                    selectedType={selectedType}
                    onClickType={onClickType}
                    handleCloseModal={onClickAddType}
                />
            }
        </div>
    );
};

export default PurchaseTypeStep;
