import React, { useState, useEffect, useLayoutEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
    useNavigate,
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { COLOR_TYPES } from '../../../utils/constants/color-states';
import { VAT_TYPE_ITEMS } from '../../../utils/constants/vat-types';
import { INVENTORY_ACTIONS } from '../../../utils/constants/inventory-actions';
import { INVENTORY_FILTERS } from '../../../utils/constants/inventory-filter-values';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import {
    tryToFetchInventoryAgreggatedData,
    tryToFetchTotalInventoryItemsNumber
} from '../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import {
    tryToFetchAssetTemplateFilters,
    tryToFetchInventoryFilters,
} from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import { tryToFetchWarehouseMarkets } from '../../../store/inventory/broker/warehouses/warehousesSlice';
import { IInventoryAggregated } from '../../../interfaces/inventory-data/IInventoryAggregated';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IMarket } from '../../../interfaces/markets/IMarket';
import DownloadSampleInventoryModal from './modals/DownloadSampleInventoryModal';
import InventoryCatalogFilters from './details/InventoryCatalogFilters';
import ImportSalesGradeModal from './modals/ImportSalesGradeModal';
import ImportInventoryModal from './modals/ImportInventoryModal';
import InventoryCategories from './details/InventoryCategories';
import InventoryMainFilter from './details/InventoryMainFilter';
import ImportCostsModal from './modals/ImportCostsModal';
import InventoryTable from './table/InventoryTable';
import SelectCheckbox from '../../../shared/select-checkbox';
import Button from '../../../shared/button';
import Input from '../../../shared/input';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Inventory = () => {
    const query = useQuery();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink1 = React.useRef() as React.MutableRefObject<any>;
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [loadingDownloadCosts, setLoadingDownloadCosts] = useState<boolean>(false)
    const [templateData, setTemplateData] = useState<Array<any>>()
    const [templateDataCosts, setTemplateDataCosts] = useState<Array<any>>()
    const [rows, setRows] = useState<Array<IInventoryAggregated>>([]);
    const [search, setSearch] = useState<string>();
    const [markets, setMarkets] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [inventoryFilter, setInventoryFilters] = useState<Array<any>>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [colorState, setColorState] = useState<any>(COLOR_TYPES?.[0]);
    const [selectedMargins, setSelectedMargins] = useState<any>();
    const [showImportGradesModal, setShowImportGradesModal] = useState<boolean>(false)
    const [showImportCostsModal, setShowImportCostsModal] = useState<boolean>(false)
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [inventorySelected, setInventorySelected] = useState<any>();
    const [showImportInventory, setShowImportInventory] = useState<boolean>(false);
    const [showDownloadInventory, setShowDownloadInventory] = useState<boolean>(false);
    const [showFilters, setshowFilters] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [userProfileMutation] = useUserProfileMutation();
    const state = useAppSelector((state) => state.inventory);
    const totalCountItems = useAppSelector((state) => state.inventory?.totalItems);
    const categoryCountItems = useAppSelector((state) => state.inventory?.categoryItems);
    const stateMarkets = useAppSelector((state) => state.warehouses);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const paginationData = useAppSelector((state) => state?.inventory?.inventoryAggregated?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.inventoryAggregated) {
            const rows = state.inventoryAggregated?.data?.elements || [];
            setRows(rows);
        }
    }, [state.inventoryAggregated]);

    useEffect(() => {
        if (stateMarkets?.warehouseMarkets && stateMarkets?.warehouseMarkets?.length > 0) {
            const formatMarkets: any = stateMarkets?.warehouseMarkets?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
            setMarkets(formatMarkets || [])
        }
    }, [stateMarkets?.warehouseMarkets])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    };

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            const findSelectedVat = activeWarehouseState?.vatTypes && activeWarehouseState?.vatTypes?.length > 0 && VAT_TYPE_ITEMS?.filter((item: any) => (activeWarehouseState?.vatTypes || [])?.includes(item?.value))
            setSelectedMargins(findSelectedVat || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters);
        }
        if (stateFilters.inventoryFieldFilters) {
            setInventoryFilters(stateFilters.inventoryFieldFilters);
        }
    }, [stateFilters?.assetTemplateFilters, stateFilters?.inventoryFieldFilters]);

    useEffect(() => {
        if (selectedCategory) {
            dispatch(tryToFetchInventoryFilters(selectedCategory?.id));
            dispatch(tryToFetchAssetTemplateFilters(selectedCategory?.id));
        }
        setFilterValues(undefined);
    }, [selectedCategory]);

    const getBrokerMarketsAndSectors = async () => {
        const response = await userProfileMutation(null).unwrap();
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = formatSectors && formatSectors?.length > 0 && formatSectors?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || formatSectors?.[0] || undefined)
        } else {
            setSelectedCategory(selectedCategory || formatSectors?.[0]);
            searchParams.set('category', selectedCategory?.label || formatSectors?.[0]?.label)
            setSearchParams(searchParams)
        }
        onResetPaginationSettings();
        setBrokerCategories(formatSectors || []);
    };

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (JSON.stringify(paginationStateFormat) !== JSON.stringify(paginationState)) {
            setPaginationState(paginationStateFormat)
        }
        let filterState: any = false
        if (query?.get('search') && search !== query?.get('search')) {
            setSearch(query?.get('search') || undefined)
            filterState = true
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label && brokerCategories) {
            const findSector = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || selectedCategory || undefined)
            filterState = true
        }
        if (query?.get('color') && query?.get('color') !== colorState?.value) {
            const findColor = COLOR_TYPES?.find((item: any) => item?.value === query?.get('color'))
            setColorState(findColor || COLOR_TYPES?.[0])
            filterState = true
        }
        if (query?.get('market') && query?.get('market') !== selectedMarket?.label && (markets && markets?.length > 0)) {
            const findMarket = (markets && markets?.length > 0) && markets?.find((item: any) => item?.label === query?.get('market'))
            setSelectedMarket(findMarket || undefined)
            filterState = true
        }
        if (query?.get('vat')) {
            const margins: any = query?.get('vat') ? (query?.get('vat') || '').split(',') : []
            const findMargins = VAT_TYPE_ITEMS?.filter((item: any) => (margins && margins?.length > 0 ? margins : [])?.includes(item?.value))
            setSelectedMargins(findMargins || undefined)
            filterState = true
        }
        if (query?.get('location') && query?.get('location') !== inventorySelected?.label) {
            const findLocation = INVENTORY_FILTERS?.find((item: any) => item?.label === query?.get('location'))
            setInventorySelected(findLocation || undefined)
            filterState = true
        }
        if (filterState) {
            onResetPaginationSettings();
        }
    }, [
        query?.get('category'),
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
        query?.get('category'),
        query?.get('market'),
        query?.get('location'),
        query?.get('color'),
        query?.get('vat'),
        brokerCategories,
        markets
    ])

    useEffect(() => {
        getBrokerMarketsAndSectors();
    }, []);

    useEffect(() => {
        if (activeWarehouse?._id) {
            dispatch(tryToFetchWarehouseMarkets(activeWarehouse?._id)).unwrap();
            dispatch(tryToFetchTotalInventoryItemsNumber(activeWarehouse?._id)).unwrap();
        }
    }, [activeWarehouse]);

    const onGettingInventoryData = async () => {
        try {
            if (selectedCategory && activeWarehouse) {
                const filters = filterValues && Object.keys(filterValues);
                let formatFilters;
                const filtersValuesFormat =
                    filters &&
                    filters?.length > 0 &&
                    filters?.map((item: any) => {
                        return {
                            key: item,
                            value: Object.keys(filterValues)
                                ? filterValues?.[item] || undefined
                                : undefined,
                        };
                    });
                if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                    formatFilters = {
                        value: filtersValuesFormat?.filter((key: any) => key?.value),
                    };
                }
                let data: any = {
                    category: {
                        id: selectedCategory.id,
                        name: (selectedCategory?.displayName || '').toLowerCase(),
                    },
                };
                if (activeWarehouse) {
                    data = {
                        ...(data || {}),
                        warehouse: activeWarehouse?._id,
                    };
                }
                if (selectedMarket) {
                    data = {
                        ...(data || {}),
                        marketId: selectedMarket ? selectedMarket?._id || '' : '',
                    };
                }
                if (selectedMargins && selectedMargins?.length > 0) {
                    const formatMargins = selectedMargins?.map((margin: any) => margin?.value);
                    data = {
                        ...(data || {}),
                        stockVats: formatMargins,
                    };
                }
                if (search) {
                    data = {
                        ...(data || {}),
                        search: search,
                    };
                }
                if (colorState?.value === 'split') {
                    data = {
                        ...(data || {}),
                        splitColor: true,
                    };
                }
                if (inventorySelected) {
                    data.location = inventorySelected?.value === 'Yes';
                }
                if (formatFilters && formatFilters?.value) {
                    data = {
                        ...(data || {}),
                        ...(formatFilters || {}),
                    };
                }
                const fetchData = {
                    ...(paginationState || {}),
                    data,
                };
                searchParams.set('page', `${paginationState?.pageNumber}`)
                searchParams.set('size', `${paginationState?.pageSize}`)
                setSearchParams(searchParams)
                await dispatch(tryToFetchInventoryAgreggatedData(fetchData)).unwrap();
            }
        } catch (err) {
            // err here
        }
    };

    useEffect(() => {
        onGettingInventoryData();
    }, [paginationState]);

    useEffect(() => {
        onResetPaginationSettings();
    }, [activeWarehouse])

    const onClickCategory = (category: any) => {
        setSelectedCategory(category);
        searchParams.set('category', category?.label)
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const onClickMarket = (value: any, type?: string) => {
        setSelectedMarket(value || undefined);
        searchParams.set('market', value?.label)
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const onChangeFilterInput = (
        value: string | null | undefined,
        type: string
    ) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]: findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]:
                    findField?.dataType === 'boolean'
                        ? value?.value === 'true'
                        : value?.value || undefined,
            });
            onResetPaginationSettings();
        }
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const onClearAllFilters = () => {
        onResetPaginationSettings();
        setFilterValues(undefined);
        setInventorySelected(undefined);
        setSelectedMargins(undefined);
        setSelectedMarket(undefined);
        setActionSelected(undefined);
        setSearch(undefined);
        searchParams.delete('search')
        searchParams.delete('location')
        searchParams.delete('vat')
        searchParams.delete('category')
        searchParams.delete('market')
        searchParams.delete('color')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    };

    const onSeeAggregationDetails = (id: any) => {
        id = JSON.stringify(id);
        let url: string = `/inventory/${selectedCategory.id}/${id}`
        if (selectedMargins) {
            const formatMargins = selectedMargins?.map((margin: any) => margin?.value);
            url = url + `?vat=${formatMargins}`
        }
        if (colorState?.value === 'split') {
            const formatQuery = selectedMargins ? '&color=true' : '?color=true';
            url = url + formatQuery
        }
        if (search) {
            const formatQuery = selectedMargins || colorState?.value === 'split' ? `&search=${search}` : `?search=${search}`
            url = url + formatQuery
        }
        navigation(url);
    };

    const onClickInventoryFilter = (value: any, type?: string) => {
        searchParams.set('location', value?.label)
        setSearchParams(searchParams)
        setInventorySelected(value);
        onResetPaginationSettings()
    };

    const onClickColorState = (state: any) => {
        setColorState(state);
        searchParams.set('color', state?.value)
        setSearchParams(searchParams)
        if (state?.value !== colorState?.value) {
            onResetPaginationSettings();
        }
    };

    const onDownloadInventorySample = async () => {
        setShowDownloadInventory(!showDownloadInventory);
    };

    const onToggleImportModal = () => {
        setShowImportInventory(!showImportInventory);
    };

    const onChangeSelectedMargins = (e: any) => {
        setSelectedMargins(e?.length > 0 ? e : []);
        e?.length > 0 ? searchParams.set('vat', e?.map((s: any) => s?.value)) : searchParams.delete('vat')
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const onDownloadTemplate = async () => {
        setLoadingDownload(true)
        setTemplateData([['itemId', 'sales_grade']])
    }

    const onDownloadTemplateCosts = async () => {
        setLoadingDownloadCosts(true)
        setTemplateDataCosts([['itemId', 'value', 'type', 'description']])
    }

    useEffect(() => {
        if (templateDataCosts && loadingDownloadCosts) {
            csvLink1.current.link.click()
        }
        setLoadingDownloadCosts(false)
    }, [templateDataCosts])

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [templateData])

    const onToggleImportGradeModal = () => {
        setShowImportGradesModal(!showImportGradesModal)
    }

    const onToggleImportCostsModal = () => {
        setShowImportCostsModal(!showImportCostsModal)
    }

    const onChangeSelectedAction = (value: any) => {
        setActionSelected(value)
    }

    const onClickGoAction = () => {
        if (actionSelected) {
            switch (actionSelected?.label) {
                case 'Import Inventory':
                    onToggleImportModal()
                    break;
                case 'Download Sample':
                    onDownloadInventorySample()
                    break;
                case 'Import Sales Grade':
                    onToggleImportGradeModal()
                    break;
                case 'Download Sales Grade Sample':
                    onDownloadTemplate()
                    break;
                case 'Import COGs':
                    onToggleImportCostsModal()
                    break;
                case 'Download COGs Sample':
                    onDownloadTemplateCosts()
                    break;
            }
            setActionSelected(undefined)
        }
    }

    return (
        <div className='relative' onClick={() => setshowFilters(false)}>
            <div className='mb-5 flex flex-row items-center justify-between'>
                <p className='page-title'>Inventory</p>
            </div>
            <div className='my-5'>
                <InventoryCatalogFilters
                    items={brokerCategories || []}
                    activeButton={selectedCategory}
                    itemsCount={categoryCountItems}
                    onClickCategory={onClickCategory}
                />
            </div>
            {showFilters &&
                <InventoryCategories
                    activeButton={selectedCategory}
                    inventoryFilter={inventoryFilter}
                    templateFilters={templateFilters}
                    filterValues={filterValues}
                    onChangeSelectedFilterValue={onChangeSelectedFilterValue}
                    onChangeFilterInput={onChangeFilterInput}
                />
            }
            <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 items-center'>
                <InventoryMainFilter
                    markets={markets}
                    colorState={colorState}
                    selectedMargins={selectedMargins}
                    selectedMarket={selectedMarket}
                    inventorySelected={inventorySelected}
                    onClickColorState={onClickColorState}
                    onChangeSelectedMargins={onChangeSelectedMargins}
                    onClickInventoryFilter={onClickInventoryFilter}
                    onClickMarket={onClickMarket}
                />
                <div
                    onClick={(e) => {
                        if (!showFilters) e.stopPropagation()
                        setshowFilters(!showFilters)
                    }}
                    className='flex flex-row items-center cursor-pointer !bg-[#EBEDEF] hover:opacity-70 !rounded py-2 px-4'>
                    <img src={'/assets/table-actions/see-more.svg'} className={'h-[15px] max-h-[15px] object-contain'} />
                    <p className='text-[#202020] text-sm font-bold ml-2'>More Filters</p>
                </div>
                <Button
                    label={'Clear All'}
                    onClickButton={onClearAllFilters}
                    className={'btn-primary-text-underline'}
                />
            </div>
            <div>
                <div className='bg-white flex flex-row justify-between items-center'>
                    <div>
                        <Input
                            placeholder={'Search Product'}
                            dataQa={'search'}
                            containerStyle='mb-0 !ml-2 pb-0 relative min-w-[400px]'
                            inputStyle={`mb-0 border-1 !rounded focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={search}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className='bg-white px-3 py-2 flex flex-col items-end'>
                            <p className='text-primary-light'>Total Number of Inventory Items:</p>
                            <p className='text-[#202020] font-bold justify-end'>{totalCountItems || 0} {(totalCountItems && totalCountItems > 1) ? 'Items' : 'Item'}</p>
                        </div>
                        <div className='flex flex-row items-center'>
                            <SelectCheckbox
                                placeholder='Select an action'
                                selectedOption={actionSelected}
                                options={INVENTORY_ACTIONS}
                                containerStyle='!mb-0 mt-4 mx-3 !min-w-[250px] !max-w-[250px]'
                                onChangeSelectedOption={onChangeSelectedAction}
                            />
                            <Button
                                label={'Go'}
                                className={`${actionSelected && (!accessControl || pageAccess?.actions?.[actionSelected?.label]) && !loadingDownload && !loadingDownloadCosts ? 'btn-primary' : 'btn-primary-disable'} !rounded mx-2`}
                                onClickButton={onClickGoAction}
                            />
                        </div>
                    </div>
                </div>
                <CSVLink
                    ref={csvLink}
                    data={[]}
                    headers={templateData && templateData?.length > 0 ? templateData?.[0] : []}
                    className={'none'}
                    target={'_blank'}
                    filename={'Sales-grade-sample.csv'}>
                </CSVLink>
                <CSVLink
                    ref={csvLink1}
                    data={[]}
                    headers={templateDataCosts && templateDataCosts?.length > 0 ? templateDataCosts?.[0] : []}
                    className={'none'}
                    target={'_blank'}
                    filename={'COGs-costs-sample.csv'}>
                </CSVLink>
                <InventoryTable
                    rows={rows}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    isSplitColor={colorState?.value === 'split'}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    onSeeAggregationDetails={onSeeAggregationDetails}
                    category={{
                        id: selectedCategory?.id,
                        name: (selectedCategory?.displayName || '').toLowerCase(),
                    }}
                />
                {showImportInventory && (
                    <ImportInventoryModal
                        openImportModal={showImportInventory}
                        handleCloseModal={onToggleImportModal}
                        markets={markets || []}
                        sectors={brokerCategories || []}
                    />
                )}
                {showDownloadInventory && (
                    <DownloadSampleInventoryModal
                        openDownloadModal={showDownloadInventory}
                        handleCloseDownloadModal={onDownloadInventorySample}
                        sectors={brokerCategories || []}
                    />
                )}
                {showImportGradesModal &&
                    <ImportSalesGradeModal
                        openImportModal={showImportGradesModal}
                        sectors={brokerCategories}
                        handleCloseModal={onToggleImportGradeModal}
                    />
                }
                {showImportCostsModal &&
                    <ImportCostsModal
                        openImportModal={showImportCostsModal}
                        sectors={brokerCategories}
                        handleCloseModal={onToggleImportCostsModal}
                    />
                }
            </div>
        </div>
    );
};

export default Inventory;
