import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { onFormatPurchaseStatus } from '../../../utils/constants/purchase-statuses';
import Button from '../../../shared/button';


interface IPurchaseInfo {
    purchaseDetails: any;
    onClickGoToPurchase: () => void;
}

const PurchaseInfo = ({
    purchaseDetails,
    onClickGoToPurchase,
}: IPurchaseInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatPurchaseStatus(purchaseDetails?.status)?.name
        const color = onFormatPurchaseStatus(purchaseDetails?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatPurchaseStatus(purchaseDetails?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text,
        })
    }, [purchaseDetails?.status])

    return (
        <div className='bg-[#f8f9fc] rounded-[6px] p-3 border-y border-[#e4e4e4] my-6'>
            <p className='text-[20px]'>Purchase Details</p>
            <div className='grid grid-cols-4 span-2 mt-2 pb-2 gap-3'>
                <div className={`${orderStatusDetails?.color} !border rounded-md ${orderStatusDetails?.text} text-center py-1 px-3 font-semibold`}>
                    {orderStatusDetails?.name}
                </div>
                {purchaseDetails?.quoteId &&
                    <div className='mb-4 flex flex-row items-center'>
                        <span>Quote Id: </span>
                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(purchaseDetails?.quoteId)}>
                            <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                            <p className='text-[14px] font-semibold'>{purchaseDetails?.quoteId}</p>
                        </div>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.customer &&
                    <div className='mb-4'>
                        <span>Purchasing from: </span>
                        <span className='font-bold'>{purchaseDetails?.purchaseInfo?.customer?.name}</span>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.dueDate &&
                    <div className='mb-4'>
                        <span>Due Date: </span>
                        <span className='font-bold'>{moment(purchaseDetails?.purchaseInfo?.dueDate).format('DD MMMM YYYY')}</span>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.orderCreator &&
                    <div className='mb-4'>
                        <span>Order Created: </span>
                        <span className='font-bold'>{purchaseDetails?.purchaseInfo?.orderCreator}</span>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.stockVat &&
                    <div className='mb-4'>
                        <span>Stock VAT: </span>
                        <span className='font-bold'>{purchaseDetails?.purchaseInfo?.stockVat?.label || purchaseDetails?.purchaseInfo?.stockVat}</span>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.warranty &&
                    <div className='mb-4'>
                        <span>Warranty(Days): </span>
                        <span className='font-bold'>{purchaseDetails?.purchaseInfo?.warranty} days</span>
                    </div>
                }
                {purchaseDetails?.itemInfo?.type &&
                    <div className='mb-4'>
                        <span>Item Status: </span>
                        <span className='font-bold'>{purchaseDetails?.itemInfo?.type}</span>
                    </div>
                }
            </div>
            <div className='mt-5 mb-10 flex flex-row justify-center items-center'>
                <Button
                    label={'Go To Purchase Details'}
                    className='btn-main'
                    onClickButton={onClickGoToPurchase}
                />
            </div>
        </div>
    )
}
export default PurchaseInfo;