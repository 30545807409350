export const onFormatCrditNoteStatus = (status: string) => {
    switch (status) {
        case 'awaiting_credit':
            return { name: 'Draft', color: 'border-[#E50000]', text: 'text-[#E50000]' }
        // case 'issue':
        //     return { name: 'Issued Credit Note', color: 'border-[#E50000]', text: 'text-[#E50000]' }
        case 'received_credit':
            return { name: 'Received Credit Note', color: 'border-[#fb6f21]', text: 'text-[#fb6f21]' }
        case 'used_credit':
            return { name: 'Used Credit Note', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        default:
            return { name: status, color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
    }
}