import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { onFormatCrditNoteStatus } from '../../../../utils/constants/credit-note-statuses';
import Button from '../../../../shared/button';


interface INoteDetailsInfo {
    noteDetails: any;
    onClickShowMainView: () => void;
}

const NoteDetailsInfo = ({
    noteDetails,
    onClickShowMainView
}: INoteDetailsInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatCrditNoteStatus(noteDetails?.status)?.name
        const color = onFormatCrditNoteStatus(noteDetails?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatCrditNoteStatus(noteDetails?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text,
        })
    }, [noteDetails?.status])

    return (
        <div className='flex flex-row items-center justify-between rounded-md bg-white py-3 px-5 my-5'>
            <div className='flex flex-row items-center'>
                <div className={`${orderStatusDetails?.color} !border rounded-md ${orderStatusDetails?.text} mr-10 py-1 px-3 font-semibold`}>
                    {orderStatusDetails?.name}
                </div>
                {noteDetails?.creditNoteNumber &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Credit Note Number</p>
                        <p className='text-[14px]'>{noteDetails?.creditNoteNumber}</p>
                    </div>
                }
                {noteDetails?.referenceInvoiceNumber &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Reference Invoice Number</p>
                        <p className='text-[14px]'>{noteDetails?.referenceInvoiceNumber}</p>
                    </div>
                }
                {noteDetails?.referenceId &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>{noteDetails?.reference === 'purchase' ? 'Purchase' : noteDetails?.reference === 'sales' ? 'Sales' : ''} Order ID</p>
                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(noteDetails?.referenceId)}>
                            <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                            <p className='text-[14px]'>{noteDetails?.referenceId}</p>
                        </div>
                    </div>
                }
                {noteDetails?.purchaseInfo?.customer?.displayName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Customer</p>
                        <p className='text-[14px]'>{noteDetails?.purchaseInfo?.customer?.displayName}</p>
                    </div>
                }
                {noteDetails?.market?.label &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Market</p>
                        <p className='text-[14px]'>{noteDetails?.market?.label}</p>
                    </div>
                }
                {noteDetails?.receivedDate &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Received Date</p>
                        <p className='text-[14px]'>{moment(noteDetails?.receivedDate).format('DD-MM-YYYY')}</p>
                    </div>
                }
            </div>
            <div>
                <Button
                    label={'Main Order View'}
                    className='btn-dark'
                    onClickButton={onClickShowMainView}
                />
            </div>
        </div>
    )
}

export default NoteDetailsInfo;