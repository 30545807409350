import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToCreateWarehouseSection,
    tryToEditWarehouseSection,
    tryToFetchSingleWarehouseSection
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Textarea from '../../../../shared/textarea';
import SelectCheckbox from '../../../../shared/select-checkbox';
import SectionModalContainer from '../loading/SectionModalContainer';


interface IManageWarehouseSectionModal {
    markets?: any[];
    sectors?: any[];
    openActionsModal: boolean;
    itemId?: string;
    section?: string;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

const ManageWarehouseSectionModal = ({
    openActionsModal,
    itemId,
    sectors,
    section,
    handleCloseModal,
    onSaveChanges,
}: IManageWarehouseSectionModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.warehouseLocations);
    const [formData, setFormData] = useState<any>();
    const [formDataErrors, setFormDataErrors] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const sectionLoading = useAppSelector((state) => state.warehouseLocations.sectionIsLoading);
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        if (itemId && state.warehouseSectionDetails) {
            const data: any = {
                name: state?.warehouseSectionDetails?.name,
                barcode: state?.warehouseSectionDetails?.barcode,
                description: state?.warehouseSectionDetails?.description
            }
            const formatCategories = (state?.warehouseSectionDetails?.categories && state?.warehouseSectionDetails?.categories?.length > 0) && state?.warehouseSectionDetails?.categories?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
            setSelectedSector(formatCategories || undefined)

            setFormData(data)
        } else {
            setFormData(undefined)
            setSelectedSector(undefined)
        }
    }, [itemId, state.warehouseSectionDetails]);

    useEffect(() => {
        if (itemId) {
            dispatch(tryToFetchSingleWarehouseSection(itemId));
        }
    }, [itemId]);

    const onCloseModal = () => {
        setSelectedSector(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async () => {
        if (!formData?.name) {
            setSaveError('Please write Section name to continue')
            return;
        }
        setLoadingSubmit(true)
        const payload: any = {
            ...formData,
            section: section === 'other-section' ? 'other-section' : undefined,
            warehouseId: activeWarehouse?._id,
            categories: section !== 'other-section' ? (selectedSector && selectedSector?.length > 0) ? selectedSector?.map((item: any) => ({ id: item?.id })) : [] : []
        };
        try {
            if (itemId) {
                const formatData = {
                    id: itemId,
                    data: payload
                }
                await dispatch(tryToEditWarehouseSection(formatData)).unwrap();
            } else {
                await dispatch(tryToCreateWarehouseSection(payload)).unwrap();
            }
            onSaveChanges(
                'success',
                `Section successfully ${itemId ? 'changed' : 'added'}.`,
            );
            setFormData(undefined)
            setFormDataErrors(undefined)
            onCloseModal();
        } catch (error) {
            setSaveError(`${error}`);
        }
        setLoadingSubmit(false)
    };


    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e);
    };

    const onChangeFormDataInput = (value: any, type?: string) => {
        if (type) {
            setFormData((prevFormData: any) => ({
                ...prevFormData || {},
                [type]: value
            }))
        }
    }

    const onChangeTextareaInputs = (value: any, type?: string) => {
        if (type) {
            setFormData((prevFormData: any) => ({
                ...prevFormData || {},
                [type]: value?.target?.value
            }))
        }
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-10'}>
                <p className='mb-4 font-bold text-xl'>{section === 'other-section' ? 'Manage Custom Section' : 'Manage Section'}</p>
                {saveError && <Error text={saveError} />}
                {((itemId && !sectionLoading) || !itemId) ? (
                    <div className='flex flex-col my-4'>
                        <Input
                            label='Section Name'
                            disabled={!!itemId}
                            type='text'
                            containerStyle='max-w-[90%]'
                            showValue={true}
                            inputValue={formData?.name}
                            onChangeInput={onChangeFormDataInput}
                            inputUniqueName='name'
                            error={formDataErrors?.name}
                        />
                        {itemId &&
                            <Input
                                label='Barcode'
                                type='text'
                                containerStyle='max-w-[90%]'
                                showValue={true}
                                inputValue={formData?.barcode}
                                onChangeInput={onChangeFormDataInput}
                                inputUniqueName='barcode'
                                error={formDataErrors?.barcode}
                            />
                        }
                        {section !== 'other-section' &&
                            <SelectCheckbox
                                name='Categories'
                                placeholder=' '
                                containerStyle='mb-1 max-w-[90%]'
                                multiple={true}
                                selectedOption={selectedSector}
                                onChangeSelectedOption={onChangeSelectedSector}
                                options={sectors || []}
                            />
                        }
                        <Textarea
                            name={'Description'}
                            placeholder='Description'
                            containerStyle='!mb-0 !min-w-[90%] !max-w-[90%]'
                            rows={3}
                            value={formData?.description}
                            index={'description'}
                            textareaType='description'
                            onChangeTextarea={onChangeTextareaInputs}
                        />
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                onClickButton={() => !loadingSubmit && onSubmit()}
                                className={loadingSubmit ? 'btn-primary-disable' : 'btn-primary'}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='my-3'>
                        <SectionModalContainer />
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default ManageWarehouseSectionModal;
