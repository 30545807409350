import React, { useState, useEffect } from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IInventoryAggregated } from '../../../../interfaces/inventory-data/IInventoryAggregated';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Expandable from '../details/Expandable';
import Table from '../../../../shared/table';
import { useNavigate } from 'react-router-dom';


interface IInventoryTable {
    rows: IInventoryAggregated[];
    isSplitColor: boolean;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    onSeeAggregationDetails: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    category: {id: string, name: string}
}


const InventoryTable = ({
    rows,
    isSplitColor,
    paginationData,
    accessControl,
    actions,
    setPaginationState,
    onSeeAggregationDetails,
    category
}: IInventoryTable) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Items Number', 'Avg. Price', 'Market', 'Action', ''])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()
    const navigation = useNavigate();

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any = (rows && rows?.length > 0) && rows.flatMap(obj =>
            Object.keys(obj?.descriptor)
        );
        const uniqueKeysArray = (allKeysArray && allKeysArray?.length > 0) && allKeysArray?.filter((key: any, index: any, array: any) => array?.indexOf(key) === index);
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        if (uniqueKeysArray && uniqueKeysArray?.length > 0) {
            uniqueKeysArray?.filter((key: string) => (key || '').toLowerCase() !== 'condition')?.map((key: string) => {
                if ((key || '').toLowerCase() === 'salesgrade') {
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push('Sales Grade')
                } else {
                    const formatKey = capitalizeFirstLetter(key)
                    const formatElement = (formatKey || '').replaceAll('_', ' ')
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push(formatElement)
                }
                return key
            })
        }
        formatTableColumns.push('Items Number')
        formatTableColumns.push('Avg. Price')
        if (isSplitColor) {
            formatTableColumns.push('Colour')
        }
        formatTableColumns.push('Action')
        formatTableColumns.push('')
        setTableLoading(false)
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData, isSplitColor])

    const handleExpand = (id: string) => {
        setExpanded([...expanded, id]);
    };

    const handleCollapse = (id: string) => {
        setExpanded(expanded.filter((item: string) => item !== id));
    };

    const onClickSeeItemDetails = (id: string) => {
        navigation(`/inventory/details/${id}`)
    }

    const handleAggregationDetails = (row: any) => {
        let id = row?._id
        if(row?.color){
            id = {...id, descriptor: {...id.descriptor, color: row.color}}
            delete id.color
        }
        onSeeAggregationDetails(id)
    }

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
            headerStyle='!bg-black !text-white'
        >
            {(!tableColumns || tableLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IInventoryAggregated, idx: number) => {
                        const open = expanded.includes(JSON.stringify(row?._id));
                        const handleExpansion = () => {
                            if (open) {
                                handleCollapse(JSON.stringify(row?._id));
                            } else {
                                handleExpand(JSON.stringify(row?._id));
                            }
                        };
                        return (
                            <>
                                <tr key={idx} className={`bg-[${open ? '#F7A21E1A' : '#fff'}] border-b hover:bg-gray-50`}>
                                    {tableExtraKeys && tableExtraKeys?.length > 0 && tableExtraKeys?.map((key: string, index: number) => {
                                        return (
                                            <td
                                                key={index}
                                                className={'px-6 py-4'}>
                                                {row?.descriptor?.[key] || ''}
                                            </td>
                                        )
                                    })
                                    }
                                    <td className='px-6 py-4 min-w-[150px]'>{row?.total}</td>
                                    <td className='px-6 py-4'>
                                        {CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.totalPrice || 0).toFixed(2)}
                                    </td>
                                    {isSplitColor && <td className='px-6 py-4'>{row?.color || ''}</td>}
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row items-center gap-2'>
                                            <div onClick={() => row?._id && (!accessControl || actions?.['See Aggregation Details']) && handleAggregationDetails(row)}>
                                                <img title={'See Aggregation Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Aggregation Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className='px-6 py-4 cursor-pointer'>
                                        <div className='flex flex-row items-center gap-1' onClick={handleExpansion}>
                                            <img
                                                title={'See Aggregation Details'}
                                                alt='item details'
                                                src={`/assets/table-actions/${open ? 'arrow-up': 'arrow-down'}.svg`}
                                                className={`h-[${open ?  '26px': '14px'}] object-contain`}
                                            />
                                            <p className={`h-fit text-[${open ? '#F7A21E' : ''}]`}>
                                                {open ? 'Collapse' : 'Expand'}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                {open && (
                                    <Expandable
                                        idx={row?._id}
                                        expanded={open}
                                        colSpan={tableColumns.length}
                                        onClickId={onClickSeeItemDetails}
                                        accessControl={accessControl}
                                        actions={actions}
                                        category={category}
                                        isSplitColor={isSplitColor}
                                        onClickSeeMore={() => row?._id && (!accessControl || actions?.['See Aggregation Details']) && handleAggregationDetails(row)}
                                    />
                                )}
                            </>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default InventoryTable;