import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditWarehouseLocationBarcode } from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ILocationBarcodeModal {
    openModal: boolean;
    itemId: string;
    barcode: string;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

const LocationBarcodeModal = ({
    openModal,
    itemId,
    barcode,
    handleCloseModal,
    onSaveChanges,
}: ILocationBarcodeModal) => {
    const dispatch = useAppDispatch();
    const [newBarcode, setNewBarcode] = useState<any>();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    useEffect(() => {
        setNewBarcode(barcode)
    }, [barcode, itemId])

    const onCloseModal = () => {
        setNewBarcode(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async () => {
        if (!newBarcode) {
            setSaveError('Please write Barcode to continue')
            return;
        }
        setLoadingSubmit(true)
        const payload: any = {
            barcode: newBarcode
        };
        try {
            await dispatch(tryToEditWarehouseLocationBarcode({ id: itemId, data: payload })).unwrap();
            onSaveChanges(
                'success',
                'Barcode successfully changed.',
            );
            onCloseModal();
        } catch (error) {
            setSaveError(`${error}`);
        }
        setLoadingSubmit(false)
    };

    const onChangeBarcode = (value: any, type?: string) => {
        setNewBarcode(value)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-10'}>
                <p className='mb-4 font-bold text-xl'>Manage Section</p>
                {saveError && <Error text={saveError} />}
                <div className='flex flex-col my-4'>
                    <p className='mt-4 text-center'>
                        Current Location Barcode
                    </p>
                    <div className='max-w-[100%]'>
                        <Barcode value={barcode} width={1.2} height={60} fontSize={14} displayValue={true} />
                    </div>
                    <Input
                        label='Barcode'
                        type='text'
                        containerStyle='max-w-[90%]'
                        showValue={true}
                        inputValue={newBarcode}
                        onChangeInput={onChangeBarcode}
                        inputUniqueName='barcode'
                    />
                    <div className='flex flex-row justify-center mt-4'>
                        <Button
                            label='Save Changes'
                            onClickButton={() => !loadingSubmit && onSubmit()}
                            className={loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default LocationBarcodeModal;
