import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddCrmData,
    tryToEditCrmData,
    tryToFetchSingleCrmData
} from '../../../../store/inventory/broker/crm-data/crmDataSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';

interface IManageBankAccountModal {
    openModal: boolean;
    crmItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
};

const ManageBankAccountModal = ({
    openModal,
    crmItemId,
    handleCloseModal,
    onSaveChanges,
}: IManageBankAccountModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.crm);
    const bankLoading = useAppSelector((state) => state.crm?.crmIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', crmItemId ? state?.crmDetails?.name || '' : '');
        setValue('displayName', crmItemId ? state?.crmDetails?.displayName || '' : '');
    }, [state.crmDetails]);

    useEffect(() => {
        if (crmItemId) {
            dispatch(tryToFetchSingleCrmData(crmItemId));
        }
    }, [crmItemId]);

    const onSubmit = async (data: FormValues) => {
        const payload: any = {
            ...data || {},
            type: 'bank_account'
        }
        try {
            if (crmItemId) {
                await dispatch(tryToEditCrmData({ id: crmItemId, data: payload })).unwrap();
                onSaveChanges(
                    'success',
                    'Bank Account successfully changed.',
                );
            } else {
                await dispatch(tryToAddCrmData(payload))
                onSaveChanges(
                    'success',
                    'Bank Account successfully added.',
                );
            }
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        handleCloseModal();
    };

    const onCloseModal = () => {
        reset();
        handleCloseModal();
    };

    return (
        <Modal open={openModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>{crmItemId ? 'Edit' : 'Add New '} Bank Account</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(crmItemId && !bankLoading) || !crmItemId ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Name'
                                label={crmItemId ? 'Name' : ''}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Name is required',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                placeholder='Display Name'
                                label={crmItemId ? 'Display Name' : ''}
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Display Name is required',
                                })}
                                error={errors.displayName?.message}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default ManageBankAccountModal;