export const PURCHASE_ORDER_STATUS = [
    { value: 'draft', name: 'Draft', label: 'Draft' },
    { value: 'accepted', name: 'Approved', label: 'Accepted' },
    { value: 'on_delivery', name: 'On Delivery', label: 'On Delivery' },
    { value: 'delivered', name: 'Delivered', label: 'Delivered' },
    { value: 'in_review', name: 'In Review', label: 'In Review' },
    { value: 'completed', name: 'Completed', label: 'Completed' },
    { value: 'split_discrepancy', name: 'Split Discrepancies', label: 'Split Discrepancies' },
    { value: 'discrepancy', name: 'Discrepancy', label: 'Discrepancy' },
    { value: 'rma_in_progress', name: 'RMA In Progress', label: 'RMA In Progress' },
    { value: 'rma_shipped', name: 'RMA Shipped', label: 'RMA Shippeds' }
]