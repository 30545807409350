import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchSingleWarehouse } from '../../../store/inventory/broker/warehouses/warehousesSlice';
import ItemLocation from './tabs/ItemLocation';
import BoxLocation from './tabs/BoxLocation';
import Tabs from './tabs/Tabs';


const tabs = [
    { name: 'Item Location', element: <ItemLocation /> },
    { name: 'Custom Section Child', element: <BoxLocation /> },
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StockMove = () => {
    const query = useQuery();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(tabs[0]);
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state.warehouses);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Stock Move')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[tabs[0]?.name] && !query?.get('type') ? tabs[0] : tabs?.find((item: any) => findChild?.actions?.[item?.name] && (query?.get('type') === item?.name || !query?.get('type')))
            setActiveTab(findActiveCategory)
        }
    }, [accessControl])

    useEffect(() => {
        if (state.warehouse) {
            // warehouse data here
        }
    }, [state.warehouse]);

    useEffect(() => {
        if (activeWarehouse?._id) {
            dispatch(tryToFetchSingleWarehouse(activeWarehouse?._id));
        }
    }, [activeWarehouse]);

    useLayoutEffect(() => {
        if (query?.get('type') && (query?.get('type') !== activeTab?.name)) {
            const findActiveTab = tabs?.find((item) => (item?.name === query?.get('type')))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    const onClickTab = (value: any) => {
        setActiveTab(value)
        searchParams.set('type', value?.name)
        setSearchParams(searchParams)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Stock Move</p>
            </div>
            <Tabs
                activeTab={activeTab.name}
                onClickTab={onClickTab}
                tabs={tabs?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name])) || []}
            />
            <div>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? activeTab?.element : ''}
            </div>
        </div>
    )
}
export default StockMove