import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISales } from '../../../../interfaces/sales/ISales';
import {
    tryToEditSaleOrderStatuses,
    tryToShowSaleSplit
} from '../../../../store/inventory/shared/sales/salesSlice';
import ReviewTable from '../tables/ReviewTable';
import AcceptItemModal from '../modals/AcceptItemModal';
import RejectItemModal from '../modals/RejectItemModal';
import ScanItemsModal from '../modals/ScanItemsModal';
import RequestModal from '../modals/RequestModal';
import SplitModal from '../modals/SplitModal';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IReviewStatus {
    saleDetails?: ISales;
    saleId: string;
    accessControl?: any;
    pageAccess?: any;
    saleCategories?: Array<any>;
    salesContent?: any;
    templateFields?: any;
    onGettingPurchaseData: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const ReviewStatus = ({
    saleId,
    saleDetails,
    accessControl,
    pageAccess,
    saleCategories,
    salesContent,
    templateFields,
    onGettingPurchaseData,
    onSuccessfullyChanged
}: IReviewStatus) => {
    const dispatch = useAppDispatch();
    const [saleItems, setSaleItems] = useState<any>();
    const [selectedItems, setSelectedItems] = useState<any>();
    const [requestedItems, setRequestedItems] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [showSplitModal, setShowSplitModal] = useState<boolean>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [showAcceptModal, setShowAcceptModal] = useState<{ show: boolean, item: any, acceptedItem: any }>();
    const [showRejectModal, setShowRejectModal] = useState<{ show: boolean, item: any, rejectedItem: any }>();
    const [showScanModal, setShowScanModal] = useState<{ show: boolean, data?: any }>();
    const [showRequestModal, setShowRequestModal] = useState<{ show: boolean, data?: any, requestedItem?: any, missingItems?: number, replace?: boolean }>();
    const findRejectedItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'rejected' && item?.match)
    const matchingItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'matching' || item?.status === 'accepted')
    const notMatchingItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'not_matching')
    const totalItems = saleDetails?.saleItems && saleDetails?.saleItems?.length > 0 && saleDetails?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + currentValue?.qty, 0)
    const selectedItemsWithoutExtra = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.match)?.length || 0
    const notTreatedExtraItem = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => item?.status === 'extra_item')

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const categoryFields = category && templateFields?.[category]
        const formatObj1: any = obj1
        let formatObj2: any = obj2
        if (categoryFields && category) {
            formatObj2 = {}
            const moreKeys: any = obj1 && (Object?.keys(obj1))?.filter((item: any, index: number) => !(categoryFields?.find((field: any) => field?.name === item)))
            obj2 && Object.keys(obj2)?.map((key: any, index: number) => {
                const findInCategories = categoryFields?.find((field: any) => field?.name === key)
                const findInMoreKeys = moreKeys && moreKeys?.length > 0 && moreKeys?.find((obj: any) => obj === key)
                if (findInCategories || findInMoreKeys) {
                    formatObj2[key] = obj2[key];
                }
                return key
            }, {});
        }
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    const onFindIfAnyRequestedItems = (item: any) => {
        const findRequestedItems = requestedItems && requestedItems?.length > 0 && requestedItems?.find((requested: any) => areObjectsEqual(item?.descriptor, requested?.match?.descriptor, item?.categoryId) && areObjectsEqual(item?.inventory, requested?.match?.inventory) && item?.price === requested?.match?.price)
        if (findRequestedItems) {
            return true
        }
        else return false
    }

    const notReviewedItems = (saleItems && saleItems?.length > 0) && saleItems?.filter((item: any) => !item?.reviewed && onFindIfAnyRequestedItems(item))

    const onClickShowSplitModal = () => {
        setShowSplitModal(true)
        document.body.style.overflow = 'hidden';
    }

    const onClickShowRequestModal = (data?: any, requestedItems?: any, missingItems?: number, replace?: boolean) => {
        document.body.style.overflow = 'hidden';
        setShowRequestModal({
            show: true,
            data: data,
            requestedItem: requestedItems,
            missingItems: missingItems,
            replace: replace
        })
    }

    const onClickShowAcceptModal = (item?: any, acceptedItem?: any) => {
        document.body.style.overflow = 'hidden';
        setShowAcceptModal({
            show: true,
            item: item,
            acceptedItem: acceptedItem,
        })
    }

    const onClickShowRejectModal = (item?: any, rejectedItem?: any) => {
        document.body.style.overflow = 'hidden';
        setShowRejectModal({
            show: true,
            item: item,
            rejectedItem: rejectedItem,
        })
    }

    const onHideModals = () => {
        setShowRequestModal(undefined)
        setShowScanModal(undefined)
        setShowAcceptModal(undefined)
        setShowRejectModal(undefined)
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
        setSaleItems(saleDetails?.saleItems)
        setSelectedItems(saleDetails?.selectedItems)
        setRequestedItems(saleDetails?.requestedItems)
    }, [saleDetails])

    const onApproveRequestItems = (newSelectedItems: any, oldRequestedItems: any, item?: any, replace?: boolean) => {
        if (item) {
            if (replace) {
                const reformatSaleItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((sale: any) => JSON.stringify(sale) !== JSON.stringify(item))
                setSelectedItems([
                    ...reformatSaleItems || [],
                    ...newSelectedItems || []
                ])
            }
            const reformatSales = (saleItems && saleItems?.length > 0) && saleItems?.map((sale: any) => {
                if (JSON.stringify(sale) === JSON.stringify(item)) {
                    return {
                        ...sale || {},
                        reviewed: true,
                    }
                }
                else return sale
            })
            setSaleItems(reformatSales)
        }
        const filterRequestedItems: any = [];
        (requestedItems && requestedItems?.length > 0) && requestedItems?.forEach((item: any) => {
            const findOldRequested = (oldRequestedItems && oldRequestedItems?.length > 0) && oldRequestedItems?.find((old: any) => JSON.stringify(old) === JSON.stringify(item))
            if (!findOldRequested) {
                filterRequestedItems?.push(item)
            }
        })
        setRequestedItems([
            ...filterRequestedItems || [],
            ...newSelectedItems || []
        ])
    }

    const onFindSaleStatus = (missingItems?: any) => {
        if (missingItems || (findRejectedItems && findRejectedItems?.length > 0)) {
            return 'processing';
        }
        return 'processed'
    }

    const onValidateSubmit = async (isConfirm: boolean) => {
        setLoadingSubmit(true)
        const totalRequestedItems = requestedItems?.length || 0
        const findAnyMissingItems = totalItems - (totalRequestedItems + selectedItemsWithoutExtra)
        if (saleDetails?.completedReviewed && isConfirm) {
            if (findAnyMissingItems && selectedItemsWithoutExtra) {
                onClickShowSplitModal()
                setLoadingSubmit(false)
                setGeneralError(undefined)
                return;
            }
        }
        setGeneralError(undefined)
        const status = isConfirm ? onFindSaleStatus(findAnyMissingItems) : 'review'
        try {
            let reformatSelectedItems: any = selectedItems || []
            let requestedItemsFormated: any = requestedItems || []
            if (isConfirm) {
                reformatSelectedItems = (selectedItems && selectedItems?.length > 0) ? selectedItems?.filter((item: any) => !(!item?.match && item?.status === 'rejected')) : []
                requestedItemsFormated = [];
                requestedItems && requestedItems?.length > 0 && requestedItems?.forEach((item: any) => {
                    const findIfReviewed = saleItems && saleItems?.length > 0 && saleItems?.find((saleItem: any) => areObjectsEqual(item?.match?.descriptor, saleItem?.descriptor, item?.categoryId) && areObjectsEqual(item?.match?.inventory, saleItem?.inventory) && item?.match?.price === saleItem?.price && saleItem?.reviewed)
                    if (!findIfReviewed) {
                        requestedItemsFormated?.push(item)
                    } else {
                        reformatSelectedItems?.push({
                            ...item || {},
                            status: 'matching'
                        })
                    }
                })
            }
            let formatData: any = {
                ...saleDetails || {},
                requestedItems: requestedItemsFormated,
                selectedItems: reformatSelectedItems,
                saleItems: saleItems,
                status: status,
                completedReviewed: true
            }
            if (isConfirm) {
                formatData = {
                    ...formatData || {},
                    completed: true
                }
            }
            await dispatch(tryToEditSaleOrderStatuses({ id: saleId, data: formatData })).unwrap();
            onGettingPurchaseData();
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onClickShowScanModal = (data?: any) => {
        document.body.style.overflow = 'hidden';
        setShowScanModal({
            show: true,
            data: data
        })
    }

    const onAddScannedItem = (data: any) => {
        setSelectedItems((prevSelectedItems: any) => [
            ...prevSelectedItems || [],
            data || {}
        ])
    }

    const onAcceptSuccessfully = (acceptedItem: any, mainItem?: any) => {
        const formatSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.map((item: any) => {
            const findIfItem = acceptedItem?.itemId ? item?.itemId === acceptedItem?.itemId : JSON.stringify(item) === JSON.stringify(acceptedItem)
            if (findIfItem) {
                return {
                    ...item,
                    status: 'accepted',
                    notMatching: false,
                }
            }
            else return item
        })
        setSelectedItems(formatSelectedItems || [])
    }

    const onRejectSuccessfully = (rejectedItem: any, mainItem?: any) => {
        const formatSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.map((item: any) => {
            const findIfItem = rejectedItem?.itemId ? item?.itemId === rejectedItem?.itemId : JSON.stringify(item) === JSON.stringify(rejectedItem)
            if (findIfItem) {
                return {
                    ...item,
                    status: 'rejected'
                }
            }
            else return item
        })
        setSelectedItems(formatSelectedItems || [])
    }

    const onHideSplitModal = () => {
        setShowSplitModal(false)
        document.body.style.overflow = 'auto';
        dispatch(tryToShowSaleSplit(false)).unwrap();
    }

    const showConfirm = !(notMatchingItems && notMatchingItems?.length > 0) && !(notReviewedItems && notReviewedItems?.length > 0) && !(notTreatedExtraItem && notTreatedExtraItem?.length > 0)
    return (
        <div className='my-5'>
            <div className='bg-white !rounded my-3 pb-4'>
                <p className='font-semibold text-[18px] p-3'>Items</p>
                <ReviewTable
                    secondReview={saleDetails?.completedReviewed}
                    rows={saleItems}
                    reservedItems={saleDetails?.reservedItems}
                    requestedItems={requestedItems}
                    accessControl={accessControl}
                    pageAccess={pageAccess}
                    selectedItems={selectedItems}
                    categories={saleCategories}
                    salesContent={salesContent}
                    templateFields={templateFields}
                    onClickAcceptItem={onClickShowAcceptModal}
                    onClickRejectItem={onClickShowRejectModal}
                    onScanItem={onClickShowScanModal}
                    onReviewItem={onClickShowRequestModal}
                />
                {generalError &&
                    <div className='my-3'>
                        <Error text={generalError} />
                    </div>
                }
                <div className='my-4 flex flex-row items-center border-t pt-4 mx-5'>
                    <Button
                        label={'Save for now'}
                        className={`${!loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Review']) ? 'btn-main' : 'btn-primary-disable'} !rounded mr-3`}
                        onClickButton={() => !loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Review']) && onValidateSubmit(false)}
                    />
                    <Button
                        label={'Confirm'}
                        className={`${(!loadingSubmit && showConfirm) && (!accessControl || pageAccess?.actions?.['Confirm Review']) ? 'btn-primary' : 'btn-primary-disable'} !rounded !shadow-none`}
                        onClickButton={() => (!loadingSubmit && showConfirm) && (!accessControl || pageAccess?.actions?.['Confirm Review']) && onValidateSubmit(true)}
                    />
                </div>
            </div>
            {showRequestModal?.show &&
                <RequestModal
                    openModal={showRequestModal?.show}
                    saleId={saleId || ''}
                    prevRequestedItems={showRequestModal?.requestedItem}
                    review={true}
                    replace={showRequestModal?.replace}
                    missingItems={showRequestModal?.missingItems || 0}
                    categories={saleCategories}
                    itemDetailsInfo={showRequestModal?.data}
                    handleCloseModal={onHideModals}
                    onApproveAction={onApproveRequestItems}
                />
            }
            {showScanModal?.show &&
                <ScanItemsModal
                    openModal={showScanModal?.show}
                    rowSelected={showScanModal?.data}
                    categories={saleCategories}
                    reservedItems={saleDetails?.reservedItems}
                    handleCloseModal={onHideModals}
                    onAddScannedItem={onAddScannedItem}
                />
            }
            {showAcceptModal?.show &&
                <AcceptItemModal
                    openModal={showAcceptModal?.show}
                    saleId={saleId}
                    itemDetailsInfo={showAcceptModal?.acceptedItem}
                    mainItem={showAcceptModal?.item}
                    handleCloseModal={onHideModals}
                    onSuccessfullyAccept={onAcceptSuccessfully}
                />
            }
            {showRejectModal?.show &&
                <RejectItemModal
                    openModal={showRejectModal?.show}
                    saleId={saleId}
                    itemDetailsInfo={showRejectModal?.rejectedItem}
                    mainItem={showRejectModal?.item}
                    handleCloseModal={onHideModals}
                    onSuccessfullyReject={onRejectSuccessfully}
                />
            }
            {showSplitModal &&
                <SplitModal
                    openModal={showSplitModal}
                    saleId={saleId}
                    rejected={findRejectedItems?.length || 0}
                    matching={matchingItems?.length || 0}
                    missing={(totalItems || 0) - (selectedItemsWithoutExtra || 0) - (requestedItems?.length)}
                    notMatching={notMatchingItems?.length || 0}
                    saleDetails={saleDetails}
                    selectedItems={selectedItems}
                    requestedItems={requestedItems}
                    saleItems={saleItems}
                    handleCloseModal={onHideSplitModal}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                />
            }
        </div>
    )
}
export default ReviewStatus;