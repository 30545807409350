import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { PaginatedPurchaseRequests } from '../../../../interfaces/purchase-requests/paginatedStore.type';


const tryToFetchSaleRequests = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedPurchaseRequests>> => {
    const url = `${INVENTORY_API_URL}/broker-sales?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedPurchaseRequests>(url, data);
};

const tryToFetchSingleSaleRequest = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/broker-sales/${id}`);
};

const tryToAddSaleRequestNote = (id: string, data: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-sales/${id}/notes`, data);
};

const tryToFetchSaleRequestNote = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/broker-sales/${id}/notes`);
};

const tryToSubmitSaleRequestOffer = (id: string, status: string, data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-sales/${id}/status/${status}`, data);
};

const tryToFetchAllSaleRequest = (data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/broker-sales/all`, data);
};

export const SaleRequestsService = {
    tryToFetchSaleRequests,
    tryToFetchSingleSaleRequest,
    tryToAddSaleRequestNote,
    tryToFetchSaleRequestNote,
    tryToSubmitSaleRequestOffer,
    tryToFetchAllSaleRequest
};