import React from 'react';
import { ISales } from '../../../../interfaces/sales/ISales';
import DraftTable from '../tables/DraftTable';

interface IDraftStatus {
    saleDetails?: ISales;
    saleCategories?: Array<any>;
    salesContent?: any;
}

const DraftStatus = ({
    saleDetails,
    saleCategories,
    salesContent
}: IDraftStatus) => {
    return (
        <div className='bg-white !rounded my-5 pb-4'>
            <p className='font-semibold text-[18px] p-3'>Items</p>
            <DraftTable
                rows={saleDetails?.saleItems}
                categories={saleCategories}
                salesContent={salesContent}
            />
        </div>
    )
}
export default DraftStatus;