import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAllCrmData } from '../../../store/inventory/broker/crm-data/crmDataSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import {
    tryToAcceptPurchase,
    tryToDeletePurchase,
    tryToFetchPurchases,
    tryToPayPurchase
} from '../../../store/inventory/broker/purchases/purchasesSlice';
import PurchasePackagesModal from './modals/PurchasePackagesModal';
import BatchingCompleteModal from './modals/BatchingCompleteModal';
import AcceptPurchaseModal from './modals/AcceptPurchaseModal';
import AllCategoriesModal from './modals/AllCategoriesModal';
import PaidPurchaseModal from './modals/PaidPurchaseModal';
import PurchasesFilters from './details/PurchasesFilters';
import MoreDetailsModal from './modals/MoreDetailsModal';
import PurchaseTable from './tables/PurchasesTable';
import AddNotesModal from './modals/AddNotesModal';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Purchases = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [rows, setRows] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>();
    const [selectedOrderStatus, setSelectedOrderStatus] = useState<string>(query?.get('filter') || 'draft');
    const [selectedCompany, setSelectedCompany] = useState<any>();
    const [brokerCompanies, setBrokerCompanies] = useState<Array<any>>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [purchaseId, setPurchaseId] = useState<string>();
    const [quoteId, setQuoteId] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
    const [showAcceptedModal, setShowAcceptedModal] = useState<boolean>(false);
    const [showPaidModal, setShowPaidModal] = useState<{ show: boolean, date?: string }>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [showDeliveredModal, setShowDeliveredModal] = useState<boolean>(false);
    const [loadingActionSubmit, setLoadingActionSubmit] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showCategoriesModal, setShowCategoriesModal] = useState<{ show: boolean, data: any }>();
    const [showBatchingModal, setShowBatchingModal] = useState<{ show: boolean, data?: any }>();
    const [showDetailsModal, setShowDetailsModal] = useState<{ show: boolean, type: string, data: any, allData?: any }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10
    });
    const [userProfileMutation] = useUserProfileMutation();
    const state = useAppSelector((state) => state.purchases);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const purchaseLoading = useAppSelector((state) => state?.purchases?.purchasesAreLoading);
    const paginationData = useAppSelector((state) => state?.purchases?.purchases?.data?.page);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchases')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    const getBrokerCompanies = async () => {
        const response: any = await dispatch(tryToFetchAllCrmData({ type: 'company' }))
        const formatCompanies: any = (response?.payload && response?.payload?.data?.length > 0) ? response?.payload?.data?.map((s: any) => ({ label: s?.displayName || s?.name, name: s?.displayName || s?.name, value: s?._id })) : []
        let findUrlCompany: any
        if (query?.get('company')) {
            findUrlCompany = formatCompanies && formatCompanies?.length > 0 && formatCompanies?.find((company: any) => company.value === query?.get('company'))
        }
        setSelectedCompany(findUrlCompany || undefined)
        setBrokerCompanies(formatCompanies || [])
    }

    const onChangeFilterSelected = (value: any, type?: string) => {
        switch (type) {
            case 'companies':
                searchParams.set('company', value?.value)
                setSelectedCompany(value || '')
                break;
            case 'order':
                if (selectedOrderStatus === value) {
                    searchParams.delete('filter')
                } else {
                    searchParams.set('filter', value)
                }
                setSelectedOrderStatus(value)
                break;
            case 'category':
                searchParams.set('category', value)
                setSelectedCategory(value || '')
                break;
        }
        onResetPaginationSettings()
    }

    const onClickCategory = (category: any) => {
        setSelectedCategory(category);
        searchParams.set('category', category?.label)
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap();
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = [...formatSectors || [], { value: 'allCategories', label: 'All Categories' }]?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || formatSectors?.[0] || undefined)
        } else {
            setSelectedCategory(selectedCategory || { value: 'allCategories', label: 'All Categories' });
            searchParams.set('category', selectedCategory?.label || 'All Categories')
            setSearchParams(searchParams)
        }
        onResetPaginationSettings();
        setBrokerCategories(formatSectors || []);
    }

    useLayoutEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        let filterState: any = false
        if (query?.get('filter')) {
            setSelectedOrderStatus(query?.get('filter') || 'draft')
            filterState = true
        }
        if (query?.get('search') && query?.get('search') !== search) {
            setSearch(query?.get('search') || undefined)
            filterState = true
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = [...brokerCategories || [], { value: 'allCategories', label: 'All Categories' }]?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || { value: 'allCategories', label: 'All Categories' })
            filterState = true
        }
        if (query?.get('company') && query?.get('company') !== selectedCompany?.label) {
            const findUrlCompany = brokerCompanies && brokerCompanies?.length > 0 && brokerCompanies?.find((company: any) => company.value === query?.get('company'))
            setSelectedCompany(findUrlCompany || undefined)
            filterState = true
        }
        if (filterState) {
            onResetPaginationSettings();
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
        query?.get('filter'),
        query?.get('category'),
        query?.get('company')
    ])

    useEffect(() => {
        getBrokerCompanies()
        getBrokerSectors()
        localStorage.setItem('prev', 'purchases')
    }, [])

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    useEffect(() => {
        if (state.purchases && selectedCategory) {
            const rows =
                state.purchases?.data?.elements && Array.isArray(state.purchases?.data.elements)
                    ? state.purchases?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.purchases]);

    const onGettingPurchaseData = async () => {
        if (selectedCategory && selectedOrderStatus) {
            try {
                let data: any = {}
                if (selectedCategory && selectedCategory?.value !== 'allCategories') {
                    data = {
                        ...data || {},
                        assetTemplateId: selectedCategory?.id,
                    }
                }
                if (activeWarehouse) {
                    data = {
                        ...data || {},
                        warehouse: activeWarehouse?._id,
                    }
                }
                if (search) {
                    data = {
                        ...data || {},
                        search: search,
                    }
                }
                if (selectedOrderStatus) {
                    data = {
                        ...data || {},
                        status: selectedOrderStatus,
                    }
                }
                if (selectedCompany) {
                    data = {
                        ...data || {},
                        company: selectedCompany?.value || '',
                    }
                }
                const fetchData = {
                    ...paginationState || {},
                    data
                }
                searchParams.set('page', `${paginationState?.pageNumber}`)
                searchParams.set('size', `${paginationState?.pageSize}`)
                setSearchParams(searchParams)
                await dispatch(tryToFetchPurchases(fetchData)).unwrap()
            } catch (err) {
                // err
            }
        }
    }

    useEffect(() => {
        if (selectedCategory && selectedOrderStatus) {
            onGettingPurchaseData()
        }
    }, [paginationState, selectedOrderStatus])

    const onOpenPurchaseForm = () => {
        navigator(`/purchases/form`)
    }

    const onClickEditPurchase = (id: string) => {
        navigator(`/purchases/form/${id}`)
    }

    const onSeePurchaseDetails = (id: string) => {
        navigator(`/purchases/${id}`)
    }

    const onClickSeeAllCategories = (data?: any) => {
        if (data) {
            setShowCategoriesModal({
                show: true,
                data: data
            })
            document.body.style.overflow = 'hidden';
        } else {
            setShowCategoriesModal(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onClickShowHideNote = (id?: string) => {
        if (id) {
            setShowNoteModal(true)
            setPurchaseId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowNoteModal(false)
            setPurchaseId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onAddedNoteSuccessfull = () => {
        setShowToast({ type: 'success', message: 'Note added successfully' });
    }

    const onDeletePurchase = () => {
        try {
            dispatch(tryToDeletePurchase(purchaseId || '')).unwrap()
            setShowToast({ type: 'success', message: 'Purchase was deleted successfully' });
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        setShowDeleteModal(false)
        setPurchaseId(undefined)
    }

    const onClickDeletePurchase = (id?: string) => {
        setShowDeleteModal(!!id)
        setPurchaseId(id || undefined)
    }

    const onAcceptedPurchaseOrder = async () => {
        setLoadingActionSubmit(true)
        try {
            await dispatch(tryToAcceptPurchase(purchaseId || '')).unwrap()
            setShowToast({ type: 'success', message: 'Purchase successfully approved' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setLoadingActionSubmit(false)
        setShowAcceptedModal(false)
        setPurchaseId(undefined)
    }

    const onCloseActionsModal = () => {
        setPurchaseId(undefined)
        setQuoteId(undefined)
        setShowAcceptedModal(false)
        setShowDeliveredModal(false)
        setShowPaidModal(undefined)
        setShowBatchingModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const onPaidPurchaseOrder = async (date?: string) => {
        setLoadingActionSubmit(true)
        try {
            await dispatch(tryToPayPurchase({ id: purchaseId || '', dueDate: date }))
            setShowToast({ type: 'success', message: 'Purchase successfully marked as paid' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setShowAcceptedModal(false)
        setPurchaseId(undefined)
        setLoadingActionSubmit(false)
        onCloseActionsModal()
    }

    const onClickStatusChange = (id: string, type: string, data?: any) => {
        switch (type) {
            case 'accepted':
                setShowAcceptedModal(true)
                break;
            case 'paid':
                setShowPaidModal({ show: true, date: data })
                document.body.style.overflow = 'hidden';
                break;
            case 'markDelivered':
                setShowDeliveredModal(true)
                document.body.style.overflow = 'hidden';
                break;
            case 'boxDetails':
                setShowDeliveredModal(true)
                document.body.style.overflow = 'hidden';
                break;
            case 'review':
                navigator(`/purchases/${id}/review`)
                break;
            case 'batch_completed':
                setShowBatchingModal({ show: true, data: data })
                document.body.style.overflow = 'hidden';
                break;
        }
        setPurchaseId(id)
        setQuoteId(data)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onSuccessSavedPackageBoxes = () => {
        document.body.style.overflow = 'auto';
        setShowToast({ type: 'success', message: 'Purchase Packages saved successfully' })
        setQuoteId(undefined)
    }

    const onClickDownloadLabel = (label: string) => {
        window.open(label, '_blank');
    }

    const onClickSeeMoreDetails = (type: string, data: any, allData?: any) => {
        document.body.style.overflow = 'hidden';
        setShowDetailsModal({
            type,
            show: true,
            data,
            allData
        })
    }

    const onClickHideMoreDetails = () => {
        document.body.style.overflow = 'auto';
        setShowDetailsModal(undefined)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Purchases</p>
            </div>
            <div className='mt-10 mb-7'>
                <PurchasesFilters
                    companyValues={brokerCompanies}
                    categories={brokerCategories}
                    selectedCategory={selectedCategory}
                    selectedCompany={selectedCompany}
                    selectedOrder={selectedOrderStatus}
                    onClickCategory={onClickCategory}
                    onChangeSelectedValue={onChangeFilterSelected}
                />
            </div>
            <div className='mb-[80px]'>
                <div className={`grid grid-cols-10 bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                    <div className={`col-span-5`}>
                        <Input
                            placeholder={'Filter Table'}
                            dataQa={'search'}
                            containerStyle='mb-0 pb-0 relative'
                            inputStyle={`mb-0 border-0 focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={search}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <div className='col-span-3'></div>
                    {/* <div className='col-span-1 flex flex-row justify-end'>
                        <Button
                            label='Clear Filters'
                            className={'btn-primary-text-underline min-w-auto'}
                            onClickButton={() => onClearAllFilters()}
                        />
                    </div> */}
                    <div className='col-span-2 flex flex-row justify-end'>
                        <Button
                            label='Create Purchase'
                            className={`${(!accessControl || pageAccess?.actions?.['Create Purchase']) ? 'btn-primary' : 'btn-primary-disable'}`}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Create Purchase']) && onOpenPurchaseForm()}
                        />
                    </div>
                </div>
                <PurchaseTable
                    rows={rows}
                    purchaseLoading={purchaseLoading}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    brokerCategories={brokerCategories}
                    activeFilter={selectedOrderStatus || ''}
                    onClickSeeAllCategories={onClickSeeAllCategories}
                    onClickDownloadLabel={onClickDownloadLabel}
                    onClickStatusChange={onClickStatusChange}
                    setPaginationState={setPaginationState}
                    onEditPurchase={onClickEditPurchase}
                    onViewContents={onSeePurchaseDetails}
                    onDeletePurchase={onClickDeletePurchase}
                    onClickShowNote={onClickShowHideNote}
                    onClickSeeMoreDetails={onClickSeeMoreDetails}
                />
            </div>
            {showNoteModal &&
                <AddNotesModal
                    openModal={showNoteModal}
                    purchaseId={purchaseId || ''}
                    handleCloseModal={onClickShowHideNote}
                    onSuccessfullyAddedNote={onAddedNoteSuccessfull}
                />
            }
            {showDeleteModal &&
                <DeleteModal
                    openDeleteModal={showDeleteModal}
                    handleCloseDeleteModal={onClickDeletePurchase}
                    onApproveDeletion={onDeletePurchase}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showAcceptedModal &&
                <AcceptPurchaseModal
                    openModal={showAcceptedModal}
                    loading={loadingActionSubmit}
                    handleCloseModal={onCloseActionsModal}
                    onApproveAction={onAcceptedPurchaseOrder}
                />
            }
            {showPaidModal?.show &&
                <PaidPurchaseModal
                    openModal={showPaidModal?.show}
                    loading={loadingActionSubmit}
                    dueDate={showPaidModal?.date}
                    handleCloseModal={onCloseActionsModal}
                    onApproveAction={onPaidPurchaseOrder}
                />
            }
            {showDeliveredModal &&
                <PurchasePackagesModal
                    openModal={showDeliveredModal}
                    purchaseId={purchaseId || ''}
                    quoteId={quoteId || ''}
                    selectedOrderStatus={selectedOrderStatus}
                    expectedItems={(rows?.length > 0) ? rows?.find((item: any) => item?._id === purchaseId)?.expectedItems || [] : []}
                    handleCloseModal={onCloseActionsModal}
                    onApproveAction={onSuccessSavedPackageBoxes}
                />
            }
            {showBatchingModal?.show &&
                <BatchingCompleteModal
                    openModal={showBatchingModal?.show}
                    purchaseId={purchaseId || ''}
                    quoteId={quoteId?.quoteId || ''}
                    selectedOrderStatus={selectedOrderStatus}
                    dueDate={showBatchingModal?.data?.purchaseInfo?.dueDate}
                    expectedItems={showBatchingModal?.data?.expectedItems}
                    handleCloseModal={onCloseActionsModal}
                />
            }
            {showDetailsModal?.show &&
                <MoreDetailsModal
                    openModal={showDetailsModal?.show}
                    categories={showDetailsModal?.data}
                    items={showDetailsModal?.allData}
                    handleCloseModal={onClickHideMoreDetails}
                />
            }
            {showCategoriesModal?.show &&
                <AllCategoriesModal
                    openModal={showCategoriesModal?.show}
                    categories={showCategoriesModal?.data}
                    handleCloseModal={onClickSeeAllCategories}
                />
            }
        </div>
    );
};

export default Purchases;