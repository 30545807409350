import React from 'react';


interface ITextarea {
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    value: any;
    index?: any;
    onChangeTextarea: (e: any, type?: string, index?: any) => void;
    textareaType?: string;
    rows?: number;
    cols?: number;
    maxLength?: number;
    containerStyle?: string;
    dataQa?: string;
    error?: string;
}

const Textarea = ({
    name,
    value,
    className,
    placeholder,
    disabled,
    index,
    onChangeTextarea,
    textareaType,
    rows,
    cols,
    maxLength,
    containerStyle,
    dataQa,
    error
}: ITextarea) => {
    return (
        <div className={`min-w-full ${containerStyle || ''}`}>
            <textarea
                name={name || ''}
                disabled={disabled || false}
                className={className || 'textarea-primary'}
                rows={rows || 4}
                cols={cols || 3}
                maxLength={maxLength || 255}
                value={value || ''}
                placeholder={placeholder}
                data-qa={dataQa || ''}
                onChange={(e) => onChangeTextarea(e, textareaType, index)}>
            </textarea>
            {error ? <span className='block text-xs text-red-500'>{error}</span> : <span className='block h-2 mt-2' />}
        </div>
    );
};

export default Textarea;