import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { ISelectedValue } from '../../../interfaces/components/ISelectedValue';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IToast } from '../../../interfaces/components/IToast';
import {
    tryToFetchAllPartners,
    tryToFetchUserData
} from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchPurchaseRequests } from '../../../store/inventory/broker/purchase-requests/purchaseRequestsSlice';
import PurchaseRequestsTable from './tables/PurchaseRequestsTable';
import PurchaseDetailsModal from './modals/PurchaseDetailsModal';
import OffersHistoryModal from './modals/OffersHistoryModal';
import AllCategoriesModal from './modals/AllCategoriesModal';
import AddNotesModal from './modals/AddNotesModal';
import OfferModal from './modals/OfferModal';
import PurchaseFilters from './details/PurchaseFilters';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PurchaseRequests = () => {
    const query = useQuery();
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const [search, setSearch] = useState<string>();
    const [rows, setRows] = useState<Array<any>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [partners, setPartners] = useState<any>();
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [purchaseId, setPurchaseId] = useState<string>();
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const [showCategoriesModal, setShowCategoriesModal] = useState<{ show: boolean, data: any }>();
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [offersHistoryModal, setOffersHistoryModal] = useState<{ show: boolean, offers: any }>();
    const [showOfferModal, setShowOfferModal] = useState<{ show: boolean, data?: any, price?: number }>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const paginationData = useAppSelector((state) => state?.purchaseRequests?.purchaseRequests?.data?.page);
    const purchasesLoading = useAppSelector((state) => state?.purchaseRequests?.purchaseRequestsAreLoading);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const user = useAppSelector((state) => state?.partners?.userData);
    const state = useAppSelector((state) => state.purchaseRequests);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchase Requests')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    const onChangeFilterSelected = (value: ISelectedValue, type?: string) => {
        if (type) {
            switch (type) {
                case 'status':
                    setSelectedStatus(value)
                    break;
                case 'category':
                    setSelectedCategory(value)
                    break;
                case 'customer':
                    setSelectedCustomer(value)
                    break;
            }
            onResetPaginationSettings()
        }
    }

    useEffect(() => {
        if (state.purchaseRequests) {
            const rows = state.purchaseRequests?.data?.elements
            setRows(rows);
        }
    }, [state.purchaseRequests]);

    useLayoutEffect(() => {
        if (query?.get('search')) {
            setSearch(query?.get('search') || undefined)
            onResetPaginationSettings()
        }
    }, [query?.get('search')])

    const onGettingFilters = () => {
        let data: any = {
            inventory: true
        }
        if (activeWarehouse) {
            data = {
                ...data || {},
                warehouseId: activeWarehouse?._id,
            }
        }
        if (selectedCategory) {
            data = {
                ...data || {},
                assetTemplateId: selectedCategory?.id,
            }
        }
        if (selectedStatus) {
            data = {
                ...data || {},
                status: selectedStatus?.value,
            }
        }
        if (selectedCustomer) {
            data = {
                ...data || {},
                brokerId: selectedCustomer?.value,
            }
        }
        if (search) {
            data = {
                ...data || {},
                search: search,
            }
        }
        const formatData: any = { data: data }
        return formatData;
    }

    const onGettingPurchaseData = async () => {
        if (activeWarehouse) {
            try {
                const data = onGettingFilters()
                const fetchData = {
                    ...paginationState || {},
                    ...data || {}
                }
                await dispatch(tryToFetchPurchaseRequests(fetchData)).unwrap()
            } catch (err) {
                // err
            }
        }
    }

    const getPartnersData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.name }))
            const filterPartners = formatPartners && formatPartners?.length > 0 && formatPartners?.filter((partner: IPartners) => partner?.username !== (user?.broker || user?.username))
            setPartners(filterPartners || [])
        } catch (err) {
            // error here
        }
    }

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setBrokerCategories(formatSectors || []);
    };

    const onClickSeeAllCategories = (data?: any) => {
        if (data) {
            setShowCategoriesModal({
                show: true,
                data: data
            })
            document.body.style.overflow = 'hidden';
        } else {
            setShowCategoriesModal(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    useEffect(() => {
        if (!user) {
            dispatch(tryToFetchUserData())
        }
        getPartnersData()
    }, [])

    useEffect(() => {
        if (activeWarehouse) {
            onGettingPurchaseData()
        }
    }, [paginationState, activeWarehouse])

    const onClickClearAllFilter = () => {
        setSelectedStatus(undefined)
        setSelectedCategory(undefined)
        setSearch(undefined);
        setSelectedCustomer(undefined);
        onResetPaginationSettings();
        searchParams.delete('search')
        setSearchParams(searchParams)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onClickShowHideDetailsModal = (id?: string) => {
        if (id) {
            setShowDetailsModal(true)
            setPurchaseId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowDetailsModal(false)
            setPurchaseId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onClickShowHideNote = (id?: string) => {
        if (id) {
            setShowNotesModal(true)
            setPurchaseId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowNotesModal(false)
            setPurchaseId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onAddedNoteSuccessfull = () => {
        setShowToast({ type: 'success', message: 'Note added successfully' });
    }

    const onClickCreateSalesOrder = (id: string, batch?: boolean) => {
        navigator(batch ? `/sales-orders/create/${id}?purchase=true&batch=true` : `/sales-orders/create/${id}?purchase=true`)
    }

    const onClickMakeAnOffer = (id?: string, data?: any, totalPrice?: number) => {
        if (id) {
            setShowOfferModal({
                show: true,
                data: data,
                price: totalPrice
            })
            setPurchaseId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowOfferModal(undefined)
            setPurchaseId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onSubmitOfferSuccessfully = () => {
        setShowToast({ type: 'success', message: 'Offer submitted successfully' });
    }

    const onSeeOffersHistory = (id?: string, offers?: any) => {
        if (id) {
            setOffersHistoryModal({
                show: true,
                offers: offers
            })
            document.body.style.overflow = 'hidden'
        } else {
            setOffersHistoryModal(undefined)
            document.body.style.overflow = 'auto'
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Purchase Requests</p>
            </div>
            <PurchaseFilters
                brokerCategories={brokerCategories}
                selectedCategory={selectedCategory}
                selectedStatus={selectedStatus}
                selectedCustomer={selectedCustomer}
                allPartners={partners}
                onClickFilter={onChangeFilterSelected}
            />
            <div className={`flex flex-row justify-between items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'search'}
                        containerStyle='mb-0 pb-0 relative min-w-[400px]'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <div className='flex flex-row justify-end'>
                    <Button
                        label='Clear Filters'
                        className={'btn-primary-text-underline min-w-auto'}
                        onClickButton={() => onClickClearAllFilter()}
                    />
                </div>
            </div>
            <div>
                <PurchaseRequestsTable
                    rows={rows}
                    rowsLoading={purchasesLoading}
                    brokerCategories={brokerCategories}
                    paginationData={paginationData}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onSeeOffersHistory={onSeeOffersHistory}
                    onClickSeeAllCategories={onClickSeeAllCategories}
                    onMakingAnOffer={onClickMakeAnOffer}
                    setPaginationState={setPaginationState}
                    onSeeDetails={onClickShowHideDetailsModal}
                    onClickShowNote={onClickShowHideNote}
                    onClickCreateSalesOrder={onClickCreateSalesOrder}
                />
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showDetailsModal &&
                <PurchaseDetailsModal
                    openModal={showDetailsModal}
                    purchaseId={purchaseId || ''}
                    categories={brokerCategories}
                    handleCloseModal={onClickShowHideDetailsModal}
                />
            }
            {showNotesModal &&
                <AddNotesModal
                    openModal={showNotesModal}
                    purchaseId={purchaseId || ''}
                    handleCloseModal={onClickShowHideNote}
                    onSuccessfullyAddedNote={onAddedNoteSuccessfull}
                />
            }
            {showOfferModal?.show &&
                <OfferModal
                    openModal={showOfferModal?.show}
                    data={showOfferModal?.data}
                    itemId={purchaseId || ''}
                    price={showOfferModal?.price}
                    brokerCategories={brokerCategories}
                    onSubmitOfferSuccessfully={onSubmitOfferSuccessfully}
                    handleCloseModal={onClickMakeAnOffer}
                />
            }
            {showCategoriesModal?.show &&
                <AllCategoriesModal
                    openModal={showCategoriesModal?.show}
                    categories={showCategoriesModal?.data}
                    handleCloseModal={onClickSeeAllCategories}
                />
            }
            {offersHistoryModal?.show &&
                <OffersHistoryModal
                    openModal={offersHistoryModal?.show}
                    offers={offersHistoryModal?.offers}
                    handleCloseModal={onSeeOffersHistory}
                />
            }
        </div>
    )
}
export default PurchaseRequests;