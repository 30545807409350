import React from 'react';
import { ISales } from '../../../../interfaces/sales/ISales';
import ShippedTable from '../tables/ShippedTable';


interface IShippedStatus {
    saleDetails?: ISales;
    saleCategories?: Array<any>;
    templateFields?: any;
}

const ShippedStatus = ({
    saleDetails,
    saleCategories,
    templateFields
}: IShippedStatus) => {
    return (
        <div className='bg-white !rounded my-5 pb-4'>
            <p className='font-semibold text-[18px] p-3'>Items</p>
            <ShippedTable
                rows={saleDetails?.saleItems}
                categories={saleCategories}
                templateFields={templateFields}
                selectedItems={saleDetails?.selectedItems}
            />
        </div>
    )
}
export default ShippedStatus;