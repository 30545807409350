import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import assetsAxios from '../../../../utils/axios/assets.axios';

const tryToFetchAssetValues = (assetTemplateId: string, data?: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/values`, data)
}

const tryToFetchAssetInventoryValues = (assetTemplateId: string, data?: any): Promise<AxiosResponse> => {
    return assetsAxios.put(`${ASSETS_API_URL}/assets/${assetTemplateId}/values/inventory`, data)
}

const tryFetchAssetFieldValues = (assetTemplateId: string, fieldName: string, search?: string, replacements?: boolean): Promise<AxiosResponse> => {
    let url = search ? `${ASSETS_API_URL}/assets/${assetTemplateId}/field/${fieldName}/${search}` : `${ASSETS_API_URL}/assets/${assetTemplateId}/field/${fieldName}`
    if (replacements) {
        url = url + '?replacements=true'
    }
    return assetsAxios.get(url)
}

export const AssetsService = {
    tryToFetchAssetValues,
    tryToFetchAssetInventoryValues,
    tryFetchAssetFieldValues
};
