import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToImportInventorySalesGrade } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import { useAssetTemplateInventoryFiltersMutation } from '../../../../store/inventory/shared/assets-templates/templateFieldsApi';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadSalesGradeCsvFile from '../details/UploadSalesGradeCsvFile';
import Modal from '../../../../shared/modal';


interface IImportSalesGradeModal {
    openImportModal: boolean;
    sectors?: Array<any>;
    handleCloseModal: () => void;
}

const ImportSalesGradeModal = ({
    openImportModal,
    sectors,
    handleCloseModal
}: IImportSalesGradeModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()
    const [salesGradeItems, setSalesGradeItems] = useState<any>()
    const [rowsError, setRowsError] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [assetTemplateInventoryFilters] = useAssetTemplateInventoryFiltersMutation()
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setSelectedCategory(undefined)
        setFileSelected(undefined)
    }

    const onGettingInventoryData = async () => {
        try {
            const response = await assetTemplateInventoryFilters({ assetId: selectedCategory?.id }).unwrap();
            if (response && response?.data && response?.data?.length > 0) {
                const findSalesGrade = response?.data?.find((item: any)=> item?.fieldName === 'sales_grade')?.options || undefined
                setSalesGradeItems(findSalesGrade)
            }else{
                setSalesGradeItems(undefined)
            }
        } catch (err) {
            // err
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            onGettingInventoryData()
        }
    }, [selectedCategory])

    const onUploadFile = async () => {
        if (!selectedCategory) {
            setImportError('Please select category first to continue.')
            return;
        }
        let errorInRows: any
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const resultsData: any = [];
                    (results && results?.data && results?.data?.length > 0) && results?.data?.map((item: any, index: number) => {
                        const formatItem = item
                        if (!item?.itemId) {
                            errorInRows = {
                                ...errorInRows || {},
                                itemId: [
                                    ...errorInRows?.itemId || [],
                                    index + 2
                                ]
                            }
                        }
                        if (!item?.sales_grade) {
                            errorInRows = {
                                ...errorInRows || {},
                                sales_grade: [
                                    ...errorInRows?.sales_grade || [],
                                    index + 2
                                ]
                            }
                        } else {
                            if (salesGradeItems && salesGradeItems?.length > 0) {
                                const validateSales = salesGradeItems?.find((grade: any) => grade?.toLowerCase() === (item?.sales_grade || '').toLowerCase())
                                if (validateSales) {
                                    formatItem.sales_grade = validateSales
                                } else {
                                    errorInRows = {
                                        ...errorInRows || {},
                                        sales_grade_invalid: [
                                            ...errorInRows?.sales_grade_invalid || [],
                                            index + 2
                                        ]
                                    }
                                }
                            }
                        }
                        resultsData.push(formatItem)
                        return item
                    })
                    const payload = {
                        assetTemplateId: selectedCategory?.id,
                        warehouse: activeWarehouse?._id,
                        data: resultsData || []
                    }
                    if (!errorInRows) {
                        await dispatch(tryToImportInventorySalesGrade(payload)).unwrap()
                        setImportError(undefined)
                        setSelectedCategory(undefined)
                        setFileSelected(undefined)
                        setImportedSuccessfully(true)
                        setRowsError(undefined)
                    } else {
                        setRowsError(errorInRows)
                    }
                }
            })
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    const onChangeSelectedSector = (value: any, type?: string) => {
        setSelectedCategory(value)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={onCloseModal}
                    />
                    : <UploadSalesGradeCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        rowsError={rowsError}
                        sectors={sectors || []}
                        selectedSector={selectedCategory}
                        activeWarehouse={activeWarehouse}
                        onChangeSelectedSector={onChangeSelectedSector}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportSalesGradeModal;