import React from 'react';


const SectionChildModalContainer = () => {
    return (
        <div>
            <div className='my-2 w-full animate-pulse'>
                <div className='animate-pulse mb-4'>
                    <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                </div>
                <div className='animate-pulse mb-4'>
                    <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                </div>
                <div className='grid grid-cols-2 gap-2 max-w-[95%]'>
                    <div className='animate-pulse mb-4'>
                        <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                        <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                    </div>
                    <div className='animate-pulse mb-4'>
                        <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                        <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                    </div>
                </div>
                <div className='animate-pulse mb-4'>
                    <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                </div>
                <div className='animate-pulse mb-4'>
                    <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[90%] mb-1'></div>
                </div>
            </div>
        </div>
    )
}

export default SectionChildModalContainer;