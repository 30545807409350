import React from 'react';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';


interface IAmountModal {
    openModal: boolean;
    type?: string;
    amount?: any;
    error?: string;
    onChangeAmount: (value: any, type?: string) => void;
    onAddTransaction: () => void;
    handleCloseModal: () => void;
}

const AmountModal = ({
    openModal,
    type,
    amount,
    error,
    onChangeAmount,
    onAddTransaction,
    handleCloseModal
}: IAmountModal) => {
    return (
        <Modal
            open={openModal}
            onClose={() => handleCloseModal()}
            showInRight>
            <Input
                containerStyle='mb-0 w-[400px]'
                label={`Amount to ${type === 'deposit' ? 'Deposit' : 'Withdraw'}`}
                dataQa={'totalBalance'}
                showValue={true}
                inputValue={amount}
                onChangeInput={onChangeAmount}
                inputUniqueName={'totalBalance'}
                error={error}
                type='number'
            />
            <div className='mt-4'>
                <Button
                    label={type === 'deposit' ? 'Deposit' : 'Withdraw'}
                    onClickButton={onAddTransaction} />
            </div>
        </Modal>
    )
}
export default AmountModal;