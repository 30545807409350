import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import { removeFromStorage } from '../../utils/webStorage';
import { useGetCurrentUserQuery } from '../../store/user/userApi';

// const userNavigation = [
//     { name: 'Your Profile', href: '#' },
//     { name: 'Settings', href: '#' },
//     { name: 'Sign out', href: '#' },
// ]

const Profile = () => {
    const navigate = useNavigate();
    const [userInfoData, setUserInfoData] = useState<any>()
    const { data: userInfo } = useGetCurrentUserQuery();
    const handleLogout = () => {
        removeFromStorage('accessToken');
        removeFromStorage('refreshToken');
        removeFromStorage('roles');
        removeFromStorage('user');
        window.location.reload();
        navigate('/');
    }

    const isJsonObject = (jsonString: string) => {
        try {
            const o = JSON.parse(jsonString);
            if (o && typeof o === 'object') {
                return true;
            }
        }
        catch (e) { }
        return false;
    };

    const getCurrentUserData = async () => {
        const getUserLocal: any = localStorage.getItem('user')
        const userFormat: any = isJsonObject(getUserLocal) ? JSON.parse(getUserLocal) : undefined
        if (getUserLocal && (!userFormat && !userFormat?.username)) {
            setUserInfoData(getUserLocal)
        } else {
            setUserInfoData(userInfo?.username)
            localStorage.setItem('user', userInfo?.username || '');
        }
    }

    useEffect(() => {
        if (!userInfoData) {
            getCurrentUserData()
        }
    }, [userInfo])

    return (
        <div className='md:ml-24 flex'>
            {/* Profile dropdown */}
            <Menu as='div' className='my-auto relative'>
                <div>
                    <Menu.Button className=' bg-white flex items-center text-sm rounded-full focus:outline-none'>
                        <span className='sr-only'>Open user menu</span>
                        <img
                            className='w-12 h-12 bg-black rounded-xl'
                            src='/assets/auth/main-photo-yellow.svg'
                            alt='main photo'
                        />
                        <div className='text-left ml-4 mr-3'>
                            <div className='font-dm_sansregular text-sm font-normal text-primary-light'>{userInfoData}</div>
                        </div>
                        <img alt='arrow down' src='/svg/layout/arrow-down.svg' />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items className='origin-top-right absolute right-30 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item >
                            <button className='block w-full text-left px-4 py-2 text-sm text-gray-700' onClick={handleLogout} type='button'>
                                Logout
                            </button>
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default Profile;