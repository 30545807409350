import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import ManageCustomerModal from '../../profile/modals/ManageCustomerModal';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


interface IAddCustomBtn {
    getBrokerCustomersAndBanks: () => void;
}

const AddCustomerBtn = ({
    getBrokerCustomersAndBanks,
}: IAddCustomBtn) => {
    const [showCrmItemModal, setShowCrmItemModal] = useState<boolean>(false);
    const [crmItemId, setCrmItemId] = useState<string | null>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        getBrokerCustomersAndBanks();
        setCrmItemId(null);
    };

    const onAddNewCustomer = () => {
        setShowCrmItemModal(true);
        setCrmItemId(null);
    };

    useEffect(() => {
        const findPage = (accessControl && accessControl?.length > 0) && accessControl?.find((item: any) => item?.name === 'Profile Inventory');
        const findChild = (findPage && findPage?.children && findPage?.children?.length > 0) && findPage?.children?.find((item: any) => item?.path === '');
        setPageAccess(findChild || undefined);
    }, [accessControl]);

    return (
        <>
            {showCrmItemModal && (
                <ManageCustomerModal
                    openModal={showCrmItemModal}
                    handleCloseModal={() => setShowCrmItemModal(false)}
                    onSaveChanges={onSaveChanges}
                    crmItemId={crmItemId}
                />
            )}
            <Button
                label=''
                icon={<img src='/assets/shared/add-new.svg' />}
                className='h-10 m-auto w-10 ml-1'
                onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Customer']) && onAddNewCustomer()}
            />
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </>
    );
};

export default AddCustomerBtn;
