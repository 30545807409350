import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload, IPaginationPayloadWithId } from '../../../../interfaces/shared/IPaginationPayload';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IWarehouseLocationsList } from '../../../../interfaces/warehouse-locations/IWarehouseLocationsList';
import { WarehouseLocationsService } from './warehouseLocationsApi';
import { IWarehouseSectionChildren, IWarehouseSections } from '../../../../interfaces/warehouse-locations/IWarehouseSections';


const initialState: IWarehouseLocationsList = {
    sectionIsLoading: false,
    sectionsAreLoading: true,
    childsAreLoading: false,
    childIsLoading: false,
    locationsAreLoading: false,
    editLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchWarehouseSections = createAsyncThunk<any, IPaginationPayload>(
    'warehouseLocations/tryToFetchWarehouseSections',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await WarehouseLocationsService.tryToFetchWarehouseSections(pageNumber, pageSize, filters, data);
        return result?.data;
    }
);

export const tryToFetchAllWarehouseSections = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchAllWarehouseSections',
    async ({ warehouseId, type, category }) => {
        const result = await WarehouseLocationsService.tryToFetchAllWarehouseSections(warehouseId, type, category);
        return result?.data?.data;
    },
);

export const tryToFetchSingleWarehouseSection = createAsyncThunk<any, string>(
    'warehouseLocations/tryToFetchSingleWarehouseSection',
    async (id: string) => {
        const result = await WarehouseLocationsService.tryToFetchSingleWarehouseSection(id);
        return result?.data;
    },
);

export const tryToCreateWarehouseSection = createAsyncThunk<any, IWarehouseSections>(
    'warehouseLocations/tryToCreateWarehouseSection',
    async (data, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToCreateWarehouseSection(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditWarehouseSection = createAsyncThunk<any, { id: string, data: IWarehouseSections }>(
    'warehouseLocations/tryToEditWarehouseSection',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToEditWarehouseSection(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteWarehouseSection = createAsyncThunk<any, string>(
    'warehouseLocations/tryToDeleteWarehouseSection',
    async (id, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToDeleteWarehouseSection(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddWarehouseSectionChild = createAsyncThunk<any, { id: string, data: IWarehouseSectionChildren }>(
    'warehouseLocations/tryToAddWarehouseSectionChild',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToAddWarehouseSectionChild(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEditWarehouseSectionChild = createAsyncThunk<any, { id: string, childId: string, data: IWarehouseSectionChildren }>(
    'warehouseLocations/tryToEditWarehouseSectionChild',
    async ({ id, childId, data }, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToEditWarehouseSectionChild(id, childId, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteWarehouseSectionChild = createAsyncThunk<ApiResponse<any>, { id: string, childId: string }>(
    'warehouseLocations/tryToDeleteWarehouseSectionChild',
    async ({ id, childId }, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToDeleteWarehouseSectionChild(id, childId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToGetWarehouseSectionChild = createAsyncThunk<any, any>(
    'warehouseLocations/tryToGetWarehouseSectionChild',
    async ({ id, childId }) => {
        const result = await WarehouseLocationsService.tryToGetWarehouseSectionChild(id, childId);
        return result?.data;
    }
);

export const tryToFetchSectionChilds = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchSectionChilds',
    async ({ id, data }) => {
        const result = await WarehouseLocationsService.tryToFetchSectionChilds(id, data);
        return result?.data;
    }
);

export const tryToFetchWarehouseLocations = createAsyncThunk<
    any,
    IPaginationPayloadWithId
>('warehouseLocations/tryToFetchWarehouseLocations', async ({ pageNumber, pageSize, id, filters, data }) => {
    const result = await WarehouseLocationsService.tryToFetchWarehouseLocations(
        pageNumber,
        pageSize,
        id,
        filters,
        data
    );
    return result?.data;
});

export const tryToEditWarehouseLocationBarcode = createAsyncThunk<any, { id: string, data: any }>(
    'warehouseLocations/tryToEditWarehouseLocationBarcode',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToEditWarehouseLocationBarcode(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToGenerateSectionLocations = createAsyncThunk<any, string>(
    'warehouseLocations/tryToGenerateSectionLocations',
    async (id, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToGenerateSectionLocations(id,);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchSectionLocations = createAsyncThunk<any, any>(
    'warehouseLocations/tryToFetchSectionLocations',
    async ({ barcode, data }) => {
        const result = await WarehouseLocationsService.tryToFetchSectionLocations(barcode, data);
        return result?.data?.data;
    }
);

export const tryToCheckChildStateAndLocation = createAsyncThunk<any, string>(
    'warehouseLocations/tryToCheckChildStateAndLocation',
    async (id, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToCheckChildStateAndLocation(id);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToChangeChildsLocation = createAsyncThunk<any, any>(
    'warehouseLocations/tryToChangeChildsLocation',
    async (data, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToChangeChildsLocation(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchSectionChildsValues = createAsyncThunk<any, {id: string, data?: any}>(
    'warehouseLocations/tryToFetchSectionChildsValues',
    async ({id, data}, { rejectWithValue }) => {
        try {
            const result = await WarehouseLocationsService.tryToFetchSectionChildsValues(id, data);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const warehouseLocationSlice = createSlice({
    name: 'warehouseLocation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchWarehouseSections
            .addCase(tryToFetchWarehouseSections.pending, (state) => {
                state.sectionsAreLoading = true;
            })
            .addCase(tryToFetchWarehouseSections.rejected, (state) => {
                state.sectionsAreLoading = false;
            })
            .addCase(tryToFetchWarehouseSections.fulfilled, (state, action) => {
                state.sectionsAreLoading = false;
                state.warehouseSections = action.payload;
            })

            // tryToFetchSingleWarehouseSection
            .addCase(tryToFetchSingleWarehouseSection.pending, (state) => {
                state.sectionIsLoading = true;
            })
            .addCase(tryToFetchSingleWarehouseSection.rejected, (state) => {
                state.sectionIsLoading = false;
            })
            .addCase(tryToFetchSingleWarehouseSection.fulfilled, (state, action) => {
                state.sectionIsLoading = false;
                state.warehouseSectionDetails = action.payload.data;
            })

            // tryToCreateWarehouseSection
            .addCase(tryToCreateWarehouseSection.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToCreateWarehouseSection.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToCreateWarehouseSection.fulfilled, (state, action) => {
                state.editLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.warehouseSections?.data) state.warehouseSections.data.elements = [data, ...state.warehouseSections?.data.elements || []];
            })

            // tryToEditWarehouseSection
            .addCase(tryToEditWarehouseSection.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToEditWarehouseSection.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToEditWarehouseSection.fulfilled, (state, action) => {
                state.editLoading = false;
                if (state.warehouseSections?.data.elements)
                    state.warehouseSections.data.elements =
                        { ...state }.warehouseSections?.data.elements.map((section) => {
                            if (section?._id === action.meta?.arg?.id) {
                                return {
                                    ...action.payload.data,
                                }
                            } else {
                                return section
                            }
                        }) || [];
                state.warehouseSectionDetails = action.payload.data;
            })

            // tryToDeleteWarehouseSection
            .addCase(tryToDeleteWarehouseSection.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToDeleteWarehouseSection.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToDeleteWarehouseSection.fulfilled, (state, action) => {
                state.editLoading = false;
                state.requestStatus = 'success';
                if (state.warehouseSections?.data.elements)
                    state.warehouseSections.data.elements = { ...state }.warehouseSections?.data.elements.filter((warehouseSection) => warehouseSection?._id !== action.meta.arg) || [];
            })

            // tryToFetchSectionChilds
            .addCase(tryToFetchSectionChilds.pending, (state) => {
                state.childsAreLoading = true;
            })
            .addCase(tryToFetchSectionChilds.rejected, (state) => {
                state.childsAreLoading = false;
            })
            .addCase(tryToFetchSectionChilds.fulfilled, (state, action) => {
                state.childsAreLoading = false;
                state.warehouseSectionChilds = action.payload.data;
            })

            // tryToGetWarehouseSectionChild
            .addCase(tryToGetWarehouseSectionChild.pending, (state) => {
                state.childIsLoading = true;
            })
            .addCase(tryToGetWarehouseSectionChild.rejected, (state) => {
                state.childIsLoading = false;
            })
            .addCase(tryToGetWarehouseSectionChild.fulfilled, (state, action) => {
                state.childIsLoading = false;
                state.warehouseSectionChild = action.payload.data;
            })

            // tryToAddWarehouseSectionChild
            .addCase(tryToAddWarehouseSectionChild.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToAddWarehouseSectionChild.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToAddWarehouseSectionChild.fulfilled, (state, action) => {
                state.editLoading = false;
                state.requestStatus = 'success';
                state.warehouseSectionChilds = action.payload.data?.childs;
                state.warehouseSectionDetails = action.payload.data;
            })

            // tryToEditWarehouseSectionChild
            .addCase(tryToEditWarehouseSectionChild.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToEditWarehouseSectionChild.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToEditWarehouseSectionChild.fulfilled, (state, action) => {
                state.editLoading = false;
                state.warehouseSectionChilds = action.payload.data?.childs
            })

            // tryToDeleteWarehouseSectionChild
            .addCase(tryToDeleteWarehouseSectionChild.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToDeleteWarehouseSectionChild.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToDeleteWarehouseSectionChild.fulfilled, (state, action) => {
                state.editLoading = false;
                state.requestStatus = 'success';
                state.warehouseSectionChilds = action.payload.data?.childs;
                state.warehouseSectionChild = undefined
                state.warehouseSectionDetails = action.payload.data;
            })

            // tryToFetchWarehouseLocations
            .addCase(tryToFetchWarehouseLocations.pending, (state) => {
                state.locationsAreLoading = true;
            })
            .addCase(tryToFetchWarehouseLocations.rejected, (state) => {
                state.locationsAreLoading = false;
            })
            .addCase(tryToFetchWarehouseLocations.fulfilled, (state, action) => {
                state.locationsAreLoading = false;
                state.warehouseLocations = action.payload;
            })

            // tryToEditWarehouseLocationBarcode
            .addCase(tryToEditWarehouseLocationBarcode.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToEditWarehouseLocationBarcode.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToEditWarehouseLocationBarcode.fulfilled, (state, action) => {
                state.editLoading = false;
                if (state.warehouseLocations?.data.elements)
                    state.warehouseLocations.data.elements =
                        { ...state }.warehouseLocations?.data.elements.map((section) => {
                            if (section?._id === action.meta?.arg?.id) {
                                return {
                                    ...action.payload.data,
                                }
                            } else {
                                return section
                            }
                        }) || [];
            })

            // tryToGenerateSectionLocations
            .addCase(tryToGenerateSectionLocations.pending, (state) => {
                state.editLoading = true;
            })
            .addCase(tryToGenerateSectionLocations.rejected, (state) => {
                state.editLoading = false;
            })
            .addCase(tryToGenerateSectionLocations.fulfilled, (state, action) => {
                state.editLoading = false;
                state.requestStatus = 'success';
                if (state.warehouseSections?.data.elements)
                    state.warehouseSections.data.elements =
                        { ...state }.warehouseSections?.data.elements.map((section) => {
                            if (section?._id === action.meta?.arg) {
                                return {
                                    ...section,
                                    generatedLocations: true
                                }
                            } else {
                                return section
                            }
                        }) || [];
                if (state.warehouseSectionDetails) {
                    state.warehouseSectionDetails.generatedLocations = true
                }
            })
    },
});

export default warehouseLocationSlice.reducer;