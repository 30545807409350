import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { IPartnersListState } from '../../../../interfaces/partners/IPartnersList';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { PartnersService } from './partnersApi';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { WarehousesService } from '../../broker/warehouses/warehousesApi';

const initialState: IPartnersListState = {
    partnerIsLoading: false,
    partnersAreLoading: false,
    partnerWarehousesAreLoading: false,
    partnerNavigationLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllPartners = createAsyncThunk<IPartners[], any>(
    'partners/tryToFetchAllPartners',
    async () => {
        const result = await PartnersService.tryToFetchAllPartners();
        return result?.data;
    },
);

export const tryToFetchUserData = createAsyncThunk<any, undefined>(
    'partners/tryToFetchUserData',
    async () => {
        const result = await PartnersService.tryToFetchUserData();
        return result?.data;
    },
);

export const tryToFetchNavigationAccess = createAsyncThunk<any, undefined | null>(
    'partners/tryToFetchNavigationAccess',
    async () => {
        const result = await PartnersService.tryToFetchNavigationAccess();
        return result?.data;
    },
);

export const tryToFetchSinglePartner = createAsyncThunk<any, string>(
    'partners/tryToFetchSinglePartner',
    async (id: string) => {
        const result = await PartnersService.tryToFetchSinglePartner(id);
        return result?.data?.data;
    },
);

export const tryToAddPartner = createAsyncThunk<ApiResponse<IPartnersSchema>, IPartners>(
    'partners/tryToAddPartner',
    async (data: IPartners, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddPartner(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddMarket = createAsyncThunk<ApiResponse<any>, { id: string }>(
    'partners/tryToAddMarket',
    async (data, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToAddMarket(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDisableMarket = createAsyncThunk<ApiResponse<any>, string>(
    'partners/tryToDisableMarket',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToDisableMarket(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFindOperatingMarkets = createAsyncThunk<Array<any>, null>(
    'agents/tryToFindOperatingMarkets',
    async () => {
        const result = await PartnersService.tryToFindOperatingMarkets();
        return result?.data;
    },
);

export const tryToFindOperatingWarehouses = createAsyncThunk<any, null>(
    'stores/tryToFindOperatingWarehouses',
    async () => {
        const result = await WarehousesService.tryToFetchWarehouses();
        return result?.data;
    },
);

export const tryToChangeActiveWarehouse = createAsyncThunk<any, { warehouseId: string }>(
    'stores/tryToChangeActiveWarehouse',
    async (data) => {
        return data?.warehouseId;
    }
);

export const tryToFetchAllPayServices = createAsyncThunk<any, null | undefined>(
    'partners/tryToFetchAllPayServices',
    async () => {
        const result = await PartnersService.tryToFetchAllPayServices();
        return result?.data;
    },
);

export const tryToFetchUserNotifications = createAsyncThunk<any, any>(
    'partners/tryToFetchUserNotifications',
    async ({ data, page, size }) => {
        const result = await PartnersService.tryToFetchUserNotifications(data, page, size);
        return result?.data?.data;
    }
);

export const tryToSetReadNotifications = createAsyncThunk<any, any>(
    'partners/tryToSetReadNotifications',
    async (data, { rejectWithValue }) => {
        try {
            const result = await PartnersService.tryToSetReadNotifications(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToAddPartner
            .addCase(tryToAddPartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToAddPartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToAddPartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.partners?.data) state.partners.data.elements = [...state.partners?.data.elements, data];
            })

            // tryToFetchSinglePartner
            .addCase(tryToFetchSinglePartner.pending, (state) => {
                state.partnerIsLoading = true;
            })
            .addCase(tryToFetchSinglePartner.rejected, (state) => {
                state.partnerIsLoading = false;
            })
            .addCase(tryToFetchSinglePartner.fulfilled, (state, action) => {
                state.partnerIsLoading = false;
                state.partnerDetail = action.payload;
            })

            // tryToFetchUserData
            .addCase(tryToFetchUserData.rejected, (state) => {
                state.userData = undefined;
            })
            .addCase(tryToFetchUserData.fulfilled, (state, action) => {
                state.userData = action.payload?.data;
            })

            // tryToFindOperatingWarehouses
            .addCase(tryToFindOperatingWarehouses.pending, (state) => {
                state.partnerWarehousesAreLoading = true;
            })
            .addCase(tryToFindOperatingWarehouses.rejected, (state) => {
                state.partnerWarehousesAreLoading = false;
            })
            .addCase(tryToFindOperatingWarehouses.fulfilled, (state, action) => {
                state.partnerWarehousesAreLoading = false;
                state.partnerWarehouses = action.payload?.data;
                const getActiveWarehouse = localStorage.getItem('active-warehouse')
                const activeWarehouse = getActiveWarehouse && getActiveWarehouse !== 'undefined' && JSON.parse(getActiveWarehouse)
                const findActiveWarehouse = action.payload?.data && activeWarehouse && action.payload?.data?.find((warehouse: any) => warehouse?._id === activeWarehouse?._id)
                state.partnerActiveWarehouse = findActiveWarehouse || action.payload?.data?.[0]
                if (!activeWarehouse) {
                    localStorage.setItem('active-warehouse', JSON.stringify(action.payload?.data?.[0]))
                }
            })

            // tryToChangeActiveWarehouse
            .addCase(tryToChangeActiveWarehouse.pending, (state) => {
                state.partnerWarehousesAreLoading = true;
            })
            .addCase(tryToChangeActiveWarehouse.rejected, (state) => {
                state.partnerWarehousesAreLoading = false;
            })
            .addCase(tryToChangeActiveWarehouse.fulfilled, (state, action) => {
                state.partnerWarehousesAreLoading = false;
                const findActiveWarehouse = state?.partnerWarehouses && state?.partnerWarehouses?.length > 0 && state?.partnerWarehouses?.find((warehouse: IWarehouse) => warehouse?._id === action?.meta?.arg?.warehouseId)
                state.partnerActiveWarehouse = findActiveWarehouse || undefined;
                localStorage.setItem('active-warehouse', JSON.stringify(findActiveWarehouse))
            })

            // tryToFetchNavigationAccess
            .addCase(tryToFetchNavigationAccess.pending, (state) => {
                state.partnerNavigationLoading = true;
            })
            .addCase(tryToFetchNavigationAccess.rejected, (state) => {
                state.partnerNavigationLoading = false;
            })
            .addCase(tryToFetchNavigationAccess.fulfilled, (state, action) => {
                state.partnerNavigationLoading = false;
                const data = action.payload.data;
                state.navigationAccess = data
            })
    }
});
export default partnersSlice.reducer;