import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreditManagmentService } from './creditManagmentApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ICreditNoteListState } from '../../../../interfaces/credit-notes/ICreditNoteList';
import { AxiosError } from 'axios';

const initialState: ICreditNoteListState = {
    creditNoteIsLoading: false,
    creditNotesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCreditNotes = createAsyncThunk<any, IPaginationPayload>(
    'creditNotes/tryToFetchCreditNotes',
    async ({ pageNumber, pageSize, data, filters }) => {
        const result = await CreditManagmentService.tryToFetchCreditNotes(
            pageNumber,
            pageSize,
            data,
            filters
        );
        return result?.data;
    }
);

export const tryToFetchCreditNoteDetails = createAsyncThunk<any, string>(
    'creditNotes/tryToFetchCreditNoteDetails',
    async (id) => {
        const result = await CreditManagmentService.tryToFetchCreditNoteDetails(id);
        return result?.data?.data;
    }
);

export const tryToFetchCreditManagementByCustomer = createAsyncThunk<any, string>(
    'creditNotes/tryToFetchCreditManagementByCustomer',
    async (customerId) => {
        const result = await CreditManagmentService.tryToFetchCreditManagementByCustomer(customerId);
        return result?.data?.data;
    }
);

export const tryToGetCredit = createAsyncThunk<any, { pageNumber: number, pageSize: number, search: string }>(
    'creditNotes/tryToGetCredit',
    async ({ pageNumber, pageSize, search }) => {
        const result = await CreditManagmentService.tryToGetCredit(pageNumber, pageSize, search);
        return result?.data?.data;
    }
);

export const tryToGetTransactions = createAsyncThunk<any, { pageNumber: number, pageSize: number, search: string, creditManagementId: string }>(
    'creditNotes/tryToGetTransactions',
    async ({ pageNumber, pageSize, search, creditManagementId }) => {
        const result = await CreditManagmentService.tryToGetTransactions(pageNumber, pageSize, search, creditManagementId);
        return result?.data?.data;
    }
);

export const tryToGetCreditById = createAsyncThunk<any,any>(
    'creditNotes/tryToGetCreditById',
    async (id) => {
        const result = await CreditManagmentService.tryToGetCreditById(id);
        return result?.data?.data;
    }
);

export const tryToFetchAllCreditNotes = createAsyncThunk<any, any>(
    'creditNotes/tryToFetchAllCreditNotes',
    async (data: any) => {
        const result = await CreditManagmentService.tryToFetchAllCreditNotes(data);
        return result?.data?.data;
    }
);

export const tryToAddCreditNote = createAsyncThunk<any, any>(
    'creditNotes/tryToAddCreditNote',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await CreditManagmentService.tryToAddCreditNote(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddCredit = createAsyncThunk<any, any>(
    'creditNotes/tryToAddCredit',
    async (data) => {
        try {
            const result = await CreditManagmentService.tryToAddCredit( data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return err;
        }
    },
);


export const tryToAddTransaction = createAsyncThunk<any, any>(
    'creditNotes/tryToAddTransaction',
    async (data) => {
        try {
            const result = await CreditManagmentService.tryToAddTransaction(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return err;
        }
    },
); 

export const tryToGetCustomers = createAsyncThunk< any>(
    'creditNotes/tryToGetCustomers',
    async ( ) => {
        try {
            const result = await CreditManagmentService.tryToGetCustomers();
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return err?.response?.data?.message;
        }
    },
);

export const tryToFetchCreditsNote = createAsyncThunk<any, string>(
    'creditNotes/tryToFetchCreditsNote',
    async (id: string) => {
        const result = await CreditManagmentService.tryToFetchCreditsNote(id);
        return result?.data;
    },
);

export const tryToDownloadCreditNotes = createAsyncThunk<any, any>(
    'creditNotes/tryToDownloadCreditNotes',
    async (data: any) => {
        const result = await CreditManagmentService.tryToDownloadCreditNotes(data);
        return result?.data;
    },
);

export const tryToMarkAsReceived = createAsyncThunk<any, any>(
    'creditNotes/tryToMarkAsReceived',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await CreditManagmentService.tryToMarkAsReceived(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToMarkAsUsed = createAsyncThunk<any, string>(
    'creditNotes/tryToMarkAsUsed',
    async (id, { rejectWithValue }) => {
        try {
            const result = await CreditManagmentService.tryToMarkAsUsed(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const creditManagmentSlice = createSlice({
    name: 'creditNotes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchCreditNotes
            .addCase(tryToFetchCreditNotes.pending, (state) => {
                state.creditNotesAreLoading = true;
            })
            .addCase(tryToFetchCreditNotes.rejected, (state) => {
                state.creditNotesAreLoading = false;
            })
            .addCase(tryToFetchCreditNotes.fulfilled, (state, action) => {
                state.creditNotesAreLoading = false;
                state.creditNotes = action.payload;
            })

            // tryToFetchCreditNoteDetails
            .addCase(tryToFetchCreditNoteDetails.pending, (state) => {
                state.creditNoteIsLoading = true;
            })
            .addCase(tryToFetchCreditNoteDetails.rejected, (state) => {
                state.creditNoteIsLoading = false;
            })
            .addCase(tryToFetchCreditNoteDetails.fulfilled, (state, action) => {
                state.creditNoteIsLoading = false;
                state.creditNoteDetails = action.payload;
            })

            // tryToFetchCreditsNote
            .addCase(tryToFetchCreditsNote.pending, (state) => {
                state.creditNoteIsLoading = true;
            })
            .addCase(tryToFetchCreditsNote.rejected, (state) => {
                state.creditNoteIsLoading = false;
            })
            .addCase(tryToFetchCreditsNote.fulfilled, (state, action) => {
                state.creditNoteIsLoading = false;
                state.notes = action.payload.data;
            })

            // tryToMarkAsReceived
            .addCase(tryToMarkAsReceived.pending, (state) => {
                state.creditNoteIsLoading = true;
            })
            .addCase(tryToMarkAsReceived.rejected, (state) => {
                state.creditNoteIsLoading = false;
            })
            .addCase(tryToMarkAsReceived.fulfilled, (state, action) => {
                state.creditNoteIsLoading = false;
                if (state.creditNotes?.data.elements)
                    state.creditNotes.data.elements =
                        { ...state }.creditNotes?.data.elements.filter(
                            (creditNote) => creditNote?._id !== action.meta.arg?.id,
                        ) || [];
                if (state.creditNoteDetails)
                    state.creditNoteDetails = {
                        ...state.creditNoteDetails || {},
                        status: 'received_credit'
                    }
            })

            // tryToMarkAsUsed
            .addCase(tryToMarkAsUsed.pending, (state) => {
                state.creditNoteIsLoading = true;
            })
            .addCase(tryToMarkAsUsed.rejected, (state) => {
                state.creditNoteIsLoading = false;
            })
            .addCase(tryToMarkAsUsed.fulfilled, (state, action) => {
                state.creditNoteIsLoading = false;
                if (state.creditNotes?.data.elements)
                    state.creditNotes.data.elements =
                        { ...state }.creditNotes?.data.elements.filter(
                            (creditNote) => creditNote?._id !== action.meta.arg,
                        ) || [];
                if (state.creditNoteDetails)
                    state.creditNoteDetails = {
                        ...state.creditNoteDetails || {},
                        status: 'used_credit'
                    }
            })

            // tryToAddCreditNote
            .addCase(tryToAddCreditNote.pending, (state) => {
                state.creditNoteIsLoading = true;
            })
            .addCase(tryToAddCreditNote.rejected, (state) => {
                state.creditNoteIsLoading = false;
            })
            .addCase(tryToAddCreditNote.fulfilled, (state, action) => {
                state.creditNoteIsLoading = false;
                state.notes = action.payload?.data;
            })
    },
});

export default creditManagmentSlice.reducer;
