import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { tryToFetchSinglePurchaseRequest } from '../../../../store/inventory/broker/purchase-requests/purchaseRequestsSlice';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IPurchaseDetailsModal {
    openModal: boolean;
    purchaseId: string;
    categories?: any;
    handleCloseModal: () => void;
}

const PurchaseDetailsModal = ({
    openModal,
    purchaseId,
    categories,
    handleCloseModal,
}: IPurchaseDetailsModal) => {
    const dispatch = useAppDispatch();
    const [purchaseDetails, setPurchaseDetails] = useState<any>();
    const state = useAppSelector((state) => state?.purchaseRequests);

    useEffect(() => {
        setPurchaseDetails(state?.purchaseRequestDetails)
    }, [state?.purchaseRequestDetails])

    useEffect(() => {
        if (purchaseId) {
            dispatch(tryToFetchSinglePurchaseRequest(purchaseId || ''))
        } else {
            setPurchaseDetails(undefined)
        }
    }, [purchaseId, openModal])

    const onCloseModal = () => {
        handleCloseModal();
    }

    let findTotalQuantity = 0;
    let subPriceTotal = 0;

    purchaseDetails?.expectedItems && purchaseDetails?.expectedItems?.length > 0 &&
        [...purchaseDetails?.expectedItems].map((currentValue: any) => {
            subPriceTotal = subPriceTotal + Number(currentValue.price || 0) * Number(currentValue?.qty || 0);
            findTotalQuantity = Number(findTotalQuantity) + Number(currentValue?.qty || 0);
            return currentValue;
        });

    return (
        <Modal
            open={openModal}
            showInRight
            onClose={() => onCloseModal()}
            title='Purchase Order Details'
            contentContainerStyle='!min-w-[70vw]'>
            <div className={'p-2 min-w-[65vw] min-h-[300px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Details</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='text-[20px]'>Review Details</p>
                    <div className='grid grid-cols-4 span-2 my-3 border-b border-[#e4e4e4] pb-2'>
                        {purchaseDetails?.purchaseInfo?.customer &&
                            <div className='mb-2'>
                                <span>Purchasing from: </span>
                                <span className='font-bold'>{purchaseDetails?.purchaseInfo?.customer?.name}</span>
                            </div>
                        }
                        {purchaseDetails?.purchaseInfo?.dueDate &&
                            <div className='mb-2'>
                                <span>Due Date: </span>
                                <span className='font-bold'>{moment(purchaseDetails?.purchaseInfo?.dueDate).format('DD MMMM YYYY')}</span>
                            </div>
                        }
                        {purchaseDetails?.purchaseInfo?.orderCreator &&
                            <div className='mb-2'>
                                <span>Order Created: </span>
                                <span className='font-bold'>{purchaseDetails?.purchaseInfo?.orderCreator}</span>
                            </div>
                        }
                        {purchaseDetails?.purchaseInfo?.stockVat &&
                            <div className='mb-2'>
                                <span>Stock VAT: </span>
                                <span className='font-bold'>{purchaseDetails?.purchaseInfo?.stockVat?.label || purchaseDetails?.purchaseInfo?.stockVat}</span>
                            </div>
                        }
                        {/* {activeMarket &&
                            <div className='mb-2'>
                                <span>Market: </span>
                                <span className='font-bold'>{activeMarket?.market?.label}</span>
                            </div>
                        } */}
                        {purchaseDetails?.purchaseInfo?.warranty &&
                            <div className='mb-2'>
                                <span>Warranty(Days): </span>
                                <span className='font-bold'>{purchaseDetails?.purchaseInfo?.warranty} days</span>
                            </div>
                        }
                    </div>
                    <div className=' border-b border-[#e4e4e4] pb-2 my-2'>
                        <Table
                            columns={['Item', 'Price per Unit', 'Units', 'Subtotal']}
                            hidePagination={true}
                            headerStyle='!bg-[#000000] !text-white'
                        >
                            {purchaseDetails?.expectedItems && purchaseDetails?.expectedItems?.length > 0 && purchaseDetails?.expectedItems?.map((row: any, idx: number) => {
                                const findCategory = categories && categories?.length > 0 && categories?.find((item: any) => item?.id === row?.categoryId)
                                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                                const keys = [...keysDescriptor || [], ...keysInventory || []]
                                return (
                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                        <td className='px-6 py-4'>
                                            <div className='flex flex-wrap'>
                                                <img src={findCategory?.enabledIcon} className={'h-[20px] min-h-[20px] object-contain'} />
                                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                                    return (
                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                            {(index !== 0) &&
                                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                </div>
                                                            }
                                                            <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </td>
                                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}{Number(row?.price || 0).toFixed(2)}</td>
                                        <td className='px-6 py-4'>{row?.qty || ''} unit(s)</td>
                                        <td className='px-6 py-4'>
                                            {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}
                                            {(Number(row?.price || 0) * Number(row?.qty || 0)).toFixed(2)}
                                        </td>
                                    </tr>
                                )
                            })}
                            {purchaseDetails?.expectedItems && purchaseDetails?.expectedItems?.length > 0 &&
                                <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                                    <td></td>
                                    <td className='px-6 py-4'>Total:</td>
                                    <td className='px-6 py-4'>{findTotalQuantity} unit(s)</td>
                                    <td className='px-6 py-4'>
                                        {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}
                                        {Number(subPriceTotal || 0).toFixed(2)}
                                    </td>
                                </tr>
                            }
                        </Table>
                        <div className='mt-2 grid grid-cols-2 span-2 min-w-[100%]'>
                            <div >
                                <div className='border border-[#a4a4a4] flex flex-row justify-between items-center p-2'>
                                    <p className='text-[20px]'>Total Items</p>
                                    <p className='text-[20px] text-primary-default'>{findTotalQuantity} Units</p>
                                </div>
                                {purchaseDetails?.batch ?
                                    <>
                                        {(subPriceTotal || purchaseDetails?.offerPrice) &&
                                            <div className='border border-[#a4a4a4] flex flex-row justify-between items-center p-2'>
                                                <p className='text-[20px]'>Initial Offer</p>
                                                <p className='text-[20px] text-primary-default'>
                                                    {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}
                                                    {Number(purchaseDetails?.offerPrice || subPriceTotal || 0).toFixed(2)}
                                                </p>
                                            </div>
                                        }
                                        {purchaseDetails?.totalOfferPrice &&
                                            <div className='border border-[#a4a4a4] flex flex-row justify-between items-center p-2'>
                                                <p className='text-[20px]'>Final Offer</p>
                                                <p className='text-[20px] text-primary-default'>
                                                    {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency|| '').toLowerCase()}`]}
                                                    {Number(purchaseDetails?.totalOfferPrice || 0).toFixed(2)}
                                                </p>
                                            </div>
                                        }
                                    </>
                                    : <div className='border border-[#a4a4a4] flex flex-row justify-between items-center p-2'>
                                        <p className='text-[20px]'>Total Amount</p>
                                        <p className='text-[20px] text-primary-default'>
                                            {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.expectedItems?.[0]?.currency || '').toLowerCase()}`]}
                                            {Number(subPriceTotal || 0).toFixed(2)}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className='flex flex-row justify-center items-center self-center'>
                                <Button
                                    label={'Close'}
                                    className='btn-primary min-w-[150px] mr-3 !rounded'
                                    onClickButton={() => onCloseModal()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default PurchaseDetailsModal;