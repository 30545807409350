import React from 'react';
import Button from '../../../../shared/button';


interface ITabs {
    tabs: any[];
    activeTab: string;
    onClickTab: (value: any) => void;
}

const Tabs = ({
    tabs,
    activeTab,
    onClickTab
}: ITabs) => {
    return (
        <>
            <div className='my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4'>
                {tabs && tabs?.length > 0 && tabs?.map((tab, index) => {
                    return (
                        <Button
                            key={index}
                            label={tab?.name}
                            className={`box-border ${activeTab === tab?.name ? 'btn-primary-rounded-less' : 'btn-noactive-categories border border-2 border-[#484a4b] !text-[#484a4b] !rounded hover:bg-gradient-to-br hover:from-[#FCEE21] hover:to-[#F7931E] hover:text-black hover:border-none !h-9'}`}
                            onClickButton={() => onClickTab(tab)}
                        />
                    )
                })}
            </div>
        </>
    )
}

export default Tabs
