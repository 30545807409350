import React from 'react';
import InventoryFilters from './InventoryFilters';


interface IInventoryCategories {
    activeButton?: string;
    templateFilters?: any;
    inventoryFilter?: any;
    filterValues?: any;
    onChangeFilterInput: (value: string | null | undefined, type: string) => void;
    onChangeSelectedFilterValue: (value: string, type?: string) => void;
}

const InventoryCategories = ({
    activeButton,
    templateFilters,
    inventoryFilter,
    filterValues,
    onChangeFilterInput,
    onChangeSelectedFilterValue
}: IInventoryCategories) => {
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className='bg-white z-20 rounded absolute overflow-x-auto right-2 top-12 max-w-[350px] min-w-[350px] drop-shadow-lg p-3 max-h-[70vh]'>
            {activeButton && ((templateFilters && templateFilters?.length > 0) || (inventoryFilter && inventoryFilter?.length > 0)) &&
                <InventoryFilters
                    items={templateFilters || []}
                    inventoryItems={inventoryFilter || []}
                    filterValues={filterValues || null}
                    onChangeInput={onChangeFilterInput}
                    onChangeSelectedValue={onChangeSelectedFilterValue}
                />
            }
        </div>
    )
}

export default InventoryCategories;