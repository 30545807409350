import React from 'react';
import Select, { components } from 'react-select';

interface ISelectCheckbox {
    ref?: any;
    options: Array<any>;
    selectedOption?: string | string[] | any;
    onChangeSelectedOption: (e: any, type?: string, index?: any, parent?: any) => void;
    multiple?: boolean;
    placeholder?: string;
    error?: string;
    selectStyle?: any;
    placeholderStyle?: any;
    singleValueStyle?: any;
    dropDownIndicatorStyle?: any;
    indicatorSeparatorStyle?: any;
    containerStyle?: string;
    uniqueName?: string;
    name?: string;
    isLoading?: boolean;
    disabled?: boolean;
    index?: any;
    loadingOptions?: boolean;
    dynamicOptions?: boolean;
    showFixed?: boolean;
    dataQa?: string;
    hideInputSpace?: boolean;
    parent?: any;
    onHandleKeyDown?: (e: any, type?: string, index?: any, parent?: any) => void;
    onChangeValue?: (e: any, type?: string, index?: any, parent?: any) => void;
    onMenuClose?: (type?: string, index?: any, parent?: any) => void;
}


const SelectCheckbox = ({
    ref,
    options,
    parent,
    selectedOption,
    multiple,
    placeholder,
    error,
    containerStyle,
    selectStyle,
    indicatorSeparatorStyle,
    dropDownIndicatorStyle,
    placeholderStyle,
    singleValueStyle,
    uniqueName,
    name,
    isLoading,
    showFixed,
    dataQa,
    index,
    loadingOptions,
    disabled,
    dynamicOptions,
    onChangeSelectedOption,
    onChangeValue,
    onMenuClose,
    onHandleKeyDown,
    hideInputSpace
}: ISelectCheckbox) => {
    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            boxShadow: 'none',
            borderColor: error ? 'red' : '#e5e7eb',
            paddingTop: 2,
            paddingBottom: 2,
            '&:hover': {
                borderColor: '#e5e7eb !important',
                cursor: 'pointer'
            },
            ...selectStyle || {}
        }),
        menu: (base: any, state: any) => ({
            ...base,
            maxHeight: 130
        }),
        menuList: (base: any, state: any) => ({
            ...base,
            maxHeight: 130
        }),
        indicatorSeparator: (base: any, state: any) => ({
            ...base,
            ...indicatorSeparatorStyle || {}
        }),
        dropdownIndicator: (base: any, state: any) => ({
            ...base,
            ...dropDownIndicatorStyle || {}
        }),
        placeholder: (base: any, state: any) => ({
            ...base,
            ...placeholderStyle || {}
        }),
        singleValue: (base: any, state: any) => ({
            ...base,
            ...singleValueStyle || {}
        }),
    }
    const Input = (props: any) => {
        return <components.Input {...props} data-qa={dataQa || ''} />;
    };

    return (
        <div className={`checkbox-select ${containerStyle || 'mb-3 w-full'}`}>
            {name && <p className='mb-1 text-[#a4a4a4]'>{name}</p>}
            <Select
                ref={ref}
                menuPosition={showFixed ? 'fixed' : 'absolute'}
                placeholder={placeholder}
                value={selectedOption || null}
                onChange={(e) => onChangeSelectedOption(e, uniqueName, index, parent)}
                options={loadingOptions ? [] : options || []}
                styles={customStyles}
                components={{ Input }}
                noOptionsMessage={(value) => loadingOptions ? 'Loading...' : 'No Options'}
                onInputChange={(e) => onChangeValue?.(e, uniqueName, index, parent)}
                isDisabled={isLoading || disabled || false}
                isLoading={isLoading || false}
                isSearchable={options?.length > 0 || dynamicOptions}
                isMulti={multiple || false}
                onKeyDown={(e) => onHandleKeyDown?.(e, uniqueName, index, parent)}
                onMenuClose={() => onMenuClose?.(uniqueName, index, parent)} />
            {error ? <span className='block text-xs text-red-500'>{error}</span> : !hideInputSpace && <span className='block h-2 mt-2' />}
        </div>
    )
}

export default SelectCheckbox