import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';
import Input from '../../../../shared/input';


interface IFormSaleItemsTable {
    category?: any;
    columns?: [];
    templateData?: any;
    inventoryFields?: any;
    filterValues: any;
    filterInventoryValues?: any;
    inventoryItems?: Array<any>;
    rowsLength: number;
    allRowsData?: any;
    allRowsItems?: any;
    loadingValues: boolean;
    loadingFields: boolean;
    loadingOptions?: any;
    filterErrors?: any;
    stringFieldsValues?: any;
    selectedMarket?: any;
    searchInDB?: any;
    itemsLoading?: { row: number; loading: boolean };
    categoryFieldsWithoutExtended?: any;
    onChangeSelectedValue: (e: any, type?: string, index?: any) => void;
    onChangeSelectedInventoryValue: (value: string, type?: string, index?: any, parent?: any) => void;
    onChangeInputValue: (e: any, type?: string, index?: any) => void;
    onChangeInventoryInput: (value: string | null | undefined, type?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
    onClickSearchInDb: (type?: string, index?: any) => void;
    onChangeSearchAssetFields: (e: any, type?: string, index?: any) => void;
    onClickAddRow: (catalog: any) => void;
    onDeleteItemRow: (id: any, categoryId: string) => void;
    onCloneRow: (id: any, categoryId: string) => void;
}

const FormSaleItemsTable = ({
    columns,
    category,
    templateData,
    inventoryFields,
    allRowsItems,
    allRowsData,
    filterErrors,
    itemsLoading,
    selectedMarket,
    filterValues,
    filterInventoryValues,
    inventoryItems,
    loadingFields,
    loadingValues,
    loadingOptions,
    searchInDB,
    categoryFieldsWithoutExtended,
    onClickAddRow,
    onChangeSelectedValue,
    onChangeSelectedInventoryValue,
    onChangeInputValue,
    onChangeSearchAssetFields,
    onMenuCloseAfterSearch,
    onChangeInventoryInput,
    onDeleteItemRow,
    onClickSearchInDb,
    onCloneRow,
}: IFormSaleItemsTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Price', 'Quantity', 'Subtotal', 'Actions']);
    const [stringFieldsValues, setStringFieldsValues] = useState<any>();
    const allRowsDataClone = [...(allRowsData || [])];

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    const formatTableColumnsAndKeys = () => {
        const formatTableColumns = [];
        const formatTableExtraKeys: any = [];
        if (columns && columns?.length > 0) {
            columns?.map((key: string) => {
                const formatKey = capitalizeFirstLetter(key);
                const formatElement = (formatKey || '').replaceAll('_', ' ');
                formatTableExtraKeys.push(key);
                formatTableColumns.push(formatElement);
                return key;
            });
        }
        if (inventoryFields && inventoryFields?.length > 0) {
            inventoryFields?.map((key: any) => {
                const formatKey = key?.name === 'sales_grade' ? 'Sales Grade' : capitalizeFirstLetter(key?.name);
                const formatElement = (formatKey || '').replaceAll('_', ' ');
                formatTableExtraKeys.push(key?.name);
                formatTableColumns.push(formatElement);
                return key;
            });
        }
        formatTableColumns.push('Price');
        formatTableColumns.push('Quantity');
        formatTableColumns.push(`${CURRENCY_SYMBOLS?.[`${(selectedMarket?.currencyName || '').toLowerCase()}`]} Subtotal`);
        formatTableColumns.push('Actions');
        setTableColumns(formatTableColumns || []);
    };

    useEffect(() => {
        formatTableColumnsAndKeys();
    }, [columns]);

    let findTotalQuantity = 0;
    let subPriceTotal = 0;

    allRowsData && allRowsData?.length > 0 &&
        [...allRowsData].map((currentValue: any) => {
            subPriceTotal = subPriceTotal + Number(currentValue.price || 0) * Number(currentValue?.qty || 0);
            findTotalQuantity = Number(findTotalQuantity) + Number(currentValue?.qty || 0);
            return currentValue;
        });

    useEffect(() => {
        setStringFieldsValues({
            ...(filterValues || {}),
        });
    }, []);

    const onChangeInventoryStringInput = async (value: string | null | undefined, type: string, index?: any) => {
        if (index || index === 0) {
            const findField = inventoryItems && inventoryItems?.length > 0 && inventoryItems?.find((field: any) => field?.name === type);
            setStringFieldsValues({
                ...(stringFieldsValues || {}),
                [index]: {
                    ...(stringFieldsValues?.[index] || {}),
                    [type]: findField?.dataType === 'number' ? Number(value || 0) : value,
                },
            });
            onChangeInventoryInput(value, type || '', index);
        }
    };

    return (
        <Table
            columns={tableColumns || []}
            containerStyle={'min-h-[300px] relative bg-white pb-10 border border-2'}
            hidePagination={true}
        >
            <>
                {[...Array(allRowsDataClone?.length || 1)].map((x, i) => {
                    const findValue = allRowsDataClone && allRowsDataClone?.length > 0 && allRowsDataClone?.find((data: any, idx: number) => idx === i);
                    const findErrorRow = filterErrors && filterErrors?.length > 0 && filterErrors?.find((error: any, idx: number) => error?.index === i);
                    return (
                        <tr key={i} className='bg-white'>
                            {!loadingValues && columns && columns?.length > 0 &&
                                columns?.map((field: string, idx: number) => {
                                    let values: any = (filterValues && filterValues?.[category?.name]?.[i] && filterValues?.[category?.name]?.[i][field])
                                        ? filterValues?.[category?.name]?.[i][field]?.length > 0 &&
                                        filterValues?.[category?.name]?.[i][field]?.map((value: string) => {
                                            return { value: value, label: value };
                                        })
                                        : templateData?.[field] &&
                                        templateData?.[field]?.length > 0 &&
                                        templateData?.[field]?.map((value: string) => {
                                            return { value: value, label: value };
                                        });
                                    const findIfExtended = (categoryFieldsWithoutExtended?.[category?.name] && categoryFieldsWithoutExtended?.[category?.name]?.length > 0) && categoryFieldsWithoutExtended?.[category?.name]?.find((item: any) => item?.name === field)
                                    if (!findIfExtended) {
                                        values = [
                                            { value: 'Any', label: 'Any' },
                                            ...values || [],
                                        ]
                                    }
                                    return (
                                        <td key={field} className='px-2 py-2 min-w-[150px]'>
                                            <AsyncSelectCheckbox
                                                placeholder={findValue?.descriptor?.[field] || ''}
                                                options={values}
                                                dynamicOptions={true}
                                                isLoading={false}
                                                loadingOptions={loadingOptions?.index === i}
                                                index={i}
                                                parent={category}
                                                error={findErrorRow?.[field]}
                                                uniqueName={field || ''}
                                                optionsItemStyle='!break-words text-[16px] font-normal'
                                                selectedOption={findValue?.descriptor?.[field]}
                                                showSearchDB={((searchInDB?.index === i && searchInDB?.[field]) && searchInDB?.category?.value === category?.id) || false}
                                                onChangeValue={onChangeSearchAssetFields}
                                                onMenuClose={onMenuCloseAfterSearch}
                                                onClickSearchInDb={onClickSearchInDb}
                                                onChangeSelectedOption={onChangeSelectedValue}
                                            />
                                        </td>
                                    );
                                })}
                            {(!loadingValues && columns && columns?.length > 0 && inventoryFields && inventoryFields?.length > 0) && inventoryFields?.map((item: any, index: number) => {
                                let values: any = item?.values;
                                if (item?.dataType === 'boolean') {
                                    values = [
                                        { value: true, name: 'True' },
                                        { value: false, name: 'False' },
                                    ];
                                } else if (item?.dataType === 'list') {
                                    values =
                                        item?.values &&
                                        item?.values?.length > 0 &&
                                        values?.map((value: string) => {
                                            return { value: value, label: value };
                                        });
                                }
                                return (
                                    <td key={index} className='px-2 py-2 min-w-[150px]'>
                                        {(item?.dataType === 'boolean' || item?.dataType === 'list') ?
                                            <SelectCheckbox
                                                key={index}
                                                placeholder={findValue?.inventory?.[item?.name] || ''}
                                                options={values}
                                                containerStyle='w-full'
                                                index={i}
                                                parent={category}
                                                error={findErrorRow?.[item?.name]}
                                                selectStyle={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '2px' }}
                                                onChangeSelectedOption={onChangeSelectedInventoryValue}
                                                uniqueName={item?.name || ''}
                                            />
                                            : <Input
                                                containerStyle={'w-full'}
                                                placeholder={''}
                                                index={i}
                                                parent={category}
                                                error={findErrorRow?.[item?.name]}
                                                inputUniqueName={item?.name}
                                                onChangeInput={onChangeInventoryStringInput}
                                                inputValue={findValue?.inventory?.[item?.name]}
                                                showValue={true}
                                            />
                                        }
                                    </td>
                                );
                            })}
                            <td className='px-2 py-2 min-w-[130px]'>
                                <Input
                                    containerStyle={'w-full'}
                                    placeholder={'Price'}
                                    inputUniqueName={'price'}
                                    onChangeInput={onChangeInputValue}
                                    inputValue={findValue?.price}
                                    parent={category}
                                    showValue={true}
                                    index={i}
                                    error={findErrorRow?.price}
                                />
                            </td>
                            <td className='px-2 py-2 min-w-[130px]'>
                                <Input
                                    containerStyle={'w-full'}
                                    placeholder={'Qty'}
                                    inputUniqueName={'qty'}
                                    onChangeInput={onChangeInputValue}
                                    inputValue={findValue?.qty}
                                    showValue={true}
                                    parent={category}
                                    index={i}
                                    error={findErrorRow?.qty}
                                />
                            </td>
                            <td className='px-2 py-2'>
                                {CURRENCY_SYMBOLS?.[`${(selectedMarket?.currencyName || '').toLowerCase()}`]}
                                {(Number(findValue?.price || 0) * Number(findValue?.qty || 0)).toFixed(2)}
                            </td>
                            <td className='px-2 min-w-[100px]'>
                                <div className='flex flex-row items-center'>
                                    <div onClick={() => onDeleteItemRow(i, category?.value || '')}>
                                        <img title={'Delete Row'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[12px] object-contain cursor-pointer mr-3'} />
                                    </div>
                                    <div onClick={() => onCloneRow(i, category?.value || '')}>
                                        <img title={'Clone Row'} alt='clone item' src={'/assets/table-actions/clone.svg'} className={'h-[25px] object-contain cursor-pointer mr-1'} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                <tr className='bg-white 1'>
                    {(columns && columns?.length > 0) &&
                        [...Array((columns?.length || 0) + (inventoryFields?.length || 0)),]?.map((field: string, idx: number) => {
                            if (idx === (columns?.length || 0) + (inventoryFields?.length || 0) - 1) {
                                return (
                                    <td key={idx} className='px-6 py-2 flex flex-row justify-end'>
                                        Totals:
                                    </td>
                                );
                            } else if (idx === 0) {
                                return (
                                    <td key={idx} className='px-2 py-2'>
                                        <Button
                                            label='Add Item'
                                            onClickButton={() => onClickAddRow(category)} />
                                    </td>
                                );
                            } else {
                                return <td key={idx} className='px-2 py-2'></td>;
                            }
                        })}
                    <td className='px-2 py-2 text-end' colSpan={4}>
                        <span className='text-primary-default'>{category?.displayName}</span>
                        <span className='ml-2 mr-1'>| Quantity: <span className='text-primary-default'>{findTotalQuantity}</span></span>
                        <span className='mr-2'>| Subtotal: <span className='text-primary-default'>
                            {CURRENCY_SYMBOLS?.[`${(selectedMarket?.currencyName || '').toLowerCase()}`]}
                            {Number(subPriceTotal || 0).toFixed(2)}
                        </span>
                        </span>
                    </td>
                </tr>
            </>
        </Table>
    );
};

export default FormSaleItemsTable;
