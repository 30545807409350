import React from 'react';
import { IWarehouseSectionChildren } from '../../../../interfaces/warehouse-locations/IWarehouseSections';
import Table from '../../../../shared/table';

const tableColumns = ['ID', 'Name', 'Min Range', 'Max Range', 'Categories', 'Custom Section Child', 'Parent', 'Last Child Space Type', 'Space Capacity', 'Action']

interface ISectionChildsTable {
    rows: any;
    rowsLoading?: boolean;
    parents?: any;
    accessControl?: any;
    actions?: any;
    onEditChild: (id: string) => void;
    onDeleteChild: (id: string) => void;
}

const SectionChildsTable = ({
    rows,
    rowsLoading,
    parents,
    accessControl,
    actions,
    onEditChild,
    onDeleteChild,
}: ISectionChildsTable) => {
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            containerStyle={'mt-0'}>
            {rowsLoading ?
                <>
                    {[...Array(7)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IWarehouseSectionChildren, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(row?.id || '')}>
                                        <p className='cut-text'>{row?.id}</p>
                                        <div>
                                            <img title={'Copy Section ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[25px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                        </div>
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.name || ''}</td>
                                <td className='px-6 py-4'>{row?.minRange || 1}</td>
                                <td className='px-6 py-4'>{row?.maxRange || 1}</td>
                                <td className='px-2 py-4'>
                                    {row?.categories && row?.categories?.map((item: any, index) => `${index ? ', ' : ''}${item?.name}`)}
                                </td>
                                <td className='px-6 py-4'>{row?.customChildSpace ? 'Yes' : 'No'}</td>
                                <td className='px-6 py-4'>{row?.parentId && parents ? parents?.find((item: any) => item?.value === row?.parentId)?.label : row?.parentId || ''}</td>
                                <td className='px-6 py-4'>{row?.type || ''}</td>
                                <td className='px-6 py-4'>{row?.capacity || ''}</td>
                                <td className='px-6 py-4 min-w-[150px]'>
                                    <div className='flex flex-row'>
                                        <div onClick={() => row?.id && (!accessControl || actions?.['Edit Child']) && onEditChild(row.id)}>
                                            <img title={'Edit Child'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={'h-[14px] object-contain cursor-pointer mr-3'} />
                                        </div>
                                        <div onClick={() => row?.id && (!accessControl || actions?.['Delete Child']) && onDeleteChild(row?.id)}>
                                            <img title={'Delete Child'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SectionChildsTable;