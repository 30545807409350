import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditSaleOrderStatuses } from '../../../../store/inventory/shared/sales/salesSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IMarkProcessedModal {
    openModal: boolean;
    saleId: string;
    quoteId: string;
    saleDetails: any;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const MarkProcessedModal = ({
    openModal,
    saleId,
    quoteId,
    saleDetails,
    handleCloseModal,
    onSuccessfullyChanged,
}: IMarkProcessedModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [error, setError] = useState<string>();
    const [csvData, setCsvData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fileSelected, setFileSelected] = useState<any>();
    const [loadingDownloadCSV, setLoadingDownloadCSV] = useState<boolean>(false);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        handleCloseModal()
    }

    const onApproveAction = async () => {
        setLoading(true)
        try {
            const formatSelectedItems: any = []
            saleDetails?.saleItems && saleDetails?.saleItems?.length > 0 && saleDetails?.saleItems?.map((item: any) => {
                const itemName = [
                    ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                    ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
                ].join(' ');
                const itemFormated = {
                    ...item || {},
                    itemName: itemName,
                    status: 'matching',
                    qty: undefined,
                    match: {
                        descriptor: item?.descriptor,
                        inventory: item?.inventory,
                        price: item?.price
                    },
                    newLocation: 'Batch Item',
                    notMatching: false
                }
                const qty = Number(item?.qty || 1);
                for (let i = 0; i < qty; i++) {
                    formatSelectedItems.push(itemFormated);
                }
                return item
            })

            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                transform: (value, header) => {
                    if (header === 'Item ID/ IMEI') {
                        return value.toString();
                    }
                    return value;
                },
                dynamicTyping: (header) => {
                    if (header === 'Item ID/ IMEI') {
                        return false;
                    }
                    return true;
                },
                complete: async function (results: any) {
                    const formatData: any = []
                    if (results?.data && results?.data?.length > 0) {
                        results?.data?.map((item: any) => {
                            const findItem = formatSelectedItems && formatSelectedItems?.length > 0 && formatSelectedItems?.find((oldItem: any) => oldItem?.itemName === item?.['Item Name'])
                            if (findItem) {
                                formatData.push({
                                    ...findItem || {},
                                    itemName: undefined,
                                    itemId: item?.['Item ID/ IMEI'],
                                    price: item?.Price ? Number(item?.Price) : Number(findItem?.price),
                                    qty: undefined
                                })
                            }
                            return item
                        })
                    }
                    const payloadFormatData: any = {
                        ...saleDetails || {},
                        status: 'processed',
                        completed: true,
                        selectedItems: formatData || []
                    }
                    await dispatch(tryToEditSaleOrderStatuses({ id: saleId, data: payloadFormatData })).unwrap();
                }
            })
            onSuccessfullyChanged('success', 'Sale Order marked as processed successfully!')
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }

    const onDownloadCSVData = () => {
        setLoadingDownloadCSV(true)
        setCsvData(undefined)
        const formatItems: any = [['Item Name', 'Item ID/ IMEI', 'Currency', 'Price']];
        (saleDetails?.saleItems && saleDetails?.saleItems?.length > 0) && saleDetails?.saleItems?.map((item: any) => {
            const itemName = [
                ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
            ].join(' ');

            const itemData = [itemName, item?.itemCode || item?.itemId, item?.currency, Number(item.price || 0).toFixed(2)]
            const qty = Number(item?.qty || 1);
            for (let i = 0; i < qty; i++) {
                formatItems.push(itemData);
            }
            return item;
        })
        setCsvData(formatItems)
    }

    useEffect(() => {
        if (csvData && loadingDownloadCSV) {
            csvLink.current.link.click()
            setLoadingDownloadCSV(false)
        }
    }, [csvData])

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Mark as Processed'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Action Verification</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='mt-2 mb-6'>Do you want to mark this sales order as processed Sale?</p>
                    {error &&
                        <div className='flex flex-row justify-center my-5'>
                            <Error text={error} />
                        </div>
                    }
                    <div className='my-5 flex flex-row items-center'>
                        <p className='mr-4 font-bold'>Download items:</p>
                        <Button
                            label={loadingDownloadCSV ? 'Loading...' : 'Download CSV'}
                            className='btn-main !rounded min-w-[150px]'
                            onClickButton={() => !loadingDownloadCSV && onDownloadCSVData()}
                        />
                        <CSVLink
                            ref={csvLink}
                            data={(csvData && csvData?.length > 0) ? csvData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                            headers={csvData?.[0] || []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Sale-${quoteId}-items-${moment().format('dd-mm-yyyy')}.csv`}
                        >
                        </CSVLink>
                    </div>
                    <div className='my-3'>
                        <p className='font-bold'>Import Items With Item ID</p>
                        <div className='flex flex-col justify-center rounded my-1'>
                            <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                    icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                    className={'btn-main min-w-[150px] !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                    label={'Upload file'}
                                    dataQa={'select-file'}
                                />
                            </div>
                            {fileSelected && <p className='mt-2 mb-5 font-bold'>{fileSelected?.name}</p>}
                        </div>
                    </div>
                    <div className='flex flex-row justify-around my-5'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px]`}
                            onClickButton={() => !loading && onCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default MarkProcessedModal;