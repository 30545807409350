import React, { useEffect, useState } from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import LocationsTable from '../tables/LocationsTable';


interface IReservedLocationsModal {
    openModal: boolean;
    categories?: any;
    reservedItems?: any;
    handleCloseModal: () => void;
}

const ReservedLocationsModal = ({
    openModal,
    reservedItems,
    categories,
    handleCloseModal,
}: IReservedLocationsModal) => {
    const [categoriesLocations, setCategoriesLocations] = useState<any>();

    const onCloseModal = () => {
        setCategoriesLocations(undefined)
        handleCloseModal()
    }

    useEffect(() => {
        if (reservedItems && reservedItems?.length > 0) {
            const groupedData = reservedItems?.reduce((acc: any, current: any) => {
                if (!acc[current?.categoryId]) {
                    acc[current?.categoryId] = [];
                }
                acc[current?.categoryId || ''].push(current);
                return acc;
            }, {});
            setCategoriesLocations(groupedData)
        }
    }, [openModal, reservedItems])

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Reserved Items Locations'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Locations</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='mt-2 mb-6'>See locations of Reserved Items?</p>
                    {categoriesLocations && Object.keys(categoriesLocations)?.map((item: any, index: number) => {
                        const findCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.value === item)
                        return (
                            <div key={index} className='my-5'>
                                {findCategory &&
                                    <div className='flex flex-row items-center mb-3'>
                                        <img src={findCategory?.enabledIcon} className={'object-contain h-[22px] min-h-[22px] mr-3'} />
                                        <p className='font-semibold text-[19px]'>{findCategory?.label}</p>
                                    </div>
                                }
                                {categoriesLocations?.[item] && categoriesLocations?.[item]?.length > 0 &&
                                    <LocationsTable
                                        rows={categoriesLocations?.[item]}
                                    />
                                }
                            </div>
                        )
                    })}
                    <div className='flex flex-row justify-center my-5'>
                        <Button
                            label='Go Back'
                            dataQa={'go-back-button'}
                            className={'btn-primary-rounded-less min-w-[150px]'}
                            onClickButton={() => onCloseModal()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ReservedLocationsModal;