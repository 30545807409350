import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IMEI_STATUESES } from '../../../../utils/constants/imei-statuses';
import { REVIEW_CONDITIONS } from '../../../../utils/constants/review-conditions';
import { tryToFetchAssetValues } from '../../../../store/inventory/shared/assets/assetsSlice';
import { tryToFetchImeiStatusCheck } from '../../../../store/inventory/broker/orders/ordersSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IReviewItemDetailsModal {
    openModal: boolean;
    categories?: any;
    rowSelected?: any;
    availableLocations?: any;
    deliveredItems?: any;
    itemCopyData?: any;
    currency?: any;
    handleCloseModal: () => void;
    onAddItemDetails: (data: any, rowSelected?: number) => void;
}

const ReviewItemDetailsModal = ({
    openModal,
    categories,
    rowSelected,
    itemCopyData,
    deliveredItems,
    availableLocations,
    currency,
    handleCloseModal,
    onAddItemDetails,
}: IReviewItemDetailsModal) => {
    const dispatch = useAppDispatch();
    const [loadingValues, setLoadingValues] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [searchInDB, setSearchInDB] = useState<any>();
    const [searchField, setSearchField] = useState<any>();
    const [loadingOptions, setLoadingOptions] = useState<any>();
    const [itemErrors, setItemErrors] = useState<any>();
    const [imeiError, setImeiError] = useState<string>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [inventoryFields, setInventoryFields] = useState<any>();
    const [filteredFields, setFilteredFields] = useState<any>();
    const [assetData, setAssetData] = useState<any>();
    const [otherData, setOtherData] = useState<any>();
    const [itemLocation, setItemLocation] = useState<any>()
    const [inventoryData, setInventoryData] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [fieldDetails, setFieldDetails] = useState<any>();
    const [copyDetails, setCopyDetails] = useState<any>();
    const [matchData, setMatchData] = useState<any>();
    const [loadingImeiStatus, setLoadingImeiStatus] = useState<boolean>(false);

    const capitalizeFirstLetter = (word: string) => {
        const formatElement = (word || '').replaceAll('_', ' ')
        return formatElement.charAt(0).toUpperCase() + formatElement.slice(1);
    }

    const onGettingAvailableLocation = () => {
        if (selectedCategory) {
            const getCategoryLocations = availableLocations?.[selectedCategory?.value] && JSON.parse(JSON.stringify(availableLocations?.[selectedCategory?.value]));
            const newLocation = getCategoryLocations && getCategoryLocations.shift()
            setItemLocation(newLocation)
        }
    }

    const onGettingTemplateFields = async () => {
        if (selectedCategory?.value) {
            try {
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(selectedCategory?.value)).unwrap()
                if (response?.fields) {
                    setInventoryFields(response?.inventoryFields)
                    setFieldDetails(response?.fields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    const onGettingAllAssetFields = async () => {
        try {
            if (selectedCategory && ((assetData && matchData && selectedCategory?.value === rowSelected?.categoryId) || selectedCategory?.value !== rowSelected?.categoryId || !matchData)) {
                let data: any = {
                    values: [],
                    inventory: true
                }
                if (assetData) {
                    const keysArray = assetData && Object.keys(assetData)
                    const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [name]: assetData?.[name] } })
                    data = {
                        ...data || {},
                        values: formatValues || []
                    }
                }
                if (searchField && searchField) {
                    data = {
                        ...data || {},
                        search: searchField || {}
                    }
                }
                const response: any = await dispatch(tryToFetchAssetValues({ id: selectedCategory?.value || '', data })).unwrap()
                if (searchField) {
                    setFilteredFields(response)
                } else {
                    setFilteredFields(response)
                    setTemplateFields(response || [])
                }
                setLoadingOptions(undefined)
                setSearchField(undefined)
                setLoadingValues(false)
            }
        } catch (err) {
            // err here
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            onGettingAllAssetFields()
        }
    }, [assetData, selectedCategory])

    useEffect(() => {
        onGettingAllAssetFields()
    }, [assetData, rowSelected, matchData])

    const onClickEnterImei = async (e: any, type?: string) => {
        if (e?.key === 'Enter' && e?.target?.value && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets')) {
            setLoadingImeiStatus(true)
            try {
                const res = await dispatch(tryToFetchImeiStatusCheck(e?.target?.value || otherData?.itemId || '')).unwrap()
                setImeiError(undefined)
                setLoadingImeiStatus(false)
                setOtherData((prevOtherData: any) => ({
                    ...prevOtherData || {},
                    imeiStatus: (res?.data?.blackliststatus || '').toLowerCase() === 'yes' ? 'Barred' : 'OK'
                }))
                return res?.data?.blackliststatus
            } catch (err: any) {
                setImeiError(`${err}`)
            }
            setLoadingImeiStatus(false)
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            onGettingTemplateFields()
        }
    }, [selectedCategory])

    useEffect(() => {
        if (selectedCategory && availableLocations) {
            onGettingAvailableLocation()
        }
    }, [selectedCategory, availableLocations])

    const onCloseModal = () => {
        handleCloseModal();
        setItemErrors(undefined)
        setSelectedCategory(undefined)
        setLoadingOptions(undefined)
        setTemplateFields(undefined)
        setInventoryFields(undefined)
        setAssetData(undefined)
        setOtherData(undefined)
        setInventoryData(undefined)
        setLoadingImeiStatus(false)
        setLoadingValues(false)
        setFieldDetails(undefined)
        setGeneralError(undefined)
        setItemLocation(undefined)
        setCopyDetails(undefined)
    }

    useEffect(() => {
        if (rowSelected && categories) {
            const findCategory = categories?.length > 0 && categories?.find((item: any) => item?.value === rowSelected?.categoryId)
            setSelectedCategory(findCategory)
        }
    }, [rowSelected, categories])

    useEffect(() => {
        if (rowSelected && selectedCategory && (selectedCategory?.value === rowSelected?.categoryId)) {
            if (copyDetails) {
                setInventoryData(copyDetails?.inventory || undefined)
                setAssetData(copyDetails?.descriptor || undefined)
                setOtherData({
                    ...otherData || {},
                    supplierCondition: copyDetails?.supplierCondition
                })
            } else if (!copyDetails && itemCopyData) {
                setInventoryData(undefined)
                setAssetData(undefined)
                setOtherData(undefined)
            } else {
                setInventoryData(rowSelected?.inventory || undefined)
                setAssetData(rowSelected?.descriptor || undefined)
            }
        }
    }, [rowSelected, copyDetails, selectedCategory])

    const onChangeSelectedValue = (value: any, type?: string) => {
        if (type) {
            setLoadingValues(true)
            setAssetData({
                ...(assetData || {}),
                [type]: value?.value || undefined,
            });
        }
    };

    const onClickClearFields = () => {
        setItemErrors(undefined)
        setLoadingOptions(undefined)
        setAssetData(undefined)
        setOtherData(undefined)
        setInventoryData(undefined)
        setLoadingImeiStatus(false)
        setLoadingValues(false)
        setFieldDetails(undefined)
        setGeneralError(undefined)
        setCopyDetails(undefined)
        setMatchData(undefined)
    }

    const onChangeSelectedCategory = (e: any, type?: string) => {
        setSelectedCategory(e)
        setCopyDetails(undefined)
        setAssetData(undefined)
        setInventoryData(undefined)
        setImeiError(undefined)
        setOtherData(undefined)
    }

    useEffect(() => {
        if (itemCopyData) {
            setCopyDetails(itemCopyData)
        } else {
            setCopyDetails(undefined)
        }
    }, [itemCopyData])

    useEffect(() => {
        if (rowSelected) {
            setMatchData(rowSelected)
        } else {
            setMatchData(undefined)
        }
    }, [rowSelected])

    const onChangeOtherSelectedValues = (e: any, type?: string) => {
        if (type) {
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [type]: e?.value
            }))
        }
    }

    const onChangeOtherInputValues = (e: any, type?: string) => {
        if (type) {
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [type]: e
            }))
        }
    }

    const onChangeSearchAssetFields = (e: any, type?: string) => {
        if (type && e) {
            if (templateFields && templateFields?.[type]) {
                const formatFilteredFields = filteredFields ? filteredFields?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase())) : (templateFields?.[type] && templateFields?.[type]?.length > 0) && templateFields?.[type]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB({ [type]: true, searchValue: e })
                    setFilteredFields((prevFilteredFields: any) => ({
                        ...prevFilteredFields || {},
                        [type]: formatFilteredFields
                    }))
                }
                else {
                    setFilteredFields((prevFilteredFields: any) => ({
                        ...prevFilteredFields || {},
                        [type]: undefined
                    }))
                    setLoadingOptions({ [type]: true })
                    setSearchField({
                        search: {
                            [type]: e
                        }
                    })
                }
            }
        }
    }

    const onChangeSelectedInventoryValue = (e: any, type?: string) => {
        if (type) {
            setInventoryData((prevInventoryData: any) => ({
                ...prevInventoryData || {},
                [type]: e?.value
            }))
        }
    }

    const onChangeInventoryStringInput = (e: any, type?: string) => {
        if (type) {
            setInventoryData((prevInventoryData: any) => ({
                ...prevInventoryData || {},
                [type]: e
            }))
        }
    }

    const onMenuCloseAfterSearch = (type?: string) => {
        if (type) {
            setSearchField(undefined)
            setSearchInDB(undefined)
        }
    }

    useEffect(() => {
        if (searchField) {
            onGettingAllAssetFields();
        }
    }, [searchField]);

    const onClickSearchInDb = (type?: any) => {
        if (searchInDB?.[type]) {
            setFilteredFields(undefined)
            setLoadingOptions({ [type]: true })
            setSearchField({
                [type]: searchInDB?.searchValue,
            })
            if (assetData && assetData?.[type]) {
                const formatFormData = Object.assign(assetData)
                delete formatFormData[type]
                setAssetData(formatFormData)
            }
        }
    }

    const onAddItemToDelivered = (copy?: boolean) => {
        if (!selectedCategory) {
            setGeneralError('Please select category!');
            return;
        }
        setGeneralError(undefined)
        let inputErrors: any
        if (!otherData?.itemId) {
            inputErrors = {
                ...inputErrors || {},
                itemId: selectedCategory?.label && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI is required' : 'Item Id is required'
            }
        }
        const findIfAlreadyAdded = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.find((item: any) => item?.itemId === otherData?.itemId)
        if (findIfAlreadyAdded) {
            inputErrors = {
                ...inputErrors || {},
                itemId: selectedCategory?.label && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI is duplicated' : 'Item Id is duplicate'
            }
        }
        if (selectedCategory?.label && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') && !otherData?.imeiStatus) {
            inputErrors = {
                ...inputErrors || {},
                imeiStatus: 'IMEI status is required'
            }
        }
        if (!otherData?.supplierCondition) {
            inputErrors = {
                ...inputErrors || {},
                supplierCondition: 'Supplier Grade is required'
            }
        }
        if (!itemLocation) {
            inputErrors = {
                ...inputErrors || {},
                location: 'Location is required'
            }
        }
        const keys = templateFields && Object.keys(templateFields)
        if (templateFields && keys && keys?.length > 0) {
            keys?.map((item: any) => {
                const findField = (fieldDetails && fieldDetails?.length > 0) && fieldDetails?.find((field: any) => field?.name === item)
                const findIfInSelected = assetData?.[item]
                if (!findIfInSelected && findField?.required) {
                    inputErrors = {
                        ...(inputErrors || {}),
                        descriptor: {
                            ...inputErrors?.descriptor || {},
                            [item]: `${item} is required`
                        }
                    }
                }
                return item
            })
        }
        if (inventoryFields && inventoryFields?.length > 0) {
            inventoryFields?.map((item: any) => {
                if (!inventoryData?.[item?.name] && item?.required) {
                    inputErrors = {
                        ...(inputErrors || {}),
                        inventory: {
                            ...inputErrors?.inventory || {},
                            [item?.name]: `${item?.name} is required`
                        }
                    }
                }
                return item
            })
        }
        if (inputErrors) {
            setItemErrors(inputErrors)
        } else {
            setItemErrors(undefined)
            const formatItem = {
                ...otherData || {},
                locations: itemLocation,
                locationId: itemLocation?.barcode,
                descriptor: assetData,
                inventory: inventoryData,
                categoryId: selectedCategory?.value,
                price: rowSelected?.price || 0,
                currency: rowSelected?.currency || currency,
                match: rowSelected ?
                    {
                        descriptor: rowSelected?.descriptor,
                        inventory: rowSelected?.inventory
                    }
                    : undefined
            }
            onAddItemDetails(formatItem)
            if (copy) {
                setOtherData({
                    ...otherData || {},
                    itemId: undefined,
                    imeiStatus: undefined
                })
                setItemLocation(undefined)
            } else {
                onCloseModal()
            }
        }
    }

    const locationSpace = itemLocation?.space?.name && `${itemLocation?.space?.name}`
    const locationFormat = itemLocation?.label || itemLocation && (Object?.keys(itemLocation))?.forEach((obj: any, idx: number) => {
        return idx === 0 ? `${itemLocation?.[obj]}` : ` - ${itemLocation?.[obj]}`
    })
    const fullLocation = locationFormat && locationSpace && `${locationSpace} - ${locationFormat}`
    
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight
            title='Review'
            contentContainerStyle={'!min-w-[750px] min-h-[150px]'}>
            <div className={'p-2 min-w-[700px]'}>
                <p className='mb-4 font-semibold text-md'>Delivered Item</p>
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <div className='border-b-2 border-[#e4e4e4]'>
                        <SelectCheckbox
                            name='Category'
                            options={categories}
                            containerStyle='!w-auto max-w-[250px]'
                            selectedOption={selectedCategory}
                            onChangeSelectedOption={onChangeSelectedCategory}
                        />
                    </div>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start border-b-2 border-[#e4e4e4]'>
                        {imeiError && <div className='col-span-2 mb-2 mt-3'><Error text={imeiError} /></div>}
                        <Input
                            containerStyle={'w-full !mt-2'}
                            label={(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI' : 'Item Id'}
                            placeholder={' '}
                            inputUniqueName={'itemId'}
                            onChangeInput={onChangeOtherInputValues}
                            inputValue={otherData?.itemId}
                            showValue={true}
                            disabled={loadingImeiStatus}
                            onHandleKeyDown={onClickEnterImei}
                            error={itemErrors?.itemId}
                        />
                        {(selectedCategory && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets'))
                            && <td className='px-2 py-2 min-w-[120px]'>
                                <SelectCheckbox
                                    placeholder={' '}
                                    name='IMEI Status'
                                    options={IMEI_STATUESES}
                                    containerStyle={'w-full'}
                                    selectedOption={otherData?.imeiStatus ? IMEI_STATUESES?.find((item: any) => item?.value === otherData?.imeiStatus) : undefined}
                                    selectStyle={{ backgroundColor: 'white', borderRadius: '4px', border: otherData?.imeiStatus ? otherData?.imeiStatus === 'OK' ? '1px solid green' : '1px solid red' : '1px solid #e5e7eb', padding: 0 }}
                                    onChangeSelectedOption={onChangeOtherSelectedValues}
                                    uniqueName={'imeiStatus'}
                                    error={itemErrors?.imeiStatus}
                                />
                            </td>
                        }
                    </div>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start border-b-2 border-[#e4e4e4]'>
                        {templateFields && (Object.keys(templateFields || []))?.map((field: string, index: number) => {
                            const values = filteredFields && filteredFields?.[field] ?
                                filteredFields?.[field]?.length > 0 &&
                                filteredFields?.[field]?.map((value: string) => {
                                    return { value: value, label: value };
                                })
                                :
                                templateFields?.[field] &&
                                templateFields?.[field]?.length > 0 &&
                                templateFields?.[field]?.map((value: string) => {
                                    return { value: value, label: value };
                                });
                            return (
                                <>
                                    <AsyncSelectCheckbox
                                        placeholder={(assetData?.[field] || '')}
                                        options={values}
                                        dynamicOptions={true}
                                        isLoading={loadingValues}
                                        name={field && capitalizeFirstLetter(field)}
                                        loadingOptions={loadingOptions}
                                        error={itemErrors?.descriptor?.[field]}
                                        uniqueName={field || ''}
                                        selectedOption={assetData?.[field]}
                                        showSearchDB={(searchInDB?.[field]) || false}
                                        optionsItemStyle='!break-words text-[16px] font-normal'
                                        onChangeValue={onChangeSearchAssetFields}
                                        onMenuClose={onMenuCloseAfterSearch}
                                        onClickSearchInDb={onClickSearchInDb}
                                        onChangeSelectedOption={onChangeSelectedValue}
                                    />
                                </>
                            )
                        })
                        }
                        {(templateFields && Object?.keys(templateFields)?.length > 0) && (inventoryFields && inventoryFields?.length > 0) && inventoryFields?.map((item: any, index: number) => {
                            let values: any = item?.values;
                            if (item?.dataType === 'boolean') {
                                values = [
                                    { value: true, name: 'True' },
                                    { value: false, name: 'False' },
                                ];
                            } else if (item?.dataType === 'list') {
                                values =
                                    item?.values &&
                                    item?.values?.length > 0 &&
                                    values?.map((value: string) => {
                                        return { value: value, label: value };
                                    });
                            }
                            return (
                                <>
                                    {item?.dataType === 'boolean' || item?.dataType === 'list'
                                        ? <SelectCheckbox
                                            key={index}
                                            name={item?.name && capitalizeFirstLetter(item?.name)}
                                            placeholder={inventoryData?.[item?.name] || ''}
                                            options={values}
                                            containerStyle='w-full'
                                            selectStyle={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '2px' }}
                                            onChangeSelectedOption={onChangeSelectedInventoryValue}
                                            uniqueName={item?.name || ''}
                                            error={itemErrors?.inventory?.[item?.name]}
                                        />
                                        : <Input
                                            containerStyle={'w-full'}
                                            placeholder={''}
                                            label={item?.name}
                                            inputUniqueName={item?.name}
                                            onChangeInput={onChangeInventoryStringInput}
                                            inputValue={inventoryData?.[item?.name]}
                                            showValue={true}
                                            error={itemErrors?.inventory?.[item?.name]}
                                        />
                                    }
                                </>
                            )
                        })}
                    </div>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start'>
                        <SelectCheckbox
                            name={'Supplier Grade'}
                            options={REVIEW_CONDITIONS}
                            containerStyle={'w-full !mt-1'}
                            selectedOption={otherData?.supplierCondition ? REVIEW_CONDITIONS?.find((item: any) => item?.value === otherData?.supplierCondition) : undefined}
                            selectStyle={{ backgroundColor: 'white', borderRadius: '4px', padding: 0 }}
                            onChangeSelectedOption={onChangeOtherSelectedValues}
                            uniqueName={'supplierCondition'}
                            error={itemErrors?.supplierCondition}
                        />
                        <Input
                            label={'Cost'}
                            disabled={true}
                            containerStyle=''
                            inputUniqueName={'price'}
                            inputValue={rowSelected?.price || '0'}
                            showValue={true}
                        />
                        <Input
                            label={'Location'}
                            disabled={true}
                            containerStyle=''
                            inputUniqueName={'location'}
                            inputValue={fullLocation || ''}
                            showValue={true}
                            error={itemErrors?.location}
                        />
                    </div>
                    <div className='min-w-full flex flex-row justify-center my-10'>
                        <Button
                            className={'!py-0 btn-main !bg-white !shadow-none mr-4 !rounded !border-0 !hover:border-0 min-w-[150px]'}
                            label={'Clear fields'}
                            onClickButton={onClickClearFields}
                        />
                        <Button
                            label={'Add and Duplicate'}
                            className={'btn-primary-rounded-less flex flex-row items-center justify-center min-w-[150px] !shadow-none'}
                            onClickButton={() => onAddItemToDelivered(true)}
                        />
                    </div>
                </div>
                <div className='flex flex-row justify-center my-3'>
                    <Button
                        label={'Go To Review'}
                        className='btn-primary-rounded-less min-w-[170px]'
                        onClickButton={() => onAddItemToDelivered(false)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ReviewItemDetailsModal;