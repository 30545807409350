import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToGenerateSectionLocations } from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import Modal from '../../../../shared/modal';
import GenerateLocationsStep from '../details/GenerateLocationsStep';
import SuccessfullyGeneratedLocations from '../details/SuccessfullyGeneratedLocations';


interface IGenerateLocationsModal {
    openModal: boolean;
    sectionId: string;
    handleCloseModal: () => void;
}

const GenerateLocationsModal = ({
    openModal,
    sectionId,
    handleCloseModal,
}: IGenerateLocationsModal) => {
    const dispatch = useAppDispatch();
    const [generateError, setGenerateError] = useState<string>()
    const [generatedSuccessfully, setGeneratedSuccessfully] = useState<boolean>(false)
    const [generateLoading, setGenerateLoading] = useState<boolean>(false)


    const onCloseModal = () => {
        setGenerateError(undefined)
        handleCloseModal();
    }

    const onGenerateLocations = async () => {
        if (sectionId) {
            setGenerateLoading(true)
            try {
                await dispatch(tryToGenerateSectionLocations(sectionId)).unwrap()
                setGeneratedSuccessfully(true)
                setGenerateError(undefined)
            } catch (err) {
                setGenerateError(`${err}`)
            }
            setGenerateLoading(false)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={() => !generateLoading && onCloseModal()}>
            <div className={'p-2 min-w-[500px] min-h-[300px]'}>
                {generatedSuccessfully ?
                    <SuccessfullyGeneratedLocations
                        handleCloseModal={() => !generateLoading && onCloseModal()}
                    />
                    : <GenerateLocationsStep
                        loadingGenerate={generateLoading}
                        generateError={generateError}
                        onGenerateLocations={onGenerateLocations}
                    />
                }
            </div>
        </Modal>
    )
}

export default GenerateLocationsModal;