import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import {
    tryToDownloadAggregatedPurchases,
    tryToDownloadPurchases,
    tryToDownloadReportSheetPurchases,
    tryToFetchPurchases
} from '../../../../store/inventory/broker/reports/reportsServiceSlice';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToFetchAllAssetsTemplates } from '../../../../store/inventory/broker/asset-templates/assetsTemplatesSlice';
import DownloadDataModal from '../modals/DownloadDataModal';
import PurchasesTable from '../tables/PurchasesTable';
import TableFilters from '../details/TableFilters';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import { AdminGuard } from '../../../../guards/RolesGuard';
import { tryToFetchAllCrmData } from '../../../../store/inventory/broker/crm-data/crmDataSlice';

const DAYS_IN_INVENTORY = [{label: '0-30 days', value: '0-30 days'}, {label: '31-60 days', value: '31-60 days'}, {label: '61-90 days', value: '61-90 days'}, {label: '91+days', value: '91+days'}]
const VAT_TYPE = [{label: 'Standard', value: 'Standard'}, {label: 'UK Margin', value: 'UK Margin'},{label: 'EU Margin', value: 'EU Margin'},{label: 'B2B Margin', value: 'B2B Margin'}, {label: 'Zero Rated', value: 'Zero Rated'}]

const PurchasesCategory = () => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<any>([]);
    const [search, setSearch] = useState<string>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>()
    const [markets, setMarkets] = useState<any>()
    const [suppliers, setSuppliers] = useState<IAssetTemplate[]>()
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [selectedSupplier, setSelectedSupplier] = useState<any>('');
    const [selectedVatType, setSelectedVatType] = useState<any>('');
    const [selectedDaysInInventory, setSelectedDaysInInventory] = useState<any>('');
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [templateData, setTemplateData] = useState<any>();
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [showAggregated, setShowAggregated] = useState<boolean>(false)
    const [showReportSheet, setShowReportSheet] = useState<boolean>(false)
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [downloadError, setDownloadError] = useState<string>()
    const [loadingDownloadReportSheet, setLoadingDownloadReportSheet] = useState<boolean>(false)
    const [loadingAggregated, setLoadingAggregated] = useState<boolean>(false)
    const [downloadReportSheetError, setDownloadReportSheetError] = useState<string>()
    const [warehousesList, setWarehousesList] = useState<Array<any>>([])
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state.reports.purchases?.data?.page);
    const state = useAppSelector((state) => state.reports);
    const loading = useAppSelector((state) => state?.reports?.purchasesAreLoading)
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const partners = useAppSelector((state) => state.partners);
    const isAdmin = AdminGuard()
    const [getAllMarkets] = useGetAllMarketsMutation()


    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const getSuppliers = async () => {
        const response: any = await dispatch(tryToFetchAllCrmData({ type: 'customer' }))
        const formatCustomer: any = (response?.payload && response?.payload?.data?.length > 0)
        ? response?.payload?.data?.map((s: any) => ({
            ...s,
            label: s?.displayName || s?.name,
            name: s?.name,
            value: s?.name,
        }))
        : [];

        setSuppliers(formatCustomer)
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, value: r?._id, label: r?.displayName }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    const getPartnerAndMarketData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap()
        const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, value: market?._id }))
        setMarkets(formatMarkets || [])
    }

    useEffect(() => {
        getPartnerAndMarketData()
        if (!stateAssets?.assetTemplates) {
            dispatch(tryToFetchAllAssetsTemplates());
        }
    }, [])

    useEffect(() => {
        if (state.purchases) {
            const rows =
                state.purchases?.data?.elements &&
                    Array.isArray(state.purchases?.data.elements)
                    ? state.purchases?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.purchases]);

    const getPurchaseData = async () => {
        let formatBody: any = {
            ...paginationState,
        }
        if (selectedSector) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    category: selectedSector?.name || ''
                }
            }
        }
        if (selectedMarket) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    market: selectedMarket?.name || ''
                }

            }
        }
        if (dateFiltersValues) {
            formatBody = {
                ...formatBody || {},
                data: {
                    ...formatBody?.data || {},
                    dateRange: dateFiltersValues
                }
            }
        }
        if (search) {
            formatBody = {
                ...formatBody || {},
                filters: search
            }
        }
        if(selectedWarehouse){
            formatBody = {
                ...formatBody || {},
                data: {...formatBody?.data, warehouse: selectedWarehouse?._id}
            }
        }
        if(selectedSupplier){
            formatBody = {
                ...formatBody || {},
                data: {...formatBody?.data, supplierName: selectedSupplier?.value}
            }
        }
        if(selectedVatType){
            formatBody = {
                ...formatBody || {},
                data: {...formatBody?.data, vatType: selectedVatType?.label}
            }
        }
        if(selectedDaysInInventory){
            formatBody = {
                ...formatBody || {},
                data: {...formatBody?.data, daysRange: selectedDaysInInventory?.value}
            }
        }
        await dispatch(tryToFetchPurchases(formatBody));
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    useEffect(() => {
        if (partners.partnerWarehouses && !isAdmin) {
            const rows =
                partners.partnerWarehouses && Array.isArray(partners.partnerWarehouses)
                    ? partners.partnerWarehouses.map((r) => ({ ...r, label: r?.name, value: r?._id }))
                    : [];
            if (JSON.stringify(warehousesList) !== JSON.stringify(rows)) {
                setWarehousesList(rows || []);
            }
        }
    }, [partners?.partnerWarehouses])

    useEffect(() => {
        getPurchaseData()
    }, [paginationState, selectedWarehouse, selectedVatType, selectedDaysInInventory, selectedSupplier]);

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        })
        onResetPaginationState()
    }

    const onChangeVatTypeFilter =  (e: any, type?: string) => {
        setSelectedVatType(e)
    }

    const onChangeDaysInInventoryFilter =  (e: any, type?: string) => {
        setSelectedDaysInInventory(e)
    }

    const onChangeSupplierFilter =  (e: any, type?: string) => {
        setSelectedSupplier(e)
    }

    const onChangeSelectedFilter = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'sector':
                    setSelectedSector(e);
                    break;
                case 'market':
                    setSelectedMarket(e);
                    break;
            }
        }
        onResetPaginationState()
    }

    const onChangeWarehouseFilter = (e: any, type?: string) => {
        setSelectedWarehouse(e)
    }

    const onClearAllFilters = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setSelectedWarehouse(undefined)
        setSelectedVatType(undefined)
        setSelectedDaysInInventory(undefined)
        setDateFiltersValues(undefined)
        setSelectedSupplier(undefined)
        onResetPaginationState()
    }

    const onToggleDownloadModal = () => {
        setShowDownloadModal(!showDownloadModal)
    }

    const onToggleReportSheetModal = () => {
        setShowReportSheet(!showReportSheet)
    }

    const onToggleAggregatedModal = () => {
        setShowAggregated(!showAggregated)
    }

    const onDownloadData = async (filters?: any) => {
        setLoadingDownload(true)
        try {
            const response: any = await dispatch(tryToDownloadPurchases(filters)).unwrap()
            setTemplateData(response || [])
            onToggleDownloadModal()
        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingDownload(false)
        }
    }

    const onDownloadReportSheetData =  async (filters?: any) => {
        setLoadingDownloadReportSheet(true)
        try {
            const response: any = await dispatch(tryToDownloadReportSheetPurchases(filters)).unwrap()
            const link = document.createElement('a');
            link.href = response?.data
            link.download = 'Data-Sheet.xlsx';
            link.click();
            onToggleReportSheetModal()

        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingDownloadReportSheet(false)
        }

    }

    const onDownloadAggregated = async (filters?: any) => {
        setLoadingAggregated(true)
        try {
            const response: any = await dispatch(tryToDownloadAggregatedPurchases(filters)).unwrap()
            const link = document.createElement('a');
            link.href = response?.data
            link.download = 'Data-Sheet.xlsx';
            link.click();
            onToggleAggregatedModal()
        } catch (err) {
            setDownloadError(`${err}`)
            setLoadingAggregated(false)
        }
    }

    useEffect(() => {
        if (templateData && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [templateData])

    return (
        <div>
            <TableFilters
                // markets={markets}
                sectors={sectors}
                // selectedMarket={selectedMarket}
                selectedSector={selectedSector}
                dateFiltersValues={dateFiltersValues}
                onChangeDateFilters={onChangeDateFilters}
                onChangeSelectedFilter={onChangeSelectedFilter}
                DAYS_IN_INVENTORY={DAYS_IN_INVENTORY}
                VAT_TYPE={VAT_TYPE}
                selectedVatType={selectedVatType}
                selectedSupplier={selectedSupplier}
                selectedDaysInInventory={selectedDaysInInventory}
                onChangeVatTypeFilter={onChangeVatTypeFilter}
                onChangeDaysInInventoryFilter={onChangeDaysInInventoryFilter}
                onChangeSupplierFilter={onChangeSupplierFilter}
                onChangeSelectedWarehouse={onChangeWarehouseFilter}
                selectedWarehouse={selectedWarehouse}
                suppliers={suppliers}
                warehouses={warehousesList}
            />
            <div className='flex flex-row justify-end flex-wrap'>
                <Button
                    label={'Clear All Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline min-w-auto !mt-0 flex flex-row justify-end bg-transparent mr-2 mb-1'
                    onClickButton={onClearAllFilters}
                />
                 <Button
                    label={'Download Report Sheet'}
                    dataQa={'download-sales'}
                    className='btn-main !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={onToggleReportSheetModal}
                />
                <Button
                    label={'Download Aggregated'}
                    dataQa={'download-sales'}
                    className='btn-main !min-w-[150px] !mt-0 mr-2 mb-1'
                    onClickButton={onToggleAggregatedModal}
                />
            </div>
            <CSVLink
                ref={csvLink}
                data={templateData?.filter((item: any, idx: number, arr: any) => { return idx > 0 }) || []}
                headers={templateData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Purchases.csv`}
            >
            </CSVLink>
            <div>
                <div className={`min-w-[100px]`}>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <PurchasesTable
                    rows={rows}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    rowsLoading={loading}
                />
            </div>
            <DownloadDataModal
                sectors={sectors}
                markets={markets}
                loadingDownload={loadingDownloadReportSheet}
                openDownloadModal={showReportSheet}
                downloadError={downloadReportSheetError}
                setDownloadError={setDownloadReportSheetError}
                handleCloseDownloadModal={onToggleReportSheetModal}
                setLoadingDownload={setLoadingDownloadReportSheet}
                onDownloadData={onDownloadReportSheetData}
                warehouses={warehousesList}
            />
            <DownloadDataModal
                sectors={sectors}
                markets={markets}
                loadingDownload={loadingDownload}
                openDownloadModal={showDownloadModal}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleDownloadModal}
                setLoadingDownload={setLoadingDownload}
                onDownloadData={onDownloadData}
                warehouses={warehousesList}
            />
              <DownloadDataModal
                sectors={sectors}
                markets={markets}
                loadingDownload={loadingAggregated}
                openDownloadModal={showAggregated}
                downloadError={downloadError}
                setDownloadError={setDownloadError}
                handleCloseDownloadModal={onToggleAggregatedModal}
                setLoadingDownload={setLoadingAggregated}
                onDownloadData={onDownloadAggregated}
                warehouses={warehousesList}
                showCategory={false}
            />
        </div>
    )
}

export default PurchasesCategory;