import React from 'react'
import ItemDetailsContainerLoading from './ItemDetailsContainerLoading'
import ItemCostsContainerLoading from './ItemCostsContainerLoading'
import ItemOrderContainerLoading from './ItemOrderContainerLoading'


const DetailsItemContainer = () => {
    return (
        <div className='my-3'>
            <div className={'grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4'}>
                <ItemDetailsContainerLoading />
                <div>
                    <ItemCostsContainerLoading />
                    <ItemOrderContainerLoading />
                </div>
            </div>
        </div>
    )
}

export default DetailsItemContainer