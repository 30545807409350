import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import { ISales } from '../../../../interfaces/sales/ISales';
import { PaginatedSales } from '../../../../interfaces/sales/paginatedStore.type';
import inventoryAxios from '../../../../utils/axios/inventory.axios';


const tryToFetchSales = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedSales>> => {
    const url = `${INVENTORY_API_URL}/sales/orders?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedSales>(url, data);
};

const tryToFetchSingleSaleForm = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/order/${id}`);
};

const tryToFetchSaleOrderContent = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/order/content/${id}`);
};

const tryToFetchSingleSale = (saleId: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/${saleId}`);
};

const tryToFetchSaleOrderSplitDetails = (saleId: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/order/split/${saleId}`);
};

const tryToEditSaleStatus = (id: string, status: string, data?: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/status/${status}`, data);
};

const tryToConfirmReviewSale = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/split/${id}`, data);
};

const tryToSplitOrderSale = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/split/${id}`);
};

const tryToEditSaleFields = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales`, data);
};

const tryToEditSaleForm = (id: string, data: ISales): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}`, data);
};

const tryToCreateSaleOrder = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_API_URL}/sales/order`, data);
};

const tryToEditSaleOrderStatuses = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}`, data);
};

const tryToRefreshStock = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/refresh-stock`);
};

const tryToFetchSaleReview = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/order/review/${id}`);
};

const tryToEditSaleReview = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/review/${id}`, data);
};

const tryToReturnToStockedPickedItem = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/item/return-stock`, data);
};

const tryToAddSalesOrderNote = (id: string, data: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/notes`, data);
};

const tryToFetchSalesOrderNote = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/sales/order/${id}/notes`);
};

const tryToRequestSaleItems = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/request/${id}`, data);
};

const tryToCheckSaleOrderAffected = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/affected/${id}`, data);
};

const tryToAcceptRejectSaleItem = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/item/action`, data);
};

const tryToGenerateProForma = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/${id}/generate-pro-forma`, data);
};

const tryToGenerateInvoice = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/${id}/generate-invoice`, data);
};

const tryToShipSaleOrder = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/sales/order/${id}/shipping`, data);
};

export const SalesService = {
    tryToFetchSales,
    tryToFetchSingleSaleForm,
    tryToFetchSaleOrderSplitDetails,
    tryToFetchSingleSale,
    tryToEditSaleFields,
    tryToEditSaleForm,
    tryToCreateSaleOrder,
    tryToEditSaleStatus,
    tryToSplitOrderSale,
    tryToFetchSaleReview,
    tryToEditSaleReview,
    tryToConfirmReviewSale,
    tryToRefreshStock,
    tryToAddSalesOrderNote,
    tryToFetchSalesOrderNote,
    tryToRequestSaleItems,
    tryToEditSaleOrderStatuses,
    tryToReturnToStockedPickedItem,
    tryToCheckSaleOrderAffected,
    tryToAcceptRejectSaleItem,
    tryToFetchSaleOrderContent,
    tryToGenerateInvoice,
    tryToGenerateProForma,
    tryToShipSaleOrder
};
