import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IWarehouseSections } from '../../../../interfaces/warehouse-locations/IWarehouseSections';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['ID', 'Section Name', 'Description', 'Barcode', 'Categories', 'Locations', 'Action']

interface IWarehouseSectionsTable {
    rows: any;
    paginationData?: IPage;
    rowsLoading?: boolean;
    accessControl?: any;
    actions?: any;
    onEditSection: (id: string) => void;
    onSeeDetails: (id: string) => void;
    onDeleteSection: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickGenerateLocations: (sectionId: string) => void;
}

const WarehouseSectionsTable = ({
    rows,
    paginationData,
    rowsLoading,
    accessControl,
    actions,
    onEditSection,
    onSeeDetails,
    onDeleteSection,
    setPaginationState,
    onClickGenerateLocations,
}: IWarehouseSectionsTable) => {
    return (
        <>
            <Table
                columns={tableColumns}
                paginationData={paginationData}
                setPaginationState={setPaginationState}
                containerStyle={'mt-0'}>
                {rowsLoading ?
                    <>
                        {[...Array(7)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    {tableColumns?.map(element => {
                                        return (
                                            <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        )
                                    })
                                    }
                                </tr>
                            )
                        })}
                    </>
                    : <>
                        {rows?.length > 0 && rows?.map((row: IWarehouseSections, idx: number) => {
                            return (
                                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(row?._id || '')}>
                                            <p className='cut-text'>{row?._id}</p>
                                            <div>
                                                <img title={'Copy Section ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[25px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className='px-6 py-4'>{row?.name || ''}</td>
                                    <td className='px-6 py-4'>{row?.description || ''}</td>
                                    <td className='px-6 py-4'>{row?.barcode || ''}</td>
                                    <td className='px-4 py-4'>
                                        {row?.categories && row?.categories?.map((item: any, index) => `${index ? ', ' : ''}${item?.name}`)}
                                    </td>
                                    <td className='px-3 py-4 min-w-[160px]'>
                                        <Button
                                            label='Generate Locations'
                                            className={row?.generatedLocations || !(!accessControl || actions?.['Generate Locations']) ? 'btn-primary-disable' : 'btn-primary'}
                                            onClickButton={() => row?._id && (!accessControl || actions?.['Generate Locations']) && !row?.generatedLocations && onClickGenerateLocations(row?._id)}
                                        />
                                    </td>
                                    <td className='px-6 py-4 min-w-[150px]'>
                                        <div className='flex flex-row'>
                                            <div onClick={() => row?._id && (!accessControl || actions?.['See Section Details']) && onSeeDetails(row._id)}>
                                                <img title={'See Details'} alt='see details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Section Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                            </div>
                                            <div onClick={() => row?._id && (!accessControl || actions?.['Edit Section']) && onEditSection(row._id)}>
                                                <img title={'Edit Section'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Edit Section']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                            </div>
                                            <div onClick={() => row?._id && (!accessControl || actions?.['Delete Section']) && onDeleteSection(row?._id)}>
                                                <img title={'Delete Section'} alt='delete section' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Section']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </>
                }
            </Table>
        </>
    )
}

export default WarehouseSectionsTable;