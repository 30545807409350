import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Table from '../../../../shared/table';

const tableColumns = ['Nr.', 'Expected items to be reserved', 'Price per item', 'Quantity', 'Sales Value', 'Matching Stock', 'Stock on Other Orders']

interface IDraftTable {
    rows: any[];
    categories?: any;
    salesContent?: any;
}

const DraftTable = ({
    rows,
    categories,
    salesContent,
}: IDraftTable) => {

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const formatObj1: any = obj1
        const formatObj2: any = obj2
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                const totalPrice = Number(row?.price) * Number(row?.qty || 0)
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                const findStock = (salesContent?.salesItems && salesContent?.salesItems?.length > 0) && salesContent?.salesItems?.find((item: any) => areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && (areObjectsEqual(item?.inventory, row?.inventory) || (!item?.inventory && !row?.inventory)) && item?.price === row?.price)
                const findCategory = categories && categories?.length > 0 && categories?.find((item: any) => item?.id === row?.categoryId)
                return (
                    <tr key={idx} className='bg-[#fef6e9] border-b hover:bg-[#ede6da]'>
                        <td className='px-6 py-4'>{idx + 1}</td>
                        <td className='px-6 py-4 max-w-[400px]'>
                            <div className='flex flex-wrap'>
                                {row?.descriptor &&
                                    <>
                                        <img src={findCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                        {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                            return (
                                                <div key={index} className='flex items-center whitespace-nowrap'>
                                                    {(index !== 0) &&
                                                        <div className='flex flex-row items-center ml-1'>
                                                            <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                        </div>
                                                    }
                                                    <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        </td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.price || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{row?.qty || 0}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(totalPrice || 0).toFixed(2)}</td>
                        <td className='px-6 py-4 text-black'>--</td>
                        <td className='px-6 py-4 text-black'>{findStock?.stockOnOtherOrders || '--'}</td>
                    </tr >
                )
            })}
        </Table >
    )
}

export default DraftTable;