import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthorizationService } from './authorizationApi';

const initialState: any = {
    requestStatus: 'default',
    message: '',
};

export const tryToAuthorizeUser = createAsyncThunk<any, { dashboard: string }>(
    'authorization/tryToAuthorizeUser',
    async (data) => {
        const result = await AuthorizationService.tryToAuthorizeUser(data);
        return result?.data;
    },
);

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    },
});

export default authorizationSlice.reducer;