import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISales } from '../../../../interfaces/sales/ISales';
import { tryToEditSaleOrderStatuses } from '../../../../store/inventory/shared/sales/salesSlice';
import ReservedLocationsModal from '../modals/ReservedLocationsModal';
import OpenOrdersTable from '../tables/OpenOrdersTable';
import ScanItemsModal from '../modals/ScanItemsModal';
import RequestModal from '../modals/RequestModal';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IOpenOrderStatus {
    saleDetails?: ISales;
    saleId: string;
    accessControl?: any;
    pageAccess?: any;
    templateFields?: any;
    saleCategories?: Array<any>;
    salesContent?: any;
    refreshStockLoading?: boolean;
    onClickRefreshStock: () => void;
    onGettingPurchaseData: () => void;
}

const OpenOrderStatus = ({
    saleDetails,
    saleId,
    accessControl,
    pageAccess,
    salesContent,
    templateFields,
    refreshStockLoading,
    saleCategories,
    onClickRefreshStock,
    onGettingPurchaseData
}: IOpenOrderStatus) => {
    const dispatch = useAppDispatch();
    const [locationsModal, setLocationsModal] = useState<boolean>();
    const [requestedItems, setRequestedItems] = useState<any>();
    const [selectedItems, setSelectedItems] = useState<any>();
    const [showRequestModal, setShowRequestModal] = useState<{ show: boolean, data?: any, requestedItem?: any, missingItems?: any }>();
    const [showScanModal, setShowScanModal] = useState<{ show: boolean, data?: any }>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [generalError, setGeneralError] = useState<string>();

    useEffect(() => {
        if (saleDetails) {
            setRequestedItems(saleDetails?.requestedItems)
            setSelectedItems(saleDetails?.selectedItems)
        }
    }, [saleDetails])

    const onClickShowScanModal = (data?: any) => {
        document.body.style.overflow = 'hidden';
        setShowScanModal({
            show: true,
            data: data
        })
    }

    const onClickShowRequestModal = (data?: any, requestedItems?: any, missingItems?: number) => {
        document.body.style.overflow = 'hidden';
        setShowRequestModal({
            show: true,
            data: data,
            requestedItem: requestedItems,
            missingItems: missingItems
        })
    }

    const onHideModals = () => {
        setShowRequestModal(undefined)
        setShowScanModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const onToggleLocationsModal = () => {
        if (locationsModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setLocationsModal(!locationsModal)
    }

    const onApproveRequestItems = (data: any, oldRequestedItems: any) => {
        const filterRequestedItems: any = [];
        (requestedItems && requestedItems?.length > 0) && requestedItems?.forEach((item: any) => {
            const findOldRequested = (oldRequestedItems && oldRequestedItems?.length > 0) && oldRequestedItems?.find((old: any) => JSON.stringify(old) === JSON.stringify(item))
            if (!findOldRequested) {
                filterRequestedItems.push(item)
            }
        })
        setRequestedItems([
            ...filterRequestedItems || [],
            ...data || []
        ])
    }

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const categoryFields = category && templateFields?.[category]
        const formatObj1: any = obj1
        let formatObj2: any = obj2
        if (categoryFields && category) {
            formatObj2 = {}
            const moreKeys: any = obj1 && (Object?.keys(obj1))?.filter((item: any, index: number) => !(categoryFields?.find((field: any) => field?.name === item)))
            obj2 && Object.keys(obj2)?.map((key: any, index: number) => {
                const findInCategories = categoryFields?.find((field: any) => field?.name === key)
                const findInMoreKeys = moreKeys && moreKeys?.length > 0 && moreKeys?.find((obj: any) => obj === key)
                if (findInCategories || findInMoreKeys) {
                    formatObj2[key] = obj2[key];
                }
                return key
            }, {});
        }
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    const onFindSaleStatus = () => {
        const findNotMatching = (selectedItems && selectedItems?.length > 0) && selectedItems?.find((item: any) => item?.status === 'not_matching' || item?.status === 'extra_item')
        if (requestedItems && requestedItems?.length > 0) {
            return 'review'
        }
        else if (findNotMatching) {
            return 'review'
        } else {
            let findMissing: boolean = false;
            (saleDetails?.saleItems && saleDetails?.saleItems?.length > 0) && saleDetails?.saleItems?.map((saleItem: any) => {
                const filterSelectedItems = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((item: any) => areObjectsEqual(item?.match?.descriptor, saleItem?.descriptor, saleItem?.categoryId) && areObjectsEqual(item?.match?.inventory, saleItem?.inventory) && (item?.match?.price === saleItem?.price))
                if (!filterSelectedItems || (saleItem?.qty > filterSelectedItems?.length)) {
                    findMissing = true
                }
                return saleItem
            })
            if (findMissing) {
                return 'processing'
            }
        }
        return 'processed'
    }

    const onValidateSubmit = async (isConfirm: boolean) => {
        setLoadingSubmit(true)
        if (!selectedItems && requestedItems) {
            setGeneralError('Please scan or request items to continue')
            setLoadingSubmit(false)
            return;
        }
        setGeneralError(undefined)
        const status = isConfirm ? onFindSaleStatus() : 'open_order'
        try {
            let formatData: any = {
                ...saleDetails || {},
                requestedItems: requestedItems,
                selectedItems: selectedItems,
            }
            if (isConfirm) {
                formatData = {
                    ...formatData || {},
                    status: status,
                    completed: true
                }
            }
            await dispatch(tryToEditSaleOrderStatuses({ id: saleId, data: formatData })).unwrap();
            onGettingPurchaseData();
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const onAddScannedItem = (data: any) => {
        setSelectedItems((prevSelectedItems: any) => [
            ...prevSelectedItems || [],
            data || {}
        ])
    }

    const onCheckBeforeRefresh = () => {
        onClickRefreshStock()
    }

    const onDeleteItemScanned = (itemId: string) => {
        const filterSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.filter((item: any) => item?.itemId !== itemId)
        setSelectedItems(filterSelectedItems || [])
    }

    return (
        <div className='my-5'>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Scan Items'}
                    className={`btn-primary !rounded !shadow-none mr-3`}
                    onClickButton={() => !refreshStockLoading && onClickShowScanModal()}
                />
                <Button
                    label={'See Locations'}
                    className={`${(!accessControl || pageAccess?.actions?.['See Locations']) ? 'btn-main' : 'btn-primary-disable'} !rounded mr-3`}
                    onClickButton={() => !refreshStockLoading && (!accessControl || pageAccess?.actions?.['See Locations']) && onToggleLocationsModal()}
                />
                <Button
                    label={'Refresh Stock'}
                    className={`${!refreshStockLoading && (!accessControl || pageAccess?.actions?.['Refresh Stock']) ? 'btn-main' : 'btn-primary-disable'} !rounded`}
                    onClickButton={() => !refreshStockLoading && (!accessControl || pageAccess?.actions?.['Refresh Stock']) && onCheckBeforeRefresh()}
                />
            </div>
            <div className='bg-white !rounded my-3 pb-4'>
                <p className='font-semibold text-[18px] p-3'>Items</p>
                <OpenOrdersTable
                    rows={saleDetails?.saleItems}
                    reservedItems={saleDetails?.reservedItems}
                    requestedItems={requestedItems}
                    selectedItems={selectedItems}
                    categories={saleCategories}
                    templateFields={templateFields}
                    salesContent={salesContent}
                    onDeleteItem={onDeleteItemScanned}
                    onRequestItem={onClickShowRequestModal}
                    onScanItem={onClickShowScanModal}
                />
                {generalError &&
                    <div className='my-3'>
                        <Error text={generalError} />
                    </div>
                }
                <div className='my-4 flex flex-row items-center border-t pt-4 mx-5'>
                    <Button
                        label={'Save for now'}
                        className={`${!loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Open Order']) ? 'btn-main' : 'btn-primary-disable'} !rounded mr-3`}
                        onClickButton={() => !loadingSubmit && (!accessControl || pageAccess?.actions?.['Save for now Open Order']) && onValidateSubmit(false)}
                    />
                    <Button
                        label={'Confirm'}
                        className={`${!loadingSubmit && (selectedItems?.length > 0 || requestedItems?.length > 0) && (!accessControl || pageAccess?.actions?.['Confirm Open Order']) ? 'btn-primary' : 'btn-primary-disable'} !rounded !shadow-none`}
                        onClickButton={() => !loadingSubmit && (selectedItems?.length > 0 || requestedItems?.length > 0) && (!accessControl || pageAccess?.actions?.['Confirm Open Order']) && onValidateSubmit(true)}
                    />
                </div>
            </div>
            {locationsModal &&
                <ReservedLocationsModal
                    reservedItems={saleDetails?.reservedItems}
                    categories={saleCategories}
                    openModal={locationsModal}
                    handleCloseModal={onToggleLocationsModal}
                />
            }
            {showScanModal?.show &&
                <ScanItemsModal
                    openModal={showScanModal?.show}
                    rowSelected={showScanModal?.data}
                    categories={saleCategories}
                    reservedItems={saleDetails?.reservedItems}
                    handleCloseModal={onHideModals}
                    onAddScannedItem={onAddScannedItem}
                />
            }
            {showRequestModal?.show &&
                <RequestModal
                    openModal={showRequestModal?.show}
                    saleId={saleId || ''}
                    prevRequestedItems={showRequestModal?.requestedItem}
                    review={false}
                    missingItems={showRequestModal?.missingItems}
                    categories={saleCategories}
                    itemDetailsInfo={showRequestModal?.data}
                    handleCloseModal={onHideModals}
                    onApproveAction={onApproveRequestItems}
                />
            }
        </div>
    )
}
export default OpenOrderStatus;