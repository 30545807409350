import React, { useRef } from 'react';
import { classNames } from '../../utils/classNames';

interface IDatepicker {
    placeholder: string;
    label?: string;
    inputValue?: string;
    error?: string;
    icon?: string;
    hideInputSpace?: boolean;
    containerStyle?: string;
    inputStyle?: string;
    dataQa?: string;
    inputUniqueName?: string;
    onChangeInput?: (value: string | null | undefined, type: string) => void;
}

const Datepicker = ({
    placeholder,
    error,
    label,
    icon,
    hideInputSpace,
    containerStyle,
    inputStyle,
    inputValue,
    dataQa,
    inputUniqueName,
    onChangeInput
}: IDatepicker) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    return (
        <div className={containerStyle || 'mb-3 relative'}>
            {!!label && <label className='block text-primary-light font-normal text-base mb-1'>{label}</label>}
            {icon && <img src={icon} className={'absolute w-[18px] object-contain left-2 top-[27%]'} />}
            <input
                ref={ref}
                type='text'
                value={inputValue || ''}
                data-qa={dataQa || ''}
                onChange={(e) => onChangeInput?.(e?.target?.value, inputUniqueName || '')}
                onFocus={() => (ref.current.type = 'date')}
                onBlur={(e) => (e.target.type = 'text')}
                placeholder={placeholder}
                className={classNames('border border-borderColor rounded w-full px-3 py-2', error ? 'border-red-500' : '', icon ? 'pl-8' : '', inputStyle || '')}
            />
            {error ? <span className='block text-xs text-red-500'>{error}</span> : !hideInputSpace && <span className='block h-2 mt-2' />}
        </div>
    );
};

export default Datepicker;