import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import {
    tryToGenerateInvoice,
    tryToGenerateProForma
} from '../../../../store/inventory/shared/sales/salesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IGenerateModal {
    openModal: boolean;
    saleId: string;
    type: string;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const GenerateModal = ({
    openModal,
    saleId,
    type,
    handleCloseModal,
    onSuccessfullyChanged,
}: IGenerateModal) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [includePostage, setIncludePostage] = useState<any>();

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        setIncludePostage(undefined)
        handleCloseModal()
    }

    const onApproveAction = async () => {
        setLoading(true)
        try {
            if (type === 'invoice') {
                await dispatch(tryToGenerateInvoice({ id: saleId, data: { includePostage: includePostage?.value === 'Yes' } })).unwrap();
            } else {
                await dispatch(tryToGenerateProForma({ id: saleId, data: { includePostage: includePostage?.value === 'Yes' } })).unwrap();
            }
            onSuccessfullyChanged('success', `${type === 'invoice' ? 'Invoice' : 'Proforma'} successfully generated!`)
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }

    const onChangeSelectedPostage = (value: any, type?: any) => {
        setIncludePostage(value)
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title={`Generate ${type === 'invoice' ? 'Invoice' : 'Proforma'}`}
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Action</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='mt-2 mb-6'>Do you want to generate {type === 'invoice' ? 'Invoice' : 'Proforma'} including postage fee or not?</p>
                    {error &&
                        <div className='flex flex-row justify-center my-5'>
                            <Error text={error} />
                        </div>
                    }
                    <SelectCheckbox
                        name='Include Postage Fee'
                        placeholder=' '
                        selectedOption={includePostage}
                        options={INVENTORY_FILTERS}
                        containerStyle='my-5'
                        onChangeSelectedOption={onChangeSelectedPostage}
                    />
                    <div className='flex flex-row justify-around my-5'>
                        <Button
                            label='Close'
                            dataQa={'close-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px]`}
                            onClickButton={() => !loading && onCloseModal()}
                        />
                        <Button
                            label='Generate'
                            dataQa={'generate-button'}
                            className={`${(loading || !includePostage) ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && includePostage && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default GenerateModal;