import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import { usePDF, Margin } from 'react-to-pdf';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IChildBarcodeModal {
    openModal: boolean;
    barcode: string;
    handleCloseModal: () => void;
}

const ChildBarcodeModal = ({
    openModal,
    barcode,
    handleCloseModal,
}: IChildBarcodeModal) => {
    const locationTest = React.useRef() as React.MutableRefObject<any>;
    const [barcodeDownload, setBarcodeDownload] = useState<boolean>(false)
    const [qrCodeDownload, setQrCodeDownload] = useState<boolean>(false)
    const { toPDF, targetRef } = usePDF({ filename: `${barcode}-barcode.pdf`, page: { margin: Margin.SMALL } });

    const onCloseModal = () => {
        handleCloseModal();
    };

    const onClickDownload = (type: string) => {
        if (type === 'barcode') {
            setBarcodeDownload(true)
        } else {
            setQrCodeDownload(true)
        }
    }

    useEffect(() => {
        if (barcodeDownload || qrCodeDownload) {
            toPDF()
            setBarcodeDownload(false)
            setQrCodeDownload(false)
        }
    }, [barcodeDownload, qrCodeDownload])

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-10'}>
                <p className='mb-4 font-bold text-xl'>Custom Section Child Barcode</p>
                <div className='my-4 flex flex-row justify-around'>
                    <Button
                        label='Download Barcode'
                        className='btn-primary mb-2'
                        onClickButton={() => onClickDownload('barcode')}
                    />
                    <Button
                        label='Download QR Code'
                        className='btn-primary mb-2'
                        onClickButton={() => onClickDownload('qrCode')}
                    />
                </div>
                <div className='flex flex-col my-4'>
                    <p className='mt-4 text-center'>
                        Barcode
                    </p>
                    <div className='max-w-[100%] flex flex-row justify-center' ref={barcodeDownload ? targetRef : locationTest}>
                        <Barcode value={barcode} width={1.2} height={60} fontSize={14} displayValue={true} />
                    </div>
                </div>
                <div className='my-5 flex flex-row justify-center'>
                    <p className='text-primary-light font-[20px]'>OR</p>
                </div>
                <p className='mt-4 text-center'>
                    QR Code
                </p>
                <div className='flex flex-col items-center' ref={qrCodeDownload ? targetRef : locationTest}>
                    <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '200px', width: '200px' }}
                        value={barcode}
                        viewBox={'0 0 256 256'}
                    />
                </div>
                <div className='flex flex-row justify-center mt-4'>
                    <Button
                        label='Close Modal'
                        onClickButton={onCloseModal}
                        className={'btn-primary-rounded-less'}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default ChildBarcodeModal;
