import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { tryToSubmitPurchaseRequestOffer } from '../../../../store/inventory/broker/purchase-requests/purchaseRequestsSlice';
import OffersHistoryModal from './OffersHistoryModal';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IOfferModal {
    openModal: boolean;
    data: any;
    price?: number;
    itemId: string;
    brokerCategories: any;
    handleCloseModal: () => void;
    onSubmitOfferSuccessfully: () => void;
}

const OfferModal = ({
    openModal,
    data,
    price,
    itemId,
    brokerCategories,
    handleCloseModal,
    onSubmitOfferSuccessfully,
}: IOfferModal) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [priceOffer, setPriceOffer] = useState<any>();
    const [offersHistoryModal, setOffersHistoryModal] = useState<boolean>(false);

    const onCloseModal = () => {
        setLoading(false)
        setSubmitError(undefined)
        setPriceOffer(undefined)
        setOffersHistoryModal(false)
        handleCloseModal()
    }

    const onMakeNewOffer = async (type?: string) => {
        setLoading(true)
        if (!priceOffer && !type) {
            setSubmitError('Please write your offer to continue!')
            setLoading(false)
            return;
        }
        try {
            if (type) {
                await dispatch(tryToSubmitPurchaseRequestOffer({ id: itemId, status: type, data: { dashboard: 'inventory', negotiate: data?.negotiateOffer } })).unwrap();
            } else {
                const formatData = {
                    totalOfferPrice: Number(priceOffer || 0),
                    currency: data?.currency || data?.expectedItems?.[0]?.currency,
                    dashboard: 'inventory',
                    negotiate: data?.negotiateOffer,
                    offerType: data?.negotiateOffer ? 'counter' : 'final'
                }
                await dispatch(tryToSubmitPurchaseRequestOffer({ id: itemId, status: 'offer_submitted', data: formatData })).unwrap();
            }
            onSubmitOfferSuccessfully()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoading(false)
    }

    const onChangePriceOffer = (value: any, type?: string) => {
        setPriceOffer(/^\d*\.?\d*$/.test(value) ? value : priceOffer)
    }

    const onSeeOffersHistory = () => {
        if (!offersHistoryModal) {
            setOffersHistoryModal(true)
        } else {
            setOffersHistoryModal(false)
        }
    }

    const latestOffer = (data?.offers && data?.offers?.length > 0 && !data?.totalOfferPrice) && data?.offers.reduce((max: any, obj: any) => { return obj.counter > max.counter ? obj : max });
    return (
        <Modal
            open={openModal}
            showInRight
            title={'Purchase Request Offer'}
            onClose={onCloseModal}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Request Offer</p>
                </div>
                {(data?.batch && data?.offers && data?.offers?.length > 0 && data?.negotiateOffer) &&
                    <div className='flex flex-row justify-end my-4'>
                        <Button
                            label={'Offers History'}
                            className='btn-main !rounded min-w-[150px]'
                            onClickButton={onSeeOffersHistory}
                        />
                    </div>
                }
                <div className='p-2 !rounded bg-[#f8f9fc]'>
                    <p className='mt-2 mb-5 text-[17px] font-medium'>This will be the offer covering the entire order.</p>
                    {submitError &&
                        <div className='my-3'>
                            <Error text={submitError} />
                        </div>
                    }
                    {((data?.offerPrice || price) && data?.status !== 'broker_offer_submitted') &&
                        <div>
                            < p className='mt-6 text-[13px]'>Broker offer</p>
                            <p className='mb-9 mt-3 font-bold ml-4 text-[17px]'>{CURRENCY_SYMBOLS?.[data?.currency || '']}{Number(data?.offerPrice || price || 0)?.toFixed(2)}</p>
                        </div>
                    }
                    {(data?.batch && data?.negotiateOffer && (latestOffer || data?.totalOfferPrice) && data?.status === 'broker_offer_submitted') &&
                        <>
                            <div>
                                <p className='font-bold text-[20px] mb-4'>Broker Offer</p>
                                <div className='flex flex-col'>
                                    {data?.categories && data?.categories?.length > 0 && data?.categories?.map((item: any, idx: number) => {
                                        const findCategory = brokerCategories && brokerCategories?.length > 0 && brokerCategories?.find((category: any) => category?.id === item?.id)
                                        const findItems = data?.expectedItems && data?.expectedItems?.length > 0 && data?.expectedItems?.filter((itemCategory: any) => itemCategory?.categoryId === item?.id)
                                        const totalQuantity = findItems && findItems?.length > 0 && findItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                                        return (
                                            <div key={idx} className='flex flex-row justify-between items-center my-2'>
                                                <p className='font-bold'>{findCategory?.displayName}</p>
                                                <p>{totalQuantity || 0} {(totalQuantity && totalQuantity > 1) ? 'items' : 'item'}</p>
                                            </div>
                                        )
                                    })}
                                    <div className='border-t-2 border-slate-400 flex flex-row justify-between items-center py-2 my-2'>
                                        <p className='font-bold text-[16px]'>Offer</p>
                                        <p>{CURRENCY_SYMBOLS?.[data?.currency || latestOffer?.currency || '']}{Number(data?.totalOfferPrice || latestOffer?.price || 0)?.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center items-center mt-7 mb-3'>
                                <Button
                                    label='Reject Offer'
                                    dataQa={'reject-button'}
                                    className={`${loading ? 'btn-main-disable' : 'btn-main !border-0'} !shadow-none !rounded min-w-[150px] mr-5`}
                                    onClickButton={() => !loading && onMakeNewOffer('offer_rejected')}
                                />
                                <Button
                                    label='Accept Offer'
                                    dataQa={'accept-button'}
                                    className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} !shadow-none !rounded min-w-[150px]`}
                                    onClickButton={() => !loading && onMakeNewOffer('offer_accepted')}
                                />
                            </div>
                            {!data?.totalOfferPrice &&
                                <div className="flex items-center justify-center my-10">
                                    <div className="border-t border-gray-300 flex-grow"></div>
                                    <span className="px-4 text-gray-500">OR</span>
                                    <div className="border-t border-gray-300 flex-grow"></div>
                                </div>
                            }
                        </>
                    }
                    {!data?.totalOfferPrice &&
                        <>
                            <Input
                                label='Offer Price'
                                showValue={true}
                                containerStyle='my-4'
                                inputValue={priceOffer}
                                onChangeInput={onChangePriceOffer}
                            />
                            <div className='flex flex-row justify-around items-center mt-7 mb-3'>
                                <Button
                                    label='Submit Offer'
                                    dataQa={'accept-button'}
                                    className={`${loading && priceOffer ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} !shadow-none min-w-[150px]`}
                                    onClickButton={() => !loading && priceOffer && onMakeNewOffer()}
                                />
                            </div>
                        </>
                    }
                </div>
                {offersHistoryModal &&
                    <OffersHistoryModal
                        openModal={offersHistoryModal}
                        offers={data.offers}
                        handleCloseModal={onSeeOffersHistory}
                    />
                }
            </div>
        </Modal >
    )
}
export default OfferModal;