import React, { useState } from 'react';
import moment from 'moment';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatPurchaseRequestStatus } from '../../../../utils/constants/purchase-requests-format-statuses';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface ISaleRequestsTable {
    rows: any[];
    rowsLoading: boolean;
    brokerCategories?: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string) => void;
    onClickShowNote: (id: string) => void;
    onSeeOffersHistory: (id: string, offers: any) => void;
    onMakingAnOffer: (id: string, row?: any, totalPrice?: number) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickCreatePurchaseOrder: (id: string, batch?: boolean) => void;
    onClickSeeAllCategories: (categories: any) => void;
}

const SaleRequestsTable = ({
    rows,
    brokerCategories,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    onSeeOffersHistory,
    onClickShowNote,
    onMakingAnOffer,
    setPaginationState,
    onSeeDetails,
    onClickCreatePurchaseOrder,
    onClickSeeAllCategories
}: ISaleRequestsTable) => {
    const [tableColumns] = useState<Array<string>>(['Sale ID', 'Customer', 'Categories', 'VAT Scheme', 'Status', 'Broker Willing to Negotiate', 'Total Price', 'Quantity', 'Due Date (dd-mm-yyyy)', 'Warranty', 'Action'])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const totalPrice = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                        const findCurrency = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.[0]?.currency
                        const value = onFormatPurchaseRequestStatus(row?.status)?.name
                        const color = onFormatPurchaseRequestStatus(row?.status)?.color
                        const text = onFormatPurchaseRequestStatus(row?.status)?.text || 'text-black'
                        const item = color && <div className={`${color} border flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium !rounded-[6px]`}>{value}</div>
                        const totalQuantity = (row?.saleItems && row?.saleItems?.length > 0) && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                        const latestOffer = (row?.offers && row?.offers?.length > 0) && row?.offers.reduce((max: any, obj: any) => { return obj.counter > max.counter ? obj : max });
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.quoteId || ''}</td>
                                <td className='px-6 py-4'>{row?.partner?.username || ''}</td>
                                <td className='pl-6 pr-2 py-4'>
                                    <div
                                        onClick={() => row?.categories?.length > 2 && onClickSeeAllCategories(row?.categories)}
                                        className='flex flex-row flex-wrap items-center cursor-pointer'
                                    >
                                        {(row?.categories && row?.categories?.length > 0 && brokerCategories && brokerCategories?.length > 0) && row?.categories?.filter((item: any, index: number) => index < 2)?.map((item: any) => {
                                            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category: any) => category?.id === item?.id)
                                            return (
                                                <img src={findCategory?.enabledIcon} className='object-contain h-[23px] min-h-[23px] mr-1' key={item?.id} />
                                            )
                                        })}
                                        {row?.categories?.length > 2 ? <p className='font-bold'>...</p> : ''}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.salesInfo?.stockVat || '--'}</td>
                                <td className='px-6 py-4'>{item}</td>
                                <td className='px-6 py-4'>{row?.negotiateOffer ? 'Yes' : 'No'}</td>
                                <td className={`px-6 py-4 ${!row?.batch ? 'min-w-[150px]' : 'min-w-[200px]'}`}>
                                    {!row?.batch ?
                                        <>
                                            {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{(totalPrice || 0).toFixed(2)}
                                        </>
                                        : <div>
                                            <p>Initial Offer: {totalPrice || row?.offerPrice ? `${CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}${Number(row?.offerPrice || totalPrice || 0).toFixed(2)}` : '--'}</p>
                                            {!row?.totalOfferPrice && latestOffer && latestOffer?.price && <p>Latest Offer: {CURRENCY_SYMBOLS?.[`${(latestOffer?.currency || findCurrency || '')?.toLowerCase()}`]}{Number(latestOffer?.price || 0)?.toFixed(2)}</p>}
                                            {row?.totalOfferPrice && <p>Final Offer: {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{Number(row?.totalOfferPrice || 0)?.toFixed(2)}</p>}
                                        </div>
                                    }
                                </td>
                                <td className='px-6 py-4'>{totalQuantity || '--'}</td>
                                <td className='px-6 py-4 min-w-[120px]'>{row?.salesInfo?.dueDate ? moment(row?.salesInfo?.dueDate).format('DD-MM-YYYY') : '--'}</td>
                                <td className='px-6 py-4'>{row?.salesInfo?.warranty || '--'}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-start'>
                                        <div onClick={() => row?._id && (!accessControl || actions?.['See Sale Request Details']) && onSeeDetails(row?._id)}>
                                            <img title={'See Sale Request Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain ${(!accessControl || actions?.['See Sale Request Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        <TableActions>
                                            {(row?.status === 'submitted' && !row?.batch || (row?.status === 'offer_accepted' && row?.batch && !row?.used)) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Create Sales Order']) && row?._id) && onClickCreatePurchaseOrder(row._id, row?.batch)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Create Purchase Order'}
                                                        dataQa={'leave-note'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Create Purchase Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(['broker_offer_submitted', 'offer_submitted']?.includes(row?.status) && row?.batch && row?.negotiateOffer && row?.offers && row?.offers?.length > 0) &&
                                                <div
                                                    onClick={() => (!accessControl || actions?.['Offers History']) && onSeeOffersHistory(row?._id || '', row?.offers)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                    role='none'>
                                                    <Button
                                                        label={'See Offers History'}
                                                        dataQa={'offers'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal word-wrap !text-[#000000] ${(!accessControl || actions?.['Offers History']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {((row?.status === 'submitted' || row?.status === 'broker_offer_submitted') && row?.batch) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Make an offer']) && row?._id) && onMakingAnOffer(row._id, row, totalPrice)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Make an offer'}
                                                        dataQa={'make-an-offer'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Make an offer']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {row?.status !== 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Add Note']) && row?._id) && onClickShowNote(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Leave a Note'}
                                                        dataQa={'leave-note'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Add Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                        </TableActions>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SaleRequestsTable;