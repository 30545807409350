import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToFetchCarriers,
    tryToGenerateLabelPacksData
} from '../../../../store/inventory/shared/carriers/carriersSlice';
import { tryToFetchSingleCrmData } from '../../../../store/inventory/broker/crm-data/crmDataSlice';
import { tryToReturnAllPurchase } from '../../../../store/inventory/broker/purchases/purchasesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IGenerateReturnLabelModal {
    openModal: boolean;
    returnedItems: any;
    category?: any;
    currency?: string;
    customerId: string;
    purchaseId: string;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const GenerateReturnLabelModal = ({
    openModal,
    returnedItems,
    currency,
    category,
    purchaseId,
    customerId,
    handleCloseModal,
    onApproveAction,
}: IGenerateReturnLabelModal) => {
    const dispatch = useAppDispatch();
    const [carriers, setCarriers] = useState<any>();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<any>();
    const [carrierSelected, setCarrierSelected] = useState<any>();
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)

    const onGettingCarriersData = async () => {
        try {
            const response = await dispatch(tryToFetchCarriers(null)).unwrap()
            const formatCarriers = response && response?.length > 0 && response.map((item: any) => ({ ...item, label: item?.displayName, value: item?.name }))
            setCarriers(formatCarriers || undefined)
        } catch (err) {
            // error
        }
    }

    useEffect(() => {
        onGettingCarriersData()
    }, [])

    const onChangeSelectedOption = (e: any) => {
        setCarrierSelected(e || undefined)
    }

    const onCloseModal = () => {
        setCarrierSelected(undefined)
        setSaveError(undefined)
        handleCloseModal()
    }

    const onGenerateLabelPack = async () => {
        if (!carrierSelected) {
            setSaveError('Please make sure to select a carrier to continue')
            return
        }
        setSaveError(undefined)
        setLoadingSubmit(true)
        try {
            let totalPrice = 0
            const getCustomerAddress = await dispatch(tryToFetchSingleCrmData(customerId)).unwrap()
            const createItemsFormat = returnedItems && returnedItems?.length > 0 && returnedItems?.map((item: any) => {
                totalPrice += Number(item?.intitialPrice || 0)
                const formatName = item?.descriptor && Object.values(item?.descriptor)?.join(', ');
                return {
                    'name': formatName,
                    'qty': 1,
                    'price': Number(item?.initialPrice || item?.price || 0).toFixed(2),
                    'currency': currency,
                    'hsCode': ''
                }
            })
            const shipmentDataFormat = {
                createShipment: {
                    'shipFrom': {
                        'name': `${getCustomerAddress?.name}`,
                        'contactName': `${getCustomerAddress?.name}`,
                        'phone': getCustomerAddress?.address?.phone,
                        'email': getCustomerAddress?.address?.email,
                        'address1': getCustomerAddress?.address?.addressLineOne,
                        'address2': getCustomerAddress?.address?.addressLineTwo,
                        'city': getCustomerAddress?.address?.city,
                        'country': getCustomerAddress?.address?.country,
                        'county': getCustomerAddress?.address?.county,
                        'postalCode': getCustomerAddress?.address?.zipCode,
                        'shipperReference': purchaseId
                    },
                    'shipmentInformation': {
                        'category': category,
                        'shipmentDate': moment(new Date()).format('YYYY-MM-DD'),
                        'packages': 1,
                        'totalWeight': 1000,
                        'weightUnitOfMeasure': 'grams',
                        'currency': currency,
                        'value': Number(totalPrice || 0).toFixed(2),
                        'description': 'IMS',
                        'items': createItemsFormat || [],
                        'serviceCode': getCustomerAddress?.address?.country === 'US' ? 'ups' : 'TSN'
                    }
                },
                'labelType': 'Inbound',
                'url': true,
                'qrCode': false,
                'barcode': true,
                'userId': customerId,
                'orderId': purchaseId,
                'itemIds': returnedItems?.map((item: any) => item?.itemId) || [],
                'sourceRequesting': 'wing_app',
                'carrierLocation': {
                    warehouseId: activeWarehouseState?._id,
                    country: activeWarehouseState?.address?.country || activeWarehouseState?.address?.state,
                    carrier: carrierSelected?.value || '',
                },
            }
            const response = await dispatch(tryToGenerateLabelPacksData(shipmentDataFormat)).unwrap();
            if (response) {
                const formatBody = {
                    labelUrl: response?.imageUrl,
                    returnShippingLabelUrl: response?.imageUrl,
                    returnTrackingNumber: response?.trackingNumber
                }
                await dispatch(tryToReturnAllPurchase({ id: purchaseId, data: formatBody })).unwrap();
            }
            onApproveAction()
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            showInRight
            title='Return Item'
            onClose={() => onCloseModal()}>
            <div className={'py-2 min-w-[400px] max-w-[450px] mx-5'}>
                <div className='border-b-2 border-slate-200 pb-2 mt-1 mb-3'>
                    <p className='font-bold text-[16px]'>Generate Return Label</p>
                </div>
                {saveError && <Error text={saveError} />}
                <div className='bg-[#f8f9fc] rounded-[6px] p-2'>
                    <p className='mt-2 mb-4'>Select carrier to generate return tracking number and label.</p>
                    <div className='my-2'>
                        <SelectCheckbox
                            selectedOption={carrierSelected}
                            name='Carrier'
                            placeholder=' '
                            containerStyle='mb-1 max-w-[90%]'
                            onChangeSelectedOption={onChangeSelectedOption}
                            options={carriers || []}
                        />
                    </div>
                    <div className='min-w-full flex flex-row justify-center mt-4 mb-6'>
                        <Button
                            label={'Generate'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} my-3 min-w-[130px] !shadow-none`}
                            onClickButton={() => !loadingSubmit && onGenerateLabelPack()}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default GenerateReturnLabelModal;