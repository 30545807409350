import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddCrmData,
    tryToEditCrmData,
    tryToFetchSingleCrmData
} from '../../../../store/inventory/broker/crm-data/crmDataSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IManageCompanyModal {
    openModal: boolean;
    crmItemId?: string | null;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
    attnName: string;
    addressLineOne: string;
    addressLineTwo?: string;
    addressLineThree?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    phone: string;
};

const ManageCompanyModal = ({
    openModal,
    crmItemId,
    handleCloseModal,
    onSaveChanges,
}: IManageCompanyModal) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.crm);
    const companyLoading = useAppSelector((state) => state.crm?.crmIsLoading);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        setValue('name', crmItemId ? state?.crmDetails?.name || '' : '');
        setValue('displayName', crmItemId ? state?.crmDetails?.displayName || '' : '');
        setValue('attnName', crmItemId ? state?.crmDetails?.address?.attnName || '' : '');
        setValue('addressLineOne', crmItemId ? state?.crmDetails?.address?.addressLineOne || '' : '');
        setValue('addressLineTwo', crmItemId ? state?.crmDetails?.address?.addressLineTwo || '' : '');
        setValue('addressLineThree', crmItemId ? state?.crmDetails?.address?.addressLineThree || '' : '');
        setValue('city', crmItemId ? state?.crmDetails?.address?.city || '' : '');
        setValue('state', crmItemId ? state?.crmDetails?.address?.state || '' : '');
        setValue('zipCode', crmItemId ? state?.crmDetails?.address?.zipCode || '' : '');
        setValue('country', crmItemId ? state?.crmDetails?.address?.country || '' : '');
        setValue('phone', crmItemId ? state?.crmDetails?.address?.phone || '' : '');
    }, [state.crmDetails]);

    useEffect(() => {
        if (crmItemId) {
            dispatch(tryToFetchSingleCrmData(crmItemId));
        }
    }, [crmItemId]);

    const onSubmit = async (data: FormValues) => {
        const addressData: any = { ...data || {} }
        delete addressData?.name
        delete addressData?.displayName
        const payload: any = {
            name: data?.name,
            displayName: data?.displayName,
            type: 'company',
            address: addressData
        }
        try {
            if (crmItemId) {
                await dispatch(tryToEditCrmData({ id: crmItemId, data: payload })).unwrap();
                onSaveChanges(
                    'success',
                    'Company successfully changed.',
                );
            } else {
                await dispatch(tryToAddCrmData(payload))
                onSaveChanges(
                    'success',
                    'Company successfully added.',
                );
            }
        } catch (error) {
            onSaveChanges('error', `${error}`);
        }
        reset();
        handleCloseModal();
    };

    const onCloseModal = () => {
        reset();
        handleCloseModal();
    };

    return (
        <Modal open={openModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>{crmItemId ? 'Edit' : 'Add New '} Company</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(crmItemId && !companyLoading) || !crmItemId ? (
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='Name'
                                label={crmItemId ? 'Name' : ''}
                                register={register('name', {
                                    required: {
                                        message: 'Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Name is required',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                placeholder='Display Name'
                                label={crmItemId ? 'Display Name' : ''}
                                register={register('displayName', {
                                    required: {
                                        message: 'Display Name is required',
                                        value: true,
                                    },
                                    validate: (value: string) =>
                                        !!value.trim() || 'Display Name is required',
                                })}
                                error={errors.displayName?.message}
                            />
                            <div>
                                <p>Address</p>
                                <div className='grid grid-cols-2 gap-4'>
                                    <Input
                                        placeholder='Attn Name'
                                        label={crmItemId ? 'Attn Name' : ''}
                                        register={register('attnName', {
                                            required: {
                                                message: 'Attn Name is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Attn Name is required',
                                        })}
                                        error={errors.attnName?.message}
                                    />
                                    <Input
                                        placeholder='Address Line One'
                                        label={crmItemId ? 'Address Line One' : ''}
                                        register={register('addressLineOne', {
                                            required: {
                                                message: 'Address Line One is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Address Line One is required',
                                        })}
                                        error={errors.addressLineOne?.message}
                                    />
                                    <Input
                                        placeholder='Address Line Two'
                                        label={crmItemId ? 'Address Line Two' : ''}
                                        register={register('addressLineTwo', {
                                            required: {
                                                message: 'Address Line Two is required',
                                                value: false,
                                            }
                                        })}
                                        error={errors.addressLineTwo?.message}
                                    />
                                    <Input
                                        placeholder='Address Line Three'
                                        label={crmItemId ? 'Address Line Three' : ''}
                                        register={register('addressLineThree', {
                                            required: {
                                                message: 'Address Line Three is required',
                                                value: false,
                                            }
                                        })}
                                        error={errors.addressLineThree?.message}
                                    />
                                    <Input
                                        placeholder='City'
                                        label={crmItemId ? 'City' : ''}
                                        register={register('city', {
                                            required: {
                                                message: 'City is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'City is required',
                                        })}
                                        error={errors.city?.message}
                                    />
                                    <Input
                                        placeholder='State'
                                        label={crmItemId ? 'State' : ''}
                                        register={register('state', {
                                            required: {
                                                message: 'State is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'State is required',
                                        })}
                                        error={errors.state?.message}
                                    />
                                    <Input
                                        placeholder='Zip Code'
                                        label={crmItemId ? 'Zip Code' : ''}
                                        register={register('zipCode', {
                                            required: {
                                                message: 'Zip Code is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Zip Code is required',
                                        })}
                                        error={errors.zipCode?.message}
                                    />
                                    <Input
                                        placeholder='Country'
                                        label={crmItemId ? 'Country' : ''}
                                        register={register('country', {
                                            required: {
                                                message: 'Country is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Country is required',
                                        })}
                                        error={errors.country?.message}
                                    />
                                    <Input
                                        placeholder='Phone'
                                        label={crmItemId ? 'Phone' : ''}
                                        register={register('phone', {
                                            required: {
                                                message: 'Phone is required',
                                                value: true,
                                            },
                                            validate: (value: string) =>
                                                !!value.trim() || 'Phone is required',
                                        })}
                                        error={errors.phone?.message}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default ManageCompanyModal;