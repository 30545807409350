import React from 'react';
import { IInventory } from '../../../../interfaces/inventory-data/IInventory';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


interface IItemCostsContainer {
    costsItems?: any;
    inventoryDetails?: IInventory;
    currentCostItem?: any;
    onChangeCostInputValue: (value: any, type?: string) => void;
    onRemoveCostItem: (index: number) => void;
    onAddDetailsToCostItems: () => void;
}

const ItemCostsContainer = ({
    costsItems,
    inventoryDetails,
    currentCostItem,
    onChangeCostInputValue,
    onRemoveCostItem,
    onAddDetailsToCostItems
}: IItemCostsContainer) => {
    return (
        <WhiteContainer containerStyle=' !h-auto !rounded p-4'>
            <p className='font-bold text-[18px] mb-4'>COGs Costing</p>
            <div>
                <div className='grid grid-cols-9 gap-3 mb-2'>
                    <div className='col-span-2 font-medium'>Value</div>
                    <div className='col-span-3 font-medium'>Type</div>
                    <div className='col-span-3 font-medium'>Description</div>
                    <div className='col-span-1'></div>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                {(costsItems && costsItems?.length > 0) && costsItems?.map((cost: any, index: number) => {
                    return (
                        <>
                            <div className='grid grid-cols-9 gap-3 my-2'>
                                <div className='col-span-2'>{CURRENCY_SYMBOLS?.[(inventoryDetails?.currency || '').toLowerCase() || '']}{cost?.value}</div>
                                <div className='col-span-3'>{cost?.type}</div>
                                <div className='col-span-3'>{cost?.description}</div>
                                <div className='col-span-1 cursor-pointer'>
                                    <div onClick={() => onRemoveCostItem(index)}>
                                        <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                    </div>
                                </div>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    )
                })}
                <div className='grid grid-cols-9 gap-3'>
                    <div className='col-span-2'>
                        <Input
                            placeholder='Value'
                            showValue={true}
                            inputUniqueName='value'
                            inputValue={currentCostItem?.value}
                            onChangeInput={onChangeCostInputValue}
                        />
                    </div>
                    <div className='col-span-3'>
                        <Input
                            placeholder='Type'
                            showValue={true}
                            inputUniqueName='type'
                            inputValue={currentCostItem?.type}
                            onChangeInput={onChangeCostInputValue}
                        />
                    </div>
                    <div className='col-span-3'>
                        <Input
                            placeholder='Description'
                            showValue={true}
                            inputUniqueName='description'
                            inputValue={currentCostItem?.description}
                            onChangeInput={onChangeCostInputValue}
                        />
                    </div>
                    <div className='col-span-1'>
                        <Button
                            label={'+'}
                            dataQa={'add-button'}
                            className={`btn-primary rounded ml-2 mt-1`}
                            onClickButton={onAddDetailsToCostItems}
                        />
                    </div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ItemCostsContainer;