import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import {
    tryToEditSaleFields,
    tryToFetchSingleSale
} from '../../../store/inventory/shared/sales/salesSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import FieldsModal from './modals/FieldsModal';
import SaleFieldsTable from './tables/SaleFieldsTable';
import FieldsCategories from './details/FieldsCategories';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Loading from '../../../shared/Loading';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const SalesManageFields = () => {
    const dispatch = useAppDispatch();
    const [selectedCategory, setSelectedCategory] = useState<string>()
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>()
    const [fieldsData, setFieldsData] = useState<any>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [fieldSelected, setFieldSelected] = useState<string>()
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false)
    const [showFieldModal, setShowFieldModal] = useState<boolean>(false)
    const [userProfileMutation] = useUserProfileMutation()
    const state = useAppSelector((state) => state.sales);

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        setBrokerCategories(formatSectors || [])
        setSelectedCategory(formatSectors?.[0]?.id || undefined)
    }

    useEffect(() => {
        getBrokerSectors()
    }, [])

    useEffect(() => {
        if (state.saleFields) {
            setFieldsData(state.saleFields ? state.saleFields.fields || [] : undefined)
        } else {
            setFieldsData(undefined)
        }
    }, [state.saleFields]);

    useEffect(() => {
        if (selectedCategory) {
            dispatch(tryToFetchSingleSale(selectedCategory));
        }
    }, [selectedCategory]);

    const onChangeCategorySelected = (value: string) => {
        setSelectedCategory(value)
    }

    const onToggleFieldModal = (id?: string) => {
        if (id) {
            setFieldSelected(id)
        }
        setShowFieldModal(!showFieldModal)
    }

    const onSaveFieldChanges = async (type: string, message: string) => {
        setShowToast({ type, message });
    }

    const onRequestToDeleteField = (id: string) => {
        setShowDeleteFieldModal(true)
        setFieldSelected(id)
    }

    const onCloseDeleteFieldModal = () => {
        setFieldSelected(undefined)
        setShowDeleteFieldModal(false)
    }

    const onDeleteCarrierField = async () => {
        try {
            if (fieldSelected) {
                const filterFields = fieldsData && fieldsData?.length > 0 && fieldsData?.filter((field: any) => field?.id !== fieldSelected)
                const formatData = {
                    assetTemplateId: selectedCategory || '',
                    fields: filterFields || []
                }
                await dispatch(tryToEditSaleFields(formatData)).unwrap();
                setShowToast({ type: 'error', message: 'Field deleted successfully' });
            }
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        onCloseDeleteFieldModal()
    }

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Manage Sales Fields</p>
            </div>
            <div className='my-10'>
                <FieldsCategories
                    items={brokerCategories || []}
                    activeButton={selectedCategory}
                    onClickCategory={onChangeCategorySelected}
                />
            </div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add new field'}
                    className={'btn-primary'}
                    onClickButton={onToggleFieldModal}
                />
            </div>
            {!brokerCategories ?
                <div className='my-3 flex flex-row justify-center items-center'>
                    <Loading />
                </div>
                :
                <>
                    {(fieldsData && fieldsData?.length > 0) ?
                        <SaleFieldsTable
                            rows={fieldsData || []}
                            onEditField={onToggleFieldModal}
                            onDeleteField={onRequestToDeleteField}
                        />
                        : <EmptyContainer text={'No field added yet'} showImage={true} />}
                </>
            }
            <DeleteModal
                openDeleteModal={showDeleteFieldModal}
                handleCloseDeleteModal={onCloseDeleteFieldModal}
                onApproveDeletion={onDeleteCarrierField}
            />
            <FieldsModal
                openFieldsModal={showFieldModal}
                handleCloseFieldsModal={onToggleFieldModal}
                fieldItemId={fieldSelected}
                saleData={fieldsData}
                assetTemplateId={selectedCategory || ''}
                onSaveChanges={onSaveFieldChanges}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    );
};

export default SalesManageFields;
