import { AxiosResponse } from 'axios';
import { ORDERS_API_URL } from '../../../../config';
import ordersAxios from '../../../../utils/axios/orders.axios';


const tryToFetchImeiStatusCheck = (imei: string): Promise<AxiosResponse> => {
    return ordersAxios.put<void>(`${ORDERS_API_URL}/shipments/item/black-list-status/${imei}`)
}

export const OrdersService = {
    tryToFetchImeiStatusCheck,
};
