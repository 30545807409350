import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CarriersService } from './carriersApi';
import { AxiosError } from 'axios';

const initialState: any = {
    loading: false,
    isLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchCarriers = createAsyncThunk<any, any>(
    'carriers/tryToFetchCarriers',
    async (data?: any) => {
        const result = await CarriersService.tryToFetchCarriers(data);
        return result?.data;
    },
);

export const tryToGenerateLabelPacks = createAsyncThunk<any, any>(
    'cs/tryToGenerateLabelPacks',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CarriersService.tryToGenerateLabelPacks(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGenerateLabelPacksData = createAsyncThunk<any, any>(
    'cs/tryToGenerateLabelPacksData',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CarriersService.tryToGenerateLabelPacksData(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToCreateReturnShippingNumber = createAsyncThunk<any, any>(
    'carriers/tryToCreateReturnShippingNumber',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CarriersService.tryToCreateReturnShippingNumber(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const carriersSlice = createSlice({
    name: 'carriers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

    },
});

export default carriersSlice.reducer;