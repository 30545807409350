import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IMEI_STATUESES } from '../../../../utils/constants/imei-statuses';
import { tryToFetchAssetValues } from '../../../../store/inventory/shared/assets/assetsSlice';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import { tryToFetchItemId } from '../../../../store/inventory/shared/item-search/itemIdSearchSlice';
import { tryToFetchImeiStatusCheck } from '../../../../store/inventory/broker/orders/ordersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IScanItemsModal {
    openModal: boolean;
    categories?: any;
    rowSelected?: any;
    reservedItems?: any;
    handleCloseModal: () => void;
    onAddScannedItem: (data: any, rowSelected?: number) => void;
}

const ScanItemsModal = ({
    openModal,
    categories,
    rowSelected,
    reservedItems,
    handleCloseModal,
    onAddScannedItem,
}: IScanItemsModal) => {
    const dispatch = useAppDispatch();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [itemErrors, setItemErrors] = useState<any>();
    const [imeiError, setImeiError] = useState<string>();
    const [itemIdNotFound, setItemIdNotFound] = useState<boolean>(false);
    const [spaceLocation, setSpaceLocation] = useState<any>();
    const [imeichecked, setImeiChecked] = useState<boolean>(false);
    const [templateFields, setTemplateFields] = useState<any>();
    const [inventoryFields, setInventoryFields] = useState<any>();
    const [assetData, setAssetData] = useState<any>();
    const [otherData, setOtherData] = useState<any>();
    const [itemLocation, setItemLocation] = useState<any>();
    const [itemLocationId, setItemLocationId] = useState<any>();
    const [inventoryData, setInventoryData] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [itemDetailsInfo, setItemDetailsInfo] = useState<any>();
    const [newLocation, setNewLocation] = useState<any>();
    const [extraDetails, setExtraDetails] = useState<any>();
    const [notMatchingError, setNotMatchingError] = useState<string>();
    const [loadingImeiStatus, setLoadingImeiStatus] = useState<boolean>(false);

    const capitalizeFirstLetter = (str: string) => {
        return str?.split(/[\s_]+/).map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    }

    const onGettingTemplateFields = async () => {
        if (selectedCategory?.id) {
            try {
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(selectedCategory?.id)).unwrap()
                if (response?.fields) {
                    setInventoryFields(response?.inventoryFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    const onGettingAllAssetFields = async () => {
        if (selectedCategory) {
            const data: any = {
                values: [],
                inventory: true
            }
            try {
                const response: any = await dispatch(tryToFetchAssetValues({ id: selectedCategory?.id || '', data })).unwrap()
                setTemplateFields(response || [])
            } catch (err) {
                // error here
            }
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            onGettingAllAssetFields()
            onGettingTemplateFields()
        }
    }, [assetData, selectedCategory])

    useEffect(() => {
        onGettingAllAssetFields()
    }, [assetData, rowSelected])

    const onCheckImeiDetails = async () => {
        try {
            const res = await dispatch(tryToFetchImeiStatusCheck(otherData?.itemId || '')).unwrap()
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                imeiStatus: (res?.data?.blackliststatus || '').toLowerCase() === 'yes' ? 'Barred' : 'OK'
            }))
        } catch (err) {
            // error here
        }
    }

    const onCheckItemDetails = async () => {
        setLoadingImeiStatus(true)
        if (otherData?.itemId) {
            try {
                const response = await dispatch(tryToFetchItemId(otherData?.itemId || '')).unwrap()
                const findCategory = (categories && categories?.length > 0) && categories?.find((category: any) => category?.name === response?.data?.category)
                setSelectedCategory(findCategory)
                if ((findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets')) {
                    onCheckImeiDetails();
                }
                if (!response?.data) {
                    setItemIdNotFound(true)
                }
                setExtraDetails({
                    purchaseDate: response?.data?.itemAcceptedDate,
                    purchasePrice: response?.data?.price,
                    cogsCosting: response?.data?.cogsCosting,
                    additionalCost: response?.data?.additionalCost
                })
                setInventoryData(response?.data?.inventory)
                setAssetData(response?.data?.revaluation || response?.data?.exchangeDefinition?.asset?.descriptor)
                setItemLocation(response?.data?.locations)
                setItemLocationId(response?.data?.locationId)
                setSpaceLocation(response?.data?.locationsInfo?.spaces?.name)
                setImeiError(undefined)
                setImeiChecked(true)
                setItemIdNotFound(false)
            } catch (err: any) {
                setItemIdNotFound(true)
            }
        }
        setLoadingImeiStatus(false)
    }

    const onClickEnterImei = async (e: any, type?: string) => {
        if (e?.key === 'Enter' && e?.target?.value) {
            setLoadingImeiStatus(true)
            onCheckItemDetails()
        }
    }

    const onCloseModal = () => {
        handleCloseModal();
        setItemErrors(undefined)
        setSelectedCategory(undefined)
        setTemplateFields(undefined)
        setInventoryFields(undefined)
        setAssetData(undefined)
        setNewLocation(undefined)
        setSpaceLocation(undefined)
        setImeiChecked(false)
        setOtherData(undefined)
        setInventoryData(undefined)
        setExtraDetails(undefined)
        setLoadingImeiStatus(false)
        setGeneralError(undefined)
        setItemLocation(undefined)
        setItemLocationId(undefined)
        setNotMatchingError(undefined)
        setItemIdNotFound(false)
    }

    useEffect(() => {
        if (rowSelected && categories) {
            const findCategory = categories?.length > 0 && categories?.find((item: any) => item?.id === rowSelected?.categoryId)
            setSelectedCategory(findCategory)
        }
    }, [rowSelected, categories])

    useEffect(() => {
        if (rowSelected && selectedCategory) {
            setItemDetailsInfo(rowSelected)
        }
    }, [rowSelected, selectedCategory])

    const onClickClearFields = () => {
        setItemErrors(undefined)
        setAssetData(undefined)
        setOtherData(undefined)
        setInventoryData(undefined)
        setLoadingImeiStatus(false)
        setNotMatchingError(undefined)
        setImeiChecked(false)
        setImeiError(undefined)
        setExtraDetails(undefined)
        setItemIdNotFound(false)
        setGeneralError(undefined)
    }

    const onChangeOtherSelectedValues = (e: any, type?: string) => {
        if (type) {
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [type]: e?.value
            }))
        }
    }

    const onChangeOtherInputValues = (e: any, type?: string) => {
        if (type) {
            if (type === 'itemId') {
                setItemLocation(undefined)
                setItemLocationId(undefined)
                setInventoryData(undefined)
                setAssetData(undefined)
                setSpaceLocation(undefined)
                setImeiChecked(false)
            }
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [type]: e
            }))
        }
    }

    const onChangeNewLocation = (e: any, type?: string) => {
        setNewLocation(e)
    }

    const onAddItemToScanned = (formatItem: any) => {
        onAddScannedItem(formatItem)
        onCloseModal()
    }

    const onValidateItem = (passNotMatching?: boolean) => {
        if (itemIdNotFound) {
            setGeneralError(`Item with this${(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? ' IMEI, not found' : ' Item Id, not found'}!`);
            return;
        }
        if (!imeichecked || !otherData?.itemId) {
            setGeneralError(`Please scan${(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? ' IMEI, is required' : ' Item Id, is required'}!`);
            return;
        }
        setGeneralError(undefined)
        let inputErrors: any
        if ((selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') && !otherData?.imeiStatus) {
            inputErrors = {
                ...inputErrors || {},
                imeiStatus: 'IMEI status is required'
            }
        }
        if (!newLocation) {
            inputErrors = {
                ...inputErrors || {},
                newLocation: 'New Location is required'
            }
        }
        if (inputErrors) {
            setItemErrors(inputErrors)
        } else {
            setItemErrors(undefined)
            const findIfItemInReserved = (reservedItems && reservedItems?.length > 0) && reservedItems?.find((item: any) => item?.itemId === otherData?.itemId)
            if (!passNotMatching && !findIfItemInReserved && rowSelected) {
                setNotMatchingError(`The ${(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI' : ' Item Id'} you have scanned is not reserved for this order. Do you want to add the item anyway?`)
                return;
            }
            setNotMatchingError(undefined)
            const formatItem = {
                ...otherData || {},
                ...extraDetails || {},
                locations: itemLocation,
                locationId: itemLocationId,
                descriptor: assetData,
                inventory: inventoryData,
                categoryId: selectedCategory?.id,
                price: rowSelected?.price || 0,
                newLocation: newLocation,
                currency: rowSelected?.currency,
                status: rowSelected ? findIfItemInReserved ? 'matching' : 'not_matching' : 'extra_item',
                match: rowSelected ?
                    {
                        descriptor: rowSelected?.descriptor,
                        inventory: rowSelected?.inventory,
                        price: rowSelected?.price
                    }
                    : undefined,
                notMatching: !findIfItemInReserved
            }
            onAddItemToScanned(formatItem)
        }
    }

    let locationFormat: any = ''
    itemLocation && (Object?.keys(itemLocation))?.forEach((obj: any, idx: number) => {
        if (idx === 0) locationFormat += itemLocation?.[obj]
        else locationFormat += ` - ${itemLocation?.[obj]}`
    })
    const keysDescriptor = itemDetailsInfo && itemDetailsInfo?.descriptor && Object?.keys(itemDetailsInfo?.descriptor)
    const keysInventory = itemDetailsInfo && itemDetailsInfo?.inventory && Object?.keys(itemDetailsInfo?.inventory)
    const keys = [...keysDescriptor || [], ...keysInventory || []]
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            showInRight
            title='Add Delivered Item'
            contentContainerStyle={'!min-w-[750px] min-h-[150px]'}>
            <div className={'p-2 min-w-[700px]'}>
                <p className='mb-4 font-semibold text-md'>You&apos;re selling:</p>
                {itemDetailsInfo &&
                    <div className='mb-5 flex flex-row items-center'>
                        {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                            return (
                                <div key={index} className='flex items-center whitespace-nowrap'>
                                    <div className='!mr-1'>{itemDetailsInfo?.descriptor?.[key] || itemDetailsInfo?.inventory?.[key]}</div>
                                </div>
                            )
                        })}
                    </div>
                }
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start'>
                        {imeiError && <div className='col-span-2 mb-2 mt-3'><Error text={imeiError} /></div>}
                        <Input
                            containerStyle={'w-full !mt-2'}
                            label={(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI' : 'Item Id'}
                            placeholder={' '}
                            inputUniqueName={'itemId'}
                            onChangeInput={onChangeOtherInputValues}
                            inputValue={otherData?.itemId}
                            showValue={true}
                            disabled={loadingImeiStatus}
                            onHandleKeyDown={onClickEnterImei}
                            error={itemErrors?.itemId}
                        />
                        {(selectedCategory && (selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets'))
                            && <td className='px-2 py-2 min-w-[120px]'>
                                <SelectCheckbox
                                    placeholder={' '}
                                    name='IMEI Status'
                                    options={IMEI_STATUESES}
                                    containerStyle={'w-full'}
                                    selectedOption={otherData?.imeiStatus ? IMEI_STATUESES?.find((item: any) => item?.value === otherData?.imeiStatus) : undefined}
                                    selectStyle={{ backgroundColor: 'white', borderRadius: '4px', border: otherData?.imeiStatus ? otherData?.imeiStatus === 'OK' ? '1px solid green' : '1px solid red' : '1px solid #e5e7eb', padding: 0 }}
                                    onChangeSelectedOption={onChangeOtherSelectedValues}
                                    uniqueName={'imeiStatus'}
                                    error={itemErrors?.imeiStatus}
                                />
                            </td>
                        }
                    </div>
                    <div className='flex flex-row justify-center my-4 border-b-2 border-[#e4e4e4] pb-4'>
                        <Button
                            label={`Scan ${(selectedCategory?.label?.toLowerCase() === 'phones' || selectedCategory?.label?.toLowerCase() === 'tablets') ? ' IMEI' : 'Item ID'}`}
                            className={`${otherData?.itemId ? 'btn-main' : 'btn-primary-disable'}`}
                            onClickButton={onCheckItemDetails}
                        />
                    </div>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start border-b-2 border-[#e4e4e4]'>
                        {templateFields && (Object.keys(templateFields || []))?.map((field: string, index: number) => {
                            return (
                                <>
                                    <SelectCheckbox
                                        name={field && capitalizeFirstLetter(field)}
                                        placeholder={' '}
                                        selectedOption={assetData?.[field] ? { value: assetData?.[field], label: assetData?.[field] } : undefined}
                                        options={[]}
                                        disabled={true}
                                        selectStyle={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '2px' }}
                                        onChangeSelectedOption={() => console.error('')}
                                    />
                                </>
                            )
                        })
                        }
                        {(templateFields && Object?.keys(templateFields)?.length > 0) && (inventoryFields && inventoryFields?.length > 0) && inventoryFields?.map((item: any, index: number) => {
                            return (
                                <>
                                    {item?.dataType === 'boolean' || item?.dataType === 'list'
                                        ? <SelectCheckbox
                                            key={index}
                                            name={item?.name && capitalizeFirstLetter(item?.name)}
                                            placeholder={' '}
                                            selectedOption={inventoryData?.[item?.name] ? { label: inventoryData?.[item?.name], value: inventoryData?.[item?.name] } : undefined}
                                            options={[]}
                                            disabled={true}
                                            containerStyle='w-full'
                                            selectStyle={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '2px' }}
                                            onChangeSelectedOption={() => console.error('')}
                                            uniqueName={item?.name || ''}
                                        />
                                        : <Input
                                            containerStyle={'w-full'}
                                            placeholder={''}
                                            label={item?.name}
                                            disabled={true}
                                            inputUniqueName={item?.name}
                                            onChangeInput={() => console.error('')}
                                            inputValue={inventoryData?.[item?.name]}
                                            showValue={true}
                                        />
                                    }
                                </>
                            )
                        })}
                    </div>
                    <div className='my-2 grid grid-cols-2 gap-4 mb-[15px] content-start'>
                        <Input
                            label={'Location'}
                            disabled={true}
                            containerStyle=''
                            inputUniqueName={'location'}
                            inputValue={locationFormat ? `${spaceLocation ? spaceLocation + ' - ' : ''}${locationFormat}` : ''}
                            showValue={true}
                        />
                        <Input
                            label={'New Location'}
                            containerStyle=''
                            inputUniqueName={'newLocation'}
                            inputValue={newLocation}
                            showValue={true}
                            onChangeInput={onChangeNewLocation}
                            error={itemErrors?.newLocation}
                        />
                    </div>
                    {notMatchingError && <Error text={notMatchingError} />}
                    <div className='min-w-full flex flex-row justify-center my-10'>
                        <Button
                            className={'!py-0 btn-main !bg-white !shadow-none mr-4 !rounded !border-0 !hover:border-0 min-w-[150px]'}
                            label={'Clear fields'}
                            onClickButton={onClickClearFields}
                        />
                        <Button
                            label={notMatchingError ? 'Yes, Add Item' : 'Add'}
                            className={'btn-primary-rounded-less flex flex-row items-center justify-center min-w-[150px] !shadow-none'}
                            onClickButton={() => notMatchingError ? onValidateItem(true) : onValidateItem()}
                        />
                    </div>
                </div>
                <div className='flex flex-row justify-center my-3'>
                    <Button
                        label={'Go To Back'}
                        className='btn-primary-rounded-less min-w-[170px]'
                        onClickButton={() => onValidateItem()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ScanItemsModal;