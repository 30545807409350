import React from 'react';
import FormPurchaseItemsTable from '../tables/FormPurchaseItemTable';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IItemsStep {
    selectedCategory?: any;
    templateFields?: any;
    inventoryFields?: any;
    filteredFields?: any;
    allRowsItems?: any;
    loadingOptions?: any;
    loadingValues?: any;
    loadingFields?: any;
    allRowsData?: any;
    filterErrors?: any;
    itemsLoading?: { row: number, loading: boolean };
    itemsGeneralError?: string;
    selectedMarket?: any;
    searchInDB?: any;
    batch?: boolean;
    requestId?: string;
    purchaseRequests?: any;
    selectedPurchaseRequests?: any;
    categoryFieldsWithoutExtended?: any;
    onClickImportItems: (category: any) => void;
    onChangeSelectedInventoryValue: (value: string, type?: string, index?: any) => void;
    onChangeInventoryInput: (value: string | null | undefined, type?: string, index?: any) => void;
    onMenuCloseAfterSearch: (type?: string, index?: any) => void;
    onClickSearchInDb: (type?: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string, index?: any) => void;
    onChangeSelectedValue: (e: any, type?: string, index?: any) => void;
    onChangeInputValue: (e: any, type?: string, index?: any) => void;
    onClickAddRow: (category: any) => void;
    onDeleteItemRow: (id: any, categoryId: string) => void;
    onCloneRow: (id: any, categoryId: string) => void;
    onChangeSelectedPurchase: (value: any, type?: string) => void;
    onAddPurchaseRequestsItems: () => void;
    onClickDownloadItems: (category: any) => void;
}

const ItemsStep = ({
    selectedCategory,
    templateFields,
    inventoryFields,
    filteredFields,
    filterErrors,
    allRowsItems,
    allRowsData,
    itemsLoading,
    itemsGeneralError,
    selectedMarket,
    loadingOptions,
    loadingValues,
    loadingFields,
    requestId,
    searchInDB,
    categoryFieldsWithoutExtended,
    batch,
    purchaseRequests,
    selectedPurchaseRequests,
    onClickImportItems,
    onChangeSelectedInventoryValue,
    onChangeSearchAssetFields,
    onChangeInventoryInput,
    onChangeSelectedValue,
    onChangeInputValue,
    onClickAddRow,
    onClickSearchInDb,
    onDeleteItemRow,
    onCloneRow,
    onMenuCloseAfterSearch,
    onChangeSelectedPurchase,
    onAddPurchaseRequestsItems,
    onClickDownloadItems,
}: IItemsStep) => {
    return (
        <div className='min-w-[100%] bg-white p-3'>
            <p className='font-bold mb-2'>Items</p>
            <p className='mb-5'>
                {!(selectedCategory && selectedCategory?.length > 0)
                    ? 'To continue adding items, please select categories first.'
                    : 'Register all items and their informations. On the table below you can write item details and add multiple items if needed.'
                }
            </p>
            {itemsGeneralError && <Error text={itemsGeneralError} />}
            {batch && (selectedCategory && selectedCategory?.length > 0) &&
                <div className='my-4 border-y'>
                    <p className='my-2 text-primary-light'>You can choose one or more batch requests to get items</p>
                    <div className='flex flex-rows items-center'>
                        <SelectCheckbox
                            name='Batch Requests'
                            options={requestId ? purchaseRequests?.filter((item: any) => item?._id !== requestId) || [] : purchaseRequests || []}
                            multiple={true}
                            containerStyle='w-[300px]'
                            selectedOption={selectedPurchaseRequests}
                            onChangeSelectedOption={onChangeSelectedPurchase}
                        />
                        <Button
                            label='Get Items'
                            className='btn-main !rounded !min-w-[150px] ml-5 mt-3'
                            onClickButton={() => onAddPurchaseRequestsItems()}
                        />
                    </div>
                </div>
            }
            <div>
                {(selectedCategory && selectedCategory?.length > 0) && selectedCategory?.map((item: any, index: number) => {
                    const indexedItems = allRowsItems && allRowsItems?.length > 0 && allRowsItems?.map((row: any, idx: number) => ({ ...row, index: idx }))
                    const indexedData = allRowsData && allRowsData?.length > 0 && allRowsData?.map((row: any, idx: number) => ({ ...row, index: idx }))
                    const rowItems = indexedItems && indexedItems?.length > 0 && indexedItems?.filter((row: any) => row?.categoryId === item?.id)
                    const rowsData = indexedData && indexedData?.length > 0 && indexedData?.filter((row: any) => row?.categoryId === item?.id)
                    const categoryInventoryFields = inventoryFields?.[item?.name]
                    const categoryTemplateFields = templateFields?.[item?.name]
                    // const fieldsNameInventory = categoryInventoryFields && categoryInventoryFields?.length > 0 && categoryInventoryFields?.map((category: any) => category?.name)
                    const fieldsNames = templateFields?.[item?.name] && Object.keys(templateFields?.[item?.name])
                    // const allFields = fieldsNames ? [...fieldsNames || [], ...fieldsNameInventory || [], 'price', 'quantity'] : []
                    const categoryErrors = filterErrors && filterErrors?.length > 0 && filterErrors?.filter((error: any) => error?.categoryId === item?.id)
                    return (
                        <div key={index} className='border-b-2 border-[#e4e4e4]'>
                            <div className='flex flex-row items-center justify-between mt-3'>
                                <div className='flex flex-row items-center'>
                                    <img src={item?.enabledIcon} className='object-contain h-[26px] min-h-[26px]' />
                                    <p className='text-[16px] ml-1'>{item?.label}</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <Button
                                        label='Download Sample'
                                        className='btn-main'
                                        onClickButton={() => onClickDownloadItems(item)}
                                    />
                                    <Button
                                        label='Import Items'
                                        className='btn-main ml-3'
                                        onClickButton={() => onClickImportItems(item)}
                                    />
                                </div>
                            </div>
                            <FormPurchaseItemsTable
                                category={item}
                                columns={fieldsNames}
                                categoryFieldsWithoutExtended={categoryFieldsWithoutExtended}
                                templateData={categoryTemplateFields}
                                inventoryFields={categoryInventoryFields}
                                selectedMarket={selectedMarket}
                                itemsLoading={itemsLoading}
                                filterErrors={categoryErrors}
                                filterValues={filteredFields}
                                allRowsItems={rowItems}
                                allRowsData={rowsData}
                                loadingFields={loadingFields}
                                loadingValues={loadingValues}
                                loadingOptions={loadingOptions}
                                searchInDB={searchInDB}
                                onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                                onChangeSearchAssetFields={onChangeSearchAssetFields}
                                onChangeInventoryInput={onChangeInventoryInput}
                                onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                                onChangeSelectedValue={onChangeSelectedValue}
                                onChangeInputValue={onChangeInputValue}
                                onClickSearchInDb={onClickSearchInDb}
                                onDeleteItemRow={onDeleteItemRow}
                                onClickAddRow={onClickAddRow}
                                onCloneRow={onCloneRow}
                                rowsLength={1}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ItemsStep;
