import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssetsService } from './assetsApi';

const initialState: any = {
    assetIsLoading: false,
    assetsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAssetValues = createAsyncThunk<any, any>(
    'assets/tryToFetchAssetValues',
    async ({ id, data }) => {
        const result = await AssetsService.tryToFetchAssetValues(id, data);
        return result?.data?.data;
    },
);

export const tryFetchAssetFieldValues = createAsyncThunk<any, any>(
    'assets/tryFetchAssetFieldValues',
    async ({ id, field, search, replacements }) => {
        const result = await AssetsService.tryFetchAssetFieldValues(id, field, search, replacements);
        return result?.data?.data;
    },
);

export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
    },
});

export default assetsSlice.reducer;
