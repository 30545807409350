import React, { useEffect, useState } from 'react';
import { ICrm } from '../../../../interfaces/crm-data/ICrm';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

// let tableColumns = ['Name', 'Label', 'Actions']

interface ICrmDataTable {
    rows: Array<any>;
    paginationData?: IPage;
    type?: string;
    accessDelete?: any;
    accessEdit?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onDeleteData: (id: string) => void;
    onEditData: (id: string) => void;
}

const CrmDataTable = ({
    rows,
    paginationData,
    type,
    accessEdit,
    accessDelete,
    setPaginationState,
    onDeleteData,
    onEditData
}: ICrmDataTable) => {
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Last Update', 'Pricing'])

    const onSetNewTableColumns = () => {
        let formatTableColumns = []
        switch (type) {
            case 'customer':
                formatTableColumns = ['Name', 'Label', 'Margin Type', 'Customer Type', 'Warranty', 'Attn Name', 'Address Line One', 'City', 'Zip Code', 'State', 'Country', 'Phone', 'Actions']
                break;
            case 'company':
                formatTableColumns = ['Name', 'Label', 'Attn Name', 'Address Line One', 'City', 'Zip Code', 'State', 'Country', 'Phone', 'Actions']
                break;
            default:
                formatTableColumns = ['Name', 'Label', 'Actions']
                break;
        }
        setTableColumns(formatTableColumns)
    }

    // useEffect(() => {
    //     onSetNewTableColumns()
    // }, [])

    useEffect(() => {
        onSetNewTableColumns()
    }, [type])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rows?.length > 0 && rows?.map((row: ICrm, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>{row?.displayName}</td>
                        {type === 'customer' &&
                            <>
                                <td className='px-6 py-4'>{row?.details?.marginType}</td>
                                <td className='px-6 py-4'>
                                    {(row?.details?.customerType && row?.details?.customerType?.length > 0 && Array.isArray(row?.details?.customerType)) && row?.details?.customerType?.map((item, index) => `${index ? ', ' : ''}${item}`)}
                                </td>
                                <td className='px-6 py-4'>{row?.warranty || ''}</td>
                            </>
                        }
                        {(type === 'company' || type === 'customer') &&
                            <>
                                <td className='px-6 py-4'>{row?.address?.attnName}</td>
                                <td className='px-6 py-4'>{row?.address?.addressLineOne}</td>
                                <td className='px-6 py-4'>{row?.address?.city}</td>
                                <td className='px-6 py-4'>{row?.address?.zipCode}</td>
                                <td className='px-6 py-4'>{row?.address?.state}</td>
                                <td className='px-6 py-4'>{row?.address?.country}</td>
                                <td className='px-6 py-4'>{row?.address?.phone}</td>
                            </>
                        }
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div onClick={() => row?._id && accessEdit && onEditData(row?._id)}>
                                    <img title='Edit Company' alt='edit company' src={'/assets/table-actions/pen.svg'} className={`h-[14px] object-contain ${accessEdit ? 'cursor-pointer' : 'cursor-not-allowed'} mr-3`} />
                                </div>
                                <div onClick={() => row?._id && accessDelete && onDeleteData(row?._id)}>
                                    <img title={'Delete Company'} alt='delete company' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${accessDelete ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default CrmDataTable;