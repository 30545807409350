import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';

const tryToAuthorizeUser = (data: { dashboard: string }): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/users/authorization`, data);
};

export const AuthorizationService = {
    tryToAuthorizeUser
};
