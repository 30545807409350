export const validateSalesFields = (formFieldsData: any, setFormFieldsErrors: any, selectedMarket: any, batch?: boolean) => {
    let fieldErrors: any
    if (!selectedMarket) {
        fieldErrors = {
            ...(fieldErrors || {}),
            market: 'Market is required'
        }
    }
    if (!formFieldsData?.customer && !formFieldsData?.partner) {
        fieldErrors = {
            ...(fieldErrors || {}),
            customer: 'Customer is required if a broker is not selected',
            partner: 'Partner is required if a customer is not selected'
        }
    }
    if (!formFieldsData?.company && !batch) {
        fieldErrors = {
            ...(fieldErrors || {}),
            company: 'Company is required'
        }
    }
    if (!formFieldsData?.orderCreator) {
        fieldErrors = {
            ...(fieldErrors || {}),
            orderCreator: 'Order Creator is required'
        }
    }
    if (!formFieldsData?.dueDate && !batch) {
        fieldErrors = {
            ...(fieldErrors || {}),
            dueDate: 'Due Date is required'
        }
    }
    // if (!formFieldsData?.invoiceNr) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         invoiceNr: 'Invoice Number is required'
    //     }
    // }
    if (!formFieldsData?.stockVat) {
        fieldErrors = {
            ...(fieldErrors || {}),
            stockVat: 'Stock VAT is required'
        }
    }
    if (!formFieldsData?.status) {
        fieldErrors = {
            ...(fieldErrors || {}),
            status: 'Status is required'
        }
    }
    if (!formFieldsData?.invoiceVat) {
        fieldErrors = {
            ...(fieldErrors || {}),
            invoiceVat: 'Invoice VAT is required'
        }
    }
    if (!formFieldsData?.bankAccount && !batch) {
        fieldErrors = {
            ...(fieldErrors || {}),
            bankAccount: 'Bank Account is required'
        }
    }
    // if (!formFieldsData?.isTempExport && formFieldsData?.isTempExport !== false) {
    //     fieldErrors = {
    //         ...(fieldErrors || {}),
    //         isTempExport: 'Is Temp Export is required'
    //     }
    // }
    if (!formFieldsData?.warranty && !batch) {
        fieldErrors = {
            ...(fieldErrors || {}),
            warranty: 'Warranty is required'
        }
    }

    if (fieldErrors) {
        setFormFieldsErrors(fieldErrors)
        return false
    }
    else {
        setFormFieldsErrors(undefined)
        return true
    }
}