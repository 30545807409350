import React from 'react';
import moment from 'moment';
import Button from '../../shared/button';


interface INotificationMessage {
    data: any;
    action: string;
    type: string;
    createdAt: string;
    readStatus: string;
    onClickSeeDetails: (id: string, type?: string, action?: string) => void;
}

const NotificationMessage = ({
    data,
    createdAt,
    action,
    type,
    readStatus,
    onClickSeeDetails
}: INotificationMessage) => {

    const onFormatTitle = (type: string, action: string) => {
        let message = '';
        if (type === 'purchase-request' && action === 'offer_created') {
            message = 'New Purchase Order Submitted'
        }
        if (type === 'sale-request' && action === 'offer_created') {
            message = 'New Sales Order Submitted'
        }
        if ((type === 'purchase-request' || type === 'sale-request') && (action === 'offer_accepted' || action === 'offer_rejected')) {
            message = `${data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username || data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username} Has Responded to the Offer`
        }
        if ((type === 'purchase-request' || type === 'sale-request') && (action === 'broker_offer_created')) {
            message = `A new offer has been made by ${data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username || data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username}`
        }
        return message
    }

    const onFormatMessage = (type: string, action: string) => {
        let message: any;
        if (type === 'purchase-request' && action === 'offer_created') {
            message = <p className='text-[13px]'>A new purchase order has been submitted by <span className='font-bold'>{data?.partner?.companyName || data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username}</span> for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review the order and proceed with the next steps.</p>
        }
        if (type === 'sale-request' && action === 'offer_created') {
            message = <p className='text-[13px]'>A new sales order has been submitted by <span className='font-bold'>{data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username}</span> for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review the order and proceed with the next steps.</p>
        }
        if (type === 'purchase-request' && action === 'offer_accepted') {
            message = <p className='text-[13px]'>The broker, <span className='font-bold'>{data?.partner?.companyName || data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username}</span>, has accepted the offer for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review their decision and take any necessary actions.</p>
        }
        if (type === 'sale-request' && action === 'offer_accepted') {
            message = <p className='text-[13px]'>The broker, <span className='font-bold'>{data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username}</span>, has accepted the offer for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review their decision and take any necessary actions.</p>
        }
        if (type === 'purchase-request' && action === 'offer_rejected') {
            message = <p className='text-[13px]'>The broker, <span className='font-bold'>{data?.partner?.companyName || data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username}</span>, has rejected the offer for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review their decision and take any necessary actions.</p>
        }
        if (type === 'sale-request' && action === 'offer_rejected') {
            message = <p className='text-[13px]'>The broker, <span className='font-bold'>{data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username}</span>, has rejected the offer for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review their decision and take any necessary actions.</p>
        }
        if (type === 'purchase-request' && action === 'broker_offer_created') {
            message = <p className='text-[13px]'>A new offer for purchase order has been submitted by <span className='font-bold'>{data?.partner?.companyName || data?.purchaseInfo?.partner?.companyName || data?.purchaseInfo?.partner?.username}</span> for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review the order and proceed with the next steps.</p>
        }
        if (type === 'sale-request' && action === 'broker_offer_created') {
            message = <p className='text-[13px]'>A new offer for sales order has been submitted by <span className='font-bold'>{data?.salesInfo?.partner?.companyName || data?.salesInfo?.partner?.username}</span> for <span className='font-bold'>{data?.quoteId || data?._id}</span>. Please review the order and proceed with the next steps.</p>
        }
        return message
    }

    return (
        <div className='bg-[#f8f9fc] !rounded p-2 my-1 grid grid-cols-8'>
            <div className='col-span-5'>
                <p className={'text-primary-light mb-1'}>{onFormatTitle(type, action)}</p>
                {onFormatMessage(type, action)}
                <Button
                    label={'View Details'}
                    className='btn-primary-text-underline !text-[13px] !pl-0 !ml-0'
                    onClickButton={() => onClickSeeDetails(data?.quoteId || data?._id, type, action)}
                />
            </div>
            <div className='col-span-3'>
                <p className='font-bold text-[14px] mb-2'>System Message</p>
                <p className='text-primary-light'>{moment(createdAt)?.format('h:mm:ss, dddd DD/MM/YYYY')}</p>
                <div className='flex flex-row items-center'>
                    <div className={`w-3 h-3 ${(readStatus || '').toLowerCase() === 'unread' ? 'bg-[#e50000]' : 'bg-[#00b67a]'} rounded-full mr-2`}></div>
                    <p className='text-primary-light'>{(readStatus || '').toLowerCase() === 'unread' ? 'Unread' : 'Read'}</p>
                </div>
            </div>
        </div>
    )
}

export default NotificationMessage;