import React from 'react';
import { ISales } from '../../../../interfaces/sales/ISales';
import ExpiredTable from '../tables/ExpiredTable';


interface IExpiredStatus {
    saleDetails?: ISales;
    saleCategories?: Array<any>;
    salesContent?: any;
    templateFields?: any;
}

const ExpiredStatus = ({
    saleDetails,
    saleCategories,
    salesContent,
    templateFields
}: IExpiredStatus) => {
    return (
        <div className='bg-white !rounded my-5 pb-4'>
            <p className='font-semibold text-[18px] p-3'>Items</p>
            <ExpiredTable
                rows={saleDetails?.saleItems}
                categories={saleCategories}
                selectedItems={saleDetails?.selectedItems}
                reservedItems={saleDetails?.reservedItems}
                requestedItems={saleDetails?.requestedItems}
                salesContent={salesContent}
                templateFields={templateFields}
            />
        </div>
    )
}
export default ExpiredStatus;