import React, { useState } from 'react';
import moment from 'moment';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface ISalesTable {
    rows: any[];
    rowsLoading: boolean;
    brokerCategories?: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    loadingDownloadCS: boolean;
    onSeeDetails: (id: string) => void;
    onClickShowNote: (id: string) => void;
    onEditSaleOrder: (id: string) => void;
    onDownloadCSVData: (rowData?: any) => void;
    onClickActions: (id: string, type: string, data?: any) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeAllCategories: (categories: any) => void;
}

const SalesTable = ({
    rows,
    brokerCategories,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    loadingDownloadCS,
    onClickShowNote,
    setPaginationState,
    onDownloadCSVData,
    onClickActions,
    onSeeDetails,
    onEditSaleOrder,
    onClickSeeAllCategories,
}: ISalesTable) => {
    const [tableColumns] = useState<Array<string>>(['Source', 'Sales Order ID', 'Customer', 'Categories', 'VAT', 'Total Price', 'Quantity', 'Due Date (dd-mm-yyyy)', 'Warranty', 'Action'])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const totalPrice = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                        const findCurrency = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.[0]?.currency
                        const totalQuantity = (row?.saleItems && row?.saleItems?.length > 0) && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                        const totalReservedItems = row?.reservedItems?.length
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>
                                    <div className={`flex flex-row items-center ${row?.purchaseRequestId ? '!text-[#f7a21e]' : '!text-black'}`}>
                                        <img src={row?.purchaseRequestId ? '/assets/table-actions/external-flag.svg' : '/assets/table-actions/internal-flag.svg'} className='object-contain h-[20px] min-h-[20px] mr-2' />
                                        <p>{row?.purchaseRequestId ? 'Wingpro' : 'IMS'}</p>
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.quoteId || ''}</td>
                                <td className='px-6 py-4'>{row?.salesInfo?.customer?.displayName || row?.salesInfo?.partner?.companyName || row?.salesInfo?.partner?.username || ''}</td>
                                <td className='pl-6 pr-2 py-4'>
                                    <div
                                        onClick={() => row?.categories?.length > 2 && onClickSeeAllCategories(row?.categories)}
                                        className='flex flex-row flex-wrap items-center cursor-pointer'
                                    >
                                        {(row?.categories && row?.categories?.length > 0 && brokerCategories && brokerCategories?.length > 0) && row?.categories?.filter((item: any, index: number) => index < 2)?.map((item: any) => {
                                            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category: any) => category?.id === item?.id)
                                            return (
                                                <img src={findCategory?.enabledIcon} className='object-contain h-[23px] min-h-[23px] mr-1' key={item?.id} />
                                            )
                                        })}
                                        {row?.categories?.length > 2 ? <p className='font-bold'>...</p> : ''}
                                    </div>
                                </td>
                                <td className='px-6 py-4 min-w-[150px]'>
                                    <p><span className='font-semibold'>Stock VAT:</span> {row?.salesInfo?.stockVat}</p>
                                    <p><span className='font-semibold'>Invoice VAT:</span> {row?.salesInfo?.invoiceVat}</p>
                                </td>
                                <td className='px-6 py-4'>
                                    {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{row?.batch ? Number(row?.offerPrice || totalPrice || 0)?.toFixed(2) : Number(totalPrice || 0).toFixed(2)}
                                </td>
                                <td className='px-6 py-4 min-w-[150px]'>
                                    {(row?.status === 'draft' || row?.status === 'expired') && (totalQuantity || 0)}
                                    {['processed', 'paid', 'shipped'].includes(row?.status) &&
                                        <div>
                                            <p><span className='font-semibold'>Quantity:</span> {totalQuantity || 0}</p>
                                            <p><span className='font-semibold'>Processed:</span> {row?.batch ? totalQuantity || 0 : row?.selectedItems?.length || 0}</p>
                                        </div>
                                    }
                                    {['open_order', 'review', 'processing'].includes(row?.status) &&
                                        <div>
                                            <p><span className='font-semibold'>Quantity:</span> {totalQuantity || 0}</p>
                                            <p><span className='font-semibold'>Reserved:</span> {totalReservedItems || 0}</p>
                                            {row?.selectedItems && <p><span className='font-semibold'>Scanned:</span> {row?.selectedItems?.length || 0}</p>}
                                            {row?.requestedItems && <p><span className='font-semibold'>Requests:</span> {row?.requestedItems?.length || 0}</p>}
                                        </div>
                                    }
                                </td>
                                <td className='px-6 py-4 min-w-[140px]'>{row?.salesInfo?.dueDate && moment(row?.salesInfo?.dueDate).format('DD-MM-YYYY')}</td>
                                <td className='px-6 py-4'>{row?.salesInfo?.warranty}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-start'>
                                        <div onClick={() => row?._id && (!accessControl || actions?.['See Sale Order Details']) && onSeeDetails(row?._id)}>
                                            <img title={'See Sale Order Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mr-3 mt-1 object-contain  ${(!accessControl || actions?.['See Sale Order Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        <TableActions>
                                            {row?.status === 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Edit Sales Order']) && row?._id) && onEditSaleOrder(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Edit Sales Order'}
                                                        dataQa={'edit-sales-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Edit Sales Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status === 'shipped' && row?.shippingInfo?.invoiceUrl) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Download Shipping Label']) && row?._id) && onClickActions(row._id, 'download_label', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Download Shipping Label'}
                                                        dataQa={'download-label'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Download Shipping Label']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status === 'shipped' && !row?.invoiceData?.pdfLink) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Generate an Invoice']) && row?._id) && onClickActions(row._id, 'generate_invoice')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Generate an Invoice'}
                                                        dataQa={'generate-an-invoice'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Generate an Invoice']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status === 'shipped' && row?.invoiceData?.pdfLink) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Download Invoice']) && row?._id) && onClickActions(row?._id, 'download_invoice', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Download Invoice'}
                                                        dataQa={'download-invoice'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Download Invoice']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {row?.status === 'shipped' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Go to Open Order']) && row?._id) && onClickActions(row._id, 'go_to_open_order')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Go to Open Order'}
                                                        dataQa={'go-to-open-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Go to Open Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {row?.status === 'expired' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Add order to draft']) && row?._id) && onClickActions(row?._id, 'add_order_draft')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Add order to draft'}
                                                        dataQa={'add-order-to-draft'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#1389e6] ${(!accessControl || actions?.['Add order to draft']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status === 'expired' && row?.parentQuoteId) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Go To Split Order']) && row?._id) && onClickActions(row?.parentQuoteId, 'go_to_split_order')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={`Go To Split Order ${row?.parentQuoteId || ''}`}
                                                        dataQa={'go-to-split-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Go To Split Order']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            <div
                                                onClick={() => ((!accessControl || actions?.['Add Note']) && row?._id) && onClickShowNote(row._id)}
                                                className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                <Button
                                                    label={'Leave a Note'}
                                                    dataQa={'leave-note'}
                                                    className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Add Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                />
                                            </div>
                                            {(row?.status === 'draft' && !row?.batch) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Mark as approved']) && row?._id) && onClickActions(row._id, 'mark_approved')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Mark as approved'}
                                                        dataQa={'mark-approved'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Mark as approved']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(row?.status === 'draft' && row?.batch) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Mark as processed']) && row?._id) && onClickActions(row._id, 'mark_processed', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Mark as processed'}
                                                        dataQa={'mark-processed'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Mark as processed']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {row?.status === 'processed' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Mark as Paid']) && row?._id) && onClickActions(row._id, 'mark_paid', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Mark as Paid'}
                                                        dataQa={'mark-as-paid'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Mark as Paid']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {row?.status === 'paid' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Mark as Shipped']) && row?._id) && onClickActions(row._id, 'mark_shipped', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Mark as Shipped'}
                                                        dataQa={'mark-as-shipped'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Mark as Shipped']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {['open_order', 'review', 'processed', 'paid', 'shipped']?.includes(row?.status || '') &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Download CSV']) && row?._id && !loadingDownloadCS) && onDownloadCSVData(row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={loadingDownloadCS ? 'Loading...' : 'Download CSV'}
                                                        dataQa={'download-csv'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Download CSV']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(['open_order', 'processed']?.includes(row?.status || '') && row?.proFormData?.pdfLink) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Download Proforma']) && row?._id) && onClickActions(row?._id, 'download_proforma', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Download Proforma'}
                                                        dataQa={'download-proforma'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Download Proforma']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(['open_order', 'processed']?.includes(row?.status || '') && !row?.proFormData?.pdfLink) &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Generate Proforma']) && row?._id) && onClickActions(row?._id, 'generate_proforma', row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Generate Proforma'}
                                                        dataQa={'generate-proforma'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Generate Proforma']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                        </TableActions>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default SalesTable;