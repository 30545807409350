import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { useUserProfileMutation } from '../../../../store/user/userDomApi';
import {
    tryToDeleteWarehouseSection,
    tryToFetchWarehouseSections
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import ManageWarehouseSectionModal from '../modals/ManageWarehouseSectionModal';
import WarehouseOtherSectionsTable from '../tables/WarehouseOtherSectionsTable';
import SearchWithButton from '../../../../shared/search-with-button';
import DeleteModal from '../../../../shared/delete-modal';
import Toast from '../../../../shared/toast';


const OtherSectionsTab = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [search, setSearch] = useState<string>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [selectedSectionId, setSelectedSectionId] = useState<string>();
    const [showSectionModal, setShowSectionModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const [userProfileMutation] = useUserProfileMutation();
    const state = useAppSelector((state) => state.warehouseLocations);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const rowsLoading = useAppSelector((state) => state?.warehouseLocations?.sectionsAreLoading);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const paginationData = useAppSelector((state) => state?.warehouseLocations?.warehouseSections?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Locations Settings')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    useEffect(() => {
        if (state.warehouseSections) {
            const rows = state.warehouseSections?.data?.elements || [];
            setRows(rows);
        }
    }, [state.warehouseSections]);

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap();
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        setBrokerCategories(formatSectors || []);
    };

    useEffect(() => {
        getBrokerSectors()
    }, [])

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    const onGettingWarehouseSectionsData = async () => {
        try {
            if (activeWarehouse) {
                let data: any = {
                    ...(paginationState || {}),
                    data: {
                        section: 'other-section'
                    }
                };
                if (activeWarehouse) {
                    data = {
                        ...(data || {}),
                        data: {
                            ...data?.data || {},
                            warehouseId: activeWarehouse?._id,
                        }
                    };
                }
                if (search) {
                    data = {
                        ...(data || {}),
                        filters: search,
                    };
                }
                await dispatch(tryToFetchWarehouseSections(data)).unwrap();
            }
        } catch (err) {
            // err here
        }
    };

    useEffect(() => {
        onGettingWarehouseSectionsData();
    }, [paginationState]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        onResetPaginationSettings();
    };

    const onClearAllFilters = () => {
        setSearch(undefined)
        onResetPaginationSettings()
    }

    const onToggleManageSectionModal = (id?: string) => {
        if (id) {
            setSelectedSectionId(id)
        }
        setShowSectionModal(true)
    }

    const onRequestDeleteSection = (id: string) => {
        setSelectedSectionId(id)
        setShowDeleteModal(true)
    }

    const onCloseSectionModal = () => {
        setShowDeleteModal(false)
        setShowSectionModal(false)
        setSelectedSectionId(undefined)
    }

    const onSaveChangesSection = (type: string, message: string) => {
        setShowToast({ type, message })
        onClearAllFilters()
    }

    const onDeleteSection = async () => {
        if (selectedSectionId) {
            try {
                await dispatch(tryToDeleteWarehouseSection(selectedSectionId)).unwrap()
                setShowToast({ type: 'success', message: 'Section deleted successfully' })
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            onCloseSectionModal()
        }
    }

    return (
        <div className='my-4'>
            <div className='my-4'>
                <SearchWithButton
                    placeholder='Filter table'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search}
                    showScndBtn={true}
                    onClickSecondButton={onClearAllFilters}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Custom Section']) && onToggleManageSectionModal()}
                    mainContainerStyle={'!grid-cols-10'}
                    scndButtonLabel='Clear All Filters'
                    buttonLabel='Add New Custom Section'
                    scndButtonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'}
                    buttonStyle={`${(!accessControl || pageAccess?.actions?.['Add New Custom Section']) ? 'btn-primary' : 'btn-primary-disable'} col-span-2`}
                />
                <WarehouseOtherSectionsTable
                    rows={rows}
                    paginationData={paginationData}
                    rowsLoading={rowsLoading}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    setPaginationState={setPaginationState}
                    onEditSection={onToggleManageSectionModal}
                    onDeleteSection={onRequestDeleteSection}
                />
            </div>
            {showSectionModal &&
                <ManageWarehouseSectionModal
                    section={'other-section'}
                    openActionsModal={showSectionModal}
                    itemId={selectedSectionId}
                    sectors={brokerCategories || []}
                    onSaveChanges={onSaveChangesSection}
                    handleCloseModal={onCloseSectionModal}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showDeleteModal &&
                <DeleteModal
                    openDeleteModal={showDeleteModal}
                    handleCloseDeleteModal={onCloseSectionModal}
                    onApproveDeletion={onDeleteSection}
                />
            }
        </div>
    )
}

export default OtherSectionsTab