import React from 'react';
import Button from '../../../../shared/button';

interface IFieldsCategories {
    items: Array<any>;
    activeButton?: string;
    onClickCategory: (item: string) => void;
}

const FieldsCategories = ({
    items,
    activeButton,
    onClickCategory,
}: IFieldsCategories) => {

    return (
        <div>
            <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Categories</p>
            <div className='my-2 grid grid-cols-6 gap-4'>
                {items && items?.length > 0 && items?.map((item, index) => {
                    let buttonClassName = 'btn-noactive-categories border'
                    if (activeButton === item?.id) buttonClassName = 'btn-categories';
                    else buttonClassName = 'btn-noactive-categories border';
                    return (
                        <Button
                            key={index}
                            label={item?.label}
                            className={`${buttonClassName} !min-w-[70px]`}
                            onClickButton={() => onClickCategory(item?.id)}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default FieldsCategories;