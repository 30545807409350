import React, { useEffect, useState } from 'react';
import { IItemIdSearch } from '../../../interfaces/item-id-search/IItemIdSearch';
import Table from '../../../shared/table';

interface IInfoTable {
    itemIdResponse?: IItemIdSearch;
    loading: boolean;
    accessControl?: any;
    actions?: any;
    onClickSeeDetails: (id: string) => void;
}

const InfoTable = ({
    itemIdResponse,
    loading,
    accessControl,
    actions,
    onClickSeeDetails
}: IInfoTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['ID', 'Cost', 'VAT Type', 'Location', 'Action'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any = itemIdResponse && itemIdResponse?.revaluation ? Object?.keys(itemIdResponse?.revaluation) : itemIdResponse?.exchangeDefinition?.asset?.descriptor && Object.keys(itemIdResponse?.exchangeDefinition?.asset?.descriptor);
        const allInventories: any = itemIdResponse && itemIdResponse?.inventory && Object.keys(itemIdResponse?.inventory);
        const uniqueKeysArray = (allKeysArray && allKeysArray?.length > 0) && allKeysArray?.filter((key: any, index: any, array: any) => array?.indexOf(key) === index);
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        if ((itemIdResponse?.category)?.toLowerCase() === 'phones' || (itemIdResponse?.category)?.toLowerCase() === 'tablets') {
            formatTableColumns.push('IMEI')
        } else {
            formatTableColumns.push('Item ID')
        }
        if (uniqueKeysArray && uniqueKeysArray?.length > 0) {
            uniqueKeysArray?.filter((key: string) => (key || '').toLowerCase() !== 'condition')?.map((key: string) => {
                if ((key || '').toLowerCase() === 'salesgrade') {
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push('Sales Grade')
                } else {
                    const formatKey = capitalizeFirstLetter(key)
                    const formatElement = (formatKey || '').replaceAll('_', ' ')
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push(formatElement)
                }
                return key
            })
        }
        if (allInventories && allInventories?.length > 0) {
            allInventories?.map((key: string) => {
                if (['salesgrade', 'sales_grade']?.includes((key || '').toLowerCase() || '')) {
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push('Sales Grade')
                } else {
                    const formatKey = capitalizeFirstLetter(key)
                    const formatElement = (formatKey || '').replaceAll('_', ' ')
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push(formatElement)
                }
                return key
            })
        }
        // formatTableColumns.push('Cost')
        // formatTableColumns.push('VAT Type')
        // formatTableColumns.push('Location')
        // formatTableColumns.push('Actions')
        setTableLoading(false)
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-black !text-white'
        >
            {(loading || tableLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    <tr className='bg-white border-b hover:bg-gray-50'>
                        <td>{itemIdResponse?.itemCode || itemIdResponse?.itemId}</td>
                        {(tableExtraKeys && tableExtraKeys?.length > 0) && tableExtraKeys?.map((key: string, index: number) => {
                            return (
                                <td
                                    key={index}
                                    className={'px-6 py-4'}>
                                    {itemIdResponse?.inventory?.[key] || itemIdResponse?.revaluation?.[key] || itemIdResponse?.exchangeDefinition?.asset?.descriptor?.[key]}

                                </td>
                            )
                        })
                        }
                        {/* <td className='px-6 py-4'>{itemIdResponse?.price}</td> */}
                        {/* <td className='px-6 py-4'>{itemIdResponse?.stockVAT}</td>
                        <td className='px-6 py-4'>
                            {itemIdResponse?.locations && (Object?.keys(itemIdResponse?.locations))?.map((loc: any, index: number) => {
                                return `${index === 0 ? `${itemIdResponse?.locations?.[loc]}` : ` - ${itemIdResponse?.locations?.[loc]}`}`
                            })}
                        </td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row'>
                                <div onClick={() => (!accessControl || actions?.['See Item Details']) && itemIdResponse?.itemId && onClickSeeDetails(itemIdResponse?.itemCode || itemIdResponse?.itemId)}>
                                    <img title={'See Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Item Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                </div>
                            </div>
                        </td> */}
                    </tr>
                </>
            }
        </Table>
    )
}

export default InfoTable;