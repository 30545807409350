import React from 'react';
import { onFormatSalesStatus } from '../../../../utils/constants/sale-details-statuses';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Button from '../../../../shared/button';


interface IParentChildDetails {
    salesDetails: any;
    onViewRestOfTheOrder: (id: string) => void;
}

const ParentChildDetails = ({
    salesDetails,
    onViewRestOfTheOrder
}: IParentChildDetails) => {

    return (
        <div className='bg-white my-5 !rounded p-3'>
            <p className='mb-4 font-semibold text-[16px]'>Rest Of The Order</p>
            {(salesDetails && salesDetails?.length > 0) && salesDetails?.map((saleDetails: any, idx: number) => {
                const orderName = onFormatSalesStatus(saleDetails?.status)?.name
                const color = onFormatSalesStatus(saleDetails?.status)?.color || 'bg-[#ebedef]'
                const text = onFormatSalesStatus(saleDetails?.status)?.text || 'text-black'
                return (
                    <div key={idx} className='flex flex-row items-center my-4'>
                        <div className={`${color} !border rounded-md ${text} mr-10 py-1 px-3 font-semibold`}>
                            {orderName}
                        </div>
                        {saleDetails?._id &&
                            <div className='flex flex-col mr-10'>
                                <p className='font-semibold text-[14px]'>Sale Quote ID</p>
                                <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(saleDetails?._id)}>
                                    <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                                    <p className='text-[14px]'>{saleDetails?._id}</p>
                                </div>
                            </div>
                        }
                        {saleDetails?.quantity &&
                            <div className='flex flex-col mr-10'>
                                <p className='font-semibold text-[14px]'>Quantity</p>
                                <p className='text-[14px]'>{saleDetails?.quantity || 0}</p>
                            </div>
                        }
                        {saleDetails?.value &&
                            <div className='flex flex-col mr-10'>
                                <p className='font-semibold text-[14px]'>Value</p>
                                <p className='text-[14px]'>{CURRENCY_SYMBOLS?.[(saleDetails?.currency || '')?.toLowerCase()]}{Number(saleDetails?.value || 0)?.toFixed(2)}</p>
                            </div>
                        }
                        <div>
                            <Button
                                label={'View Order'}
                                className='btn-dark-yellow'
                                onClickButton={() => onViewRestOfTheOrder(saleDetails?._id || '')}
                            />
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default ParentChildDetails;