import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PurchaseRequestsService } from './purchaseRequestsApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPurchaseRequestList } from '../../../../interfaces/purchase-requests/IPurchaseRequestList';
import { AxiosError } from 'axios';


const initialState: IPurchaseRequestList = {
    purchaseRequestsAreLoading: false,
    purchaseRequestIsLoading: false,
    purchaseNotesAreLoading: true,
    mainOrderLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPurchaseRequests = createAsyncThunk<any, IPaginationPayload>(
    'purchaseRequests/tryToFetchPurchaseRequests',
    async ({ pageNumber, pageSize, data }) => {
        const result = await PurchaseRequestsService.tryToFetchPurchaseRequests(
            pageNumber,
            pageSize,
            data,
        );
        return result?.data;
    }
);

export const tryToFetchSinglePurchaseRequest = createAsyncThunk<any, string>(
    'purchaseRequests/tryToFetchSinglePurchaseRequest',
    async (id: string) => {
        const result = await PurchaseRequestsService.tryToFetchSinglePurchaseRequest(id);
        return result?.data;
    },
);

export const tryToFetchAllPurchaseRequest = createAsyncThunk<any, any>(
    'purchaseRequests/tryToFetchAllPurchaseRequest',
    async (data: any) => {
        const result = await PurchaseRequestsService.tryToFetchAllPurchaseRequest(data);
        return result?.data?.data;
    },
);

export const tryToAddPurchaseRequestNote = createAsyncThunk<any, any>(
    'purchaseRequests/tryToAddPurchaseRequestNote',
    async ({ id, data }) => {
        const result = await PurchaseRequestsService.tryToAddPurchaseRequestNote(id, data);
        return result?.data;
    },
);

export const tryToFetchPurchaseRequestNote = createAsyncThunk<any, string>(
    'purchaseRequests/tryToFetchPurchaseRequestNote',
    async (id: string) => {
        const result = await PurchaseRequestsService.tryToFetchPurchaseRequestNote(id);
        return result?.data;
    },
);

export const tryToSubmitPurchaseRequestOffer = createAsyncThunk<any, { id: string, status: string, data?: any }>(
    'purchaseOrders/tryToSubmitPurchaseRequestOffer',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await PurchaseRequestsService.tryToSubmitPurchaseRequestOffer(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const purchaseRequestsSlice = createSlice({
    name: 'purchaseRequests',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPurchaseRequests
            .addCase(tryToFetchPurchaseRequests.pending, (state) => {
                state.purchaseRequestsAreLoading = true;
            })
            .addCase(tryToFetchPurchaseRequests.rejected, (state) => {
                state.purchaseRequestsAreLoading = false;
            })
            .addCase(tryToFetchPurchaseRequests.fulfilled, (state, action) => {
                state.purchaseRequestsAreLoading = false;
                state.purchaseRequests = action.payload;
            })

            // tryToFetchSinglePurchaseRequest
            .addCase(tryToFetchSinglePurchaseRequest.pending, (state) => {
                state.purchaseRequestIsLoading = true;
            })
            .addCase(tryToFetchSinglePurchaseRequest.rejected, (state) => {
                state.purchaseRequestIsLoading = false;
            })
            .addCase(tryToFetchSinglePurchaseRequest.fulfilled, (state, action) => {
                state.purchaseRequestIsLoading = false;
                state.purchaseRequestDetails = action.payload.data;
            })

            // tryToFetchPurchaseRequestNote
            .addCase(tryToFetchPurchaseRequestNote.pending, (state) => {
                state.purchaseNotesAreLoading = true;
            })
            .addCase(tryToFetchPurchaseRequestNote.rejected, (state) => {
                state.purchaseNotesAreLoading = false;
            })
            .addCase(tryToFetchPurchaseRequestNote.fulfilled, (state, action) => {
                state.purchaseNotesAreLoading = false;
                state.purchaseRequestNotes = action.payload.data;
            })

            // tryToAddPurchaseRequestNote
            .addCase(tryToAddPurchaseRequestNote.pending, (state) => {
                state.purchaseNotesAreLoading = true;
            })
            .addCase(tryToAddPurchaseRequestNote.rejected, (state) => {
                state.purchaseNotesAreLoading = false;
            })
            .addCase(tryToAddPurchaseRequestNote.fulfilled, (state, action) => {
                state.purchaseNotesAreLoading = false;
                state.purchaseRequestNotes = action.payload?.data;
            })

            // tryToSubmitPurchaseRequestOffer
            .addCase(tryToSubmitPurchaseRequestOffer.pending, (state) => {
                state.purchaseNotesAreLoading = true;
            })
            .addCase(tryToSubmitPurchaseRequestOffer.rejected, (state) => {
                state.purchaseNotesAreLoading = false;
            })
            .addCase(tryToSubmitPurchaseRequestOffer.fulfilled, (state, action) => {
                state.purchaseNotesAreLoading = false;
                state.requestStatus = 'success';
                if (state.purchaseRequests?.data.elements)
                    state.purchaseRequests.data.elements =
                        { ...state }.purchaseRequests?.data.elements.map((purchase) => {
                            if (purchase?._id === action.meta.arg?.id) {
                                return {
                                    ...purchase || {},
                                    status: action.meta.arg?.status,
                                    totalOfferPrice: action?.meta?.arg?.data?.totalOfferPrice || action?.payload?.data?.totalOfferPrice,
                                    offers: action?.payload?.data?.offers,
                                }
                            } else {
                                return purchase
                            }
                        }
                        ) || [];
            })
    },
});

export default purchaseRequestsSlice.reducer;
