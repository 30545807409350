import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IWarehouse } from '../../../interfaces/warehouses/IWarehouse';
import { tryToFetchSingleWarehouse } from '../../../store/inventory/broker/warehouses/warehousesSlice';
import Tabs from './tabs/Tabs';
import SectionsTab from './tabs/SectionsTab';
import LocationsTab from './tabs/LocationsTab';
import OtherSectionsTab from './tabs/OtherSectionsTab';

const tabs = [
    { name: 'Custom Sections', element: <OtherSectionsTab /> },
    { name: 'Sections', element: <SectionsTab /> },
    { name: 'Generated Locations', element: <LocationsTab /> }
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const WarehouseLocations = () => {
    const query = useQuery();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [warehouseData, setWarehouseData] = useState<IWarehouse>();
    const [activeTab, setActiveTab] = useState<any>(tabs[0]);
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state.warehouses);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const warehouseIsLoading = useAppSelector((state) => state.warehouses?.warehouseIsLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Locations Settings')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[tabs[0]?.name] && !query?.get('type') ? tabs[0] : tabs?.find((item: any) => findChild?.actions?.[item?.name] && (query?.get('type') === item?.name || !query?.get('type')))
            setActiveTab(findActiveCategory)
        }
    }, [accessControl])

    useEffect(() => {
        if (state.warehouse) {
            setWarehouseData(state.warehouse)
        }
    }, [state.warehouse]);

    useEffect(() => {
        if (activeWarehouse?._id) {
            dispatch(tryToFetchSingleWarehouse(activeWarehouse?._id));
        }
    }, [activeWarehouse]);

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = tabs?.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    const onClickTab = (value: any) => {
        setActiveTab(value)
        searchParams.set('type', value?.name)
        setSearchParams(searchParams)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Warehouse Locations Configurations</p>
            </div>
            <div className='mb-5'>
                {!warehouseIsLoading && warehouseData &&
                    <div>
                        <p className='font-medium'>Name: <span className='font-normal'>{warehouseData?.name}</span></p>
                        <p className='font-medium'>Details: <span className='font-normal'>{warehouseData?.address?.addressNo} {warehouseData?.address?.street}, {warehouseData?.address?.city} {warehouseData?.address?.postalCode}, {warehouseData?.address?.country}</span></p>
                    </div>
                }
            </div>
            <Tabs
                activeTab={activeTab.name}
                onClickTab={onClickTab}
                tabs={tabs?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name])) || []}
            />
            <div>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? activeTab?.element : ''}
            </div>
        </div>
    )
}
export default WarehouseLocations