import React from 'react';
import Table from '../../../../shared/table';

const tableColumns = ['Section', 'Item ID/Code', 'Location']

interface ILocationsTable {
    rows: any[];
}

const LocationsTable = ({
    rows,
}: ILocationsTable) => {
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.section?.name}</td>
                        <td className='px-6 py-4'>{row?.itemId}</td>
                        <td className='px-6 py-4'>
                            {row?.locations && (Object?.keys(row?.locations))?.map((obj: any, index: number) => {
                                return index === 0 ? row?.locations?.[obj] : ` - ${row?.locations?.[obj]}`
                            })}
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default LocationsTable;