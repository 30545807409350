import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL, OFFER_API_URL, ORDERS_API_URL, USERS_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { PaginatedReports } from '../../../../interfaces/reports/paginatedStore.type';
import offersAxios from '../../../../utils/axios/offers.axios';
import userAxios from '../../../../utils/axios/user.axios';

const tryToFetchSales = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    filters?: any
): Promise<AxiosResponse<any>> => {
    const url = filters ?
        `${INVENTORY_API_URL}/admin-management/items/sold?search=${filters}&page=${pageNumber}&size=${pageSize}` :
        `${INVENTORY_API_URL}/admin-management/items/sold?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedReports>(url, data);
};

const tryToFetchPurchases = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    filters?: any
): Promise<AxiosResponse<any>> => {
    const url = filters ?
        `${INVENTORY_API_URL}/admin-management/purchase/items?search=${filters}&page=${pageNumber}&size=${pageSize}` :
        `${INVENTORY_API_URL}/admin-management/purchase/items?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedReports>(url, data);
};

const tryToFetchInventories = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    filters?: any
): Promise<AxiosResponse<any>> => {
    const url = filters ?
        `${INVENTORY_API_URL}/admin-management/inventory/items?search=${filters}&page=${pageNumber}&size=${pageSize}` :
        `${INVENTORY_API_URL}/admin-management/inventory/items?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedReports>(url, data);
};

const tryToFetchAllSalesHistory = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    filters?: any
): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/sales-upload-history/all?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedReports>(url, data);
};

const tryToFetchSingleSaleHistory = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedReports>> => {
    const url = `${INVENTORY_API_URL}/sales-upload-history/${filters}/details?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedReports>(url, data);
};

const tryToDownloadSales = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/items/sold/download`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadSalesSample = (): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/sample/download`
    return inventoryAxios.get<any>(url);
};

const tryToDownloadPurchases = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/purchase/items/download`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadCashouts = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/cashouts/items/download`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadInventories = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/inventory/items/download`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadReportSheetInventories = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/inventory/download-sheet`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadReportSheetSales = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/sales/download-sheet`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadReportSheetPurchases = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/purchases/download-sheet`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadWholesalePriceBuy = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/dom/tracking/pricing/buy`
    return offersAxios.put<any>(url, data);
};

const tryToDownloadWholesalePriceSell = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/dom/tracking/pricing/sell`
    return offersAxios.put<any>(url, data);
};

const tryToDownloadPartnersReport = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${USERS_API_URL}/dom/tracking/brokers`
    return userAxios.put<any>(url, data);
};

const tryToDownloadPricingReport = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${OFFER_API_URL}/offers/download/best-pricing`
    return userAxios.post<any>(url, data);
};

const tryToImportSales = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/admin-management/upload-sales-data`, data);
};

const tryToDownloadThings = (): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_API_URL}/items/download/things-screen`
    return inventoryAxios.post<any>(url);
};

const tryToDownloadAggregatedInventories = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/inventory/download-aggregated`
    return inventoryAxios.put<any>(url, data);
};

const tryToDownloadAggregatedPurchases = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/purchases/download-aggregated`
    return inventoryAxios.put<any>(url, data);
};

const tryToFetchForGivenDate = (data?: any): Promise<AxiosResponse<any>> => {
    const url = `${INVENTORY_API_URL}/admin-management/inventory/historical-data`
    return inventoryAxios.put<any>(url, data);
};


export const ReportsService = {
    tryToFetchSales,
    tryToFetchPurchases,
    tryToFetchInventories,
    tryToDownloadPurchases,
    tryToFetchAllSalesHistory,
    tryToFetchSingleSaleHistory,
    tryToDownloadSales,
    tryToDownloadInventories,
    tryToImportSales,
    tryToDownloadSalesSample,
    tryToDownloadWholesalePriceBuy,
    tryToDownloadWholesalePriceSell,
    tryToDownloadPartnersReport,
    tryToDownloadPricingReport,
    tryToDownloadCashouts,
    tryToDownloadThings,
    tryToDownloadReportSheetInventories,
    tryToDownloadReportSheetPurchases,
    tryToDownloadAggregatedInventories,
    tryToDownloadAggregatedPurchases,
    tryToFetchForGivenDate,
    tryToDownloadReportSheetSales
};
