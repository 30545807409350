import React from 'react'
import { IInventory } from '../../../../interfaces/inventory-data/IInventory';
import { IMEI_STATUESES } from '../../../../utils/constants/imei-statuses';
import { VAT_ITEMS } from '../../../../utils/constants/vat-types';
import ItemCatalogFieldsForm from './ItemCatalogFieldsForm'
import WhiteContainer from '../../../../shared/white-container';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


interface IItemDetailsContainer {
    inventoryDetails?: IInventory;
    otherDataErrors?: any;
    otherData?: any;
    formData?: any;
    imeiError?: string;
    imeiNotExist: boolean;
    imeiSearch?: any;
    imeiStatus?: string;
    items: Array<any>;
    templateId?: string;
    filterValues: any;
    filterInventoryValues: any;
    filterExtendedValues: any;
    inventoryItems?: Array<any>;
    extendedItems?: Array<any>;
    filterErrors?: any;
    inventoryFilterErrors?: any;
    loadingValues: boolean;
    loadingFields: boolean;
    loadingOptions?: any;
    filteredFields?: any;
    searchInDB?: any;
    primaryQuestions: any,
    secondaryQuestions: any;
    selectedPrimaryAnswers?: any;
    selectedSecondaryAnswers?: any;
    onChangeInputValue: (value: any, type?: string) => void;
    onChangeImeiSearch: (value: any, type?: string) => void;
    onClickFindByImei: () => void;
    onClickImeiNotExist: () => void;
    onClearAssetFieldsData: () => void;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeSelectedInventoryValue: (value: string, type?: string) => void;
    onChangeSelectedExtendedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeInventoryInput: (value: string | null | undefined, type: string) => void;
    onChangeExtendedInput: (value: string | null | undefined, type: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onClickSearchInDb: (type?: string, index?: any) => void;
    onChangeSelectedDetail: (value: any, type?: string) => void;
    onChangeExtraText: (value: any, type?: string) => void;
    onSelectPrimaryQuestion: (value: any, type?: string, index?: string) => void;
    onSelectSecondaryQuestion: (value: any, type?: string, index?: string) => void;
    onChangeSecondaryInputValue: (value: any, type?: string, index?: string) => void;
}

const ItemDetailsContainer = ({
    primaryQuestions,
    secondaryQuestions,
    selectedPrimaryAnswers,
    selectedSecondaryAnswers,
    inventoryDetails,
    otherDataErrors,
    otherData,
    formData,
    imeiError,
    imeiNotExist,
    imeiSearch,
    imeiStatus,
    items,
    inventoryItems,
    extendedItems,
    filterValues,
    filterInventoryValues,
    filterExtendedValues,
    filterErrors,
    inventoryFilterErrors,
    loadingValues,
    loadingFields,
    loadingOptions,
    filteredFields,
    searchInDB,
    onClearAssetFieldsData,
    onChangeSelectedValue,
    onChangeSelectedInventoryValue,
    onChangeSelectedExtendedValue,
    onChangeInventoryInput,
    onChangeExtendedInput,
    onChangeInput,
    onChangeSearchAssetFields,
    onMenuCloseAfterSearch,
    onClickSearchInDb,
    onClickImeiNotExist,
    onChangeInputValue,
    onChangeImeiSearch,
    onClickFindByImei,
    onChangeSelectedDetail,
    onChangeExtraText,
    onSelectPrimaryQuestion,
    onSelectSecondaryQuestion,
    onChangeSecondaryInputValue,
}: IItemDetailsContainer) => {
    return (
        <WhiteContainer containerStyle='!rounded p-4'>
            <p className='font-bold text-[18px] mb-4'>Stock Item Details</p>
            <div>
                <Input
                    label='Item ID'
                    placeholder=' '
                    disabled={true}
                    showValue={true}
                    inputValue={inventoryDetails?.itemId}
                />
                <Input
                    label='Item Name'
                    placeholder=' '
                    showValue={true}
                    disabled={true}
                    inputValue={otherData?.itemName}
                    containerStyle='min-w-[85%]'
                    inputUniqueName='itemName'
                />
                {(inventoryDetails && inventoryDetails?.category && ['phones', 'tablets']?.includes?.((inventoryDetails?.category || '').toLowerCase()))
                    &&
                    <div>
                        <div className='flex flex-row justify-between items-center'>
                            <Input
                                label='IMEI'
                                placeholder=' '
                                showValue={true}
                                error={imeiError || otherData?.imei || undefined}
                                disabled={imeiNotExist}
                                inputValue={imeiSearch}
                                containerStyle='min-w-[70%] md:min-w-[75%] lg:min-w-[80%] xl:min-w-[85%]'
                                onChangeInput={onChangeImeiSearch}
                                inputUniqueName='itemCode'
                            />
                            <Button
                                label={'Check'}
                                onClickButton={() => !imeiNotExist && onClickFindByImei()}
                                className='btn-primary ml-2 mt-2 max-w-[30%]'
                            />
                        </div>
                        {imeiStatus && <p className={imeiStatus === 'Yes' ? 'text-red-500 mb-2' : 'text-[#198754] mb-2'}>IMEI Blacklisted: {imeiStatus}</p>}
                        <div className='flex flex-row items-center mb-6'>
                            <div className='flex items-center mr-2'>
                                <input
                                    onChange={() => onClickImeiNotExist()}
                                    checked={imeiNotExist}
                                    id='checkbox-all'
                                    data-qa={'checkbox'}
                                    type='checkbox'
                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                />
                                <label htmlFor='checkbox-all' className='sr-only'>
                                    checkbox
                                </label>
                            </div>
                            <p className='text-primary-light'>
                                IMEI doesn&apos;t exist.
                            </p>
                        </div>
                    </div>
                }
                <ItemCatalogFieldsForm
                    primaryQuestions={primaryQuestions}
                    secondaryQuestions={secondaryQuestions}
                    selectedPrimaryAnswers={selectedPrimaryAnswers}
                    selectedSecondaryAnswers={selectedSecondaryAnswers}
                    items={items || []}
                    filterExtendedValues={filterExtendedValues}
                    loadingValues={loadingValues}
                    loadingFields={loadingFields}
                    loadingOptions={loadingOptions}
                    extendedItems={extendedItems || []}
                    inventoryItems={inventoryItems || []}
                    filteredFields={filteredFields}
                    filterValues={formData}
                    searchInDB={searchInDB}
                    filterInventoryValues={filterInventoryValues}
                    templateId={inventoryDetails?.categoryId || ''}
                    filterErrors={filterErrors}
                    inventoryFilterErrors={inventoryFilterErrors}
                    extendedFilterErrors={undefined}
                    onChangeExtendedInput={onChangeExtendedInput}
                    onChangeSelectedExtendedValue={onChangeSelectedExtendedValue}
                    onChangeSecondaryInputValue={onChangeSecondaryInputValue}
                    onSelectPrimaryQuestion={onSelectPrimaryQuestion}
                    onSelectSecondaryQuestion={onSelectSecondaryQuestion}
                    onClearAssetFieldsData={onClearAssetFieldsData}
                    onClickSearchInDb={onClickSearchInDb}
                    onChangeSelectedValue={onChangeSelectedValue}
                    onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                    onChangeInput={onChangeInput}
                    onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                    onChangeInventoryInput={onChangeInventoryInput}
                    onChangeSearchAssetFields={onChangeSearchAssetFields}
                />
                <Input
                    label='Sales Price'
                    placeholder=' '
                    showValue={true}
                    error={otherDataErrors?.salesPrice}
                    inputValue={otherData?.salesPrice}
                    containerStyle='min-w-[85%]'
                    onChangeInput={onChangeInputValue}
                    inputUniqueName='salesPrice'
                />
                <Input
                    label='Purchase Price'
                    placeholder=' '
                    showValue={true}
                    disabled={true}
                    error={otherDataErrors?.price}
                    inputValue={otherData?.price}
                    containerStyle='min-w-[85%]'
                    onChangeInput={onChangeInputValue}
                    inputUniqueName='price'
                />
                {(inventoryDetails && inventoryDetails?.category && ['phones', 'tablets']?.includes?.((inventoryDetails?.category || '').toLowerCase()))
                    &&
                    <SelectCheckbox
                        name={'IMEI Status'}
                        options={IMEI_STATUESES}
                        selectedOption={otherData?.imeiStatus ? IMEI_STATUESES?.find((item: any) => item?.value === otherData?.imeiStatus) : undefined}
                        onChangeSelectedOption={onChangeSelectedDetail}
                        uniqueName='imeiStatus'
                        error={otherDataErrors?.imeiStatus}
                    />
                }
                <SelectCheckbox
                    name={'VAT Code'}
                    options={VAT_ITEMS}
                    selectedOption={otherData?.stockVAT ? VAT_ITEMS?.find((item: any) => item?.value === otherData?.stockVAT) : undefined}
                    onChangeSelectedOption={onChangeSelectedDetail}
                    uniqueName='stockVAT'
                    error={otherDataErrors?.stockVAT}
                />
                <Textarea
                    name={'Extras'}
                    placeholder='Extras'
                    containerStyle='!mb-0'
                    rows={2}
                    dataQa={'extras'}
                    value={otherData?.extras}
                    index={'extras'}
                    textareaType='description'
                    onChangeTextarea={onChangeExtraText}
                />
            </div>
        </WhiteContainer>
    )
}

export default ItemDetailsContainer