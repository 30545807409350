import React from 'react';
import { SALE_CREATE_TYPE } from '../../../../utils/constants/sales-order-statuses';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IPurchaseTypeModal {
    openModal: boolean;
    selectedType?: any;
    handleCloseModal: () => void;
    onClickType: (item: any) => void;
}

const PurchaseTypeModal = ({
    openModal,
    selectedType,
    onClickType,
    handleCloseModal,
}: IPurchaseTypeModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Pick Order Type'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-[#e4e4e4] mt-8 mb-4'>
                    <p className='mb-4 font-semibold text-md'>Choose Type</p>
                </div>
                <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {SALE_CREATE_TYPE?.map((item: any, index: number) => {
                        let buttonClassName = 'btn-noactive-categories border'
                        if (selectedType?.value === item?.value) buttonClassName = 'btn-categories-list !rounded-[6px]';
                        else buttonClassName = 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0] !rounded-[6px]';
                        return (
                            <Button
                                key={index}
                                label={item?.label}
                                className={`${buttonClassName} !min-w-[70px] text-sm`}
                                onClickButton={() => onClickType(item)}
                                dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                            />
                        )
                    })}
                </div>
                <div className='my-4 flex flex-row justify-center'>
                    <Button
                        label='Confirm'
                        className='btn-primary min-w-[250px] mt-10'
                        onClickButton={onCloseModal}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default PurchaseTypeModal;