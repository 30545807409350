import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currencySymbols';
import { IToast } from '../../../interfaces/components/IToast';
import { IPurchase } from '../../../interfaces/purchases/IPurchase';
import { IPurchaseContent } from '../../../interfaces/purchases/IPurchaseContent';
import {
    tryToAcceptPurchase,
    tryToAcceptPurchaseItem,
    tryToFetchPurchaseContent,
    tryToFetchSinglePurchase,
    tryToFinalizePurchaseReview,
    tryToPayPurchase,
    tryToReturnPurchaseItem,
    tryToSplitPurchase,
    tryToAwaitCreditPurchaseItem
} from '../../../store/inventory/broker/purchases/purchasesSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import ReviewDetailsPurchaseTable from './tables/ReviewDetailsPurchaseTable';
import PurchaseDetailsTable from './tables/PurchaseDetailsTable';
import SplitPurchaseTable from './tables/SplitPurchaseTable';
import ReturnItemsTable from './tables/ReturnItemsTable';
import RevalueDiscrepancyItemModal from './modals/RevalueDiscrepancyItemModal';
import AcceptDiscrepancyItemModal from './modals/AcceptDiscrepancyItemModal';
import ReturnDiscrepancyItemModal from './modals/ReturnDiscrepancyItemModal';
import GenerateReturnLabelModal from './modals/GenerateReturnLabelModal';
import BatchingCompleteModal from './modals/BatchingCompleteModal';
import PurchasePackagesModal from './modals/PurchasePackagesModal';
import AcceptPurchaseModal from './modals/AcceptPurchaseModal';
import AwaitingCreditModal from './modals/AwaitingCreditModal';
import PaidPurchaseModal from './modals/PaidPurchaseModal';
import PurchaseDetailsInfo from './details/PurchaseDetailsInfo';
import MainOrderModal from '../../../shared/main-order-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Error from '../../../shared/error';


const PurchaseDetails = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const [splitLoading, setSplitLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [purchaseCategory, setPurchaseCategory] = useState<any>();
    const [showGenerateLabelModal, setShowGenerateLabelModal] = useState<boolean>(false);
    const [showAcceptItemModal, setShowAcceptItemModal] = useState<boolean>(false);
    const [showRevalueItemModal, setShowRevalueItemModal] = useState<boolean>(false);
    const [showReturnItemModal, setShowReturnItemModal] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<any>();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [loadingSubmitAction, setLoadingSubmitAction] = useState<boolean>(false);
    const [purchaseDetails, setPurchaseDetails] = useState<IPurchase>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [purchaseContent, setPurchaseContent] = useState<IPurchaseContent[]>();
    const [showPaidModal, setShowPaidModal] = useState<boolean>(false);
    const [showAcceptedModal, setShowAcceptedModal] = useState<boolean>(false);
    const [showDeliveredModal, setShowDeliveredModal] = useState<boolean>(false);
    const [loadingActionSubmit, setLoadingActionSubmit] = useState<boolean>(false);
    const [showCreditItemModal, setShowCreditItemModal] = useState<boolean>(false);
    const [showSplitTable, setShowSplitTable] = useState<boolean>(false);
    const [templateFields, setTemplateFields] = useState<any>();
    const [showCompletedModal, setShowCompletedModal] = useState<boolean>(false);
    const [loadingFinalizeOrder, setLoadingFinalizeOrder] = useState<boolean>();
    const [showMainOrderModal, setShowMainOrderModal] = useState<boolean>();
    const [finalizeError, setFinalizeError] = useState<string>();
    const [userProfileMutation] = useUserProfileMutation();
    const state = useAppSelector((state) => state.purchases);
    // const loadingRows = useAppSelector((state) => state.purchases?.purchaseIsLoading);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Purchases')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Purchase Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ label: s?.displayName || s?.name, name: s?.name, value: s?.id })) : []
        setPurchaseCategory(formatSectors || [])
    }

    useEffect(() => {
        if (state.purchaseDetails) {
            setPurchaseDetails(state.purchaseDetails)
            if (!purchaseCategory) {
                getBrokerSectors()
            }
        }
    }, [state.purchaseDetails])

    useEffect(() => {
        if (state.purchaseContent) {
            setPurchaseContent(state.purchaseContent)
        }
    }, [state.purchaseContent])

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSinglePurchase(id))
            dispatch(tryToFetchPurchaseContent(id))
        }
    }, [id])

    const onGoBack = () => {
        const prevEntries = localStorage.getItem('prev')
        if (prevEntries === 'purchases') {
            navigator(-1)
        } else {
            navigator(`/purchases?filter=${purchaseDetails?.status}`);
        }
        navigator(-1)
    }

    const onClickPurchaseReview = () => {
        navigator(`/purchases/${id}/review`)
    }

    const onGettingAllAssetFields = async () => {
        if (purchaseDetails?.categories && purchaseDetails?.categories?.length > 0) {
            try {
                await Promise.all(purchaseDetails?.categories?.map(async (item: any) => {
                    if (!templateFields?.[item?.code]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.id)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.id || '']: response?.fields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
        }
    };

    useEffect(() => {
        if (purchaseDetails?.categories && purchaseDetails?.status && ['in_review', 'split_discrepancy']?.includes(purchaseDetails?.status || '')) {
            onGettingAllAssetFields()
        }
    }, [purchaseDetails?.categories, purchaseDetails?.status])

    const onClickSplitOrder = async () => {
        setSplitLoading(true)
        try {
            const res: any = await dispatch(tryToSplitPurchase(purchaseDetails?._id || '')).unwrap()
            if (res && res?.quoteId) {
                navigator(`/purchases/${res?.quoteId}`)
            }
            setShowToast({ type: 'success', message: 'Purchase successfully split.' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setSplitLoading(false)
    }

    const onGoToParentOrder = async () => {
        navigator(`/purchases/${purchaseDetails?.parentQuoteId}`)
    }

    const onClickGenerateLabel = () => {
        if (showGenerateLabelModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowGenerateLabelModal(!showGenerateLabelModal)
    }

    const onClickDownloadLabel = () => {
        if (purchaseDetails?.returnShippingLabelUrl) {
            window?.open(purchaseDetails?.returnShippingLabelUrl, '_blank')
        }
    }

    const onClickShowAcceptModal = (itemId: string) => {
        document.body.style.overflow = 'hidden';
        setSelectedItemId(itemId)
        setShowAcceptItemModal(true)
    }

    const onClickShowReturnModal = (itemId: string) => {
        document.body.style.overflow = 'hidden';
        setSelectedItemId(itemId)
        setShowReturnItemModal(true)
    }

    const onClickShowRevalueModal = (itemId: string) => {
        document.body.style.overflow = 'hidden';
        setSelectedItemId(itemId)
        setShowRevalueItemModal(true)
    }

    const onClickShowAwatingCredit = (item: any) => {
        document.body.style.overflow = 'hidden';
        setSelectedItem(item)
        setShowCreditItemModal(true)
    }

    const onHideAction = () => {
        setShowAcceptItemModal(false)
        setShowRevalueItemModal(false)
        setShowReturnItemModal(false)
        setSelectedItemId(undefined)
        setSelectedItem(undefined)
        setShowCreditItemModal(false)
        document.body.style.overflow = 'auto';
    }

    const onAcceptItem = async () => {
        if (selectedItemId) {
            setLoadingSubmitAction(true)
            try {
                await dispatch(tryToAcceptPurchaseItem({ id: purchaseDetails?._id || '', itemId: selectedItemId })).unwrap()
                setShowToast({ type: 'success', message: 'Item successfully accepted' })
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            setSelectedItemId(undefined)
            setShowAcceptItemModal(false)
            setLoadingSubmitAction(false)
            document.body.style.overflow = 'auto';
        }
    }

    const onReturnItem = async () => {
        if (selectedItemId) {
            setLoadingSubmitAction(true)
            try {
                await dispatch(tryToReturnPurchaseItem({ id: purchaseDetails?._id || '', itemId: selectedItemId })).unwrap()
                setShowToast({ type: 'success', message: 'Item successfully marked to return' })
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            setSelectedItemId(undefined)
            setShowReturnItemModal(false)
            document.body.style.overflow = 'auto';
            setLoadingSubmitAction(false)
        }
    }

    const onAddToAwaitingCreditItem = async () => {
        if (selectedItem) {
            setLoadingSubmitAction(true)
            try {
                await dispatch(tryToAwaitCreditPurchaseItem({ id: purchaseDetails?._id || '', item: selectedItem })).unwrap()
                setShowToast({ type: 'success', message: 'Item successfully changed' })
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            setSelectedItem(undefined)
            setShowCreditItemModal(false)
            setLoadingSubmitAction(false)
            document.body.style.overflow = 'auto';
        }
    }

    const onSuccessRevalueItem = () => {
        setShowToast({ type: 'success', message: 'Item successfully revalued' })
        setSelectedItemId(undefined)
        setShowRevalueItemModal(false)
        document.body.style.overflow = 'auto';
    }

    const onSuccessGenerate = () => {
        setShowToast({ type: 'success', message: 'Label generated successfully' })
        document.body.style.overflow = 'auto';
    }

    const onAcceptedPurchaseOrder = async () => {
        setLoadingActionSubmit(true)
        try {
            await dispatch(tryToAcceptPurchase(state?.purchaseDetails?._id || '')).unwrap()
            setShowToast({ type: 'success', message: 'Purchase successfully approved' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        setLoadingActionSubmit(false)
        setShowAcceptedModal(false)
    }

    const onClickMarkAsApproved = () => {
        setShowAcceptedModal(true)
    }

    const onClickMarkAsCompleted = () => {
        setShowCompletedModal(true)
        document.body.style.overflow = 'hidden';
    }

    const onClickCloseConfirm = () => {
        setShowCompletedModal(false)
        document.body.style.overflow = 'auto';
    }

    const onClosePaidModal = () => {
        setShowPaidModal(false)
        document.body.style.overflow = 'auto';
    }

    const onPaidPurchaseOrder = async (date?: string) => {
        setLoadingActionSubmit(true)
        try {
            await dispatch(tryToPayPurchase({ id: state?.purchaseDetails?._id || '', dueDate: date }))
            setShowToast({ type: 'success', message: 'Purchase successfully marked as paid' })
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` })
        }
        onClosePaidModal()
        setLoadingActionSubmit(false)
    }

    const onClickMarkAsPaid = () => {
        document.body.style.overflow = 'hidden';
        setShowPaidModal(true)
    }

    const onClickMarkAsDelivered = () => {
        document.body.style.overflow = 'hidden';
        setShowDeliveredModal(true)
    }

    const onSuccessSavedPackageBoxes = () => {
        setShowToast({ type: 'success', message: 'Purchase Packages saved successfully' })
    }

    const onClosePackagesModal = () => {
        document.body.style.overflow = 'auto';
        setShowDeliveredModal(false)
    }

    const onClickToggleSplitTable = () => {
        setShowSplitTable(!showSplitTable)
    }

    const onClickShowMainView = () => {
        if (showMainOrderModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowMainOrderModal(!showMainOrderModal)
    }

    const onFinalizeReview = async () => {
        try {
            setLoadingFinalizeOrder(true)
            const response = await dispatch(tryToFinalizePurchaseReview(id || '')).unwrap();
            if (!(response?.returnItems?.length > 0)) {
                setLoadingFinalizeOrder(false)
                setFinalizeError(undefined)
                navigator(`/purchases/${purchaseDetails?.parentQuoteId}`)
            }
            setFinalizeError(undefined)
        } catch (err) {
            setFinalizeError(`${err}`)
        }
        setLoadingFinalizeOrder(false)
    }

    const deliveredDate = (purchaseDetails && purchaseDetails?.events && purchaseDetails?.events?.length > 0) ? purchaseDetails?.events?.find((item: any) => item?.message === 'PACKAGES_ADDED')?.timestamp || '' : ''
    const returnedItems = ['discrepancy']?.includes(purchaseDetails?.status || '') && purchaseDetails?.discrepancyItems && purchaseDetails?.discrepancyItems?.length > 0 ? purchaseDetails?.discrepancyItems?.filter((item: any) => ['returned', 'return']?.includes(item?.discrepancyStatus || ''))?.length || 0 : 0
    const addedToInventory = ['discrepancy']?.includes(purchaseDetails?.status || '') && purchaseDetails?.discrepancyItems && purchaseDetails?.discrepancyItems?.length > 0 ? purchaseDetails?.discrepancyItems?.filter((item: any) => ['accept', 'accepted', 'revalued', 'revalue']?.includes(item?.discrepancyStatus || ''))?.length || 0 : 0
    const expectedCredit = ['discrepancy']?.includes(purchaseDetails?.status || '') && purchaseDetails?.discrepancyItems && purchaseDetails?.discrepancyItems?.length > 0 ? purchaseDetails?.discrepancyItems?.filter((item: any) => ['returned', 'return']?.includes(item?.discrepancyStatus || ''))?.reduce((sum, item) => sum + item.price, 0) || 0 : 0
    const extraItems = ['discrepancy']?.includes(purchaseDetails?.status || '') && purchaseDetails?.discrepancyItems && purchaseDetails?.discrepancyItems?.length > 0 ? purchaseDetails?.discrepancyItems?.filter((item: any) => ['extra_item']?.includes(item?.discrepancyStatus || ''))?.length || 0 : 0
    const allItemsDone = (['discrepancy']?.includes(purchaseDetails?.status || '') && purchaseDetails?.discrepancyItems && purchaseDetails?.discrepancyItems?.length > 0) ? !(purchaseDetails?.discrepancyItems?.find((item: any) => ['extra_item', 'missing', 'not_match', 'not_matching']?.includes(item?.discrepancyStatus || ''))) : false
    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-2'}>
                <div className='flex flex-row items-center justify-between w-full'>
                    <div className='flex flex-row items-center'>
                        <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                            <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                        </div>
                        <p className='page-title'>Purchase Details</p>
                    </div>
                    <div className='flex flex-row items-center'>
                        {purchaseDetails && (['draft']?.includes(purchaseDetails?.status || '') && !purchaseDetails?.batch) &&
                            <Button
                                label={'Mark as approved'}
                                className={`${(!accessControl || pageAccess?.actions?.['Mark as approved']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as approved']) && onClickMarkAsApproved()}
                            />
                        }
                        {purchaseDetails && (['draft']?.includes(purchaseDetails?.status || '') && purchaseDetails?.batch) &&
                            <Button
                                label={'Mark as completed'}
                                className={`${(!accessControl || pageAccess?.actions?.['Mark as completed']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as completed']) && onClickMarkAsCompleted()}
                            />
                        }
                        {purchaseDetails && (['accepted']?.includes(purchaseDetails?.status || '')) &&
                            <Button
                                label={'Mark as paid'}
                                className={`${(!accessControl || pageAccess?.actions?.['Mark as paid']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as paid']) && onClickMarkAsPaid()}
                            />
                        }
                        {purchaseDetails && (['on_delivery']?.includes(purchaseDetails?.status || '')) &&
                            <Button
                                label={'Mark as delivered'}
                                className={`${(!accessControl || pageAccess?.actions?.['Mark as delivered']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as delivered']) && onClickMarkAsDelivered()}
                            />
                        }
                        {purchaseDetails && (['rma_in_progress']?.includes(purchaseDetails?.status || '')) &&
                            <Button
                                label={'Generate Label'}
                                className={`${(!accessControl || pageAccess?.actions?.['Generate Label']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Generate Label']) && onClickGenerateLabel()}
                            />
                        }
                        {purchaseDetails && (['rma_shipped']?.includes(purchaseDetails?.status || '')) &&
                            <Button
                                label={'Download Label'}
                                className={`${(!accessControl || pageAccess?.actions?.['Download Label']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Download Label']) && onClickDownloadLabel()}
                            />
                        }
                        {(purchaseDetails && !(['draft', 'on_delivery', 'accepted', 'completed', 'rma_in_progress', 'rma_shipped']?.includes(purchaseDetails?.status || ''))) &&
                            <>
                                <div className='flex flex-row'>
                                    {purchaseDetails?.reviewed &&
                                        <Button
                                            label='Split Order'
                                            className={`${!splitLoading && (!accessControl || pageAccess?.actions?.['Split Order']) ? 'btn-primary' : 'btn-primary-disable'}`}
                                            onClickButton={() => (!splitLoading && (!accessControl || pageAccess?.actions?.['Split Order'])) && onClickSplitOrder()}
                                        />
                                    }
                                    {!purchaseDetails?.reviewed &&
                                        <Button
                                            label={'Review Purchase Order'}
                                            className={['on_delivery', 'delivered', 'in_review']?.includes(purchaseDetails?.status || '') && (!accessControl || pageAccess?.actions?.['Purchase Review']) ? 'btn-primary cursor-pointer ml-3' : 'btn-primary-disable cursor-pointer ml-3'}
                                            onClickButton={() => ['on_delivery', 'delivered', 'in_review']?.includes(purchaseDetails?.status || '') && (!accessControl || pageAccess?.actions?.['Purchase Review']) && onClickPurchaseReview()}
                                        />
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <PurchaseDetailsInfo
                purchaseDetails={purchaseDetails}
                deliveredDate={deliveredDate}
                onGoToParentOrder={onGoToParentOrder}
                onClickShowMainView={onClickShowMainView}
                onSeePackagesDetails={onClickMarkAsDelivered}
            />
            {purchaseDetails && (['discrepancy']?.includes(purchaseDetails?.status || '')) ?
                <div>
                    <ReviewDetailsPurchaseTable
                        rows={purchaseDetails?.discrepancyItems || []}
                        onClickItemAccept={onClickShowAcceptModal}
                        onClickItemReturn={onClickShowReturnModal}
                        onClickItemRevalue={onClickShowRevalueModal}
                        onClickItemCredit={onClickShowAwatingCredit}
                    />
                    {finalizeError && <Error text={finalizeError} />}
                    <div className='flex flex-row justify-end items-center my-4'>
                        <p className='mr-5 text-[#00b67a]'>Added to Inventory: {addedToInventory || 0}</p>
                        <p className='mr-5 text-[#e50000]'>To be Returned: {returnedItems}</p>
                        <p className='mr-5 text-[#f7a21e]'>Expected Credit: {CURRENCY_SYMBOLS?.[`${(purchaseDetails?.discrepancyItems?.[0]?.currency || '').toLowerCase()}`]}{Number(expectedCredit || 0).toFixed(2)}</p>
                        <p className='mr-5 text-[#1389e6]'>Extra Items: {extraItems} item(s)</p>
                        <Button
                            label={'Finalize Review'}
                            className={`${allItemsDone && !loadingFinalizeOrder ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} min-w-[170px] !shadow-none`}
                            onClickButton={() => allItemsDone && !loadingFinalizeOrder && onFinalizeReview()}
                        />
                    </div>
                </div>
                : (['split_discrepancy', 'in_review']?.includes(purchaseDetails?.status || '')) ?
                    <div className='my-10'>
                        <PurchaseDetailsTable
                            showRevalued={true}
                            rows={purchaseContent || []}
                        />
                        <div className='my-5'>
                            <Button
                                label={showSplitTable ? 'Hide Items Breakdown' : 'View Items Breakdown'}
                                className='btn-primary-rounded-less'
                                onClickButton={onClickToggleSplitTable}
                            />
                        </div>
                        <div className={`${!showSplitTable ? 'hidden' : 'auto'}`}>
                            <div>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-[20px] font-bold'>Items Breakdown</p>
                                    <div className='flex flex-row items-center text-[13px]'>
                                        <p>Legend: </p>
                                        <p className='bg-[#fef6e8] p-1 mx-2 !rounded'>Expected Purchase Order Items</p>
                                        <p className='bg-[#e5f8f2] p-1 mx-2 !rounded'>Matching Items</p>
                                        <p className='bg-[#fce5e5] p-1 mx-2 !rounded'>Not Matching or Missing Items</p>
                                        <p className='bg-[#e7f3fc] p-1 mx-2 !rounded'>Extra Items</p>
                                    </div>
                                </div>
                                <SplitPurchaseTable
                                    templateFields={templateFields}
                                    categories={purchaseCategory || []}
                                    deliveredItems={purchaseDetails?.deliveredItems || []}
                                    rows={purchaseDetails?.expectedItems || []}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {((['rma_in_progress', 'rma_shipped'])?.includes(purchaseDetails?.status || '')) ?
                            <div className='my-10'>
                                <ReturnItemsTable
                                    rows={purchaseDetails?.returnedItems || []}
                                />
                            </div>
                            :
                            <div className='my-10'>
                                <PurchaseDetailsTable
                                    showRevalued={purchaseDetails?.status === 'completed'}
                                    rows={purchaseContent || []}
                                />
                            </div>
                        }
                    </>
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showAcceptItemModal &&
                <AcceptDiscrepancyItemModal
                    openModal={showAcceptItemModal}
                    loading={loadingSubmitAction}
                    onApproveAction={onAcceptItem}
                    handleCloseModal={onHideAction}
                />
            }
            {showReturnItemModal &&
                <ReturnDiscrepancyItemModal
                    openModal={showReturnItemModal}
                    loading={loadingSubmitAction}
                    onApproveAction={onReturnItem}
                    handleCloseModal={onHideAction}
                />
            }
            {showRevalueItemModal &&
                <RevalueDiscrepancyItemModal
                    openModal={showRevalueItemModal}
                    purchaseId={purchaseDetails?._id || ''}
                    itemId={selectedItemId || ''}
                    handleCloseModal={onHideAction}
                    onSuccessSave={onSuccessRevalueItem}
                />
            }
            {showCreditItemModal &&
                <AwaitingCreditModal
                    openModal={showCreditItemModal}
                    loading={loadingSubmitAction}
                    onApproveAction={onAddToAwaitingCreditItem}
                    handleCloseModal={onHideAction}
                />
            }
            {showAcceptedModal &&
                <AcceptPurchaseModal
                    openModal={showAcceptedModal}
                    loading={loadingActionSubmit}
                    handleCloseModal={() => setShowAcceptedModal(false)}
                    onApproveAction={onAcceptedPurchaseOrder}
                />
            }
            {showCompletedModal &&
                <BatchingCompleteModal
                    openModal={showCompletedModal}
                    purchaseId={purchaseDetails?._id || ''}
                    quoteId={purchaseDetails?.quoteId || ''}
                    selectedOrderStatus={'on_delivery'}
                    expectedItems={purchaseDetails?.expectedItems}
                    dueDate={state?.purchaseDetails?.purchaseInfo?.dueDate}
                    handleCloseModal={onClickCloseConfirm}
                />
            }
            {showGenerateLabelModal &&
                <GenerateReturnLabelModal
                    openModal={showGenerateLabelModal}
                    category={purchaseDetails?.categories?.[0]?.code}
                    currency={purchaseDetails?.currency}
                    returnedItems={purchaseDetails?.returnedItems}
                    purchaseId={purchaseDetails?._id || ''}
                    customerId={purchaseDetails?.purchaseInfo?.customer?.id || ''}
                    handleCloseModal={onClickGenerateLabel}
                    onApproveAction={onSuccessGenerate}
                />
            }
            {showPaidModal &&
                <PaidPurchaseModal
                    openModal={showPaidModal}
                    loading={loadingActionSubmit}
                    dueDate={state?.purchaseDetails?.purchaseInfo?.dueDate}
                    handleCloseModal={onClosePaidModal}
                    onApproveAction={onPaidPurchaseOrder}
                />
            }
            {showDeliveredModal &&
                <PurchasePackagesModal
                    openModal={showDeliveredModal}
                    purchaseId={purchaseDetails?._id || ''}
                    quoteId={purchaseDetails?.quoteId || ''}
                    selectedOrderStatus={'on_delivery'}
                    expectedItems={purchaseDetails?.expectedItems || []}
                    handleCloseModal={onClosePackagesModal}
                    onApproveAction={onSuccessSavedPackageBoxes}
                />
            }
            {showMainOrderModal &&
                <MainOrderModal
                    openModal={showMainOrderModal}
                    id={id || ''}
                    categories={purchaseCategory}
                    handleCloseModal={onClickShowMainView}
                />
            }
        </div >
    )
}

export default PurchaseDetails;