import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import { CREDIT_NOTES_FILTERS } from '../../../../utils/constants/credit-notes-filters';
import { DISCREPANCY_SOURCES } from '../../../../utils/constants/discrepancy-source';


interface ICreditNoteFilter {
    activeFilter: string;
    brokerCustomers?: Array<any>;
    brokerCategories?: Array<any>;
    customerSelected?: any;
    selectedSource?: any;
    selectedCategory?: any;
    onChangeFilterSelected: (value: any, type?: string) => void;
    onClickActiveFilter: (item: string) => void;
}

const CreditNoteFilter = ({
    activeFilter,
    brokerCustomers,
    brokerCategories,
    customerSelected,
    selectedSource,
    selectedCategory,
    onChangeFilterSelected,
    onClickActiveFilter
}: ICreditNoteFilter) => {
    return (
        <>
            <div className='my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4'>
                {CREDIT_NOTES_FILTERS?.map((item: { label: string, value: string }, idx: number) => {
                    return (
                        <Button
                            key={idx}
                            label={item?.label}
                            className={`box-border ${activeFilter === item?.value ? 'btn-primary-rounded-less' : 'btn-noactive-categories border border-2 border-[#484a4b] !text-[#484a4b] !rounded hover:bg-gradient-to-br hover:from-[#FCEE21] hover:to-[#F7931E] hover:text-black hover:border-none'}`}
                            onClickButton={() => onClickActiveFilter(item?.value)}
                        />
                    )
                })}
            </div>
            <div className='mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Customer'
                    uniqueName='customer'
                    placeholder=' '
                    options={brokerCustomers || []}
                    selectedOption={customerSelected}
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <SelectCheckbox
                    name='Discrepancy Source'
                    uniqueName='source'
                    placeholder=' '
                    options={DISCREPANCY_SOURCES}
                    selectedOption={selectedSource}
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <SelectCheckbox
                    name='Category'
                    uniqueName='category'
                    placeholder=' '
                    options={brokerCategories || []}
                    selectedOption={selectedCategory}
                    onChangeSelectedOption={onChangeFilterSelected}
                />
            </div>
        </>
    )
}
export default CreditNoteFilter;