import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddPurchasePackages,
    tryToFetchPurchasePackages,
    tryToGetPurchaseUuid
} from '../../../../store/inventory/broker/purchases/purchasesSlice';
import { tryToFetchAllWarehouseSections } from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import PrintLabel from '../print-label/PrintLabel';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IPurchasePackagesModal {
    openModal: boolean;
    purchaseId: string;
    quoteId: string;
    selectedOrderStatus: string;
    expectedItems: any;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const PurchasePackagesModal = ({
    openModal,
    purchaseId,
    quoteId,
    selectedOrderStatus,
    expectedItems,
    handleCloseModal,
    onApproveAction,
}: IPurchasePackagesModal) => {
    const dispatch = useAppDispatch();
    const [sections, setSections] = useState<any>();
    const [packages, setPackages] = useState<any>([{}]);
    const [justView, setJustView] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [generalError, setGeneralError] = useState<string>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    const onGettingOrderPackages = async () => {
        try {
            const response = await dispatch(tryToFetchPurchasePackages(purchaseId)).unwrap()
            setPackages(response?.packages || [{}])
            setJustView((response?.packages?.length > 0) || false)
        } catch (err) {
            // err here
            setJustView(false)
        }
        setLoading(false)
    }

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: activeWarehouse?._id || '' })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    const onGettingUUID = async () => {
        try {
            const response = await dispatch(tryToGetPurchaseUuid(null)).unwrap();
            return response
        } catch (err) {
            // error here
        }
        return undefined
    }

    useEffect(() => {
        setLoading(true)
        onGettingOrderPackages()
        onGettingWarehouseSections()
    }, [purchaseId, openModal])

    const onCloseModal = () => {
        setGeneralError(undefined)
        setLoading(false)
        setSubmitLoading(false)
        setPackages([{}])
        handleCloseModal()
    }

    const onSubmit = async () => {
        setSubmitLoading(true)
        let error = ''
        if (!packages || packages?.length <= 0) {
            setGeneralError('Please add at least one row to continue')
            setSubmitLoading(false)
            return;
        }
        if (packages && packages?.length > 0) {
            packages?.forEach((item: any) => {
                if (!item?.name || !item?.space || (!item?.nrOfItems && item?.nrOfItems !== 0)) {
                    error = 'Please fill all the details to continue '
                }
            })
            if (error) {
                setSubmitLoading(false)
                setGeneralError(error)
                return;
            }
        }
        setGeneralError(undefined)
        try {
            const formBody = {
                totalPackages: packages?.length || 0,
                packages: (packages && packages?.length > 0) ?
                    packages?.map((item: any, index: number) => {
                        return {
                            id: item?.id,
                            orderNumber: index + 1,
                            spaceId: item?.space?.value,
                            name: item?.name,
                            nrOfItems: item?.nrOfItems
                        }
                    })
                    : []
            }
            await dispatch(tryToAddPurchasePackages({ id: purchaseId, data: formBody, status: selectedOrderStatus })).unwrap()
            setGeneralError(undefined)
            onApproveAction()
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setSubmitLoading(false)
    }

    const onChangePackageDetailsInput = (e: any, type?: string, index?: any) => {
        if (type && (index || index === 0)) {
            const formatPackages = (packages && packages?.length > 0) ? packages?.map((item: any, idx: number) => {
                if (index === idx) {
                    return {
                        ...item,
                        id: type === 'name' ? undefined : item?.id,
                        [type]: e
                    }
                } else {
                    return item
                }
            }) : [{ [type]: e }]
            setPackages(formatPackages || [{}])
        }
    }

    const onAddPackage = async (packageName: string, packageSpace: any, idx: number) => {
        if (!packageName || !packageSpace) {
            setGeneralError('Package name and Section is required!')
            return;
        }
        const findPackageName = packages && packages?.length > 0 && packages?.find((item: any, index: number) => (item?.name === packageName) && (index !== idx))
        if (findPackageName) {
            setGeneralError('Package name should be unique')
            return;
        }
        setGeneralError(undefined)
        const uuid = await onGettingUUID()
        const formatPackages = packages && packages?.length > 0 && packages?.map((item: any) => {
            if (item?.name === packageName) {
                return {
                    ...item,
                    id: uuid,
                }
            } else {
                return item
            }
        })
        setPackages(formatPackages)
    }

    useEffect(() => {
        if (packages && packages?.length > 0) {
            packages?.forEach((item: any, idx: number) => {
                if (item?.name && item?.space && !item?.id) {
                    onAddPackage(item?.name, item?.space, idx)
                }
            })
        }
    }, [packages])

    const onDeletePackage = (id: string) => {
        setPackages((prevPackages: any) => prevPackages?.filter((item: any) => (item?.id !== id)) || [{}])
    }

    const onAddRow = () => {
        setPackages([
            ...packages || [],
            {}
        ])
    }

    const numberOfPackages = packages.reduce((accumulator: number, item: any) => accumulator + Number(item.nrOfItems || 0), 0)
    const expectedItemsNumber = expectedItems.reduce((accumulator: number, item: any) => accumulator + Number(item.qty || 0), 0)
    return (
        <Modal
            open={openModal}
            showInRight
            onClose={() => onCloseModal()}
            title='Locations'
            contentContainerStyle='!min-w-[70vw]'>
            <div className={'p-2 min-w-[65vw] min-h-[300px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{justView ? 'You received' : 'What did you receive?'}</p>
                </div>
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded-[6px]'>
                    {(!loading) &&
                        <>
                            <Table
                                hidePagination={true}
                                containerStyle='min-h-[150px] !pb-[70px]'
                                columns={['NR', 'Type of Container', 'Section', 'Nr. of items ', 'Label', 'ID', 'Delete']}>
                                {(packages && packages?.length > 0 ? packages : [{}]).map((obj: any, idx: number) => {
                                    return (
                                        <>
                                            <tr key={idx} className={`bg-[#f8f9fc] border-b hover:bg-gray-50`}>
                                                <td className='px-6 py-2 align-middle'>
                                                    {idx + 1}
                                                </td>
                                                <td className='px-6 py-2 align-middle max-w-[140px]'>
                                                    {justView ?
                                                        <p>{obj?.name}</p>
                                                        : <Input
                                                            placeholder='Name'
                                                            showValue={true}
                                                            containerStyle='mt-1 max-w-[140px]'
                                                            inputUniqueName='name'
                                                            inputValue={obj?.name}
                                                            index={idx}
                                                            onChangeInput={onChangePackageDetailsInput}
                                                        />
                                                    }
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    {justView ?
                                                        <p>{obj?.space?.name}</p>
                                                        : <SelectCheckbox
                                                            placeholder='Section'
                                                            selectedOption={obj?.space}
                                                            options={sections}
                                                            containerStyle='!col-span-2'
                                                            onChangeSelectedOption={onChangePackageDetailsInput}
                                                            uniqueName='space'
                                                            index={idx}
                                                        />
                                                    }
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    {justView ?
                                                        <p>{obj?.nrOfItems}</p>
                                                        : <Input
                                                            placeholder=' '
                                                            showValue={true}
                                                            containerStyle='mt-1 max-w-[100px]'
                                                            inputUniqueName='nrOfItems'
                                                            inputValue={obj?.nrOfItems}
                                                            index={idx}
                                                            onChangeInput={onChangePackageDetailsInput}
                                                        />
                                                    }
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    {obj?.id &&
                                                        <PrintLabel
                                                            barcode={obj?.id}
                                                            orderId={quoteId}
                                                        />
                                                    }
                                                </td>
                                                <td className='px-6 py-2 align-middle w-[180px] max-w-[180px]'>
                                                    <div className='flex flex-row items-center cursor-pointer' onClick={() => obj?.id && navigator?.clipboard?.writeText(obj?.id)}>
                                                        {obj?.id &&
                                                            <div className='cursor-pointer'>
                                                                <img title={'Copy Quote ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[22px] min-h-[22px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                                            </div>
                                                        }
                                                        <p className='cut-text'>{obj?.id || ''}</p>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    <div className='cursor-pointer' onClick={() => onDeletePackage(obj?.id)}>
                                                        <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                    </div>
                                                </td>
                                            </tr>
                                            {(packages && packages?.length > 0 && ((idx + 1) === packages?.length) && !justView) &&
                                                <tr className='py-4 px-2'>
                                                    <td colSpan={3} className='py-3'>
                                                        <Button
                                                            label={'Add Row'}
                                                            className='min-w-[120px] btn-primary'
                                                            onClickButton={onAddRow}
                                                        />
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })}
                            </Table >
                        </>
                    }
                </div>
                <div className='flex flex-col items-center py-2 bg-[#f8f9fc]'>
                    <div className='bg-[#ffffff] w-[80%] rounded-[6px] py-4 px-3 flex flex-row justify-center mb-2'>
                        <p>Expecting: <span className='font-bold'>{expectedItemsNumber}</span></p>
                        {(numberOfPackages && packages && packages?.length > 0) ? <p className='ml-4 text-[#00b67a]'>Received: <span className='font-bold'>{numberOfPackages}</span></p> : ''}
                        {(numberOfPackages && packages && packages?.length > 0) ? <p className='ml-4 text-[#e50000]'>Missing: <span className='font-bold'>{(expectedItemsNumber - numberOfPackages) >= 0 ? expectedItemsNumber - numberOfPackages : 0}</span></p> : ''}
                    </div>
                    <Button
                        label='Confirm'
                        dataQa={'continue-button'}
                        className={submitLoading || loading || !(packages?.length > 0) || justView ? 'btn-primary-disable min-w-[150px]' : 'btn-primary min-w-[150px]'}
                        onClickButton={() => !submitLoading && !loading && (packages?.length > 0) && !justView && onSubmit()}
                    />
                </div>
            </div>
        </Modal >
    )
}
export default PurchasePackagesModal;