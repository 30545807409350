import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ICrm } from '../../../../interfaces/crm-data/ICrm';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import {
    tryToDeleteCrmData,
    tryToFetchCrmData
} from '../../../../store/inventory/broker/crm-data/crmDataSlice';
import ManageBankAccountModal from '../modals/ManageBankAccountModal';
import CrmDataTable from '../tables/CrmDataTable';
import EmptyContainer from '../../../../shared/empty-container';
import DeleteModal from '../../../../shared/delete-modal';
import Loading from '../../../../shared/Loading';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';
import Input from '../../../../shared/input';


const BankAccounts = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<ICrm[]>([]);
    const [search, setSearch] = useState<string>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [crmItemId, setCrmItemId] = useState<string | null>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showCrmItemModal, setShowCrmItemModal] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const paginationData = useAppSelector((state) => state.crm.crm?.data?.page);
    const rowsLoading = useAppSelector((state) => state.crm.crmAreLoading);
    const state = useAppSelector((state) => state.crm);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Profile Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.crm) {
            const rows =
                state.crm?.data?.elements &&
                    Array.isArray(state.crm?.data.elements)
                    ? state.crm?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.crm]);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    const onGettingCrmData = async () => {
        let formatData: any = {
            ...paginationState || {},
            data: {
                type: 'bank_account'
            }
        }
        if (search && search !== '') {
            formatData = {
                ...formatData || {},
                data: {
                    ...formatData?.data || {},
                    filters: {
                        search
                    }
                }
            }
        }
        if (formatData) {
            dispatch(tryToFetchCrmData(formatData));
        }
    }

    useEffect(() => {
        onGettingCrmData()
    }, [paginationState]);

    const onAddNewBankAccount = () => {
        setShowCrmItemModal(true)
        setCrmItemId(null)
    }

    const onDeleteCrmData = async () => {
        try {
            await dispatch(tryToDeleteCrmData(crmItemId || ''));
            setShowToast({
                type: 'success',
                message: 'Bank Account successfully deleted',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setCrmItemId(undefined);
        setShowDeleteModal(false);
    };

    const onEditCrmData = (id: string) => {
        setCrmItemId(id)
        setShowCrmItemModal(true)
    }

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setCrmItemId(null)
    };

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        setCrmItemId(null)
    }

    const onDeleteAction = (id: string) => {
        setShowDeleteModal(true)
        setCrmItemId(id)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationState()
    }

    return (
        <div>
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Add New Bank Account'}
                    className={(!accessControl || pageAccess?.actions?.['Add New Bank Account']) ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Add New Bank Account']) && onAddNewBankAccount()}
                />
            </div>
            <div className='my-5 relativ'>
                <div className={'min-w-[100px]'}>
                    <Input
                        placeholder={'Filter Table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                {rowsLoading ? (
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                ) : (
                    <>
                        {rows?.length > 0 ? (
                            <CrmDataTable
                                rows={rows}
                                paginationData={paginationData}
                                accessEdit={(!accessControl || pageAccess?.actions?.['Edit Bank Account'])}
                                accessDelete={(!accessControl || pageAccess?.actions?.['Delete Bank Account'])}
                                setPaginationState={setPaginationState}
                                onDeleteData={onDeleteAction}
                                onEditData={onEditCrmData}
                            />
                        ) : (
                            <EmptyContainer
                                text={'No Bank Account added yet.'}
                                showImage={true}
                            />
                        )}
                    </>
                )}
            </div>
            {showCrmItemModal &&
                <ManageBankAccountModal
                    openModal={showCrmItemModal}
                    handleCloseModal={() => setShowCrmItemModal(false)}
                    onSaveChanges={onSaveChanges}
                    crmItemId={crmItemId}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onDeleteCrmData}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default BankAccounts;