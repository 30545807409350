import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { AxiosError } from 'axios';
import { ReportsService } from './reportsServiceApi';
import { IReportsListState } from '../../../../interfaces/reports/IReportsList';
import { PaginatedReports } from '../../../../interfaces/reports/paginatedStore.type';


const initialState: IReportsListState = {
    salesAreLoading: false,
    inventoriesAreLoading: false,
    purchasesAreLoading: false,
    salesHistoryAreLoading: false,
    saleHistoryIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchSales = createAsyncThunk<PaginatedReports, IPaginationPayload>(
    'reports/tryToFetchSales',
    async ({ pageNumber, pageSize, data, filters }) => {
        const result = await ReportsService.tryToFetchSales(
            pageNumber,
            pageSize,
            data,
            filters
        );
        return result?.data;
    }
)

export const tryToFetchPurchases = createAsyncThunk<PaginatedReports, IPaginationPayload>(
    'reports/tryToFetchPurchases',
    async ({ pageNumber, pageSize, data, filters }) => {
        const result = await ReportsService.tryToFetchPurchases(
            pageNumber,
            pageSize,
            data,
            filters
        );
        return result?.data;
    }
)

export const tryToFetchInventories = createAsyncThunk<PaginatedReports, IPaginationPayload>(
    'reports/tryToFetchInventories',
    async ({ pageNumber, pageSize, data, filters }) => {
        const result = await ReportsService.tryToFetchInventories(
            pageNumber,
            pageSize,
            data,
            filters
        );
        return result?.data;
    }
)

export const tryToFetchAllSalesHistory = createAsyncThunk<
    PaginatedReports,
    IPaginationPayload
>('reports/tryToFetchAllSalesHistory', async ({ pageNumber, pageSize, filters }) => {
    const result = await ReportsService.tryToFetchAllSalesHistory(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToDownloadSales = createAsyncThunk<any, any>(
    'reports/tryToDownloadSales',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadSales(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadReportSheetInventories = createAsyncThunk<any, any>(
    'reports/tryToDownloadReportSheetInventories',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadReportSheetInventories(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadReportSheetSales = createAsyncThunk<any, any>(
    'reports/tryToDownloadReportSheetSales',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadReportSheetSales(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadReportSheetPurchases = createAsyncThunk<any, any>(
    'reports/tryToDownloadReportSheetPurchases',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadReportSheetPurchases(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToFetchSingleSaleHistory = createAsyncThunk<PaginatedReports, IPaginationPayload>(
    'reports/tryToFetchSingleSaleHistory',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await ReportsService.tryToFetchSingleSaleHistory(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToDownloadSalesSample = createAsyncThunk<any, any>(
    'reports/tryToDownloadSalesSample',
    async () => {
        const result = await ReportsService.tryToDownloadSalesSample();
        return result?.data;
    }
)

export const tryToDownloadPurchases = createAsyncThunk<any, any>(
    'reports/tryToDownloadPurchases',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadPurchases(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadCashouts = createAsyncThunk<any, any>(
    'reports/tryToDownloadCashouts',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadCashouts(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadInventories = createAsyncThunk<any, any>(
    'reports/tryToDownloadInventories',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadInventories(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToImportSales = createAsyncThunk<any, any>(
    'reports/tryToImportSales',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToImportSales(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadWholesalePriceBuy = createAsyncThunk<any, any>(
    'reports/tryToDownloadWholesalePriceBuy',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadWholesalePriceBuy(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadWholesalePriceSell = createAsyncThunk<any, any>(
    'reports/tryToDownloadWholesalePriceSell',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadWholesalePriceSell(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadPartnersReport = createAsyncThunk<any, any>(
    'reports/tryToDownloadPartnersReport',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadPartnersReport(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)
export const tryToDownloadPricingReport = createAsyncThunk<any, any>(
    'reports/tryToDownloadPricingReport',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadPricingReport(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)
export const tryToDownloadThings = createAsyncThunk<any, any>(
    'reports/tryToDownloadThings',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadThings();
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)


export const tryToDownloadAggregatedInventories = createAsyncThunk<any, any>(
    'reports/tryToDownloadAggregatedInventories',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadAggregatedInventories(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToDownloadAggregatedPurchases = createAsyncThunk<any, any>(
    'reports/tryToDownloadAggregatedPurchases',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToDownloadAggregatedPurchases(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToFetchForGivenDate = createAsyncThunk<any, any>(
    'reports/tryToFetchForGivenDate',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await ReportsService.tryToFetchForGivenDate(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSales
            .addCase(tryToFetchSales.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToFetchSales.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToFetchSales.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.sales = action.payload;
            })

            // tryToFetchPurchases
            .addCase(tryToFetchPurchases.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToFetchPurchases.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToFetchPurchases.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchases = action.payload;
            })

            // tryToFetchInventories
            .addCase(tryToFetchInventories.pending, (state) => {
                state.inventoriesAreLoading = true;
            })
            .addCase(tryToFetchInventories.rejected, (state) => {
                state.inventoriesAreLoading = false;
            })
            .addCase(tryToFetchInventories.fulfilled, (state, action) => {
                state.inventoriesAreLoading = false;
                state.inventories = action.payload;
            })

            // tryToFetchAllSalesHistory
            .addCase(tryToFetchAllSalesHistory.pending, (state) => {
                state.salesHistoryAreLoading = true;
            })
            .addCase(tryToFetchAllSalesHistory.rejected, (state) => {
                state.salesHistoryAreLoading = false;
            })
            .addCase(tryToFetchAllSalesHistory.fulfilled, (state, action) => {
                state.salesHistoryAreLoading = false;
                state.salesHistory = action.payload;
            })


            // tryToFetchSingleSaleHistory
            .addCase(tryToFetchSingleSaleHistory.pending, (state) => {
                state.saleHistoryIsLoading = true;
            })
            .addCase(tryToFetchSingleSaleHistory.rejected, (state) => {
                state.saleHistoryIsLoading = false;
            })
            .addCase(tryToFetchSingleSaleHistory.fulfilled, (state, action) => {
                state.saleHistoryIsLoading = false;
                state.saleHistoryDetail = action.payload;
            })
    },
});

export default reportsSlice.reducer;