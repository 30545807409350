import React from 'react';

interface ILayoutRegister {
    children?: React.ReactNode
}

const LayoutRegister = ({
    children
}: ILayoutRegister) => {
    return (
        <div className='grid grid-cols-7 gap-4 min-w-full min-h-full'>
            <div className='col-span-4 bg-red h-screen sticky top-0 max-w-full'>
                <img alt='register process' src={'/assets/auth/register-img.png'} className={'h-full w-full object-cover relative'}/>
            </div>
            <div className='col-span-3 bg-blue min-h-full p-6'>
                {children}
            </div>
        </div>
    )
}

export default LayoutRegister;