import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { UserPassPayload } from '../../../../interfaces/auth/IUser';
import { ErrorPayload } from '../../../../interfaces/auth/IErrorPayload';
import { useLoginMutation } from '../../../../store/auth/authApi';
import { tryToAuthorizeUser } from '../../../../store/inventory/shared/authorization/authorizationSlice';
import Input from '../../../../shared/input';
import Loading from '../../../../shared/Loading';


const LoginForm = () => {
    const dispatch = useAppDispatch()
    const { register, handleSubmit, formState: { errors } } = useForm<UserPassPayload>();
    const [loginMutation, { isLoading, error }] = useLoginMutation();
    const [errorAuthorize, setErrorAuthorize] = useState<string>()
    const navigate = useNavigate();

    const onSubmit = async (payload: UserPassPayload) => {
        const { data } = await loginMutation(payload).unwrap();
        const roles = data.servicePayload.accessToken.payload['cognito:groups'];
        localStorage.setItem('accessToken', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('cgToken', data?.servicePayload?.accessToken?.jwtToken || '')
        localStorage.setItem('roles', roles?.[0] || '');
        localStorage.removeItem('user')
        localStorage.removeItem('broker')
        if (roles?.[0] !== 'admins') {
            const response = await dispatch(tryToAuthorizeUser({ dashboard: 'inventory' })).unwrap()
            if (!response) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('roles')
                setErrorAuthorize('You are not authorized to access this dashboard')
                return;
            }
        }
        navigate(roles?.[0] === 'admins' ? '/sales-admin' : '/inventory');

    }
    const err = { ...error as ErrorPayload };
    const errorMsg = err.status === 401 ? 'Username or password are incorrect' : 'Something went wrong';
    return (
        <>
            <form className='mt-8 mb-5' onSubmit={handleSubmit(onSubmit)}>
                {(!!err.data || errorAuthorize) &&
                    <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'>
                        <span className='font-medium'> {errorAuthorize || err.data.message || errorMsg} </span>
                    </div>
                }
                <Input
                    placeholder='Enter username or email address'
                    dataQa='username-login'
                    register={register('username', {
                        required: {
                            message: 'Username is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Username is required'
                    })}
                    error={errors.username?.message}
                />
                <Input
                    placeholder='Password'
                    type='password'
                    dataQa='password-login'
                    register={register('password', {
                        required: {
                            message: 'Password is required',
                            value: true,
                        },
                    })}
                    error={errors.password?.message}
                />
                <button data-qa={'submit-login'} className='mt-1 w-full justify-center flex btn-primary !py-2 px-5 text-white rounded !font-semibold' type='submit'>
                    {isLoading && <Loading />}
                    Login
                </button>
            </form>
        </>
    )
}

export default LoginForm;