import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchAssetsTemplates } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import SaleAssetsTable from './tables/SaleAssetsTable';
import EmptyContainer from '../../../shared/empty-container';


const SaleAssets = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<IAssetTemplate[]>([]);
    const [pageAccess, setPageAccess] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const paginationData = useAppSelector((state) => state.assetsTemplates?.assetTemplates?.data?.page);
    const rowsLoading = useAppSelector((state) => state.assetsTemplates?.assetsTemplatesAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const state = useAppSelector((state) => state.assetsTemplates);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.assetTemplates) {
            const rows =
                state.assetTemplates?.data?.elements && Array.isArray(state.assetTemplates?.data.elements)
                    ? state.assetTemplates?.data.elements.map((r) => ({ ...r, id: r._id }))
                    : [];
            setRows(rows);
        }
    }, [state.assetTemplates]);

    useEffect(() => {
        dispatch(tryToFetchAssetsTemplates(paginationState));
    }, [paginationState]);

    const onSeeFieldDetails = async (id: string) => {
        navigate(`/sales-admin/${id}`);
    }

    return (
        <div>
            <div>
                <p className='page-title'>Sales Asset Catalogs</p>
            </div>
            <div className='my-5 relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <SaleAssetsTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onSeeFieldDetails={onSeeFieldDetails}
                    />
                    : <EmptyContainer text={'No asset catalogs added yet.'} showImage={true} />
                }
            </div>
        </div>
    )
}

export default SaleAssets;