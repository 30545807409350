import React, { useState } from 'react';
import moment from 'moment';
import Datepicker from '../../../../shared/datepicker';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IPaidPurchaseModal {
    openModal: boolean;
    dueDate?: string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: (date: string) => void;
}

const PaidPurchaseModal = ({
    openModal,
    dueDate,
    loading,
    handleCloseModal,
    onApproveAction,
}: IPaidPurchaseModal) => {
    const [dueDateNew, setDueDateNew] = useState<any>(moment(dueDate).format('YYYY-MM-DD'))

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDueDateNew(value)
    }

    return (
        <Modal
            open={openModal}
            showInRight
            onClose={() => handleCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Paid Purchase</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='my-2 font-bold'>Are you sure you want to Mark this Purchase as Paid?</p>
                    <div className='my-5'>
                        <Datepicker
                            label='Due Date'
                            placeholder=' '
                            containerStyle='mb-0'
                            inputValue={dueDateNew || undefined}
                            onChangeInput={onChangeDateFilters}
                            inputUniqueName={'dueDate'}
                        />
                    </div>
                    <div className='flex flex-row justify-center my-2'>
                        <Button
                            label='Confirm'
                            dataQa={'continue-button'}
                            className={(loading || !dueDateNew) ? 'btn-primary-disable min-w-[150px]' : 'btn-primary min-w-[150px]'}
                            onClickButton={() => !loading && dueDateNew && onApproveAction(dueDateNew)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default PaidPurchaseModal;