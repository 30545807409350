import React, { useState, useEffect } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { ICreditNotes } from '../../../../interfaces/credit-notes/ICreditNotes';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface ICreditNotesTable {
    rows: ICreditNotes[];
    rowsLoading: boolean;
    sourceSelected?: any;
    activeFilter?: string;
    brokerCategories?: Array<any>;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    onClickDownloadCSV: (items: any) => void;
    onClickStatusChange: (id: string, type: string, data?: any) => void;
    onClickShowNote: (orderId: string) => void;
    onSeeCreditNoteDetails: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const CreditNotesTable = ({
    rows,
    activeFilter,
    brokerCategories,
    sourceSelected,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    onClickShowNote,
    setPaginationState,
    onClickDownloadCSV,
    onClickStatusChange,
    onSeeCreditNoteDetails
}: ICreditNotesTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Customer', 'Order Type', 'Order ID', 'Category', 'Nr. of Items', 'Credit Amount', 'Action'])

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    useEffect(() => {
        setTableLoading(true)
        if (activeFilter === 'awaiting_credit') {
            setTableColumns(['Customer', 'Order Type', sourceSelected?.value === 'purchase' ? 'Purchase Order ID' : sourceSelected?.value === 'sales' ? 'Sale Order ID' : 'Order ID', 'Category', 'Nr. of Items', 'Credit Amount', 'Action'])
        }
        else if (activeFilter === 'received_credit') {
            setTableColumns(['Credit Note Number', 'Reference Invoice Number', 'Customer', 'Order Type', sourceSelected?.value === 'purchase' ? 'Purchase Order ID' : sourceSelected?.value === 'sales' ? 'Sale Order ID' : 'Order ID', 'Category', 'Nr. of Items', 'Credit Amount', 'Action'])
        }
        else if (activeFilter === 'used_credit') {
            setTableColumns(['Credit Note Number', 'Reference Invoice Number', 'Customer', 'Order Type', sourceSelected?.value === 'purchase' ? 'Purchase Order ID' : sourceSelected?.value === 'sales' ? 'Sale Order ID' : 'Order ID', 'Category', 'Nr. of Items', 'Credit Amount', 'Action'])
        }
        setTableLoading(false)
    }, [rows, sourceSelected, activeFilter, paginationData])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {(!tableColumns || tableLoading || rowsLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: ICreditNotes, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {['received_credit', 'used_credit']?.includes(activeFilter || '') &&
                                    <>
                                        <td className='px-6 py-4'>{row?.creditNoteNumber}</td>
                                        <td className='px-6 py-4'>{row?.referenceInvoiceNumber}</td>
                                    </>
                                }
                                <td className='px-6 py-4'>{row?.customer?.displayName || ''}</td>
                                <td className='px-6 py-4'>{row?.reference && capitalizeFirstLetter(row?.reference)}</td>
                                <td className='px-6 py-4'>{row?.referenceId}</td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        {(row?.categories && row?.categories?.length > 0 && brokerCategories && brokerCategories?.length > 0) && row?.categories?.map((item: any) => {
                                            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category: any) => category?.id === item?.id)
                                            return (
                                                <img src={findCategory?.enabledIcon} className='object-contain w-[20px] min-w-[20px] mr-2' key={item?.id} />
                                            )
                                        })}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.items?.length || 0}</td>
                                <td className={`px-6 py-4 min-w-[120px] ${row?.amount && row?.amount > 0 ? 'text-[#00b67a]' : 'text-[#e50000]'}`}>
                                    {(row?.amount && row?.amount > 0) ? '+' : row?.amount === 0 ? '' : '-'}{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{(Math.abs(row?.amount || 0)).toFixed(2)}
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => row?._id && (!accessControl || actions?.['See Credit Note Details']) && onSeeCreditNoteDetails(row?._id)}>
                                            <img title={'See Credit Note Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`w-[20px] min-w-[20px] mb-3 object-contain ${(!accessControl || actions?.['See Credit Note Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                        {activeFilter !== 'used_credit' &&
                                            <TableActions>
                                                {activeFilter === 'awaiting_credit' &&
                                                    <div
                                                        onClick={() => (!accessControl || actions?.['Mark as Received']) && onClickStatusChange(row?._id || '', 'received', row)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as Received'}
                                                            dataQa={'received'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Mark as Received']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                {activeFilter === 'received_credit' &&
                                                    <div
                                                        onClick={() => (!accessControl || actions?.['Mark as Used']) && onClickStatusChange(row?._id || '', 'used')}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as Used'}
                                                            dataQa={'used'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Mark as Used']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Leave a Note']) && row?._id) && onClickShowNote(row?._id || '')}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Leave a Note'}
                                                        dataQa={'leave-note'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Leave a Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Download CSV']) && row?._id) && onClickDownloadCSV(row?.items)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Download CSV'}
                                                        dataQa={'download-csv'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Download CSV']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            </TableActions>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default CreditNotesTable;