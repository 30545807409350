import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import {
    onFormatSaleItemStatus,
    onFormatSalesStatus
} from '../../../../utils/constants/sale-details-statuses';
import { tryToRequestSaleItems } from '../../../../store/inventory/shared/sales/salesSlice';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';


interface IRequestModal {
    openModal: boolean;
    prevRequestedItems?: any;
    saleId: string;
    itemDetailsInfo?: any;
    missingItems: number;
    categories?: any;
    review: boolean;
    replace?: boolean;
    handleCloseModal: () => void;
    onApproveAction: (data?: any, oldData?: any, item?: any, replace?: boolean) => void;
}

const RequestModal = ({
    openModal,
    prevRequestedItems,
    saleId,
    itemDetailsInfo,
    missingItems,
    categories,
    review,
    replace,
    handleCloseModal,
    onApproveAction,
}: IRequestModal) => {
    const dispatch = useAppDispatch();
    const [selectedItems, setSelectedItems] = useState<any>();
    const [ordersResponse, setOrdersResponse] = useState<any>();
    const [loadingLoadMore, setLoadingLoadMore] = useState<boolean>(false);

    const onGettingData = async (loadMore?: boolean, orderId?: string) => {
        setLoadingLoadMore(true)
        try {
            let formatData: any = {
                itemRequested: itemDetailsInfo,
                itemNr: missingItems
            }
            if (loadMore) {
                formatData = {
                    ...formatData || {},
                    loadMore: true
                }
            }
            if (orderId) {
                formatData = {
                    ...formatData || {},
                    orderId: orderId
                }
            }
            if (prevRequestedItems) {
                formatData = {
                    ...formatData || {},
                    requestedItems: prevRequestedItems
                }
            }
            const res = await dispatch(tryToRequestSaleItems({ id: saleId || '', data: formatData })).unwrap()
            setOrdersResponse(res)
        } catch (err) {
            // error here
        }
        setLoadingLoadMore(false)
    }

    useEffect(() => {
        if (prevRequestedItems) {
            setSelectedItems(prevRequestedItems)
        }
        onGettingData()
    }, [prevRequestedItems, itemDetailsInfo])

    const onCloseModal = () => {
        setSelectedItems(undefined);
        setOrdersResponse(undefined);
        setLoadingLoadMore(false);
        handleCloseModal();
    }

    const onConfirm = () => {
        onApproveAction(selectedItems, prevRequestedItems, itemDetailsInfo, replace)
        onCloseModal()
    }

    const onSelectItem = (item: any, orderId: string) => {
        const findIfInSelected = selectedItems && selectedItems?.length > 0 && selectedItems?.find((selected: any) => selected?.saleOrderId === orderId && selected?.itemId === item?.itemId && selected?.status === item?.status)
        if (findIfInSelected) {
            const filterSelected = selectedItems && selectedItems?.length > 0 && selectedItems?.filter((selected: any) => !(selected?.saleOrderId === orderId && selected?.itemId === item?.itemId && selected?.status === item?.status))
            setSelectedItems(filterSelected)
        } else {
            setSelectedItems((prevSelectedItems: any) => [
                ...prevSelectedItems || [],
                {
                    ...item || {},
                    match: {
                        descriptor: itemDetailsInfo?.descriptor,
                        inventory: itemDetailsInfo?.inventory,
                        price: itemDetailsInfo?.price
                    },
                    saleOrderId: orderId
                }
            ])
        }
    }

    const keysDescriptor = itemDetailsInfo && itemDetailsInfo?.descriptor && Object?.keys(itemDetailsInfo?.descriptor)
    const keysInventory = itemDetailsInfo && itemDetailsInfo?.inventory && Object?.keys(itemDetailsInfo?.inventory)
    const keys = [...keysDescriptor || [], ...keysInventory || []]
    return (
        <Modal
            open={openModal}
            showInRight
            onClose={() => onCloseModal()}
            title={review ? 'Request' : 'Review'}
            contentContainerStyle='!min-w-[70vw]'>
            <div className={'p-2 min-w-[65vw] min-h-[300px]'}>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3 my-3'>
                    <p className='font-semibold text-[20px]'>Order Fulfillment Request</p>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='col-span-2'>
                            <div className='mb-2 flex flex-row items-center'>
                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                            <div className='!mr-1 !ml-1'>{itemDetailsInfo?.descriptor?.[key] || itemDetailsInfo?.inventory?.[key]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            <p>{CURRENCY_SYMBOLS?.[`${(itemDetailsInfo?.currency || '').toLowerCase()}`]}{(Number(itemDetailsInfo?.price || 0)).toFixed(2)} per {itemDetailsInfo?.qty > 1 ? 'Items' : 'Item'}</p>
                            <p>Quantity: {itemDetailsInfo?.qty} {itemDetailsInfo?.qty > 1 ? 'Items' : 'Item'}</p>
                            <div className='border-t pt-2 mb-3'>
                                <p>Missing: {missingItems} {missingItems > 1 ? 'Items' : 'Item'}</p>
                            </div>
                        </div>
                        <div className='justify-self-end'>
                            <img src={'/assets/shared/requested-loop.svg'} className='object-contain min-w-[80px] w-[80px]' />
                        </div>
                    </div>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='text-[20px]'>Matching items from other open orders</p>

                    <div className=' border-b border-[#e4e4e4] pb-2 my-2'>
                        <Table
                            columns={['Order ID', 'Customer', 'Quantity', 'Order Value', 'Due Date', 'Status', 'Requested', 'Actions']}
                            hidePagination={true}
                            headerStyle='!bg-[#000000] !text-white'
                        >
                            {ordersResponse && ordersResponse?.length > 0 && ordersResponse?.map((order: any, index: number) => {
                                const findSelectedItems = selectedItems && selectedItems?.length > 0 && selectedItems?.filter((item: any) => item?.saleOrderId === order?._id)
                                const totalQuantity = (order?.saleItems && order?.saleItems?.length > 0) && order?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                                const totalPrice = order?.saleItems && order?.saleItems?.length > 0 && order?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                                const findCurrency = order?.saleItems && order?.saleItems?.length > 0 && order?.saleItems?.[0]?.currency
                                return (
                                    <>
                                        <tr key={index} className='border-b hover:bg-gray-50 my-1 bg-[#fef6e8]'>
                                            <td className='px-6 py-4'>{order?.quoteId}</td>
                                            <td className='px-6 py-4'>{order?.salesInfo?.customer?.displayName || order?.salesInfo?.partner?.username || ''}</td>
                                            <td className='px-6 py-4 min-w-[140px]'>
                                                <p>Reserved: {order?.reservedItems?.length || 0}</p>
                                                <p>Missing: {Number(totalQuantity || 0) - (order?.reservedItems?.length || 0)}</p>
                                                <p>Picked: {order?.selectedItems?.length || 0}</p>
                                            </td>
                                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{totalPrice || 0}</td>
                                            <td className='px-6 py-4 min-w-[140px]'>{order?.salesInfo?.dueDate && moment(order?.salesInfo?.dueDate).format('DD-MM-YYYY')}</td>
                                            <td className={`px-6 py-4 ${onFormatSalesStatus(order?.status).text}`}>{onFormatSalesStatus(order?.status)?.name || order?.status}</td>
                                            <td className='px-6 py-4'>{findSelectedItems?.length || 0}</td>
                                            <td></td>
                                        </tr>
                                        {order?.items &&
                                            <tr className='bg-[#f8f8f8] shadow my-3'>
                                                <td colSpan={8} className='px-4 py-3 border-r border-slate-100'>
                                                    <table className='w-full p-3'>
                                                        <tbody>
                                                            {order?.items?.map((item: any, idx: number) => {
                                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                                const findIfRowIsSelected = selectedItems && selectedItems?.length > 0 && selectedItems?.find((selected: any) => selected?.saleOrderId === order?._id && (selected?.itemId === item?.itemId && selected?.status === item?.status))
                                                                return (
                                                                    <tr key={idx} className='border-b bg-[#fff]'>
                                                                        <td className='px-2 py-4'>
                                                                            <div className='flex items-center'>
                                                                                <input
                                                                                    onChange={() => onSelectItem(item, order?._id)}
                                                                                    checked={!!findIfRowIsSelected}
                                                                                    data-qa={'checkbox-type'}
                                                                                    id='checkbox-all'
                                                                                    type='checkbox'
                                                                                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2' />
                                                                                <label htmlFor='checkbox-all' className='sr-only'>checkbox</label>
                                                                            </div>
                                                                        </td>
                                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                                    return (
                                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                                            {(index !== 0) &&
                                                                                                <div className='flex flex-row items-center ml-1'>
                                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                                </div>
                                                                                            }
                                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td className='py-2 px-2'>{item?.itemId}</td>
                                                                        <td className='py-2 px-2'>{CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{item?.price}</td>
                                                                        <td className={`py-2 px-2 ${onFormatSaleItemStatus(item?.status)?.text}`}>{onFormatSaleItemStatus(item?.status)?.name || item?.status}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td colSpan={5} className='px-2 py-2'>
                                                                    <Button
                                                                        label={'Load All Matching Items'}
                                                                        className='btn-primary-text-underline !text-[#000000]'
                                                                        onClickButton={() => !loadingLoadMore && onGettingData(true, order?._id)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            })}
                        </Table>
                        <div className='px-4 py-2 mb-4'>
                            <Button
                                label={'Load All Sales Orders'}
                                className='btn-primary-text-underline !text-[#000000]'
                                onClickButton={() => !loadingLoadMore && onGettingData(true)}
                            />
                        </div>
                        <div className='flex flex-row justify-center items-center self-center'>
                            <Button
                                label={review ? 'Confirm Request' : 'Submit Request'}
                                className={'btn-primary min-w-[150px]'}
                                onClickButton={() => onConfirm()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default RequestModal;