import React from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Nr.', 'Expected Item', 'Price per item', 'Number Of Expected Items', 'Expected Value', 'Delivered Items', 'Delivered Value', 'Actions']

interface IReviewExpectedItemsTable {
    rows: any[];
    categories?: Array<any>;
    deliveredItems?: any;
    locations?: any;
    onAddItemToDelivered: (data: any) => void;
}

const ReviewExpectedItemsTable = ({
    rows,
    categories,
    deliveredItems,
    locations,
    onAddItemToDelivered
}: IReviewExpectedItemsTable) => {

    const areObjectsEqual = (obj1: any, obj2: any) => {
        const stringifiedObj1 = obj1 && JSON.stringify(obj1, Object.keys(obj1).sort());
        const stringifiedObj2 = obj2 && JSON.stringify(obj2, Object.keys(obj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'>
            {(rows?.length > 0) && rows?.map((row: any, idx: number) => {
                const totalPrice = (row?.price) * (row?.qty || 0)
                const findCategory = categories && categories?.length > 0 && categories?.find((item: any) => item?.value === row?.categoryId)
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                const filterDeliveredItems = deliveredItems && deliveredItems?.length > 0 && deliveredItems?.filter((item: any) => item?.match && (Number(item?.price) === Number(row?.price)) && areObjectsEqual(row?.descriptor, item?.match?.descriptor) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)))
                const totalDeliveredItems = filterDeliveredItems?.length || 0
                const totalDeliveredValue = (filterDeliveredItems && filterDeliveredItems?.length > 0) && filterDeliveredItems?.reduce((accumulator: number, item: any) => accumulator + item?.price, 0);
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50 my-1 bg-[#fef6e8]'>
                        <td className='pl-6 py-4 text-black'>{idx + 1}</td>
                        <td className='pl-3 py-4 min-w-[400px]'>
                            <div className='flex flex-wrap'>
                                <img src={findCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                            {(index !== 0) &&
                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                </div>
                                            }
                                            <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.price || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{row?.qty}</td>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(totalPrice || 0).toFixed(2)}</td>
                        <td className='px-6 py-4'>{totalDeliveredItems || '--'}</td>
                        <td className='px-6 py-4'>{totalDeliveredValue && CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{totalDeliveredValue ? Number(totalDeliveredValue || 0)?.toFixed(2) : '--'}</td>
                        <td className='px-2 py-4'>
                            <Button
                                label={'Review'}
                                className={`${locations?.[row?.categoryId]?.length > 0 ? 'btn-primary-rounded-less' : 'btn-primary-disable !rounded'} text-[14px] !font-medium`}
                                onClickButton={() => row?.descriptor && locations?.[row?.categoryId]?.length > 0 && onAddItemToDelivered(row)}
                            />
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default ReviewExpectedItemsTable;