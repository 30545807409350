import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Nr.', 'Expected Item to be reserved', 'Price per item', 'Quantity', 'Sales Value', 'Matching Stock', 'Stock on Other Orders', 'Actions']

interface IReviewTable {
    rows: any[];
    categories?: Array<any>;
    reservedItems?: any;
    selectedItems?: any;
    requestedItems?: any;
    templateFields?: any;
    secondReview?: boolean;
    salesContent?: any;
    accessControl?: any;
    pageAccess?: any;
    onScanItem: (item: any) => void;
    onClickAcceptItem: (item: any, acceptedItem: any, extra?: boolean) => void;
    onClickRejectItem: (item: any, rejectItem: any, extra?: boolean) => void;
    onReviewItem: (item: any, requestedItems?: any, missingItems?: number, replace?: boolean) => void;
}

const ReviewTable = ({
    rows,
    categories,
    reservedItems,
    selectedItems,
    requestedItems,
    templateFields,
    secondReview,
    salesContent,
    accessControl,
    pageAccess,
    onScanItem,
    onReviewItem,
    onClickAcceptItem,
    onClickRejectItem,
}: IReviewTable) => {
    const [formatRows, setFormatRows] = useState<any>();
    const [hideDetails, setHideDetails] = useState<any>();

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const categoryFields = category && templateFields?.[category]
        const formatObj1: any = obj1
        let formatObj2: any = obj2
        if (categoryFields && category) {
            formatObj2 = {}
            const moreKeys: any = obj1 && (Object?.keys(obj1))?.filter((item: any, index: number) => !(categoryFields?.find((field: any) => field?.name === item)))
            obj2 && Object.keys(obj2)?.map((key: any, index: number) => {
                const findInCategories = categoryFields?.find((field: any) => field?.name === key)
                const findInMoreKeys = moreKeys && moreKeys?.length > 0 && moreKeys?.find((obj: any) => obj === key)
                if (findInCategories || findInMoreKeys) {
                    formatObj2[key] = obj2[key];
                }
                return key
            }, {});
        }
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    useEffect(() => {
        if (selectedItems && rows) {
            const newRows: any = [...rows || []]
            const findAllExtra = (selectedItems && selectedItems?.length > 0) && selectedItems?.filter((selected: any) => (!selected?.match || Object?.keys(selected?.match)?.length <= 0))
            if (findAllExtra && findAllExtra?.length > 0) {
                findAllExtra?.forEach((item: any) => {
                    newRows.push(
                        {
                            item: item,
                            descriptor: item?.descriptor,
                            inventory: item?.inventory,
                            currency: rows?.[0]?.currency || undefined,
                            price: 0,
                            extra: true,
                            qty: 1
                        }
                    )
                })
            }
            setFormatRows(newRows)
        }
    }, [rows, selectedItems])

    const findInSelected = (id: string) => {
        const findIfInSelected = (selectedItems && selectedItems?.length > 0) && selectedItems?.find((item: any) => item?.itemId === id)
        if (findIfInSelected) {
            return true
        }
        else return false
    }

    const onClickHideShowItem = (index: number, type: string) => {
        setHideDetails({
            ...hideDetails || {},
            [index]: {
                ...hideDetails?.[index] || {},
                [type]: !hideDetails?.[index]?.[type]
            }
        })
    }

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'>
            {(formatRows && formatRows?.length > 0) && formatRows?.map((row: any, idx: number) => {
                const findStock = (salesContent?.salesItems && salesContent?.salesItems?.length > 0) && salesContent?.salesItems?.find((item: any) => areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && areObjectsEqual(item?.inventory, row?.inventory) && item?.price === row?.price)
                const totalPrice = (row?.price) * (row?.qty || 0)
                const findCategory = (categories && categories?.length > 0) && categories?.find((item: any) => item?.id === row?.categoryId)
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                const totalReservedItems = (reservedItems && reservedItems?.length > 0 && !row?.extra) && reservedItems?.filter((item: any) => row?.descriptor && item?.match ? areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.inventory) || (!row?.inventory && !item?.inventory)) && areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)) : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor)?.length || 0
                const reservedItemsFormated = (reservedItems && reservedItems?.length > 0 && !row?.extra) && reservedItems?.filter((item: any) => row?.descriptor && item?.match ? areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.inventory) || (!row?.inventory && !item?.inventory)) && areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)) && !findInSelected(item?.itemId) : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor && !findInSelected(item?.itemId))
                const matchingItems = (selectedItems && selectedItems?.length > 0 && !row?.extra) && selectedItems?.filter((item: any) => row?.descriptor && item?.match ? !item?.notMatching && areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)) && (item?.status === 'matching' || item?.status === 'accepted') : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor)
                const notMatchingItems = (selectedItems && selectedItems?.length > 0 && !row?.extra) && selectedItems?.filter((item: any) => item?.match && (item.status === 'not_matching' || item?.status === 'rejected') && areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)))
                const filterSelectedItems = (selectedItems && selectedItems?.length > 0 && !row?.extra) && selectedItems?.filter((item: any) => row?.descriptor && item?.match ? areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)) : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor)
                const requestedItemsFormated = (requestedItems && requestedItems?.length > 0 && !row?.extra) && requestedItems?.filter((item: any) => row?.descriptor && item?.match && areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.inventory) || (!row?.inventory && !item?.inventory)) && areObjectsEqual(row?.descriptor, item?.match?.descriptor, row?.categoryId) && (areObjectsEqual(row?.inventory, item?.match?.inventory) || (!row?.inventory && !item?.match?.inventory)))
                const missingItems = !row?.extra ? (row?.qty - ((reservedItemsFormated?.length || 0) + (requestedItemsFormated?.length || 0) + (matchingItems?.length || 0) + (notMatchingItems ? notMatchingItems?.length || 0 : 0))) : 0
                return (
                    <>
                        <tr key={idx} className={`border-b hover:bg-gray-50 my-1 ${row?.extra ? '!bg-[#e7f3fc]' : '!bg-[#fef6e8]'}`}>
                            <td className='pl-6 py-4 text-black'>{idx + 1}</td>
                            <td className='pl-3 py-4 min-w-[400px]'>
                                <div className='flex flex-wrap'>
                                    {row?.descriptor ?
                                        <>
                                            <img src={findCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                            {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                                return (
                                                    <div key={index} className='flex items-center whitespace-nowrap'>
                                                        {(index !== 0) &&
                                                            <div className='flex flex-row items-center ml-1'>
                                                                <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                            </div>
                                                        }
                                                        <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : <p>Extra Items</p>
                                    }
                                </div>
                            </td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{row?.price || 0}</td>
                            <td className='px-6 py-4'>{row?.qty || 0}</td>
                            <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{totalPrice || 0}</td>
                            <td className='px-6 py-4'>{totalReservedItems || '--'}</td>
                            <td className='px-6 py-4'>{findStock?.stockOnOtherOrders || '--'}</td>
                            <td className='px-6 py-4'>
                                <div className='flex flex-row items-center'>
                                    {(!row?.extra && (missingItems > 0 || ((filterSelectedItems?.length || 0) < row?.qty) || (reservedItemsFormated?.length > 0))) &&
                                        <Button
                                            label={'Scan'}
                                            className='btn-primary-text-underline'
                                            onClickButton={() => onScanItem(row)}
                                        />
                                    }
                                    {(requestedItemsFormated && requestedItemsFormated?.length > 0) &&
                                        <>
                                            {!row?.reviewed ?
                                                <Button
                                                    label={'Review'}
                                                    className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Review Item']) ? '!text-[#1389e6]' : '!text-[#000]'}`}
                                                    onClickButton={() => (!accessControl || pageAccess?.actions?.['Review Item']) && onReviewItem(row, requestedItemsFormated, missingItems)}
                                                />
                                                : <Button
                                                    label={'Reviewed'}
                                                    className='btn-primary-text-underline !text-[#00b67a]'
                                                />
                                            }
                                        </>
                                    }
                                    {(row?.extra && row?.item?.status === 'extra_item') &&
                                        <>
                                            <Button
                                                label={'Accept'}
                                                className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Accept Item']) ? '!text-[#00b67a]' : '!text-[#000]'}`}
                                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Accept Item']) && onClickAcceptItem(row, row?.item, true)}
                                            />
                                            <Button
                                                label={'Reject'}
                                                className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Reject Item']) ? '!text-[#e50000]' : '!text-[#000]'}`}
                                                onClickButton={() => (!accessControl || pageAccess?.actions?.['Reject Item']) && onClickRejectItem(row, row?.item, true)}
                                            />
                                        </>
                                    }
                                    {(row?.extra && ['accepted', 'rejected']?.includes(row?.item?.status || '')) &&
                                        <>
                                            <p className={`${row?.item?.status === 'accepted' ? '!text-[#00b67a]' : '!text-[#e50000]'}`}>{row?.item?.status === 'accepted' ? 'Accepted' : 'Rejected'}</p>
                                        </>
                                    }
                                </div>
                            </td>
                        </tr>
                        {(!row?.extra && !hideDetails?.[idx]?.row && ((filterSelectedItems && filterSelectedItems?.length > 0) || reservedItemsFormated || (missingItems > 0) || requestedItemsFormated)) &&
                            <tr className='bg-[#f8f8f8] shadow my-3'>
                                <td colSpan={(tableColumns?.length) || 5} className='px-4 py-3 border-r border-slate-100'>
                                    <table className='w-full p-3'>
                                        <tbody>
                                            {(reservedItemsFormated && reservedItemsFormated?.length > 0) &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'reserved')}>
                                                            <p className='text-[#00b67a]'>Reserved ({reservedItemsFormated?.length || 0} of {(row?.qty) ? (Number(row?.qty) - (matchingItems?.length || 0) - (notMatchingItems?.length || 0)) : 0})</p>
                                                            <img src={'/assets/table-actions/matching-array.svg'} className={`object-contain h-[16px] ml-3 ${hideDetails?.[idx]?.reserved ? 'rotate-[-90deg]' : 'rotate-0'}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {(!hideDetails?.[idx]?.reserved && (reservedItemsFormated && reservedItemsFormated?.length > 0)) && reservedItemsFormated?.map((item: any, idx: number) => {
                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                return (
                                                    <tr key={idx} className='border-b bg-[#e5f8f2]'>
                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                    return (
                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                            {(index !== 0) &&
                                                                                <div className='flex flex-row items-center ml-1'>
                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                </div>
                                                                            }
                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                            <div>
                                                                <p className='font-bold'>{(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI:' : 'Item ID:'}</p>
                                                                <p>{item?.itemId}</p>
                                                            </div>
                                                        </td>
                                                        {(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') &&
                                                            <td className='py-2 px-2'>
                                                                <p className='font-bold'>IMEI Status:</p>
                                                                <p>{item?.imeiStatus}</p>
                                                            </td>
                                                        }
                                                        <td className='py-2 px-2'>
                                                            <p className='font-bold'>Location:</p>
                                                            <p>
                                                                {item?.locations && (Object?.keys(item?.locations))?.map((obj: any, index: number) => {
                                                                    return index === 0 ? item?.locations?.[obj] : ` - ${item?.locations?.[obj]}`
                                                                })}
                                                            </p>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {(requestedItemsFormated && requestedItemsFormated?.length > 0) &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'requested')}>
                                                            <p className='text-[#1389e6]'>Requested ({requestedItems?.length || 0} of {(row?.qty) ? (Number(row?.qty) - (matchingItems?.length || 0) - (notMatchingItems?.length || 0)) : 0})</p>
                                                            <img src={'/assets/table-actions/requested-array.svg'} className={`object-contain h-[16px] ml-3 ${hideDetails?.[idx]?.requested ? 'rotate-[-90deg]' : 'rotate-0'}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {(!hideDetails?.[idx]?.requested && (requestedItemsFormated && requestedItemsFormated?.length > 0)) && requestedItemsFormated?.map((item: any, idx: number) => {
                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                return (
                                                    <tr key={idx} className='border-b bg-[#d7e9f7]'>
                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                    return (
                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                            {(index !== 0) &&
                                                                                <div className='flex flex-row items-center ml-1'>
                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                </div>
                                                                            }
                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                            <div>
                                                                <p className='font-bold'>{(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI:' : 'Item ID:'}</p>
                                                                <p>{item?.itemId}</p>
                                                            </div>
                                                        </td>
                                                        {(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') &&
                                                            <td className='py-2 px-2'>
                                                                <p className='font-bold'>IMEI Status:</p>
                                                                <p>{item?.imeiStatus}</p>
                                                            </td>
                                                        }
                                                        <td className='py-2 px-2'>
                                                            <p className='font-bold'>Location:</p>
                                                            <p>
                                                                {item?.newLocation ?
                                                                    item?.newLocation
                                                                    : <>
                                                                        {item?.locations && (Object?.keys(item?.locations))?.map((obj: any, index: number) => {
                                                                            return index === 0 ? item?.locations?.[obj] : ` - ${item?.locations?.[obj]}`
                                                                        })}
                                                                    </>
                                                                }
                                                            </p>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {matchingItems && matchingItems?.length > 0 &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'matching')}>
                                                            <p className='text-[#00b67a]'>Matching ({matchingItems?.length || 0} of {filterSelectedItems?.length || 0})</p>
                                                            <img src={'/assets/table-actions/matching-array.svg'} className={`object-contain h-[16px] ml-3 ${hideDetails?.[idx]?.matching ? 'rotate-[-90deg]' : 'rotate-0'}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {(!hideDetails?.[idx]?.matching && (matchingItems && matchingItems?.length > 0)) && matchingItems?.map((item: any, idx: number) => {
                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                return (
                                                    <tr key={idx} className='border-b bg-[#e5f8f2]'>
                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                    return (
                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                            {(index !== 0) &&
                                                                                <div className='flex flex-row items-center ml-1'>
                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                </div>
                                                                            }
                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                            <div>
                                                                <p className='font-bold'>{(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI:' : 'Item ID:'}</p>
                                                                <p>{item?.itemId}</p>
                                                            </div>
                                                        </td>
                                                        {(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') &&
                                                            <td className='py-2 px-2'>
                                                                <p className='font-bold'>IMEI Status:</p>
                                                                <p>{item?.imeiStatus}</p>
                                                            </td>
                                                        }
                                                        <td className='py-2 px-2'>
                                                            <p className='font-bold'>Location:</p>
                                                            <p>
                                                                {item?.newLocation ?
                                                                    item?.newLocation
                                                                    : <>
                                                                        {item?.locations && (Object?.keys(item?.locations))?.map((obj: any, index: number) => {
                                                                            return index === 0 ? item?.locations?.[obj] : ` - ${item?.locations?.[obj]}`
                                                                        })}
                                                                    </>
                                                                }
                                                            </p>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {(notMatchingItems && notMatchingItems?.length > 0) &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'not-matching')}>
                                                            <p className='text-[#e50000]'>Not Matching({notMatchingItems?.length || 0} of {filterSelectedItems?.length || 0})</p>
                                                            <img src={'/assets/table-actions/notmatching-array.svg'} className={`object-contain h-[16px] ml-3 ${hideDetails?.[idx]?.['not-matching'] ? 'rotate-[-90deg]' : 'rotate-0'}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {(!hideDetails?.[idx]?.['not-matching'] && notMatchingItems && notMatchingItems?.length > 0) && notMatchingItems?.map((item: any, idx: number) => {
                                                const matchingKeysDesc = item?.descriptor && Object.keys(item?.descriptor)
                                                const matchingKeysInven = item?.inventory && Object.keys(item?.inventory)
                                                const matchingKeys = [...matchingKeysDesc || [], ...matchingKeysInven || []]
                                                const findMatchCategory = categories && categories?.length > 0 && categories?.find((category: any) => category?.id === item?.categoryId)
                                                return (
                                                    <tr key={idx} className='border-b bg-[#fce5e5]'>
                                                        <td className='pl-3 py-2 !w-[400px] !max-w-[410px]'>
                                                            <div className='flex flex-wrap !w-[400px] !max-w-[400px]'>
                                                                <img src={findMatchCategory?.enabledIcon} className={'h-[22px] min-h-[22px] object-contain'} />
                                                                {(matchingKeys && matchingKeys?.length > 0) && matchingKeys?.map((key: string, index: number) => {
                                                                    return (
                                                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                                                            {(index !== 0) &&
                                                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                                                </div>
                                                                            }
                                                                            <div className='!mr-1 !ml-1'>{item?.descriptor?.[key] || item?.inventory?.[key]}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='py-2 px-2'>
                                                            <div>
                                                                <p className='font-bold'>{(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') ? 'IMEI:' : 'Item ID:'}</p>
                                                                <p>{item?.itemId}</p>
                                                            </div>
                                                        </td>
                                                        {(findCategory?.label?.toLowerCase() === 'phones' || findCategory?.label?.toLowerCase() === 'tablets') &&
                                                            <td className='py-2 px-2'>
                                                                <p className='font-bold'>IMEI Status:</p>
                                                                <p>{item?.imeiStatus}</p>
                                                            </td>
                                                        }
                                                        <td className='py-2 px-2'>
                                                            <p className='font-bold'>Location:</p>
                                                            <p>
                                                                {item?.newLocation ?
                                                                    item?.newLocation
                                                                    : <>
                                                                        {item?.locations && (Object?.keys(item?.locations))?.map((obj: any, index: number) => {
                                                                            return index === 0 ? item?.locations?.[obj] : ` - ${item?.locations?.[obj]}`
                                                                        })}
                                                                    </>
                                                                }
                                                            </p>
                                                        </td>
                                                        <td className='py-2 px-3'>
                                                            {item?.status === 'rejected' || item?.status === 'accepted' ?
                                                                <p>{item?.status === 'rejected' ? 'Rejected' : 'Accepted'}</p>
                                                                : <div className='flex flex-row'>
                                                                    <Button
                                                                        label={'Accept'}
                                                                        className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Accept Item']) ? '!text-[#00b67a]' : '!text-[#000]'}`}
                                                                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Accept Item']) && onClickAcceptItem(row, item)}
                                                                    />
                                                                    <Button
                                                                        label={'Reject'}
                                                                        className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Reject Item']) ? '!text-[#e50000]' : '!text-[#000]'}`}
                                                                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Reject Item']) && onClickRejectItem(row, item)}
                                                                    />
                                                                    <Button
                                                                        label={'Replace'}
                                                                        className={`btn-primary-text-underline ${(!accessControl || pageAccess?.actions?.['Replace Item']) ? '!text-[#1389e6]' : '!text-[#000]'}`}
                                                                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Replace Item']) && onReviewItem(row, requestedItemsFormated, 1, true)}
                                                                    />
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            {(missingItems > 0) &&
                                                <tr>
                                                    <td colSpan={tableColumns?.length || 1}>
                                                        <div
                                                            className='flex flex-row items-center cursor-pointer'
                                                            onClick={() => onClickHideShowItem(idx, 'matching')}>
                                                            <p className='text-[#e50000]'>Missing ({missingItems || 0} of {row?.qty || 0})</p>
                                                            <img src={'/assets/table-actions/notmatching-array.svg'} className={'object-contain h-[16px] ml-3 rotate-[-90deg]'} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                    </>
                )
            })}
        </Table>
    )
}

export default ReviewTable;