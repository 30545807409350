import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPurchase } from '../../../interfaces/purchases/IPurchase';
import {
    tryToFetchSinglePurchase,
    tryToReviewPurchase
} from '../../../store/inventory/broker/purchases/purchasesSlice';
import { useUserProfileMutation } from '../../../store/user/userDomApi';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import ExpectedAndDeliveredItemsTable from './tables/ExpectedAndDeliveredItemsTable';
import ReviewExpectedItemsTable from './tables/ReviewExpectedItemsTable';
import ReviewItemLocationModal from './modals/ReviewItemLocationModal';
import ReviewItemDetailsModal from './modals/ReviewItemDetailsModal';
import Button from '../../../shared/button';
import Error from '../../../shared/error';


const PurchaseReview = () => {
    const { id } = useParams();
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const [mainLocations, setMainLocations] = useState<any>();
    const [availableLocations, setAvailableLocations] = useState<any>();
    const [purchaseDetails, setPurchaseDetails] = useState<IPurchase>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [deliveredItems, setDeliveredItems] = useState<any>();
    const [submitError, setSubmitError] = useState<string>();
    const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
    const [showItemModal, setShowItemModal] = useState<{ show: boolean, matchBody?: any, data?: any }>();
    const [spreadLocations, setSpreadLocations] = useState<any>();
    const [itemsGeneralError, setItemsGeneralError] = useState<string>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [userProfileMutation] = useUserProfileMutation();
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const state = useAppSelector((state) => state.purchases);

    const getBrokerSectors = async () => {
        const response = await userProfileMutation(null).unwrap()
        const formatSectors: any = response?.data?.sectors?.length > 0 ? response?.data?.sectors?.map((s: any) => ({ ...s || {}, label: s?.displayName || s?.name, name: s?.name, value: s?.id })) : []
        setBrokerCategories(formatSectors || [])
    }

    const getPurchaseDetailsById = async () => {
        await dispatch(tryToFetchSinglePurchase(id || ''))
    }

    const onGettingAllAssetFields = async () => {
        if (purchaseDetails?.categories && purchaseDetails?.categories?.length > 0) {
            try {
                await Promise.all(purchaseDetails?.categories?.map(async (item: any) => {
                    if (!templateFields?.[item?.code]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.id)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.id || '']: response?.fields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
        }
    };

    useEffect(() => {
        if (purchaseDetails?.categories) {
            onGettingAllAssetFields()
        }
    }, [purchaseDetails?.categories])

    useEffect(() => {
        if (state.purchaseDetails) {
            setPurchaseDetails(state?.purchaseDetails || undefined)
            const formatItems = state?.purchaseDetails?.deliveredItems && state?.purchaseDetails?.deliveredItems?.length > 0 && state?.purchaseDetails?.deliveredItems?.map((item: any) => {
                return {
                    ...item || {},
                    newLocation: !item?.locations || false,
                    supplierCondition: item?.supplierCondition,
                    imeiStatus: item?.imeiStatus,
                }
            })
            setDeliveredItems(formatItems || undefined)
        }
    }, [state.purchaseDetails])

    useEffect(() => {
        getPurchaseDetailsById()
        getBrokerSectors()
        localStorage.setItem('prev', 'review')
    }, [])

    const onClickShowLocation = () => {
        if (showLocationModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowLocationModal(!showLocationModal)
    }

    useEffect(() => {
        if (availableLocations) {
            const locationKeys = availableLocations && Object?.keys(availableLocations)
            if (locationKeys && locationKeys?.length > 0) {
                let formatLocations: any;
                locationKeys?.map((category: string) => {
                    let counter = 0;
                    const resultArray = Object.values(availableLocations?.[category])
                        .flatMap((array: any) =>
                            array?.filter((item: any) => item.capacity - item.filledCapacity > 0)
                                .flatMap((item: any) =>
                                    Array.from({ length: item.capacity - item.filledCapacity }, () => ({
                                        ...item,
                                        unique: ++counter,
                                    }))
                                )
                        );
                    let reformatResultArray: any = [...resultArray || []]
                    const rowsDataFormat = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.map((item: any, index: any) => {
                        reformatResultArray = reformatResultArray?.filter((obj: any, idx: number) => idx !== 0)
                        if (item?.newLocation && item?.categoryId === category) {
                            return {
                                ...item,
                                newLocation: true,
                                locations: resultArray?.[index],
                                locationId: resultArray?.[index]?.barcode
                            }
                        }
                        else {
                            return item
                        }
                    })
                    setDeliveredItems(rowsDataFormat)
                    formatLocations = {
                        ...formatLocations || {},
                        [category]: reformatResultArray || undefined
                    }
                    return category
                })
                setSpreadLocations(formatLocations)
            }
        } else {
            setSpreadLocations(undefined)
            const rowsDataFormat = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.map((item: any, index: any) => {
                if (item?.newLocation) {
                    return {
                        ...item,
                        locations: undefined,
                        locationId: undefined
                    }
                }
                else {
                    return item
                }
            })
            setDeliveredItems(rowsDataFormat)
        }
    }, [availableLocations])

    const onClickAddExtraItem = (item?: any) => {
        if ((!spreadLocations || !spreadLocations?.[item?.categoryId] || !(spreadLocations?.[item?.categoryId]?.length > 0)) && item) {
            setItemsGeneralError('No available locations for new items in that category!')
            return;
        }
        setItemsGeneralError(undefined)
        document.body.style.overflow = 'hidden';
        setShowItemModal({
            show: true,
            matchBody: item
        })
    }

    const onCloseItemModal = () => {
        document.body.style.overflow = 'auto';
        setShowItemModal(undefined)
    }

    const onAddItemToDelivered = (item: any) => {
        if (item) {
            setDeliveredItems([
                ...deliveredItems || [],
                item || {}
            ])
            const categoryLocations = spreadLocations?.[item?.categoryId] && JSON.parse(JSON.stringify(spreadLocations?.[item?.categoryId]));
            categoryLocations.shift()
            const findAllCategories = spreadLocations && Object.keys(spreadLocations)
            let otherLocations: any;
            if (findAllCategories && findAllCategories?.length > 0) {
                findAllCategories?.forEach((categoryItem: any) => {
                    if (categoryItem !== item?.categoryId) {
                        const filterCategories = spreadLocations?.[categoryItem]
                        filterCategories.shift();
                        otherLocations = {
                            ...otherLocations || {},
                            [categoryItem]: filterCategories
                        }
                    }
                })
            }
            setSpreadLocations({
                ...otherLocations || {},
                [item?.categoryId || '']: categoryLocations
            })
        }
    }

    const onSubmitPurchase = async (notCompleted: boolean) => {
        try {
            const formatItems = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.map((item: any) => {
                return {
                    ...item,
                    locations: item?.locations?.descriptor ? item?.locations?.descriptor : item?.locations,
                    section: item?.section ? item?.section : item?.locations?.space
                }
            })
            const purchaseDataForm = {
                deliveredItems: formatItems,
                warehouseId: activeWarehouse?._id || '',
                marketId: purchaseDetails?.marketId,
                reviewed: !notCompleted
            }
            const dataFormat = {
                id: id || '',
                data: purchaseDataForm
            }
            await dispatch(tryToReviewPurchase(dataFormat))
            setSubmitError(undefined)
            navigator(`/purchases/${id}`)
        } catch (err: any) {
            setSubmitError(err || 'Something went wrong!')
        }
    }

    const onDeleteDeliveredItem = (itemId: string) => {
        setDeliveredItems((prevDeliveredItems: any) => prevDeliveredItems?.filter((item: any) => item?.itemId !== itemId))
    }

    const onCopyDeliveredItem = (item: any, data: any) => {
        document.body.style.overflow = 'hidden';
        setShowItemModal({
            show: true,
            matchBody: item,
            data: data
        })
    }

    const areObjectsEqual = (obj1: any, obj2: any, category?: string) => {
        const categoryFields = category && templateFields?.[category]
        const formatObj1: any = obj1
        let formatObj2: any = obj2
        if (categoryFields && category) {
            formatObj2 = {}
            const moreKeys: any = obj1 && (Object?.keys(obj1))?.filter((item: any, index: number) => !(categoryFields?.find((field: any) => field?.name === item)))
            obj2 && Object.keys(obj2)?.map((key: any, index: number) => {
                const findInCategories = categoryFields?.find((field: any) => field?.name === key)
                const findInMoreKeys = moreKeys && moreKeys?.length > 0 && moreKeys?.find((obj: any) => obj === key)
                if (findInCategories || findInMoreKeys) {
                    formatObj2[key] = obj2[key];
                }
                return key
            }, {});
        }
        const stringifiedObj1 = formatObj1 && JSON.stringify(formatObj1, Object.keys(formatObj1).sort());
        const stringifiedObj2 = formatObj2 && JSON.stringify(formatObj2, Object.keys(formatObj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    const totalExpectedItems = (purchaseDetails?.expectedItems && purchaseDetails?.expectedItems?.length > 0) && purchaseDetails?.expectedItems?.reduce((accumulator: number, item: any) => accumulator + Number(item?.qty), 0);
    const notReview = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.filter((item: any) => !(!item?.match || (Object?.keys(item?.match)?.length <= 0)));
    let matchedItems: number = 0
    let notmatchedItems: number = 0
    const extraItems = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.filter((item: any) => !item?.match || (Object?.keys(item?.match)?.length <= 0))
    purchaseDetails?.expectedItems && purchaseDetails?.expectedItems?.length > 0 && purchaseDetails?.expectedItems?.forEach((row: any) => {
        const matchingItemsFilter = (deliveredItems && deliveredItems?.length > 0) && deliveredItems?.filter((item: any) => row?.descriptor && item?.match ? Number(item?.price) === Number(row?.price) && areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) && areObjectsEqual(row?.inventory, item?.inventory) && areObjectsEqual(row?.descriptor, item?.match?.descriptor) && areObjectsEqual(row?.inventory, item?.match?.inventory) : (!item?.match || Object?.keys(item?.match)?.length <= 0) && !row?.descriptor)
        const notMatchingItemsFilter = (deliveredItems && deliveredItems?.length > 0 && row?.descriptor) && deliveredItems?.filter((item: any) => item?.match && (!areObjectsEqual(row?.descriptor, item?.descriptor, row?.categoryId) || !areObjectsEqual(row?.inventory, item?.inventory)) && (Number(row?.price) === Number(item?.price)) && areObjectsEqual(row?.descriptor, item?.match?.descriptor) && areObjectsEqual(row?.inventory, item?.match?.inventory))
        matchedItems += (matchingItemsFilter?.length || 0)
        notmatchedItems += (notMatchingItemsFilter?.length || 0)
    })
    return (
        <div>
            <div className='mb-5 flex flex-row justify-between'>
                <p className='page-title'> Purchase Review</p>
                <div className='flex flex-row items-center'>
                    <Button
                        label={'Save for Now'}
                        className={`${(deliveredItems && deliveredItems?.length > 0) ? 'btn-main' : 'btn-main-disable'} min-w-[160px] mr-2 !rounded`}
                        onClickButton={() => (deliveredItems && deliveredItems?.length > 0) && onSubmitPurchase(true)}
                    />
                    <Button
                        label={'Complete Review'}
                        className={`${(deliveredItems && deliveredItems?.length > 0) ? 'btn-primary' : 'btn-primary-disable'} !rounded min-w-[160px]`}
                        onClickButton={() => (deliveredItems && deliveredItems?.length > 0) && onSubmitPurchase(false)}
                    />
                </div>
            </div>
            {submitError && <Error text={submitError} />}
            <div className='mt-2 bg-[white] border-t-2 border-[#e4e4e4] p-2'>
                <div className='my-4 pb-3 border-b-2 border-[#e4e4e4] flex flex-row items-center'>
                    <p className='font-bold text-[18px]'>Locations</p>
                    <div className='mr-3 ml-3'>
                        <Button
                            label='Add Location'
                            icon={<img src='/assets/shared/add-box.svg' className='w-[30px] object-contains pr-2' />}
                            className='btn-primary flex flex-row items-center'
                            onClickButton={onClickShowLocation}
                        />
                    </div>
                </div>
                <div className='border-b-2 border-[#e4e4e4]'>
                    <div className='flex flex-row justify-between items-center my-2'>
                        <p className='font-bold text-[18px]'>Expected Items</p>
                        <div>
                            <Button
                                label={'Add Extra Item'}
                                className='btn-main min-w-[150px] !rounded'
                                onClickButton={onClickAddExtraItem}
                            />
                        </div>
                    </div>
                    {itemsGeneralError && <Error text={itemsGeneralError} />}
                    <ReviewExpectedItemsTable
                        rows={purchaseDetails?.expectedItems || []}
                        categories={brokerCategories}
                        deliveredItems={deliveredItems}
                        locations={spreadLocations}
                        onAddItemToDelivered={onClickAddExtraItem}
                    />
                    {deliveredItems && deliveredItems?.length > 0 &&
                        <>
                            <p className='font-bold text-[18px] mt-7 mb-2'>Delivered Items</p>
                            <ExpectedAndDeliveredItemsTable
                                rows={purchaseDetails?.expectedItems || []}
                                categories={brokerCategories}
                                deliveredItems={deliveredItems}
                                templateFields={templateFields}
                                onDeleteDeliveredItem={onDeleteDeliveredItem}
                                onCopyDeliveredItem={onCopyDeliveredItem}
                            />
                        </>
                    }
                </div>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-row items-center my-3'>
                        <Button
                            label={'Save for Now'}
                            className={`${(deliveredItems && deliveredItems?.length > 0) ? 'btn-main' : 'btn-main-disable'} min-w-[160px] mr-2 !rounded`}
                            onClickButton={() => (deliveredItems && deliveredItems?.length > 0) && onSubmitPurchase(true)}
                        />
                        <Button
                            label={'Complete Review'}
                            className={`${(deliveredItems && deliveredItems?.length > 0) ? 'btn-primary' : 'btn-primary-disable'} !rounded min-w-[160px]`}
                            onClickButton={() => (deliveredItems && deliveredItems?.length > 0) && onSubmitPurchase(false)}
                        />
                    </div>
                    <div className='flex flex-row items-center justify-around min-w-[50%]'>
                        <p className='ml-4'>Expecting: {totalExpectedItems} item{(totalExpectedItems || 0) > 1 ? 's' : ''}</p>
                        <p className='ml-4 text-[#00b67a]'>Matching: {matchedItems || 0} item{(matchedItems || 0) > 1 ? 's' : ''}</p>
                        <p className='ml-4 text-[#e50000]'>Not Matching: {notmatchedItems || 0} item{(notmatchedItems || 0) > 1 ? 's' : ''}</p>
                        <p className='ml-4 text-[#afcade]'>Extra Items: {extraItems?.length || 0}</p>
                        <p className='ml-4 text-[#b4b4b4]'>Not Reviewed: {(Number(totalExpectedItems) - (notReview?.length || 0)) < 0 ? 0 : (Number(totalExpectedItems) - (notReview?.length || 0))} item{(Number(totalExpectedItems) - (notReview?.length || 0)) > 1 ? 's' : ''}</p>
                    </div>
                </div>
            </div>
            {showItemModal?.show &&
                <ReviewItemDetailsModal
                    openModal={showItemModal?.show}
                    rowSelected={showItemModal?.matchBody}
                    categories={brokerCategories}
                    availableLocations={spreadLocations}
                    deliveredItems={deliveredItems}
                    itemCopyData={showItemModal?.data}
                    currency={purchaseDetails?.currency}
                    handleCloseModal={onCloseItemModal}
                    onAddItemDetails={onAddItemToDelivered}
                />
            }
            {showLocationModal &&
                <ReviewItemLocationModal
                    openModal={showLocationModal || false}
                    warehouseId={activeWarehouse?._id}
                    mainLocations={mainLocations}
                    categories={purchaseDetails?.categories}
                    availableLocations={availableLocations}
                    allCategories={brokerCategories}
                    handleCloseModal={onClickShowLocation}
                    setAvailableLocations={setAvailableLocations}
                    setMainLocations={setMainLocations}
                />
            }
        </div>
    )
}

export default PurchaseReview;