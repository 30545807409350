import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';


interface ITableFilters {
    sectors?: any;
    // markets?: any;
    vat?: any;
    selectedSector?: any;
    // selectedMarket?: any;
    selectedWarehouse?: any;
    selectedCustomer?: any;
    selectedSupplier?: any;
    selectedVatType?: any;
    selectedDaysInInventory?: any;
    dateFiltersValues?: any;
    onChangeSelectedFilter: (e: any, type?: string, index?: any) => void;
    onChangeVatTypeFilter: (e: any, type?: string, index?: any) => void;
    onChangeCustomer?: (e: any, type?: string, index?: any) => void;
    onChangeDaysInInventoryFilter: (e: any, type?: string, index?: any) => void;
    onChangeSupplierFilter: (e: any, type?: string, index?: any) => void;
    onChangeDateFilters: (value: string | null | undefined, type: string) => void;
    onChangeSelectedWarehouse?: (e: any, type?: string, index?: any) => void;
    warehouses?: any
    combined?: any
    suppliers?: any
    VAT_TYPE?: any
    DAYS_IN_INVENTORY?: any
    showCustomerFilter?: boolean
}

const TableFilters = ({
    sectors,
    // markets,
    selectedSector,
    // selectedMarket,
    dateFiltersValues,
    onChangeSelectedFilter,
    onChangeDateFilters,
    warehouses,
    onChangeSelectedWarehouse,
    selectedWarehouse,
    DAYS_IN_INVENTORY,
    VAT_TYPE, 
    selectedVatType,
    selectedDaysInInventory,
    onChangeVatTypeFilter,
    onChangeDaysInInventoryFilter,
    onChangeSupplierFilter,
    suppliers,
    selectedSupplier,
    combined,
    selectedCustomer,
    onChangeCustomer,
    showCustomerFilter = false
}: ITableFilters) => {
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 sm:gap-2 lg:gap-4 mt-4 mb-7'>
            {warehouses && onChangeSelectedWarehouse &&  <SelectCheckbox
                name='Warehouse'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                selectedOption={selectedWarehouse}
                placeholder=' '
                uniqueName='sector'
                onChangeSelectedOption={onChangeSelectedWarehouse}
                options={warehouses || []}
            /> }
            <SelectCheckbox
                name='Category'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={`${(selectedSector?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                selectedOption={selectedSector}
                placeholder=' '
                uniqueName='sector'
                onChangeSelectedOption={onChangeSelectedFilter}
                options={sectors || []}
            />
            {showCustomerFilter && onChangeCustomer &&  <SelectCheckbox
                name='Customer'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={`${(selectedCustomer?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                selectedOption={selectedCustomer}
                placeholder=' '
                uniqueName='combined'
                onChangeSelectedOption={onChangeCustomer}
                options={combined || []}
            />}
            <SelectCheckbox
                name='VAT Type'
                dataQa={'vatType-filter'}
                placeholder={''}
                options={VAT_TYPE || []}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                onChangeSelectedOption={onChangeVatTypeFilter}
                selectedOption={selectedVatType}
                uniqueName={'vatType'}
            />
            <SelectCheckbox
                name='Suppliers'
                dataQa={'suppliers-filter'}
                placeholder={''}
                options={suppliers || []}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                onChangeSelectedOption={onChangeSupplierFilter}
                selectedOption={selectedSupplier}
                uniqueName={'suppliers'}
            />
            <SelectCheckbox
                name='Days in Inventory'
                dataQa={'daysInInvetory-filter'}
                placeholder={''}
                options={DAYS_IN_INVENTORY || []}
                selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                onChangeSelectedOption={onChangeDaysInInventoryFilter}
                selectedOption={selectedDaysInInventory}
                uniqueName={'daysInInvetory'}
            />
            {/* <SelectCheckbox
                name='Market'
                containerStyle='mb-1 min-w-[90%]'
                dataQa={'market-selected'}
                selectedOption={selectedMarket}
                placeholder=' '
                uniqueName='market'
                onChangeSelectedOption={onChangeSelectedFilter}
                options={markets || []}
            /> */}
            <Datepicker
                label='Date From'
                placeholder=' '
                dataQa={'date-from'}
                containerStyle='mb-1 min-w-[90%]'
                inputValue={dateFiltersValues?.from}
                onChangeInput={onChangeDateFilters}
                inputUniqueName={'from'}
            />
            <Datepicker
                label='Date To'
                placeholder=' '
                dataQa={'date-to'}
                containerStyle='mb-2 min-w-[90%]'
                inputValue={dateFiltersValues?.to}
                onChangeInput={onChangeDateFilters}
                inputUniqueName={'to'}
            />
        </div>
    )
}
export default TableFilters;