import React from 'react';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-types';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IInventoryDetailsCategories {
    markets: Array<any>;
    sources: Array<any>;
    inventorySelected?: string;
    reservedSelected?: string;
    selectedMargins?: any;
    selectedMarket?: any;
    selectedSource?: any;
    onClickMarket: (value: any, type?: string) => void;
    onClickInventoryFilter: (value: any, type?: string) => void;
    onClickReservedFilter: (value: any, type?: string) => void;
    onChangeSelectedMargins: (value: any, type?: string) => void;
    onChangeSource: (value: any, type?: string) => void;
}

const InventoryDetailsCategories = ({
    markets,
    sources,
    inventorySelected,
    reservedSelected,
    selectedMargins,
    selectedMarket,
    selectedSource,
    onClickMarket,
    onClickInventoryFilter,
    onClickReservedFilter,
    onChangeSelectedMargins,
    onChangeSource
}: IInventoryDetailsCategories) => {
    return (
        <div>
            <div className='my-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                <SelectCheckbox
                    name={'Market'}
                    placeholder={' '}
                    selectedOption={selectedMarket}
                    options={markets || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickMarket}
                    uniqueName={'market'}
                />
                <SelectCheckbox
                    name='Location'
                    placeholder={' '}
                    selectedOption={inventorySelected}
                    options={INVENTORY_FILTERS || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickInventoryFilter}
                    uniqueName={'inventory'}
                />
                <SelectCheckbox
                    name='Reserved'
                    placeholder={' '}
                    selectedOption={reservedSelected}
                    options={INVENTORY_FILTERS || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200, }}
                    onChangeSelectedOption={onClickReservedFilter}
                    uniqueName={'reserved'}
                />
                <SelectCheckbox
                    name='VAT Type'
                    placeholder=' '
                    onChangeSelectedOption={onChangeSelectedMargins}
                    options={VAT_TYPE_ITEMS}
                    selectedOption={selectedMargins}
                    multiple={true}
                />
                <SelectCheckbox
                    name='Source'
                    placeholder=' '
                    onChangeSelectedOption={onChangeSource}
                    options={sources}
                    selectedOption={selectedSource}
                    uniqueName='source'
                />
            </div>
        </div>
    )
}

export default InventoryDetailsCategories;