import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { ICreditNoteDetails } from '../../../../interfaces/credit-notes/ICreditNoteDetails';
import { PaginatedCreditNotes } from '../../../../interfaces/credit-notes/paginatedStore.type';

const tryToFetchCreditNotes = (
    pageNumber: number,
    pageSize: number,
    data?: any,
    filters?: any,
): Promise<AxiosResponse<PaginatedCreditNotes>> => {
    const url = filters ? `${INVENTORY_API_URL}/ims-credit-notes?page=${pageNumber}&size=${pageSize}&filters=${filters}` : `${INVENTORY_API_URL}/ims-credit-notes?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedCreditNotes>(url, data);
};

const tryToFetchCreditNoteDetails = (id: string): Promise<AxiosResponse<ApiResponse<ICreditNoteDetails>>> => {
    return inventoryAxios.get<ApiResponse<any>>(`${INVENTORY_API_URL}/ims-credit-notes/${id}`);
};

const tryToFetchCreditManagementByCustomer = (customerId: string): Promise<AxiosResponse<ApiResponse<ICreditNoteDetails>>> => {
    return inventoryAxios.get<ApiResponse<any>>(`${INVENTORY_API_URL}/credit-management/by-customer/${customerId}`);
};

const tryToAddCreditNote = (id: string, data: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/ims-credit-notes/${id}/notes`, data);
};

const tryToAddCredit = (data: string): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_API_URL}/credit-management`, data);
};

const tryToAddTransaction = (data: string): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_API_URL}/credit-management-transactions`, data);
};

const tryToGetCredit = (pageNumber: number,
    pageSize: number, search: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/credit-management/all?page=${pageNumber}&size=${pageSize}${search ? `&search=${search}` : ``}`);
};

const tryToGetTransactions = (pageNumber: number,
    pageSize: number, search: string, creditManagementId: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/credit-management-transactions/credit-management/${creditManagementId}?page=${pageNumber}&size=${pageSize}${search ? `&search=${search}` : ``}`);
};

const tryToGetCreditById = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/credit-management/${id}`);
};

const tryToGetCustomers = (): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/credit-management/customers`);
};

const tryToFetchCreditsNote = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/ims-credit-notes/${id}/notes`);
};

const tryToFetchAllCreditNotes = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/ims-credit-notes/all`, data);
};

const tryToDownloadCreditNotes = (data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/ims-credit-notes/download`, data);
};

const tryToMarkAsReceived = (id: string, data: any): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/ims-credit-notes/${id}/received`, data);
};

const tryToMarkAsUsed = (id: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/ims-credit-notes/${id}/used`);
};

export const CreditManagmentService = {
    tryToFetchCreditNotes,
    tryToFetchCreditNoteDetails,
    tryToAddCreditNote,
    tryToFetchCreditsNote,
    tryToFetchAllCreditNotes,
    tryToDownloadCreditNotes,
    tryToMarkAsReceived,
    tryToMarkAsUsed,
    tryToAddCredit,
    tryToGetCustomers,
    tryToGetCreditById,
    tryToGetCredit,
    tryToAddTransaction,
    tryToGetTransactions,
    tryToFetchCreditManagementByCustomer
};
