import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { onFormatPurchaseStatus } from '../../../../utils/constants/purchase-statuses';
import Button from '../../../../shared/button';


interface IPurchaseDetailsInfo {
    purchaseDetails: any;
    deliveredDate: any;
    onGoToParentOrder: () => void;
    onSeePackagesDetails: () => void;
    onClickShowMainView: () => void;
}

const PurchaseDetailsInfo = ({
    purchaseDetails,
    deliveredDate,
    onGoToParentOrder,
    onSeePackagesDetails,
    onClickShowMainView
}: IPurchaseDetailsInfo) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState<{ name: string, color?: string, text?: string }>()

    useEffect(() => {
        const orderName = onFormatPurchaseStatus(purchaseDetails?.status)?.name
        const color = onFormatPurchaseStatus(purchaseDetails?.status)?.color || 'bg-[#ebedef]'
        const text = onFormatPurchaseStatus(purchaseDetails?.status)?.text || 'text-black'
        setOrderStatusDetails({
            name: orderName,
            color,
            text,
        })
    }, [purchaseDetails?.status])

    return (
        <div className={`flex flex-row items-center rounded-md bg-white py-3 px-5 my-5 ${['discrepancy', 'completed',]?.includes?.(purchaseDetails?.status || '') ? 'justify-between' : ''}`}>
            <div className='flex flex-row items-center'>
                <div className={`${orderStatusDetails?.color} !border rounded-md ${orderStatusDetails?.text} mr-10 py-1 px-3 font-semibold`}>
                    {orderStatusDetails?.name}
                </div>
                {purchaseDetails?.quoteId &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Quote Id</p>
                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(purchaseDetails?.quoteId)}>
                            <img alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[17px] min-h-[17px] object-contain cursor-pointer mr-1 hover:opacity-70'} />
                            <p className='text-[14px]'>{purchaseDetails?.quoteId}</p>
                        </div>
                    </div>
                }
                {purchaseDetails?.parentQuoteId &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Parent Quote Id</p>
                        <div className='flex flex-row items-center cursor-pointer' onClick={onGoToParentOrder}>
                            <p className='text-[14px]'>{purchaseDetails?.parentQuoteId}</p>
                        </div>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.customer?.displayName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Customer</p>
                        <p className='text-[14px]'>{purchaseDetails?.purchaseInfo?.customer?.displayName}</p>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.partner?.companyName &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Broker</p>
                        <p className='text-[14px]'>{purchaseDetails?.purchaseInfo?.partner?.companyName}</p>
                    </div>
                }
                {purchaseDetails?.market?.label &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Market</p>
                        <p className='text-[14px]'>{purchaseDetails?.market?.label}</p>
                    </div>
                }
                {purchaseDetails?.purchaseInfo?.stockVat &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Stock VAT</p>
                        <p className='text-[14px]'>{purchaseDetails?.purchaseInfo?.stockVat}</p>
                    </div>
                }
                {!deliveredDate && purchaseDetails?.purchaseInfo?.dueDate &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Due Date</p>
                        <p className='text-[14px]'>{moment(purchaseDetails?.purchaseInfo?.dueDate).format('DD-MM-YYYY')}</p>
                    </div>
                }
                {deliveredDate &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Delivered Date</p>
                        <p className='text-[14px]'>{moment(deliveredDate).format('DD-MM-YYYY')}</p>
                    </div>
                }
                {((purchaseDetails?.packages && purchaseDetails?.packages?.length > 0) && ['delivered', 'in_review']?.includes(purchaseDetails?.status || '')) &&
                    <div className='flex flex-col mr-10'>
                        <p className='font-semibold text-[14px]'>Location of Boxes</p>
                        <div className='flex flex-row items-center cursor-pointer' onClick={onSeePackagesDetails}>
                            <p className='text-[14px] text-primary-default'>See Details</p>
                        </div>
                    </div>
                }
            </div>
            {['discrepancy', 'completed',]?.includes?.(purchaseDetails?.status || '') && <div>
                <Button
                    label={'Main Order View'}
                    className='btn-dark'
                    onClickButton={onClickShowMainView}
                />
            </div>
            }
        </div>
    )
}

export default PurchaseDetailsInfo;