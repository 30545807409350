import React from 'react';
import Modal from '../../../../shared/modal';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';
import Datepicker from '../../../../shared/datepicker';
import Button from '../../../../shared/button';


interface IAddNotesModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onSubmit: () => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeSelectedCustomer: (e: any) => void;
    onChangeMarket: (e: any) => void;
    brokerCustomers: any
    creditItem: any
    selectedBrokerCustomers: any
    selectedMarket: any
    markets: any
    errors: any
}
const AddCreditModal = ({
    openModal,
    brokerCustomers,
    creditItem,
    errors,
    selectedBrokerCustomers,
    selectedMarket,
    markets,
    onSubmit,
    onChangeInput,
    onChangeMarket,
    handleCloseModal,
    onChangeSelectedCustomer,
}: IAddNotesModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            showInRight
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <SelectCheckbox
                    name='Customer'
                    dataQa={'select-market'}
                    containerStyle='mb-1 min-w-full'
                    multiple={false}
                    selectedOption={selectedBrokerCustomers}
                    onChangeSelectedOption={(e) => {
                        onChangeSelectedCustomer(e)
                    }}
                    options={brokerCustomers || []}
                    error={errors?.customerId}
                />
                <SelectCheckbox
                    name='Market'
                    dataQa={'select-market'}
                    containerStyle='mb-1 min-w-full'
                    selectedOption={selectedMarket}
                    multiple={false}
                    error={errors?.market}
                    onChangeSelectedOption={(e) => onChangeMarket(e)}
                    options={markets || []}
                />
                <Input
                    containerStyle='mb-0'
                    label='Total Balance'
                    dataQa={'totalBalance'}
                    showValue={true}
                    inputValue={creditItem?.totalBalance}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'totalBalance'}
                    error={errors?.totalBalance}
                    type='number'
                />
                <Datepicker
                    label='Expiry Date'
                    placeholder='Optional'
                    dataQa={'expiryDate'}
                    containerStyle='mb-1 min-w-[90%]'
                    inputValue={creditItem?.expiryDate}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'expiryDate'}
                    error={errors?.expiryDate}
                />
                <Button
                    label='Add Credit'
                    onClickButton={onSubmit} />
            </div>
        </Modal>
    )
}

export default AddCreditModal;