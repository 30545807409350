import React, { useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatDiscrepancyStatus } from '../../../../utils/constants/discrepancy-format-status';
import TableActions from '../../../../shared/table/table-actions';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface IReviewDetailsPurchaseTable {
    rows: Array<any>;
    actions?: any;
    accessControl?: any;
    onClickItemAccept: (itemId: any) => void;
    onClickItemRevalue: (itemId: any) => void;
    onClickItemReturn: (itemId: any) => void;
    onClickItemCredit: (item: any) => void;
}

const ReviewDetailsPurchaseTable = ({
    rows,
    actions,
    accessControl,
    onClickItemAccept,
    onClickItemRevalue,
    onClickItemReturn,
    onClickItemCredit,
}: IReviewDetailsPurchaseTable) => {
    const [tableColumns] = useState<Array<string>>(['Nr.', 'Items', 'IMEI', 'IMEI Status', 'Location', 'Discrepancy Amount', 'Discrepancy Type', 'Actions'])

    // const totalExpectedValue = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + item?.price, 0);
    return (
        <Table
            columns={tableColumns}
            headerStyle='!bg-black !text-white'
            hidePagination={true}>
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                return (
                    <tr key={idx} className={`border-b`}>
                        <td className='px-6 py-4'>{idx + 1}</td>
                        <td className='px-6 py-4 max-w-[400px]'>
                            <div className='flex flex-wrap'>
                                <img src={row?.category?.icon} className={'h-[16px] min-h-[16px] object-contain'} />
                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                            {(index !== 0) &&
                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                </div>
                                            }
                                            <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                        <td className='px-6 py-4'>{row?.itemId || '--'}</td>
                        <td className='px-6 py-4'>{row?.imeiStatus || '--'}</td>
                        <td className='px-6 py-4 max-w-[230px]'>
                            {row?.section?.name && `${row?.section?.name} - `}
                            {row?.locations ? (Object?.keys(row?.locations))?.map((loc: any, index: number) => {
                                return `${index === 0 ? `${row?.locations?.[loc]}` : ` - ${row?.locations?.[loc]}`}`
                            }) : '--'}
                        </td>
                        <td className='px-6 py-4'>{(row?.price || row?.price === 0) ? CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`] : ''}{row?.price || row?.price === 0 ? Number(row?.price || 0).toFixed(2) : '--'}</td>
                        <td className={`px-6 py-4 ${onFormatDiscrepancyStatus(row?.discrepancyStatus)?.color}`}>{row?.discrepancyStatus && onFormatDiscrepancyStatus(row?.discrepancyStatus)?.label}</td>
                        <td className='px-4 py-4 min-w-[100px]'>
                            <div className='flex flex-row items-center'>
                                {['missing', 'not_match', 'not_matching', 'extra_item']?.includes(row?.discrepancyStatus) &&
                                    <TableActions>
                                        {/* <div
                                        // onClick={() => ((!accessControl || actions?.['Add Note']) && row?._id) && onClickShowNote(row._id)}
                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                        <Button
                                            label={'Leave a Note'}
                                            dataQa={'leave-note'}
                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Add Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                        />
                                    </div> */}
                                        {row?.discrepancyStatus === 'missing' &&
                                            <>
                                                <div
                                                    onClick={() => (!accessControl || actions?.['Awaiting Credit']) && onClickItemCredit(row)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Awaiting Credit'}
                                                        dataQa={'awaiting-credit'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Awaiting Credit']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        onClickButton={() => (!accessControl || actions?.['Awaiting Credit']) && onClickItemCredit(row)}
                                                    />
                                                </div>
                                            </>
                                        }
                                        {(['not_match', 'not_matching', 'extra_item']?.includes(row?.discrepancyStatus)) &&
                                            <>
                                                <div
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Accept'}
                                                        dataQa={'accept'}
                                                        onClickButton={() => (row?.price || row?.price === 0) && !row?.returned && (!accessControl || actions?.Accept) && onClickItemAccept(row?.itemId)}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(row?.price || row?.price === 0) && !row?.returned && (!accessControl || actions?.Accept) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                                <div
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Revalue'}
                                                        dataQa={'revalue'}
                                                        onClickButton={() => (row?.price || row?.price === 0) && !row?.returned && (!accessControl || actions?.Revalue) && onClickItemRevalue(row?.itemId)}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(row?.price || row?.price === 0) && (!accessControl || actions?.Revalue) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                                <div
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Return'}
                                                        dataQa={'return'}
                                                        onClickButton={() => !row?.returned && (!accessControl || actions?.Return) && onClickItemReturn(row?.itemId)}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${!row?.returned && (!accessControl || actions?.Return) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </TableActions>
                                }
                            </div>
                        </td>
                    </tr>
                )
            })}
            {/* {rows && rows?.length > 0 &&
                <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                    <td></td>
                    <td></td>
                    <td colSpan={3} className='px-6 py-4 text-end'>Open Discrepancy Amount:</td>
                    <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(rows?.[0]?.currency || '').toLowerCase()}`]}{totalExpectedValue || 0}</td>
                    <td className='px-6 py-4'></td>
                    <td className='px-6 py-4'></td>
                </tr>
            } */}
        </Table>
    )
}

export default ReviewDetailsPurchaseTable;