import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ISales } from '../../../../interfaces/sales/ISales';
import {
    tryToCreateReturnShippingNumber,
    tryToFetchCarriers
} from '../../../../store/inventory/shared/carriers/carriersSlice';
import { useGetAllMarketsMutation } from '../../../../store/user/userDomApi';
import { tryToShipSaleOrder } from '../../../../store/inventory/shared/sales/salesSlice';
import { tryToFetchPartnerWarehouses } from '../../../../store/inventory/broker/warehouses/warehousesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IShipOrderModal {
    openModal: boolean;
    saleId: string;
    saleDetails?: ISales;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const ShipOrderModal = ({
    openModal,
    saleId,
    saleDetails,
    handleCloseModal,
    onSuccessfullyChanged,
}: IShipOrderModal) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [shippingStep, setShippingStep] = useState<number>(1);
    const [warehouses, setWarehouses] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [allMarkets, setAllMarkets] = useState<Array<any>>();
    const [email, setEmail] = useState<any>();
    const [customerWillGet, setCustomerWillGet] = useState<boolean>(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>();
    const [shippingDetails, setShippingDetails] = useState<any>();
    const [addressDetails, setAddressDetails] = useState<any>();
    const [carriers, setCarriers] = useState<any>();
    const [trackingNumberGenerated, setTrackingNumberGenerated] = useState<string>();
    const [manualTrackingNumber, setManualTrackingNumber] = useState<any>();
    const [loadingGenerate, setLoadingGenerate] = useState<any>();
    const [carrierSelected, setCarrierSelected] = useState<any>();
    const [generateCarrierSelected, setGenerateCarrierSelected] = useState<any>();
    const [trackingNumber, setTrackingNumber] = useState<any>();
    const [invoiceUrl, setInvoiceUrl] = useState<any>();
    const [getAllMarkets] = useGetAllMarketsMutation();
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        setShippingStep(1)
        setCustomerWillGet(false)
        setShippingDetails(undefined)
        handleCloseModal()
    }

    const getMarketsData = async () => {
        const response = await getAllMarkets(null).unwrap()
        const formatMarkets = response && response?.length > 0 && response?.map((market: any) => ({ ...market, value: market?._id }))
        setAllMarkets(formatMarkets || [])
    }

    const onGettingCarriersData = async () => {
        try {
            const response = await dispatch(tryToFetchCarriers(null)).unwrap()
            const formatCarriers = response && response?.length > 0 && response.map((item: any) => ({ ...item, label: item?.displayName, value: item?.name }))
            setCarriers(formatCarriers || undefined)
        } catch (err) {
            // error
        }
    }

    const onGettingPartnerWarehouses = async () => {
        try {
            const response: any = await dispatch(tryToFetchPartnerWarehouses(saleDetails?.salesInfo?.partner?.id)).unwrap();
            const formatWarehouses = (response && response?.length > 0) && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setWarehouses(formatWarehouses || undefined)
        } catch (err) {
            // err here
        }
    }

    useEffect(() => {
        if (saleDetails?.salesInfo?.partner?.username) {
            onGettingPartnerWarehouses()
        }
    }, [saleDetails])

    useEffect(() => {
        onGettingCarriersData()
        getMarketsData()
    }, [])

    const onClickCustomerWillSend = () => {
        setCustomerWillGet(!customerWillGet)
    }

    const onApproveAction = async () => {
        setLoading(true)
        if (!customerWillGet) {
            if (manualTrackingNumber && (!carrierSelected || !trackingNumber)) {
                setError('Please select carrier and write tracking number to continue')
                return;
            } else if (!manualTrackingNumber && (!generateCarrierSelected)) {
                setError('Please select carrier to continue!')
                return;
            }
        }
        setError(undefined)
        try {
            const formatAddress = {
                ...addressDetails || {},
                addressLines: {
                    addressLine1: addressDetails?.street,
                    addressLine2: addressDetails?.addressNo
                }
            }
            let formatData: any = {
                customerCollect: customerWillGet,
                phone: phoneNumber,
                email: email,
                address: !customerWillGet && selectedWarehouse ? undefined : formatAddress,
                shippingDetails: !customerWillGet ? shippingDetails : undefined,
                weight: !customerWillGet ? shippingDetails?.weight : undefined,
                packages: !customerWillGet ? shippingDetails?.packages : undefined,
                activeWarehouse: activeWarehouse?._id,
                partnerWarehouse: !customerWillGet ? selectedWarehouse : undefined
            }
            if (manualTrackingNumber && !customerWillGet) {
                formatData = {
                    ...formatData || {},
                    trackingNumber: trackingNumber,
                    carrier: carrierSelected ? carrierSelected?.name || '' : '',
                    carrierId: carrierSelected ? carrierSelected?._id || '' : '',
                    invoiceUrl: invoiceUrl || undefined,
                };
            } else if (!customerWillGet && !manualTrackingNumber) {
                formatData = {
                    ...formatData || {},
                    trackingNumber: trackingNumberGenerated,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.name || '' : '',
                    carrierId: generateCarrierSelected ? generateCarrierSelected?._id || '' : '',
                    invoiceUrl: invoiceUrl || undefined,
                };
            }
            await dispatch(tryToShipSaleOrder({ id: saleId || '', data: formatData })).unwrap()
            onSuccessfullyChanged('success', 'Sale Order marked as shipped successfully!')
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }

    const onNextStep = () => {
        if (!phoneNumber || !email) {
            setError('Please fill the email and phone number to continue')
            return;
        }
        else if (!customerWillGet && (!selectedWarehouse && !addressDetails)) {
            setError('Please fill the data to continue')
            return;
        }
        if (!customerWillGet) {
            setShippingStep(2);
        } else {
            onApproveAction()
        }
        setError(undefined)
    }

    const onGenerateReturnTracking = async () => {
        try {
            const totalWeight: any = shippingDetails?.weight;
            const totalPrice = saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0 && saleDetails?.selectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price), 0)
            const itemsToShip = (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.map((item: any) => {
                return {
                    itemId: item?.itemId,
                    name: item?.descriptor ? Object.values(item?.descriptor).join(' ') : '',
                    qty: 1,
                    price: Number(item?.price || 0.01).toFixed(2),
                    currency: item?.currency,
                    hsCode: '',
                }
            })
            const itemIds = itemsToShip && itemsToShip?.length > 0 && itemsToShip?.map((item: any) => item?.itemId)
            const shipmentDataFormat = {
                createShipment: {
                    shipTo: {
                        name: saleDetails?.salesInfo?.partner?.companyName || saleDetails?.salesInfo?.customer?.displayName,
                        contactName: saleDetails?.salesInfo?.partner?.companyName || saleDetails?.salesInfo?.customer?.displayName,
                        phone: phoneNumber,
                        email: email,
                        address1: selectedWarehouse?.address?.street || addressDetails?.street,
                        address2: selectedWarehouse?.address?.addressNo || addressDetails?.addressNo,
                        city: selectedWarehouse?.address?.city || addressDetails?.city,
                        country: selectedWarehouse?.address?.country || addressDetails?.country,
                        postalCode: selectedWarehouse?.address?.postalCode || addressDetails?.postcode,
                        shipperReference: saleId,
                    },
                    shipmentInformation: {
                        shipmentDate: moment(new Date()).format('YYYY-MM-DD'),
                        packages: shippingDetails?.packages || 1,
                        totalWeight: totalWeight,
                        weightUnitOfMeasure: 'grams',
                        currency: saleDetails?.saleItems?.[0]?.currency,
                        value: Number(totalPrice || 0.01).toFixed(2),
                        description: 'wingpro',
                        items: itemsToShip,
                        serviceCode: activeWarehouse?.markets?.[0]?.name === 'US' ? 'ups' : 'TSN',
                    },
                },
                labelType: 'Outbound',
                url: true,
                qrCode: false,
                barcode: true,
                orderId: saleId,
                itemIds: itemIds || [],
                sourceRequesting: 'wingpro',
                carrierLocation: {
                    warehouseId: activeWarehouse?._id,
                    country: selectedWarehouse?.address?.country || addressDetails?.country,
                    carrier: generateCarrierSelected ? generateCarrierSelected?.value || '' : '',
                },
            };
            const response = await dispatch(tryToCreateReturnShippingNumber(shipmentDataFormat)).unwrap();
            setInvoiceUrl(response?.invoiceUrl || response?.imageUrl || undefined);
            setTrackingNumberGenerated(response?.trackingNumber || undefined);
            setError(undefined);
        } catch (err) {
            setError(`${err}`);
        }
        setLoadingGenerate(false);
    };

    useEffect(() => {
        if (generateCarrierSelected && activeWarehouse) {
            onGenerateReturnTracking();
        }
    }, [generateCarrierSelected, activeWarehouse]);

    const onChangeSelectedWarehouse = (value: any, type?: any) => {
        setSelectedWarehouse(value);
        setAddressDetails(undefined)
    }

    const onChangeAddressInput = (value: any, type?: any) => {
        if (type) {
            setAddressDetails({
                ...addressDetails || {},
                [type]: value
            })
            setSelectedWarehouse(undefined)
        }
    }

    const onChangeSelectedAddressInput = (value: any, type?: any) => {
        if (type) {
            setAddressDetails({
                ...addressDetails || {},
                [type]: value?.name
            })
            setSelectedWarehouse(undefined)
        }
    }

    const onChangeShippingInput = (value: any, type?: any) => {
        if (type) {
            setShippingDetails({
                ...shippingDetails || {},
                [type]: value
            })
        }
    }

    const onChangePhoneNumber = (value: any, type?: any) => {
        setPhoneNumber(value)
    }

    const onChangeEmail = (value: any, type?: any) => {
        setEmail(value)
    }

    const onChangeSelectedOptionGenerate = (e: any) => {
        setGenerateCarrierSelected(e || undefined);
        setLoadingGenerate(true);
    }

    const onClickManualTrackingNumber = () => {
        setManualTrackingNumber(!manualTrackingNumber);
    };

    const onChangeTrackingNumber = (e: any) => {
        setTrackingNumber(e || undefined);
    };

    const onChangeSelectedOption = (e: any) => {
        setCarrierSelected(e || undefined);
    };

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Generate Shipping Label'
            contentContainerStyle='min-w-[650px]'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[600px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Shipping Details ({shippingStep} of 2)</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    {shippingStep === 1 ?
                        <>
                            <div className='flex flex-row my-4'>
                                <p className='font-semibold'>Customer s Shipping Address</p>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <Input
                                    label='Customer'
                                    placeholder=' '
                                    disabled={true}
                                    showValue={true}
                                    inputValue={saleDetails?.salesInfo?.partner?.username || saleDetails?.salesInfo?.customer?.displayName}
                                />
                                <Input
                                    label='Phone Number'
                                    placeholder=' '
                                    showValue={true}
                                    inputValue={phoneNumber}
                                    inputUniqueName='phoneNumber'
                                    onChangeInput={onChangePhoneNumber}
                                />
                            </div>
                            <div className='grid grid-col-1'>
                                <Input
                                    label='Tracking Email'
                                    placeholder=' '
                                    showValue={true}
                                    inputValue={email}
                                    inputUniqueName='email'
                                    onChangeInput={onChangeEmail}
                                />
                            </div>
                            <div className='flex flex-row items-center mb-6 border-b pb-3'>
                                <div className='flex items-center mr-2'>
                                    <input
                                        onChange={() => onClickCustomerWillSend()}
                                        checked={customerWillGet}
                                        id='checkbox-all'
                                        data-qa={'checkbox'}
                                        type='checkbox'
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                    />
                                    <label htmlFor='checkbox-all' className='sr-only'>
                                        checkbox
                                    </label>
                                </div>
                                <p className='text-primary-default font-bold'>
                                    Customer will collect this shipment
                                </p>
                            </div>
                            <div>
                                {saleDetails?.salesInfo?.partner?.username &&
                                    <>
                                        <p className='my-4'>Do you want to sent it a warehouse or to a specific address?</p>
                                        <SelectCheckbox
                                            selectedOption={selectedWarehouse}
                                            options={warehouses || []}
                                            name={'Warehouse'}
                                            disabled={customerWillGet}
                                            placeholder=' '
                                            onChangeSelectedOption={onChangeSelectedWarehouse}
                                        />
                                        <p className='my-3 text-center'>or</p>
                                    </>
                                }
                                <div className='grid grid-cols-1 gap-4'>
                                    <Input
                                        label='Address Line 1'
                                        placeholder=' '
                                        showValue={true}
                                        disabled={customerWillGet}
                                        inputValue={addressDetails?.street}
                                        inputUniqueName='street'
                                        containerStyle='mb-0'
                                        onChangeInput={onChangeAddressInput}
                                    />
                                    <Input
                                        label='Address Line 2 (Optional)'
                                        placeholder=' '
                                        showValue={true}
                                        disabled={customerWillGet}
                                        inputValue={addressDetails?.addressNo}
                                        inputUniqueName='addressNo'
                                        containerStyle='mb-0'
                                        onChangeInput={onChangeAddressInput}
                                    />
                                </div>
                                <div className='grid grid-cols-2 gap-4'>
                                    <Input
                                        label='State/Province'
                                        placeholder=' '
                                        showValue={true}
                                        disabled={customerWillGet}
                                        inputValue={addressDetails?.state}
                                        inputUniqueName='state'
                                        onChangeInput={onChangeAddressInput}
                                    />
                                    <Input
                                        label='City'
                                        placeholder=' '
                                        showValue={true}
                                        disabled={customerWillGet}
                                        inputValue={addressDetails?.city}
                                        inputUniqueName='city'
                                        onChangeInput={onChangeAddressInput}
                                    />
                                    <SelectCheckbox
                                        name='Country'
                                        placeholder=' '
                                        selectedOption={addressDetails?.country ? allMarkets?.find((item: any) => item?.name === addressDetails?.country) : undefined}
                                        disabled={customerWillGet}
                                        options={allMarkets || []}
                                        uniqueName='country'
                                        onChangeSelectedOption={onChangeSelectedAddressInput}
                                    />
                                    <Input
                                        label='Postcode / ZIP'
                                        placeholder=' '
                                        showValue={true}
                                        disabled={customerWillGet}
                                        inputValue={addressDetails?.postcode}
                                        inputUniqueName='postcode'
                                        onChangeInput={onChangeAddressInput}
                                    />
                                </div>
                            </div>
                            {error &&
                                <div className='flex flex-row justify-center my-5'>
                                    <Error text={error} />
                                </div>
                            }
                            <div className='flex flex-row justify-center my-5'>
                                <Button
                                    label='Next'
                                    dataQa={'next-button'}
                                    className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                                    onClickButton={() => !loading && onNextStep()}
                                />
                            </div>
                        </>
                        : <>
                            <div className='flex flex-row justify-center my-4'>
                                <p className='text-[18px]'>How will you send this shipment?</p>
                            </div>
                            <p className='font-bold my-4'>Shipping Box Information</p>
                            <div className='grid grid-cols-2 gap-4'>
                                <Input
                                    label='Number of boxes'
                                    placeholder=' '
                                    showValue={true}
                                    inputValue={shippingDetails?.packages}
                                    inputUniqueName='packages'
                                    onChangeInput={onChangeShippingInput}
                                />
                                <Input
                                    label='Weight (Grams)'
                                    placeholder=' '
                                    showValue={true}
                                    inputValue={shippingDetails?.weight}
                                    inputUniqueName='weight'
                                    onChangeInput={onChangeShippingInput}
                                />
                            </div>
                            <div className='flex flex-col items-center border-t-2 border-dashed pt-4'>
                                <SelectCheckbox
                                    selectedOption={generateCarrierSelected}
                                    key={1}
                                    disabled={manualTrackingNumber}
                                    placeholder='Carrier'
                                    containerStyle='mb-1 min-w-[90%]'
                                    isLoading={loadingGenerate}
                                    onChangeSelectedOption={onChangeSelectedOptionGenerate}
                                    options={carriers || []}
                                    dataQa={'carrieer-selected'}
                                />
                                <Input
                                    placeholder='Tracking Number'
                                    dataQa={'tracking-number'}
                                    showValue={true}
                                    inputValue={trackingNumberGenerated}
                                    disabled={true}
                                    containerStyle={'min-w-[90%]'}
                                />
                            </div>
                            <div className='flex flex-row justify-center items-center my-4 min-w-[100%]'>
                                <div className='flex items-center mr-2'>
                                    <p className='font-semibold mr-1 text-[16px]'>
                                        Manual tracking number?
                                    </p>
                                    <input
                                        onChange={onClickManualTrackingNumber}
                                        checked={manualTrackingNumber}
                                        id='checkbox-all'
                                        type='checkbox'
                                        data-qa={'checkbox'}
                                        className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                    />
                                    <label htmlFor='checkbox-all' className='sr-only'>
                                        checkbox
                                    </label>
                                </div>
                            </div>
                            {manualTrackingNumber && (
                                <div className='flex flex-col items-center my-4'>
                                    <SelectCheckbox
                                        selectedOption={carrierSelected}
                                        key={1}
                                        placeholder='Carrier'
                                        dataQa={'carrier-selected'}
                                        containerStyle='mb-1 min-w-[90%]'
                                        onChangeSelectedOption={(e) => onChangeSelectedOption(e)}
                                        options={carriers || []}
                                    />
                                    <Input
                                        placeholder='Tracking Number'
                                        dataQa={'tracking-number'}
                                        showValue={true}
                                        inputValue={trackingNumber}
                                        onChangeInput={onChangeTrackingNumber}
                                        containerStyle={'min-w-[90%]'}
                                    />
                                </div>
                            )}
                            {error &&
                                <div className='flex flex-row justify-center my-5'>
                                    <Error text={error} />
                                </div>
                            }
                            <div className='flex flex-row justify-center my-5'>
                                <Button
                                    label='Confirm'
                                    dataQa={'confirm-button'}
                                    className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                                    onClickButton={() => !loading && onApproveAction()}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default ShipOrderModal;