import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAddress } from '../../../../interfaces/auth/ICreateUserSchema';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import SelectCheckbox from '../../../../shared/select-checkbox';

type FormValues = {
    email: string;
    name: string;
    username: string;
    companyName: string;
    country: string;
    sectors: string | string[];
    markets: string | string[];
    addresses?: IAddress[];
    vatNumber: string;
    companyRegistrationNumber: string;
    phoneNumber: string;
    annualGMV: string;
    password: string;
    confirmPassword: string;
    url: string;
};

interface IDetailsStep {
    step: number;
    sectorItems: Array<any>;
    marketItems: Array<any>;
    createError?: string;
    onRequestToRegisterPartner: (data: IPartners) => Promise<boolean>;
}

const DetailsStep = ({
    step,
    sectorItems,
    marketItems,
    createError,
    onRequestToRegisterPartner,
}: IDetailsStep) => {
    const [selectedSectors, setSelectedSectors] = useState<Array<any>>();
    const [selectedMarkets, setSelectedMarkets] = useState<Array<any>>();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm<FormValues>();

    const onSubmit = async (data: FormValues) => {
        const filterSectors = selectedSectors?.map((sector) => {
            return { name: sector?.name, id: sector?.value }
        })
        const formatMarkets = selectedMarkets && selectedMarkets?.length > 0 && selectedMarkets?.map((market: any) => ({ id: market?.value }))
        const payload: any = {
            ...data,
            enabled: false,
            sectors: filterSectors,
            markets: formatMarkets,
            username: (data?.username).toLowerCase(),
            type: 'organization-account',
            dashboard: 'inventory'
        };
        delete payload.confirmPassword;
        const response = await onRequestToRegisterPartner(payload);
        if (response) {
            reset();
        }
    };

    const onChangeSelectedOption = (e: any) => {
        setSelectedSectors(e?.length > 0 ? e?.map((s: any) => ({ ...s })) : [])
        setValue(
            'sectors',
            e?.length > 0 ? e?.map((s: any) => s?.value) : undefined,
        );
    };

    const onChangeMarketSelected = (e: any) => {
        setSelectedMarkets(e?.length > 0 ? e?.map((s: any) => s) : [])
        setValue(
            'markets',
            e?.length > 0 ? e?.map((s: any) => s?.value) : undefined,
        );
    };

    return (
        <div
            className={`${step === 2 ? 'block' : 'hidden'} min-h-full flex flex-col py-4 px-3`}>
            <p className={'heading-four mb-10'}>
                Tell us more about your company
            </p>
            <form className='mt-4 mb-2' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    containerStyle='mb-1'
                    placeholder='Full Name'
                    register={register('name', {
                        required: {
                            message: 'Full Name is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Full Name is required',
                    })}
                    error={errors.name?.message}
                />
                <Input
                    containerStyle='mb-1'
                    placeholder='Username'
                    register={register('username', {
                        required: {
                            message: 'Username is required',
                            value: true,
                        },
                        pattern: {
                            /* eslint-disable-next-line */
                            value: /^\S*$/,
                            message: 'Username should not include spaces'
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Username is required',
                    })}
                    error={errors.username?.message}
                />
                <Input
                    placeholder='Company Name'
                    containerStyle='mb-1'
                    register={register('companyName', {
                        required: {
                            message: 'Company Name is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Company Name is required',
                    })}
                    error={errors.companyName?.message}
                />
                <Input
                    placeholder='Country'
                    containerStyle='mb-1'
                    register={register('country', {
                        required: {
                            message: 'Country is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Country is required',
                    })}
                    error={errors.country?.message}
                />
                <Input
                    placeholder='Broker URL'
                    containerStyle='mb-1'
                    register={register('url', {
                        required: {
                            message: 'Broker URL is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Broker URL is required',
                    })}
                    error={errors.url?.message}
                />
                <SelectCheckbox
                    placeholder='Category'
                    onChangeSelectedOption={onChangeSelectedOption}
                    options={sectorItems}
                    selectedOption={selectedSectors}
                    multiple={true}
                    error={
                        Object.keys(errors).length !== 0 &&
                            (!watch('sectors') || watch('sectors')?.length <= 0)
                            ? 'Category is required'
                            : undefined
                    }
                />
                <SelectCheckbox
                    placeholder='Markets'
                    onChangeSelectedOption={onChangeMarketSelected}
                    options={marketItems}
                    selectedOption={selectedMarkets}
                    multiple={true}
                    error={
                        Object.keys(errors).length !== 0 &&
                            (!watch('markets') || watch('markets')?.length <= 0)
                            ? 'Market is required'
                            : undefined
                    }
                />
                <Input
                    placeholder='VAT Number'
                    containerStyle='mb-1'
                    register={register('vatNumber', {
                        required: {
                            message: 'VAT Number is required',
                            value: true,
                        },
                        minLength: {
                            value: 8,
                            message: 'Minimum number of characters is 8'
                        },
                        validate: (value: string) => !!value.trim() || 'VAT Number is required'
                    })}
                    error={errors.vatNumber?.message}
                />
                <Input
                    placeholder='Company Registration Number'
                    containerStyle='mb-1'
                    register={register('companyRegistrationNumber', {
                        required: {
                            message: 'Company Registration Number is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() ||
                            'Company Registration Number is required',
                    })}
                    error={errors.companyRegistrationNumber?.message}
                />
                <Input
                    placeholder='Phone Number'
                    containerStyle='mb-1'
                    register={register('phoneNumber', {
                        required: {
                            message: 'Phone Number is required',
                            value: true,
                        },
                        pattern: {
                            value: /^\+[0-9 ]+$/,
                            message:
                                'Please write a valid number ex. +123456789',
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Phone Number is required',
                    })}
                    error={errors.phoneNumber?.message}
                />
                <Input
                    placeholder='Annual GMV'
                    containerStyle='mb-1'
                    register={register('annualGMV', {
                        required: {
                            message: 'Annual GMV is required',
                            value: true,
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Annual GMV is required',
                    })}
                    error={errors.annualGMV?.message}
                />
                <hr className='min-w-full my-3' />
                <Input
                    label='Set a password (This will be used only by Admins)'
                    containerStyle='mb-1 mt-2'
                    type={'password'}
                    register={register('password', {
                        required: {
                            message: 'Password is required',
                            value: true,
                        },
                        minLength: {
                            value: 8,
                            message: 'Minimum number of characters is 8',
                        },
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message:
                                'Password should be at least 8 characters long, and must contain at least 1 number, 1 lower case, 1 upper case and 1 special character(@$!%*?&)',
                        },
                        validate: (value: string) =>
                            !!value.trim() || 'Password is required',
                    })}
                    error={errors.password?.message}
                />
                <Input
                    label='Repeat Password'
                    containerStyle='mb-1 mt-2'
                    type={'password'}
                    register={register('confirmPassword', {
                        required: {
                            message: 'Repeat password is required',
                            value: true,
                        },
                        validate: (val: string) => {
                            if (watch('password') !== val) {
                                return 'Repeat password do not match';
                            }
                        },
                    })}
                    error={errors.confirmPassword?.message}
                />
                <p className='my-2'>
                    By proceeding, you agree to our{' '}
                    <span className='text-[#F7A21E]'>Terms & Conditions</span>.
                    To find out what personal data we collect and how we use it,
                    please visit our{' '}
                    <span className='text-[#F7A21E]'>Privacy Policy</span>.
                </p>
                {createError && <Error text={createError} />}
                <div className='flex flex-row justify-end py-2'>
                    <Button
                        label='Next'
                        type={'submit'}
                        className={'btn-primary'}
                    />
                </div>
            </form>
        </div>
    );
};

export default DetailsStep;
