import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToValidatedImportedItems } from '../../../../store/inventory/broker/purchases/purchasesSlice';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';


interface IImportPurchaseItemsModal {
    openImportModal: boolean;
    category?: any;
    templateFields?: any;
    inventoryFields?: any;
    handleCloseModal: () => void;
    onSuccessfullyUploadItems: (data: any, category: any) => void;
}

const ImportPurchaseItemsModal = ({
    openImportModal,
    category,
    templateFields,
    inventoryFields,
    handleCloseModal,
    onSuccessfullyUploadItems
}: IImportPurchaseItemsModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()
    const [rowsError, setRowsError] = useState<any>()
    const [showReplacements, setShowReplacements] = useState<{ show: boolean, data?: any }>();
    const [successRows, setSuccessRows] = useState<any>();

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
        setRowsError(undefined)
        setImportError(undefined)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setFileSelected(undefined)
        setRowsError(undefined)
        setShowReplacements(undefined)
        setSuccessRows(undefined)
    }

    const onUploadFile = async () => {
        const errorInRows: any = []
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const formatData: any = []
                    results?.data && results?.data?.length > 0 && results?.data?.map((item: any, index: number) => {
                        const newObj: any = {};
                        let isNonEmptyObject = false;
                        for (const key in item) {
                            if (typeof item[key] === 'string') {
                                const trimmedValue = item[key].trim();
                                newObj[key] = trimmedValue;
                                if (trimmedValue !== '') {
                                    isNonEmptyObject = true;
                                }
                            } else if (item[key] !== null && item[key] !== undefined) {
                                newObj[key] = item[key];
                                isNonEmptyObject = true;
                            }
                        }
                        if (isNonEmptyObject) {
                            formatData.push(
                                {
                                    ...newObj || {},
                                    index: index + 2
                                }
                            )
                        }
                        return item
                    })
                    const payload = {
                        assetTemplateId: category?.value,
                        data: formatData || []
                    }
                    const response = await dispatch(tryToValidatedImportedItems(payload)).unwrap();
                    const formatDataSuccess: any = []
                    response?.data && response?.data?.length > 0 && response?.data?.map((item: any) => {
                        if (item?.succeed) {
                            let templateFieldsFormated = {}
                            let inventoryFieldsFormated = {}
                            templateFields && templateFields?.length > 0 && templateFields?.map((field: any) => {
                                templateFieldsFormated = {
                                    ...templateFieldsFormated || {},
                                    [field]: item?.[field]
                                }
                                return field
                            })
                            inventoryFields && inventoryFields?.length > 0 && inventoryFields?.map((field: any) => {
                                inventoryFieldsFormated = {
                                    ...inventoryFieldsFormated || {},
                                    [field]: item?.[field]
                                }
                                return field
                            })
                            formatDataSuccess.push({
                                descriptor: templateFieldsFormated,
                                inventory: inventoryFieldsFormated,
                                price: item?.price,
                                qty: item?.quantity,
                                categoryId: category?.value
                            })
                        } else {
                            errorInRows.push(item)
                        }
                        return item
                    })
                    if (response?.replacements && response?.replacements?.length > 0) {
                        setShowReplacements({
                            show: true,
                            data: response?.replacements
                        })
                        setSuccessRows(formatDataSuccess)
                    } else {
                        onSuccessfullyUploadItems(formatDataSuccess, category)
                    }
                    if (!errorInRows || !(errorInRows?.length > 0)) {
                        setImportError(undefined)
                        setFileSelected(undefined)
                        if (!(response?.replacements && response?.replacements?.length > 0)) {
                            setImportedSuccessfully(true)
                        }
                        setRowsError(undefined)
                    } else {
                        setRowsError(errorInRows)
                    }
                }
            })
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    const onAcceptSuggestions = () => {
        onSuccessfullyUploadItems(successRows, category)
        if (!(rowsError && rowsError?.length > 0)) {
            setImportedSuccessfully(true)
        }
        setShowReplacements(undefined)
    }

    return (
        <Modal
            open={openImportModal}
            showInRight={true}
            title='Upload Items by CSV'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <div className={'w-full flex flex-col items-center justify-between'}>
                        <div>
                            <p className='text-md font-medium'>Items have been successfully added to the table.</p>
                        </div>
                        <img src={'/assets/shared/success-mark.svg'} className={'h-[100px] object-contain my-10'} />
                        <Button
                            label='Close'
                            className='btn-primary-rounded-less my-3 min-w-[150px] !shadow-none'
                            onClickButton={handleCloseModal}
                        />
                    </div>
                    : <div className='p-1'>
                        <div className='mb-3'>
                            <p className='text-sm font-semibold'>Upload purchase order items by a csv file</p>
                        </div>
                        <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                            <p className='text-md mb-2 mt-1'>Import data for {category?.label || category?.name}</p>
                            {importError && <Error text={importError} />}
                            {(rowsError && rowsError?.length > 0) &&
                                <div className='flex flex-col !text-red-600'>
                                    <p className='mb-2'>These rows were not valid and can&lsquo;t be imported: </p>
                                    <div className="flex flex-wrap">
                                        {rowsError?.map((item: any, index: number) => (
                                            <span key={index} className="mb-1">
                                                {index === 0 ? '' : ', '}
                                                {item?.index}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className='flex flex-col justify-center rounded my-5'>
                                <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                        icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main min-w-[150px] !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                        label={'Upload file'}
                                        dataQa={'select-file'}
                                    />
                                </div>
                                {fileSelected && <p className='mt-2 mb-5 font-bold'>{fileSelected?.name}</p>}
                            </div>
                            {showReplacements?.show &&
                                <div className='my-5 border-t-2 pt-3'>
                                    <p className='font-semibold text-[16px] mb-2'>Suggested Replacements for File Columns</p>
                                    {showReplacements?.data && showReplacements?.data?.length > 0 && showReplacements?.data?.map((item: any, index: number) => {
                                        return (
                                            <div key={index} className='flex flex-row items-center my-1 mx-1'>
                                                <p className='font-bold'>- {item?.name}</p>
                                                <p className='mx-1'>with</p>
                                                <p className='font-bold'>{item?.replaceWith}</p>
                                            </div>
                                        )
                                    })}
                                    <p className='italic'>You can choose to accept or decline these suggestions as needed.</p>
                                </div>
                            }
                            <div className='flex flex-row items-center justify-center my-2'>
                                <Button
                                    label={'Close'}
                                    className='btn-primary-rounded-less mr-4 min-w-[120px]'
                                    onClickButton={onCloseModal}
                                />
                                {showReplacements?.show ?
                                    <Button
                                        label={'Accept Suggestions'}
                                        onClickButton={() => onAcceptSuggestions()}
                                        className={'btn-primary-rounded-less !shadow-none min-w-[150px]'}
                                    />
                                    : <Button
                                        label='Confirm'
                                        onClickButton={() => fileSelected && !rowsError && onUploadFile()}
                                        className={`${fileSelected && !rowsError ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} !shadow-none min-w-[150px]`}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default ImportPurchaseItemsModal;
