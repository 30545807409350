import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Display Name', 'Name', 'Asset Data']

interface ISaleAssetsTable {
    rows: IAssetTemplate[];
    rowsLoading: boolean;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeFieldDetails: (id: string) => void;
}

const SaleAssetsTable = ({
    rows,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    setPaginationState,
    onSeeFieldDetails
}: ISaleAssetsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.displayName}</td>
                                <td className='px-6 py-4'>{row?.name}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'See Fields'}
                                        className={row?.enabled && (!accessControl || actions?.['See Asset Catalog Fields']) ? 'btn-filters' : 'btn-noactive-filters'}
                                        onClickButton={() => row?._id && (!accessControl || actions?.['See Asset Catalog Fields']) && onSeeFieldDetails(row?._id)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SaleAssetsTable;