import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToGetTransactions } from '../../../../store/inventory/broker/credit-managment/creditManagmentSlice';
import Table from '../../../../shared/table';

const tableColumns = ['Tranasction ID', 'Amount', 'Issued By', 'Type']

interface ITransactionsTable {
    search: string,
    creditManagementId: string,
    loading: boolean,
    currency: string
}

const TransactionsTable = ({
    search,
    creditManagementId,
    loading,
    currency
}: ITransactionsTable) => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([])
    const [paginationData, setPaginationData] = useState()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const getTransactions = async () => {
        try {
            const data = await dispatch(tryToGetTransactions({ ...paginationState, search: `${search || ''}`, creditManagementId })).unwrap()
            setPaginationData(data?.page)
            setRows(data?.elements)
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            if (search) {
                searchParams.set('search', `${search}`)
            } else {
                searchParams.delete('search')
            }
            setSearchParams(searchParams)
        } catch (error) {
            // error here
        }
    }

    useEffect(() => {
        if (!loading) {
            getTransactions()
        }
    }, [paginationState, search, loading])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?._id}</td>
                        <td className='px-6 py-4'>{currency}{(row?.amount || 0).toFixed(2) || ''}</td>
                        <td className='px-6 py-4'>{row?.details?.issuedBy?.name}</td>
                        <td className='px-6 py-4'>{row?.type}</td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default TransactionsTable;