import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import Button from '../../../shared/button';
import SalesCategory from './categories/SalesCategory';
import PurchasesCategory from './categories/PurchasesCategory';
import InventoryCategory from './categories/InventoryCategory';


const templateCategories = [
    { name: 'Sales', element: <SalesCategory /> },
    { name: 'Purchases', element: <PurchasesCategory /> },
    { name: 'Inventory', element: <InventoryCategory /> },
]

const AdminReports = () => {
    const [activeCategory, setActiveCategory] = useState<any>(templateCategories[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Reports Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveCategory = findChild?.actions?.[templateCategories[0]?.name] ? templateCategories[0] : templateCategories?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveCategory(findActiveCategory)
        }
    }, [accessControl])

    const onChangeActiveCategory = (item: any) => {
        setActiveCategory(item)
    }

    return (
        <div>
            <div>
                <p className='page-title'>Reports</p>
            </div>
            <div className='flex flex-row items-center flex-wrap my-5'>
                {templateCategories?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((category, index) => {
                    return (
                        <Button
                            key={index}
                            label={category?.name}
                            dataQa={`${category?.name}`}
                            className={activeCategory?.name === category?.name ? 'btn-categories ml-2 min-w-[120px] mb-1' : 'btn-noactive-categories ml-2 min-w-[120px] mb-1'}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeCategory?.name]) && activeCategory?.element}
            </div>
        </div >
    )
}

export default AdminReports;