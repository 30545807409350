import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { PaginatedWarehouseLocations, PaginatedWarehouseSections } from '../../../../interfaces/warehouse-locations/paginatedStore.type';
import { IWarehouseSections } from '../../../../interfaces/warehouse-locations/IWarehouseSections';


const tryToFetchWarehouseSections = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedWarehouseSections>> => {
    const url = filters ? `${USERS_API_URL}/spaces?page=${pageNumber}&size=${pageSize}&search=${filters}` : `${USERS_API_URL}/spaces?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedWarehouseSections>(url, data);
};

const tryToFetchAllWarehouseSections = (warehouseId: string, type?: string, category?: string): Promise<AxiosResponse> => {
    let url = `${USERS_API_URL}/spaces/all/${warehouseId}`;
    if (type) {
        url = `${USERS_API_URL}/spaces/all/${warehouseId}?type=${type}`;
    }
    if (category) {
        url = url + `${type ? '&' : '?'}category=${category}`;
    }
    return brokersAxios.get(url);
};

const tryToFetchSingleWarehouseSection = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/spaces/${id}`);
};

const tryToEditWarehouseSection = (id: string, data: IWarehouseSections): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/spaces/${id}`, data);
};

const tryToCreateWarehouseSection = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/spaces`, data);
};

const tryToDeleteWarehouseSection = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/spaces/${id}`);
};

const tryToGetWarehouseSectionChild = (id: string, childId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/spaces/${id}/child/${childId}`);
};

const tryToAddWarehouseSectionChild = (id: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/spaces/${id}/child`, data);
};

const tryToEditWarehouseSectionChild = (id: string, childId: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/spaces/${id}/child/${childId}`, data)
};

const tryToDeleteWarehouseSectionChild = (id: string, childId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/spaces/${id}/child/${childId}`);
};

const tryToFetchSectionChilds = (id: string, data?: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/spaces/${id}/childs`, data);
};

const tryToFetchWarehouseLocations = (
    pageNumber: number,
    pageSize: number,
    id: string,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedWarehouseLocations>> => {
    const url = filters ? `${USERS_API_URL}/locations/${id}?page=${pageNumber}&size=${pageSize}&search=${filters}` : `${USERS_API_URL}/locations/${id}?page=${pageNumber}&size=${pageSize}`
    return brokersAxios.put<PaginatedWarehouseLocations>(url, data);
};

const tryToEditWarehouseLocationBarcode = (id: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/${id}/barcode`, data);
};

const tryToGenerateSectionLocations = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/spaces/${id}/locations/generate`);
};

const tryToFetchSectionLocations = (barcode: string, data?: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/search/${barcode}`, data);
};

const tryToCheckChildStateAndLocation = (id: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/search/${id}/check`);
};

const tryToChangeChildsLocation = (data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/move-stock`, data);
};

const tryToFetchSectionChildsValues = (id: string, data?: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/${id}/values`, data);
};


export const WarehouseLocationsService = {
    tryToFetchWarehouseSections,
    tryToFetchSingleWarehouseSection,
    tryToFetchAllWarehouseSections,
    tryToEditWarehouseSection,
    tryToCreateWarehouseSection,
    tryToDeleteWarehouseSection,
    tryToGetWarehouseSectionChild,
    tryToAddWarehouseSectionChild,
    tryToEditWarehouseSectionChild,
    tryToDeleteWarehouseSectionChild,
    tryToFetchSectionChilds,
    tryToFetchWarehouseLocations,
    tryToEditWarehouseLocationBarcode,
    tryToGenerateSectionLocations,
    tryToFetchSectionLocations,
    tryToCheckChildStateAndLocation,
    tryToChangeChildsLocation,
    tryToFetchSectionChildsValues
};