import React from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { INVENTORY_FILTERS } from '../../../../utils/constants/inventory-filter-values';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-types';
import { COLOR_TYPES } from '../../../../utils/constants/color-states';


interface IInventoryMainFilter {
    selectedMarket?: any;
    inventorySelected?: any;
    markets?: any;
    colorState: any;
    selectedMargins?: any;
    onClickInventoryFilter: (value: any, type?: string) => void;
    onChangeSelectedMargins: (value: any, type?: string) => void;
    onClickColorState: (state: string) => void;
    onClickMarket: (value: any, type?: string) => void;
}

const InventoryMainFilter = ({
    markets,
    colorState,
    selectedMarket,
    inventorySelected,
    selectedMargins,
    onClickMarket,
    onClickColorState,
    onClickInventoryFilter,
    onChangeSelectedMargins
}: IInventoryMainFilter) => {
    return (
        <>
            <SelectCheckbox
                name='Location'
                placeholder='Select'
                dataQa={inventorySelected?.name === undefined ? 'locations-selected' : inventorySelected?.name.toLowerCase().replaceAll(' ', '-')}
                containerStyle=''
                options={INVENTORY_FILTERS || []}
                selectedOption={inventorySelected}
                onChangeSelectedOption={onClickInventoryFilter}
                uniqueName='inventory'
            />
            <SelectCheckbox
                name='Market'
                placeholder='Select'
                dataQa={selectedMarket?.name === undefined ? 'market-selected' : selectedMarket?.name.toLowerCase().replaceAll(' ', '-')}
                containerStyle=''
                options={markets || []}
                selectedOption={selectedMarket}
                onChangeSelectedOption={onClickMarket}
                uniqueName='market'
            />
            <SelectCheckbox
                name='VAT Type'
                placeholder='Select'
                containerStyle=''
                onChangeSelectedOption={onChangeSelectedMargins}
                options={VAT_TYPE_ITEMS}
                selectedOption={selectedMargins}
                multiple={true}
            />
            <SelectCheckbox
                name='Colour'
                placeholder='Select'
                dataQa={selectedMarket?.name === undefined ? 'color-selected' : selectedMarket?.name.toLowerCase().replaceAll(' ', '-')}
                containerStyle=''
                options={COLOR_TYPES || []}
                selectedOption={colorState}
                onChangeSelectedOption={onClickColorState}
                uniqueName='colour'
            />
        </>
    )
}
export default InventoryMainFilter