import React from 'react';
import Select from '../../../../shared/select';

interface ISalesReviewFilters {
    markets?: Array<any>;
    partners?: Array<any>;
    sectors?: Array<any>;
    selectedMarket?: string;
    selectedPartner?: string;
    selectedSector?: string;
    onChangeSelectedFilter: (e: any, name?: string) => void;
}

const SalesReviewFilters = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    onChangeSelectedFilter
}: ISalesReviewFilters) => {
    return (
        <div className='grid grid-cols-3 gap-5'>
            <Select
                name='Market'
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                value={selectedMarket}
                showValue={true}
                onChangeSelectValue={onChangeSelectedFilter}
                inputTypeName={'market'}
                options={markets || []}
            />
            <Select
                name='Partner'
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                value={selectedPartner}
                showValue={true}
                onChangeSelectValue={onChangeSelectedFilter}
                inputTypeName={'partner'}
                options={partners || []}
            />
            <Select
                name='Category'
                placeholder=' '
                containerStyle='mb-1 min-w-full'
                value={selectedSector}
                showValue={true}
                onChangeSelectValue={onChangeSelectedFilter}
                inputTypeName={'sector'}
                options={sectors || []}
            />
        </div>
    )
}
export default SalesReviewFilters