import React from 'react';


interface IModal {
    open: boolean;
    onClose: () => void;
    newClose?: any;
    children?: React.ReactNode;
    title?: string;
    showInRight?: boolean;
    contentContainerStyle?: string;
    dataQa?: string;
    headerStyle?: any
    titleInMiddle?: boolean
    titelStyle?: any
}

const Modal = ({
    open,
    onClose,
    newClose,
    children,
    title,
    showInRight,
    dataQa,
    contentContainerStyle,
    headerStyle,
    titleInMiddle,
    titelStyle
}: IModal) => {
    return (
        <div className={`fixed z-50 inset-0 ${open ? '' : 'pointer-events-none'} `}>
            {/* backdrop */}
            <div className={`fixed inset-0 bg-black ${open ? 'opacity-50' : 'pointer-events-none opacity-0'}`}
                onClick={onClose} />
            {/* content */}
            <div className={`fixed overflow-auto overscroll-auto ${showInRight ? 'max-h-[100vh] top-0 bottom-0 right-0' : '!max-h-[95vh] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'} bg-white shadow-lg w-auto min-w-[200px] h-auto min-h-[100px] max-w-screen-sm p-4 ${open ? 'opacity-100' : 'pointer-events-none opacity-0'} ${contentContainerStyle || ''}`}>
                <div className={`flex flex-row justify-between items-center mt-1 mb-2 ${headerStyle}`}>
                    {titleInMiddle && <div></div>}
                    <p className={`text-[#5C5C5C] text-[15px] ${titelStyle}`}>{title || ''}</p>
                    <div data-qa={'close-modal'} onClick={onClose} className={'cursor-pointer'}>
                        {newClose || <img alt='close' src={'/assets/shared/modal-close.svg'} className='object-contain w-[16px]' />}
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;