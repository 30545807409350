import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';
import { PaginatedWingplusOrders } from '../../../../interfaces/wingplus-orders/paginatedStore.type';


const tryToFetchWingplusOrders = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedWingplusOrders>> => {
    const url = `${INVENTORY_API_URL}/wingplus/orders?page=${pageNumber}&size=${pageSize}`;
    return inventoryAxios.put<PaginatedWingplusOrders>(url, filters ? { ...data || {}, search: filters } : data);
};

const tryToFetchWingplusOrder = (orderId: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/wingplus/orders/${orderId}`)
}

const tryToAddImeiInOrder = (orderId: string, data: { imei: string }): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/wingplus/orders/${orderId}/imei`, data)
}

const tryToChangeOrderToCompleted = (orderId: string): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/wingplus/orders/${orderId}/completed`)
}

export const WingplusOrdersService = {
    tryToFetchWingplusOrders,
    tryToFetchWingplusOrder,
    tryToAddImeiInOrder,
    tryToChangeOrderToCompleted
};