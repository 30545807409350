import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const ItemCostsContainerLoading = () => {
    return (
        <WhiteContainer containerStyle='!rounded p-4 !h-auto'>
            <p className='font-bold text-[18px] mb-4'>COGs Costing</p>
            <div>
                <div className='grid grid-cols-9 gap-3 mb-2'>
                    <div className='col-span-2 font-medium'>Value</div>
                    <div className='col-span-3 font-medium'>Type</div>
                    <div className='col-span-3 font-medium'>Description</div>
                    <div className='col-span-1'></div>
                </div>
                <div className=' my-4 animate-pulse grid grid-cols-9 gap-3 mb-2'>
                    <div className='h-8 bg-gray-200 col-span-2 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                    <div className='h-8 bg-gray-200 col-span-3 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                    <div className='h-8 bg-gray-200 col-span-3 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                    <div className='h-8 bg-gray-200 col-span-1 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ItemCostsContainerLoading;