import React from 'react';
import moment from 'moment';
import { CURRENCY_SYMBOLS } from '../../utils/constants/currencySymbols';
import Table from '../table';

const tableColumns = ['Offer Price', 'Proposers', 'Status', 'Timestamp']

interface IOffersTable {
    rows: any[];
}

const OffersTable = ({
    rows,
}: IOffersTable) => {

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '')?.toLowerCase()}`]}{Number(row?.price || 0)?.toFixed(2)}</td>
                        <td className='px-6 py-4'>{row?.createdBy?.companyName || row?.createdBy?.username}</td>
                        <td className='px-6 py-4'>{capitalizeFirstLetter(row?.status || 'pending')}</td>
                        <td className='px-6 py-4'>{moment(row?.updatedAt).format('DD-MM-YYYY hh:mm:ss')}</td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default OffersTable;