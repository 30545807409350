import React from 'react';
import moment from 'moment';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import { ISales } from '../../../../interfaces/sales/ISales';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Sale Id', 'Status', 'Items Number', 'Total Price', 'Total Quanity', 'Attn Name', 'Weight', 'Shipping Cost', 'Created', 'Change Status', 'Action']

interface ISalesReviewTable {
    rows: ISales[];
    rowsLoading: boolean;
    paginationData?: IPage;
    markets?: IAllMarkets[];
    sectors?: IAssetTemplate[];
    partners?: IPartners[];
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeDetails: (id: string) => void;
    onOpenSaleStatusModal: (id: string) => void;
}

const SalesReviewTable = ({
    rows,
    rowsLoading,
    paginationData,
    accessControl,
    actions,
    setPaginationState,
    onClickSeeDetails,
    onOpenSaleStatusModal
}: ISalesReviewTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: ISales, idx: number) => {
                        const totalPrice = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty),
                            0)
                        const totalQuantity = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty),
                            0)
                        const findCurrency = row?.saleItems && row?.saleItems?.length > 0 && row?.saleItems?.[0]?.currency
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?._id}</td>
                                <td className='px-6 py-4'>{row?.status}</td>
                                <td className='px-6 py-4 '>{row?.saleItems?.length || 0}</td>
                                <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(findCurrency || '').toLowerCase()}`]}{(totalPrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{totalQuantity || 0}</td>
                                <td className='px-6 py-4'>{row?.shippingInfo?.attnName}</td>
                                <td className='px-6 py-4'>{row?.shippingInfo?.weight}</td>
                                <td className='px-6 py-4'>{row?.shippingInfo?.shippingCost}</td>
                                <td className='px-6 py-4'>{row?.createdAt && moment(row?.createdAt).fromNow()}</td>
                                <td className='px-6 py-4'>
                                    <Button
                                        label={'Change'}
                                        className={`${(((row?.status || '')?.toLowerCase() === 'pending') && (!accessControl || actions?.['Change Status'])) ? 'btn-primary' : 'btn-primary-disable'}`}
                                        onClickButton={() => (row?._id && (row?.status || '')?.toLowerCase() === 'pending') && (!accessControl || actions?.['Change Status']) && onOpenSaleStatusModal(row?._id)}
                                    />
                                </td>
                                <td className='px-6 py-4'>
                                    <div className='flex flex-row justify-around'>
                                        <div onClick={() => row?._id && (!accessControl || actions?.['See Details']) && onClickSeeDetails(row?._id)}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SalesReviewTable;