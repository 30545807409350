import React, { useEffect, useState } from 'react';
import {
    useLocation,
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SALES_SOURCES } from '../../../utils/constants/sales-sources';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currencySymbols';
import { IToast } from '../../../interfaces/components/IToast';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToFetchAllPartners,
    tryToFetchUserData
} from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchSales } from '../../../store/inventory/shared/sales/salesSlice';
import { tryToFetchAllCrmData } from '../../../store/inventory/broker/crm-data/crmDataSlice';
import MarkProcessedModal from './modals/MarkProcessedModal';
import AllCategoriesModal from './modals/AllCategoriesModal';
import MarkApprovedModal from './modals/MarkApprovedModal';
import MarkAsPaidModal from './modals/MarkAsPaidModal';
import ShipOrderModal from './modals/ShipOrderModal';
import AddNotesModal from './modals/AddNotesModal';
import GenerateModal from './modals/GenerateModal';
import SalesFilters from './details/SalesFilters';
import SalesTable from './tables/SalesTable';
import EmptyContainer from '../../../shared/empty-container';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SalesOrders = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const query = useQuery();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState<string>();
    const [rows, setRows] = useState<Array<any>>([]);
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<string>(query?.get('filter') || 'draft');
    const [customers, setCustomers] = useState<any>();
    const [partners, setPartners] = useState<any>();
    const [saleId, setSaleId] = useState<any>();
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [selectedSource, setSelectedSource] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [csvData, setCsvData] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const [loadingDownloadCSV, setLoadingDownloadCSV] = useState<boolean>(false);
    const [showCategoriesModal, setShowCategoriesModal] = useState<{ show: boolean, data: any }>();
    const [showGenerateModal, setShowGenerateModal] = useState<{ show: boolean, type: string, item: string }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showActionModal, setShowActionModal] = useState<{ show: boolean, type: string, data?: any }>()
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const paginationData = useAppSelector((state) => state.sales?.sales?.data?.page);
    const salesLoading = useAppSelector((state) => state.sales?.salesAreLoading);
    const user = useAppSelector((state) => state?.partners?.userData);
    const state = useAppSelector((state) => state.sales);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (state.sales) {
            const rows = state.sales?.data?.elements
            setRows(rows);
        }
    }, [state.sales]);

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState]);

    const onChangeFilterSelected = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'customer':
                    setSelectedCustomer(value)
                    searchParams.set('customer', value?.label)
                    break;
                case 'source':
                    setSelectedSource(value)
                    searchParams.set('source', value?.label)
                    break;
                case 'status':
                    if (selectedStatus === value) {
                        searchParams.delete('filter')
                    } else {
                        searchParams.set('filter', value)
                    }
                    setSelectedStatus(value)
                    break;
            }
            onResetPaginationSettings()
        }
    }

    const onClickCategory = (category: any) => {
        setSelectedCategory(category);
        searchParams.set('category', category?.label)
        setSearchParams(searchParams)
        onResetPaginationSettings();
    };

    const getBrokerCustomersAndBanks = async () => {
        try {
            const response: any = await dispatch(tryToFetchAllCrmData({ type: 'customer' }));
            const formatCustomer: any = (response?.payload && response?.payload?.data?.length > 0)
                ? response?.payload?.data?.map((s: any) => ({
                    ...s,
                    label: s?.displayName || s?.name,
                    name: s?.name,
                    value: s?._id,
                }))
                : [];
            setCustomers(formatCustomer || []);
        } catch (err) {
            // err here
        }
    };

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = response?.sectors?.length > 0 ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : []
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = [...formatSectors || [], { value: 'allCategories', label: 'All Categories' }]?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || formatSectors?.[0] || undefined)
        } else {
            setSelectedCategory(selectedCategory || { value: 'allCategories', label: 'All Categories' });
            searchParams.set('category', selectedCategory?.label || 'All Categories')
            setSearchParams(searchParams)
        }
        onResetPaginationSettings();
        setBrokerCategories(formatSectors || []);
    };

    const getPartnersData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.name }))
            const filterPartners = formatPartners && formatPartners?.length > 0 && formatPartners?.filter((partner: IPartners) => partner?.username !== (user?.broker || user?.username))
            setPartners(filterPartners || [])
        } catch (err) {
            // error here
        }
    }

    const onClickShowHideNote = (id?: string) => {
        if (id) {
            setShowNotesModal(true)
            setSaleId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowNotesModal(false)
            setSaleId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onClickSeeAllCategories = (data?: any) => {
        if (data) {
            setShowCategoriesModal({
                show: true,
                data: data
            })
            document.body.style.overflow = 'hidden';
        } else {
            setShowCategoriesModal(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onAddedNoteSuccessfull = () => {
        setShowToast({ type: 'success', message: 'Note added successfully' });
    }

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    useEffect(() => {
        if (!user) {
            dispatch(tryToFetchUserData())
        }
        getPartnersData()
        getBrokerCustomersAndBanks()
        localStorage.setItem('prev', 'sales-orders')
    }, [])

    const onGettingFilters = () => {
        let data: any = {
            inventory: true
        }
        if (activeWarehouse) {
            data = {
                ...data || {},
                warehouseId: activeWarehouse?._id,
            }
        }
        if (selectedCategory) {
            data = {
                ...data || {},
                assetTemplateId: selectedCategory?.id,
            }
        }
        if (selectedStatus) {
            data = {
                ...data || {},
                status: selectedStatus,
            }
        }
        if (selectedSource) {
            data = {
                ...data || {},
                source: selectedSource?.value,
            }
        }
        if (selectedCustomer) {
            data = {
                ...data || {},
                partnerId: selectedCustomer?.value,
            }
        }
        if (search) {
            data = {
                ...data || {},
                search: search,
            }
        }
        const formatData: any = { data: data }
        return formatData;
    }

    const onGettingSalesData = async () => {
        if (activeWarehouse && selectedStatus) {
            try {
                const data = onGettingFilters()
                const fetchData = {
                    ...paginationState || {},
                    ...data || {}
                }
                await dispatch(tryToFetchSales(fetchData)).unwrap()
            } catch (err) {
                // err
            }
        }
    }

    const onSuccessfullyChanged = (type: string, message: string) => {
        setShowToast({ type, message })
    }

    useEffect(() => {
        if (activeWarehouse && selectedStatus) {
            onGettingSalesData()
        }
    }, [paginationState, activeWarehouse, selectedStatus])

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        let filterState: any = false
        if (query?.get('filter')) {
            setSelectedStatus(query?.get('filter') || 'draft')
            filterState = true
        }
        if (query?.get('search') && query?.get('search') !== search) {
            setSearch(query?.get('search') || undefined)
            filterState = true
        }
        if (query?.get('status') && query?.get('status') !== selectedStatus) {
            setSelectedStatus(query?.get('status') || 'draft')
            filterState = true
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = [...brokerCategories || [], { value: 'allCategories', label: 'All Categories' }]?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || { value: 'allCategories', label: 'All Categories' })
            filterState = true
        }
        if (query?.get('source') && query?.get('source') !== selectedSource?.label) {
            const findSource = SALES_SOURCES && SALES_SOURCES?.length > 0 && SALES_SOURCES?.find((source: any) => source?.label === query?.get('source'))
            setSelectedSource(findSource || findSource || undefined)
            filterState = true
        }
        if (query?.get('customer') && query?.get('customer') !== selectedCustomer?.label) {
            const findPartner = partners && partners?.length > 0 && partners?.find((partner: any) => partner?.label === query?.get('customer'))
            const findCustomer = customers && customers?.length > 0 && customers?.find((partner: any) => partner?.label === query?.get('customer'))
            setSelectedCustomer(findPartner || findCustomer || undefined)
            filterState = true
        }
        if (filterState) {
            onResetPaginationSettings();
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('search'),
        query?.get('filter'),
        query?.get('category'),
        query?.get('customer'),
        query?.get('source'),
        query?.get('status'),
    ])

    const onClickClearAllFilter = () => {
        setSelectedCategory(undefined)
        setSearch(undefined);
        setSelectedCustomer(undefined);
        setSelectedSource(undefined)
        searchParams.delete('category')
        searchParams.delete('source')
        searchParams.delete('customer')
        searchParams.delete('filter')
        onResetPaginationSettings();
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onSeeSaleDetails = (id: string) => {
        navigator(`/sales-orders/${id}`)
    }

    const onCreateOrder = () => {
        navigator('/sales-orders/create')
    }

    const onEditOrder = (id: string) => {
        navigator(`/sales-orders/edit/${id}`)
    }

    const onDownloadLabel = (data: any) => {
        window.open(data?.shippingInfo?.invoiceUrl, '_blank');
    }

    const onGenerateInvoice = async (id: string, type: string) => {
        setShowGenerateModal({
            show: true,
            type: type,
            item: id
        })
        document.body.style.overflow = 'hidden';
    }

    const onDownloadData = (data: any) => {
        window.open(data, '_blank');
    }

    const onClickActions = (id: string, type: string, data?: any) => {
        if (type) {
            switch (type) {
                case 'download_label':
                    onDownloadLabel(data)
                    break;
                case 'generate_invoice':
                    onGenerateInvoice(id, 'invoice')
                    break;
                case 'generate_proforma':
                    onGenerateInvoice(id, 'proforma')
                    break;
                case 'download_proforma':
                    onDownloadData(data?.proFormData?.pdfLink)
                    break;
                case 'download_invoice':
                    onDownloadData(data?.invoiceData?.pdfLink)
                    break;
                case 'go_to_open_order':
                    break;
                case 'add_order_draft':
                    navigator(`/sales-orders/edit/${id}?expired=true`);
                    break;
                case 'go_to_split_order':
                    navigator(`/sales-orders/${id}`);
                    break;
                case 'mark_approved':
                case 'mark_processed':
                case 'mark_paid':
                case 'mark_shipped':
                    setShowActionModal({ show: true, type: type, data: data })
                    document.body.style.overflow = 'hidden';
                    break;
            }
            setSaleId(id)
        }
    }

    const onCloseActions = () => {
        setShowActionModal(undefined)
        setSaleId(undefined)
        setShowGenerateModal(undefined)
        document.body.style.overflow = 'auto';
    }

    const onDownloadCSVData = (saleDetails: any) => {
        setLoadingDownloadCSV(true)
        const formatItems: any = [['Item ID/ IMEI', 'Item Name', 'Price', 'Sales Date']];
        const salesDate = saleDetails?.payInfo?.payDate ? moment(saleDetails?.payInfo?.payDate).format('DD-MM-YYYY') : '';
        (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.map((item: any) => {
            const itemName = [
                ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
            ].join(' ');

            const itemData = [item?.itemCode || item?.itemId, itemName, `${CURRENCY_SYMBOLS?.[`${(saleDetails?.saleItems?.[0]?.currency || '')?.toLowerCase()}`]}${item.price}`, salesDate || '']
            formatItems.push(itemData);
            return item;
        })
        setCsvData(formatItems)
    }

    useEffect(() => {
        if (csvData && loadingDownloadCSV) {
            csvLink.current.link.click()
            setLoadingDownloadCSV(false)
        }
    }, [csvData])

    return (
        <div>
            <div>
                <p className='page-title'>Sales Orders</p>
            </div>
            <div>
                <SalesFilters
                    categories={brokerCategories}
                    selectedCategory={selectedCategory}
                    selectedCustomer={selectedCustomer}
                    selectedSource={selectedSource}
                    selectedStatus={selectedStatus}
                    customers={[...partners || [], ...customers || []]}
                    onChangeSelectedValue={onChangeFilterSelected}
                    onClickCategory={onClickCategory}
                />
            </div>
            <div className={`flex flex-row justify-between items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div>
                    <Input
                        placeholder={'Filter Table'}
                        dataQa={'search'}
                        containerStyle='mb-0 pb-0 relative min-w-[400px]'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <div className='flex flex-row justify-end'>
                    <Button
                        label='Clear Filters'
                        className={'btn-primary-text-underline min-w-auto'}
                        onClickButton={() => onClickClearAllFilter()}
                    />
                    <Button
                        label='Create Order'
                        className={'btn-primary-rounded-less min-w-auto min-w-[150px]'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Create Order']) && onCreateOrder()}
                    />
                </div>
            </div>
            <div>
                {(salesLoading || (rows && rows?.length > 0)) ?
                    <SalesTable
                        rows={rows}
                        rowsLoading={salesLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        brokerCategories={brokerCategories}
                        paginationData={paginationData}
                        loadingDownloadCS={loadingDownloadCSV}
                        onDownloadCSVData={onDownloadCSVData}
                        setPaginationState={setPaginationState}
                        onClickShowNote={onClickShowHideNote}
                        onSeeDetails={onSeeSaleDetails}
                        onEditSaleOrder={onEditOrder}
                        onClickActions={onClickActions}
                        onClickSeeAllCategories={onClickSeeAllCategories}
                    />
                    : <EmptyContainer
                        showImage={false}
                        text='No Sales found for status selected.'
                    />
                }
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showNotesModal &&
                <AddNotesModal
                    openModal={showNotesModal}
                    saleId={saleId || ''}
                    handleCloseModal={onClickShowHideNote}
                    onSuccessfullyAddedNote={onAddedNoteSuccessfull}
                />
            }
            {(showActionModal?.show && showActionModal?.type === 'mark_approved') &&
                <MarkApprovedModal
                    openModal={showActionModal?.show}
                    saleId={saleId || ''}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onCloseActions}
                />
            }
            {(showActionModal?.show && showActionModal?.type === 'mark_processed') &&
                <MarkProcessedModal
                    openModal={showActionModal?.show}
                    saleId={saleId || ''}
                    quoteId={showActionModal?.data?.quoteId || ''}
                    saleDetails={showActionModal?.data}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onCloseActions}
                />
            }
            {(showActionModal?.show && showActionModal?.type === 'mark_paid') &&
                <MarkAsPaidModal
                    openModal={showActionModal?.show}
                    saleId={saleId || ''}
                    partnerId={showActionModal?.data?.salesInfo?.partner?.id || showActionModal?.data?.salesInfo?.customer?.id}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onCloseActions}
                />
            }
            {(showActionModal?.show && showActionModal?.type === 'mark_shipped') &&
                <ShipOrderModal
                    openModal={showActionModal?.show}
                    saleId={saleId || ''}
                    saleDetails={showActionModal?.data}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                    handleCloseModal={onCloseActions}
                />
            }
            <CSVLink
                ref={csvLink}
                data={(csvData && csvData?.length > 0) ? csvData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                headers={csvData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Sale-items-${moment().format('DD-MM-YYYY')}.csv`}
            >
            </CSVLink>
            {showGenerateModal?.show &&
                <GenerateModal
                    openModal={showGenerateModal?.show}
                    saleId={showGenerateModal?.item || ''}
                    type={showGenerateModal?.type || ''}
                    handleCloseModal={onCloseActions}
                    onSuccessfullyChanged={onSuccessfullyChanged}
                />
            }
            {showCategoriesModal?.show &&
                <AllCategoriesModal
                    openModal={showCategoriesModal?.show}
                    categories={showCategoriesModal?.data}
                    handleCloseModal={onClickSeeAllCategories}
                />
            }
        </div>
    )
}
export default SalesOrders;