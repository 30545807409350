import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IInventory } from '../../../interfaces/inventory-data/IInventory';
import { IDisplayQuestion } from '../../../interfaces/wingplus-orders/IWingplusOrder';
import { IQuestion, IQuestionDependencies } from '../../../interfaces/questions/IQuestions';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import { tryToFetchImeiStatusCheck } from '../../../store/inventory/broker/orders/ordersSlice';
import { tryToFetchAssetValues } from '../../../store/inventory/shared/assets/assetsSlice';
import { tryToGetAllQuestions } from '../../../store/questions/questionsSlice';
import {
    tryToChangeInventoryItemDetails,
    tryToDeleteInventoryItem,
    tryToFetchSingleInventoryItem
} from '../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import ItemOrderDetailsContainer from './item-details/ItemOrderDetailsContainer';
import SuccessfullySavedToast from './item-details/SuccessfullySavedToast';
import ItemDetailsContainer from './item-details/ItemDetailsContainer';
import ItemCostsContainer from './item-details/ItemCostsContainer';
import DetailsItemContainer from './loading/DetailsItemContainer';
import LabelPrintModal from './modals/LabelPrintModal';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Error from '../../../shared/error';

type IQuestionSecondary = IQuestion & { show: boolean }

const InventoryItemDetails = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const [inventoryDetails, setInventoryDetails] = useState<IInventory>();
    const [otherData, setOtherData] = useState<any>();
    const [formData, setFormData] = useState<any>();
    const [inventoryFormData, setInventoryFormData] = useState<any>();
    const [extendedFormData, setExtendedFormData] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [otherDataErrors, setOtherDataErrors] = useState<any>();
    const [filterErrors, setFilterErrors] = useState<any>();
    const [imeiResponseStatus, setImeiResponseStatus] = useState<any>();
    const [imeiError, setImeiError] = useState<string>();
    const [imeiSearch, setImeiSearch] = useState<string>();
    const [imeiNotExist, setImeiNotExist] = useState<boolean>(false);
    const [inventoryFilterErrors, setInventoryFilterErrors] = useState<any>();
    const [fieldDetails, setFieldDetails] = useState<any>();
    const [invertoryFields, setInvertoryFields] = useState<any>();
    const [extendedFields, setExtendedFields] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [filteredFields, setFilteredFields] = useState<any>();
    const [searchInDB, setSearchInDB] = useState<any>();
    const [searchField, setSearchField] = useState<any>();
    const [loadingValues, setLoadingValues] = useState<boolean>(false);
    const [loadingFields, setLoadingFields] = useState<boolean>(false);
    const [loadingOptions, setLoadingOptions] = useState<any>();
    const [costsItems, setCostsItems] = useState<any>();
    const [currentCostItem, setCurrentCostItem] = useState<any>();
    const [differenceDate, setDifferenceDate] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showPrintLabelModal, setShowPrintLabelModal] = useState<boolean>(false);
    const [primaryQuestions, setPrimaryQuestions] = useState<Array<IQuestion>>();
    const [secondaryQuestions, setSecondaryQuestions] = useState<Array<IQuestionSecondary>>();
    const [selectedSecondaryAnswers, setSelectedSecondaryAnswers] = useState<any>();
    const [selectedPrimaryAnswers, setSelectedPrimaryAnswers] = useState<any>();
    const [checkedDependecies, setCheckedDependecies] = useState<boolean>(false);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const stateInventory = useAppSelector((state) => state?.inventory?.inventoryDetails);
    const loadingData = useAppSelector((state) => state?.inventory?.inventoryDetailsIsLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Inventory Item Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onGettingAllAssetFields = async () => {
        if (stateInventory?.categoryId) {
            try {
                const assetSelected = stateInventory?.categoryId
                const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(assetSelected)).unwrap()
                if (response?.fields) {
                    setFieldDetails(response?.fields)
                    setInvertoryFields(response?.inventoryFields)
                    setExtendedFields(response?.extendedFields)
                }
            } catch (err) {
                // error here
            }
        }
    }

    const onCheckingDependencies = (selectedPrimQuestions: any, selectedSecQuestions: any, match?: boolean) => {
        if (!checkedDependecies) {
            let formatSelectedSecondaryQuestions = selectedSecQuestions ? Object.assign(selectedSecQuestions) : {}
            const formatSecondaryQuestions = (secondaryQuestions && secondaryQuestions?.length > 0) ? secondaryQuestions?.map((item: IQuestionSecondary) => {
                if (item?.dependencies && item?.dependencies?.length > 0) {
                    let showQuestions = true
                    item?.dependencies?.forEach((dependency: IQuestionDependencies) => {
                        if (dependency?.question) {
                            let findQuestions
                            if (selectedPrimQuestions && selectedPrimQuestions?.[dependency?.question]) {
                                findQuestions = selectedPrimQuestions?.[dependency?.question]
                            }
                            if (selectedSecQuestions && selectedSecQuestions?.[dependency?.question]) {
                                findQuestions = selectedSecQuestions?.[dependency?.question]
                            }
                            if (findQuestions) {
                                showQuestions = item?.dependenciesType === 'match_one' ? true : showQuestions
                                if (dependency?.answerValues?.includes(findQuestions?.value)) {
                                    if (match) {
                                        const findAnswer: IDisplayQuestion | undefined = (stateInventory?.displayQuestions && stateInventory?.displayQuestions?.length > 0) ? stateInventory?.displayQuestions?.find((question: IDisplayQuestion) => question?.question === item?.question) : undefined
                                        let questionValue
                                        if (findAnswer && item.answers?.type) {
                                            switch (item.answers?.type) {
                                                case 'SINGLE_CHOICE':
                                                    questionValue = {
                                                        ...findAnswer || {},
                                                        id: findAnswer?.answers?.[0]?.id,
                                                        value: findAnswer?.answers?.[0]?.answer,
                                                        label: findAnswer?.answers?.[0]?.answer
                                                    }
                                                    break;
                                                case 'MULTIPLE_CHOICE':
                                                    questionValue = (findAnswer?.answers && findAnswer?.answers?.length > 0) &&
                                                        findAnswer?.answers?.map((item: any) => {
                                                            return {
                                                                ...item,
                                                                value: item?.answer,
                                                                label: item?.answer
                                                            }
                                                        })
                                                    break;
                                                case 'NUMBER_INPUT':
                                                case 'TEXT_INPUT':
                                                    questionValue = {
                                                        ...findAnswer || {},
                                                        value: findAnswer?.value,
                                                    }
                                                    break;
                                            }
                                        }
                                        formatSelectedSecondaryQuestions[item?.question] = questionValue
                                    }
                                } else {
                                    showQuestions = item?.dependenciesType === 'match_one' ? showQuestions : false
                                    if (formatSelectedSecondaryQuestions?.[item.question]) {
                                        formatSelectedSecondaryQuestions = {
                                            ...formatSelectedSecondaryQuestions || {},
                                            [item.question]: undefined
                                        }
                                    }
                                }
                            } else {
                                showQuestions = item?.dependenciesType === 'match_one' ? showQuestions : false
                                if (formatSelectedSecondaryQuestions?.[item.question]) {
                                    formatSelectedSecondaryQuestions = {
                                        ...formatSelectedSecondaryQuestions || {},
                                        [item.question]: undefined
                                    }
                                }
                            }
                        }
                    })
                    return {
                        ...item,
                        show: showQuestions
                    }
                } else {
                    return item
                }
            }) : undefined
            setSelectedSecondaryAnswers(formatSelectedSecondaryQuestions)
            setSecondaryQuestions(formatSecondaryQuestions)
            setCheckedDependecies(true)
        }
    }

    useEffect(() => {
        if ((selectedPrimaryAnswers || selectedSecondaryAnswers) && !checkedDependecies) {
            onCheckingDependencies(selectedPrimaryAnswers, selectedSecondaryAnswers)
        }
    }, [selectedPrimaryAnswers, selectedSecondaryAnswers])

    const onGettingQuestions = async () => {
        try {
            const assetSelected = stateInventory?.categoryId
            const formatData = {
                categoryId: assetSelected,
                service: inventoryDetails?.source || 'app',
                marketId: inventoryDetails?.marketId,
                review: true
            }
            const response: any = await dispatch(tryToGetAllQuestions(formatData)).unwrap()
            setPrimaryQuestions(response?.primaryQuestions)
            const formatSecondaryQuestions: Array<IQuestionSecondary> = [];
            response?.secondaryQuestions && response?.secondaryQuestions?.length > 0 &&
                response?.secondaryQuestions?.forEach((item: IQuestion) => {
                    const filterQuestions = [...response?.primaryQuestions || [], ...response?.secondaryQuestions || []].map(item => item.question);
                    const formatDependencies = (item?.dependencies && item?.dependencies?.length > 0) ? item?.dependencies?.filter((dependency: any) => filterQuestions?.includes(dependency?.question)) : undefined
                    formatSecondaryQuestions.push({
                        ...item || {},
                        show: !formatDependencies || formatDependencies?.length <= 0,
                        type: 'SECONDARY',
                        dependencies: formatDependencies
                    })
                })
            setSecondaryQuestions(formatSecondaryQuestions)
        } catch (err) {
            // error here
        }
    }

    const onFormatQuestions = (onlySecondary?: boolean) => {
        let formatFormData: { [key: string]: string } = {};
        const formatQuestions: Array<any> = [];
        if (primaryQuestions && primaryQuestions?.length > 0 && !onlySecondary) {
            primaryQuestions?.forEach((item: IQuestion) => {
                if (item?.field) {
                    formatFormData = {
                        ...formatFormData || {},
                        [item?.field]: selectedPrimaryAnswers?.[item?.question]?.value
                    }
                }
                formatQuestions.push({
                    question: item?.question,
                    id: item?._id,
                    answers: [
                        {
                            answer: selectedPrimaryAnswers?.[item?.question]?.value
                        }
                    ]
                })
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestion) => {
                if (item?.answers?.type && selectedSecondaryAnswers?.[item?.question]) {
                    let answerValue
                    let value
                    switch (item?.answers?.type) {
                        case 'SINGLE_CHOICE': {
                            if (selectedSecondaryAnswers?.[item?.question]?.value) {
                                answerValue = [{
                                    id: selectedSecondaryAnswers?.[item?.question]?.id,
                                    answer: selectedSecondaryAnswers?.[item?.question]?.value,
                                }]
                            }
                            break;
                        }
                        case 'MULTIPLE_CHOICE':
                            answerValue = (selectedSecondaryAnswers?.[item?.question]?.value && selectedSecondaryAnswers?.[item?.question]?.value?.length > 0) &&
                                selectedSecondaryAnswers?.[item?.question]?.value?.map((item: any) => {
                                    return {
                                        id: item?.id,
                                        answer: item?.value,
                                    }
                                })
                            break;
                        case 'NUMBER_INPUT':
                        case 'TEXT_INPUT': {
                            if (selectedSecondaryAnswers?.[item?.question]?.value) {
                                value = selectedSecondaryAnswers?.[item?.question]?.value
                            }
                            break;
                        }
                    }
                    if (answerValue || value) {
                        formatQuestions.push({
                            question: item?.question,
                            id: item?._id,
                            answers: answerValue || [],
                            value: value || undefined
                        })
                    }
                }
            })
        }
        return {
            assets: formatFormData,
            questions: formatQuestions
        }
    }

    const onGettingFieldValues = async () => {
        if (stateInventory?.categoryId) {
            try {
                let data: any = {
                    values: []
                }
                if (selectedPrimaryAnswers) {
                    const keysArray = selectedPrimaryAnswers && Object.keys(selectedPrimaryAnswers)
                    const formatValues = keysArray && keysArray?.length > 0 && keysArray?.map((name: string) => { return { [selectedPrimaryAnswers[name].field]: selectedPrimaryAnswers?.[name]?.value } })
                    data = {
                        ...data || {},
                        values: formatValues || []
                    }
                }
                if (searchField) {
                    data = {
                        ...data || {},
                        search: {
                            ...searchField || {}
                        }
                    }
                }
                const response = await dispatch(tryToFetchAssetValues({ id: stateInventory?.categoryId, data })).unwrap()
                setTemplateFields(response || [])
            } catch (err) {
                // error here
            }
            setLoadingFields(false)
            setLoadingOptions(undefined)
        }
    }

    const onFindingDifferenceDate = () => {
        const currentDate = moment();
        const specificDate = moment(stateInventory?.bookedIn || stateInventory?.createdAt);

        const differenceInSeconds = currentDate.diff(specificDate, 'seconds');
        let differenceFromToday: any = '0 days';

        if (differenceInSeconds >= 86400) {
            differenceFromToday = `${Math.floor(differenceInSeconds / 86400)} ${Math.floor(differenceInSeconds / 86400) > 1 ? 'days' : 'day'}`;
        } else if (differenceInSeconds >= 3600) {
            differenceFromToday = `${Math.floor(differenceInSeconds / 3600)} ${Math.floor(differenceInSeconds / 3600) > 1 ? 'hours' : 'hour'}`;
        } else if (differenceInSeconds >= 60) {
            differenceFromToday = `${Math.floor(differenceInSeconds / 60)} ${Math.floor(differenceInSeconds / 60) > 1 ? 'minutes' : 'minute'}`;
        } else {
            differenceFromToday = `${differenceInSeconds} ${differenceInSeconds > 1 ? 'seconds' : 'second'}`;
        }

        setDifferenceDate(differenceFromToday);
    }

    useEffect(() => {
        if (stateInventory?.bookedIn || stateInventory?.createdAt) {
            onFindingDifferenceDate()
        }
    }, [inventoryDetails])

    useEffect(() => {
        if (stateInventory?.categoryId) {
            onGettingAllAssetFields()
            onGettingFieldValues()
            onGettingQuestions()
        }
        setOtherData({
            price: stateInventory?.revaluatedPrice || stateInventory?.price,
            salesPrice: stateInventory?.salesPrice,
            itemName: stateInventory?.itemName,
            imeiStatus: stateInventory?.imeiStatus,
            extras: stateInventory?.extras,
            stockVAT: stateInventory?.stockVAT
        })
        // setFormData(stateInventory?.revaluation || stateInventory?.exchangeDefinition?.asset?.descriptor || undefined)
        setInventoryFormData(stateInventory?.inventory || undefined)
        setCostsItems(stateInventory?.cogsCosting || [])
        if (stateInventory?.itemCode) {
            setImeiSearch(stateInventory?.itemCode)
        }else{
            setImeiSearch(undefined)
        }
        onFindingDifferenceDate()
        if (!stateInventory) {
            setInventoryDetails(undefined)
        }
    }, [stateInventory, id])

    useEffect(() => {
        if (stateInventory && extendedFields) {
            let prepareExtendedData: any;
            extendedFields?.map((item: any) => {
                if (stateInventory?.revaluation?.[item?.name] || stateInventory?.exchangeDefinition?.asset?.descriptor?.[item?.name]) {
                    prepareExtendedData = {
                        ...prepareExtendedData || {},
                        [item?.name]: stateInventory?.revaluation?.[item?.name] || stateInventory?.exchangeDefinition?.asset?.descriptor?.[item?.name]
                    }
                }
                return item
            })
            setExtendedFormData(prepareExtendedData || undefined)
        }
    }, [stateInventory, id, extendedFields])

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleInventoryItem(id)).unwrap()
        }
    }, [id])

    const onGoBack = () => {
        navigator(-1)
    }

    const onSubmitSave = async () => {
        setSubmitLoading(true)
        try {
            let assetData: any = onFormatQuestions()?.assets
            if ((stateInventory?.category || '').toLowerCase() === 'clothing') {
                assetData = inventoryDetails?.exchangeDefinition?.asset?.descriptor || undefined
            }
            let formatInventoryData: any = {}
            if (invertoryFields && invertoryFields?.length > 0) {
                invertoryFields?.map((item: any) => {
                    formatInventoryData = {
                        ...(formatInventoryData || {}),
                        [item?.name]: inventoryFormData?.[item?.name]
                    }
                    return item
                })
            }
            if (extendedFields && extendedFields?.length > 0) {
                extendedFields?.map((item: any) => {
                    assetData = {
                        ...(assetData || {}),
                        [item?.name]: extendedFormData?.[item?.name]
                    }
                    return item
                })
            }
            let formatBody: any = {
                ...otherData || {},
                questions: onFormatQuestions(true)?.questions,
                cogsCosting: costsItems,
                descriptor: assetData,
                inventory: formatInventoryData
            }
            if (imeiSearch && !imeiNotExist) {
                formatBody = {
                    ...formatBody || {},
                    itemCode: imeiSearch,
                }
            } else if (imeiNotExist) {
                formatBody = {
                    ...formatBody || {},
                    imeiNotExisting: imeiNotExist,
                }
            }
            await dispatch(tryToChangeInventoryItemDetails({ id: stateInventory?.itemId || '', data: formatBody })).unwrap()
            setShowToast({ type: 'success', message: 'Item successfully saved' });
            setSubmitError(undefined)
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setSubmitLoading(false)
    }

    const onValidateSave = () => {
        if (!stateInventory) {
            setSubmitError('No Item details to save!')
            return;
        }
        setSubmitLoading(true)
        setSubmitError(undefined)
        let otherErrors: any
        if (!otherData?.salesPrice) {
            otherErrors = {
                ...otherErrors || {},
                salesPrice: 'Sales Price is required'
            }
        }
        if (!otherData?.stockVAT) {
            otherErrors = {
                ...otherErrors || {},
                stockVAT: 'VAT Code is required'
            }
        }
        if (!imeiSearch && !imeiNotExist && ['phones', 'tablets']?.includes?.((inventoryDetails?.category || '').toLowerCase())) {
            otherErrors = {
                ...otherErrors || {},
                imei: 'IMEI is required'
            }
        }
        let itemErrors: any
        let inventoryErrors: any
        const fieldValues: Array<string> = [];
        for (const key in selectedPrimaryAnswers) {
            if (Object.hasOwnProperty.call(selectedPrimaryAnswers[key], 'field')) {
                if (selectedPrimaryAnswers[key].value) {
                    fieldValues.push(selectedPrimaryAnswers[key].field);
                }
            }
        }
        const keys = (primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.map((item: IQuestion) => item?.field)
        if (templateFields && keys && keys?.length > 0) {
            keys?.map((item: any) => {
                const findField = fieldDetails && fieldDetails?.find((field: any) => field?.name === item)
                const findIfInSelected = (fieldValues && fieldValues?.length > 0) && fieldValues?.find((field: string) => field === item)
                if (!findIfInSelected && findField?.required) {
                    itemErrors = {
                        ...(itemErrors || {}),
                        [item]: 'This question is required'
                    }
                }
                return item
            })
        }
        if (secondaryQuestions && secondaryQuestions?.length > 0) {
            secondaryQuestions?.forEach((item: IQuestionSecondary) => {
                const findIfInSelected = selectedSecondaryAnswers[item.question]
                if (!findIfInSelected && item?.required && item.show) {
                    itemErrors = {
                        ...(itemErrors || {}),
                        [item.question]: 'This question is required'
                    }
                }
            })
        }
        if (invertoryFields && invertoryFields?.length > 0) {
            invertoryFields?.map((item: any) => {
                if (!inventoryFormData?.[item?.name] && item?.required) {
                    inventoryErrors = {
                        ...(inventoryErrors || {}),
                        [item?.name]: `${item?.name} is required`
                    }
                }
                return item
            })
        }
        if (!itemErrors && !inventoryErrors && !otherErrors) {
            setFilterErrors(undefined)
            setInventoryFilterErrors(undefined)
            setOtherDataErrors(undefined)
            onSubmitSave()
        } else {
            setSubmitLoading(false)
            setFilterErrors(itemErrors)
            setInventoryFilterErrors(inventoryErrors)
            setOtherDataErrors(otherErrors)
        }
    }

    const onChangeSelectedDetail = (value: any, name?: string) => {
        if (name) {
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [name]: value?.value
            }))
        }
    }

    const onChangeInputValue = (value: any, name?: string) => {
        if (name) {
            setOtherData((prevOtherData: any) => ({
                ...prevOtherData || {},
                [name]: value
            }))
        }
    }

    const onChangeSearchAssetFields = (e: any, type?: string, index?: string) => {
        if (type && e && index) {
            if (templateFields && templateFields?.[index]) {
                const formatFilteredFields = templateFields?.[index] && templateFields?.[index]?.length > 0 && templateFields?.[index]?.filter((next: any) => (next || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB({ [index]: true, searchValue: e })
                    setFilteredFields({ [index]: formatFilteredFields })
                }
                else {
                    setFilteredFields(undefined)
                    setLoadingOptions({ [index]: true })
                    setSearchField({
                        [index]: e
                    })
                    if (selectedPrimaryAnswers?.[type]) {
                        const formatFormData = Object.assign(selectedPrimaryAnswers)
                        delete formatFormData[type]
                        setSelectedPrimaryAnswers(
                            {
                                ...formatFormData || {}
                            }
                        )
                        setCheckedDependecies(false)
                    }
                }
            }
        } else {
            setFilteredFields(undefined)
        }
    }

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            setLoadingFields(true)
            setFormData({
                ...(formData || {}),
                [type]: value?.value || undefined,
            });
            if (searchField?.[type]) {
                setSearchField(undefined)
            }
        }
    };

    const onSelectPrimaryQuestion = (value: any, type?: string, index?: string) => {
        if (type && index) {
            setSelectedPrimaryAnswers((prevPrimaryAnswer: any) => ({
                ...prevPrimaryAnswer || {},
                [type]: value
            }))
            setCheckedDependecies(false)
        }
    }

    const onMenuCloseAfterSearch = (type?: string, index?: any) => {
        if (type && searchField?.[index]) {
            setSearchField(undefined)
            setSearchInDB(undefined)
        }
    }

    const onChangeSelectedInventoryValue = (value: any, type?: string) => {
        if (type) {
            const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
            setInventoryFormData({
                ...(inventoryFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    const onChangeSelectedExtendedValue = (value: any, type?: string) => {
        if (type) {
            const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
            setExtendedFormData({
                ...(extendedFormData || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
        }
    }

    useEffect(() => {
        if (selectedPrimaryAnswers) {
            setLoadingValues(false)
        }
        onGettingFieldValues()
    }, [selectedPrimaryAnswers, searchField])

    const onClickFindByImei = async () => {
        if (imeiSearch) {
            try {
                const response = await dispatch(tryToFetchImeiStatusCheck(imeiSearch)).unwrap()
                if (response && response?.data) {
                    setOtherData((prevOtherData: any) => ({
                        ...prevOtherData || {},
                        imeiStatus: (response?.data?.blackliststatus || '').toLowerCase() === 'yes' ? 'Barred' : 'OK'
                    }))
                    setImeiResponseStatus(response?.data?.blackliststatus)
                    setImeiError(undefined)
                } else {
                    setImeiError('Item not found!')
                }
            } catch (err) {
                setImeiError(`${err}`)
            }
        }
    }

    const onChangeImeiSearch = (value: string | null | undefined, type?: string) => {
        setImeiSearch(value || undefined)
        setImeiError(undefined)
        setImeiResponseStatus(undefined)
    }

    const onClickImeiNotExist = () => {
        if (!imeiNotExist) {
            setImeiSearch(undefined)
        }
        setImeiResponseStatus(undefined)
        setImeiNotExist(!imeiNotExist)
    }

    const onClickSearchInDb = (type?: any, index?: any) => {
        if (searchInDB?.[index]) {
            setFilteredFields(undefined)
            setLoadingOptions({ [index]: true })
            setSearchField({
                [index]: searchInDB?.searchValue
            })
            if (selectedPrimaryAnswers?.[type]) {
                const formatFormData = Object.assign(selectedPrimaryAnswers)
                delete formatFormData[type]
                setSelectedPrimaryAnswers(
                    {
                        ...formatFormData || {}
                    }
                )
                setCheckedDependecies(false)
            }
        }
    }

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFields && templateFields?.length > 0 && templateFields?.find((field: any) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeFilterInventoryInput = (value: string | null | undefined, type: string) => {
        const findField = invertoryFields && invertoryFields?.length > 0 && invertoryFields?.find((field: any) => field?.name === type);
        setInventoryFormData({
            ...(inventoryFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeFilterExtendedInput = (value: string | null | undefined, type: string) => {
        const findField = extendedFields && extendedFields?.length > 0 && extendedFields?.find((field: any) => field?.name === type);
        setExtendedFormData({
            ...(extendedFormData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeExtraText = (e: any, type?: string, index?: any) => {
        setOtherData((prevOtherData: any) => ({
            ...prevOtherData || {},
            extras: e?.target?.value
        }))
    }

    const onRemoveCostItem = (index: number) => {
        const filterCosts = costsItems && costsItems?.length > 0 && costsItems?.filter((item: any, idx: number) => idx !== index)
        setCostsItems(filterCosts || [])
    }

    const onAddDetailsToCostItems = () => {
        if (currentCostItem && currentCostItem?.value && currentCostItem?.type) {
            setCostsItems((prevItems: any) => ([...prevItems || [], currentCostItem]))
            setCurrentCostItem(undefined)
        }
    }

    const onChangeCostInputValue = (value: any, name?: string) => {
        if (name) {
            setCurrentCostItem((prevItem: any) => ({
                ...prevItem || {},
                [name]: name === 'value' ? (/^\d*\.?\d*$/).test(value) ? value : currentCostItem?.value : value
            }))
        }
    }

    useEffect(() => {
        if (stateInventory && (primaryQuestions || secondaryQuestions) && !inventoryDetails) {
            let formatPrimaryQuestions: any = {};
            let formatSecondaryQuestions: any = {};
            (stateInventory?.revaluatedDisplayQuestions || stateInventory?.displayQuestions)?.map((item: any) => {
                const findIfInPrimary = (primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.find((question: any) => question?._id === item?.id)
                const findIfInSecondary = (secondaryQuestions && secondaryQuestions?.length > 0) && secondaryQuestions?.find((question: any) => question?._id === item?.id)
                if (findIfInPrimary) {
                    formatPrimaryQuestions = {
                        ...formatPrimaryQuestions || {},
                        [item.question]: {
                            ...item || {},
                            field: findIfInPrimary?.field,
                            label: item?.answers?.[0]?.answer,
                            value: item?.answers?.[0]?.answer
                        }
                    }
                } else if (findIfInSecondary) {
                    let questionValue: any
                    switch (findIfInSecondary?.answers?.type) {
                        case 'SINGLE_CHOICE':
                            questionValue = {
                                ...item || {},
                                id: item?.answers?.[0]?.id,
                                value: item?.answers?.[0]?.answer,
                                label: item?.answers?.[0]?.answer
                            }
                            break;
                        case 'MULTIPLE_CHOICE':
                            questionValue = (item?.answers && item?.answers?.length > 0) &&
                                item?.answers?.map((obj: any) => {
                                    return {
                                        ...obj,
                                        value: obj?.answer,
                                        label: obj?.answer
                                    }
                                })
                            break;
                        case 'NUMBER_INPUT':
                        case 'TEXT_INPUT':
                            questionValue = {
                                ...item || {},
                                value: item?.value,
                                label: item?.value
                            }
                            break;
                    }
                    formatSecondaryQuestions = {
                        ...formatSecondaryQuestions || {},
                        [item.question]: questionValue
                    }
                }
                return item
            })
            setSelectedPrimaryAnswers(formatPrimaryQuestions)
            setSelectedSecondaryAnswers(formatSecondaryQuestions)
            onCheckingDependencies(formatPrimaryQuestions, formatSecondaryQuestions)
            setCheckedDependecies(true)
            setInventoryDetails(stateInventory || undefined)
        }
    }, [primaryQuestions, secondaryQuestions, stateInventory, id, inventoryDetails])

    const onClearAssetFieldsData = () => {
        setFormData(undefined)
        setSelectedPrimaryAnswers(undefined)
        setSelectedSecondaryAnswers(undefined)
        setInventoryFormData(undefined)
        setExtendedFormData(undefined)
        setCheckedDependecies(false)
    }

    const onToggleDeleteModal = () => {
        setShowDeleteModal((prevDeleteModal) => !prevDeleteModal)
    }

    const onDeleteInventoryItem = async () => {
        try {
            await dispatch(tryToDeleteInventoryItem(stateInventory?.itemId || '')).unwrap()
            setImeiNotExist(false)
            setImeiSearch(undefined)
            setFormData(undefined)
            setOtherData(undefined)
            setCostsItems(undefined)
            setInventoryFormData(undefined)
            setExtendedFormData(undefined)
            setSelectedPrimaryAnswers(undefined)
            setCheckedDependecies(false)
            setSelectedSecondaryAnswers(undefined)
            setImeiError(undefined)
            setImeiResponseStatus(undefined)
            setShowDeleteModal(false)
            onGoBack()
        } catch (err) {
            setSubmitError(`${err}`)
        }
    }

    const onChangeSecondaryQuestion = (value: any, type?: string, index?: any) => {
        if (type) {
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [type]: {
                    type: index,
                    value: index === 'NUMBER_INPUT' ? (/^\d*\.?\d*$/).test(value) ? value : selectedSecondaryAnswers?.[type]?.value : value
                }
            }))
            setCheckedDependecies(false)
        }
    }

    const onSelectSecondaryQuestion = (value: any, type?: string, index?: string) => {
        if (type) {
            setSelectedSecondaryAnswers((prevSecondaryAnswer: any) => ({
                ...prevSecondaryAnswer || {},
                [type]: value
            }))
            setCheckedDependecies(false)
        }
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-5'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Item Details</p>
                </div>
            </div>
            {(loadingData || loadingValues || !(templateFields || templateFields?.length > 0)) ?
                <div>
                    <DetailsItemContainer />
                </div>
                :
                <div className='my-3'>
                    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4'}>
                        <ItemDetailsContainer
                            primaryQuestions={primaryQuestions}
                            secondaryQuestions={secondaryQuestions}
                            selectedPrimaryAnswers={selectedPrimaryAnswers}
                            selectedSecondaryAnswers={selectedSecondaryAnswers}
                            inventoryDetails={inventoryDetails}
                            otherDataErrors={otherDataErrors}
                            otherData={otherData}
                            formData={formData}
                            imeiError={imeiError}
                            imeiSearch={imeiSearch}
                            imeiStatus={imeiResponseStatus}
                            imeiNotExist={imeiNotExist}
                            items={templateFields || []}
                            loadingValues={loadingValues}
                            loadingFields={loadingFields}
                            loadingOptions={loadingOptions}
                            inventoryItems={invertoryFields || []}
                            extendedItems={extendedFields || []}
                            filteredFields={filteredFields}
                            filterValues={formData}
                            searchInDB={searchInDB}
                            filterInventoryValues={inventoryFormData}
                            filterExtendedValues={extendedFormData}
                            templateId={inventoryDetails?.categoryId || ''}
                            filterErrors={filterErrors}
                            inventoryFilterErrors={inventoryFilterErrors}
                            onClearAssetFieldsData={onClearAssetFieldsData}
                            onClickSearchInDb={onClickSearchInDb}
                            onChangeSelectedValue={onChangeSelectedFilterValue}
                            onChangeSelectedInventoryValue={onChangeSelectedInventoryValue}
                            onChangeInput={onChangeFilterInput}
                            onChangeExtendedInput={onChangeFilterExtendedInput}
                            onChangeSelectedExtendedValue={onChangeSelectedExtendedValue}
                            onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                            onChangeInventoryInput={onChangeFilterInventoryInput}
                            onChangeSearchAssetFields={onChangeSearchAssetFields}
                            onChangeSelectedDetail={onChangeSelectedDetail}
                            onChangeExtraText={onChangeExtraText}
                            onChangeInputValue={onChangeInputValue}
                            onChangeImeiSearch={onChangeImeiSearch}
                            onClickFindByImei={onClickFindByImei}
                            onClickImeiNotExist={onClickImeiNotExist}
                            onChangeSecondaryInputValue={onChangeSecondaryQuestion}
                            onSelectPrimaryQuestion={onSelectPrimaryQuestion}
                            onSelectSecondaryQuestion={onSelectSecondaryQuestion}
                        />
                        <div>
                            <ItemCostsContainer
                                costsItems={costsItems}
                                inventoryDetails={inventoryDetails}
                                currentCostItem={currentCostItem}
                                onChangeCostInputValue={onChangeCostInputValue}
                                onRemoveCostItem={onRemoveCostItem}
                                onAddDetailsToCostItems={onAddDetailsToCostItems}
                            />
                            <ItemOrderDetailsContainer
                                inventoryDetails={inventoryDetails}
                                differenceDate={differenceDate}
                                accessControl={accessControl}
                                actions={pageAccess?.actions}
                                onTogglePrintLabel={() => setShowPrintLabelModal(!showPrintLabelModal)}
                            />
                        </div>
                    </div>
                    {submitError &&
                        <div className='my-4 flex flex-row justify-center'>
                            <Error text={submitError} />
                        </div>
                    }
                    <div className='flex flex-row justify-end items-center my-5'>
                        <Button
                            label='Delete this stock item'
                            className={`${(!accessControl || pageAccess?.actions?.['Delete this stock item']) ? 'btn-error' : 'btn-primary-disable'} mr-2 rounded`}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Delete this stock item']) && onToggleDeleteModal()}
                        />
                        <Button
                            label='Save Changes'
                            className={!submitLoading ? 'btn-primary' : 'btn-primary-disable'}
                            onClickButton={() => !submitLoading && onValidateSave()}
                        />
                    </div>
                </div>
            }
            {showPrintLabelModal &&
                <LabelPrintModal
                    openModal={showPrintLabelModal}
                    itemId={id || ''}
                    data={inventoryDetails?.revaluation || inventoryDetails?.exchangeDefinition?.asset?.descriptor}
                    dataInventory={inventoryDetails?.inventory}
                    vatType={inventoryDetails?.stockVAT}
                    handleCloseModal={() => setShowPrintLabelModal(!showPrintLabelModal)}
                />
            }
            <DeleteModal
                openDeleteModal={showDeleteModal}
                handleCloseDeleteModal={onToggleDeleteModal}
                onApproveDeletion={onDeleteInventoryItem}
            />
            {showToast?.message &&
                <SuccessfullySavedToast
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)} />
            }
        </div>
    )
}

export default InventoryItemDetails