import React, { useState, useEffect } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Table from '../../../../shared/table';

interface ISaleDetailsTable {
    rows: Array<any>;
    rowsLoading: boolean;
}

const SaleDetailsTable = ({
    rows,
    rowsLoading,
}: ISaleDetailsTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Price', 'Quantity'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const findKeys = rows && rows?.length > 0 && rows?.[0] && rows?.[0]?.descriptor && Object?.keys(rows?.[0]?.descriptor)
        const formatTableColumns = []
        const formatTableExtraKeys: any = []

        if (findKeys && findKeys?.length > 0) {
            findKeys && findKeys?.length > 0 && findKeys?.map((key: string) => {
                const formatKey = capitalizeFirstLetter(key)
                const formatElement = (formatKey || '').replaceAll('_', ' ')
                formatTableExtraKeys.push(key)
                formatTableColumns.push(formatElement)
                return key
            })
        }
        formatTableColumns.push('Price')
        formatTableColumns.push('Quantity')

        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <Table
            columns={tableColumns}
            containerStyle={'mt-0'}
        >
            {(rowsLoading || tableLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {(tableExtraKeys && tableExtraKeys?.length > 0) && tableExtraKeys?.map((key: string, index: number) => {
                                    return (
                                        <td
                                            key={index}
                                            className={'px-6 py-4'}>
                                            {row?.descriptor?.[key] || ''}
                                        </td>
                                    )
                                })}
                                <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{(row?.price || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.qty || ''}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SaleDetailsTable;