export const onFormatPurchaseStatus = (status: string) => {
    switch (status) {
        case 'draft':
            return { name: 'Draft', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'on_delivery':
            return { name: 'On Delivery', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'accepted':
            return { name: 'Approved', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'delivered':
            return { name: 'Delivered', color: 'border-[#f7a21e]', text: 'text-[#f7a21e]' }
        case 'in_review':
            return { name: 'In Review', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'completed':
            return { name: 'Completed', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        case 'split_discrepancy':
            return { name: 'Split Discrepancies', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'discrepancy':
            return { name: 'Discrepancy', color: 'border-[#E50000]', text: 'text-[#E50000]' }
        case 'rma_in_progress':
            return { name: 'RMA In Progress', color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
        case 'rma_shipped':
            return { name: 'RMA Shipped', color: 'border-[#00b67a]', text: 'text-[#00b67a]' }
        default:
            return { name: status, color: 'border-[#1389e6]', text: 'text-[#1389e6]' }
    }
}