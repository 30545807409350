import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToImportInventoryData } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';
import { tryToFetchAllPartners } from '../../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchAllCrmData } from '../../../../store/inventory/broker/crm-data/crmDataSlice';
import { IPartners } from '../../../../interfaces/partners/IPartners';

interface IImportInventoryModal {
    openImportModal: boolean;
    sectors?: Array<any>;
    markets?: Array<any>;
    handleCloseModal: () => void;
}

const ImportInventoryModal = ({
    openImportModal,
    sectors,
    markets,
    handleCloseModal
}: IImportInventoryModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()
    const [combined, setCombined] = useState<any>();
    const [brookers, setBrookers] = useState<any>([]);
    const [customers, setCustomers] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [selectedCustomer, setSelectedCustomer] = useState<any>()
    const [selectedVatType, setSelectedVatType] = useState<any>('');
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)

    const user = useAppSelector((state) => state?.partners?.userData);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        })
    }

    const getPartnersData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.name }))
            const filterPartners = formatPartners && formatPartners?.length > 0 && formatPartners?.filter((partner: IPartners) => partner?.username !== (user?.broker || user?.username))
            setBrookers(filterPartners || [])
        } catch (err) {
            // error here
        }
    }

    const getSuppliers = async () => {
        const response: any = await dispatch(tryToFetchAllCrmData({ type: 'customer' }))

        const formatCustomer2: any = (response?.payload && response?.payload?.data?.length > 0)
        ? response?.payload?.data?.map((s: any) => ({
            ...s,
            label: s?.displayName || s?.name,
            name: s?.name,
            value: s?._id,
        }))
        : [];

        setCustomers(formatCustomer2)
    }

    useEffect(() => {
        getSuppliers()
        getPartnersData()
    }, [])

    useEffect(() => {
        setCombined([...brookers, ...customers])
    }, [brookers, customers])

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setSelectedMarket(undefined)
        setSelectedCategory(undefined)
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        if (!selectedCategory || !selectedMarket) {
            setImportError('Please select market and category first to continue.')
            return;
        }
        try {
                        Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const payload = {
                        marketId: selectedMarket?._id,
                        assetTemplateId: selectedCategory?.id,
                        warehouseId: activeWarehouse?._id,
                        supplierId: selectedCustomer?.value,
                        vatType: selectedVatType?.label,
                        purchaseDate: dateFiltersValues.from,
                        data: results?.data || []
                    }
                    await dispatch(tryToImportInventoryData(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    const onChangeSelectedSector = (value: any, type?: string) => {
        setSelectedCategory(value)
    }

    const onChangeSelectedMarket = (value: any, type?: string) => {
        setSelectedMarket(value)
    }

    const onChangeCustomer =  (e: any, type?: string) => {
        setSelectedCustomer(e)
    }

    const onChangeVatTypeFilter =  (e: any, type?: string) => {
        setSelectedVatType(e)
    }

    const onClickRemoveThisFile = () => {
        setFileSelected(undefined)
    }

    return (
        <Modal
        showInRight
            open={openImportModal}
            onClose={onCloseModal}
            title='Import Inventory'
            headerStyle={'border-solid border-b-[1px] border-b-[#EBEDEF] pb-4'}
            titleInMiddle={true}
            titelStyle={'text-black'}
            >
                <p className='font-medium text-[20px] mb-8 border-solid border-b-[1px] border-b-[#EBEDEF] pl-6 mt-10 pb-2'>Import CSV</p>
            <div className={'p-2 min-w-[500px] px-6'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={onCloseModal}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        markets={markets || []}
                        sectors={sectors || []}
                        selectedSector={selectedCategory}
                        selectedMarket={selectedMarket}
                        activeWarehouse={activeWarehouse}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                        combined={combined}
                        onChangeDateFilters={onChangeDateFilters}
                        dateFiltersValues={dateFiltersValues}
                        onChangeCustomer={onChangeCustomer}
                        selectedCustomer={selectedCustomer}
                        selectedVatType={selectedVatType}
                        onChangeVatTypeFilter={onChangeVatTypeFilter}
                        onClickRemoveThisFile={onClickRemoveThisFile}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportInventoryModal;