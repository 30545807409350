import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IWarehouseLocation } from '../../../../interfaces/warehouse-locations/IWarehouseLocation';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['ID', 'Barcode', 'Values', 'Custom Space', 'Values Barcodes', 'Capacity', 'Filled Capacity', 'Action']

interface IWarehouseLocationsTable {
    rows: any;
    paginationData?: IPage;
    rowsLoading?: boolean;
    accessControl?: any;
    actions?: any;
    onEditBarcode: (id: string, barcode: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickShowCustomSpaceChildBarcode: (barcode: string) => void;
}

const WarehouseLocationsTable = ({
    rows,
    paginationData,
    rowsLoading,
    accessControl,
    actions,
    onEditBarcode,
    setPaginationState,
    onClickShowCustomSpaceChildBarcode
}: IWarehouseLocationsTable) => {
    return (
        <>
            <Table
                columns={tableColumns}
                paginationData={paginationData}
                setPaginationState={setPaginationState}
                containerStyle={'mt-0'}>
                {rowsLoading ?
                    <>
                        {[...Array(7)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    {tableColumns?.map(element => {
                                        return (
                                            <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        )
                                    })
                                    }
                                </tr>
                            )
                        })}
                    </>
                    : <>
                        {rows?.length > 0 && rows?.map((row: IWarehouseLocation, idx: number) => {
                            const valueKeys = row?.descriptor && Object.keys(row?.descriptor)
                            return (
                                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(row?._id || '')}>
                                            <p className='cut-text'>{row?._id}</p>
                                            <div>
                                                <img title={'Copy Section ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[25px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className='px-6 py-4'>{row?.barcode || ''}</td>
                                    <td className='px-6 py-4'>
                                        {valueKeys && valueKeys?.length > 0 && valueKeys?.map((item: any, index: number) => {
                                            return (
                                                <p key={index} className='border-b py-1'>{item}: {row?.descriptor?.[item] || ''}</p>
                                            )
                                        })}
                                    </td>
                                    <td className='px-6 py-4'>
                                        {row?.customSpace?.name || ''}
                                    </td>
                                    <td className='px-6 py-4'>
                                        {(valueKeys && valueKeys?.length>0) && valueKeys?.filter((item: any) => (((item)?.toLowerCase() === (row?.customSpace?.child)?.toLowerCase()) || !row?.customSpace?.child))?.map((item: any, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => row?.barcodes?.[item] && onClickShowCustomSpaceChildBarcode(row?.barcodes?.[item])}
                                                    className={row?.barcodes?.[item] ? 'underline cursor-pointer text-[#F7A21E] hover:opacity-95 border-b py-1' : 'border-b py-1'}>
                                                    {row?.descriptor?.[item] || item}: {row?.barcodes?.[item] || ''}
                                                </div>
                                            )
                                        })}
                                    </td>
                                    <td className='px-6 py-4'>{row?.capacity || ''}</td>
                                    <td className='px-6 py-4'>{row?.filledCapacity || 0}</td>
                                    <td className='px-3 py-4 min-w-[150px]'>
                                        <Button
                                            label={'Manage Barcode'}
                                            className={(!accessControl || actions?.['Manage Barcode']) ? 'btn-primary' : 'btn-primary-disable'}
                                            onClickButton={() => row?._id && (!accessControl || actions?.['Manage Barcode']) && onEditBarcode(row._id, row?.barcode)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </>
                }
            </Table>
        </>
    )
}

export default WarehouseLocationsTable;