import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-types';
import { IAddressErrors } from '../../../../interfaces/auth/ICreateUserSchema';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IToast } from '../../../../interfaces/components/IToast';
import {
    tryToAddWarehouse,
    tryToDisableWarehouse,
    tryToEditWarehouse,
    tryToFetchWarehouses
} from '../../../../store/inventory/broker/warehouses/warehousesSlice';
import {
    useGetAddressDetailsMutation,
    useValidatePostalCodeMutation,
    // useValidatePostalCodeMutation
} from '../../../../store/inventory/broker/address-check/addressCheckDomApi';
import WarehouseItem from '../details/WarehouseItem';
// import AddressModal from '../../../../shared/address/AddressModal';
import AddAddress from '../../../../shared/address/AddAddress';
import DeleteModal from '../../../../shared/delete-modal';
import Toast from '../../../../shared/toast';
import BillingAndShippingAddressModal from '../../../../shared/address/BillingAndShippingAddressModal';
interface IWarehouses {
    brokerMarkets: Array<any>;
    accessControl?: any;
    pageAccess?: any;
}

const warehouseValues = {
    markets: [],
    name: '',
    address: {
        street: '',
        addressNo: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        default: false,
        phone: '',
    }
}

const Warehouses = ({
    brokerMarkets,
    accessControl,
    pageAccess
}: IWarehouses) => {
    const dispatch = useAppDispatch();
    const [showWarehouseModal, setShowWarehouseModal] = useState<boolean>(false)
    const [warehouseSelected, setWarehouseSelected] = useState<string | null>()
    const [warehouseErrors, setWarehouseErrors] = useState<IAddressErrors | null>()
    const [warehouseItem, setWarehouseItem] = useState<IWarehouse>(warehouseValues)
    const [warehouses, setWarehouses] = useState<IWarehouse[] | null>()
    const [marketSelected, setMarketSelected] = useState<any>()
    const [showToast, setShowToast] = useState<IToast | null>()
    const [showResultsBilling, setShowResultsBilling] = useState<boolean>(false);
    const [searchAddress, setSearchAddress] = useState<string>();
    const [vatSelected, setVatSelected] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [showResults, setShowResults] = useState<boolean>(false)
    const [searchAddressResults, setSearchAddressResults] = useState<any>()
    const [searchAddressResultsBilling, setSearchAddressResultsBilling] = useState<any>();
    const [showDeleteWarehouseModal, setShowDeleteWarehouseModal] = useState<boolean>(false)
    const state = useAppSelector((state) => state.warehouses);
    const [getAddressDetails] = useGetAddressDetailsMutation()
    

    const [showBillingModal, setShowBillingModal] = useState<boolean>(false);
    const [billingErrors, setBillingErrors] = useState<IAddressErrors | null>();
    const [billingItem, setBillingItem] = useState<any>({});
    const [marketSelectedBilling, setMarketSelectedBilling] = useState<any>();
    const [sameForBilling, setSameForBilling] = useState<any>(false);
    const [searchAddressBilling, setSearchAddressBilling] = useState<string>();
    // const [userProfile, setUserProfile] = useState<any>()
    const [disabledCheck, setDisabledCheck] = useState<boolean>(true);
    const [enableSubmitBilling, setEnableSubmitBilling] = useState<boolean>(false);
    const [enableSubmitWarehouse, setEnableSubmitWarehouse] = useState<boolean>(false);
    const [warehouseId, setWarehouseId] = useState<string | undefined>('');
    const [validatePostalCode] = useValidatePostalCodeMutation()


    useEffect(() => {
        if(sameForBilling){
            setBillingItem({
                ...billingItem,
                address: {
                    city: warehouseItem?.address?.city,
                    addressNo: warehouseItem?.address?.addressNo,
                    postalCode: warehouseItem?.address?.postalCode,
                    country: warehouseItem?.address?.country,
                    state: warehouseItem?.address?.state,
                    phone: warehouseItem?.address?.phone,
                    street: warehouseItem?.address?.street
                }
            })
            setMarketSelectedBilling(marketSelected)
        }
    }, [sameForBilling])

    useEffect(() => {
        dispatch(tryToFetchWarehouses(null)).unwrap()
    }, [])

    useEffect(() => {
        setWarehouses(state.warehouses)
    }, [state.warehouses])

    useEffect(() => {
        if(!warehouseItem?.address?.city || !warehouseItem?.address?.addressNo || !warehouseItem?.address?.postalCode || !warehouseItem?.address?.street || !warehouseItem?.address?.country || !warehouseItem?.address?.state || !warehouseItem?.address?.phone){
            setDisabledCheck(true)
        }else{
            setDisabledCheck(false)
        }
    }, [warehouseItem])

    useEffect(() => {
        if(billingItem?.address?.city || billingItem?.address?.addressNo || billingItem?.address?.postalCode || billingItem?.address?.street || billingItem?.address?.country || billingItem?.address?.state || billingItem?.address?.phone || marketSelectedBilling){
            setEnableSubmitBilling(true)
        }else{
            setEnableSubmitBilling(false)
        }
    }, [billingItem])

    useEffect(() => {
        if(warehouseItem?.address?.city || warehouseItem?.address?.addressNo || warehouseItem?.address?.postalCode || warehouseItem?.address?.street || warehouseItem?.address?.country || warehouseItem?.address?.state || warehouseItem?.address?.phone || marketSelected || vatSelected){
            setEnableSubmitWarehouse(true)
        }else{
            setEnableSubmitWarehouse(false)
        }
    }, [warehouseItem])

    const onValidateISOCountry = async (country: string, state: string) => {
        try {
            const response: any = await getAddressDetails(`${country}, ${state}`)
            const results = response?.data?.results;
            if (results && results.length > 0) {
                return results[0]?.address_components?.[results[0]?.address_components?.length ? results[0]?.address_components?.length - 1 : 0]?.short_name;
            } else {
                return false;
            }
        } catch (err) {
            return false
        }
    }

    const onAppendUpdateNewWarehouse = async (type: string, index?: number) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        let isValid = true
        let errors
        if (!warehouseItem?.address?.country || warehouseItem?.address?.country === '') {
            isValid = false
            errors = { ...errors || {}, country: 'Country is required' }
        }
        if (!warehouseItem?.address?.street || warehouseItem?.address?.street === '') {
            isValid = false
            errors = { ...errors || {}, street: 'Street is required' }
        }
        if (!warehouseItem?.address?.addressNo || warehouseItem?.address?.addressNo === '') {
            isValid = false
            errors = { ...errors || {}, addressNo: 'Address No is required' }
        }
        if (!warehouseItem?.address?.city || warehouseItem?.address?.city === '') {
            isValid = false
            errors = { ...errors || {}, city: 'City is required' }
        }
        if (!warehouseItem?.address?.postalCode || warehouseItem?.address?.postalCode === '') {
            isValid = false
            errors = { ...errors || {}, postalCode: 'Postal Code is required' }
        }
        if (!warehouseItem?.address?.phone || warehouseItem?.address?.phone === '') {
            isValid = false
            errors = { ...errors || {}, phone: 'Phone is required' }
        }
        if (!warehouseItem?.address?.state || warehouseItem?.address?.state === '') {
            isValid = false
            errors = { ...errors || {}, state: 'State is required' }
        }
        if (!marketSelected || marketSelected?.length <= 0) {
            isValid = false
            errors = { ...errors || {}, market: 'Markets are required' }
        }
        if (!warehouseItem?.name || warehouseItem?.name === '') {
            isValid = false
            errors = { ...errors || {}, warehouseName: 'Warehouse Name is required' }
        }
        if (!warehouseItem?.address?.email || warehouseItem?.address?.email === '') {
            isValid = false
            errors = { ...errors || {}, email: 'Email is required' }
        }

        let isValidBilling = true
        let errorsBilling
        if (!billingItem?.address?.country || billingItem?.address?.country === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, country: 'Country is required' }
        }
        if (!billingItem?.address?.street || billingItem?.address?.street === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, street: 'Street is required' }
        }
        if (!billingItem?.address?.addressNo || billingItem?.address?.addressNo === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, addressNo: 'Address No is required' }
        }
        if (!billingItem?.address?.city || billingItem?.address?.city === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, city: 'City is required' }
        }
        if (!billingItem?.address?.postalCode || billingItem?.address?.postalCode === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, postalCode: 'Postal Code is required' }
        }
        if (!billingItem?.address?.phone || billingItem?.address?.phone === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, phone: 'Phone is required' }
        }
        if (!billingItem?.address?.state || billingItem?.address?.state === '') {
            isValidBilling = false
            errorsBilling = { ...errorsBilling || {}, state: 'State is required' }
        }

        if (isValid && warehouseItem && isValidBilling) {
            const resCountry = await onValidateISOCountry(warehouseItem?.address?.country, warehouseItem?.address?.state)
            if (resCountry) {
                setWarehouseErrors(undefined)
            } else {
                setWarehouseErrors({ country: 'Country is not valid' })
                setLoadingSubmit(false)
                return;
            }
            const postalCodeResponse: any = await validatePostalCode({ postalCode: warehouseItem?.address?.postalCode, country: warehouseItem?.address?.country })
            if (!(postalCodeResponse?.data?.results && postalCodeResponse?.data?.results?.length > 0)) {
                setWarehouseErrors({ postalCode: 'Postal Code is not valid' })
                setLoadingSubmit(false)
                return;
            } else {
                setWarehouseErrors(undefined)
            }
            const resCountryBilling = await onValidateISOCountry(billingItem?.address?.country, billingItem?.address?.state)
            if (resCountryBilling) {
                setBillingErrors(undefined)
            } else {
                setBillingErrors({ country: 'Country is not valid' })
                setLoadingSubmit(false)
                return;
            }
            const postalCodeResponseBilling: any = await validatePostalCode({ postalCode: billingItem?.address?.postalCode, country: billingItem?.address?.country })
            if (!(postalCodeResponseBilling?.data?.results && postalCodeResponseBilling?.data?.results?.length > 0)) {
                setBillingErrors({ postalCode: 'Postal Code is not valid' })
                setLoadingSubmit(false)
                return;
            } else {
                setBillingErrors(undefined)
            }
            try {
                if (type === 'update' && warehouseId) {
                    // const findCountry = brokerMarkets?.length > 0 && brokerMarkets?.find((item) => item?.market?._id === (marketSelected?.[0]?.value))
                    const formatMarketsIds = marketSelected && marketSelected?.length > 0 && marketSelected?.map((item: any) => {
                        return item?.value
                    })
                    const formatData = {
                        _id: warehouseId,
                        name: warehouseItem?.name,
                        marketIds: formatMarketsIds || [],
                        vatTypes: vatSelected && vatSelected?.length > 0 ? vatSelected?.map((item: any) => item?.value) : undefined,
                        markets: formatMarketsIds || [],
                        address: {
                            ...warehouseItem?.address,
                            country: resCountry || warehouseItem?.address?.country,
                             warehouseCountry: resCountry || warehouseItem?.address?.country,
                        },
                        billingAddress: {...billingItem?.address},
                        shippingAddress: {...warehouseItem?.address}
                    }
                    await dispatch(tryToEditWarehouse(formatData)).unwrap()
                    setShowToast({ type: 'success', message: 'Warehouse successfully updated' });
                } else {
                    // const findCountry = brokerMarkets?.length > 0 && brokerMarkets?.find((item) => item?.market?._id === (marketSelected?.[0]?.value))
                    const formatMarkets = (marketSelected && marketSelected?.length > 0) && marketSelected?.map((item: any) => {
                        return item?.value
                    })
                    const formatData = {
                        ...warehouseItem,
                        markets: formatMarkets || [],
                        vatTypes: vatSelected && vatSelected?.length > 0 ? vatSelected?.map((item: any) => item?.value) : undefined,
                        enabled: true,
                        address: {
                            ...warehouseItem?.address,
                            warehouseCountry: warehouseItem?.address?.country,
                        },
                        shippingAddress: {
                            ...warehouseItem?.address,
                        },
                        billingAddress: enableSubmitBilling ? {street: billingItem?.address?.street, city: billingItem?.address?.city, postalCode: billingItem?.address?.postalCode, country: billingItem?.address?.country, addressNo: billingItem?.address?.addressNo, phone: billingItem?.address?.phone, state:  billingItem?.address?.state} : undefined
                    }
                    await dispatch(tryToAddWarehouse(formatData)).unwrap()
                    setShowToast({ type: 'success', message: 'Warehouse successfully added' });
                }
            } catch (err: any) {
                setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
            }
            setShowWarehouseModal(false)
            setWarehouseItem(warehouseValues)
            setWarehouseErrors(null)
            setVatSelected(undefined)
            setMarketSelected(undefined)
            setWarehouseSelected(null)
            setBillingItem(undefined)
            setSameForBilling(false)
            setBillingErrors(null)
        } else {
            setWarehouseErrors(errors)
            setBillingErrors(errorsBilling)
        }
        setLoadingSubmit(false)
    }

    const onChangeAddressInput = (value: string | null | undefined, type: string) => {
        const phoneNumberCharacters = /^[0-9+\s]+$/;
        if (type === 'phone' && value && !phoneNumberCharacters.test(value)) {
            return;
        }
        setWarehouseItem({
            ...warehouseItem,
            address: {
                ...warehouseItem?.address,
                [type]: value
            }
        })
    }

    const onSelectOneAddressBilling = (response: any) => {
        if (response && response?.address_components) {
            let billingItemFormat: any = billingItem
            for (const component of response?.address_components) {
                const componentType = component?.types?.[0];
                switch (componentType) {
                    case 'street_number': {
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                addressNo: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'route': {
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                street: `${component.short_name}`
                            }
                        }
                        break;
                    }
                    case 'postal_code': {
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                postalCode: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'postal_code_suffix': {
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                postalCode: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'locality':
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                city: `${component.long_name}`
                            }
                        }
                        break;
                    case 'administrative_area_level_1':
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                state: `${component.short_name}`
                            }
                        }
                        break;
                    case 'administrative_area_level_2':
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                state: `${component.short_name}`
                            }
                        }
                        break;
                    case 'country': {
                        const findMarket = brokerMarkets?.find((market) => market?.market?.name === component.short_name)
                        setMarketSelected(findMarket?.market ? [{ ...findMarket || {}, name: findMarket?.market?.name, label: findMarket?.market?.label, value: findMarket?.market?._id }] : undefined)
                        billingItemFormat = {
                            ...billingItemFormat || {},
                            address: {
                                ...billingItemFormat.address || {},
                                country: `${component.long_name}`
                            }
                        }
                        break;
                    }
                }
            }
            setBillingItem(billingItemFormat || {})
            setShowResultsBilling(false)
            setSearchAddressResultsBilling(undefined)
            setSearchAddressBilling(undefined)
        }
    }

    const onOpenWarehouseModal = (item?: IWarehouse) => {
        if (item) {
            setWarehouseId(item?._id)
            setWarehouseSelected(item?.id)
            setWarehouseItem(item)
            const formatWarehouseMarkets = item?.markets && item?.markets?.length > 0 && item?.markets?.map((market) => {
                return {
                    ...market,
                    value: market?._id,
                }
            })
            setMarketSelected(formatWarehouseMarkets)
            const formatVatTypes = (item?.vatTypes && item?.vatTypes?.length > 0) && VAT_TYPE_ITEMS?.filter((vat: any) => (item.vatTypes || []).includes(vat?.value))
            setVatSelected(formatVatTypes)
            setBillingItem({address: item?.billingAddress})
        } else {
            setWarehouseId('')
            setWarehouseSelected(undefined)
            setWarehouseItem(warehouseValues)
            setMarketSelected(undefined)
            setVatSelected(undefined)
        }
        setShowWarehouseModal(true)
    }

    const onChangeWarehouseName = (value: string | null | undefined, type: string) => {
        setWarehouseItem({
            ...warehouseItem,
            name: value || ''
        })
    }

    const onChangeSelectedMarket = (e: any) => {
        // const findMarket = brokerMarkets?.find((market) => market?.market?._id === e?.target?.value)
        setMarketSelected(e)
        setWarehouseItem({
            ...warehouseItem,
            markets: e
        })
    }

    const onClickDeleteWarehouse = (warehouse: IWarehouse) => {
        setWarehouseSelected(warehouse?._id)
        setShowDeleteWarehouseModal(true)
    }

    const onCloseDeleteModal = () => {
        setWarehouseSelected(undefined)
        setShowDeleteWarehouseModal(false)
    }

    const onChangeStatusWarehouse = () => {
        dispatch(tryToDisableWarehouse(warehouseSelected || ''))
        onCloseDeleteModal()
    }

    const onChangeSearchAddress = async (e: any, type?: string) => {
        setSearchAddress(e)
    }

    const onSelectOneAddress = (response: any) => {
        if (response && response?.address_components) {
            let warehouseItemFormat: any = warehouseItem
            for (const component of response?.address_components) {
                const componentType = component?.types?.[0];
                switch (componentType) {
                    case 'street_number': {
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                addressNo: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'route': {
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                street: `${component.short_name}`
                            }
                        }
                        break;
                    }
                    case 'postal_code': {
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                postalCode: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'postal_code_suffix': {
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                postalCode: `${component.long_name}`
                            }
                        }
                        break;
                    }
                    case 'locality':
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                city: `${component.long_name}`
                            }
                        }
                        break;
                    case 'administrative_area_level_1':
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                state: `${component.short_name}`
                            }
                        }
                        break;
                    case 'administrative_area_level_2':
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                state: `${component.short_name}`
                            }
                        }
                        break;
                    case 'country': {
                        const findMarket = brokerMarkets?.find((market) => market?.market?.name === component.short_name)
                        setMarketSelected(findMarket?.market ? [{ ...findMarket || {}, name: findMarket?.market?.name, label: findMarket?.market?.label, value: findMarket?.market?._id }] : undefined)
                        warehouseItemFormat = {
                            ...warehouseItemFormat || {},
                            address: {
                                ...warehouseItemFormat.address || {},
                                country: `${component.long_name}`
                            }
                        }
                        break;
                    }
                }
            }
            setWarehouseItem(warehouseItemFormat || {})
            setShowResults(false)
            setSearchAddressResults(undefined)
            setSearchAddress(undefined)
        }
    }

    const onPressEnterSearch = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (searchAddress) {
                const response: any = await getAddressDetails(searchAddress)
                if (response?.data?.results) {
                    setSearchAddressResults(response?.data?.results)
                }
                setShowResults(true)
            }
        }
        setShowResults(true)
    }

    const onPressEnterSearchBilling = async (e: any) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (searchAddressBilling) {
                const response: any = await getAddressDetails(searchAddressBilling)
                if (response?.data?.results) {
                    setSearchAddressResultsBilling(response?.data?.results)
                }
                setShowResultsBilling(true)
            }
        }
        setShowResultsBilling(true)
    }

    const onChangeVatType = (value: any, type?: string) => {
        setVatSelected(value)
    }

    const onAddBillingAddress = () => {
        
    }
    const onChangeBillingAddressInput = (value: string | null | undefined, type: string) => {
        const phoneNumberCharacters = /^[0-9+\s]+$/;
        if (type === 'phone' && value && !phoneNumberCharacters.test(value)) {
            return;
        }
        setBillingItem({
            ...billingItem,
            address: {
                ...billingItem?.address,
                [type]: value
            }
        })
    }
    const onChangeSelectedMarketBilling = (e: any) => {
        // const findMarket = brokerMarkets?.find((market) => market?.market?._id === e?.target?.value)
        setMarketSelectedBilling(e)
        setBillingItem({
            ...billingItem,
            markets: e
        })
    }
    const handleSameForBillingAddress = () => {
        setSameForBilling(!sameForBilling)
    }

    const clearBilling = () => {
        setMarketSelectedBilling(undefined)
        setBillingItem({})
    }

    const clearWarehouse = () => {
        setVatSelected(undefined)
        setMarketSelected(undefined)
        setWarehouseItem(warehouseValues)
    }

    const clearBothAddresses = () => {
        setSameForBilling(false)
        clearBilling()
        clearWarehouse()
    }

    const onChangeSearchAddressBilling = async (e: any, type?: string) => {
        setSearchAddressBilling(e)
    }

    return (
        <div className='grid grid-cols-3 gap-4'>
            {warehouses && warehouses?.length > 0 && warehouses?.map((warehouse: IWarehouse, index: number) => {
                return (
                    <div
                        key={index}
                        className='cursor-pointer hover:opacity-90'>
                        <WarehouseItem
                            showActions={true}
                            key={index}
                            warehouse={warehouse}
                            isEnable={true}
                            accessDeleteWarehouse={(!accessControl || pageAccess?.actions?.['Delete Warehouse'])}
                            accessEditWarehouse={(!accessControl || pageAccess?.actions?.['Edit Warehouse'])}
                            onChangeStatusWarehouse={() => onClickDeleteWarehouse(warehouse)}
                            onEditWarehouse={() => onOpenWarehouseModal(warehouse)}
                        />
                    </div>
                )
            })}
            {(!accessControl || pageAccess?.actions?.['Add new warehouse']) &&
                <AddAddress
                    title={'Add new warehouse'}
                    onClickAddAddress={() => onOpenWarehouseModal()}
                />
            }
            {showWarehouseModal && <BillingAndShippingAddressModal
                     isWarehouse={true}
                     loading={loadingSubmit}
                     markets={brokerMarkets?.map(market => ({ ...market || {}, name: market?.market?.name, label: market?.market?.label, value: market?.market?._id }))}
                     selectedMarket={marketSelected || warehouseItem?.markets}
                     openAddressModal={showWarehouseModal}
                     newAddress={warehouseItem?.address}
                     searchAddress={searchAddress}
                     showResults={showResults}
                     showResultsBilling={showResultsBilling}
                     errors={warehouseErrors}
                     selectedVat={vatSelected}
                     results={searchAddressResults}
                     resultsBilling={searchAddressResultsBilling}
                     searchAddressBilling={searchAddressBilling}
                     addressId={0}
                     type={warehouseId ? 'update' : 'add'}
                     warehouseName={warehouseItem?.name}
                     handleCloseAddressModal={() => {
                         setShowWarehouseModal(false)
                         setWarehouseErrors(undefined)
                         setMarketSelected(undefined)
                         setMarketSelectedBilling(undefined)
                         setVatSelected(undefined)
                         setLoadingSubmit(false)
                         setShowBillingModal(false)
                         setWarehouseErrors(undefined)
                         setBillingErrors(undefined)
                         setMarketSelected(undefined)
                         setVatSelected(undefined)
                         setLoadingSubmit(false)
                         setEnableSubmitBilling(false)
                         setEnableSubmitWarehouse(false)
                         setSameForBilling(false)
                         setDisabledCheck(true)
                         setBillingItem({})
                         setWarehouseId('')
                     }}
                     onSelectOneAddress={onSelectOneAddress}
                     onSelectOneAddressBilling={onSelectOneAddressBilling}
                     onChangeVatType={onChangeVatType}
                     onChangeSearchAddress={onChangeSearchAddress}
                     onSaveChanges={onAppendUpdateNewWarehouse}
                     onChangeAddressInput={onChangeAddressInput}
                     onPressEnterSearch={onPressEnterSearch}
                     onPressEnterSearchBilling={onPressEnterSearchBilling}
                     hideResults={() => setShowResults(false)}
                     onShowResult={() => setShowResults(true)}
                     onChangeWarehouseName={onChangeWarehouseName}
                     onChangeSelectedMarket={onChangeSelectedMarket}
                     marketsBilling={brokerMarkets?.map(market => ({ ...market || {}, name: market?.market?.name, label: market?.market?.label, value: market?.market?._id }))}
                     billingItem={billingItem}
                     marketSelected={marketSelectedBilling}
                     showBillingModal={showBillingModal}
                     selectedVatBilling={vatSelected}
                     onSaveChangesBilling={onAddBillingAddress}
                     onChangeAddressInputBilling={onChangeBillingAddressInput}
                     newAddressBilling={billingItem?.address}
                     onChangeSelectedMarketBilling={onChangeSelectedMarketBilling}
                     sameForBilling={sameForBilling}
                     handleSameForBillingAddress={handleSameForBillingAddress}
                     disabledCheck={disabledCheck}
                     enableSubmitBilling={enableSubmitBilling}
                     enableSubmitWarehouse={enableSubmitWarehouse}
                     billingErrors={billingErrors}
                     clearBilling={clearBilling}
                     clearWarehouse={clearWarehouse}
                     clearBothAddresses={clearBothAddresses}
                     onChangeSearchAddressBilling={onChangeSearchAddressBilling}
                     warehouseId={warehouseId}
                />}
            {/* {showWarehouseModal &&
                <AddressModal
                    isWarehouse={true}
                    loading={loadingSubmit}
                    markets={brokerMarkets?.map(market => ({ ...market || {}, name: market?.market?.name, label: market?.market?.label, value: market?.market?._id }))}
                    selectedMarket={marketSelected || warehouseItem?.markets}
                    selectedVat={vatSelected}
                    openAddressModal={showWarehouseModal}
                    newAddress={warehouseItem?.address}
                    searchAddress={searchAddress}
                    showResults={showResults}
                    errors={warehouseErrors}
                    results={searchAddressResults}
                    addressId={0}
                    type={warehouseItem?._id ? 'update' : 'add'}
                    warehouseName={warehouseItem?.name}
                    handleCloseAddressModal={() => {
                        setShowWarehouseModal(false)
                        setWarehouseErrors(undefined)
                        setMarketSelected(undefined)
                        setVatSelected(undefined)
                        setLoadingSubmit(false)
                    }}
                    onSelectOneAddress={onSelectOneAddress}
                    onChangeSearchAddress={onChangeSearchAddress}
                    onChangeVatType={onChangeVatType}
                    onSaveChanges={onAppendUpdateNewWarehouse}
                    onChangeAddressInput={onChangeAddressInput}
                    onPressEnterSearch={onPressEnterSearch}
                    hideResults={() => setShowResults(false)}
                    onShowResult={() => setShowResults(true)}
                    onChangeWarehouseName={onChangeWarehouseName}
                    onChangeSelectedMarket={onChangeSelectedMarket}
                />
            } */}
            <DeleteModal
                openDeleteModal={showDeleteWarehouseModal}
                handleCloseDeleteModal={onCloseDeleteModal}
                onApproveDeletion={onChangeStatusWarehouse}
            />
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}

export default Warehouses;