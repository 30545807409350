export const VAT_TYPE_ITEMS = [
    { value: 'Standard', label: 'Standard', name: 'Standard' },
    { value: 'UK Margin', label: 'UK Margin', name: 'UK Margin' },
    { value: 'EU Margin', label: 'EU Margin', name: 'EU Margin' },
    { value: 'EU Standard', label: 'EU Standard', name: 'EU Standard' },
    // { value: 'B2B Margin', label: 'B2B Margin' },
]

export const VAT_ITEMS = [
    { value: 'Standard', label: 'Standard', name: 'Standard' },
    { value: 'UK Margin', label: 'UK Margin', name: 'UK Margin' },
    { value: 'EU Margin', label: 'EU Margin', name: 'EU Margin' },
    { value: 'EU Standard', label: 'EU Standard', name: 'EU Standard' },
]

export const INVOICE_VAT_TYPES = [
    { value: 'Standard', label: 'Standard', name: 'Standard' },
    { value: 'Reverse', label: 'Reverse', name: 'Reverse' },
    { value: 'Zero', label: 'Zero', name: 'Zero' },
    { value: 'Ec-Sale', label: 'Ec-Sale', name: 'Ec-Sale' },
]