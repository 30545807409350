import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToFetchAllWarehouseSections,
    tryToFetchSectionChildsValues,
    tryToFetchSectionLocations
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Table from '../../../../shared/table';


interface IReviewItemLocationModal {
    openModal: boolean;
    warehouseId?: string;
    categories?: any;
    allCategories?: any;
    availableLocations?: any;
    setAvailableLocations?: any;
    mainLocations?: any;
    setMainLocations?: any;
    handleCloseModal: () => void;
}

const ReviewItemLocationModal = ({
    openModal,
    warehouseId,
    categories,
    allCategories,
    availableLocations,
    setAvailableLocations,
    setMainLocations,
    mainLocations,
    handleCloseModal,
}: IReviewItemLocationModal) => {
    const dispatch = useAppDispatch();
    const [sections, setSections] = useState<any>();
    const [categoriesToShow, setCategoriesToShow] = useState<any>();
    const [scannedLocation, setScannedLocation] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [sectionChilds, setSectionChilds] = useState<any>();
    const [selectedSectionChilds, setSelectedSectionsChilds] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [allLocations, setAllLocations] = useState<any>();
    const [allSections, setAllSections] = useState<any>();
    const [categorySelected, setCategorySelected] = useState<any>();
    const [categoryChanged, setCategoryChanged] = useState<any>();

    const areObjectsEqual = (obj1: any, obj2: any) => {
        const stringifiedObj1 = obj1 && JSON.stringify(obj1, Object.keys(obj1).sort());
        const stringifiedObj2 = obj2 && JSON.stringify(obj2, Object.keys(obj2).sort());
        return stringifiedObj1 === stringifiedObj2;
    }

    useEffect(() => {
        if (allCategories && availableLocations && categories && mainLocations && !categoriesToShow) {
            const categoriesKeys = mainLocations && Object.keys(mainLocations)
            const findAllCategoriesToShow = categoriesKeys && categoriesKeys?.map((item: any) => {
                const findCategory = allCategories && allCategories?.length > 0 && allCategories?.find((category: any) => category?.id === item)
                return findCategory
            })
            setCategoriesToShow(findAllCategoriesToShow || [])
        }else{
            setCategoriesToShow(categories)
        }
    }, [availableLocations, mainLocations, categories, allCategories])

    const onGettingWarehouseSections = async () => {
        try {
            let responseLocations = {}
            await Promise.all((categoriesToShow && categoriesToShow?.length > 0) && categoriesToShow?.map(async (item: any) => {
                if (item.id) {
                    const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: warehouseId || '', category: item?.id })).unwrap()
                    const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
                    responseLocations = {
                        ...responseLocations || {},
                        [item?.id]: formatResponse
                    }
                }
            })
            )
            setSections(responseLocations || undefined)
        } catch (err) {
            // error here
        }
    }

    const onGettingWarehouseSectionChilds = async () => {
        if (categoryChanged) {
            try {
                const findKeyValues = selectedSectionChilds?.[categoryChanged] && Object?.keys(selectedSectionChilds?.[categoryChanged])
                const formatValues = (findKeyValues && findKeyValues?.length > 0) && findKeyValues?.map((item: string) => ({ [item]: selectedSectionChilds?.[categoryChanged]?.[item] }))
                const dataValues = formatValues ? { values: formatValues } : undefined
                const response = await dispatch(tryToFetchSectionChildsValues({ id: selectedSection?.[categoryChanged]?.value || scannedLocation?.[categoryChanged], data: dataValues })).unwrap()
                const responseLocations = {
                    ...sectionChilds || {},
                    [categoryChanged]: response
                }
                setSectionChilds(responseLocations || undefined)
            } catch (err) {
                // error here
            }
            setCategoryChanged(undefined)
        }
    }

    useEffect(() => {
        setAllSections(mainLocations)
        setAllLocations(availableLocations)
    }, [warehouseId, mainLocations, availableLocations])

    useEffect(() => {
        if (warehouseId && categoriesToShow) {
            onGettingWarehouseSections()
        }
    }, [warehouseId, categoriesToShow, openModal])

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setAllLocations(undefined)
        setAllSections(undefined)
        setGeneralError(undefined)
        setSectionChilds(undefined)
        setCategoriesToShow(undefined)
        setCategoryChanged(undefined)
        setSelectedSectionsChilds(undefined)
    }

    const onSubmitItem = async () => {
        setMainLocations(allSections)
        setAvailableLocations(allLocations)
        onCloseModal()
    }

    useEffect(() => {
        if (categoryChanged) {
            onGettingWarehouseSectionChilds()
        }
    }, [categoryChanged])

    const onChangedScannedLocation = (e: any, type?: string) => {
        if (type) {
            setScannedLocation({
                ...scannedLocation || {},
                [type]: e
            })
            setSelectedSection({
                ...selectedSection || {},
                [type]: undefined
            })
            setSectionChilds({
                ...sectionChilds || {},
                [type]: undefined
            })
            setSelectedSectionsChilds({
                ...selectedSectionChilds || {},
                [type]: undefined
            })
        }
    }

    const onChangeSelectedSection = (e: any, type?: string) => {
        if (type) {
            setSelectedSection({
                ...selectedSection || {},
                [type]: e
            })
            setCategoryChanged(type)
            setScannedLocation({
                ...scannedLocation || {},
                [type]: undefined
            })
            setSectionChilds({
                ...sectionChilds || {},
                [type]: undefined
            })
            setSelectedSectionsChilds({
                ...selectedSectionChilds || {},
                [type]: undefined
            })
        }
    }

    const onClickAddLocationOrSection = async (categoryId: string) => {
        if (!selectedSection && !scannedLocation) {
            return;
        }
        const findIfAlreadyAdded = allSections?.[categoryId] && allSections?.[categoryId]?.length > 0 && allSections?.[categoryId]?.find((item: any) => (item?.id === selectedSection?.[categoryId]?.barcode || scannedLocation?.[categoryId]) && (!selectedSectionChilds?.[categoryId] || !item?.children || areObjectsEqual(item?.children, selectedSectionChilds?.[categoryId])))
        if (findIfAlreadyAdded) {
            setGeneralError('Location already added on list')
            return;
        }
        setGeneralError(undefined)
        try {
            const findKeyValues = selectedSectionChilds?.[categoryId] && Object?.keys(selectedSectionChilds?.[categoryId])
            const formatValues = (findKeyValues && findKeyValues?.length > 0) && findKeyValues?.map((item: string) => ({ [item]: selectedSectionChilds?.[categoryId]?.[item] }))
            const dataValues = formatValues ? { values: formatValues } : undefined
            const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.[categoryId]?.barcode || scannedLocation?.[categoryId], data: dataValues }))?.unwrap()
            if (response && response?.length > 0) {
                const formatResponse = response && response?.length > 0 && response?.map((item: any) => {
                    let formatLabel: any
                    item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                        formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                    })
                    return {
                        ...item,
                        value: item?._id,
                        label: formatLabel,
                        name: formatLabel,
                    }
                })
                setAllLocations((prevAllLocations: any) => ({
                    ...prevAllLocations || {},
                    [categoryId]: {
                        ...prevAllLocations?.[categoryId] || {},
                        [selectedSection?.[categoryId].barcode || scannedLocation?.[categoryId]]: [
                            ...prevAllLocations?.[categoryId]?.[(selectedSection?.[categoryId].barcode || scannedLocation?.[categoryId])] || [],
                            ...formatResponse || [],
                        ]
                    }
                }))
                let formatNameSectionChild: any
                selectedSectionChilds?.[categoryId] && (Object?.keys(selectedSectionChilds?.[categoryId]))?.forEach((obj: any, idx: number) => {
                    formatNameSectionChild = idx === 0 ? selectedSectionChilds?.[categoryId]?.[obj] : formatNameSectionChild + ` - ${selectedSectionChilds?.[categoryId]?.[obj]}`
                })
                setAllSections((prevAllSections: any) => ({
                    ...prevAllSections || {},
                    [categoryId]: [
                        ...prevAllSections?.[categoryId] || [],
                        {
                            id: selectedSection?.[categoryId]?.barcode || scannedLocation?.[categoryId],
                            name: formatNameSectionChild ? `${selectedSection?.[categoryId].label} - ${formatNameSectionChild}` : selectedSection?.[categoryId].label,
                            children: selectedSectionChilds?.[categoryId]
                        }
                    ]?.filter((item, index, self) => index === self.findIndex((i) => i?.id === item?.id && (!i?.children || !item?.children || areObjectsEqual(item?.children, i?.children))))
                }))
                setGeneralError(undefined)
                setSelectedSection({
                    ...selectedSection || {},
                    [categoryId]: undefined
                })
                setScannedLocation({
                    ...scannedLocation || {},
                    [categoryId]: undefined
                })
                setSectionChilds({
                    ...sectionChilds || {},
                    [categoryId]: undefined
                })
                setSelectedSectionsChilds({
                    ...selectedSectionChilds || {},
                    [categoryId]: undefined
                })
            } else if (response && response?.length === 0) {
                setGeneralError('No available capacity in this location!')
            } else {
                setGeneralError('Not found any location for the Barcode!')
            }
        } catch (err) {
            setGeneralError(`${err}`)
        }
    }

    const onDeleteSection = (section: any, categoryId: string, children?: any) => {
        if (section) {
            setAllSections((prevAllSections: any) => ({
                ...prevAllSections || {},
                [categoryId]: prevAllSections?.[categoryId]?.filter((item: any) => !((item?.id === section) && (children ? JSON.stringify(children) === JSON.stringify(item?.children) : !item?.children)))
            }))
            setAllLocations((prevLocations: any) => {
                const newState = { ...prevLocations };
                delete newState?.[categoryId]?.[section];
                return newState;
            })
        }
    }

    const onEnterLocationScanInput = async (e: any, type?: string) => {
        if (e?.key === 'Enter') {
            setCategoryChanged(type)
        }
    }

    const onChangeSelectedSectionChild = (e: any, type?: string, index?: any) => {
        if (type && index) {
            setSelectedSectionsChilds({
                ...selectedSectionChilds || {},
                [type]: {
                    ...selectedSectionChilds?.[type] || {},
                    [index]: e?.value
                }
            })
            setCategoryChanged(type)
        }
    }

    const onChangeOtherCategories = (value: any, type?: string) => {
        setCategorySelected(value)
    }

    const onClickAddAnotherCategory = () => {
        if (categorySelected) {
            setCategoriesToShow((prevCategories: any) => [
                ...prevCategories || [],
                categorySelected || {},
            ])
            setCategorySelected(undefined)
        }
    }

    const fitlerAllCategories = categoriesToShow && allCategories?.filter((item: any) =>
        !categoriesToShow?.some((category: any) => category?.id === item?.id)
    );

    return (
        <Modal
            open={openModal}
            showInRight
            title='Main Location'
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[700px] min-h-[350px]'}>
            <div className={'p-3 min-w-[650px] min-h-[100%]'}>
                <p className='mb-4 font-bold text-xl'>Auto Assign Locations</p>
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    {categoriesToShow && categoriesToShow?.length > 0 && categoriesToShow?.map((category: any, index: number) => {
                        const categorySections = allSections && allSections?.[category?.id]
                        const findCategoryDetails = (allCategories && allCategories?.length > 0) && allCategories?.find((item: any) => item?.value === category?.id)
                        const categorySectionChild = sectionChilds?.[category?.id] && Object.keys(sectionChilds?.[category?.id])
                        return (
                            <div key={index} className='border-b-2 border-[#e4e4e4] pb-2 mb-3'>
                                <div className='my-2 flex flex-row items-center'>
                                    <img src={findCategoryDetails?.enabledIcon} className='object-contain min-h-[24px] h-[24px] mr-2' />
                                    <p>{findCategoryDetails?.label || findCategoryDetails?.name}</p>
                                </div>
                                <p>Select Main Location (Scan or Select section)</p>
                                <div className='my-2 flex flex-row items-center'>
                                    <Input
                                        label='Scan Section Barcode'
                                        containerStyle='min-w-[250px] mr-2'
                                        showValue={true}
                                        inputUniqueName={category?.id}
                                        inputValue={scannedLocation?.[category?.id]}
                                        onHandleKeyDown={onEnterLocationScanInput}
                                        onChangeInput={onChangedScannedLocation}
                                    />
                                    <SelectCheckbox
                                        name='Select Section'
                                        placeholder=' '
                                        options={sections?.[category?.id]}
                                        uniqueName={category?.id}
                                        selectedOption={selectedSection?.[category?.id]}
                                        containerStyle='min-w-[250px]'
                                        onChangeSelectedOption={onChangeSelectedSection}
                                    />
                                </div>
                                {categorySectionChild && categorySectionChild?.length > 0 &&
                                    <div className='grid grid-cols-2 span-2'>
                                        {categorySectionChild?.map((child: any, index: number) => {
                                            return (
                                                <SelectCheckbox
                                                    name={child}
                                                    key={index}
                                                    placeholder=' '
                                                    disabled={!(sectionChilds?.[category?.id]?.[child] && sectionChilds?.[category?.id]?.[child]?.length > 0)}
                                                    options={sectionChilds?.[category?.id]?.[child] && sectionChilds?.[category?.id]?.[child]?.map((key: any) => ({ value: key, label: key }))}
                                                    containerStyle='min-w-[250px]'
                                                    uniqueName={category?.id}
                                                    index={child}
                                                    selectedOption={selectedSectionChilds?.[category?.id]?.[child] ? { value: selectedSectionChilds?.[category?.id]?.[child], label: selectedSectionChilds?.[category?.id]?.[child] } : undefined}
                                                    onChangeSelectedOption={onChangeSelectedSectionChild}
                                                />
                                            )
                                        })
                                        }
                                    </div>
                                }
                                <div className='flex flex-row justify-end mb-3 mt-1'>
                                    <Button
                                        label='Add as Category Locations'
                                        className='btn-primary mx-4 mt-2'
                                        onClickButton={() => onClickAddLocationOrSection(category?.id)}
                                    />
                                </div>
                                <div>
                                    {(categorySections && categorySections?.length > 0) &&
                                        <Table
                                            hidePagination={true}
                                            containerStyle='mb-4'
                                            headerStyle='!bg-black !text-white'
                                            columns={['Location', 'Location Name', 'Delete']}>
                                            {allSections?.[category?.id]?.map((obj: any, idx: number) => {
                                                return (
                                                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                                        <td className='px-6 py-2'>{obj?.id}</td>
                                                        <td className='px-6 py-2'>{obj?.name || ''}</td>
                                                        <td className='px-6 py-2'>
                                                            <div className='cursor-pointer' onClick={() => onDeleteSection(obj?.id, category?.id, obj?.children)}>
                                                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </Table>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <div className='border-b-2 border-[#e4e4e4] pb-2 mb-3'>
                        <SelectCheckbox
                            name={'Select Other Categories'}
                            options={allCategories ? fitlerAllCategories : []}
                            selectedOption={categorySelected}
                            onChangeSelectedOption={onChangeOtherCategories}
                            containerStyle='max-w-[80%]'
                        />
                        <div className='flex flex-row my-4'>
                            <Button
                                label={'Add Category'}
                                className={categorySelected ? 'btn-main !rounded' : 'btn-primary-disable !rounded'}
                                onClickButton={onClickAddAnotherCategory}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center my-4'>
                    <Button
                        label='Confirm'
                        className='btn-primary'
                        onClickButton={onSubmitItem}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ReviewItemLocationModal;