import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddPurchasePackages,
    tryToChangePurchaseStatus,
    tryToGetPurchaseUuid,
    tryToPayPurchase
} from '../../../../store/inventory/broker/purchases/purchasesSlice';
import { tryToFetchAllWarehouseSections } from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import PrintLabel from '../print-label/PrintLabel';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Table from '../../../../shared/table';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IBatchingCompleteModal {
    openModal: boolean;
    purchaseId: string;
    quoteId: string;
    selectedOrderStatus: string;
    expectedItems: any;
    dueDate?: string;
    handleCloseModal: () => void;
}

const BatchingCompleteModal = ({
    openModal,
    purchaseId,
    quoteId,
    selectedOrderStatus,
    expectedItems,
    dueDate,
    handleCloseModal,
}: IBatchingCompleteModal) => {
    const dispatch = useAppDispatch();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [csvData, setCsvData] = useState<any>();
    const [sections, setSections] = useState<any>();
    const [packages, setPackages] = useState<any>([{}]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fileSelected, setFileSelected] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [loadingDownloadCSV, setLoadingDownloadCSV] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [dueDateNew, setDueDateNew] = useState<any>(moment(dueDate).format('YYYY-MM-DD'))
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: activeWarehouse?._id || '' })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
        setLoading(false)
    }

    const onGettingUUID = async () => {
        try {
            const response = await dispatch(tryToGetPurchaseUuid(null)).unwrap();
            return response
        } catch (err) {
            // error here
        }
        return undefined
    }

    useEffect(() => {
        setLoading(true)
        onGettingWarehouseSections()
    }, [purchaseId, openModal])

    const onCloseModal = () => {
        setGeneralError(undefined)
        setLoading(false)
        setSubmitLoading(false)
        setPackages([{}])
        handleCloseModal()
    }

    const onSubmit = async () => {
        setSubmitLoading(true)
        let error = ''
        if (!packages || packages?.length <= 0) {
            setGeneralError('Please add at least one row to continue')
            setSubmitLoading(false)
            return;
        }
        if (!fileSelected) {
            setGeneralError('Please import CSV file to continue')
            setSubmitLoading(false)
            return;
        }
        if (packages && packages?.length > 0) {
            packages?.forEach((item: any) => {
                if (!item?.name || !item?.space || (!item?.nrOfItems && item?.nrOfItems !== 0)) {
                    error = 'Please fill all the details to continue '
                }
            })
            if (error) {
                setSubmitLoading(false)
                setGeneralError(error)
                return;
            }
        }
        setGeneralError(undefined)
        try {
            const formBody = {
                totalPackages: packages?.length || 0,
                packages: (packages && packages?.length > 0) ?
                    packages?.map((item: any, index: number) => {
                        return {
                            id: item?.id,
                            orderNumber: index + 1,
                            spaceId: item?.space?.value,
                            name: item?.name,
                            nrOfItems: item?.nrOfItems
                        }
                    })
                    : []
            }
            await dispatch(tryToAddPurchasePackages({ id: purchaseId, data: formBody, status: selectedOrderStatus })).unwrap()
            await dispatch(tryToPayPurchase({ id: purchaseId || '', dueDate: dueDateNew })).unwrap()
            const formatItems: any = [];
            (expectedItems && expectedItems?.length > 0) && expectedItems?.map((item: any) => {
                const itemName = [
                    ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                    ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
                ].join(' ');

                const itemData = {
                    itemName, currency: item?.currency, price: Number(item.price || 0).toFixed(2), item: item
                }
                formatItems.push(itemData);

                return item;
            })
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                transform: (value, header) => {
                    if (header === 'Item ID/ IMEI') {
                        return value.toString();
                    }
                    return value;
                },
                dynamicTyping: (header) => {
                    if (header === 'Item ID/ IMEI') {
                        return false;
                    }
                    return true;
                },
                complete: async function (results: any) {
                    const formatData: any = []
                    if (results?.data && results?.data?.length > 0) {
                        results?.data?.map((item: any) => {
                            const findItem = formatItems && formatItems?.length > 0 && formatItems?.find((oldItem: any) => oldItem?.itemName === item?.['Item Name'])
                            if (findItem) {
                                formatData.push({
                                    ...findItem?.item,
                                    itemId: item?.['Item ID/ IMEI'],
                                    price: item?.Price ? Number(item?.Price) : Number(findItem?.price),
                                    qty: undefined
                                })
                            }
                            return item
                        })
                    }
                    const payload = {
                        items: formatData || []
                    }
                    await dispatch(tryToChangePurchaseStatus({ id: purchaseId || '', status: 'completed', data: payload })).unwrap()
                }
            })
            setGeneralError(undefined)
            onCloseModal()
        } catch (err) {
            setGeneralError(`${err}`)
        }
        setSubmitLoading(false)
    }

    const onChangePackageDetailsInput = (e: any, type?: string, index?: any) => {
        if (type && (index || index === 0)) {
            const formatPackages = (packages && packages?.length > 0) ? packages?.map((item: any, idx: number) => {
                if (index === idx) {
                    return {
                        ...item,
                        id: type === 'name' ? undefined : item?.id,
                        [type]: e
                    }
                } else {
                    return item
                }
            }) : [{ [type]: e }]
            setPackages(formatPackages || [{}])
        }
    }

    const onAddPackage = async (packageName: string, packageSpace: any, idx: number) => {
        if (!packageName || !packageSpace) {
            setGeneralError('Package name and Section is required!')
            return;
        }
        const findPackageName = packages && packages?.length > 0 && packages?.find((item: any, index: number) => (item?.name === packageName) && (index !== idx))
        if (findPackageName) {
            setGeneralError('Package name should be unique')
            return;
        }
        setGeneralError(undefined)
        const uuid = await onGettingUUID()
        const formatPackages = packages && packages?.length > 0 && packages?.map((item: any) => {
            if (item?.name === packageName) {
                return {
                    ...item,
                    id: uuid,
                }
            } else {
                return item
            }
        })
        setPackages(formatPackages || [{}])
    }

    useEffect(() => {
        if (packages && packages?.length > 0) {
            packages?.forEach((item: any, idx: number) => {
                if (item?.name && item?.space && !item?.id) {
                    onAddPackage(item?.name, item?.space, idx)
                }
            })
        }
    }, [packages])

    const onDeletePackage = (id: string) => {
        setPackages((prevPackages: any) => prevPackages?.filter((item: any) => (item?.id !== id)) || [{}])
    }

    const onAddRow = () => {
        setPackages([
            ...packages || [],
            {}
        ])
    }

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDueDateNew(value)
    }

    const onDownloadCSVData = () => {
        setLoadingDownloadCSV(true)
        setCsvData(undefined)
        const formatItems: any = [['Item Name', 'Item ID/ IMEI', 'Currency', 'Price']];
        (expectedItems && expectedItems?.length > 0) && expectedItems?.map((item: any) => {
            const itemName = [
                ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
            ].join(' ');

            const itemData = [itemName, item?.itemCode || item?.itemId, item?.currency, Number(item.price || 0).toFixed(2)]
            const qty = Number(item?.qty || 1);
            for (let i = 0; i < qty; i++) {
                formatItems.push(itemData);
            }
            return item;
        })
        setCsvData(formatItems)
    }

    useEffect(() => {
        if (csvData && loadingDownloadCSV) {
            csvLink.current.link.click()
            setLoadingDownloadCSV(false)
        }
    }, [csvData])

    const numberOfPackages = packages.reduce((accumulator: number, item: any) => accumulator + Number(item.nrOfItems || 0), 0)
    const expectedItemsNumber = expectedItems.reduce((accumulator: number, item: any) => accumulator + Number(item.qty || 0), 0)
    return (
        <Modal
            open={openModal}
            showInRight
            onClose={() => onCloseModal()}
            title='Mark Order Completed'
            contentContainerStyle='!min-w-[70vw]'>
            <div className={'p-2 min-w-[65vw] min-h-[300px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Mark Order as Completed</p>
                </div>
                {generalError && <Error text={generalError} />}
                <div className='bg-[#f8f9fc] rounded-[6px]'>
                    <div className='border-b p-3'>
                        <p className='my-2 font-bold'>Are you sure you want to Mark this Purchase as Paid?</p>
                        <div className='my-5'>
                            <Datepicker
                                label='Due Date'
                                placeholder=' '
                                containerStyle='mb-0'
                                inputValue={dueDateNew || undefined}
                                onChangeInput={onChangeDateFilters}
                                inputUniqueName={'dueDate'}
                            />
                        </div>
                    </div>
                    {(!loading) &&
                        <>
                            <Table
                                hidePagination={true}
                                containerStyle='min-h-[150px] !pb-[70px]'
                                columns={['NR', 'Type of Container', 'Section', 'Nr. of items ', 'Label', 'ID', 'Delete']}>
                                {(packages && packages?.length > 0 ? packages : [{}]).map((obj: any, idx: number) => {
                                    return (
                                        <>
                                            <tr key={idx} className={`bg-[#f8f9fc] border-b hover:bg-gray-50`}>
                                                <td className='px-6 py-2 align-middle'>
                                                    {idx + 1}
                                                </td>
                                                <td className='px-6 py-2 align-middle max-w-[140px]'>
                                                    <Input
                                                        placeholder='Name'
                                                        showValue={true}
                                                        containerStyle='mt-1 max-w-[140px]'
                                                        inputUniqueName='name'
                                                        inputValue={obj?.name}
                                                        index={idx}
                                                        onChangeInput={onChangePackageDetailsInput}
                                                    />
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    <SelectCheckbox
                                                        placeholder='Section'
                                                        selectedOption={obj?.space}
                                                        options={sections}
                                                        containerStyle='!col-span-2'
                                                        onChangeSelectedOption={onChangePackageDetailsInput}
                                                        uniqueName='space'
                                                        index={idx}
                                                    />
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    <Input
                                                        placeholder=' '
                                                        showValue={true}
                                                        containerStyle='mt-1 max-w-[100px]'
                                                        inputUniqueName='nrOfItems'
                                                        inputValue={obj?.nrOfItems}
                                                        index={idx}
                                                        onChangeInput={onChangePackageDetailsInput}
                                                    />
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    {obj?.id &&
                                                        <PrintLabel
                                                            barcode={obj?.id}
                                                            orderId={quoteId}
                                                        />
                                                    }
                                                </td>
                                                <td className='px-6 py-2 align-middle w-[180px] max-w-[180px]'>
                                                    <div className='flex flex-row items-center cursor-pointer' onClick={() => obj?.id && navigator?.clipboard?.writeText(obj?.id)}>
                                                        {obj?.id &&
                                                            <div className='cursor-pointer'>
                                                                <img title={'Copy Quote ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[22px] min-h-[22px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                                            </div>
                                                        }
                                                        <p className='cut-text'>{obj?.id || ''}</p>
                                                    </div>
                                                </td>
                                                <td className='px-6 py-2 align-middle'>
                                                    <div className='cursor-pointer' onClick={() => onDeletePackage(obj?.id)}>
                                                        <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                                    </div>
                                                </td>
                                            </tr>
                                            {(packages && packages?.length > 0 && ((idx + 1) === packages?.length)) &&
                                                <tr className='py-4 px-2'>
                                                    <td colSpan={3} className='py-3'>
                                                        <Button
                                                            label={'Add Row'}
                                                            className='min-w-[120px] btn-primary'
                                                            onClickButton={onAddRow}
                                                        />
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })}
                            </Table >
                        </>
                    }
                </div>
                <div className='flex flex-col items-center py-2 bg-[#f8f9fc] border-b p-3'>
                    <div className='bg-[#ffffff] w-[80%] rounded-[6px] py-4 px-3 flex flex-row justify-center mb-2'>
                        <p>Expecting: <span className='font-bold'>{expectedItemsNumber}</span></p>
                        {(numberOfPackages && packages && packages?.length > 0) ? <p className='ml-4 text-[#00b67a]'>Received: <span className='font-bold'>{numberOfPackages}</span></p> : ''}
                        {(numberOfPackages && packages && packages?.length > 0) ? <p className='ml-4 text-[#e50000]'>Missing: <span className='font-bold'>{(expectedItemsNumber - numberOfPackages) >= 0 ? expectedItemsNumber - numberOfPackages : 0}</span></p> : ''}
                    </div>

                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <div className='my-5 flex flex-row items-center'>
                        <p className='mr-4 font-bold'>Download items:</p>
                        <Button
                            label={loadingDownloadCSV ? 'Loading...' : 'Download CSV'}
                            className='btn-main !rounded min-w-[150px]'
                            onClickButton={() => !loadingDownloadCSV && onDownloadCSVData()}
                        />
                        <CSVLink
                            ref={csvLink}
                            data={(csvData && csvData?.length > 0) ? csvData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                            headers={csvData?.[0] || []}
                            className={'none'}
                            target={'_blank'}
                            filename={`Purchase-${quoteId}-items-${moment().format('dd-mm-yyyy')}.csv`}
                        >
                        </CSVLink>
                    </div>
                    <div className='my-3'>
                        <p className='font-bold'>Import Items With Item ID</p>
                        <div className='flex flex-col justify-center rounded my-1'>
                            <div className='flex flex-row items-center mr-10' {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                    icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                    className={'btn-main min-w-[150px] !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                    label={'Upload file'}
                                    dataQa={'select-file'}
                                />
                            </div>
                            {fileSelected && <p className='mt-2 mb-5 font-bold'>{fileSelected?.name}</p>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center my-5'>
                    <Button
                        label='Submit'
                        dataQa={'continue-button'}
                        className={submitLoading || loading || !(packages?.length > 0) && !fileSelected ? 'btn-primary-disable min-w-[150px]' : 'btn-primary min-w-[150px]'}
                        onClickButton={() => !submitLoading && !loading && (packages?.length > 0) && fileSelected && onSubmit()}
                    />
                </div>
            </div>
        </Modal >
    )
}
export default BatchingCompleteModal;