import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import inventoryAxios from '../../../../utils/axios/inventory.axios';

const tryToFetchItemId = (imei: string): Promise<AxiosResponse> => {
    return inventoryAxios.get(`${INVENTORY_API_URL}/inventory/imei/${imei}`);
};

export const SearchItemIdService = {
    tryToFetchItemId,
};