import React, { useEffect, useState, useCallback } from 'react';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone'
import { useForm, useFieldArray } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditSaleFields } from '../../../../store/inventory/shared/sales/salesSlice';
import { ISingleSaleField } from '../../../../interfaces/sales/ISalesFields';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Switch from '../../../../shared/switch';
import Select from '../../../../shared/select';
import Button from '../../../../shared/button';


interface IFieldsModal {
    openFieldsModal: boolean;
    fieldItemId?: string | null;
    assetTemplateId: string;
    saleData?: ISingleSaleField[];
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    type: string;
    value?: { name: string }[];
    required?: boolean;
    handleCloseFieldsModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
};

const dataTypes = [
    { label: 'String', value: 'string' },
    { label: 'Number', value: 'number' },
    { label: 'List', value: 'list' },
    { label: 'Boolean', value: 'boolean' }
]

const FieldsModal = ({
    openFieldsModal,
    fieldItemId,
    assetTemplateId,
    saleData,
    handleCloseFieldsModal,
    onSaveChanges,
}: IFieldsModal) => {
    const dispatch = useAppDispatch();
    const [newValue, setNewValue] = useState<string>()
    const [listError, setListError] = useState<string>()
    const [requiredField, setRequiredField] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'value'
    })

    useEffect(() => {
        if (saleData) {
            const findField = saleData && saleData?.length > 0 && saleData?.find((field) => field?.id === fieldItemId)
            setValue('name', findField ? findField?.name || '' : '')
            setValue('type', findField ? findField?.type || '' : '')
            const formatValuesData = findField && findField?.value && findField?.value?.length > 0 && findField?.value?.map((v) => { return { name: v } })
            setValue('value', findField ? formatValuesData || [{ name: '' }] : [{ name: '' }])
            setValue('required', findField ? findField?.required || false : false)
            setRequiredField(findField ? findField?.required || false : false)
        }

    }, [saleData, fieldItemId]);

    const watchDataType = watch('type');
    const onSubmit = async (data: FormValues) => {
        if (watchDataType === 'list' && (!fields || fields?.length <= 0)) {
            setListError('Please write at least one list item.')
            return;
        }
        const formatData: any = {
            name: data?.name,
            type: data?.type
        }
        if (data?.value && data?.value?.length > 0 && watchDataType === 'list') {
            formatData.value = data?.value?.map((item) => item?.name)
        }
        const payload = {
            ...formatData,
            required: requiredField || false,
        };
        try {
            handleCloseFieldsModal()
            if (fieldItemId) {
                const filterFields = saleData && saleData?.length > 0 && saleData?.filter((field) => field?.id !== fieldItemId)
                const dataFormat = {
                    assetTemplateId: assetTemplateId,
                    fields: [
                        ...filterFields || [],
                        payload
                    ]
                }
                await dispatch(tryToEditSaleFields(dataFormat)).unwrap();
            } else {
                const dataFormat = {
                    assetTemplateId: assetTemplateId,
                    fields: [
                        ...saleData || [],
                        payload
                    ]
                }
                await dispatch(tryToEditSaleFields(dataFormat)).unwrap();
            }
            onSaveChanges('success', `Sale Field successfully ${fieldItemId ? 'changed' : 'added'}.`)
        } catch (error) {
            onSaveChanges('error', `${error}`)
        }
        setRequiredField(false)
        setListError(undefined)
        setFileSelected(undefined)
        reset();
    };

    const onChangeNewValue = (value: string | null | undefined, type: string) => {
        setNewValue(value || undefined)
        setListError(undefined)
    }

    const onAddNewValue = () => {
        if (newValue && newValue !== '') {
            const appendData = { name: newValue }
            append(appendData)
            setNewValue('')
        }
    }

    const onDeleteValue = (id: number) => {
        remove(id)
    }

    useEffect(() => {
        if (watchDataType !== 'list') {
            remove()
        }
    }, [watchDataType])

    const onChangeFieldRequired = () => {
        setRequiredField(!requiredField)
    }

    const onCloseModal = () => {
        setListError(undefined)
        setFileSelected(undefined)
        reset()
        setRequiredField(false)
        handleCloseFieldsModal()
    }

    const onGettingListValuesFromFile = () => {
        if (fileSelected) {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const key = results?.data?.[0] && Object.keys(results?.data?.[0])
                    const unique = results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any) => item?.[key?.[0]])
                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    if (unique && unique?.length > 0) {
                        unique?.map((item: any) => {
                            append({ name: item })
                            return item;
                        })
                    }
                }
            }
            )
        }
    }

    useEffect(() => {
        if (fileSelected) {
            onGettingListValuesFromFile()
        }
    }, [fileSelected])

    return (
        <Modal
            open={openFieldsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium'>Sale field for asset catalog selected.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col my-4'>
                        <Input
                            placeholder='Name'
                            register={register('name', {
                                required: {
                                    message: 'Name is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Name is required'
                            })}
                            error={errors.name?.message}
                        />
                        <div className={'my-3'}>
                            <Switch
                                onToggleSwitch={onChangeFieldRequired}
                                checked={requiredField}
                                beforeText={'Required Field'}
                            />
                        </div>
                        <Select
                            placeholder='Data Type'
                            register={register('type', {
                                required: {
                                    message: 'Data type is required',
                                    value: true,
                                },
                                validate: (value: string) => !!value.trim() || 'Data type is required'
                            })}
                            options={dataTypes}
                            error={errors?.type?.message}
                        />
                        {watchDataType === 'list' &&
                            <>
                                <div className='flex flex-row items-center'>
                                    <Input
                                        placeholder='List Value'
                                        containerStyle={'mt-0 flex flex-row h-full items-center'}
                                        showValue={true}
                                        inputValue={newValue}
                                        inputUniqueName={'newValue'}
                                        onChangeInput={onChangeNewValue}
                                    />
                                    <Button
                                        label='Add Value'
                                        className='btn-filters ml-2 mt-0'
                                        onClickButton={onAddNewValue}
                                    />
                                    <div className='flex flex-col items-center justify-center ml-2' {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button
                                            icon={<img src='/assets/shared/upload-file.svg' className='w-[20px] object-contains pr-2' />}
                                            className={'btn-primary-rounded !py-1 !shadow-none flex flex-row items-center'}
                                            label={'Select File'}
                                        />
                                    </div>
                                </div>
                                {listError && <Error text={listError} />}
                                <div className='flex flex-row items-center flex-wrap my-3'>
                                    {fields && fields?.length > 0 && fields?.map((item: any, idx: number) => {
                                        return (
                                            <div key={idx} className={'flex flex-row items-center justify-between border border-solid border-[#F7A21E] rounded-md px-1 mr-1 mb-1'}>
                                                <p className='text-slate-400'>{item?.name}</p>
                                                <div className='pl-1 flex flex-row items-center h-full' onClick={() => onDeleteValue(idx)}>
                                                    <img alt='close' src={'/assets/shared/close-grey.svg'} className={'w-[20px] mt-1 object-contain cursor-pointer'} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                type={'submit'}
                                className={'btn-primary'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
export default FieldsModal;