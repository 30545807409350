import React, { useEffect } from 'react'

interface ISuccessfullySavedToast {
    text?: string;
    onHandleCloseToast: () => void;
}

const SuccessfullySavedToast = ({
    text,
    onHandleCloseToast
}: ISuccessfullySavedToast) => {

    useEffect(() => {
        setInterval(
            onHandleCloseToast,
            6000
        );
    }, [])

    return (
        <div
            className={`${!text && 'hidden'} mx-2 sm:mx-auto w-[350px] fixed bottom-10 right-0 left-0 flex flex-row items-center justify-between bg-white shadow p-3 text-sm leading-none font-medium rounded-xl whitespace-no-wrap`}>
            <div className="inline-flex items-center text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd" />
                </svg>
                {text}
            </div>
            <div
                onClick={() => onHandleCloseToast()}
                className="text-green-700 cursor-pointer hover:text-green-800">
                <span
                    className="flex-shrink-0 inline-flex item-center justify-center border-l-2 border-t-2 border-green-700 p-1 leading-none rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd" />
                    </svg>
                </span>
            </div>
        </div>
    )
}

export default SuccessfullySavedToast;