import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToFetchInventoryAgreggatedDetails } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import { IInventory } from '../../../../interfaces/inventory-data/IInventory';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';


interface IExpandable {
    idx: any;
    expanded: boolean;
    colSpan: number;
    onClickId: (item: string) => void
    onClickSeeMore: () => void
    accessControl?: any;
    actions?: any;
    category: { id: string, name: string },
    isSplitColor: boolean
}

interface IPaginationInfo {
    hasNextPage: boolean; 
    number: number;       
    size: number;         
    totalElements: number; 
    totalPages: number;   
  }

const Expandable = ({
    idx,
    expanded,
    colSpan,
    onClickId,
    accessControl,
    actions,
    category,
    isSplitColor,
    onClickSeeMore
}: IExpandable) => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IInventory[]>([]);
    const [metaData, setMetaData] = useState<IPaginationInfo | undefined>()
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    const getDetails = async () => {
        try {
            let id = idx
            if (isSplitColor) {
                if (id?.color) {
                    id = { ...id, descriptor: { ...id.descriptor, color: idx.color } }
                    delete id.color
                }
            }
            const response = await dispatch(
                tryToFetchInventoryAgreggatedDetails({
                    pageNumber: 1,
                    pageSize: 10,
                    data: {
                        _id: JSON.stringify(id),
                        warehouse: activeWarehouseState?._id,
                        category,
                        splitColor: isSplitColor
                    },
                })
            ).unwrap();
            setData(response?.data?.elements);
            setMetaData(response?.data?.page);
        } catch (error) { }
    };

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        getDetails();
    }, [isSplitColor]);

    if (!expanded) return null;
    return (
        <tr className='w-full h-10 bg-black columns-4'>
            <td className='w-full h-full bg-white' colSpan={colSpan}>
                {data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td className='px-6 py-4'>{item?.itemName || '--'}</td>
                            <td className='px-6 py-4'>
                                <p className='font-bold text-[#8A8A8E]'>Item ID / IMEI:</p>
                                <p className='text-[#F7A21E] underline hover:opacity-95 cursor-pointer' onClick={() => (!accessControl || actions?.['See Item Details']) && onClickId(item?.itemCode || item?.itemId)}>{item?.itemCode || item?.itemId || '--'}</p>
                            </td>
                            <td className='px-6 py-4'>
                                <p className='font-bold text-[#8A8A8E]'>Location:</p>
                                {item?.section?.name && `${item?.section?.name} - `}
                                {(item?.locations &&
                                    Object?.keys(item?.locations)?.map(
                                        (loc: any, index: number) => {
                                            return `${index === 0
                                                ? `${item?.locations?.[loc]}`
                                                : ` - ${item?.locations?.[loc]}`
                                                }`;
                                        }
                                    )) ||
                                    '--'}
                            </td>
                            <td className='px-6 py-4 min-w-[150px]'>
                                <p className='font-bold text-[#8A8A8E]'>Status:</p>
                                {item?.orderStatus || '--'}
                            </td>
                            <td className='px-6 py-4'>
                                <p className='font-bold text-[#8A8A8E]'>Cost:</p>
                                {CURRENCY_SYMBOLS?.[`${(item?.currency || '').toLowerCase()}`]}
                                {(item?.totalPrice || 0).toFixed(2)}
                            </td>
                        </tr>
                    );
                })}
                <div>{(metaData && metaData?.totalElements > 10) && <div onClick={onClickSeeMore}><p className='px-6 py-4 text-[#F7A21E] cursor-pointer'>See more</p></div>}</div>
            </td>
        </tr>
    );
};
export default Expandable;