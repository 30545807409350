import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { SPACE_CHILD_TYPES } from '../../../../utils/constants/space-child-type';
import { IWarehouseSections } from '../../../../interfaces/warehouse-locations/IWarehouseSections';
import {
    tryToAddWarehouseSectionChild,
    tryToEditWarehouseSectionChild,
    tryToGetWarehouseSectionChild
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import SectionChildModalContainer from '../loading/SectionChildModalContainer';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IManageSectionChild {
    sectors?: any[];
    parents?: any[];
    openActionsModal: boolean;
    itemId: string;
    childId?: string;
    sectionDetails?: IWarehouseSections;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

const ManageSectionChild = ({
    openActionsModal,
    itemId,
    childId,
    sectors,
    parents,
    sectionDetails,
    handleCloseModal,
    onSaveChanges,
}: IManageSectionChild) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.warehouseLocations);
    const [formData, setFormData] = useState<any>();
    const [formDataErrors, setFormDataErrors] = useState<any>();
    const [selectedSector, setSelectedSector] = useState<any>();
    const [selectedParent, setSelectedParent] = useState<any>();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const childLoading = useAppSelector((state) => state.warehouseLocations.childIsLoading);

    useEffect(() => {
        if (childId && state.warehouseSectionChild) {
            const data: any = {
                name: state?.warehouseSectionChild?.name,
                capacity: state?.warehouseSectionChild?.capacity,
                customChildSpace: state?.warehouseSectionChild?.customChildSpace,
                minRange: state?.warehouseSectionChild?.minRange,
                maxRange: state?.warehouseSectionChild?.maxRange,
                type: state?.warehouseSectionChild?.type,
            }
            const findParent = state?.warehouseSectionChild?.parentId && parents && parents?.length > 0 && parents?.find((item: any) => item?.value === state?.warehouseSectionChild?.parentId)
            setSelectedParent(findParent || undefined)
            const formatCategories = (state?.warehouseSectionChild?.categories && state?.warehouseSectionChild?.categories?.length > 0) && state?.warehouseSectionChild?.categories?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
            setSelectedSector(formatCategories || undefined)
            setFormData(data)
        } else {
            setFormData(undefined)
            setSelectedSector(undefined)
            setSelectedParent(undefined)
        }
    }, [childId, state.warehouseSectionChild, parents, openActionsModal]);

    useEffect(() => {
        if (itemId && childId) {
            dispatch(tryToGetWarehouseSectionChild({ id: itemId || '', childId: childId || '' }));
        }
    }, [itemId, childId]);

    const onCloseModal = () => {
        setSelectedSector(undefined)
        setSaveError(undefined)
        handleCloseModal();
    };

    const onSubmit = async () => {
        if (!formData?.name) {
            setSaveError('Please write name to continue')
            return;
        }
        if (formData?.minRange && formData?.maxRange && (formData?.minRange > formData?.maxRange)) {
            setSaveError('Maximum Range cannot be lower than Minimum Range')
            return;
        }
        setLoadingSubmit(true)
        const payload: any = {
            ...formData,
            customChildSpace: selectedParent?.value ? false : formData?.customChildSpace,
            minRange: formData?.minRange || 1,
            maxRange: formData?.maxRange || 1,
            categories: (selectedSector && selectedSector?.length > 0) ? selectedSector?.map((item: any) => ({ id: item?.id })) : [],
            parentId: selectedParent?.value || undefined
        };
        try {
            if (childId) {
                const formatData = {
                    id: itemId,
                    childId: childId || '',
                    data: payload
                }
                await dispatch(tryToEditWarehouseSectionChild(formatData)).unwrap();
            } else {
                const formatData = {
                    id: itemId,
                    data: payload
                }
                await dispatch(tryToAddWarehouseSectionChild(formatData)).unwrap();
            }
            onSaveChanges(
                'success',
                `Section successfully ${childId ? 'changed' : 'added'}.`,
            );
            setFormData(undefined)
            setFormDataErrors(undefined)
            onCloseModal();
        } catch (error) {
            setSaveError(`${error}`);
        }
        setLoadingSubmit(false)
    };

    useEffect(() => {
        if (!selectedParent) {
            setFormData((prevFormData: any) => ({
                ...prevFormData || {},
                type: undefined,
                capacity: undefined
            }))
        }
    }, [selectedParent])

    const onChangeSelectedSector = (e: any) => {
        setSelectedSector(e);
    };

    const onChangeSelectedParent = (e: any) => {
        setSelectedParent(e);
        setFormData((prevFormData: any) => ({
            ...prevFormData || {},
            customChildSpace: false
        }))
    };

    const onChangeFormDataInput = (value: any, type?: string) => {
        if (type) {
            setFormData((prevFormData: any) => ({
                ...prevFormData || {},
                [type]: ['capacity', 'minRange', 'maxRange']?.includes(type) ? value ? Number(value) : value : value
            }))
        }
    }

    const onChangeSelectedItem = (value: any, type?: string) => {
        if (type) {
            setFormData((prevFormData: any) => ({
                ...prevFormData || {},
                [type]: value?.value,
                capacity: type === 'type' ? value?.value === 'SINGLE' ? 1 : undefined : prevFormData?.capacity
            }))
        }
    }

    const onClickCustomSectionChild = () => {
        setFormData((prevFormData: any) => ({
            ...prevFormData || {},
            customChildSpace: !prevFormData?.customChildSpace
        }))
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[400px] max-w-[450px] mx-10'}>
                <p className='mb-4 font-bold text-xl'>Manage Section</p>
                {saveError && <Error text={saveError} />}
                {((childId && !childLoading) || !childId) ? (
                    <div className='flex flex-col my-4'>
                        <Input
                            label='Name'
                            disabled={!!childId}
                            type='text'
                            containerStyle='max-w-[90%]'
                            showValue={true}
                            inputValue={formData?.name}
                            onChangeInput={onChangeFormDataInput}
                            inputUniqueName='name'
                            error={formDataErrors?.name}
                        />
                        <SelectCheckbox
                            name='Categories'
                            placeholder=' '
                            containerStyle='mb-1 max-w-[90%]'
                            multiple={true}
                            selectedOption={selectedSector}
                            onChangeSelectedOption={onChangeSelectedSector}
                            options={sectors || []}
                        />
                        <div className='flex flex-row justify-between max-w-[90%] items-cener'>
                            <Input
                                label='Minimum Name Range Number'
                                disabled={sectionDetails?.generatedLocations}
                                type='text'
                                containerStyle='max-w-[44%]'
                                showValue={true}
                                inputValue={formData?.minRange}
                                onChangeInput={onChangeFormDataInput}
                                inputUniqueName='minRange'
                                error={formDataErrors?.minRange}
                            />
                            <Input
                                label='Maximum Name Range Number'
                                disabled={sectionDetails?.generatedLocations}
                                type='text'
                                containerStyle='max-w-[44%]'
                                showValue={true}
                                inputValue={formData?.maxRange}
                                onChangeInput={onChangeFormDataInput}
                                inputUniqueName='maxRange'
                                error={formDataErrors?.maxRange}
                            />
                        </div>
                        <div className='flex flex-row justify-start items-center my-6 min-w-[100%]'>
                            <div className='flex items-center mr-1'>
                                <input
                                    onChange={() => !selectedParent && onClickCustomSectionChild()}
                                    checked={formData?.customChildSpace}
                                    id='checkbox-all-1'
                                    data-qa={'checkbox'}
                                    type='checkbox'
                                    className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-green-600 rounded focus:accent-green-600 focus:ring-2'
                                />
                                <label htmlFor='checkbox-all-1' className='sr-only'>
                                </label>
                            </div>
                            <p className='text-[#a4a4a4]'>
                                Custom Section Child
                            </p>
                        </div>
                        <SelectCheckbox
                            name='Parent'
                            disabled={sectionDetails?.generatedLocations}
                            placeholder=' '
                            containerStyle='mb-1 max-w-[90%]'
                            selectedOption={selectedParent}
                            onChangeSelectedOption={onChangeSelectedParent}
                            options={parents ? parents?.filter((item: any) => item?.value !== childId) : []}
                        />
                        <>
                            <SelectCheckbox
                                name='Space Child Type'
                                placeholder=' '
                                disabled={sectionDetails?.generatedLocations}
                                containerStyle='mb-1 max-w-[90%]'
                                uniqueName='type'
                                selectedOption={formData?.type ? SPACE_CHILD_TYPES?.find((item: any) => item?.value === formData?.type) : undefined}
                                onChangeSelectedOption={onChangeSelectedItem}
                                options={SPACE_CHILD_TYPES || []}
                            />
                            {formData?.type && formData?.type === 'MULTIPLE' &&
                                <Input
                                    label='Capacity'
                                    disabled={sectionDetails?.generatedLocations}
                                    type='text'
                                    containerStyle='max-w-[90%]'
                                    showValue={true}
                                    inputValue={formData?.capacity}
                                    onChangeInput={onChangeFormDataInput}
                                    inputUniqueName='capacity'
                                    error={formDataErrors?.capacity}
                                />
                            }
                        </>
                        <div className='flex flex-row justify-end mt-4'>
                            <Button
                                label='Save'
                                onClickButton={() => !loadingSubmit && onSubmit()}
                                className={loadingSubmit ? 'btn-primary-disable' : 'btn-primary'}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='my-3'>
                        <SectionChildModalContainer />
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default ManageSectionChild;
