import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISales } from '../../../interfaces/sales/ISales';
import { tryToFetchSingleSaleForm } from '../../../store/inventory/shared/sales/salesSlice';
import SaleDetailsTable from './tables/SaleDetailsTable';


const SaleDetails = () => {
    const { id } = useParams();
    const navigator = useNavigate()
    const dispatch = useAppDispatch();
    const [saleDetails, setSaleDetails] = useState<ISales>()
    const state = useAppSelector((state) => state.sales);
    const rowsLoading = useAppSelector((state) => state.sales?.saleIsLoading);

    useEffect(() => {
        setSaleDetails(state.sale || undefined)
    }, [state?.sale]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchSingleSaleForm(id)).unwrap()
        }
    }, [id])

    const onGoBack = () => {
        navigator('/sales-review-admin')
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-5'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Sale Details</p>
                </div>
            </div>
            <div>
                <SaleDetailsTable
                    rowsLoading={rowsLoading}
                    rows={saleDetails?.saleItems || []}
                />
            </div>
        </div>
    );
};

export default SaleDetails;