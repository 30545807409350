import React from 'react';
import Modal from '../../../../shared/modal';


interface IMoreDetailsModal {
    openModal: boolean;
    categories?: any;
    items?: any;
    handleCloseModal: () => void;
}

const MoreDetailsModal = ({
    openModal,
    categories,
    items,
    handleCloseModal
}: IMoreDetailsModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            showInRight
            onClose={onCloseModal}
            contentContainerStyle={'min-w-[650px]'}>
            <div className={`p-4 min-w-[600px]`}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Category Details</p>
                </div>
                <div>
                    <div className='bg-[#f8f9fc] rounded p-3 mb-2'>
                        <div className={'mt-2 overflow-hidden transition-max-h ease-out duration-300 max-h-auto'}>
                            {categories?.length > 0 && categories?.map((key: any, index: number) => {
                                const itemsPerCategory = (items && items?.length > 0) && items?.filter((item: any) => item?.categoryId === key?.id)
                                const calculateTotal = (itemsPerCategory && itemsPerCategory?.length > 0) && itemsPerCategory?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                                return (
                                    <div key={index} className='mb-2 px-3 py-2 bg-[#f8f9fc] rounded'>
                                        <div className='bg-white p-2'>
                                            <p>Category: {key?.code}</p>
                                            <p>Number Of Items: {calculateTotal || 0}</p>
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default MoreDetailsModal;