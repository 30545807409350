import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { WingplusOrdersService } from './wingplusOrdersApi';
import { IWingplusOrdersListState } from '../../../../interfaces/wingplus-orders/IWingplusOrdersList';


const initialState: IWingplusOrdersListState = {
    wingplusOrderIsLoading: false,
    wingplusOrdersAreLoading: false,
    wingplusStatusesLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchWingplusOrders = createAsyncThunk<any, IPaginationPayload>(
    'wingplusOrders/tryToFetchWingplusOrders',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await WingplusOrdersService.tryToFetchWingplusOrders(pageNumber, pageSize, filters, data);
        return result.data;
    }
);

export const tryToFetchWingplusOrder = createAsyncThunk<any, string>(
    'wingplusOrders/tryToFetchWingplusOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const result = await WingplusOrdersService.tryToFetchWingplusOrder(orderId);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAddImeiInOrder = createAsyncThunk<any, { orderId: string, data: { imei: string } }>(
    'wingplusOrders/tryToAddImeiInOrder',
    async ({ orderId, data }, { rejectWithValue }) => {
        try {
            const result = await WingplusOrdersService.tryToAddImeiInOrder(orderId, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToChangeOrderToCompleted = createAsyncThunk<any, { orderId: string }>(
    'wingplusOrders/tryToChangeOrderToCompleted',
    async ({ orderId }, { rejectWithValue }) => {
        try {
            const result = await WingplusOrdersService.tryToChangeOrderToCompleted(orderId);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const wingplusOrdersSlice = createSlice({
    name: 'wingplusOrders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchWingplusOrders
            .addCase(tryToFetchWingplusOrders.pending, (state) => {
                state.wingplusOrdersAreLoading = true;
            })
            .addCase(tryToFetchWingplusOrders.rejected, (state) => {
                state.wingplusOrdersAreLoading = false;
                state.wingplusOrders = undefined
            })
            .addCase(tryToFetchWingplusOrders.fulfilled, (state, action) => {
                state.wingplusOrdersAreLoading = false;
                state.wingplusOrders = action.payload;
            })

            // tryToFetchWingplusOrder
            .addCase(tryToFetchWingplusOrder.pending, (state) => {
                state.wingplusOrderIsLoading = true;
            })
            .addCase(tryToFetchWingplusOrder.rejected, (state) => {
                state.wingplusOrderIsLoading = false;
                state.wingplusOrder = undefined;
            })
            .addCase(tryToFetchWingplusOrder.fulfilled, (state, action) => {
                state.wingplusOrderIsLoading = false;
                state.wingplusOrder = action.payload;
            })

            // tryToAddImeiInOrder
            .addCase(tryToAddImeiInOrder.pending, (state) => {
                state.wingplusStatusesLoading = true;
            })
            .addCase(tryToAddImeiInOrder.rejected, (state) => {
                state.wingplusStatusesLoading = false;
            })
            .addCase(tryToAddImeiInOrder.fulfilled, (state, action) => {
                state.wingplusStatusesLoading = false;
                if (state.wingplusOrders?.data.elements)
                    state.wingplusOrders.data.elements =
                        { ...state }.wingplusOrders?.data.elements.map((item) => {
                            if (item?._id === action.meta?.arg?.orderId) {
                                return {
                                    ...item,
                                    status: 'in-review',
                                    tradeInItem: {
                                        ...item?.tradeInItem || {},
                                        imei: action?.meta?.arg?.data?.imei
                                    }
                                }
                            } else {
                                return item
                            }
                        }) || [];
                if (state.wingplusOrder) {
                    if(state.wingplusOrder.tradeInItem)
                        state.wingplusOrder.tradeInItem.imei = action?.meta?.arg?.data?.imei
                    state.wingplusOrder.status = 'in-review'
                }
            })

            // tryToChangeOrderToCompleted
            .addCase(tryToChangeOrderToCompleted.pending, (state) => {
                state.wingplusStatusesLoading = true;
            })
            .addCase(tryToChangeOrderToCompleted.rejected, (state) => {
                state.wingplusStatusesLoading = false;
            })
            .addCase(tryToChangeOrderToCompleted.fulfilled, (state, action) => {
                state.wingplusStatusesLoading = false;
                if (state.wingplusOrders?.data.elements)
                    state.wingplusOrders.data.elements =
                        { ...state }.wingplusOrders?.data.elements.map((item) => {
                            if (item?._id === action.meta?.arg?.orderId) {
                                return {
                                    ...item,
                                    status: 'completed'
                                }
                            } else {
                                return item
                            }
                        }) || [];
                if (state.wingplusOrder)
                    state.wingplusOrder = {
                        ...state.wingplusOrder || {},
                        status: 'completed'
                    }
            })
    },
});

export default wingplusOrdersSlice.reducer;