import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import {
    tryToDeleteWarehouseSectionChild,
    tryToFetchSectionChilds,
    tryToFetchSingleWarehouseSection
} from '../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import GenerateLocationsModal from './modals/GenerateLocationsModal';
import SectionChildsFilter from './details/SectionChildsFilter';
import ManageSectionChild from './modals/ManageSectionChild';
import Toast from '../../../shared/toast';
import DeleteModal from '../../../shared/delete-modal';
import SectionChildsTable from './tables/SectionChildsTable';
import SearchWithButton from '../../../shared/search-with-button';


const SectionDetails = () => {
    const { id } = useParams();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [search, setSearch] = useState<string>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [parents, setParents] = useState<any>();
    const [selectedParents, setSelectedParents] = useState<any>();
    const [selectedChildId, setSelectedChildId] = useState<string>();
    const [showChildModal, setShowChildModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const state = useAppSelector((state) => state.warehouseLocations);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const rowsLoading = useAppSelector((state) => state?.warehouseLocations?.childsAreLoading);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Locations Settings')
        const findChild = (findPage && findPage?.children && findPage?.children?.length > 0) && findPage?.children?.find((item: any) => item?.name === 'Section Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onGettingSectionDetails = async () => {
        if (id) {
            try {
                await dispatch(tryToFetchSingleWarehouseSection(id || '')).unwrap();
            } catch (err) {
                // error here
            }
        }
    }

    const onGettingSectionChilds = async () => {
        if (id) {
            try {
                let data: any = {};
                if (selectedCategory && selectedCategory?.length > 0) {
                    data = {
                        ...(data || {}),
                        categories: selectedCategory?.map((item: any) => item?.id),
                    };
                }
                if (selectedParents) {
                    data = {
                        ...(data || {}),
                        parentId: selectedParents?.value,
                    };
                }
                if (search) {
                    data = {
                        ...(data || {}),
                        search: search,
                    };
                }
                await dispatch(tryToFetchSectionChilds({ id: id || '', data: data })).unwrap();
            } catch (err) {
                // err here
            }
        }
    }

    useEffect(() => {
        if (state.warehouseSectionChilds) {
            const rows = state.warehouseSectionChilds || [];
            setRows(rows);
        }
    }, [state.warehouseSectionChilds]);

    useEffect(() => {
        if (state.warehouseSectionDetails) {
            const formatCategories = (state?.warehouseSectionDetails?.categories && state?.warehouseSectionDetails?.categories?.length > 0) && state?.warehouseSectionDetails?.categories?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
            setBrokerCategories(formatCategories || [])
            const formatParents = (state.warehouseSectionDetails?.childs && state.warehouseSectionDetails?.childs?.length > 0) && state.warehouseSectionDetails?.childs?.map((item: any) => ({ ...item, label: item?.name, value: item?.id }))
            setParents(formatParents || [])
        }
    }, [state.warehouseSectionDetails]);

    useEffect(() => {
        onGettingSectionDetails()
    }, [id])

    useEffect(() => {
        onGettingSectionChilds()
    }, [id, selectedCategory, selectedParents, search])

    const onGoBack = () => {
        navigation(-1)
    }

    const onChangeSelectedFilter = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'category':
                    setSelectedCategory(e);
                    break;
                case 'parent':
                    setSelectedParents(e);
                    break;
            }
        }
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
    };

    const onClearAllFilters = () => {
        setSelectedCategory(undefined)
        setSearch(undefined)
        setSelectedParents(undefined)
    }

    const onToggleManageChildModal = (id?: string) => {
        if (id) {
            setSelectedChildId(id)
        }
        setShowChildModal(true)
    }

    const onRequestDeleteChild = (id: string) => {
        setSelectedChildId(id)
        setShowDeleteModal(true)
    }

    const onCloseChildModal = () => {
        setShowDeleteModal(false)
        setShowChildModal(false)
        setSelectedChildId(undefined)
    }

    const onSaveChangesChild = (type: string, message: string) => {
        setShowToast({ type, message })
        onClearAllFilters()
    }

    const onDeleteChild = async () => {
        if (selectedChildId) {
            try {
                await dispatch(tryToDeleteWarehouseSectionChild({ id: id || '', childId: selectedChildId })).unwrap()
                setShowToast({ type: 'success', message: 'Section child deleted successfully' })
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` })
            }
            onCloseChildModal()
        }
    }

    const onClickGenerateLocations = () => {
        setShowGenerateModal(!showGenerateModal)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Section Details</p>
                </div>
            </div>
            {state?.warehouseSectionDetails?.name && <p className='mt-2 font-medium text-primary-light'>Section Name: {state?.warehouseSectionDetails?.name}</p>}
            {state?.warehouseSectionDetails?.barcode && <p className='mt-2 font-medium text-primary-light'>Section Barcode: {state?.warehouseSectionDetails?.barcode}</p>}
            <div className='my-4'>
                <SectionChildsFilter
                    parents={parents || []}
                    selectedParent={selectedParents}
                    sectors={brokerCategories || []}
                    selectedSector={selectedCategory}
                    onSelectFilter={onChangeSelectedFilter}
                />
            </div>
            <div className='my-4'>
                <SearchWithButton
                    placeholder='Filter table'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search}
                    showScndBtn={true}
                    showThirdBtn={true}
                    onClickThirdButton={onClearAllFilters}
                    onClickSecondButton={() => !state.warehouseSectionDetails?.generatedLocations && (!accessControl || pageAccess?.actions?.['Add New Child']) && onToggleManageChildModal()}
                    onClickButton={() => !state.warehouseSectionDetails?.generatedLocations && (!accessControl || pageAccess?.actions?.['Generate Locations']) && onClickGenerateLocations()}
                    thirdButtonLabel='Clear All Filters'
                    mainContainerStyle={'!grid-cols-10'}
                    scndButtonLabel='Add New Child'
                    buttonLabel='Generate Locations'
                    thirdButtonStyle='btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'
                    buttonStyle={`${state.warehouseSectionDetails?.generatedLocations ? 'btn-primary-disable' : 'btn-primary'} col-span-2 !ml-1`}
                    scndButtonStyle={`${state.warehouseSectionDetails?.generatedLocations ? 'btn-primary-disable' : 'btn-primary'} col-span-2`}
                />
                <SectionChildsTable
                    rows={rows}
                    parents={parents || []}
                    rowsLoading={rowsLoading}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onEditChild={onToggleManageChildModal}
                    onDeleteChild={onRequestDeleteChild}
                />
            </div>
            {showChildModal &&
                <ManageSectionChild
                    sectors={brokerCategories || []}
                    parents={parents || []}
                    itemId={id || ''}
                    sectionDetails={state?.warehouseSectionDetails}
                    childId={selectedChildId}
                    openActionsModal={showChildModal}
                    handleCloseModal={onCloseChildModal}
                    onSaveChanges={onSaveChangesChild}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showDeleteModal &&
                <DeleteModal
                    openDeleteModal={showDeleteModal}
                    handleCloseDeleteModal={onCloseChildModal}
                    onApproveDeletion={onDeleteChild}
                />
            }
            {showGenerateModal &&
                <GenerateLocationsModal
                    openModal={showGenerateModal}
                    sectionId={id || ''}
                    handleCloseModal={onClickGenerateLocations}
                />
            }
        </div>
    )
}

export default SectionDetails;