import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import {
    tryToFetchAllWarehouseSections,
    tryToFetchSectionLocations
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import {
    tryToChangeInventoryItemsLocation,
    tryToFetchItemDetails
} from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import ItemsLocationTable from '../tables/ItemsLocationTable';
import WhiteContainer from '../../../../shared/white-container';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Toast from '../../../../shared/toast';


const ItemLocation = () => {
    const dispatch = useAppDispatch();
    const [sections, setSections] = useState<any>();
    const [listItems, setListItems] = useState<any>();
    const [itemsError, setItemsError] = useState<any>();
    const [searchInDB, setSearchInDB] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [searchField, setSearchField] = useState<any | null>(null);
    const [filteredLocations, setFilteredLocations] = useState<any>();
    const [scannedLocation, setScannedLocation] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [currentLocations, setCurrentLocations] = useState<any>();
    const [responseLocations, setResponseLocations] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [findItemErrors, setFindItemErrors] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [itemsDetails, setItemsDetails] = useState<any>();
    const [findBarcodeLocationsResponse, setFindBarcodeLocationsResponse] = useState<any>();
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            setSelectedSection(undefined)
        }
    }, [activeWarehouseState])

    const onClickClearLocationAndSection = () => {
        setCurrentLocations(undefined)
        setSelectedSection(undefined)
        setScannedLocation(undefined)
        setItemsDetails(undefined)
        setSearchField(undefined)
        setSearchInDB(undefined)
        setFilteredLocations(undefined)
        setItemsError(undefined)
        setListItems(undefined)
        setFindItemErrors(undefined)
        setResponseLocations(undefined)
    }

    const onResponseNotFound = () => {
        setCurrentLocations(undefined)
        setItemsDetails(undefined)
        setSearchField(undefined)
        setSearchInDB(undefined)
        setFilteredLocations(undefined)
        setItemsError(undefined)
        setListItems(undefined)
        setFindItemErrors(undefined)
        setResponseLocations(undefined)
    }

    const onFindIfThereIsSpaceInSelectedLocation = async () => {
        if (selectedSection || scannedLocation) {
            try {
                const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.barcode || scannedLocation }))?.unwrap()
                if (response && response?.length > 0) {
                    const formatResponse = response && response?.length > 0 && response?.map((item: any) => {
                        let formatLabel: any
                        item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                            formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                        })
                        return {
                            ...item,
                            value: item?._id,
                            label: formatLabel,
                            name: formatLabel,
                            fullName: item?.fullName
                        }
                    })
                    setResponseLocations(formatResponse || undefined)
                    setFindBarcodeLocationsResponse(undefined)
                } else if (response && response?.length === 0) {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('No available capacity in this location!')
                } else {
                    onResponseNotFound()
                    setFindBarcodeLocationsResponse('Not found any location for the Barcode!')
                }
            } catch (err) {
                setFindBarcodeLocationsResponse(`${err}`)
            }
        }
    }

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: activeWarehouse?._id || '', type: 'space' })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (activeWarehouse?._id) {
            onGettingWarehouseSections()
        }
    }, [activeWarehouse])

    const onGettingItemCurrentLocation = async (itemId: string, categories?: any) => {
        if (itemId) {
            try {
                const response: any = await dispatch(tryToFetchItemDetails(itemId)).unwrap()
                if (response) {
                    setItemsDetails((prevItemsDetails: any) => ({
                        ...prevItemsDetails || {},
                        [itemId]: response
                    }))

                    let formatLabel: any
                    response?.locations && (Object?.keys(response?.locations))?.forEach((obj: any, idx: number) => {
                        formatLabel = idx === 0 ? response?.locations?.[obj] : formatLabel + ` - ${response?.locations?.[obj]}`
                    })
                    setCurrentLocations((prevLocations: any) => ([
                        ...prevLocations?.filter((item: any) => item?.itemId !== itemId) || [],
                        {
                            itemId: itemId,
                            location: formatLabel || 'No Location'
                        }
                    ]))
                    if (categories && categories?.length > 0 && response?.category) {
                        const foundCategory = categories?.find((item: any) => item?.code === response?.category)
                        if (!foundCategory) {
                            setFindItemErrors((prevItemErrors: any) => ({
                                ...prevItemErrors || {},
                                [itemId]: 'Item Category is not accepted in this category'
                            }))
                        }
                    } else {
                        setFindItemErrors((prevItemErrors: any) => ({
                            ...prevItemErrors || {},
                            [itemId]: true
                        }))
                    }
                }
            } catch (err) {
                setFindItemErrors((prevItemErrors: any) => ({
                    ...prevItemErrors || {},
                    [itemId]: 'Item doesn\'t exist'
                }))
            }
        }
    }

    const onGettingResponseOfItemCurrentLocation = async (itemId: string) => {
        if (itemId) {
            try {
                const response: any = await dispatch(tryToFetchItemDetails(itemId)).unwrap()
                if (response) {
                    const findLocationDetailsInResponse = (listItems && listItems?.length > 0) && listItems?.find((item: any) => item?.itemId === itemId)?.location
                    if (findLocationDetailsInResponse) {
                        const keys = findLocationDetailsInResponse?.descriptor && Object.keys(findLocationDetailsInResponse?.descriptor);
                        let value: any = null;
                        for (let i = keys.length - 1; i >= 0; i--) {
                            const key = keys[i];
                            const value1 = findLocationDetailsInResponse?.descriptor?.[key];
                            const value2 = findLocationDetailsInResponse?.categories?.[key] && findLocationDetailsInResponse?.categories?.[key]?.length > 0 ? findLocationDetailsInResponse?.categories?.[key] : null;
                            if (value2) {
                                value = value2 || value1;
                                break;
                            }
                        }
                        const foundCategory = (value && value?.length > 0) && value?.find((item: any) => item?.code === response?.category)
                        if (!foundCategory) {
                            return 'Item Category is not accepted in this category'
                        }
                    } else {
                        return true
                    }
                }
            } catch (err) {
                return 'Item doesn\'t exist'
            }
        }
    }

    const onChangedScannedLocation = (e: any, type?: string) => {
        setScannedLocation(e)
        setSelectedSection(undefined)
        setResponseLocations(undefined)
    }

    const onEnterAction = async (e: any, type?: string) => {
        e.stopPropagation()
        if (e?.key === 'Enter') {
            if (scannedLocation) {
                onFindIfThereIsSpaceInSelectedLocation()
            }
        }
    }

    useEffect(() => {
        if (selectedSection) {
            onFindIfThereIsSpaceInSelectedLocation()
        }
    }, [selectedSection])

    const onChangeSelectedSection = (e: any, type?: string) => {
        setSelectedSection(e)
        setScannedLocation(undefined)
        setResponseLocations(undefined)
    }

    const onAddNewRow = () => {
        const checkIfAnyEmptyRows = (listItems && listItems?.length > 0) && listItems?.filter((item: any) => (!item || !(Object?.keys(item)?.length > 0) || !(item?.itemId || item?.location)))
        if (!(listItems && listItems?.length > 0) || (!checkIfAnyEmptyRows || checkIfAnyEmptyRows?.length <= 0)) {
            setListItems((prevItems: any) => (
                [
                    ...prevItems || [],
                    {}
                ]
            ))
        }
    }

    const onChangeRowItemId = (e: any, type?: string, index?: any) => {
        if ((index || index === 0) && type) {
            setListItems((prevItems: any) => prevItems ? prevItems?.map((obj: any, idx: number) =>
                idx === index ? { ...obj, [type]: e } : obj
            ) : [{ [type]: e }])
            setFindBarcodeLocationsResponse(undefined)
        }
    }

    const onChangeRowSelectedLocation = (e: any, type?: string, index?: any) => {
        if ((index || index === 0) && type) {
            const findIfItemIdExists = listItems && listItems?.length > 0 && listItems?.find((item: any, idx: number) => (idx === index && item?.itemId))
            if (findIfItemIdExists) {
                setListItems((prevItems: any) => prevItems ? prevItems?.map((obj: any, idx: number) =>
                    idx === index ? { ...obj, [type]: e } : obj
                ) : [{ [type]: e }])
                if (searchField) {
                    setSearchField(undefined)
                }
                const keys = e?.descriptor && Object.keys(e?.descriptor);
                let value: any = null;
                for (let i = keys.length - 1; i >= 0; i--) {
                    const key = keys[i];
                    const value1 = e?.descriptor?.[key];
                    const value2 = e?.categories?.[key] && e?.categories?.[key]?.length > 0 ? e?.categories?.[key] : null;
                    if (value2) {
                        value = value2 || value1;
                        break;
                    }
                }
                if (!findItemErrors?.[findIfItemIdExists?.itemId]) {
                    if (e?.customSpace || (!e?.categories || Object?.keys(e?.categories)?.length <= 0)) {
                        onGettingItemCurrentLocation(findIfItemIdExists?.itemId)
                    } else {
                        onGettingItemCurrentLocation(findIfItemIdExists?.itemId, value)
                    }
                } else if (findIfItemIdExists?.itemId) {
                    const findItemDetails = itemsDetails?.[findIfItemIdExists?.itemId]
                    const foundCategory = (value && value?.length > 0) && value?.find((item: any) => item?.code === findItemDetails?.category)
                    if (!foundCategory) {
                        setFindItemErrors((prevItemErrors: any) => ({
                            ...prevItemErrors || {},
                            [findIfItemIdExists?.itemId]: 'Item Category is not accepted in this category'
                        }))
                    }
                }
                setFindBarcodeLocationsResponse(undefined)
            } else {
                setFindBarcodeLocationsResponse('Your not allowed to select Location without Item ID / IMEI')
            }
        }
    }

    const onGettingFilteredLocations = async () => {
        try {
            const formatSearch = searchField ? searchField?.split?.(' ').join('')?.replace('-', '') : searchField
            const response: any = await dispatch(tryToFetchSectionLocations({ barcode: selectedSection?.value || scannedLocation, data: { search: formatSearch } }))?.unwrap()
            const formatResponse: any = (response && response?.length > 0) && response?.map((item: any) => {
                let formatLabel: any
                item?.descriptor && (Object?.keys(item?.descriptor))?.forEach((obj: any, idx: number) => {
                    formatLabel = idx === 0 ? item?.descriptor?.[obj] : formatLabel + ` - ${item?.descriptor?.[obj]}`
                })
                return {
                    ...item,
                    value: item?._id,
                    label: formatLabel,
                    name: formatLabel,
                    fullName: item?.fullName
                }
            })
            setFilteredLocations(formatResponse || [])
        } catch (err) {
            // error here
        }
        setLoadingOptions(false)
        setSearchField(null)
    }

    const onChangeSearchLocationFields = (e: any, type?: string) => {
        setSearchInDB(e)
        if (e) {
            if (responseLocations) {
                const formatFilteredFields = (responseLocations && responseLocations?.length > 0) && responseLocations?.filter((next: any) => (next?.fullName || next?.name || '').toLowerCase().includes((e || '')?.toLowerCase()))
                if (formatFilteredFields && formatFilteredFields?.length > 0) {
                    setSearchInDB(e)
                    setLoadingOptions(false)
                    setFilteredLocations(formatFilteredFields)
                }
                else {
                    setFilteredLocations(undefined)
                    setLoadingOptions(true)
                    setSearchField(e)
                }
            } else {
                setSearchField(e)
            }
        } else {
            setFilteredLocations(undefined)
        }
    }

    const onMenuCloseAfterSearch = () => {
        setSearchField(undefined)
    }

    const onClickSearchInDb = () => {
        if (searchInDB) {
            setFilteredLocations(undefined)
            setLoadingOptions(true)
            setSearchField(searchInDB)
        }
    }

    useEffect(() => {
        if (searchField !== null) {
            onGettingFilteredLocations()
        }
    }, [searchField])

    const onEnterRowItemId = async (e: any, type?: string, index?: any) => {
        e.stopPropagation()
        if ((index || index === 0)) {
            if (e?.key === 'Enter') {
                const findItemId: any = listItems && listItems?.length > 0 && listItems?.find((item: any, idx: number) => idx === index)
                const findIfCopyItemId: any = findItemId && (listItems && listItems?.length > 0) && listItems?.find((item: any, idx: number) => idx !== index && item?.itemId === findItemId?.itemId)
                if (findItemId && findItemId?.itemId) {
                    if (!findIfCopyItemId) {
                        onGettingItemCurrentLocation(findItemId?.itemId)
                    }
                    else {
                        setItemsError((prevItemsError: any) => ({
                            ...prevItemsError || {},
                            itemIds: {
                                ...prevItemsError?.itemIds || {},
                                [index]: 'Two same Items are not allowed!'
                            }
                        }))
                    }
                }
            }
        }
    }

    const onRemoveRowListItem = (index: number) => {
        setListItems((prevItems: any) => prevItems?.filter((obj: any, idx: number) => idx !== index))
    }

    const onSubmit = async () => {
        setSubmitLoading(true)
        try {
            const listItemsFormat = (listItems && listItems?.length > 0) && listItems?.filter((item: any) => (Object.keys(item))?.length > 0)
            const formatPayload = listItemsFormat && listItemsFormat?.length > 0 && listItemsFormat?.map((item: any) => {
                return {
                    ...item,
                    location: item?.location?.barcode
                }
            })
            await dispatch(tryToChangeInventoryItemsLocation(formatPayload)).unwrap()
            setShowToast({ type: 'success', message: 'Items Location successfully changed' });
            onClickClearLocationAndSection()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setSubmitLoading(false)
    }

    const onValidateItems = async () => {
        setSubmitLoading(true)
        let errors: any
        const listItemsFormat = (listItems && listItems?.length > 0) && listItems?.filter((item: any) => (Object.keys(item))?.length > 0)
        if (!listItemsFormat || listItemsFormat?.length <= 0) {
            setSubmitError(`Please add at least one row to continue`);
            setSubmitLoading(false);
            return;
        }
        setSubmitError(undefined)
        if (listItems && listItems?.length > 0) {
            await Promise.all(listItems?.map(async (item: any, index: number) => {
                if (item?.itemId || item?.location) {
                    if (item?.itemId) {
                        const matchingIndex = listItems?.findIndex((otherObj: any, otherIndex: number) => otherIndex !== index && Object.is(item?.itemId, otherObj?.itemId));
                        if (matchingIndex !== -1) {
                            errors = {
                                ...errors || {},
                                itemIds: {
                                    ...errors?.itemIds || {},
                                    [index]: 'No duplicates allowed!'
                                }
                            };
                        }
                        else {
                            if (findItemErrors?.[item?.itemId] && findItemErrors?.[item?.itemId] !== true) {
                                errors = {
                                    ...errors || {},
                                    itemIds: {
                                        ...errors?.itemIds || {},
                                        [index]: findItemErrors?.[item?.itemId]
                                    }
                                }
                            } else if (!findItemErrors?.[item?.itemId] && findItemErrors?.[item?.itemId] !== true) {
                                const getItem: any = await onGettingResponseOfItemCurrentLocation(item?.itemId)
                                if (getItem && getItem !== true) {
                                    errors = {
                                        ...errors || {},
                                        itemIds: {
                                            ...errors?.itemIds || {},
                                            [index]: getItem
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else if (!item?.itemId) {
                        errors = {
                            ...errors || {},
                            itemIds: {
                                ...errors?.itemIds || {},
                                [index]: 'Item ID / IMEI is required'
                            }
                        }
                    }
                    if (!item?.location) {
                        errors = {
                            ...errors || {},
                            locations: {
                                ...errors?.locations || {},
                                [index]: 'Location is required'
                            }
                        }
                    }
                }
                return item
            }))
        }
        if (errors && Object?.keys(errors)?.length > 0) {
            setItemsError(errors)
            setSubmitError('Please fix errors first to continue')
            setSubmitLoading(false)
        } else {
            setItemsError(undefined)
            setSubmitError(undefined)
            onSubmit()
        }
    }

    const onClickClearRow = (index: any) => {
        setListItems((prevItems: any) => prevItems?.map((obj: any, idx: number) => idx === index ? {} : obj))
        setSubmitError(undefined)
    }

    return (
        <WhiteContainer containerStyle='my-5 px-0'>
            {findBarcodeLocationsResponse && <Error text={findBarcodeLocationsResponse} />}
            <p className='text-[#8A8A8E] text-[16px] px-4'>First select item location</p>
            <div className='flex flex-row items-center mb-5 px-4'>
                <Input
                    label=''
                    containerStyle='min-w-[300px]'
                    showValue={true}
                    inputValue={scannedLocation}
                    onChangeInput={onChangedScannedLocation}
                    onHandleKeyDown={onEnterAction}
                    placeholder='Location Barcode'
                    hideInputSpace={true}
                />
                <p className='mx-5 text-primary-light'>or</p>
                <SelectCheckbox
                    placeholder='Select Section'
                    options={sections}
                    selectedOption={selectedSection}
                    containerStyle='min-w-[300px]'
                    onChangeSelectedOption={onChangeSelectedSection}
                    hideInputSpace={true}
                />
                <Button
                    label='Clear All'
                    className='btn-primary-text-underline mx-4 text-[16px] '
                    onClickButton={onClickClearLocationAndSection}
                />
            </div>
            <p className='text-primary-light text-[16px] px-4'>Press Enter in Current Location input to get Current Item Location</p>
            <div>
                <ItemsLocationTable
                    listValues={listItems}
                    itemIdsError={findItemErrors}
                    itemsError={itemsError}
                    searchInDB={searchInDB}
                    loadingOptions={loadingOptions}
                    filteredLocations={filteredLocations}
                    responseLocations={responseLocations}
                    itemsCurrentLocations={currentLocations}
                    onClickClearRow={onClickClearRow}
                    onClickSearchInDb={onClickSearchInDb}
                    onMenuCloseAfterSearch={onMenuCloseAfterSearch}
                    onChangeSearchLocationField={onChangeSearchLocationFields}
                    onChangeItemId={onChangeRowItemId}
                    onChangeSelectedLocation={onChangeRowSelectedLocation}
                    onEnterItemIdInput={onEnterRowItemId}
                    onRemoveListValue={onRemoveRowListItem}
                />
            </div>
            <div className='flex flex-row justify-between'>
            <div className='flex flex-row justify-start my-5 px-4'>
                <Button
                    label='Add New Row'
                    className={responseLocations && !submitLoading ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => responseLocations && !submitLoading && onAddNewRow()}
                />
            </div>
            {submitError &&
                <div className='flex flex-row justify-center my-4'>
                    <Error text={submitError} />
                </div>
            }
            <div className='flex flex-row justify-end my-5 px-4'>
                <Button
                    label='Save Data'
                    className={responseLocations && !submitLoading ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => responseLocations && !submitLoading && onValidateItems()}
                />
            </div>
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </WhiteContainer>
    )
}
export default ItemLocation;