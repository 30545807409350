import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISales } from '../../../interfaces/sales/ISales';
import { IToast } from '../../../interfaces/components/IToast';
import { tryToFetchAssetTemplateFieldsFilter } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import { tryToFetchUserData } from '../../../store/inventory/shared/partners/partnersSlice';
import {
    tryToFetchSaleOrderContent,
    tryToFetchSingleSaleForm,
    tryToRefreshStock
} from '../../../store/inventory/shared/sales/salesSlice';
import SaleOrderDetailsInfo from './details/SaleOrderDetailsInfo';
import SaleDetailsActions from './details/SaleDetailsActions';
import ParentChildDetails from './details/ParentChildDetails';
import SaleDetailsStats from './details/SaleDetailsStats';
import ProcessingStatus from './status-details/ProcessingStatus';
import ProccessedStatus from './status-details/ProcessedStatus';
import OpenOrderStatus from './status-details/OpenOrderStatus';
import ShippedStatus from './status-details/ShippedStatus';
import ExpiredStatus from './status-details/ExpiredStatus';
import ReviewStatus from './status-details/ReviewStatus';
import DraftStatus from './status-details/DraftStatus';
import Toast from '../../../shared/toast';


const SaleOrderDetails = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const [saleCategories, setSaleCategories] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [saleDetails, setSaleDetails] = useState<ISales>();
    const [salesContent, setSalesContent] = useState<any>();
    const [templateFields, setTemplateFields] = useState<any>();
    const [refreshStockLoading, setRefreshStockLoading] = useState<boolean>(false)
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const user = useAppSelector((state) => state?.partners?.userData);
    const state = useAppSelector((state) => state.sales);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Sales Order Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setSaleCategories(formatSectors || [])
    }

    const onGettingAllAssetFields = async () => {
        if (saleDetails?.categories && saleDetails?.categories?.length > 0) {
            try {
                await Promise.all(saleDetails?.categories?.map(async (item: any) => {
                    if (!templateFields?.[item?.code]) {
                        const response: any = await dispatch(tryToFetchAssetTemplateFieldsFilter(item?.id)).unwrap();
                        if (response?.fields) {
                            setTemplateFields((prevTemplateFields: any) => ({
                                ...prevTemplateFields || {},
                                [item?.id || '']: response?.fields
                            }));
                        }
                    }
                    return item;
                }))
            } catch (err) {
                // error here
            }
        }
    };

    useEffect(() => {
        if (saleDetails?.categories) {
            onGettingAllAssetFields()
        }
    }, [saleDetails?.categories, saleDetails?.status])

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    const onGettingPurchaseData = async () => {
        await dispatch(tryToFetchSingleSaleForm(id || ''))
        setRefreshStockLoading(false)
    }

    useEffect(() => {
        if (!user) {
            dispatch(tryToFetchUserData())
        }
        onGettingPurchaseData();
        dispatch(tryToFetchSaleOrderContent(id || '')).unwrap();
    }, [id])

    useEffect(() => {
        if (state.saleContent) {
            setSalesContent(state.saleContent)
        }
    }, [state.saleContent])

    useEffect(() => {
        if (state.sale) {
            setSaleDetails(state.sale)
        }
    }, [state.sale])

    const onGoBack = () => {
        navigator(-1)
    }

    const onViewRestOfTheOrder = (id: string) => {
        navigator(`/sales-orders/${id}`);
    }

    const onSuccessfullyChanged = (type: string, message: string) => {
        setShowToast({ type, message })
        onGettingPurchaseData();
    }

    const onClickRefreshStock = async () => {
        setRefreshStockLoading(true)
        try {
            await dispatch(tryToRefreshStock(id || '')).unwrap()
            onGettingPurchaseData();
            onSuccessfullyChanged('success', 'Refreshed stock successfully!')
        } catch (e) {
            onSuccessfullyChanged('error', `${e}`)
            setRefreshStockLoading(false)
        }
    }

    const ActiveStatus = () => {
        if (saleDetails?.status) {
            switch (saleDetails?.status) {
                case 'draft':
                    return <DraftStatus
                        saleDetails={saleDetails}
                        saleCategories={saleCategories}
                        salesContent={salesContent}
                    />
                case 'open_order':
                    return <OpenOrderStatus
                        saleDetails={saleDetails}
                        saleId={id || ''}
                        templateFields={templateFields}
                        saleCategories={saleCategories}
                        accessControl={accessControl}
                        pageAccess={pageAccess}
                        salesContent={salesContent}
                        refreshStockLoading={refreshStockLoading}
                        onGettingPurchaseData={onGettingPurchaseData}
                        onClickRefreshStock={onClickRefreshStock}
                    />
                case 'review':
                    return <ReviewStatus
                        saleId={id || ''}
                        saleDetails={saleDetails}
                        accessControl={accessControl}
                        pageAccess={pageAccess}
                        templateFields={templateFields}
                        salesContent={salesContent}
                        saleCategories={saleCategories}
                        onGettingPurchaseData={onGettingPurchaseData}
                        onSuccessfullyChanged={onSuccessfullyChanged}
                    />
                case 'processing':
                    return <ProcessingStatus
                        saleDetails={saleDetails}
                        saleId={id || ''}
                        saleCategories={saleCategories}
                        accessControl={accessControl}
                        pageAccess={pageAccess}
                        salesContent={salesContent}
                        templateFields={templateFields}
                        refreshStockLoading={refreshStockLoading}
                        onSuccessfullyChanged={onSuccessfullyChanged}
                        onGettingPurchaseData={onGettingPurchaseData}
                        onClickRefreshStock={onClickRefreshStock}
                    />
                case 'processed':
                case 'paid':
                    return <ProccessedStatus
                        saleCategories={saleCategories}
                        saleDetails={saleDetails}
                        templateFields={templateFields}
                    />
                case 'shipped':
                    return <ShippedStatus
                        saleCategories={saleCategories}
                        saleDetails={saleDetails}
                        templateFields={templateFields}
                    />
                case 'expired':
                    return <ExpiredStatus
                        saleCategories={saleCategories}
                        saleDetails={saleDetails}
                        salesContent={salesContent}
                        templateFields={templateFields}
                    />
                default:
                    return <div></div>
            }
        }
    }

    const findInSelected = (id: string) => {
        const findIfInSelected = (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.find((item: any) => item?.itemId === id)
        if (findIfInSelected) {
            return true
        }
        else return false
    }

    const reservedItemsWithoutSelected = (saleDetails?.reservedItems && saleDetails?.reservedItems?.length > 0) ? saleDetails?.reservedItems?.filter((item: any) => item?.match && !findInSelected(item?.itemId))?.length || 0 : 0
    const requestedItems = (saleDetails?.saleItems && saleDetails?.saleItems?.length > 0) && saleDetails?.saleItems?.reduce((accumulator: number, item: any) => accumulator + item?.qty, 0);
    const reservedItems = ['processing', 'review', 'open_order', 'expired'].includes(saleDetails?.status || '') ? saleDetails?.reservedItems && saleDetails?.reservedItems?.length : undefined
    const selectedItemsWithoutExtra = (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.filter((item: any) => !item?.match || item?.status !== 'extra_item')
    const requestedItemsData = ['review']?.includes(saleDetails?.status || '') && saleDetails?.completedReviewed ? saleDetails?.requestedItems?.length || 0 : undefined
    const missingItems = ['processing', 'review', 'open_order', 'expired'].includes(saleDetails?.status || '') ? requestedItems ? Number(requestedItems) - ((reservedItemsWithoutSelected || 0) + (selectedItemsWithoutExtra?.length || 0) + (requestedItemsData || 0)) : undefined : undefined
    const processedItems = ['processed', 'paid', 'shipped'].includes(saleDetails?.status || '') ? (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.reduce((accumulator: number, item: any) => accumulator + (!item?.notMatching ? 1 : 0), 0) : undefined
    const scannedItems = ['review']?.includes(saleDetails?.status || '') && saleDetails?.completedReviewed ? saleDetails?.selectedItems?.length || 0 : undefined
    const notMatching = ['review'].includes(saleDetails?.status || '') && saleDetails?.completedReviewed ? (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.reduce((accumulator: number, item: any) => accumulator + (item?.match && item?.status === 'not_matching' ? 1 : 0), 0) : undefined
    const matchingItems = ['review'].includes(saleDetails?.status || '') && saleDetails?.completedReviewed ? (saleDetails?.selectedItems && saleDetails?.selectedItems?.length > 0) && saleDetails?.selectedItems?.reduce((accumulator: number, item: any) => accumulator + (['accepted', 'matching']?.includes(item?.status) && item?.match ? 1 : 0), 0) : undefined
    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-2'}>
                <div className='flex flex-row items-center justify-between w-full'>
                    <div className='flex flex-row items-center'>
                        <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                            <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                        </div>
                        <p className='page-title'>Sale Details</p>
                    </div>
                    <SaleDetailsActions
                        saleDetails={saleDetails}
                        accessControl={accessControl}
                        pageAccess={pageAccess}
                        onSuccessfullyChanged={onSuccessfullyChanged}
                    />
                </div>
            </div>
            <SaleOrderDetailsInfo
                saleDetails={saleDetails}
            />
            {saleDetails?.status !== 'draft' &&
                <SaleDetailsStats
                    requested={requestedItems}
                    requestedItems={!saleDetails?.completedReviewed ? requestedItemsData : undefined}
                    totalReserved={reservedItems}
                    missingItems={missingItems}
                    processed={processedItems}
                    scanned={scannedItems}
                    notMatching={notMatching}
                    matching={matchingItems}
                />
            }
            {ActiveStatus()}
            {(saleDetails?.restOrders && saleDetails?.restOrders?.length > 0) &&
                <ParentChildDetails
                    salesDetails={saleDetails?.restOrders}
                    onViewRestOfTheOrder={onViewRestOfTheOrder}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}
export default SaleOrderDetails;