import React from 'react';
import QRCode from 'react-qr-code';
import { usePDF, Margin } from 'react-to-pdf';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface ILabelPrintModal {
    openModal: boolean;
    data?: any;
    dataInventory?: any;
    vatType?: string;
    itemId: string;
    handleCloseModal: () => void;
}

const LabelPrintModal = ({
    openModal,
    data,
    dataInventory,
    vatType,
    itemId,
    handleCloseModal,
}: ILabelPrintModal) => {
    const { toPDF, targetRef } = usePDF({ filename: `${itemId}-label.pdf`, page: { margin: Margin.SMALL } });

    const findAssetFields = data && Object.keys(data)
    const inventoryFields = dataInventory && Object.keys(dataInventory)
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[350px]'}>
                <p className='mb-4 font-semibold text-sm'>Label</p>
                <div className='my-4 flex flex-row justify-center'>
                    <Button
                        label='Download Label'
                        className='btn-primary mb-2'
                        onClickButton={() => toPDF()}
                    />
                </div>
                <div className='flex flex-col items-center' ref={targetRef}>
                    <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '200px', width: '200px' }}
                        value={itemId}
                        viewBox={'0 0 256 256'}
                    />
                    <p className='text-center my-2'>{itemId}</p>
                    <div className='my-4'>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        {(findAssetFields && findAssetFields?.length > 0) && findAssetFields?.map((field: any, index: number) => {
                            return (
                                <>
                                    <div className='grid grid-cols-2 gap-3 my-2'>
                                        <p className=''>{`${field[0].toUpperCase()}${field?.slice(1).toLowerCase()}`}</p>
                                        <p className='break-words'>
                                            {data?.[field]}
                                        </p>
                                    </div>
                                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                </>
                            )
                        }
                        )}
                        {(inventoryFields && inventoryFields?.length > 0) && inventoryFields?.map((field: any, index: number) => {
                            return (
                                <>
                                    <div className='grid grid-cols-2 gap-3 my-2'>
                                        <p className=''>{`${field[0].toUpperCase()}${field?.slice(1).toLowerCase()}`}</p>
                                        <p className='break-words'>
                                            {dataInventory?.[field]}
                                        </p>
                                    </div>
                                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                </>
                            )
                        }
                        )}
                        {vatType &&
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>VAT Type</p>
                                    <p className='break-words'>
                                        {vatType}
                                    </p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default LabelPrintModal;