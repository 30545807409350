import React from 'react';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import Button from '../../../../shared/button';
import WhiteContainer from '../../../../shared/white-container';


interface IWarehouseItem {
    warehouse: IWarehouse;
    key?: number;
    showActions?: boolean;
    isEnable?: boolean;
    marketName?: string;
    warehouseName?: string;
    accessWarehouseDetails?: any;
    accessDeleteWarehouse?: any;
    accessEditWarehouse?: any;
    onEditWarehouse?: () => void;
    onChangeStatusWarehouse?: () => void;
    onShowWarehouseDetails?: () => void;
}

const WarehouseItem = ({
    warehouse,
    key,
    showActions,
    isEnable,
    accessWarehouseDetails,
    accessDeleteWarehouse,
    accessEditWarehouse,
    onEditWarehouse,
    onChangeStatusWarehouse,
    onShowWarehouseDetails,
}: IWarehouseItem) => {
    return (
        <WhiteContainer containerStyle='max-w-[90%]' type='rounded' key={key}>
            <div className='w-full h-full flex flex-col items-start justify-start'>
                <p className='font-bold text-[17px] mt-2 mb-5'>{warehouse?.name}</p>
                <div className='flex flex-row items-start justify-between w-full'>
                   <div className='flex flex-col '>
                   <div className='flex flex-row items-center mb-3 text-[#F7A21E] font-bold'>
                    <img src={'/assets/profile/shipping-location.png'} className={'h-[24px] object-contain mr-2 '} title='Shipping Address'/>
                    <p>Shipping Address</p>
                   </div>
                    <div className='ml-8'>
                        <p>{warehouse?.address?.street}</p>
                        <p>{warehouse?.address?.addressNo}</p>
                        <p>{warehouse?.address?.city}</p>
                        <p>{warehouse?.address?.postalCode}</p>
                        <p>{warehouse?.address?.country}</p>
                        <p>{warehouse?.address?.state}</p>
                        <p>{warehouse?.billingAddress?.phone}</p>
                    </div>
                   </div>
                   {warehouse?.billingAddress && <div className='flex flex-col'>
                    <div className='flex flex-row items-center mb-3 text-[#51996A] font-bold'>
                   <img src={'/assets/profile/bill-receipt.png'} className={'h-[24px] object-contain mr-2'} title='Billing Address'/>
                   <p>Billing Address</p>
                   </div>
                    <div className='ml-8'>
                        <p>{warehouse?.billingAddress?.street || ''}</p>
                        <p>{warehouse?.billingAddress?.addressNo}</p>
                        <p>{warehouse?.billingAddress?.city}</p>
                        <p>{warehouse?.billingAddress?.postalCode}</p>
                        <p>{warehouse?.billingAddress?.country}</p>
                        <p>{warehouse?.billingAddress?.state}</p>
                        <p>{warehouse?.address?.phone}</p>
                    </div>
                   </div>}
                </div>
             <div className='flex flex-row justify-between w-full'>
             {/* {warehouse?.address?.phone ?
                    <div className='flex flex-row items-center mt-3 mb-5'>
                        <img src={'/assets/profile/phone.png'} className={'h-[24px] object-contain mr-2'} />
                        <p>{warehouse?.address?.phone}</p>
                    </div>
                    : <div className='flex flex-row items-center mt-6 mb-6'>
                    </div>
                } */}
                   {/* {warehouse?.billingAddress?.phone ?
                    <div className='flex flex-row items-center mt-3 mb-5'>
                        <img src={'/assets/profile/phone.png'} className={'h-[24px] object-contain mr-2'} />
                        <p>{warehouse?.billingAddress?.phone}</p>
                    </div>
                    : <div className='flex flex-row items-center mt-6 mb-6'>
                    </div>
                } */}

             </div>
                <p className='font-bold text-[16px] mt-4 mb-2'>Markets</p>
                <div className='flex flex-row flex-wrap items-center'>
                    {(warehouse?.markets && warehouse?.markets?.length > 0)
                        && warehouse?.markets?.map((item, index) => {
                            return (
                                <div key={index}>
                                    {item?.flagUrl && <img
                                        src={`${item?.flagUrl}`}
                                        title={item?.label}
                                        className={'w-[16px] object-contain mr-1 mb-2'}
                                    />}
                                </div>
                            )
                        })
                    }
                </div>
                {showActions &&
                    <div className='mt-4 mb-2'>
                        <Button
                            label={'Edit'}
                            dataQa={'edit'}
                            className={`${accessEditWarehouse ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                            onClickButton={() => accessEditWarehouse && onEditWarehouse?.()}
                        />
                        <Button
                            label={isEnable ? 'Delete' : 'Enable'}
                            dataQa={'enable-delete'}
                            className={`${accessDeleteWarehouse ? 'btn-primary-text-underline' : 'btn-primary-text-underline-disable'}`}
                            onClickButton={() => accessDeleteWarehouse && onChangeStatusWarehouse?.()}
                        />
                    </div>
                }
            </div>
        </WhiteContainer>
    )
}

export default WarehouseItem;