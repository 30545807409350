import React from 'react';
import { SALES_SOURCES } from '../../../../utils/constants/sales-sources';
import { SALES_ORDER_STATUSES } from '../../../../utils/constants/sales-order-statuses';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ISalesFilters {
    selectedStatus?: string;
    selectedCustomer?: any;
    customers: any;
    categories?: any;
    selectedCategory?: any;
    selectedSource?: any;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onClickCategory: (value: any) => void;
}

const SalesFilters = ({
    selectedStatus,
    selectedCustomer,
    customers,
    categories,
    selectedSource,
    selectedCategory,
    onClickCategory,
    onChangeSelectedValue,
}: ISalesFilters) => {
    return (
        <div>
            <div className='mb-4'>
                <p className='text-primary-light font-bold text-sm mt-6 mb-4'>Categories</p>
                <div className='my-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4'>
                    <Button
                        label={<span className={`${selectedCategory?.value === 'allCategories' ? 'text-[#202020]' : 'text-[#8a8a8e]'} text-[15px]`}>All Categories</span>}
                        dataQa={'all-categories'}
                        className={`${selectedCategory?.value === 'allCategories' ? 'btn-categories-list' : 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0]'} !min-w-[70px]  !rounded-[6px] text-sm`}
                        onClickButton={() => onClickCategory({ value: 'allCategories', label: 'All Categories' })}
                    />
                    {categories && categories?.length > 0 && categories?.map((item: any, index: number) => {
                        let buttonClassName = 'btn-noactive-categories border'
                        if (selectedCategory?.value === item?.value) buttonClassName = 'btn-categories-list !rounded-[6px]';
                        else buttonClassName = 'btn-noactive-categories-list border !border-[#a0a0a0] !text-[#a0a0a0] !rounded-[6px]';
                        return (
                            <Button
                                key={index}
                                label={item?.label}
                                className={`${buttonClassName} !min-w-[70px] text-sm`}
                                onClickButton={() => onClickCategory(item)}
                                dataQa={`${item?.label && item?.label?.toLowerCase()?.replaceAll(' ', '-')}`}
                                icon={item.enabledIcon ? <div className={'w-[23px] h-[23px]'}><img src={selectedCategory?.value === item?.value ? item.enabledIcon : item.disabledIcon} className={'max-w-[100%] max-h-[100%] min-w-[100%] min-h-[100%] object-contain'} /></div> : ''}
                            />)
                    })}
                </div>
            </div>
            <div className='my-6 grid grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Source'
                    dataQa={'source-filter'}
                    placeholder={''}
                    options={SALES_SOURCES || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onChangeSelectedValue}
                    selectedOption={selectedSource}
                    uniqueName={'source'}
                />
                <SelectCheckbox
                    name='Customer'
                    dataQa={'customer-filter'}
                    placeholder={''}
                    options={customers || []}
                    selectStyle={{ backgroundColor: '#ffffffa4', borderRadius: '4px', minWidth: 200 }}
                    onChangeSelectedOption={onChangeSelectedValue}
                    selectedOption={selectedCustomer}
                    uniqueName={'customer'}
                />
            </div>
            <div className='min-w-full'>
                <div className='flex flex-row items-center'>
                    <p className='text-[#202020] text-sm font-bold ml-2 mr-4'>Filter</p>
                    <div className='flex flex-row items-center flex-wrap'>
                        {(SALES_ORDER_STATUSES && SALES_ORDER_STATUSES?.length > 0) && SALES_ORDER_STATUSES?.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    onClickButton={() => onChangeSelectedValue(item?.value, 'status')}
                                    label={item?.name}
                                    className={`${selectedStatus === item?.value ? 'btn-filters' : 'btn-noactive-filters !text-[#a0a0a0] !border-[#a0a0a0]'} mr-2 !font-medium mb-2`}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesFilters;