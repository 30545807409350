import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAssetsTemplatesListState } from '../../../../interfaces/asset-templates/IAssetsTemplatesList';
import { IAssetFields, IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { PaginatedAssetTemplate } from '../../../../interfaces/asset-templates/paginatedStore.type';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { AssetsTemplatesService } from './assetsTemplatesApi';


const initialState: IAssetsTemplatesListState = {
    assetsTemplatesAreLoading: false,
    assetTemplateIsLoading: false,
    assetTemplateFieldsAreLoading: false,
    assetTemplateFieldIsLoading: false,
    allAssetsAreLoading: false,
    assetTemplateFiltersAreLoading: false,
    assetTemplateFramesAreLoading: false,
    assetTemplateFrameIsLoading: false,
    assetTemplateSalesGradeIsLoading: false,
    parentCategoriesAreLoading: false,
    parentCategories: [],
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAssetsTemplates = createAsyncThunk<PaginatedAssetTemplate, IPaginationPayload>(
    'assetTemplates/tryToFetchAssetsTemplates',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await AssetsTemplatesService.tryToFetchAssetsTemplates(pageNumber, pageSize, filters);
        return result.data;
    },
);

export const tryToFetchAllAssetsTemplates = createAsyncThunk<any>(
    'assetTemplates/tryToFetchAllAssetsTemplates',
    async () => {
        const result = await AssetsTemplatesService.tryToFetchAllAssetTemplates();
        return result?.data?.data;
    },
);

export const tryToFetchAssetTemplateFieldsFilter = createAsyncThunk<IAssetFields[], string>(
    'assetTemplates/tryToFetchAssetTemplateFieldsFilter',
    async (id) => {
        const result = await AssetsTemplatesService.tryToFetchAssetTemplateFieldsFilter(id);
        return result?.data?.data;
    },
);

export const tryToFetchAssetTemplateFilters = createAsyncThunk<any, string>(
    'assetTemplates/tryToFetchAssetTemplateFilters',
    async (assetId) => {
        const result = await AssetsTemplatesService.tryToFetchAssetTemplateFilters(assetId);
        return result?.data?.data;
    },
);

export const tryToFetchInventoryFilters = createAsyncThunk<any, string>(
    'assetTemplates/tryToFetchInventoryFilters',
    async (assetId) => {
        const result = await AssetsTemplatesService.tryToFetchInventoryFilters(assetId);
        return result?.data?.data;
    },
);

export const tryToFetchSingleAssetTemplate = createAsyncThunk<IAssetTemplate, string>(
    'assetTemplates/tryToFetchSingleAssetTemplate',
    async (id: string) => {
        const result = await AssetsTemplatesService.tryToFetchSingleAssetTemplate(id);
        return result?.data?.data;
    },
);

export const assetTemplatesSlice = createSlice({
    name: 'assetTemplates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAssetsTemplates
            .addCase(tryToFetchAssetsTemplates.pending, (state) => {
                state.assetsTemplatesAreLoading = true;
            })
            .addCase(tryToFetchAssetsTemplates.rejected, (state) => {
                state.assetsTemplatesAreLoading = false;
            })
            .addCase(tryToFetchAssetsTemplates.fulfilled, (state, action) => {
                state.assetsTemplatesAreLoading = false;
                state.assetTemplates = action.payload;
            })

            // tryToFetchAllAssetsTemplates
            .addCase(tryToFetchAllAssetsTemplates.pending, (state) => {
                state.allAssetsAreLoading = true;
            })
            .addCase(tryToFetchAllAssetsTemplates.rejected, (state) => {
                state.allAssetsAreLoading = false;
            })
            .addCase(tryToFetchAllAssetsTemplates.fulfilled, (state, action) => {
                state.allAssetsAreLoading = false;
                state.allAssetTemplates = action?.payload;
            })

            // tryToFetchAssetTemplateFilters
            .addCase(tryToFetchAssetTemplateFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToFetchAssetTemplateFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(tryToFetchAssetTemplateFilters.fulfilled, (state, action) => {
                state.assetTemplateFiltersAreLoading = false;
                state.assetTemplateFilters = action.payload;
            })

            // tryToFetchInventoryFilters
            .addCase(tryToFetchInventoryFilters.pending, (state) => {
                state.assetTemplateFiltersAreLoading = true;
            })
            .addCase(tryToFetchInventoryFilters.rejected, (state) => {
                state.assetTemplateFiltersAreLoading = false;
            })
            .addCase(tryToFetchInventoryFilters.fulfilled, (state, action) => {
                state.assetTemplateFiltersAreLoading = false;
                state.inventoryFieldFilters = action.payload;
            })

            // tryToFetchSingleAssetTemplate
            .addCase(tryToFetchSingleAssetTemplate.pending, (state) => {
                state.assetTemplateIsLoading = true;
            })
            .addCase(tryToFetchSingleAssetTemplate.rejected, (state) => {
                state.assetTemplateIsLoading = false;
            })
            .addCase(tryToFetchSingleAssetTemplate.fulfilled, (state, action) => {
                state.assetTemplateIsLoading = false;
                state.assetTemplateDetails = action.payload;
            })
    }
});

export default assetTemplatesSlice.reducer;


