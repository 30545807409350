import React from 'react';
import { ISingleSaleField } from '../../../../interfaces/sales/ISalesFields';
import Table from '../../../../shared/table';

const tableColumns = ['Field Id', 'Name', 'Is Required', 'Data Type', 'Values', 'Actions']

interface ISaleFieldsTable {
    rows: ISingleSaleField[];
    onEditField: (id: string) => void;
    onDeleteField: (id: string) => void;
}

const SaleFieldsTable = ({
    rows,
    onEditField,
    onDeleteField
}: ISaleFieldsTable) => {

    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
        >
            {rows?.length > 0 && rows?.map((row: ISingleSaleField, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{row?.id}</td>
                        <td className='px-6 py-4'>{row?.name}</td>
                        <td className='px-6 py-4'>{row?.required ? 'Yes' : 'No'}</td>
                        <td className='px-6 py-4'>{row?.type}</td>
                        <td className='px-6 py-4'> {row?.value && row?.value?.map((item, index) => `${index ? ', ' : ''}${item}`)}</td>
                        <td className='px-6 py-4'>
                            <div className='flex flex-row items-center'>
                                <div onClick={() => row?.id && onEditField(row?.id)}>
                                    <img title={'Edit Field'} alt='edit item' src={'/assets/table-actions/pen.svg'} className={'h-[14px] object-contain cursor-pointer mr-3'} />
                                </div>
                                <div onClick={() => row?.id && onDeleteField(row?.id)}>
                                    <img title={'Delete Field'} alt='delete item' src={'/assets/table-actions/delete-close.svg'} className={'h-[14px] object-contain cursor-pointer mr-1'} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default SaleFieldsTable;