import React from 'react';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';


interface IGenerateLocationsStep {
    loadingGenerate: boolean;
    generateError?: string;
    onGenerateLocations: () => void;
}

const GenerateLocationsStep = ({
    loadingGenerate,
    generateError,
    onGenerateLocations
}: IGenerateLocationsStep) => {
    return (
        <div className={'w-full flex flex-col items-center justify-around min-h-[250px]'}>
            <div>
                <p className='text-[16px] font-medium text-center my-4'>Ensure that you have completed all configurations related to the section before proceeding to the Generate Locations step. Note that locations for the section cannot be generated more than once if items have been placed in them.</p>
                {generateError && <div className='flex flex-row justify-center my-4'><Error text={generateError} /></div>}
            </div>
            <Button
                label='Generate Locations'
                dataQa={'generate-locations'}
                className={`${!loadingGenerate ? 'btn-primary' : 'btn-primary-disable'} my-3 min-w-[150px] !shadow-none`}
                onClickButton={() => !loadingGenerate && onGenerateLocations()}

            />
        </div>
    )
}

export default GenerateLocationsStep;