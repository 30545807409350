import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToMarkAsUsed } from '../../../../store/inventory/broker/credit-notes/creditNotesSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IMarkAsUsedModal {
    openModal: boolean;
    data?: any;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessfullySaved: () => void;
}

const MarkAsUsedModal = ({
    openModal,
    data,
    itemId,
    handleCloseModal,
    onSuccessfullySaved,
}: IMarkAsUsedModal) => {
    const dispatch = useAppDispatch();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();

    const onCloseModal = () => {
        setLoadingSubmit(false)
        setSubmitError(undefined)
        handleCloseModal();
    }

    const onSubmitMarkAsUsed = async () => {
        setLoadingSubmit(true)
        setSubmitError(undefined)
        try {
            await dispatch(tryToMarkAsUsed(itemId || '')).unwrap();
            onSuccessfullySaved()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
    }

    return (
        <Modal
            open={openModal}
            showInRight
            title={'Mark as Used'}
            onClose={() => onCloseModal()}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Used Credit Note</p>
                </div>
                {submitError && <Error text={submitError} />}
                <div className='p-3 !rounded bg-[#f8f9fc] my-2'>
                    <p className='my-2 text-[17px] font-medium'>Are you sure you want to mark as used this item with all the details?</p>
                    <p className='mb-3 text-[13px]'>This credit note after you complete this action will be added into the used credit notes.</p>
                    <div className='flex flex-row justify-around items-center mt-7 mb-3'>
                        <Button
                            label='No'
                            dataQa={'continue-button'}
                            className={`${loadingSubmit ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px]`}
                            onClickButton={() => !loadingSubmit && handleCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loadingSubmit ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loadingSubmit && onSubmitMarkAsUsed()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default MarkAsUsedModal;