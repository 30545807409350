import { AxiosResponse } from 'axios';
import { INVENTORY_API_URL } from '../../../../config';
import { ICrmSchema } from '../../../../interfaces/crm-data/ICrmSchema';
import { PaginatedCrm } from '../../../../interfaces/crm-data/paginatedStore.type';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import inventoryAxios from '../../../../utils/axios/inventory.axios';

const tryToFetchCrmData = (
    pageNumber: number,
    pageSize: number,
    data?: any,
): Promise<AxiosResponse<PaginatedCrm>> => {
    const url = `${INVENTORY_API_URL}/crmdata?page=${pageNumber}&size=${pageSize}`
    return inventoryAxios.put<PaginatedCrm>(url, data);
};

const tryToFetchAllCrmData = (data: any): Promise<AxiosResponse<ApiResponse<ICrmSchema>>> => {
    return inventoryAxios.put<ApiResponse<any>>(`${INVENTORY_API_URL}/crmdata/all`,data);
};

const tryToFetchSingleCrmData = (id: string): Promise<AxiosResponse<ApiResponse<ICrmSchema>>> => {
    return inventoryAxios.get<ApiResponse<any>>(`${INVENTORY_API_URL}/crmdata/${id}`);
};

const tryToAddCrmData = (data: ICrmSchema): Promise<AxiosResponse> => {
    return inventoryAxios.post(`${INVENTORY_API_URL}/crmdata`, data);
};

const tryToEditCrmData = (id: string,payload: ICrmSchema): Promise<AxiosResponse> => {
    return inventoryAxios.put(`${INVENTORY_API_URL}/crmdata/${id}`, payload);
};

const tryToDeleteCrmData = (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return inventoryAxios.delete(`${INVENTORY_API_URL}/crmdata/${id}/delete`)
}

export const CrmService = {
    tryToFetchCrmData,
    tryToFetchSingleCrmData,
    tryToFetchAllCrmData,
    tryToAddCrmData,
    tryToEditCrmData,
    tryToDeleteCrmData
};
