import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DISCREPANCY_SOURCES } from '../../../utils/constants/discrepancy-source';
import { CREDIT_NOTES_FILTERS } from '../../../utils/constants/credit-notes-filters';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToDownloadCreditNotes,
    tryToFetchCreditNotes
} from '../../../store/inventory/broker/credit-notes/creditNotesSlice';
import { tryToFetchUserData } from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchAllCrmData } from '../../../store/inventory/broker/crm-data/crmDataSlice';
import CreditNoteFilter from './details/CreditNoteFilters';
import CreditNotesTable from './tables/CreditNotesTable';
import MarkAsReceivedModal from './modals/MarkAsReceivedModal';
import MarkAsUsedModal from './modals/MarkAsUsedModal';
import AddNotesModal from './modals/AddNotesModal';
import EmptyContainer from '../../../shared/empty-container';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreditNotes = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const query = useQuery();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const csvLink1 = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<Array<any>>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeFilter, setActiveFilter] = useState<string>(query?.get('status') || CREDIT_NOTES_FILTERS?.[0]?.value);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10
    });
    const [brokerCategories, setBrokerCategories] = useState<Array<any>>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [customerSelected, setCustomerSelected] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [selectedSource, setSelectedSource] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [orderId, setOrderId] = useState<string>();
    const [search, setSearch] = useState<string>();
    const [creditNotesData, setCreditNotesData] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showReceivedModal, setShowReceivedModal] = useState<{ show: boolean, item: any }>();
    const [showUsedModal, setShowUsedModal] = useState<boolean>(false);
    const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
    const [brokerCustomers, setBrokerCustomers] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [loadingDownloadCSV, setLoadingDownloadCSV] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<any>();
    const state = useAppSelector((state) => state.creditNotes);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const loadingData = useAppSelector((state) => state.creditNotes?.creditNotesAreLoading);
    const paginationData = useAppSelector((state) => state?.creditNotes?.creditNotes?.data?.page);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const user = useAppSelector((state) => state?.partners?.userData)

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Credit Notes')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (state.creditNotes && activeFilter) {
            const rows = state.creditNotes?.data?.elements
            setRows(rows);
        }
    }, [state.creditNotes]);

    const onGettingFilters = () => {
        let data: any
        if (activeFilter) {
            data = {
                ...data || {},
                status: activeFilter,
            }
        }
        if (selectedCategory) {
            data = {
                ...data || {},
                categoryId: selectedCategory?.id,
            }
        }
        if (activeWarehouse) {
            data = {
                ...data || {},
                warehouse: activeWarehouse?._id,
            }
        }
        if (selectedSource) {
            data = {
                ...data || {},
                reference: selectedSource?.value,
            }
        }
        if (customerSelected) {
            data = {
                ...data || {},
                customerId: customerSelected?.value || '',
            }
        }
        let formatData: any = { data: data }
        if (search) {
            formatData = {
                ...formatData || {},
                filters: search,
            }
        }
        return formatData;
    }

    const onGettingPurchaseData = async () => {
        if (activeFilter) {
            try {
                const data = onGettingFilters()
                const fetchData = {
                    ...paginationState || {},
                    ...data || {}
                }
                searchParams.set('page', `${paginationState?.pageNumber}`)
                searchParams.set('size', `${paginationState?.pageSize}`)
                setSearchParams(searchParams)
                await dispatch(tryToFetchCreditNotes(fetchData)).unwrap()
            } catch (err) {
                // err
            }
        }
    }

    useEffect(() => {
        if (activeFilter) {
            onGettingPurchaseData()
        }
    }, [paginationState])

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setBrokerCategories(formatSectors || []);
        if (!selectedCategory) {
            const findCategory = (formatSectors && formatSectors?.length > 0) && formatSectors?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findCategory || undefined);
            onResetPaginationState();
        }
    };

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    const onChangeFilterSelected = (value: any, type?: string) => {
        switch (type) {
            case 'customer':
                searchParams.set('customer', value?.label)
                setCustomerSelected(value || '')
                break;
            case 'source':
                searchParams.set('source', value?.label)
                setSelectedSource(value || '')
                break;
            case 'category':
                searchParams.set('category', value?.label)
                setSelectedCategory(value || '')
                break;
        }
        onResetPaginationState()
    }

    const getBrokerCustomers = async () => {
        const response: any = await dispatch(tryToFetchAllCrmData({ type: 'customer' }))
        const formatCustomer: any = (response?.payload && response?.payload?.data?.length > 0) ? response?.payload?.data?.map((s: any) => ({ label: s?.displayName || s?.name, name: s?.displayName || s?.name, value: s?._id })) : []
        let findUrlCustomer: any
        if (query?.get('customer')) {
            findUrlCustomer = formatCustomer && formatCustomer?.length > 0 && formatCustomer?.find((customer: any) => customer.label === query?.get('customer'))
        }
        setCustomerSelected(findUrlCustomer || undefined)
        setBrokerCustomers(formatCustomer || [])
    }

    const onClickActiveFilter = (item: string) => {
        setActiveFilter(item)
        searchParams.set('status', item)
        onResetPaginationState()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        onResetPaginationState()
    }

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationState()
        }
    }, [activeWarehouseState])

    useEffect(() => {
        if (!user) {
            dispatch(tryToFetchUserData())
        }
        getBrokerCustomers();
    }, [])

    useEffect(() => {
        const paginationStateFormat = paginationState
        if (query?.get('page') && Number(query?.get('page')) !== paginationState?.pageNumber) {
            paginationStateFormat.pageNumber = Number(query?.get('page') || 1)
        }
        if (query?.get('size')) {
            paginationStateFormat.pageSize = Number(query.get('size') || 10)
        }
        if (paginationStateFormat !== paginationState) {
            setPaginationState(paginationStateFormat)
        }
        let filterState: any = false
        if (query?.get('search') && query?.get('search') !== search) {
            setSearch(query?.get('search') || undefined)
            filterState = true
        }
        if (query?.get('status') && query?.get('status') !== activeFilter) {
            setActiveFilter(query?.get('status') || activeFilter)
            filterState = true
        }
        if (query?.get('category') && query?.get('category') !== selectedCategory?.label) {
            const findSector = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((item: any) => item?.label === query?.get('category'))
            setSelectedCategory(findSector || undefined)
            filterState = true
        }
        if (query?.get('customer') && query?.get('customer') !== customerSelected?.label) {
            const findCustomer = (brokerCustomers && brokerCustomers?.length > 0) && brokerCustomers?.find((customer: any) => customer?.value === query?.get('customer'))
            setCustomerSelected(findCustomer || undefined)
            filterState = true
        }
        if (query?.get('source') && query?.get('source') !== selectedSource?.label) {
            const findSource = (DISCREPANCY_SOURCES && DISCREPANCY_SOURCES?.length > 0) && DISCREPANCY_SOURCES?.find((source: any) => source?.label === query?.get('source'))
            setSelectedSource(findSource || undefined)
            filterState = true
        }
        if (filterState) {
            onResetPaginationState();
        }
    }, [
        query?.get('page'),
        query?.get('size'),
        query?.get('status'),
        query?.get('source'),
        query?.get('search'),
        query?.get('category'),
        query?.get('customer')
    ])

    const onClearAllFilters = () => {
        onResetPaginationState();
        setSelectedCategory(undefined);
        setCustomerSelected(undefined);
        setSelectedSource(undefined);
        setSearch(undefined);
        searchParams.delete('search')
        searchParams.delete('category')
        searchParams.delete('customer')
        searchParams.delete('source')
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }

    const onClickShowHideNote = (id?: string) => {
        if (id) {
            setShowNoteModal(true)
            setOrderId(id)
            document.body.style.overflow = 'hidden';
        } else {
            setShowNoteModal(false)
            setOrderId(undefined)
            document.body.style.overflow = 'auto';
        }
    }

    const onClickStatusChange = (id: string, type: string, data?: any) => {
        switch (type) {
            case 'received':
                setShowReceivedModal({ show: true, item: data })
                break;
            case 'used':
                setShowUsedModal(true)
                break;
        }
        document.body.style.overflow = 'hidden';
        setOrderId(id)
    }

    const onCloseActionsModal = () => {
        setOrderId(undefined)
        setShowReceivedModal(undefined)
        setShowUsedModal(false)
        document.body.style.overflow = 'auto';
    }

    const onSuccessfullyReceived = () => {
        onCloseActionsModal();
        setShowToast({ type: 'success', message: 'Credit Note was successfully marked as received!' })
    }

    const onSuccessfullyUsed = () => {
        onCloseActionsModal();
        setShowToast({ type: 'success', message: 'Credit Note was successfully marked as used!' })
    }

    const onSeeCreditNoteDetails = (id: string) => {
        navigator(`/credit-notes/${id}`)
    }

    const onDownloadTemplate = async () => {
        setCreditNotesData(undefined)
        setLoadingDownload(true)
        try {
            const settingsFilters = onGettingFilters()
            const response: any = await dispatch(tryToDownloadCreditNotes(settingsFilters)).unwrap()
            setCreditNotesData(response?.data || [])
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response || err?.data?.message || err}` })
            setLoadingDownload(false)
        }
    }

    const onAddedNoteSuccessfull = () => {
        setShowToast({ type: 'success', message: 'Note added successfully' });
    }

    useEffect(() => {
        if (creditNotesData && loadingDownload) {
            csvLink.current.link.click()
            setLoadingDownload(false)
        }
    }, [creditNotesData])

    const onDownloadCSVData = (items: any) => {
        setLoadingDownloadCSV(true)
        setCsvData(undefined)
        const formatItems: any = [['Item Name', 'Item ID/ IMEI', 'Currency', 'Initial Price', 'Final Price', 'Discrepancy Type']];
        (items && items?.length > 0) && items?.map((item: any) => {
            const itemName = [
                ...item?.descriptor ? Object.entries(item?.descriptor).map(([key, value]) => `${value}`) : [],
                ...item?.inventory ? Object.entries(item?.inventory).map(([key, value]) => key === 'sales_grade' ? `Grade ${value}` : `${value}`) : [],
            ].join(' ');

            const itemData = [itemName, item?.itemCode || item?.itemId, item?.currency, Number(item.initialPrice || 0).toFixed(2), Number(item?.price || 0)?.toFixed(2), item?.discrepancyStatus === 'awaiting_credit' ? 'missing' : item?.discrepancyStatus]
            formatItems.push(itemData);
            return item;
        })
        setCsvData(formatItems)
    }

    useEffect(() => {
        if (csvData && loadingDownloadCSV) {
            csvLink1.current.link.click()
            setLoadingDownloadCSV(false)
        }
    }, [csvData])

    return (
        <div>
            <div>
                <p className='page-title'>Credit Notes</p>
            </div>
            <CreditNoteFilter
                activeFilter={activeFilter}
                brokerCategories={brokerCategories}
                brokerCustomers={brokerCustomers}
                selectedCategory={selectedCategory}
                selectedSource={selectedSource}
                customerSelected={customerSelected}
                onChangeFilterSelected={onChangeFilterSelected}
                onClickActiveFilter={onClickActiveFilter}
            />
            <div className={`flex flex-row justify-end items-center bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div>
                    <Input
                        placeholder={'Filter By ID'}
                        dataQa={'search'}
                        containerStyle='mb-0 pb-0 relative min-w-[400px]'
                        inputStyle={`mb-0 border-0 focus:outline-none`}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={search}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <div className='flex flex-row justify-end ml-5'>
                    <Button
                        label='Clear Filters'
                        className={'btn-primary-text-underline min-w-auto'}
                        onClickButton={() => onClearAllFilters()}
                    />
                </div>
                {activeFilter === 'used' &&
                    <div className='col-span-2 flex flex-row justify-end'>
                        <Button
                            label={loadingDownload ? 'Loading data...' : 'Export CSV'}
                            onClickButton={() => !loadingDownload && (!accessControl || pageAccess?.actions?.['Export CSV']) && onDownloadTemplate()}
                            className={`${!loadingDownload && (!accessControl || pageAccess?.actions?.['Export CSV']) ? 'btn-primary' : 'btn-primary-disable'} !shadow-none`}
                        />
                    </div>
                }
            </div>
            {(loadingData || (rows && rows?.length > 0)) ?
                <CreditNotesTable
                    rows={rows}
                    rowsLoading={loadingData}
                    sourceSelected={selectedSource}
                    brokerCategories={brokerCategories}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    paginationData={paginationData}
                    activeFilter={activeFilter}
                    onClickDownloadCSV={onDownloadCSVData}
                    onClickShowNote={onClickShowHideNote}
                    setPaginationState={setPaginationState}
                    onClickStatusChange={onClickStatusChange}
                    onSeeCreditNoteDetails={onSeeCreditNoteDetails}
                />
                : <EmptyContainer
                    text='No Credit Notes found for this status.'
                />
            }
            {showNoteModal &&
                <AddNotesModal
                    openModal={showNoteModal}
                    id={orderId || ''}
                    handleCloseModal={onClickShowHideNote}
                    onSuccessfullyAddedNote={onAddedNoteSuccessfull}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            <CSVLink
                ref={csvLink}
                data={(creditNotesData && creditNotesData?.length > 0) ? creditNotesData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                headers={creditNotesData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Credit-notes-${moment().format('dd-mm-yyyy')}.csv`}
            >
            </CSVLink>
            <CSVLink
                ref={csvLink1}
                data={(csvData && csvData?.length > 0) ? csvData?.filter((item: any, idx: number) => idx > 0) || [] : []}
                headers={csvData?.[0] || []}
                className={'none'}
                target={'_blank'}
                filename={`Credit-note-items-${moment().format('DD-MM-YYYY')}.csv`}
            >
            </CSVLink>
            {showReceivedModal?.show &&
                <MarkAsReceivedModal
                    openModal={showReceivedModal?.show}
                    itemId={orderId || ''}
                    data={showReceivedModal?.item}
                    onSuccessfullySaved={onSuccessfullyReceived}
                    handleCloseModal={onCloseActionsModal}
                />
            }
            {showUsedModal &&
                <MarkAsUsedModal
                    openModal={showUsedModal}
                    itemId={orderId || ''}
                    onSuccessfullySaved={onSuccessfullyUsed}
                    handleCloseModal={onCloseActionsModal}
                />
            }
        </div>
    )
}
export default CreditNotes;