import React from 'react'

interface ISaleDetailsStats {
    requested?: number;
    totalReserved?: number;
    processed?: number;
    missingItems?: number;
    requestedItems?: number;
    picked?: number;
    scanned?: number;
    matching?: number;
    notMatching?: number;
}

const SaleDetailsStats = ({
    requested,
    totalReserved,
    missingItems,
    processed,
    requestedItems,
    picked,
    scanned,
    notMatching,
    matching,
}: ISaleDetailsStats) => {
    return (
        <div className='my-5 grid grid-cols-5 gap-4'>
            <div className='bg-white !rounded p-2'>
                <img src={'/assets/sales-stats/requested.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                <p className='mb-2'>Requested quantity</p>
                <p className='font-bold'>{requested || 0} {(requested && requested > 1) ? 'items' : 'item'}</p>
            </div>
            {(totalReserved || totalReserved === 0) &&
                <div className='bg-white !rounded p-2'>
                    <img src={'/assets/sales-stats/reserved.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                    <p className='mb-2'>Total reserved</p>
                    <p className='font-bold'><span className='text-[#00b67a]'>{totalReserved}</span>/{requested}</p>
                </div>
            }
            {(processed || processed === 0) &&
                <div className='bg-white !rounded p-2'>
                    <img src={'/assets/sales-stats/reserved.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                    <p className='mb-2'>Processed</p>
                    <p className='font-bold'><span className='text-[#00b67a]'>{processed}</span>/{requested}</p>
                </div>
            }
            {(missingItems || missingItems === 0) &&
                <div className='bg-white !rounded p-2'>
                    <img src={'/assets/sales-stats/missing.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                    <p className='mb-2'>Missing items</p>
                    <p className='font-bold'><span className='text-[#e50000]'>{missingItems}</span>/{requested}</p>
                </div>
            }
            {(requestedItems || requestedItems === 0) &&
                <div className='bg-white !rounded p-2 col-span-2'>
                    <img src={'/assets/sales-stats/requested-items.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                    <p className='mb-2'>Requested items from other open orders</p>
                    <p className='font-bold text-[#1389e6]'>{requestedItems}</p>
                </div>
            }
            {(picked || picked === 0) &&
                <div className='bg-white !rounded p-2'>
                    <img src={'/assets/sales-stats/reserved.svg'} className={'object-contain w-[22px] min-w-[22px] mb-2'} />
                    <p className='mb-2'>Picked</p>
                    <p className='font-bold'><span className='text-[#00b67a]'>{picked}</span>/{requested}</p>
                </div>
            }
            {(scanned || scanned === 0) &&
                <div className='bg-white !rounded p-2 col-span-2 flex flex-row'>
                    <div className='bg-[#f8f9fd] py-2 px-3 mr-2 !rounded'>
                        <p className='mb-2'>Scanned</p>
                        <p className='font-bold ml-2'><span className='text-[#00b67a]'>{scanned || 0}</span>/{totalReserved}</p>
                    </div>
                    <div className='bg-[#f8f9fd] py-2 px-3 mr-2 !rounded'>
                        <p className='mb-2'>Matching</p>
                        <p className='font-bold text-[#00b67a] ml-2'>{matching || 0}</p>
                    </div>
                    <div className='bg-[#f8f9fd] py-2 px-3 !rounded'>
                        <p className='mb-2'>Not Matching</p>
                        <p className='font-bold text-[#e50000] ml-2'>{notMatching || 0}</p>
                    </div>
                </div>
            }
        </div>
    )
}
export default SaleDetailsStats