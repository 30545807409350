import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const ItemDetailsContainerLoading = () => {
    return (
        <WhiteContainer containerStyle='!rounded p-4'>
            <p className='font-bold text-[18px] mb-4'>Stock Item Details</p>
            <div className='min-w-[100%]'>
                {[...Array(10)].map((x, i) => {
                    return (
                        <>
                            <div className=' my-4 animate-pulse'>
                                <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                                <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                            </div>
                        </>
                    )
                })
                }
            </div>
            <div className=' my-4 animate-pulse'>
                <div className='h-25 bg-gray-200 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
            </div>
        </WhiteContainer>
    )
}

export default ItemDetailsContainerLoading;