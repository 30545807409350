import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToMarkAsReceived } from '../../../../store/inventory/broker/credit-notes/creditNotesSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';


interface IMarkAsReceivedModal {
    openModal: boolean;
    broker?: string;
    data?: any;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessfullySaved: () => void;
}

const MarkAsReceivedModal = ({
    openModal,
    broker,
    data,
    itemId,
    handleCloseModal,
    onSuccessfullySaved,
}: IMarkAsReceivedModal) => {
    const dispatch = useAppDispatch();
    const [invoiceNumber, setInvoiceNumber] = useState<any>();
    const [noteNumber, setNoteNumber] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();

    const onCloseModal = () => {
        setInvoiceNumber(undefined)
        setNoteNumber(undefined)
        setLoadingSubmit(false)
        setSubmitError(undefined)
        handleCloseModal();
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        if (!invoiceNumber || !noteNumber) {
            setSubmitError('Please fill all the fields to continue!')
            setLoadingSubmit(false)
            return;
        }
        setSubmitError(undefined)
        try {
            const data = {
                creditNoteNumber: noteNumber,
                referenceInvoiceNumber: invoiceNumber
            }
            await dispatch(tryToMarkAsReceived({ id: itemId, data })).unwrap();
            onSuccessfullySaved()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
    }

    const onChangeInputValues = (value: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'invoice':
                    setInvoiceNumber(value);
                    break;
                case 'credit':
                    setNoteNumber(value);
                    break;
            }
        }
    }

    return (
        <Modal
            open={openModal}
            showInRight
            title={'Mark as Received'}
            onClose={() => onCloseModal()}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Add details to mark this awaiting credit as received</p>
                </div>
                {submitError && <Error text={submitError} />}
                <div className='p-3 !rounded bg-[#f8f9fc] my-2'>
                    <p className='font-bold mb-3'>Credit Note</p>
                    <div className='grid grid-cols-2 gap-3 border-b-2 border-slate-200'>
                        <Input
                            label='Seller'
                            placeholder=' '
                            showValue={true}
                            disabled={true}
                            inputValue={data?.customer?.displayName}
                        />
                        <Input
                            label='Buyer'
                            placeholder=' '
                            showValue={true}
                            disabled={true}
                            inputValue={broker || undefined}
                        />
                        <Input
                            label='Credit Amount'
                            placeholder=' '
                            showValue={true}
                            disabled={true}
                            inputValue={`${data?.amount}`}
                        />
                        <Input
                            label='Order Type'
                            placeholder=' '
                            showValue={true}
                            disabled={true}
                            inputValue={data?.reference}
                        />
                        <Input
                            label='Reference Original Order'
                            placeholder=' '
                            showValue={true}
                            disabled={true}
                            inputValue={data?.referenceId}
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        <Input
                            label='Credit Note Number'
                            placeholder=' '
                            showValue={true}
                            inputUniqueName={'credit'}
                            inputValue={noteNumber}
                            onChangeInput={onChangeInputValues}
                        />
                        <Input
                            label='Reference Invoice Number'
                            placeholder=' '
                            showValue={true}
                            inputUniqueName='invoice'
                            inputValue={invoiceNumber}
                            onChangeInput={onChangeInputValues}
                        />
                    </div>
                </div>
                <div className='flex flex-row justify-center my-5'>
                    <Button
                        label={'Confirm'}
                        className={`${(!loadingSubmit && invoiceNumber && noteNumber) ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} min-w-[170px]`}
                        onClickButton={() => (!loadingSubmit && invoiceNumber && noteNumber) && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default MarkAsReceivedModal;