import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import {
    tryToChangeChildsLocation,
    tryToCheckChildStateAndLocation,
    tryToFetchAllWarehouseSections
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import WhiteContainer from '../../../../shared/white-container';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Error from '../../../../shared/error';
import Toast from '../../../../shared/toast';


const BoxLocation = () => {
    const dispatch = useAppDispatch();
    const [sections, setSections] = useState<any>();
    const [childsLocation, setChildsLocation] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [currentLocations, setCurrentLocations] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [findItemErrors, setFindItemErrors] = useState<any>();
    const [itemsError, setItemsError] = useState<any>();
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
        }
    }, [activeWarehouseState])

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: activeWarehouse?._id || '', type: 'custom' })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (activeWarehouse?._id) {
            onGettingWarehouseSections()
        }
    }, [activeWarehouse])

    const onAddNewRow = () => {
        const checkIfAnyEmptyRows = childsLocation && childsLocation?.length > 0 && childsLocation?.filter((item: any) => (!item || !(Object?.keys(item)?.length > 0) || !(item?.childLocation || item?.customSection)))
        if (childsLocation && childsLocation?.length > 0 && (!checkIfAnyEmptyRows || checkIfAnyEmptyRows?.length <= 0)) {
            setChildsLocation((prevItems: any) => (
                [
                    ...prevItems || [],
                    {}
                ]
            ))
        }
    }

    const onClickClearRow = (index: any) => {
        setChildsLocation((prevItems: any) => prevItems?.map((obj: any, idx: number) => idx === index ? {} : obj))
    }

    const onRemoveRow = (index: any) => {
        setChildsLocation((prevItems: any) => prevItems?.filter((obj: any, idx: number) => idx !== index))
    }

    const onGettingChildCurrentLocation = async (itemId: string) => {
        try {
            const response: any = await dispatch(tryToCheckChildStateAndLocation(itemId)).unwrap()
            if (response) {
                setCurrentLocations((prevLocations: any) => ([
                    ...prevLocations?.filter((item: any) => item?.itemId !== itemId) || [],
                    {
                        itemId: itemId,
                        location: response?.customSpace?.name || 'No Location'
                    }
                ]))
                setFindItemErrors((prevItemErrors: any) => ({
                    ...prevItemErrors || {},
                    [itemId]: true
                }))
            }
            else {
                setFindItemErrors((prevItemErrors: any) => ({
                    ...prevItemErrors || {},
                    [itemId]: 'Barcode is not valid for assignment to any Custom Section'
                }))
            }
        } catch (err) {
            setFindItemErrors((prevItemErrors: any) => ({
                ...prevItemErrors || {},
                [itemId]: 'Barcode doesn\'t exist'
            }))
        }
    }

    const onGettingResponseOfChildCurrentLocation = async (itemId: string) => {
        try {
            const response: any = await dispatch(tryToCheckChildStateAndLocation(itemId)).unwrap()
            if (response) {
                return true
            } else {
                return 'Barcode is not valid for assignment to any Custom Section'
            }
        } catch (err) {
            return 'Barcode doesn\'t exist'
        }
    }

    const onChangeSelectedCustomSection = (e: any, type?: string, index?: any) => {
        if ((index || index === 0) && type) {
            const findIfChildExists = childsLocation && childsLocation?.length > 0 && childsLocation?.find((item: any, idx: number) => (idx === index && item?.childLocation))
            if (findIfChildExists) {
                setChildsLocation((prevItems: any) => prevItems ? prevItems?.map((obj: any, idx: number) =>
                    idx === index ? { ...obj, [type]: e?.value, customSectionText: undefined } : obj
                ) : [{ [type]: e?.value }])
                if (!findItemErrors?.[findIfChildExists?.childLocation]) {
                    onGettingChildCurrentLocation(findIfChildExists?.childLocation)
                }
            }
        }
    }

    const onChangeChildLocation = (e: any, type?: string, index?: any) => {
        if ((index || index === 0) && type) {
            setChildsLocation((prevItems: any) => prevItems ? prevItems?.map((obj: any, idx: number) =>
                idx === index ? { ...obj, [type]: e } : obj
            ) : [{ [type]: e }])
        }
    }

    const onEnterRowChild = async (e: any, type?: string, index?: any) => {
        e.stopPropagation()
        if ((index || index === 0)) {
            if (e?.key === 'Enter') {
                const findItemId: any = (childsLocation && childsLocation?.length > 0) && childsLocation?.find((item: any, idx: number) => idx === index)
                const findIfCopyItemId: any = findItemId && (childsLocation && childsLocation?.length > 0) && childsLocation?.find((item: any, idx: number) => idx !== index && item?.childLocation === findItemId?.childLocation)
                if (findItemId && findItemId?.childLocation) {
                    if (!findIfCopyItemId) {
                        onGettingChildCurrentLocation(findItemId?.childLocation)
                        setItemsError(undefined)
                    }
                    else {
                        setItemsError((prevItemsError: any) => ({
                            ...prevItemsError || {},
                            childs: {
                                ...prevItemsError?.childs || {},
                                [index]: 'Two same barcodes are not allowed!'
                            }
                        }))
                    }
                }
            }
        }
    }

    const onSubmit = async () => {
        setSubmitLoading(true)
        try {
            const filterNonEmptyObjects = childsLocation && childsLocation?.length > 0 && childsLocation?.filter((item: any) => item && Object.keys(item)?.length > 0)
            const formatData = (filterNonEmptyObjects && filterNonEmptyObjects?.length > 0) && filterNonEmptyObjects?.map((item: any) => {
                return {
                    childLocation: item?.childLocation,
                    customSection: item?.customSection || item?.customSectionText
                }
            })
            await dispatch(tryToChangeChildsLocation(formatData)).unwrap()
            setShowToast({ type: 'success', message: 'Changes saved successfully' });
            setFindItemErrors(undefined)
            setItemsError(undefined)
            setSubmitError(undefined)
            setCurrentLocations(undefined)
            setChildsLocation(undefined)
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setSubmitLoading(false)
    }

    const onValidateSubmit = async () => {
        setSubmitLoading(true)
        const filterNoEmptyRows = childsLocation && childsLocation?.length > 0 && childsLocation?.filter((item: any) => item && Object.keys(item)?.length > 0)
        if (!filterNoEmptyRows || filterNoEmptyRows?.length <= 0) {
            setSubmitError('Please add at least one row to continue!')
            setSubmitLoading(false)
            return;
        }
        setSubmitError(undefined)
        let errors: any;
        if (childsLocation && childsLocation?.length > 0) {
            await Promise.all(childsLocation?.map(async (obj: any, index: number) => {
                if (obj?.childLocation || (obj?.customSection || obj?.customSectionText)) {
                    if (!obj?.childLocation) {
                        errors = {
                            ...errors || {},
                            childs: {
                                ...errors?.childs || {},
                                [index]: 'Custom Section Child Barcode is required!'
                            }
                        };
                    } else {
                        const matchingIndex = childsLocation?.findIndex((otherObj: any, otherIndex: number) => otherIndex !== index && Object.is(obj?.childLocation, otherObj?.childLocation));
                        if (matchingIndex !== -1) {
                            errors = {
                                ...errors || {},
                                childs: {
                                    ...errors?.childs || {},
                                    [index]: 'No duplicates allowed!'
                                }
                            };
                        } else {
                            if (findItemErrors?.[obj?.childLocation] && findItemErrors?.[obj?.childLocation] !== true) {
                                errors = {
                                    ...errors || {},
                                    childs: {
                                        ...errors?.childs || {},
                                        [index]: findItemErrors?.[obj?.childLocation]
                                    }
                                }
                            } else if (!findItemErrors?.[obj?.childLocation] && findItemErrors?.[obj?.childLocation] !== true) {
                                const getItem: any = await onGettingResponseOfChildCurrentLocation(obj?.childLocation)
                                if (getItem && getItem !== true) {
                                    errors = {
                                        ...errors || {},
                                        childs: {
                                            ...errors?.childs || {},
                                            [index]: getItem
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (!obj?.customSection && !obj?.customSectionText) {
                        errors = {
                            ...errors || {},
                            customSections: {
                                ...errors?.customSections || {},
                                [index]: 'Custom Section is required!'
                            }
                        };
                    } else if (obj?.customSectionText) {
                        const findIfSectionExists = sections && sections?.length > 0 && sections?.find((section: any) => section?.barcode === obj?.customSectionText)
                        if (!findIfSectionExists) {
                            errors = {
                                ...errors || {},
                                customSections: {
                                    ...errors?.customSections || {},
                                    [index]: 'Custom Section doesn\'t exist!'
                                }
                            };
                        }
                    }
                }
                return obj;
            }));
        }
        if (errors && Object?.keys(errors)?.length > 0) {
            setItemsError(errors)
            setSubmitError('Please fix errors first to continue')
            setSubmitLoading(false)
        } else {
            setSubmitError(undefined)
            setItemsError(undefined)
            onSubmit()
        }
    }

    const onScanCustomSection = (e: any, type?: string, index?: any) => {
        if ((index || index === 0) && type) {
            const findIfChildExists = childsLocation && childsLocation?.length > 0 && childsLocation?.find((item: any, idx: number) => (idx === index && item?.childLocation))
            if (findIfChildExists) {
                setChildsLocation((prevItems: any) => prevItems ? prevItems?.map((obj: any, idx: number) =>
                    idx === index ? { ...obj, customSectionText: e, customSection: undefined } : obj
                ) : [{ customSectionText: e }])
            }
        }
    }

    return (
        <WhiteContainer containerStyle='my-5'>
            <p className='my-4 text-primary-light'>Press Enter in Barcode input to get Current Custom Section Location of Child</p>
            {((childsLocation && childsLocation?.length > 0) ? childsLocation : [{}])?.map((item: any, idx: number) => {
                const currentChildLocation = currentLocations?.find((obj: any) => obj?.itemId === item?.childLocation)?.location
                const filterCustomSections = (currentChildLocation && sections && sections?.length > 0) ? sections?.filter((obj: any) => obj?.name !== currentChildLocation) : sections
                return (
                    <div key={idx} className='my-5 grid grid-cols-9 border-b'>
                        <Input
                            label='Barcode'
                            containerStyle='!col-span-2 !max-w-[99%]'
                            showValue={true}
                            inputUniqueName='childLocation'
                            index={idx}
                            inputValue={item?.childLocation}
                            error={itemsError?.childs?.[idx] || (findItemErrors?.[item?.childLocation] && findItemErrors?.[item?.childLocation] !== true ? findItemErrors?.[item?.childLocation] : undefined) || undefined}
                            onChangeInput={onChangeChildLocation}
                            onHandleKeyDown={onEnterRowChild}
                        />
                        <div className='min-h-[100%] h-[100%] !col-span-2 flex flex-col mx-6 min-w-[200px]'>
                            <p className='mb-1 text-[#a4a4a4] mb-4'>Current Location</p>
                            <p>{currentChildLocation || ''}</p>
                            <p></p>
                        </div>
                        <div className='!col-span-4'>
                            <p className='text-primary-light mb-1'>Write or Select Custom Section</p>
                            <div className='grid grid-cols-2'>
                                <Input
                                    containerStyle='!max-w-[99%]'
                                    showValue={true}
                                    inputUniqueName='customSectionText'
                                    index={idx}
                                    inputValue={item?.customSectionText}
                                    error={itemsError?.customSections?.[idx]}
                                    onChangeInput={onScanCustomSection}
                                />
                                <SelectCheckbox
                                    placeholder=' '
                                    index={idx}
                                    options={filterCustomSections || []}
                                    selectedOption={item?.customSection ? sections?.find((section: any) => section?.value === item?.customSection) || undefined : undefined}
                                    containerStyle='!max-w-[99%]'
                                    uniqueName='customSection'
                                    onChangeSelectedOption={onChangeSelectedCustomSection}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <Button
                                label='Clear'
                                className='btn-primary-text-underline mx-1'
                                onClickButton={() => onClickClearRow(idx)}
                            />
                            <div onClick={() => onRemoveRow(idx)} className='flex flex-row items-center'>
                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] hover:w-[17px] object-contain'} />
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className='flex flex-row justify-start my-5'>
                <Button
                    label='Add New Row'
                    className={(childsLocation && childsLocation?.length > 0) && !submitLoading ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => (childsLocation && childsLocation?.length > 0) && !submitLoading && onAddNewRow()}
                />
            </div>
            {submitError &&
                <div className='flex flex-row justify-center my-4'>
                    <Error text={submitError} />
                </div>
            }
            <div className='flex flex-row justify-end my-5'>
                <Button
                    label='Save Data'
                    className={(childsLocation && childsLocation?.length > 0) && !submitLoading ? 'btn-primary' : 'btn-primary-disable'}
                    onClickButton={() => (childsLocation && childsLocation?.length > 0) && !submitLoading && onValidateSubmit()}
                />
            </div>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </WhiteContainer>
    )
}
export default BoxLocation;