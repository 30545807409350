import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IInventoryListState } from '../../../../interfaces/inventory-data/IInventoryList';
import { InventoryService } from './inventoryDataApi';
import { AxiosError } from 'axios';


const initialState: IInventoryListState = {
    inventoryAreLoading: false,
    inventoryIsLoading: false,
    inventoryAggregatedIsLoading: false,
    inventoryAggregatedAreLoading: false,
    inventoryDetailsIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchInventoryData = createAsyncThunk<
    any,
    IPaginationPayload
>('inventory/tryToFetchInventoryData', async ({ pageNumber, pageSize, data }) => {
    const result = await InventoryService.tryToFetchInventoryData(
        pageNumber,
        pageSize,
        data,
    );
    return result;
});


export const tryToFetchInventoryAgreggatedData = createAsyncThunk<
    any,
    IPaginationPayload
>('inventory/tryToFetchInventoryAgreggatedData', async ({ pageNumber, pageSize, data }) => {
    const result = await InventoryService.tryToFetchInventoryAgreggatedData(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchInventoryAgreggatedDetails = createAsyncThunk<
    any,
    IPaginationPayload
>('inventory/tryToFetchInventoryAgreggatedDetails', async ({ pageNumber, pageSize, data }) => {
    const result = await InventoryService.tryToFetchInventoryAgreggatedDetails(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchSingleInventoryItem = createAsyncThunk<any, string>(
    'inventory/tryToFetchSingleInventoryItem',
    async (id) => {
        const result = await InventoryService.tryToFetchSingleInventoryItem(id);
        return result?.data;
    }
);

export const tryToFetchTotalInventoryItemsNumber = createAsyncThunk<any, string>(
    'inventory/tryToFetchTotalInventoryItemsNumber',
    async (id) => {
        const result = await InventoryService.tryToFetchTotalInventoryItemsNumber(id);
        return result?.data;
    }
);

export const tryToFetchItemDetails = createAsyncThunk<any, string>(
    'inventory/tryToFetchItemDetails',
    async (id, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToFetchItemDetails(id);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchInventoryFieldValues = createAsyncThunk<any, any>(
    'inventory/tryToFetchInventoryFieldValues',
    async ({ categoryId, data }) => {
        const result = await InventoryService.tryToFetchInventoryFieldValues(
            categoryId,
            data
        );
        return result?.data;
    }
);

export const tryToChangeInventoryItemDetails = createAsyncThunk<any, any>(
    'inventory/tryToChangeInventoryItemDetails',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToChangeInventoryItemDetails(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeleteInventoryItem = createAsyncThunk<any, any>(
    'inventory/tryToDeleteInventoryItem',
    async (id, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToDeleteInventoryItem(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDownloadInventorySample = createAsyncThunk<any, any>(
    'inventory/tryToDownloadInventorySample',
    async (data, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToDownloadInventorySample(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToImportInventoryData = createAsyncThunk<any, any>(
    'inventory/tryToImportInventoryData',
    async (data, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToImportInventoryData(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToImportInventorySalesGrade = createAsyncThunk<any, any>(
    'inventory/tryToImportInventorySalesGrade',
    async (data) => {
        const result = await InventoryService.tryToImportInventorySalesGrade(
            data
        );
        return result?.data;
    }
);

export const tryToImportInventoryCosts = createAsyncThunk<any, any>(
    'inventory/tryToImportInventoryCosts',
    async (data) => {
        const result = await InventoryService.tryToImportInventoryCosts(
            data
        );
        return result?.data;
    }
);

export const tryToAddInventoryLocation = createAsyncThunk<any, any>(
    'inventory/tryToAddInventoryLocation',
    async (data) => {
        const result = await InventoryService.tryToAddInventoryLocation(
            data
        );
        return result?.data;
    }
);

export const tryToChangeInventoryItemsLocation = createAsyncThunk<any, any>(
    'inventory/tryToChangeInventoryItemsLocation',
    async (data, { rejectWithValue }) => {
        try {
            const result = await InventoryService.tryToChangeInventoryItemsLocation(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchInventoryData
            .addCase(tryToFetchInventoryData.pending, (state) => {
                state.inventoryAreLoading = true;
            })
            .addCase(tryToFetchInventoryData.rejected, (state) => {
                state.inventoryAreLoading = false;
            })
            .addCase(tryToFetchInventoryData.fulfilled, (state, action) => {
                state.inventoryAreLoading = false;
                state.inventory = action.payload;
            })

            // tryToFetchInventoryAgreggatedData
            .addCase(tryToFetchInventoryAgreggatedData.pending, (state) => {
                state.inventoryAggregatedAreLoading = true;
            })
            .addCase(tryToFetchInventoryAgreggatedData.rejected, (state) => {
                state.inventoryAggregatedAreLoading = false;
            })
            .addCase(tryToFetchInventoryAgreggatedData.fulfilled, (state, action) => {
                state.inventoryAggregatedAreLoading = false;
                state.inventoryAggregated = action.payload;
            })

            // tryToFetchInventoryAgreggatedDetails
            .addCase(tryToFetchInventoryAgreggatedDetails.pending, (state) => {
                state.inventoryAreLoading = true;
            })
            .addCase(tryToFetchInventoryAgreggatedDetails.rejected, (state) => {
                state.inventoryAreLoading = false;
            })
            .addCase(tryToFetchInventoryAgreggatedDetails.fulfilled, (state, action) => {
                state.inventoryAreLoading = false;
                state.inventory = action.payload;
            })

            // tryToFetchSingleInventoryItem
            .addCase(tryToFetchSingleInventoryItem.pending, (state) => {
                state.inventoryDetailsIsLoading = true;
                state.inventoryDetails = undefined;
            })
            .addCase(tryToFetchSingleInventoryItem.rejected, (state) => {
                state.inventoryDetailsIsLoading = false;
            })
            .addCase(tryToFetchSingleInventoryItem.fulfilled, (state, action) => {
                state.inventoryDetailsIsLoading = false;
                state.inventoryDetails = action.payload?.data;
            })

            // tryToFetchTotalInventoryItemsNumber
            .addCase(tryToFetchTotalInventoryItemsNumber.fulfilled, (state, action) => {
                state.inventoryAreLoading = false;
                state.totalItems = action.payload?.data?.itemCount || 0;
                state.categoryItems = action.payload?.data?.categoryCount || [];
            })

            // tryToAddInventoryLocation
            .addCase(tryToAddInventoryLocation.pending, (state) => {
                state.inventoryIsLoading = true;
            })
            .addCase(tryToAddInventoryLocation.rejected, (state) => {
                state.inventoryIsLoading = false;
            })
            .addCase(tryToAddInventoryLocation.fulfilled, (state, action) => {
                state.inventoryIsLoading = false;
                // state.inventory = action.payload;
                if (state.inventory?.data.elements)
                    state.inventory.data.elements =
                        { ...state }.inventory?.data.elements.map(
                            (location) => {
                                if (location?.itemId === action?.meta?.arg?.itemId) {
                                    return {
                                        ...location || {},
                                        locations: action?.meta?.arg?.locations,
                                        warehouse: action?.meta?.arg?.warehouse?.id
                                    }
                                } else {
                                    return location
                                }
                            }
                        ) || [];
            })

            // tryToChangeInventoryItemDetails
            .addCase(tryToChangeInventoryItemDetails.pending, (state) => {
                state.inventoryAggregatedAreLoading = true;
            })
            .addCase(tryToChangeInventoryItemDetails.rejected, (state) => {
                state.inventoryAggregatedAreLoading = false;
            })
            .addCase(tryToChangeInventoryItemDetails.fulfilled, (state, action) => {
                state.inventoryAggregatedAreLoading = false;
                state.inventoryDetails = action?.payload?.data;
            })

            // tryToDeleteInventoryItem
            .addCase(tryToDeleteInventoryItem.pending, (state) => {
                state.inventoryAggregatedAreLoading = true;
            })
            .addCase(tryToDeleteInventoryItem.rejected, (state) => {
                state.inventoryAggregatedAreLoading = false;
            })
            .addCase(tryToDeleteInventoryItem.fulfilled, (state, action) => {
                state.inventoryAggregatedAreLoading = false;
                state.inventoryDetails = undefined;
            })
    },
});

export default inventorySlice.reducer;
