import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToAddCredit,
    tryToAddTransaction,
    tryToGetCustomers
} from '../../../store/inventory/broker/credit-managment/creditManagmentSlice';
import { tryToFindOperatingMarkets } from '../../../store/inventory/shared/partners/partnersSlice';
import CreditManagmentTable from './tables/CreditManagmentTable';
import AddCreditModal from './modals/AddCreditModal';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Toast from '../../../shared/toast';
import Modal from '../../../shared/modal';


const CreditManagment = () => {
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10
    });
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<string>();
    const [selectedBrokerCustomers, setSelectedBrokerCustomers] = useState<any>();
    const [creditItem, setCreditItem] = useState<any>({ customerId: undefined, totalBalance: 0, expiryDate: undefined })
    const [showToast, setShowToast] = useState<IToast | null>();
    const [errors, setErrors] = useState<any>();
    const [managmentsCustomer, setManagmentsCustomers] = useState<any>([])
    const [markets, setMarkets] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [type, setType] = useState<string>('')
    const [selectedItem, setSelectedItem] = useState<any>('')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>(0)
    const [error, setError] = useState<string>('')
    const [loadingTransaction, setLoadingTransaction] = useState(false)

    const fetchMarkets = async () => {
        try {
            const data = await dispatch(tryToFindOperatingMarkets(null)).unwrap()
            const formatData = data.map(item => {
                return {
                    label: item?.market?.label,
                    value: item?.market?.label,
                    id: item?.market?._id,
                }
            })
            setMarkets(formatData)
        } catch (error) {
            // error here
        }
    }

    const fetchCustomers = async () => {
        try {
            const data = await dispatch(tryToGetCustomers()).unwrap()
            const formatCustomer = data?.data?.map((item: any) => { return { ...item, value: item?._id, label: item?.displayName } })
            setManagmentsCustomers(formatCustomer)
        } catch (error) {
            // error here
        }
    }

    useEffect(() => {
        fetchMarkets()
        fetchCustomers()
    }, [])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
        searchParams.set('page', '1')
        searchParams.set('size', `${paginationState?.pageSize || 10}`)
        setSearchParams(searchParams)
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        if (value) searchParams.set('search', value)
        else searchParams.delete('search')
        onResetPaginationState()
    }

    const onClearAllFilters = () => {
        setSearch('')
    }

    const onSuccessfullyAddedCredit = () => {
        setShowToast({ type: 'success', message: 'Credit added successfully' });
        setLoading(false)
        setShowAddModal(false)
        setCreditItem(undefined)
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            let isValid = true
            let errors
            if (!creditItem?.customerId || creditItem?.customerId === '') {
                isValid = false
                errors = { ...errors || {}, customerId: 'Customer is required' }
            }
            if (!creditItem?.totalBalance || creditItem?.totalBalance === '') {
                isValid = false
                errors = { ...errors || {}, totalBalance: 'Total balance is required' }
            }
            if (!creditItem?.marketId || creditItem?.marketId === '') {
                isValid = false
                errors = { ...errors || {}, market: 'Market is required' }
            }
            if (isValid && creditItem) {
                const data = await dispatch(tryToAddCredit(creditItem)).unwrap()
                if (data?.response?.data?.error) {
                    setShowToast({ type: 'error', message: `${data?.response?.data?.error}` });
                    setLoading(false)
                } else {
                    onSuccessfullyAddedCredit()
                }
            } else {
                setErrors(errors)
            }
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
            setLoading(false)
        }
        setLoading(false)
    }

    const onPressAdd = () => {
        setShowAddModal(true)
    }

    const closeModal = () => {
        setShowAddModal(false)
        setCreditItem(undefined)
        setSelectedMarket(undefined)
        setSelectedBrokerCustomers(undefined)
        setErrors('')
    }

    const onChangeSelectedCustomer = (e: any) => {
        setSelectedBrokerCustomers(e)
        setCreditItem({ ...creditItem, customerId: e._id })
    }

    const onChangeMarket = (e: any) => {
        setSelectedMarket(e)
        setCreditItem({ ...creditItem, marketId: e?.id })
    }

    const onChangeInput = (value: string | null | undefined, type: string) => {
        setCreditItem({ ...creditItem, [type]: value })
    }

    const onSeeCreditManagmentDetails = (id: string) => {
        navigator(`/credit-management/${id}`)
    }

    const onChangeAmount = (e: any) => {
        setAmount(e)
    }

    const onAddTransaction = async () => {
        if (!selectedItem) {
            return
        }
        setLoadingTransaction(true)
        let isValid = true
        if (!amount) {
            isValid = false
            setError('Amount is required')
        }
        if (amount <= 0) {
            isValid = false
            setError('Amount should not be 0 or below')
        }
        if (type === 'withdraw' && amount > selectedItem?.outStandingCredit) {
            isValid = false
            setError('Amount should not be higher than balance')
        }
        try {
            if (isValid) {
                setError('')
                const body = {
                    creditManagementId: selectedItem?._id,
                    amount: amount,
                    type: type
                }
                await dispatch(tryToAddTransaction(body)).unwrap()
                setLoadingTransaction(false)
                setShowModal(false)
                setType('')
                setSelectedItem(undefined)
                setAmount(0)
                setError('')
            }
        } catch (error: any) {
            setError(`${error?.response?.data || error?.response || error}`)
            setLoadingTransaction(false)
        }
        setLoadingTransaction(false)
    }

    const onPressDepositOrWithdraw = (type: string, item: any) => {
        setType(type)
        setSelectedItem(item)
        setShowModal(true)
    }

    const onCloseDepositOrWithdrawModal = () => {
        setShowModal(false)
        setError('')
        setSelectedItem(undefined)
        setType('')
        setAmount(0)
        setLoadingTransaction(false)
    }

    return (
        <div>
            <div className='mb-10'>
                <p className='page-title mb-10'>Credit Management</p>
            </div>
            <div className={`flex flex-row items-center justify-between bg-[#f8f0e6] mt-3 mb-2 p-1 !rounded`}>
                <div className='flex flex-row justify-between'>
                    <div>
                        <Input
                            placeholder={'Search Credit'}
                            dataQa={'search'}
                            containerStyle='mb-0 pb-0 relative min-w-[400px]'
                            inputStyle={`mb-0 border-0 focus:outline-none`}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={search}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                    <div className='flex flex-row justify-end ml-5'>
                        <Button
                            label='Clear Filters'
                            className={'btn-primary-text-underline min-w-auto'}
                            onClickButton={() => onClearAllFilters()}
                        />
                    </div></div>
                <Button label={'Add Credit '} onClickButton={onPressAdd} />
            </div>
            <CreditManagmentTable
                search={search}
                loading={loading}
                loadingTransaction={loadingTransaction}
                onSeeCreditManagmentDetails={onSeeCreditManagmentDetails}
                onClickAction={onPressDepositOrWithdraw} />
            <AddCreditModal
                openModal={showAddModal}
                handleCloseModal={closeModal}
                brokerCustomers={managmentsCustomer}
                selectedBrokerCustomers={selectedBrokerCustomers}
                onChangeSelectedCustomer={onChangeSelectedCustomer}
                creditItem={creditItem}
                onChangeInput={onChangeInput}
                onSubmit={onSubmit}
                errors={errors}
                selectedMarket={selectedMarket}
                onChangeMarket={onChangeMarket}
                markets={markets}
            />
            <Modal open={showModal} onClose={onCloseDepositOrWithdrawModal} showInRight>
                <Input
                    containerStyle='mb-0 w-[400px]'
                    label={`Amount to ${type === 'deposit' ? 'Deposit' : 'Withdraw'}`}
                    dataQa={'totalBalance'}
                    showValue={true}
                    inputValue={amount}
                    onChangeInput={onChangeAmount}
                    inputUniqueName={'totalBalance'}
                    error={error}
                    type='number'
                />
                <div className='mt-4'>
                    <Button
                        label={type === 'deposit' ? 'Deposit' : 'Withdraw'}
                        onClickButton={onAddTransaction} />
                </div>
            </Modal>
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div>
    )
}
export default CreditManagment;