import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import {
    tryToFetchAllWarehouseSections,
    tryToFetchWarehouseLocations
} from '../../../../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import WarehouseLocationsTable from '../tables/WarehouseLocationsTable';
import LocationBarcodeModal from '../modals/LocationBarcodeModal';
import LocationsFilters from '../details/LocationsFilters';
import SearchWithButton from '../../../../shared/search-with-button';
import Toast from '../../../../shared/toast';
import ChildBarcodeModal from '../modals/ChildBarcodeModal';


const LocationsTab = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [search, setSearch] = useState<string>();
    const [sections, setSections] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [selectedSection, setSelectedSection] = useState<any>();
    const [selectedLocationId, setSelectedLocationId] = useState<string>();
    const [selectedBarcodeId, setSelectedBarcodeId] = useState<string>();
    const [showBarcodeModal, setShowBarcodeModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [showChildBarcodeModal, setChildShowBarcodeModal] = useState<{ show: boolean, barcode: string }>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const state = useAppSelector((state) => state.warehouseLocations);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const rowsLoading = useAppSelector((state) => state?.warehouseLocations?.locationsAreLoading);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);
    const paginationData = useAppSelector((state) => state?.warehouseLocations?.warehouseLocations?.data?.page);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Locations Settings')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
    };

    useEffect(() => {
        if (state.warehouseLocations) {
            const rows = state.warehouseLocations?.data?.elements || [];
            setRows(rows);
        }
    }, [state.warehouseLocations]);

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    const onGettingWarehouseLocationsData = async () => {
        try {
            if (activeWarehouse) {
                let data: any = {
                    ...(paginationState || {}),
                    id: activeWarehouse?._id || ''
                };
                if (selectedSection) {
                    data = {
                        ...(data || {}),
                        data: {
                            ...data?.data || {},
                            spaceId: selectedSection?.value
                        }
                    };
                }
                if (search) {
                    data = {
                        ...(data || {}),
                        filters: search,
                    };
                }
                await dispatch(tryToFetchWarehouseLocations(data)).unwrap();
            }
        } catch (err) {
            // err here
        }
    };

    const onGettingWarehouseSections = async () => {
        try {
            const response = await dispatch(tryToFetchAllWarehouseSections({ warehouseId: activeWarehouse?._id || '' })).unwrap()
            const formatResponse = response && response?.length > 0 && response?.map((item: any) => ({ ...item, value: item?._id, label: item?.name }))
            setSections(formatResponse || undefined)
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (activeWarehouse?._id) {
            onGettingWarehouseSections()
        }
    }, [activeWarehouse])

    useEffect(() => {
        onGettingWarehouseLocationsData();
    }, [paginationState]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        onResetPaginationSettings();
    };

    const onClearAllFilters = () => {
        setSearch(undefined)
        setSelectedSection(undefined)
        onResetPaginationSettings()
    }

    const onCloseBarcodeModal = () => {
        setShowBarcodeModal(false)
        setSelectedBarcodeId(undefined)
        setSelectedLocationId(undefined)
    }

    const onEditLocationBarcode = (id: string, barcode: string) => {
        setSelectedBarcodeId(barcode)
        setSelectedLocationId(id)
        setShowBarcodeModal(true)
    }

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message })
    }

    const onChangeSelectedFilter = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'section':
                    setSelectedSection(e);
                    break;
            }
            onResetPaginationSettings()
        }
    }

    const onClickShowCustomSpaceChildBarcode = (barcode: string) => {
        setChildShowBarcodeModal({
            show: true,
            barcode: barcode
        })
    }

    return (
        <div className='my-4'>
            <div className='mb-2'>
                <LocationsFilters
                    sections={sections}
                    selectedSection={selectedSection}
                    onSelectFilter={onChangeSelectedFilter}
                />
            </div>
            <div className='my-1'>
                <SearchWithButton
                    placeholder='Filter table'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search}
                    onClickButton={onClearAllFilters}
                    mainContainerStyle={'!grid-cols-10'}
                    buttonLabel='Clear All Filters'
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'}
                />
                <WarehouseLocationsTable
                    rows={rows}
                    paginationData={paginationData}
                    rowsLoading={rowsLoading}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    onEditBarcode={onEditLocationBarcode}
                    setPaginationState={setPaginationState}
                    onClickShowCustomSpaceChildBarcode={onClickShowCustomSpaceChildBarcode}
                />
            </div>
            {showBarcodeModal &&
                <LocationBarcodeModal
                    openModal={showBarcodeModal}
                    itemId={selectedLocationId || ''}
                    barcode={selectedBarcodeId || ''}
                    onSaveChanges={onSaveChanges}
                    handleCloseModal={onCloseBarcodeModal}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showChildBarcodeModal?.show &&
                <ChildBarcodeModal
                    openModal={showChildBarcodeModal?.show}
                    barcode={showChildBarcodeModal?.barcode || ''}
                    handleCloseModal={() => setChildShowBarcodeModal(undefined)}
                />
            }
        </div>
    )
}

export default LocationsTab