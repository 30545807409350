import React from 'react';
import { PURCHASE_ORDER_STATUS } from '../../../../utils/constants/purchase-order-status';
import { VAT_TYPE_ITEMS } from '../../../../utils/constants/vat-types';
import AddCustomerBtn from './AddCustomerBtn';
import Input from '../../../../shared/input';
import Datepicker from '../../../../shared/datepicker';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IFieldsStep {
    fieldsData?: any;
    formData?: any;
    formErrors?: any;
    partners?: any;
    brokerCustomers?: Array<any>;
    markets?: Array<any>;
    selectedMarket?: any;
    isSale?: boolean;
    isBatch?: boolean;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeDateInput: (value: string | null | undefined, type: string) => void;
    getBrokerCustomersAndBanks: () => void
}

const FieldsStep = ({
    fieldsData,
    partners,
    brokerCustomers,
    formData,
    formErrors,
    markets,
    selectedMarket,
    isSale,
    isBatch,
    onChangeDateInput,
    onChangeInput,
    onChangeSelectedValue,
    getBrokerCustomersAndBanks
}: IFieldsStep) => {
    return (
        <div className='bg-white p-3 border-b-2 border-[#e4e4e4]'>
            <p className='font-bold mb-2'>Purchase Details</p>
            <p className='mb-4'>Please fill details on the form below.</p>
            <div className={'grid grid-cols-4 gap-4'}>
                <SelectCheckbox
                    name='Broker'
                    placeholder={formData?.partner ? partners?.find((item: any) => item?.username === formData?.partner?.username)?.companyName || '' : ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={partners || []}
                    selectedOption={fieldsData?.partner}
                    uniqueName={'partner'}
                    disabled={isSale || false}
                    error={formErrors?.partner}
                />
                <div className='flex'>
                    <SelectCheckbox
                        name='Supplier'
                        placeholder={formData?.customer?.displayName || ''}
                        onChangeSelectedOption={onChangeSelectedValue}
                        options={brokerCustomers || []}
                        selectedOption={fieldsData?.customer}
                        uniqueName={'customer'}
                        disabled={isSale || false}
                        error={formErrors?.customer}
                    />
                    <AddCustomerBtn getBrokerCustomersAndBanks={getBrokerCustomersAndBanks} />
                </div>
                <Datepicker
                    label={isBatch ? 'Due Date (Optional)' : 'Due Date'}
                    placeholder=' '
                    containerStyle='mb-0'
                    inputValue={formData?.dueDate}
                    onChangeInput={onChangeDateInput}
                    inputUniqueName={'dueDate'}
                    error={formErrors?.dueDate}
                />
                <SelectCheckbox
                    name='Status'
                    placeholder={formData?.status || ''}
                    disabled={true}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={PURCHASE_ORDER_STATUS || []}
                    selectedOption={fieldsData?.status}
                    uniqueName={''}
                    error={formErrors?.status}
                />
                <Input
                    label={'Order Creator'}
                    containerStyle='mb-0'
                    showValue={true}
                    disabled={true}
                    inputValue={formData?.orderCreator}
                    inputUniqueName={'orderCreator'}
                    error={formErrors?.orderCreator}
                />
                <SelectCheckbox
                    name='Stock VAT'
                    placeholder={formData?.stockVat || ''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={VAT_TYPE_ITEMS || []}
                    selectedOption={fieldsData?.stockVat}
                    uniqueName={'stockVat'}
                    error={formErrors?.stockVat}
                />
                <SelectCheckbox
                    name='Market'
                    placeholder={''}
                    onChangeSelectedOption={onChangeSelectedValue}
                    options={markets || []}
                    selectedOption={selectedMarket}
                    uniqueName={'market'}
                    error={formErrors?.market}
                />
                <Input
                    label={isBatch ? 'Warranty (Days) (Optional)' : 'Warranty (Days)'}
                    containerStyle='mb-0'
                    showValue={true}
                    type={'number'}
                    inputValue={formData?.warranty}
                    onChangeInput={onChangeInput}
                    inputUniqueName={'warranty'}
                    error={formErrors?.warranty}
                />
            </div>
        </div>
    )
}
export default FieldsStep;