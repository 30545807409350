import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { ICreditNoteDetails } from '../../../interfaces/credit-notes/ICreditNoteDetails';
import { tryToFetchUserData } from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchCreditNoteDetails } from '../../../store/inventory/broker/credit-notes/creditNotesSlice';
import CreditNoteDetailsTable from './tables/CreditNoteDetailsTable';
import MarkAsReceivedModal from './modals/MarkAsReceivedModal';
import MainOrderModal from '../../../shared/main-order-modal';
import MarkAsUsedModal from './modals/MarkAsUsedModal';
import NoteDetailsInfo from './details/NoteDetailsInfo';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const CreditNoteDetails = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const [pageAccess, setPageAccess] = useState<any>();
    const [creditNoteDetails, setCreditNoteDetails] = useState<ICreditNoteDetails>();
    const [showReceivedModal, setShowReceivedModal] = useState<boolean>(false);
    const [showUsedModal, setShowUsedModal] = useState<boolean>(false);
    const [showMainOrderModal, setShowMainOrderModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<IToast | null>();
    const [brokerCategories, setBrokerCategories] = useState<any>();
    const state = useAppSelector((state) => state.creditNotes);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const user = useAppSelector((state) => state?.partners?.userData)

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Credit Notes')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Credit Notes Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.creditNoteDetails) {
            setCreditNoteDetails(state.creditNoteDetails)
        } else {
            setCreditNoteDetails(undefined)
        }
    }, [state.creditNoteDetails])

    const getBrokerSectors = async () => {
        const response = user;
        const formatSectors: any = (response?.sectors?.length > 0) ? response?.sectors?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, value: s?.name })) : [];
        setBrokerCategories(formatSectors || []);
    };

    useEffect(() => {
        if (user) {
            getBrokerSectors();
        }
    }, [user]);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchCreditNoteDetails(id))
            if (!user) {
                dispatch(tryToFetchUserData())
            }
        }
    }, [id])

    const onGoBack = () => {
        navigator(-1)
    }

    const onClickMarkAsUsed = () => {
        if (showUsedModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowUsedModal(!showUsedModal)
    }

    const onClickMarkAsReceived = () => {
        if (showReceivedModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowReceivedModal(!showReceivedModal)
    }

    const onSuccessfullyReceived = () => {
        setShowToast({ type: 'success', message: 'Credit Note was successfully marked as received!' })
    }

    const onSuccessfullyUsed = () => {
        setShowToast({ type: 'success', message: 'Credit Note was successfully marked as used!' })
    }

    const onClickShowMainView = () => {
        if (showMainOrderModal) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
        setShowMainOrderModal(!showMainOrderModal)
    }

    return (
        <div>
            <div className='flex flex-row items-center justify-between w-full'>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                        <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Credit Note Details</p>
                </div>
                <div className='flex flex-row items-center'>
                    {creditNoteDetails && (['awaiting_credit']?.includes(creditNoteDetails?.status || '')) &&
                        <Button
                            label={'Mark as Received'}
                            className={`${(!accessControl || pageAccess?.actions?.['Mark as Received']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as Received']) && onClickMarkAsReceived()}
                        />
                    }
                    {creditNoteDetails && (['received_credit']?.includes(creditNoteDetails?.status || '')) &&
                        <Button
                            label={'Mark as Used'}
                            className={`${(!accessControl || pageAccess?.actions?.['Mark as Used']) ? 'btn-primary' : 'btn-primary-disable'} cursor-pointer min-w-[170px]`}
                            onClickButton={() => (!accessControl || pageAccess?.actions?.['Mark as Used']) && onClickMarkAsUsed()}
                        />
                    }
                </div>
            </div>
            <NoteDetailsInfo
                noteDetails={creditNoteDetails}
                onClickShowMainView={onClickShowMainView}
            />
            <CreditNoteDetailsTable
                rows={creditNoteDetails?.items || []}
                status={creditNoteDetails?.status}
                accessControl={accessControl}
                actions={pageAccess?.actions}
            />
            {showReceivedModal &&
                <MarkAsReceivedModal
                    openModal={showReceivedModal}
                    itemId={id || ''}
                    broker={user?.broker || user?.username}
                    data={creditNoteDetails}
                    onSuccessfullySaved={onSuccessfullyReceived}
                    handleCloseModal={onClickMarkAsReceived}
                />
            }
            {showUsedModal &&
                <MarkAsUsedModal
                    openModal={showUsedModal}
                    itemId={id || ''}
                    onSuccessfullySaved={onSuccessfullyUsed}
                    handleCloseModal={onClickMarkAsUsed}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
            {showMainOrderModal &&
                <MainOrderModal
                    openModal={showMainOrderModal}
                    id={id || ''}
                    categories={brokerCategories}
                    handleCloseModal={onClickShowMainView}
                />
            }
        </div>
    )
}
export default CreditNoteDetails;