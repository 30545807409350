import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IItemIdSearchList } from '../../../../interfaces/item-id-search/IItemIdSearchList';
import { SearchItemIdService } from './itemIdSearchApi';

const initialState: IItemIdSearchList = {
    itemIdSearchIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchItemId = createAsyncThunk<any, string>(
    'search-item-id/tryToFetchItemId',
    async (imei, { rejectWithValue }) => {
        try {
            const result = await SearchItemIdService.tryToFetchItemId(imei);
            return result.data;
        }
        catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const itemIdSearchSlice = createSlice({
    name: 'imeiSearch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchItemId
            .addCase(tryToFetchItemId.pending, (state) => {
                state.itemIdSearchIsLoading = true;
            })
            .addCase(tryToFetchItemId.rejected, (state) => {
                state.itemIdSearchIsLoading = false;
            })
            .addCase(tryToFetchItemId.fulfilled, (state, action) => {
                state.itemIdSearchIsLoading = false;
                state.itemIdSearch = action.payload;
            })
    },
});

export default itemIdSearchSlice.reducer;