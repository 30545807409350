import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Item ID', 'Purchase Order ID', 'Item Code/IMEI', 'Item Category', 'Item Name','Market', 'Warehouse','Purchase Date', 'Purchase Channel' ,'Supplier Name', 'VAT Type', 'Currency', 'Price Without Boost', 'WingPay Boost', 'Item Purchase Price', 'Multiple Cashout Boost', 'Gesture', 'Total Purchase Cost', 'GBP Total Purchase Cost', 'FX Rate', 'Additional VA Costs','Total Item Cost', 'GBP Total Item Cost', 'Sales Price', 'Current Item Value (GBP)', 'FX Rate (rate on the generated date)',  `Supplier's Address`, `Supplier's Email`]

interface IPurchasesTable {
    rows: any[];
    rowsLoading: boolean;
    paginationData?: IPage;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const PurchasesTable = ({
    rows,
    rowsLoading,
    paginationData,
    setPaginationState,
}: IPurchasesTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4'>{row?.itemId || ''}</td>
                                <td className='px-6 py-4'>{row?.orderId || ''}</td>
                                <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                <td className='px-6 py-4'>{row?.category || ''}</td>
                                <td className='px-6 py-4 min-w-[170px]'>{row?.itemName || ''}</td>
                                <td className='px-6 py-4'>{row?.market || ''}</td>
                                <td className='px-6 py-4'>{row?.warehouse || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseDate && moment(row?.purchaseDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                <td className='px-6 py-4'>{row?.source || ''}</td>
                                <td className='px-6 py-4'>{row?.customerName || ''}</td>
                                <td className='px-6 py-4'>{row?.vatType || ''}</td>
                                <td className='px-6 py-4'>{row?.currency || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePriceWithoutBoost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.boost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.purchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.multipleCashoutPriceBoost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gestureAmount || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.totalPurchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpTotalPurchasePrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.exchangeValue || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.additionalCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.totalItemCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpTotalItemCost || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.salesPrice || ''}</td>
                                <td className='px-6 py-4'>{Number(row?.gbpPrice || 0).toFixed(2)}</td>
                                <td className='px-6 py-4'>{row?.exchangeValue || ''}</td>
                                <td className='px-6 py-4'>{row?.customerAddress || ''}</td>
                                <td className='px-6 py-4'>{row?.customerEmail || ''}</td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table >
    )
}

export default PurchasesTable;