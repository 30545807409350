import React, { useEffect, useState } from 'react';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Datepicker from '../../../../shared/datepicker';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDownloadDataModal {
    openDownloadModal: boolean;
    sectors?: any;
    partners?: any;
    markets?: any;
    warehouses?: any;
    currency?: any;
    vat?: any;
    hideFilters?: boolean;
    hideDates?: boolean;
    downloadError?: string;
    setDownloadError: any;
    loadingDownload: boolean;
    showCategory?: boolean;
    setLoadingDownload?: any;
    onDownloadData: (filters?: any) => void;
    handleCloseDownloadModal: () => void;
    cashouts?: boolean;
}

const DownloadDataModal = ({
    openDownloadModal,
    sectors,
    markets,
    hideFilters,
    hideDates = false,
    downloadError,
    setDownloadError,
    loadingDownload,
    setLoadingDownload,
    onDownloadData,
    handleCloseDownloadModal,
    cashouts,
    warehouses,
    showCategory = true
}: IDownloadDataModal) => {
    const [selectedSector, setSelectedSector] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const [dateFiltersValues, setDateFiltersValues] = useState<any>();
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>()
    const [includeTestOrders, setIncludeTestOrders] = useState<boolean>(false);
    const [dateError, setDateError] = useState<string>('')

    const onChangeSelectedFilter = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'sector':
                    setSelectedSector(e);
                    break;
                case 'market':
                    setSelectedMarket(e);
                    break;
                case 'warehouse':
                    setSelectedWarehouse(e);
                    break;
            }
        }
    }

    const onCloseModal = () => {
        setSelectedMarket(undefined)
        setSelectedSector(undefined)
        setSelectedWarehouse(undefined)
        setDateFiltersValues(undefined)
        setIncludeTestOrders(false)
        setLoadingDownload(false)
        setDownloadError(undefined)
        setDateError('')
        handleCloseDownloadModal()
    };

    useEffect(() => {
        if (!openDownloadModal) {
            setSelectedMarket(undefined)
            setSelectedSector(undefined)
            setSelectedWarehouse(undefined)
            setDateFiltersValues(undefined)
            setIncludeTestOrders(false)
            setLoadingDownload(false)
            setDownloadError(undefined)
            setDateError('')
        }
    }, [openDownloadModal]);

    const validateDates = () => {

        const today = new Date();
        if (dateFiltersValues?.from) {
            const fromDate = new Date(dateFiltersValues.from);
            if (fromDate > today) {
                setDateError('"From" date cannot be in the future.');
                return false;
            }
        }
        if (dateFiltersValues?.to) {
            const toDate = new Date(dateFiltersValues.to);
            if (toDate > today) {
                setDateError('"To" date cannot be in the future.');
                return false;
            }
        }
        if (dateFiltersValues?.from && dateFiltersValues?.to) {
            const fromDate = new Date(dateFiltersValues.from);
            const toDate = new Date(dateFiltersValues.to);
            if (toDate < fromDate) {
                setDateError('"To" date must be later than "From" date.');
                return false;
            }


        }
        setDateError('');
        return true;
    };

    const onChangeDateFilters = (value: string | null | undefined, type: string) => {
        setDateFiltersValues({
            ...dateFiltersValues,
            [type]: value || undefined
        });

        if ((type === 'from' && dateFiltersValues?.to) || (type === 'to' && dateFiltersValues?.from) ) {
            validateDates();
        }

    };

    const onDownloadTemplate = async () => {
        if (!validateDates()) {
            return
        }
        setLoadingDownload(true);
        let formatBody: any = {};

        if (selectedSector) {
            formatBody = {
                ...formatBody,
                category: selectedSector?.name || '',
                catalogId: selectedSector?._id || ''
            };
        }
        if (selectedMarket) {
            formatBody = {
                ...formatBody || {},
                market: selectedMarket?.name || '',
                marketId: selectedMarket?._id || ''
            }
        }
        if (dateFiltersValues && !hideFilters) {
            formatBody = {
                ...formatBody || {},
                dateRange: dateFiltersValues
            }
        }
        if (dateFiltersValues && hideFilters) {
            formatBody = {
                ...formatBody || {},
                range: dateFiltersValues
            }
        }
        if(selectedWarehouse){
            formatBody = {
                ...formatBody || {},
                 warehouse: selectedWarehouse?._id
            }
        }
        onDownloadData(formatBody)
    }

    return (
        <Modal
            open={openDownloadModal}
            onClose={onCloseModal}
            contentContainerStyle={'!min-w-[450px]'}
        >
            <div className={'p-2 min-w-[400px]'}>
                <p className='mb-2 font-semibold text-sm'>Download Data</p>
                <p className='mb-4 text-sm'>Use filters to download data.</p>
                {downloadError && <Error text={downloadError} />}
                {dateError && <Error text={dateError} />}
                <div className='flex flex-col items-center'>
                    {!hideFilters &&
                        <>
                            {showCategory && <SelectCheckbox
                                name='Category'
                                containerStyle='mb-1 min-w-[90%]'
                                dataQa={'category-selected'}
                                selectedOption={selectedSector}
                                placeholder=' '
                                uniqueName='sector'
                                onChangeSelectedOption={onChangeSelectedFilter}
                                options={sectors || []}
                            />}
                            <SelectCheckbox
                                name='Warehouse'
                                containerStyle='mb-1 min-w-[90%]'
                                dataQa={`${(selectedWarehouse?.name || '')?.toLowerCase().replaceAll(' ', '-')}`}
                                selectedOption={selectedWarehouse}
                                placeholder=' '
                                uniqueName='warehouse'
                                onChangeSelectedOption={onChangeSelectedFilter}
                                options={warehouses || []}
                            />
                            {/* <SelectCheckbox
                                name='Market'
                                containerStyle='mb-1 min-w-[90%]'
                                dataQa={'market-selecte'}
                                selectedOption={selectedMarket}
                                placeholder=' '
                                uniqueName='market'
                                onChangeSelectedOption={onChangeSelectedFilter}
                                options={markets || []}
                            /> */}
                        </>
                    }
                    {!hideDates &&
                        <>
                            <Datepicker
                                label='Date From'
                                placeholder=' '
                                dataQa={'date-from'}
                                containerStyle='mb-1 min-w-[90%]'
                                inputValue={dateFiltersValues?.from}
                                onChangeInput={onChangeDateFilters}
                                inputUniqueName={'from'}
                            />
                            <Datepicker
                                label='Date To'
                                placeholder=' '
                                dataQa={'date-to'}
                                containerStyle='mb-2 min-w-[90%]'
                                inputValue={dateFiltersValues?.to}
                                onChangeInput={onChangeDateFilters}
                                inputUniqueName={'to'}
                            />
                        </>
                    }
                    {cashouts && <label className='mb-1 min-w-[90%]'>
                        <input
                            type='checkbox'
                            checked={includeTestOrders}
                            onChange={() => setIncludeTestOrders(!includeTestOrders)}
                        />
                        Include Test Orders
                    </label>}
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Download Data'}
                        dataQa={loadingDownload ? 'loading-data...' : 'download-data'}
                        onClickButton={loadingDownload ? () => { } : onDownloadTemplate}
                        className={`${!loadingDownload ? 'btn-main' : 'btn-main-disable'} my-3 !py-2 !shadow-none`}
                    />
                </div>
            </div>
        </Modal >
    )
}
export default DownloadDataModal;