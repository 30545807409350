import React from 'react';
import moment from 'moment';
import Table from '../../../../shared/table';

const tableColumns = ['Timestamp', 'User', 'Note']

interface INotesTable {
    rows: any[];
}

const NotesTable = ({
    rows,
}: INotesTable) => {
    return (
        <Table
            columns={tableColumns}
            hidePagination={true}
            headerStyle='!bg-[#000000] !text-white'
        >
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-4'>{moment(row?.timestamp).format('DD-MM-YYYY hh:mm:ss')}</td>
                        <td className='px-6 py-4'>{row?.partner?.username}</td>
                        <td className='px-6 py-4'>{row?.text}</td>
                    </tr>
                )
            })}
        </Table>
    )
}

export default NotesTable;