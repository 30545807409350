import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currencySymbols';
import { tryToFetchItemId } from '../../../store/inventory/shared/item-search/itemIdSearchSlice';
import PurchaseInfo from '../details/PurchaseInfo';
import SaleInfo from '../details/SaleInfo';
import InfoTable from '../tables/InfoTables';
import Button from '../../../shared/button';
import Modal from '../../../shared/modal';
import Input from '../../../shared/input';
import Error from '../../../shared/error';


interface IImeiModal {
    openInfoModal: boolean;
    handleCloseModal: () => void;
}

const ItemIdModal = ({
    openInfoModal,
    handleCloseModal,
}: IImeiModal) => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const [itemId, setItemId] = useState<any>()
    const [addImeiError, setImeiError] = useState<string>()
    const [itemIdResponse, setItemIdResponse] = useState<any>()
    const loading = useAppSelector((state) => state?.imeiSearch?.itemIdSearchIsLoading)

    const onCloseModal = () => {
        handleCloseModal();
        setItemId(undefined);
        setImeiError(undefined)
        setItemIdResponse(undefined)
    }

    const onSubmit = async () => {
        try {
            const response = await dispatch(tryToFetchItemId(itemId)).unwrap()
            setItemIdResponse(response?.data)
        } catch (err) {
            setItemIdResponse(undefined)
            setImeiError(`${err}`)
        }
    }

    const onEnterKeyPressInput = async (e: any) => {
        if (e?.key === 'Enter') {
            e?.preventDefault()
            onSubmit()
        }
    }

    const onChangeFilterValue = (value: string | null | undefined, type?: string) => {
        if (type) {
            setItemId(value);
        }
    };

    const onClickSeeDetails = () => {
        navigation(`/inventory/details/${itemIdResponse?.itemCode || itemIdResponse?.itemId || itemIdResponse?.id}`)
        onCloseModal();
    }

    const onClickGoToPurchase = () => {
        navigation(`/purchases/${itemIdResponse?.purchase.quoteId}`)
        onCloseModal();
    }

    const onClickGoToSale = () => {
        navigation(`/sales-orders/${itemIdResponse?.sale?.quoteId}`)
        onCloseModal();
    }

    return (
        <Modal
            open={openInfoModal}
            showInRight={true}
            title='Find Item in Inventory'
            contentContainerStyle='!min-w-[70vw]'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[65vw]'}>
                <div className='mb-6 border-b'>
                    <p className='mb-4 font-semibold'>Search Item</p>
                </div>
                <div className='bg-[#f8f9fc] p-3 !rounded'>
                    <p className='mb-4'>Please press “Enter” or “Search Item” to load the results</p>
                    {addImeiError && <Error text={addImeiError} />}
                    <div className='flex flex-row items-center mt-3'>
                        <Input
                            label='Item ID'
                            containerStyle='min-w-[80%] mr-5'
                            showValue={true}
                            inputValue={itemId}
                            onChangeInput={(value) => onChangeFilterValue(value, 'itemId')}
                            onHandleKeyDown={onEnterKeyPressInput}
                            inputUniqueName={'itemId'}
                        />
                        <Button
                            label={'Search Item'}
                            dataQa={'add-item-id-btn'}
                            onClickButton={() => onSubmit()}
                            className={'btn-primary-rounded-less mb-3 mt-4 min-w-[130px] !shadow-none'}
                        />
                    </div>
                    {itemIdResponse &&
                        <div className='my-4'>
                            <p className='font-bold text-md my-5'>{['phones', 'tablets']?.includes(itemIdResponse?.category || '') ? 'IMEI: ' : 'Item ID: '} {itemId}</p>
                            <div className='grid grid-cols-4 gap-4'>
                                <div className='mr-4 mb-4 col-span-2'>
                                    <p className='font-semibold'>Item Location: </p>
                                    <p>Warehouse: {itemIdResponse?.warehouseInfo?.name}</p>
                                    <div>
                                        {itemIdResponse?.locationsInfo?.spaces?.name}{itemIdResponse?.locations ? ' - ' : ''}
                                        {itemIdResponse?.locations && (Object?.keys(itemIdResponse?.locations))?.map((loc: any, index: number) => {
                                            return `${index === 0 ? `${itemIdResponse?.locations?.[loc]}` : ` - ${itemIdResponse?.locations?.[loc]}`}`
                                        })}
                                    </div>
                                </div>
                                <div className='mr-4 mb-4'>
                                    <p className='font-semibold'>Item Cost: </p>
                                    <p>{CURRENCY_SYMBOLS?.[(itemIdResponse?.currency || '')?.toLowerCase()]}{itemIdResponse?.revaluatedPrice || itemIdResponse?.price}</p>
                                </div>
                                <div className='mb-4'>
                                    <Button
                                        label={'See Item Details'}
                                        className='btn-main'
                                        onClickButton={onClickSeeDetails}
                                    />
                                </div>
                            </div>
                            <InfoTable
                                itemIdResponse={itemIdResponse}
                                loading={loading}
                                onClickSeeDetails={onClickSeeDetails}
                            />
                            {itemIdResponse?.purchase &&
                                <PurchaseInfo
                                    purchaseDetails={itemIdResponse?.purchase}
                                    onClickGoToPurchase={onClickGoToPurchase}
                                />
                            }
                            {itemIdResponse?.sale &&
                                <SaleInfo
                                    saleDetails={itemIdResponse?.sale}
                                    onClickGoToSale={onClickGoToSale}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        </Modal >
    )
}

export default ItemIdModal;