import React from 'react';

interface IError {
    text: string;
}

const Error = ({ text }: IError) => {
    return (
        <div className='my-2 text-xs text-red-500'>{text}</div>
    );
};

export default Error;