import React, { useEffect, useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Table from '../../../../shared/table';
import { onFormatDiscrepancyStatus } from '../../../../utils/constants/discrepancy-format-status';


interface ICreditNoteDetailsTable {
    rows: Array<any>;
    actions?: any;
    status?: string;
    accessControl?: any;
}

const CreditNoteDetailsTable = ({
    rows,
    status,
    actions,
    accessControl,
}: ICreditNoteDetailsTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Nr.', 'Items', 'IMEI(Item Code)', 'IMEI Status', 'Location', 'Initial Price', 'Final Price', 'Discrepancy Type', 'Awaiting Credit'])

    useEffect(() => {
        if (status) {
            setTableLoading(true)
            switch (status) {
                case 'awaiting_credit':
                    setTableColumns(['Nr.', 'Items', 'IMEI(Item Code)', 'IMEI Status', 'Location', 'Initial Price', 'Final Price', 'Discrepancy Type', 'Awaiting Credit'])
                    break;
                case 'received_credit':
                    setTableColumns(['Nr.', 'Items', 'IMEI(Item Code)', 'IMEI Status', 'Location', 'Initial Price', 'Final Price', 'Discrepancy Type', 'Received Credit'])
                    break;
                case 'used_credit':
                    setTableColumns(['Nr.', 'Items', 'IMEI(Item Code)', 'IMEI Status', 'Location', 'Initial Price', 'Final Price', 'Discrepancy Type', 'Used Credit'])
                    break;
            }

            setTableLoading(false)

        }
    }, [status])

    const totalAwaitingCredit = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + (Number(item?.credit || 0)), 0);
    return (
        <Table
            columns={tableColumns}
            headerStyle='!bg-black !text-white'
            hidePagination={true}>
            {(!tableColumns || tableLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const keys = row?.descriptor && Object.keys(row?.descriptor)
                        return (
                            <tr key={idx} className={`border-b`}>
                                <td className='px-6 py-4'>{idx + 1}</td>
                                <td className='px-6 py-4 max-w-[400px]'>
                                    <div className='flex flex-wrap'>
                                        <img src={row?.category?.icon} className={'h-[16px] min-h-[16px] object-contain'} />
                                        {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                            return (
                                                <div key={index} className='flex items-center whitespace-nowrap'>
                                                    {(index !== 0) &&
                                                        <div className='flex flex-row items-center ml-1 mr-1'>
                                                            <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                        </div>
                                                    }
                                                    <div className='!mr-1 !ml-1'>{row?.descriptor?.[key]}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.itemId || '--'}</td>
                                <td className='px-6 py-4'>{row?.imeiStatus || '--'}</td>
                                <td className='px-6 py-4'>
                                    {row?.locations ? (Object?.keys(row?.locations))?.map((loc: any, index: number) => {
                                        return `${index === 0 ? `${row?.locations?.[loc]}` : ` - ${row?.locations?.[loc]}`}`
                                    }) : '--'}
                                </td>
                                <td className={`px-6 py-4 ${row?.initialPrice && row?.initialPrice > 0 ? 'text-[#00b67a]' : 'text-[#e50000]'}`}>
                                    {(row?.initialPrice && row?.initialPrice > 0) ? '+' : row?.initialPrice === 0 ? '' : '-'}{(row?.initialPrice || row?.initialPrice === 0) ? CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`] : ''}{row?.initialPrice || row?.initialPrice === 0 ? Math.abs(row?.initialPrice) : '--'}
                                </td>
                                <td className={`px-6 py-4 ${row?.price && row?.price > 0 ? 'text-[#00b67a]' : 'text-[#e50000]'}`}>
                                    {(row?.price && row?.price > 0) ? '+' : row?.price === 0 ? '' : '-'}{(row?.price || row?.price === 0) ? CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`] : ''}{row?.price || row?.price === 0 ? Math.abs(row?.price) : '--'}
                                </td>
                                <td className={`px-6 py-4 ${onFormatDiscrepancyStatus(row?.discrepancyStatus)?.color}`}>{row?.discrepancyStatus && onFormatDiscrepancyStatus(row?.discrepancyStatus)?.label}</td>
                                <td className={`px-6 py-4 ${row?.credit && row?.credit > 0 ? 'text-[#00b67a]' : 'text-[#e50000]'}`}>
                                    {(row?.credit && row?.credit > 0) ? '+' : row?.credit === 0 ? '' : '-'}{(row?.credit || row?.credit === 0) ? CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`] : ''}{row?.credit || row?.credit === 0 ? Math.abs(row?.credit) : '--'}
                                </td>
                            </tr>
                        )
                    })}
                    {rows && rows?.length > 0 &&
                        <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan={4} className={`px-6 py-4 text-end ${totalAwaitingCredit && totalAwaitingCredit > 0 ? 'text-[#f7a21e]' : 'text-[#e50000]'}`}>
                                <span>{status === 'awaiting_credit' ? 'Awaiting Credit: ' : status === 'received_credit' ? 'Received Credit: ' : 'Used Credit: '}</span>
                                <span className='font-bold'>{(totalAwaitingCredit && totalAwaitingCredit > 0) ? '+' : totalAwaitingCredit === 0 ? '' : '-'}{CURRENCY_SYMBOLS?.[`${(rows?.[0]?.currency || '').toLowerCase()}`]}{Math.abs(totalAwaitingCredit || 0).toFixed(2)}</span>
                            </td>
                        </tr>
                    }
                </>
            }
        </Table>
    )
}

export default CreditNoteDetailsTable;