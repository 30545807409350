import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatPurchaseStatus } from '../../../../utils/constants/purchase-statuses';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';
import TableActions from '../../../../shared/table/table-actions';


interface IPurchaseTable {
    rows: any;
    paginationData?: IPage;
    activeFilter: string;
    purchaseLoading?: boolean;
    accessControl?: any;
    actions?: any;
    brokerCategories?: any;
    onClickDownloadLabel: (label: any) => void;
    onClickStatusChange: (id: string, type: string, date?: string) => void;
    onEditPurchase: (id: string) => void;
    onViewContents: (id: string) => void;
    onClickShowNote: (id: string) => void;
    onDeletePurchase: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeMoreDetails: (type: string, typeData: any, allData?: any) => void;
    onClickSeeAllCategories: (categories: any) => void;
}

const PurchaseTable = ({
    rows,
    paginationData,
    activeFilter,
    purchaseLoading,
    accessControl,
    actions,
    brokerCategories,
    onClickDownloadLabel,
    onClickStatusChange,
    onEditPurchase,
    onViewContents,
    onClickShowNote,
    onDeletePurchase,
    setPaginationState,
    onClickSeeMoreDetails,
    onClickSeeAllCategories
}: IPurchaseTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(true)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>([])

    const formatTableColumnsAndKeys = () => {
        if (activeFilter) {
            setTableLoading(true)
            switch (activeFilter) {
                case 'draft':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'] || [],
                        // 'Purchase Accepted',
                        'Actions'
                    ]))
                    setTableExtraKeys(['purchaseAccepted'])
                    break;
                case 'accepted':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        // 'Purchase Paid',
                        'Actions'
                    ]))
                    setTableExtraKeys(['paid'])
                    break;
                case 'on_delivery':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        // 'Mark As Delivered',
                        'Actions'
                    ]))
                    setTableExtraKeys(['markDelivered'])
                    break;
                case 'delivered':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        // 'Boxes Details',
                        'Actions'
                    ]))
                    setTableExtraKeys(['boxDetails', 'review'])
                    break;
                case 'in_review':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        'Actions'
                    ]))
                    setTableExtraKeys([])
                    break;
                case 'completed':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        'Actions'
                    ]))
                    setTableExtraKeys([])
                    break;
                case 'discrepancy':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        'Actions'
                    ]))
                    setTableExtraKeys([])
                    break;
                case 'rma_in_progress':
                case 'split_discrepancy':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        'Actions'
                    ]))
                    setTableExtraKeys([])
                    break;
                case 'rma_shipped':
                    setTableColumns((prevTableCols: any) => ([
                        ...['Quote ID', 'Customer', 'VAT Scheme', 'Status', 'Categories', 'Totals', 'Quantity', 'Due Date (dd-mm-yyyy)'],
                        // 'Download Label',
                        'Actions'
                    ]))
                    setTableExtraKeys(['labelDownload'])
                    break;
            }
        }
        setTableLoading(false)
    }

    useEffect(() => {
        formatTableColumnsAndKeys()
    }, [activeFilter, rows, paginationData])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}
        >
            {purchaseLoading || tableLoading ?
                <>
                    {[...Array(7)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        const totalPrice = row?.expectedItems && row?.expectedItems?.length > 0 && row?.expectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
                        const findCurrency = row?.expectedItems && row?.expectedItems?.length > 0 && row?.expectedItems?.[0]?.currency
                        const value = onFormatPurchaseStatus(row?.status)?.name
                        const color = onFormatPurchaseStatus(row?.status)?.color
                        const text = onFormatPurchaseStatus(row?.status)?.text || 'text-black'
                        const item = color && <div className={`${color} border flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium !rounded-[6px]`}>{value}</div>
                        const totalQuantity = activeFilter === 'rma_shipped' || activeFilter === 'rma_shipped' ? row?.returnedItems?.length || 0 : (row?.expectedItems && row?.expectedItems?.length > 0) && row?.expectedItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4 min-w-[150px]'>
                                    <div className='flex flex-row items-center cursor-pointer' onClick={() => navigator?.clipboard?.writeText(row?.quoteId)}>
                                        <p className='cut-text'>{row?.quoteId}</p>
                                        <div>
                                            <img title={'Copy Quote ID'} alt='copy' src={'/assets/table-actions/copy.svg'} className={'h-[25px] object-contain cursor-pointer ml-1 hover:opacity-70'} />
                                        </div>
                                    </div>
                                </td>
                                <td className='px-6 py-4'>{row?.purchaseInfo?.customer?.displayName || row?.purchaseInfo?.partner?.companyName || row?.purchaseInfo?.partner?.username || ''}</td>
                                <td className='px-6 py-4'>{row?.purchaseInfo?.stockVat || '--'}</td>
                                <td className='px-6 py-4'>{item}</td>
                                <td className='pl-6 pr-2 py-4'>
                                    <div
                                        onClick={() => row?.categories?.length > 2 && onClickSeeAllCategories(row?.categories)}
                                        className='flex flex-row flex-wrap items-center cursor-pointer'
                                    >
                                        {(row?.categories && row?.categories?.length > 0 && brokerCategories && brokerCategories?.length > 0) && row?.categories?.filter((item: any, index: number) => index < 2)?.map((item: any) => {
                                            const findCategory = (brokerCategories && brokerCategories?.length > 0) && brokerCategories?.find((category: any) => category?.id === item?.id)
                                            return (
                                                <img src={findCategory?.enabledIcon} className='object-contain h-[23px] min-h-[23px] mr-1' key={item?.id} />
                                            )
                                        })}
                                        {row?.categories?.length > 2 ? <p className='font-bold'>...</p> : ''}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>
                                    {CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{row?.batch ? Number(row?.offerPrice || totalPrice || 0)?.toFixed(2) : Number(totalPrice || 0).toFixed(2)}
                                </td>
                                <td className='px-6 py-4'>{totalQuantity || 0}</td>
                                <td className='px-6 py-4 min-w-[120px]'>{row?.purchaseInfo?.dueDate && moment(row?.purchaseInfo?.dueDate).format('DD-MM-YYYY')}</td>
                                <td className='px-2 py-4 min-w-[150px]'>
                                    <div className='flex flex-row items-center'>
                                        <div onClick={() => row?.quoteId && onViewContents(row?.quoteId)} className='mr-4 mb-2'>
                                            <img title={'View Contents'} alt='view contents' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                        </div>
                                        <TableActions>
                                            {activeFilter !== 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Add Note']) && row?._id) && onClickShowNote(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Leave a Note'}
                                                        dataQa={'leave-note'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Add Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {(tableExtraKeys && tableExtraKeys?.length > 0) && tableExtraKeys?.map((key: string, index: number) => {
                                                let value: any
                                                if (key === 'purchaseAccepted' && !row?.batch) {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Purchase Accepted']) && onClickStatusChange(row?._id, 'accepted')}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as approved'}
                                                            dataQa={'accepted'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Purchase Accepted']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'purchaseAccepted' && row?.batch) {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Purchase Completed']) && onClickStatusChange(row?._id, 'batch_completed', row)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as completed'}
                                                            dataQa={'completed'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Purchase Completed']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'paid') {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Purchase Paid']) && onClickStatusChange(row?._id, 'paid', row?.purchaseInfo?.dueDate)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as paid'}
                                                            dataQa={'paid'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Purchase Paid']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'markDelivered') {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Purchase Delivered']) && onClickStatusChange(row?._id, 'markDelivered', row?.quoteId)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Mark as delivered'}
                                                            dataQa={'delivered'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Purchase Delivered']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'boxDetails') {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Boxes Details']) && onClickStatusChange(row?._id, 'boxDetails', row?.quoteId)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'View Locations'}
                                                            dataQa={'view-locations'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Boxes Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'review') {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Purchase Review']) && onClickStatusChange(row?._id, 'review')}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Review Purchase'}
                                                            dataQa={'review'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#00b67a] ${(!accessControl || actions?.['Purchase Review']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                if (key === 'labelDownload') {
                                                    value = <div
                                                        onClick={() => (!accessControl || actions?.['Download Label']) && onClickDownloadLabel(row?.returnShippingLabelUrl)}
                                                        className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'
                                                        role='none'>
                                                        <Button
                                                            label={'Shipping Label'}
                                                            dataQa={'label'}
                                                            className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${((!accessControl || actions?.['Download Label']) && row?.returnShippingLabelUrl) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                        />
                                                    </div>
                                                }
                                                return (
                                                    <div key={index}>
                                                        {value}
                                                    </div>
                                                )
                                            })}
                                            {activeFilter === 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Add Note']) && row?._id) && onClickShowNote(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Leave a Note'}
                                                        dataQa={'leave-note'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Add Note']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {activeFilter === 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Edit Purchase']) && row?._id) && onEditPurchase(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Edit Purchase Order'}
                                                        dataQa={'edit-purchase-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#000000] ${(!accessControl || actions?.['Edit Purchase']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                            {activeFilter === 'draft' &&
                                                <div
                                                    onClick={() => ((!accessControl || actions?.['Delete Purchase']) && row?._id) && onDeletePurchase(row._id)}
                                                    className='py-2 px-4 cursor-pointer flex flex-row items-center align-flex-start hover:bg-[#f4f4f4]'>
                                                    <Button
                                                        label={'Delete Purchase Order'}
                                                        dataQa={'delete-purchase-order'}
                                                        className={`btn-primary-text-underline !no-underline !font-normal !text-[#e50000] ${(!accessControl || actions?.['Delete Purchase']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                                    />
                                                </div>
                                            }
                                        </TableActions>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default PurchaseTable;