export const SALES_ORDER_STATUSES = [
    { value: 'draft', name: 'Draft', label: 'Draft' },
    { value: 'open_order', name: 'Open Orders', label: 'Open Orders' },
    { value: 'review', name: 'Review', label: 'Review' },
    { value: 'processing', name: 'Processing', label: 'Processing' },
    { value: 'processed', name: 'Processed', label: 'Processed' },
    { value: 'paid', name: 'Paid', label: 'Paid' },
    { value: 'shipped', name: 'Shipped', label: 'Shipped' },
    { value: 'expired', name: 'Expired', label: 'Expired' },
]

export const SALE_CREATE_TYPE = [
    { value: 'Item By Item', label: 'Line By Line' },
    { value: 'Multiple', label: 'Batch Order' },
]