import React from 'react';


const TemplateFieldsLoading = () => {
    return (
        <div className='min-w-[100%]'>
            {[...Array(6)].map((x, i) => {
                return (
                    <>
                        <div className=' my-4 animate-pulse'>
                            <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                            <div className='h-8 bg-gray-200 rounded dark:bg-gray-200 w-[95%] mb-1'></div>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

export default TemplateFieldsLoading;