import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SALES_STATUSES } from '../../../utils/constants/sales-statuses';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IPartners } from '../../../interfaces/partners/IPartners';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { tryToFetchAllAssetsTemplates } from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import { tryToFetchAllPartners } from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchSales } from '../../../store/inventory/shared/sales/salesSlice';
import { useGetAllMarketsMutation } from '../../../store/user/userDomApi';
import SaleReviewStatusModal from './modals/SaleReviewStatusModal';
import SalesReviewFilters from './details/SalesReviewFilters';
import SalesReviewTable from './tables/SalesReviewTable';
import SelectCheckbox from '../../../shared/select-checkbox';
import EmptyContainer from '../../../shared/empty-container';


const SalesReview = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const [rows, setRows] = useState<Array<any>>([]);
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [partners, setPartners] = useState<IPartners[]>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [selectedMarket, setSelectedMarket] = useState<string>();
    const [selectedPartner, setSelectedPartner] = useState<string>();
    const [selectedSector, setSelectedSector] = useState<string>();
    const [statusSelected, setStatusSelected] = useState<string>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [showStatusModal, setShowStatusModal] = useState<any>({ show: false, id: undefined });
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10
    });
    const [getAllMarkets] = useGetAllMarketsMutation();
    const state = useAppSelector((state) => state.sales);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const rowsLoading = useAppSelector((state) => state.sales?.salesAreLoading);
    const paginationData = useAppSelector((state) => state?.sales?.sales?.data?.page);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Sales Review Admin')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined
        })
    }

    const getPartnerAndMarketData = async () => {
        try {
            const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
            const formatPartners = partnerResponse && partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: IPartners) => ({ ...partner, label: partner?.companyName, value: partner?._id, name: partner?.name }))
            setPartners(formatPartners || [])
            const marketResponse = await getAllMarkets(null).unwrap()
            const formatMarkets = marketResponse && marketResponse?.length > 0 && marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }))
            setMarkets(formatMarkets || [])
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates && Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({ ...r || {}, name: r?.displayName, label: r?.displayName, value: r?._id, }))
                    : [];
            setSectors(rows?.length > 0 ? rows?.filter((r) => (r?.name || '').toLowerCase() !== 'currencies') : []);
        }
    }, [stateAssets.allAssetTemplates]);

    useEffect(() => {
        getPartnerAndMarketData()
        dispatch(tryToFetchAllAssetsTemplates());
    }, [])

    const onChangeSelectedFilter = (e: any, type?: string) => {
        switch (type) {
            case 'market':
                setSelectedMarket(e?.target?.value);
                break;
            case 'sector':
                setSelectedSector(e?.target?.value)
                break;
            case 'partner':
                setSelectedPartner(e?.target?.value)
                break;
        }
        onResetPaginationSettings()
    }

    useEffect(() => {
        if (state.sales) {
            const rows =
                state.sales?.data?.elements && Array.isArray(state.sales?.data.elements)
                    ? state.sales?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.sales]);

    const onGettingSalesData = async () => {
        let data: any = {}
        if (selectedSector) {
            data = {
                ...data || {},
                assetTemplateId: selectedSector,
            }
        }
        if (selectedMarket) {
            data = {
                ...data || {},
                marketId: selectedMarket,
            }
        }
        if (selectedPartner) {
            data = {
                ...data || {},
                partnerId: selectedPartner,
            }
        }
        if (statusSelected) {
            data = {
                ...data || {},
                status: statusSelected,
            }
        }
        const fetchData = {
            ...paginationState || {},
            data
        }
        await dispatch(tryToFetchSales(fetchData)).unwrap()
    }

    useEffect(() => {
        onGettingSalesData()
    }, [paginationState])

    const onOpenSalesForm = (id: string) => {
        navigator(`/sales-review-admin/${id}`)
    }

    const onChangeStatus = (value: any, type?: string) => {
        setStatusSelected(value?.value)
        onResetPaginationSettings()
    }

    const onOpenSaleStatusModal = (id?: string) => {
        if (id) {
            setShowStatusModal({ show: true, id: id })
        } else {
            setShowStatusModal({ show: false, id: undefined })
        }
    }

    return (
        <div>
            <div>
                <p className='page-title'>Sales Review</p>
            </div>
            <div className='mt-10 mb-7'>
                <SalesReviewFilters
                    markets={markets}
                    sectors={sectors}
                    partners={partners}
                    selectedMarket={selectedMarket}
                    selectedPartner={selectedPartner}
                    selectedSector={selectedSector}
                    onChangeSelectedFilter={onChangeSelectedFilter}
                />
            </div>
            <div className='mb-2 grid grid-cols-4 gap-4'>
                <SelectCheckbox
                    name='Statuses'
                    placeholder={statusSelected || ''}
                    options={SALES_STATUSES || []}
                    selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                    onChangeSelectedOption={onChangeStatus}
                    uniqueName={'status'}
                />
            </div>
            <div>
                {(rows?.length > 0 || rowsLoading) ?
                    <SalesReviewTable
                        rows={rows}
                        paginationData={paginationData}
                        markets={markets}
                        sectors={sectors}
                        partners={partners}
                        rowsLoading={rowsLoading}
                        accessControl={accessControl}
                        actions={pageAccess?.actions}
                        setPaginationState={setPaginationState}
                        onClickSeeDetails={onOpenSalesForm}
                        onOpenSaleStatusModal={onOpenSaleStatusModal}
                    />
                    : <EmptyContainer text={'No sales added yet.'} showImage={true} />
                }
            </div>
            <SaleReviewStatusModal
                openActionsModal={showStatusModal?.show || false}
                handleCloseActionsModal={onOpenSaleStatusModal}
                saleId={showStatusModal?.id || ''}
            />
        </div>
    );
};

export default SalesReview;