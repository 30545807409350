import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditSaleStatus } from '../../../../store/inventory/shared/sales/salesSlice';
import { SALES_ADMIN_STATUSES } from '../../../../utils/constants/sales-statuses';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import SelectCheckbox from '../../../../shared/select-checkbox';

interface ISaleReviewStatusModal {
    openActionsModal: boolean;
    saleId: string;
    handleCloseActionsModal: () => void;
}

const SaleReviewStatusModal = ({
    openActionsModal,
    saleId,
    handleCloseActionsModal,
}: ISaleReviewStatusModal) => {
    const dispatch = useAppDispatch();
    const [selectedStatus, setSelectedStatus] = useState<string>()
    const [generalError, setGeneralError] = useState<string>()

    const onCloseActionsModal = () => {
        handleCloseActionsModal();
        setSelectedStatus(undefined)
        setGeneralError(undefined)
    }

    const onSubmit = async () => {
        if (!selectedStatus) {
            setGeneralError('Please first select the status to continue.')
            return;
        }
        try {
        
            await dispatch(tryToEditSaleStatus({ id: saleId, status: selectedStatus }))
            onCloseActionsModal()
        } catch (err: any) {
            setGeneralError(`${err?.response?.message || err?.response || err}` || 'Something went wrong!')
        }
    }

    const onChangeStatus = (value: any, type?: string) => {
        setSelectedStatus(value?.value)
    }

    return (
        <Modal
            open={openActionsModal}
            onClose={onCloseActionsModal}>
            <div className={'p-2 min-w-[250px] min-h-[300px] flex flex-col justify-between'}>
                <p className='mb-4 font-semibold text-sm'>Review Sale</p>
                {generalError && <Error text={generalError} />}
                <div className='flex flex-col items-center mb-6'>
                    <SelectCheckbox
                        name='Status'
                        placeholder={selectedStatus || ''}
                        options={SALES_ADMIN_STATUSES || []}
                        selectStyle={{ backgroundColor: 'white', borderRadius: '15px' }}
                        onChangeSelectedOption={onChangeStatus}
                        uniqueName={'status'}
                    />
                </div>
                <div className='flex flex-row justify-end'>
                    <Button
                        label={'Change Status'}
                        className={'btn-primary !shadow-none'}
                        onClickButton={onSubmit}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default SaleReviewStatusModal;