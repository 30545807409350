import { useGetCurrentUserQuery } from '../store/user/userApi';
import { readFromStorage } from '../utils/webStorage';

export const GuestGuard = (): boolean => {
    const token = readFromStorage('accessToken');
    if (token) {
        return false
    }
    else {
        return true
    }
};

export const AdminGuard = (): boolean => {
    const role = localStorage.getItem('roles')
    const { data }: any = !role && useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');

    return token ? role ? localStorage.getItem('roles') === 'admins' : (data && data?.groups && data?.groups?.length) && Boolean(data?.groups.includes('admins')) : false;
};

export const PartnerAgentGuard = (): boolean => {
    const { data } = useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? (data && data?.groups && data?.groups?.length) ? Boolean(data?.groups.includes('partners') || data?.groups.includes('agents')) : (localStorage.getItem('roles') === 'partners' || localStorage.getItem('roles') === 'agents') : false;
};

export const PartnerGuard = (): boolean => {
    const { data } = useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? (data && data?.groups && data?.groups?.length) ? Boolean(data?.groups.includes('partners')) : (localStorage.getItem('roles') === 'partners') : false;
};