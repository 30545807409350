import React from 'react';
import Table from '../table';


const LoadingPage = () => {
    return (
        <div>
            <div className='my-5 flex flex-row flex-wrap items-center'>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
                <div className='my-2 max-w-sm animate-pulse mr-4'>
                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[130px] mb-1'></div>
                </div>
            </div>
            <div className='grid grid-cols-3 gap-3'>
                <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mb-2'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-[50px] bg-gray-200 rounded-full dark:bg-gray-200 w-[35px] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    </div>
                </div>
                <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mb-2'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-[50px] bg-gray-200 rounded-full dark:bg-gray-200 w-[35px] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    </div>
                </div>
                <div className='bg-[#f8f9fc] !rounded p-2 flex flex-col justify-between'>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[50%] mb-2'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-[50px] bg-gray-200 rounded-full dark:bg-gray-200 w-[35px] mb-1'></div>
                    </div>
                    <div className='my-2 max-w-sm animate-pulse'>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[40%] mb-1'></div>
                    </div>
                </div>
            </div>
            <div className=' border-b border-[#e4e4e4] pb-2 my-2'>
                <Table
                    columns={['Item', 'Price per Unit', 'Units', 'Subtotal']}
                    hidePagination={true}
                    headerStyle='!bg-[#000000] !text-white'>
                    <>
                        {[...Array(3)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    {[...Array(4)].map((x, j) => {
                                        return (
                                            <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </>
                </Table>
            </div>
        </div>
    )
}

export default LoadingPage;