import React from 'react';
import { ISales } from '../../../../interfaces/sales/ISales';
import ProccessedTable from '../tables/ProccessedTable';

interface IProccessedStatus {
    saleDetails?: ISales;
    saleCategories?: Array<any>;
    templateFields?: any;
}

const ProccessedStatus = ({
    saleDetails,
    saleCategories,
    templateFields
}: IProccessedStatus) => {
    return (
        <div className='bg-white !rounded my-5 pb-4'>
            <p className='font-semibold text-[18px] p-3'>Items</p>
            <ProccessedTable
                rows={saleDetails?.saleItems}
                categories={saleCategories}
                templateFields={templateFields}
                selectedItems={saleDetails?.selectedItems}
            />
        </div>
    )
}
export default ProccessedStatus;