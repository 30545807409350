import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import {
    tryToAcceptRejectSaleItem,
    tryToCheckSaleOrderAffected
} from '../../../../store/inventory/shared/sales/salesSlice';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';


interface IAcceptItemModal {
    openModal: boolean;
    saleId: string;
    itemDetailsInfo?: any;
    mainItem?: any;
    handleCloseModal: () => void;
    onSuccessfullyAccept: (acceptedItem: any, item: any) => void;
}

const AcceptItemModal = ({
    openModal,
    saleId,
    itemDetailsInfo,
    mainItem,
    handleCloseModal,
    onSuccessfullyAccept,
}: IAcceptItemModal) => {
    const dispatch = useAppDispatch();
    const [acceptError, setAcceptError] = useState<string>();
    const [orderAffected, setOrderAffected] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    const onGettingOrderAffecting = async () => {
        try {
            const res = await dispatch(tryToCheckSaleOrderAffected({ id: saleId || '', data: itemDetailsInfo })).unwrap();
            setOrderAffected(res)
        } catch (err) {
            setOrderAffected(undefined)
        }
    }

    useEffect(() => {
        if (itemDetailsInfo && saleId) {
            onGettingOrderAffecting()
        }
    }, [itemDetailsInfo, saleId])

    const onCloseModal = () => {
        handleCloseModal();
        setAcceptError(undefined)
        setLoadingSubmit(false)
        setOrderAffected(undefined)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        try {
            const formatItem = {
                ...itemDetailsInfo || {},
                status: 'accepted',
                notMatching: false,
            }
            await dispatch(tryToAcceptRejectSaleItem({ id: saleId, item: formatItem })).unwrap();
            onSuccessfullyAccept(itemDetailsInfo, mainItem)
            onCloseModal()
        } catch (err) {
            setAcceptError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    const keysDescriptor = itemDetailsInfo && itemDetailsInfo?.descriptor && Object?.keys(itemDetailsInfo?.descriptor)
    const keysInventory = itemDetailsInfo && itemDetailsInfo?.inventory && Object?.keys(itemDetailsInfo?.inventory)
    const keys = [...keysDescriptor || [], ...keysInventory || []]
    const totalPrice = (orderAffected && orderAffected?.saleItems && orderAffected?.saleItems?.length > 0) && orderAffected?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.price * currentValue?.qty), 0)
    const findCurrency = orderAffected?.saleItems && orderAffected?.saleItems?.length > 0 && orderAffected?.saleItems?.[0]?.currency
    const totalQuantity = (orderAffected && orderAffected?.saleItems && orderAffected?.saleItems?.length > 0) && orderAffected?.saleItems?.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.qty), 0)
    const missingItems = orderAffected && totalQuantity ? (totalQuantity - (orderAffected?.reservedItems?.length || 0)) : 0
    return (
        <Modal
            open={openModal}
            showInRight
            title='Accept Item Into Sale Order'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px] border-t my-3'}>
                <p className='mb-4 text-sm'>What has been Scanned:</p>
                {itemDetailsInfo &&
                    <div className='mb-5 flex flex-row items-center border-b pb-3'>
                        {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                            return (
                                <div key={index} className='flex items-center whitespace-nowrap'>
                                    <div className='!mr-1'>{itemDetailsInfo?.descriptor?.[key] || itemDetailsInfo?.inventory?.[key]}</div>
                                </div>
                            )
                        })}
                    </div>
                }
                {acceptError && <Error text={acceptError} />}
                <div className='bg-[#f8f9fc] !p-3'>
                    {orderAffected ?
                        <div>
                            <p className='text-[20px] my-3'>Affected Order</p>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className='mb-4'>
                                    <p className='text-primary-light'>Affected Sale Order ID</p>
                                    <p>{orderAffected?._id}</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='text-primary-light'>Customer</p>
                                    <p>{orderAffected?.salesInfo?.customer?.displayName || orderAffected?.salesInfo?.partner?.username || ''}</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='text-primary-light'>Quantity</p>
                                    <p>Reserved: {orderAffected?.reservedItems?.length || 0}</p>
                                    <p>Missing: {missingItems || 0}</p>
                                    <p>Picked: {orderAffected?.selectedItems?.filter((item: any) => !item?.notMatching).length || 0}</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='text-primary-light'>Order Value</p>
                                    <p>{CURRENCY_SYMBOLS?.[`${(findCurrency || '')?.toLowerCase()}`]}{Number(totalPrice || 0)?.toFixed(2)}</p>
                                    <p className='text-primary-light'>Due Date</p>
                                    <p>{moment(orderAffected?.salesInfo?.dueDate).format('DD-MM-YYYY')}</p>
                                </div>
                            </div>
                        </div>
                        : <p>Accepting this item won&apos;t affect any sale order</p>
                    }
                </div>
                <div className='flex flex-row justify-center items-center my-5'>
                    <Button
                        label={'Go Back'}
                        className='btn-main !rounded mr-4 min-w-[100px]'
                        onClickButton={() => !loadingSubmit && onCloseModal()}
                    />
                    <Button
                        label={'Accept Item'}
                        className={loadingSubmit ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AcceptItemModal;