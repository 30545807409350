import React, { useState } from 'react';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import Table from '../../../../shared/table';


interface IReturnItemsTable {
    rows: Array<any>;
    actions?: any;
    accessControl?: any;
}

const ReturnItemsTable = ({
    rows,
    actions,
    accessControl,
}: IReturnItemsTable) => {
    const [tableColumns] = useState<Array<string>>(['Nr.', 'Items', 'IMEI', 'IMEI Status', 'Amount'])

    const totalExpectedValue = (rows && rows?.length > 0) && rows?.reduce((accumulator: number, item: any) => accumulator + item?.price, 0);
    return (
        <Table
            columns={tableColumns}
            headerStyle='!bg-black !text-white'
            hidePagination={true}>
            {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                const keysDescriptor = row?.descriptor && Object?.keys(row?.descriptor)
                const keysInventory = row?.inventory && Object?.keys(row?.inventory)
                const keys = [...keysDescriptor || [], ...keysInventory || []]
                return (
                    <tr key={idx} className={`border-b`}>
                        <td className='px-6 py-4'>{idx + 1}</td>
                        <td className='px-6 py-4 max-w-[400px]'>
                            <div className='flex flex-wrap'>
                                <img src={row?.category?.icon} className={'h-[16px] min-h-[16px] object-contain'} />
                                {(keys && keys?.length > 0) && keys?.map((key: string, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center whitespace-nowrap'>
                                            {(index !== 0) &&
                                                <div className='flex flex-row items-center ml-1 mr-1'>
                                                    <div className='w-1 h-1 bg-[#a4a4a4] rounded-full'></div>
                                                </div>
                                            }
                                            <div className='!mr-1 !ml-1'>{row?.descriptor?.[key] || row?.inventory?.[key]}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                        <td className='px-6 py-4'>{row?.itemId || '--'}</td>
                        <td className='px-6 py-4'>{row?.imeiStatus || '--'}</td>
                        <td className='px-6 py-4'>{(row?.price || row?.price === 0) ? CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`] : ''}{row?.price || row?.price === 0 ? Number(row?.price || 0).toFixed(2) : '--'}</td>
                    </tr>
                )
            })}
            {rows && rows?.length > 0 &&
                <tr className='!font-bold !text-black !border-t-2 border-[#f7a21e]'>
                    <td></td>
                    <td colSpan={3} className='px-6 py-4 text-end'>Amount:</td>
                    <td className='px-6 py-4'>{CURRENCY_SYMBOLS?.[`${(rows?.[0]?.currency || '').toLowerCase()}`]}{Number(totalExpectedValue || 0).toFixed(2)}</td>
                </tr>
            }
        </Table>
    )
}

export default ReturnItemsTable;