import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation } from '../../../../store/user/userDomApi';
import PasswordActionStatus from '../details/PasswordActionStatus';
import ForgotPasswordForm from '../details/ForgotPasswordForm';
import Modal from '../../../../shared/modal';


interface IForgotPassword {
    openForgotModal: boolean;
    handleCloseForgotModal: () => void;
}

type FormValues = {
    username: string;
};

const ForgotPassword = ({
    openForgotModal,
    handleCloseForgotModal
}: IForgotPassword) => {
    const [codeSentSuccessfully, setCodeSentSuccessfully] = useState<boolean>(false)
    const [forgotPasswordMutation] = useForgotPasswordMutation()
    const [generalError, setGeneralError] = useState<string>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>({});

    const onCloseForgotModal = () => {
        setCodeSentSuccessfully(false)
        handleCloseForgotModal()
        reset()
    }

    const onSubmit = async (data: FormValues) => {
        try {
            await forgotPasswordMutation(data).unwrap()
            setCodeSentSuccessfully(true)
            setGeneralError(undefined)
            reset()
        } catch (err) {
            setGeneralError(`${err}`)
        }
    }

    return (
        <Modal
            open={openForgotModal}
            onClose={onCloseForgotModal}>
            <div className={'p-2 min-w-[500px]'}>
                {codeSentSuccessfully ?
                    <PasswordActionStatus />
                    :
                    <ForgotPasswordForm
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        error={generalError}
                        errors={errors}
                    />
                }
            </div>
        </Modal>
    )
}
export default ForgotPassword;