import React, { useEffect, useState } from 'react';
import {
    useAppDispatch,
    useAppSelector
} from '../../app/hooks';
import { AdminGuard } from '../../guards/RolesGuard';
import {
    tryToChangeActiveWarehouse,
    tryToFindOperatingWarehouses
} from '../../store/inventory/shared/partners/partnersSlice';
import SelectSearchInside from '../../shared/market-select-search/select-inside-search';
import Navigation from './Navigation';


const Sidebar = () => {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state.partners);
    const [warehousesList, setWarehousesList] = useState<Array<any>>([])
    const activeWarehouse = state?.partnerActiveWarehouse
    const isAdmin = AdminGuard()

    useEffect(() => {
        if (state.partnerWarehouses && !isAdmin) {
            const rows =
                state.partnerWarehouses && Array.isArray(state.partnerWarehouses)
                    ? state.partnerWarehouses.map((r) => ({ ...r, label: r?.name, value: r?._id }))
                    : [];
            if (JSON.stringify(warehousesList) !== JSON.stringify(rows)) {
                setWarehousesList(rows || []);
            }
        }
    }, [state?.partnerWarehouses])

    useEffect(() => {
        if (!isAdmin) {
            dispatch(tryToFindOperatingWarehouses(null)).unwrap()
        }
    }, [])

    const onCheckActiveWarehouse = async () => {
        const localWarehouse = localStorage.getItem('active-warehouse')
        const formatLocalWarehouse = localWarehouse && localWarehouse !== 'undefined' ? JSON.parse(localWarehouse) : undefined
        if (activeWarehouse) {
            const findActiveMarket = warehousesList && warehousesList?.length > 0 && warehousesList?.find((warehouse) => warehouse?._id === activeWarehouse?._id)
            if (!findActiveMarket && warehousesList && warehousesList?.length > 0 && activeWarehouse) {
                dispatch(tryToChangeActiveWarehouse({ warehouseId: warehousesList?.[0]?._id || '' }))
            }
        } else if (formatLocalWarehouse) {
            const findActiveWarehouse = warehousesList && warehousesList?.length > 0 && warehousesList?.find((warehouse) => warehouse?._id === formatLocalWarehouse?._id)
            if (!findActiveWarehouse && warehousesList && warehousesList?.length > 0) {
                dispatch(tryToChangeActiveWarehouse({ warehouseId: warehousesList?.[0]?._id || '' }))
            } else if (findActiveWarehouse) {
                dispatch(tryToChangeActiveWarehouse({ warehouseId: findActiveWarehouse?._id || '' }))
            }
        }
        else if (warehousesList && warehousesList?.length > 0) {
            dispatch(tryToChangeActiveWarehouse({ warehouseId: warehousesList?.[0]?.market?._id || '' }))
        }
    }

    useEffect(() => {
        if (warehousesList) {
            onCheckActiveWarehouse()
        }
    }, [warehousesList])

    const onChangeSelectedValue = async (value: any) => {
        const findWarehouseId = warehousesList?.find((warehouse) => warehouse?.name === value?.name)
        if (findWarehouseId?._id !== activeWarehouse?._id) {
            dispatch(tryToChangeActiveWarehouse({ warehouseId: findWarehouseId?._id || '' }))
        }
    }

    return (
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 pt-24'>
            <div className='flex flex-col flex-grow pt-5 bg-white overflow-y-auto'>
                {!isAdmin &&
                    <>
                        <p className='mb-3 text-[#8a8a8e] px-4 pr-5'>Warehouse</p>
                        <div className='flex flex-col px-4 pr-5 mt-1 mb-2'>
                            <SelectSearchInside
                                selectedOption={{ label: activeWarehouse?.name, value: activeWarehouse?._id }}
                                options={warehousesList || []}
                                onChangeSelectedOption={onChangeSelectedValue}
                                placeholder={activeWarehouse?.label || 'Warehouses'}
                            />
                        </div>
                    </>
                }
                <div className='flex items-center flex-shrink-0 px-4'>
                    {/* <form className='w-full flex md:ml-0' action='#' method='GET'>
                        <label htmlFor='search-field' className='sr-only'>
                            Search
                        </label>

                        <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                            <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                                <img alt='search' className='h-4 w-4' src='/svg/layout/search-icon.svg' />
                            </div>
                            <input
                                id='search-field'
                                className='bg-bgColor-search min-w-[99%] rounded block h-full pl-8 pr-3 py-2 border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                placeholder='Search'
                                type='search'
                                name='search'
                            />
                        </div>
                    </form> */}
                </div>
                <div className='mt-8 flex-1 flex flex-col'>
                    <nav className='flex-1 px-4 pb-4'>
                        <Navigation />
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;