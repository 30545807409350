import React, { useEffect, useState } from 'react';
import { IQuestion } from '../../../../interfaces/questions/IQuestions';
import TemplateFieldsLoading from '../loading/TemplateFieldsLoading';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';


interface IItemCatalogFieldsForm {
    primaryQuestions: any,
    secondaryQuestions: any;
    selectedPrimaryAnswers?: any;
    selectedSecondaryAnswers?: any;
    items: Array<any>;
    templateId?: string;
    filterValues: any;
    filterInventoryValues: any;
    filterExtendedValues: any;
    inventoryItems?: Array<any>;
    extendedItems?: Array<any>;
    filterErrors?: any;
    inventoryFilterErrors?: any;
    extendedFilterErrors?: any;
    loadingValues: boolean;
    loadingFields: boolean;
    loadingOptions?: any;
    filteredFields?: any;
    searchInDB?: any;
    secondaryQuestionsErrors?: any;
    onClearAssetFieldsData: () => void;
    onChangeSelectedValue: (value: string, type?: string) => void;
    onChangeSelectedInventoryValue: (value: string, type?: string) => void;
    onChangeSelectedExtendedValue: (value: string, type?: string) => void;
    onChangeInput: (value: string | null | undefined, type: string) => void;
    onChangeInventoryInput: (value: string | null | undefined, type: string) => void;
    onChangeExtendedInput: (value: string | null | undefined, type: string) => void;
    onChangeSearchAssetFields: (e: any, type?: string) => void;
    onMenuCloseAfterSearch: (type?: string) => void;
    onClickSearchInDb: (type?: string, index?: any) => void;
    onSelectPrimaryQuestion: (value: any, type?: string, index?: string) => void;
    onSelectSecondaryQuestion: (value: any, type?: string, index?: string) => void;
    onChangeSecondaryInputValue: (value: any, type?: string, index?: string) => void;
}

const ItemCatalogFieldsForm = ({
    primaryQuestions,
    secondaryQuestions,
    selectedPrimaryAnswers,
    selectedSecondaryAnswers,
    items,
    inventoryItems,
    extendedItems,
    filterValues,
    filterInventoryValues,
    filterExtendedValues,
    filterErrors,
    extendedFilterErrors,
    inventoryFilterErrors,
    loadingValues,
    loadingFields,
    loadingOptions,
    filteredFields,
    searchInDB,
    secondaryQuestionsErrors,
    onClearAssetFieldsData,
    onChangeSelectedValue,
    onChangeSelectedExtendedValue,
    onChangeExtendedInput,
    onChangeSelectedInventoryValue,
    onChangeInventoryInput,
    onChangeSearchAssetFields,
    onMenuCloseAfterSearch,
    onClickSearchInDb,
    onSelectPrimaryQuestion,
    onSelectSecondaryQuestion,
    onChangeSecondaryInputValue
}: IItemCatalogFieldsForm) => {
    const [stringFieldsValues, setStringFieldsValues] = useState<any>()

    useEffect(() => {
        setStringFieldsValues({
            ...(filterInventoryValues || {})
        })
    }, [])

    const onChangeInventoryStringInput = async (value: string | null | undefined, type: string) => {
        const findField = inventoryItems && inventoryItems?.length > 0 && inventoryItems?.find((field: any) => field?.name === type);
        setStringFieldsValues({
            ...(stringFieldsValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
        onChangeInventoryInput(value, type || '')
    }

    const capitalizeWords = (str: string) => {
        return str.split(/[\s_]+/).map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    }

    const findTemplateFields = items && Object.keys(items)
    return (
        <div className='mt-2'>
            {(findTemplateFields && findTemplateFields?.length > 0) &&
                <div className='flex flex-row justify-end my-1'>
                    <Button
                        label='Clear Item  Details Data'
                        className='btn-primary-text-underline'
                        onClickButton={onClearAssetFieldsData}
                    />
                </div>
            }
            {(!loadingValues && findTemplateFields && findTemplateFields?.length > 0) ?
                <>
                    {(primaryQuestions && primaryQuestions?.length > 0) && primaryQuestions?.map((item: any, index: number) => {
                        const filteredFieldsFind = filteredFields?.[item?.field]
                        const findError = filterErrors && filterErrors?.[item?.field]
                        const findLoadingOptions = !!loadingOptions?.[item?.field]
                        const values = filteredFieldsFind ?
                            filteredFieldsFind?.length > 0 &&
                            filteredFieldsFind?.map((value: string) => {
                                return { ...item, value: value, label: value };
                            })
                            : items?.[item?.field] &&
                            items?.[item?.field]?.length > 0 &&
                            items?.[item?.field]?.map((value: string) => {
                                return { ...item, value: value, label: value };
                            });
                        return (
                            <div key={index}>
                                <AsyncSelectCheckbox
                                    options={values || []}
                                    isLoading={(loadingFields && !selectedPrimaryAnswers?.[item?.question]) || false}
                                    dynamicOptions={true}
                                    name={item?.question}
                                    showSearchDB={searchInDB?.[item?.field] || false}
                                    placeholder={selectedPrimaryAnswers?.[item?.question] ? selectedPrimaryAnswers?.[item?.question]?.label : `Select ${item?.field}`}
                                    loadingOptions={findLoadingOptions}
                                    selectedOption={selectedPrimaryAnswers?.[item?.question]}
                                    uniqueName={item?.question}
                                    index={item?.field}
                                    onChangeSelectedOption={onSelectPrimaryQuestion}
                                    onChangeValue={onChangeSearchAssetFields}
                                    onMenuClose={onMenuCloseAfterSearch}
                                    onClickSearchInDb={onClickSearchInDb}
                                    error={findError}
                                />
                            </div>
                        )
                    })}
                    {(secondaryQuestions && secondaryQuestions?.length > 0) && secondaryQuestions?.map((item: IQuestion & { show: boolean }, index: number) => {
                        const findError = secondaryQuestionsErrors?.[item?.question]
                        const values = ['MULTIPLE_CHOICE', 'SINGLE_CHOICE'].includes(item?.answers?.type) && item?.answers?.options && item?.answers?.options?.length > 0 ?
                            item?.answers?.options?.map((option: { answer: string }) => {
                                return { ...option, value: option?.answer, label: option?.answer, type: item?.answers?.type };
                            })
                            : undefined
                        return (
                            <>
                                <div>
                                    {['MULTIPLE_CHOICE', 'SINGLE_CHOICE'].includes(item?.answers?.type)
                                        ?
                                        <SelectCheckbox
                                            options={values || []}
                                            placeholder=' '
                                            name={item?.question}
                                            selectedOption={selectedSecondaryAnswers?.[item?.question]}
                                            error={findError}
                                            multiple={item?.answers?.type === 'MULTIPLE_CHOICE'}
                                            disabled={!item?.show}
                                            containerStyle='w-full'
                                            uniqueName={item?.question}
                                            index={item?.answers?.type}
                                            onChangeSelectedOption={onSelectSecondaryQuestion}
                                        />
                                        : <Input
                                            error={findError}
                                            inputUniqueName={item?.question}
                                            showValue={true}
                                            label={item?.question}
                                            inputValue={selectedSecondaryAnswers?.[item?.question]?.value || undefined}
                                            disabled={!item?.show}
                                            placeholder=' '
                                            index={item?.answers?.type}
                                            containerStyle='!mb-0 my-1'
                                            onChangeInput={onChangeSecondaryInputValue}
                                        />
                                    }
                                </div>
                            </>
                        )
                    })}
                </>
                : <TemplateFieldsLoading />
            }
            {(!loadingValues && findTemplateFields && findTemplateFields?.length > 0 && inventoryItems && inventoryItems?.length > 0) && inventoryItems?.map((item, index) => {
                let values: any = item?.values;
                const findError = inventoryFilterErrors && inventoryFilterErrors?.[item?.name]
                if (item?.dataType === 'boolean') {
                    values = [
                        { value: true, name: 'True' },
                        { value: false, name: 'False' },
                    ];
                } else if (item?.dataType === 'list') {
                    values =
                        item?.values &&
                        item?.values?.length > 0 &&
                        values?.map((value: string) => {
                            return { value: value, label: value };
                        });
                }
                return (
                    <>
                        {item?.dataType === 'boolean' || item?.dataType === 'list'
                            ? <SelectCheckbox
                                key={index}
                                name={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : `${capitalizeWords(item?.name)}`}
                                placeholder={filterInventoryValues?.[item?.name] || ' '}
                                options={values}
                                selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeSelectedInventoryValue}
                                uniqueName={item?.name || ''}
                                error={findError}
                            />
                            : <Input
                                label={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : item?.name && `${capitalizeWords(item?.name)}`}
                                placeholder={' '}
                                inputUniqueName={item?.name}
                                onChangeInput={onChangeInventoryStringInput}
                                inputValue={filterInventoryValues?.[item?.name]}
                                showValue={true}
                                error={findError}
                            />
                        }
                    </>
                )
            })}
            {(!loadingValues && findTemplateFields && findTemplateFields?.length > 0 && extendedItems && extendedItems?.length > 0) && extendedItems?.map((item, index) => {
                let values: any = filteredFields && filteredFields?.[item?.name];
                const findError = extendedFilterErrors && extendedFilterErrors?.[item?.name]
                if (item?.dataType === 'boolean') {
                    values = [
                        { value: true, name: 'True' },
                        { value: false, name: 'False' },
                    ];
                } else if (item?.dataType === 'list') {
                    values =
                        values &&
                            values?.length > 0 ?
                            values?.map((value: string) => {
                                return { value: value, label: value };
                            }) :
                            items?.[item?.name] &&
                            items?.[item?.name]?.length > 0 &&
                            items?.[item?.name]?.map((value: string) => {
                                return { ...item, value: value, label: value };
                            });
                }
                return (
                    <>
                        {item?.dataType === 'boolean' || item?.dataType === 'list'
                            ? <SelectCheckbox
                                key={index}
                                name={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : `${capitalizeWords(item?.name)}`}
                                placeholder={filterExtendedValues?.[item?.name] || ' '}
                                options={values}
                                selectStyle={{ backgroundColor: 'white', borderRadius: '4px' }}
                                onChangeSelectedOption={onChangeSelectedExtendedValue}
                                uniqueName={item?.name || ''}
                                error={findError}
                            />
                            : <Input
                                label={item?.name && (item?.name || '').toLowerCase() === 'health' ? 'Battery Health' : item?.name && `${capitalizeWords(item?.name)}`}
                                placeholder={' '}
                                inputUniqueName={item?.name}
                                onChangeInput={onChangeExtendedInput}
                                inputValue={filterExtendedValues?.[item?.name]}
                                showValue={true}
                                error={findError}
                            />
                        }
                    </>
                )
            })}
        </div>
    )
}

export default ItemCatalogFieldsForm;