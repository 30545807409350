import { AxiosResponse } from 'axios';
import { ASSETS_API_URL } from '../../../../config';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import assetsAxios from '../../../../utils/axios/assets.axios';


const tryToFetchAllAssetTemplates = (): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(`${ASSETS_API_URL}/dom/asset-templates`);
};

const tryToFetchAssetsTemplates = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${ASSETS_API_URL}/asset-templates?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ASSETS_API_URL}/asset-templates?page=${pageNumber}&size=${pageSize}`;
    return assetsAxios.put<any>(url);
};

const tryToFetchAssetTemplateFieldsFilter = (
    assetId: string,
): Promise<AxiosResponse<any>> => {
    return assetsAxios.get<any>(`${ASSETS_API_URL}/asset-templates/${assetId}/field-values`);
};

const tryToFetchAssetTemplateFilters = (assetId: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/asset-templates/${assetId}/get-filters`);
};

const tryToFetchInventoryFilters = (assetId: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/asset-templates/${assetId}/get-inventory-filters`);
};

const tryToFetchSingleAssetTemplate = (id: string): Promise<AxiosResponse<ApiResponse<any>>> => {
    return assetsAxios.get(`${ASSETS_API_URL}/asset-templates/${id}`);
};

export const AssetsTemplatesService = {
    tryToFetchAllAssetTemplates,
    tryToFetchAssetsTemplates,
    tryToFetchAssetTemplateFieldsFilter,
    tryToFetchAssetTemplateFilters,
    tryToFetchInventoryFilters,
    tryToFetchSingleAssetTemplate
};