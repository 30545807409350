import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../utils/constants/currencySymbols';
import {
    tryToAddTransaction,
    tryToGetCreditById
} from '../../../store/inventory/broker/credit-managment/creditManagmentSlice';
import TransactionsTable from './tables/TransactionsTable';
import Button from '../../../shared/button';
import AmountModal from './modals/AmountModal';


const CreditManagmentDetails = () => {
    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const [details, setDetails] = useState<any>()
    const [type, setType] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>(0)
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const currency = CURRENCY_SYMBOLS?.[`${(details?.currency || '')}`]

    const onPress = (type: string) => {
        setType(type)
        setShowModal(true)
    }

    const getDetails = async () => {
        try {
            const data = await dispatch(tryToGetCreditById(id)).unwrap()
            setDetails(data)
        } catch (error) {
            // error here
        }
    }

    const onChangeAmount = (e: any) => {
        setAmount(e)
    }

    useEffect(() => {
        getDetails()
    }, [])

    const onAddTransaction = async () => {
        setLoading(true)
        let isValid = true
        if (!amount) {
            isValid = false
            setError('Amount is required')
        }
        if (amount <= 0) {
            isValid = false
            setError('Amount should not be 0 or below')
        } if (type === 'withdraw' && amount > details?.outStandingCredit) {
            isValid = false
            setError('Amount should not be higher than balance')
        }
        try {
            if (isValid) {
                setError('')
                const body = {
                    creditManagementId: id,
                    amount: amount,
                    type: type
                }
                await dispatch(tryToAddTransaction(body)).unwrap()
                await getDetails()
                setLoading(false)
                setShowModal(false)
                setType('')
                setAmount(0)
                setError('')
            }
        } catch (error: any) {
            setError(`${error?.response?.data || error?.response || error}`)
            setLoading(false)
        }
        setLoading(false)
    }

    const onGoBack = () => {
        navigator('/credit-management')
    }

    const onCloseAmountModal = () => {
        setShowModal(false)
        setAmount(0)
        setType('')
        setError('')
        setLoading(false)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-5'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                        <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Credit Details</p>
                </div>
            </div>
            <p>Customer: <strong>{details?.customerInfo?.name}</strong></p>
            <div className='flex flex-row w-full justify-between flex-wrap gap-3 '>
                <div className='mt-2 p-5 bg-white  min-w-[175px] flex-auto rounded-2xl border-[1px] border-solid border-[#EBEDEF] flex gap-6 flex-col justify-between'>
                    <div>
                        <p className='text-base font-bold'>Initial Balance</p>
                    </div>
                    <div>
                        <p className='font-bold text-xl'>{currency}{details?.initialBalance?.toFixed(2)}</p>
                    </div>
                </div>
                <div className='mt-2 p-5 bg-white  min-w-[175px] flex-auto rounded-2xl border-[1px] border-solid border-[#EBEDEF] flex gap-6 flex-col'>
                    <div>
                        <p className='text-base font-bold'>Purchases</p>
                        <p className='text-base font-light'>(Credit Spent)</p>
                    </div>
                    <div>
                        <p className='font-bold text-xl'>{currency}{details?.creditSpent?.toFixed(2)}</p>
                    </div>
                </div>
                <div className='mt-2 p-5 bg-white  min-w-[175px] flex-auto rounded-2xl border-[1px] border-solid border-[#EBEDEF] flex flex-col justify-between'>
                    <div>
                        <p className='text-base font-bold'>Balance</p>
                    </div>
                    <div>
                        <p className='font-bold text-xl'>{currency}{details?.outStandingCredit?.toFixed(2)}</p>
                    </div>
                </div>
                <div className='mt-2 p-5 bg-white  min-w-[175px] flex-auto rounded-2xl border-[1px] border-solid border-[#EBEDEF] flex flex-col justify-between'>
                    <div>
                        <p className='text-base font-bold'>Top Ups</p>
                    </div>
                    <div>
                        <p className='font-bold text-xl'>{currency}{details?.topUps?.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-row gap-3 mt-4 mb-4'>
                <Button
                    label={'Deposit'}
                    onClickButton={() => { onPress('deposit') }} />
                <Button
                    label={'Withdraw'}
                    onClickButton={() => { onPress('withdraw') }} />
            </div>
            <TransactionsTable
                search=''
                creditManagementId={id || ''}
                loading={loading}
                currency={currency} />
            <AmountModal
                openModal={showModal}
                amount={amount}
                type={type}
                error={error}
                onAddTransaction={onAddTransaction}
                onChangeAmount={onChangeAmount}
                handleCloseModal={onCloseAmountModal}
            />
        </div>
    )
}

export default CreditManagmentDetails
