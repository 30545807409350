import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    tryToAddSaleRequestNote,
    tryToFetchSaleRequestNote
} from '../../../../store/inventory/broker/sale-requests/saleRequestsSlice';
import NotesTable from '../tables/NotesTable';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Button from '../../../../shared/button';
import Textarea from '../../../../shared/textarea';


interface IAddNotesModal {
    openModal: boolean;
    saleId: string;
    handleCloseModal: () => void;
    onSuccessfullyAddedNote: () => void;
}

type FormValues = {
    note: string;
};

const AddNotesModal = ({
    openModal,
    saleId,
    handleCloseModal,
    onSuccessfullyAddedNote,
}: IAddNotesModal) => {
    const dispatch = useAppDispatch();
    const [addNoteError, setAddNoteError] = useState<string>()
    const [quoteNotes, setQuoteNotes] = useState<any>();
    const quoteNotesData = useAppSelector((state) => state?.saleRequests?.saleRequestNotes)

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue
    } = useForm<FormValues>({});

    useEffect(() => {
        setQuoteNotes(quoteNotesData || undefined)
    }, [saleId, quoteNotesData])

    useEffect(() => {
        if (saleId) {
            dispatch(tryToFetchSaleRequestNote(saleId))
        }
    }, [saleId, openModal])

    const onCloseModal = () => {
        handleCloseModal();
        setAddNoteError(undefined)
        reset();
    }

    const onSubmit = async (data: FormValues) => {
        try {
            await dispatch(tryToAddSaleRequestNote({ id: saleId, data: { text: data?.note } })).unwrap()
            onSuccessfullyAddedNote()
            setAddNoteError(undefined)
            reset();
        } catch (err) {
            setAddNoteError(`${err}`)
        }
    }

    const onChangeValueMessage = (e: any) => {
        setValue('note', e?.target?.value)
    }

    return (
        <Modal
            open={openModal}
            showInRight
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <p className='mb-4 font-semibold text-sm'>Leave a Note</p>
                {addNoteError && <Error text={addNoteError} />}
                <div className='bg-[#f8f9fc] !py-3'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-row pl-3'>
                            <Textarea
                                placeholder='Note'
                                rows={4}
                                containerStyle='!min-w-[94%]'
                                className={'!w-full !bg-white p-2'}
                                value={watch('note')}
                                onChangeTextarea={onChangeValueMessage}
                                error={errors.note?.message}
                            />
                        </div>
                        <div className='min-w-full flex flex-row justify-center'>
                            <Button
                                label={'Add Note'}
                                className={'btn-primary-rounded-less my-3 min-w-[130px] !shadow-none'}
                                type='submit'
                            />
                        </div>
                    </form>
                    {quoteNotes && quoteNotes?.length > 0 &&
                        <div className='p-2'>
                            <p className='mt-4 mb-1 text-[17px]'>Previous Notes</p>
                            <NotesTable
                                rows={quoteNotes}
                            />
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default AddNotesModal;