import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';


interface IReturnDiscrepancyItemModal {
    openModal: boolean;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const ReturnDiscrepancyItemModal = ({
    openModal,
    loading,
    handleCloseModal,
    onApproveAction,
}: IReturnDiscrepancyItemModal) => {
    return (
        <Modal
            open={openModal}
            showInRight
            title={'Discrepancy Action'}
            onClose={() => handleCloseModal()}>
            <div className={'p-2 min-w-[450px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Return</p>
                </div>
                <div className='p-2 !rounded bg-[#f8f9fc] my-3'>
                    <p className='my-2 text-[17px] font-medium'>Are you sure you want to Return the Item?</p>
                    <p className='mb-3 text-[13px]'>Once you do this action this item will be counted as an item to be returned and a child quote id for RMA will be created.</p>
                    <div className='flex flex-row justify-center my-6'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px] mr-3`}
                            onClickButton={() => !loading && handleCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'yes-button'}
                            className={`${loading ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ReturnDiscrepancyItemModal;