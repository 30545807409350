import { AxiosResponse } from 'axios';
import { USERS_API_URL } from '../../../../config';
import brokersAxios from '../../../../utils/axios/brokers-roles.axios';
import { IPartnersSchema } from '../../../../interfaces/partners/IPartnersSchema';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IWarehouse } from '../../../../interfaces/warehouses/IWarehouse';
import { IWarehouseLocation } from '../../../../interfaces/warehouses/IWarehouseLocation';

const tryToFetchWarehouses = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/warehouse/all`)
}

const tryToFetchPartnerWarehouses = (brokerId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/dom/warehouses/all/broker/${brokerId}`)
}

const tryToFetchWarehouseMarkets = (warehouseId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/warehouse/${warehouseId}/markets`)
}

const tryToFetchSingleWarehouse = (warehouseId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/warehouse/${warehouseId}`)
}

const tryToEditWarehouse = (warehouseId: string, payload: IWarehouse): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${warehouseId}`, payload);
};

const tryToEnableWarehouse = (warehouseId: string): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${warehouseId}/enable`);
};

const tryToAddWarehouse = (data: IWarehouse): Promise<AxiosResponse<ApiResponse<IPartnersSchema>>> => {
    return brokersAxios.post(`${USERS_API_URL}/warehouse`, data)
}

const tryToDisableWarehouse = (warehouseId: string): Promise<AxiosResponse<ApiResponse<IWarehouse>>> => {
    return brokersAxios.delete(`${USERS_API_URL}/warehouse/${warehouseId}`)
}

const tryToAddWarehouseFields = (warehouseId: string, data: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${warehouseId}/location`, data);
};

const tryToEditWarehouseField = (warehouseId: string, fieldId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${warehouseId}/location/${fieldId}`, payload);
};

const tryToFetchWarehouseField = (warehouseId: string, fieldId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/warehouse/${warehouseId}/location/${fieldId}`);
};

const tryToDeleteWarehouseField = (warehouseId: string, fieldId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/warehouse/${warehouseId}/location/${fieldId}`);
};

const tryToGetWarehouseFields = (warehouseId: string, data?: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/${warehouseId}/values`, data);
};

const tryToFetchWarehouseLocations = (
    pageNumber: number,
    pageSize: number,
    id: string,
    filters?: string,
): Promise<AxiosResponse<any>> => {
    const url = filters
        ? `${USERS_API_URL}/locations/${id}?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${USERS_API_URL}/locations/${id}?page=${pageNumber}&size=${pageSize}`;
    return brokersAxios.get<any>(url);
};

const tryToFetchWarehouseSingleLocation = (warehouseId: string, locationId: string): Promise<AxiosResponse> => {
    return brokersAxios.get(`${USERS_API_URL}/locations/${warehouseId}/${locationId}`)
}

const tryToAddWarehouseLocation = (data: IWarehouseLocation): Promise<AxiosResponse> => {
    return brokersAxios.post(`${USERS_API_URL}/locations`, data);
};

const tryToEditWarehouseLocation = ( locationId: string, payload: any): Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/locations/${locationId}`, payload);
};

const tryToDeleteWarehouseLocation = (warehouseId: string, fieldId: string): Promise<AxiosResponse> => {
    return brokersAxios.delete(`${USERS_API_URL}/locations/${warehouseId}/${fieldId}`);
};

const tryToSortWarehouseFields = (warehouseId: string, data: any):Promise<AxiosResponse> => {
    return brokersAxios.put(`${USERS_API_URL}/warehouse/${warehouseId}/location/sort`, data);
};

export const WarehousesService = {
    tryToFetchWarehouses,
    tryToFetchWarehouseMarkets,
    tryToFetchSingleWarehouse,
    tryToFetchWarehouseField,
    tryToFetchWarehouseLocations,
    tryToFetchWarehouseSingleLocation,
    tryToGetWarehouseFields,
    tryToEditWarehouse,
    tryToEditWarehouseField,
    tryToEditWarehouseLocation,
    tryToEnableWarehouse,
    tryToAddWarehouse,
    tryToAddWarehouseFields,
    tryToAddWarehouseLocation,
    tryToDisableWarehouse,
    tryToDeleteWarehouseField,
    tryToDeleteWarehouseLocation,
    tryToSortWarehouseFields,
    tryToFetchPartnerWarehouses
};
