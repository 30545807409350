import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToImportInventoryCosts } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCostsCsvFile from '../details/UploadCostsCsvFile';
import Modal from '../../../../shared/modal';


interface IImportCostsModal {
    openImportModal: boolean;
    sectors?: Array<any>;
    handleCloseModal: () => void;
}

const ImportCostsModal = ({
    openImportModal,
    sectors,
    handleCloseModal
}: IImportCostsModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()
    const [rowsError, setRowsError] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setSelectedCategory(undefined)
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        if (!selectedCategory) {
            setImportError('Please select category first to continue.')
            return;
        }
        let errorInRows: any
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    results && results?.data && results?.data?.length > 0 && results?.data?.map((item: any, index: number) => {
                        if (!item?.itemId) {
                            errorInRows = {
                                ...errorInRows || {},
                                itemId: [
                                    ...errorInRows?.itemId || [],
                                    index + 1
                                ]
                            }
                        }
                        if (!item?.value) {
                            errorInRows = {
                                ...errorInRows || {},
                                value: [
                                    ...errorInRows?.value || [],
                                    index + 1
                                ]
                            }
                        }
                        if (!item?.type) {
                            errorInRows = {
                                ...errorInRows || {},
                                type: [
                                    ...errorInRows?.type || [],
                                    index + 1
                                ]
                            }
                        }
                        return item
                    })

                    const payload = {
                        assetTemplateId: selectedCategory?.id,
                        warehouse: activeWarehouse?._id,
                        data: results?.data || []
                    }
                    if (!errorInRows) {
                        await dispatch(tryToImportInventoryCosts(payload)).unwrap()
                        setImportError(undefined)
                        setSelectedCategory(undefined)
                        setFileSelected(undefined)
                        setImportedSuccessfully(true)
                        setRowsError(undefined)
                    } else {
                        setRowsError(errorInRows)
                    }
                }
            })
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    const onChangeSelectedSector = (value: any, type?: string) => {
        setSelectedCategory(value)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={onCloseModal}
                    />
                    : <UploadCostsCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        rowsError={rowsError}
                        sectors={sectors || []}
                        selectedSector={selectedCategory}
                        activeWarehouse={activeWarehouse}
                        onChangeSelectedSector={onChangeSelectedSector}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportCostsModal;