import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { VAT_TYPE_ITEMS } from '../../../utils/constants/vat-types';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IToast } from '../../../interfaces/components/IToast';
import { IInventory } from '../../../interfaces/inventory-data/IInventory';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToFetchAssetTemplateFilters,
    tryToFetchInventoryFilters
} from '../../../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import {
    tryToAddInventoryLocation,
    tryToFetchInventoryAgreggatedDetails
} from '../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import { tryToFetchAllCrmData } from '../../../store/inventory/broker/crm-data/crmDataSlice';
import { tryToFetchAllPayServices } from '../../../store/inventory/shared/partners/partnersSlice';
import { tryToFetchWarehouseMarkets } from '../../../store/inventory/broker/warehouses/warehousesSlice';
import InventoryDetailsCategories from './details/InventoryDetailsCategories';
import InventoryDetailsFilters from './details/InventoryDetailsFilters';
import InventoryDetailsTable from './table/InventoryDetailsTable';
import SearchWithButton from '../../../shared/search-with-button';
import WarehouseModal from '../../../shared/warehouse-modal';
import Toast from '../../../shared/toast';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const InventoryDetails = () => {
    const query = useQuery();
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const { assetId, id } = useParams();
    const [rows, setRows] = useState<Array<IInventory>>([]);
    const [search, setSearch] = useState<string>();
    const [markets, setMarkets] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [sources, setSources] = useState<any>();
    const [selectedMargins, setSelectedMargins] = useState<any>();
    const [templateFilters, setTemplateFilters] = useState<Array<any>>();
    const [inventoryFilter, setInventoryFilters] = useState<Array<any>>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [selectedSource, setSelectedSource] = useState<any>();
    const [activeWarehouse, setActiveWarehouse] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10
    });
    const [inventorySelected, setInventorySelected] = useState<any>();
    const [reservedSelected, setReservedSelected] = useState<any>();
    const [showWarehouseModal, setShowWarehouseModal] = useState<{ show: boolean, id: string }>()
    const state = useAppSelector((state) => state.inventory);
    const stateMarkets = useAppSelector((state) => state.warehouses);
    const stateFilters = useAppSelector((state) => state.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.navigationAccess);
    const paginationData = useAppSelector((state) => state?.inventory?.inventory?.data?.page);
    const activeWarehouseState = useAppSelector((state) => state?.partners?.partnerActiveWarehouse);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Inventory')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Inventory Aggregation Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.inventory) {
            const rows =
                state.inventory?.data?.elements && Array.isArray(state.inventory?.data.elements)
                    ? state.inventory?.data.elements.map((r) => ({ ...r }))
                    : [];
            setRows(rows);
        }
    }, [state.inventory]);

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined
        })
    }

    useEffect(() => {
        if (activeWarehouseState && (JSON.stringify(activeWarehouseState) !== JSON.stringify(activeWarehouse))) {
            setActiveWarehouse(activeWarehouseState || undefined)
            onResetPaginationSettings()
        }
    }, [activeWarehouseState])

    const getBrokerSources = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0 && payServicesResponse?.data?.map((service: any) => ({ ...service, label: service?.displayName, value: service?._id }))
        const response: any = await dispatch(tryToFetchAllCrmData({ type: 'customer' }))
        const formatCustomer: any = response?.payload && response?.payload?.data?.length > 0 ? response?.payload?.data?.map((s: any) => ({ ...s, label: s?.displayName || s?.name, name: s?.name, value: s?._id })) : []
        setSources([
            ...formatPayServices || [],
            ...formatCustomer || []
        ])
    }

    useEffect(() => {
        if (stateFilters.assetTemplateFilters) {
            setTemplateFilters(stateFilters.assetTemplateFilters)
        }
        if (stateFilters.inventoryFieldFilters) {
            setInventoryFilters(stateFilters.inventoryFieldFilters)
        }
    }, [stateFilters?.assetTemplateFilters, stateFilters?.inventoryFieldFilters, assetId]);

    const onGettingInventoryData = async () => {
        if (activeWarehouse && !(!selectedMargins && query?.get('vat')) && !(!search && query?.get('search'))) {
            try {
                const filters = filterValues && Object.keys(filterValues)
                let formatFilters
                const filtersValuesFormat = filters && filters?.length > 0 && filters?.map((item: any) => {
                    return {
                        key: item,
                        value: Object.keys(filterValues) ? filterValues?.[item] || undefined : undefined
                    }
                })

                if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                    formatFilters = {
                        value: filtersValuesFormat?.filter((key: any) => key?.value)
                    }
                }
                let data: any
                if (id) {
                    data = {
                        ...data || {},
                        _id: id
                    }
                }
                if (assetId) {
                    data = {
                        ...data || {},
                        category: {
                            id: assetId
                        }
                    }
                }
                if (query.get('color')) {
                    data = {
                        ...data || {},
                        splitColor: true
                    }
                }
                if (activeWarehouse) {
                    data = {
                        ...data || {},
                        warehouse: activeWarehouse?._id,
                    }
                }
                if (selectedMargins && selectedMargins?.length > 0) {
                    const formatMargins = selectedMargins?.map((margin: any) => margin?.value)
                    data = {
                        ...data || {},
                        stockVats: formatMargins
                    }
                }
                if (selectedSource) {
                    data = {
                        ...data || {},
                        source: selectedSource?.name
                    }
                }
                if (selectedMarket) {
                    data = {
                        ...data || {},
                        marketId: selectedMarket ? selectedMarket?._id || '' : '',
                    }
                }
                if (search) {
                    data = {
                        ...data || {},
                        search: search
                    }
                }
                if (formatFilters && formatFilters?.value) {
                    data = {
                        ...data || {},
                        ...formatFilters || {}
                    }
                }
                if (inventorySelected) {
                    data.location = inventorySelected?.value === 'Yes'
                }
                if (reservedSelected) {
                    data.sales = reservedSelected?.value === 'Yes'
                }
                const fetchData = {
                    ...paginationState || {},
                    data
                }
                await dispatch(tryToFetchInventoryAgreggatedDetails(fetchData)).unwrap()
            } catch (err) {
                // err here
            }
        }
    }

    useEffect(() => {
        if (assetId) {
            dispatch(tryToFetchInventoryFilters(assetId))
            dispatch(tryToFetchAssetTemplateFilters(assetId))
        }
        onGettingInventoryData()
        setFilterValues(undefined)
    }, [assetId, id])

    useLayoutEffect(() => {
        if (query.get('vat')) {
            const margins: any = query?.get('vat') ? (query?.get('vat') || '').split(',') : []
            const findMargins = VAT_TYPE_ITEMS?.filter((item: any) => (margins && margins?.length > 0 ? margins : [])?.includes(item?.value))
            setSelectedMargins(findMargins || undefined)
        }
    }, [query.get('vat')])


    useLayoutEffect(() => {
        if (query.get('search')) {
            const searchData = query?.get('search') || undefined
            setSearch(searchData)
        }
    }, [query.get('search')])

    useEffect(() => {
        onGettingInventoryData()
    }, [paginationState, activeWarehouse])

    useEffect(() => {
        getBrokerSources()
    }, [])

    useEffect(() => {
        if (stateMarkets?.warehouseMarkets && stateMarkets?.warehouseMarkets?.length > 0) {
            const formatMarkets = stateMarkets.warehouseMarkets && stateMarkets.warehouseMarkets?.length > 0 && stateMarkets.warehouseMarkets?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id }))
            setMarkets(formatMarkets || [])
        }
    }, [stateMarkets.warehouseMarkets])

    useEffect(() => {
        if (activeWarehouse?._id) {
            dispatch(tryToFetchWarehouseMarkets(activeWarehouse?._id)).unwrap();
        }
    }, [activeWarehouse])

    const onClickMarket = (value: any, type?: string) => {
        setSelectedMarket(value || undefined)
        onResetPaginationSettings()
    }

    const onChangeFilterInput = (value: string | null | undefined, type: string) => {
        const findField = templateFilters?.find((field) => field?.name === type);
        setFilterValues({
            ...(filterValues || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedFilterValue = (value: any, type?: string) => {
        if (type) {
            const findField = templateFilters?.find((field) => field?.name === type);
            setFilterValues({
                ...(filterValues || {}),
                [type]: findField?.dataType === 'boolean' ? value?.value === 'true' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined)
        onResetPaginationSettings()
    }

    const onClearAllFilters = () => {
        onResetPaginationSettings()
        setFilterValues(undefined)
        setSelectedMargins(undefined)
        setReservedSelected(undefined)
        setInventorySelected(undefined)
        setSelectedMarket(undefined)
        setSelectedSource(undefined)
    }

    const onGoBack = () => {
        navigation(-1)
    }

    const onClickInventoryFilter = (value: any, type?: string) => {
        setInventorySelected(value)
        onResetPaginationSettings()
    }

    const onClickReservedFilter = (value: any, type?: string) => {
        setReservedSelected(value)
        onResetPaginationSettings()
    }

    const onChangeSelectedMargins = (e: any) => {
        setSelectedMargins(e?.length > 0 ? e : [])
        onResetPaginationSettings()
    }

    const onChangeSource = (e: any, type?: string) => {
        setSelectedSource(e)
        onResetPaginationSettings()
    }

    const onChangeWarehouseLocation = (id?: string) => {
        navigation('/stock-move?type=Item Location')
    }

    const onSetItemLocation = (data: any) => {
        try {
            const formatData = {
                ...data || {},
                itemId: showWarehouseModal?.id || ''
            }
            dispatch(tryToAddInventoryLocation(formatData))
            setShowToast({ type: 'success', message: 'Warehouse and Location were added successfully' });
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response?.data || err?.response || err}` });
        }
        setShowWarehouseModal(undefined)
    }

    const onClickSeeItemDetails = (id: string) => {
        navigation(`/inventory/details/${id}`)
    }

    return (
        <div>
            <div className={'flex flex-row items-center justify-between mb-5'}>
                <div className='flex flex-row items-center'>
                    <div onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Inventory Details</p>
                </div>
            </div>
            <div className='my-10'>
                <InventoryDetailsCategories
                    markets={markets || []}
                    sources={sources || []}
                    selectedSource={selectedSource}
                    selectedMarket={selectedMarket}
                    reservedSelected={reservedSelected}
                    selectedMargins={selectedMargins}
                    inventorySelected={inventorySelected}
                    onChangeSource={onChangeSource}
                    onChangeSelectedMargins={onChangeSelectedMargins}
                    onClickInventoryFilter={onClickInventoryFilter}
                    onClickReservedFilter={onClickReservedFilter}
                    onClickMarket={onClickMarket}
                />
                {assetId && ((templateFilters && templateFilters?.length > 0) || (inventoryFilter && inventoryFilter?.length > 0)) &&
                    <InventoryDetailsFilters
                        items={templateFilters || []}
                        inventoryItems={inventoryFilter || []}
                        filterValues={filterValues || null}
                        onChangeInput={onChangeFilterInput}
                        onChangeSelectedValue={onChangeSelectedFilterValue}
                    />
                }
            </div>
            <div>
                <SearchWithButton
                    buttonLabel='Clear All Filters'
                    placeholder='Filter table'
                    onChangeSearchValue={onChangeSearchValue}
                    search={search}
                    onClickButton={onClearAllFilters}
                    buttonStyle={'btn-primary-text-underline min-w-auto col-span-2 flex flex-row justify-end'}
                    mainContainerStyle={'!grid-cols-10'}
                />
                <InventoryDetailsTable
                    rows={rows}
                    sources={sources || []}
                    accessControl={accessControl}
                    actions={pageAccess?.actions}
                    paginationData={paginationData}
                    setPaginationState={setPaginationState}
                    onChangeLocation={onChangeWarehouseLocation}
                    onClickSeeDetails={onClickSeeItemDetails}
                />
                {showWarehouseModal && showWarehouseModal?.show &&
                    <WarehouseModal
                        open={true}
                        title={'Inventory Item Location'}
                        onClose={onChangeWarehouseLocation}
                        onSetItemLocation={onSetItemLocation}
                    />
                }
                {showToast?.message &&
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                }
            </div>
        </div>
    )
}

export default InventoryDetails;